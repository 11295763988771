import type { CellContext, ColumnDef } from '@tanstack/react-table';
import type { FunctionComponent, ReactNode } from 'react';
import { formatterForName } from 'components/formatter.utils';
import GTable from 'components/technical/GTable/GTable';

import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import SectionColumn from 'components/technical/layout/Column/SectionColumn';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import { type IStrategyPositionStatisticsQuery, useStrategyPositionStatisticsQuery } from 'generated/graphql';

type MetricRow = IStrategyPositionStatisticsQuery['strategy']['positionStatistics'][number];

const statisticNameAndFormat = {
  AVG_HOLDING_TIME: {
    name: 'Average holding time',
    format: 'duration' as const,
  },
  AVG_TRADES_PER_WEEK: { name: 'Average trades per week', format: 'number' as const },
  AVG_TRADE_WIN_RATIO: { name: 'Average trade win ratio', format: 'percentage' as const },
};

const StrategyTradingStatistics: FunctionComponent<{ strategy: Label }> = ({ strategy }) => {
  const { loaded, Fallback, data } = useDefaultErrorHandling(
    useStrategyPositionStatisticsQuery({ variables: { label: strategy } })
  );

  if (!loaded) {
    return <Fallback />;
  }
  const tableData = data.strategy.positionStatistics;

  const columns: ColumnDef<MetricRow>[] = [
    {
      header: 'Name',
      accessorFn: ({ name }) => statisticNameAndFormat[name].name,
    },
    {
      header: 'Value',
      cell: (props: CellContext<MetricRow, unknown>): ReactNode => {
        const { name, value } = props.row.original;
        const formatter = formatterForName(statisticNameAndFormat[name].format);
        return formatter(value);
      },
    },
  ];
  return (
    <SectionColumn>
      <HeaderBar title="Trading statistics" />
      <GTable data={tableData} columns={columns} hideHeaders disablePagination />
    </SectionColumn>
  );
};

export default StrategyTradingStatistics;
