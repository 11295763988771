import type { ReactElement } from 'react';
import { IconVariant } from './cryptocurrencies/CryptocurrenciesData.tsx';
import { AssetIcon, type AssetIconAssetProps } from './AssetLabel.tsx';
import { MaxItemsAvatarGroup } from 'components/MaxItemsAvatarGroup.tsx';
import { sortBy, uniqBy } from 'lodash/fp';

export const AssetIconList = (props: { assets: AssetIconAssetProps[]; maxItems?: number }): ReactElement => {
  const size = IconVariant.LARGE;
  const uniqAssets = uniqBy((asset) => asset.id, props.assets);
  const sorted = sortBy((asset) => asset.symbol, uniqAssets);
  const items = sorted.map((asset, i) => <AssetIcon asset={asset} size={size} key={i} />);

  return (
    <MaxItemsAvatarGroup maxItems={props.maxItems} size={size}>
      {items}
    </MaxItemsAvatarGroup>
  );
};
