import { Typography } from '@mui/joy';
import type { ReactElement } from 'react';

export const Header4 = ({ title }: { title: string }): ReactElement => {
  /* jscpd:ignore-start */
  return (
    <Typography level="body-md" mb={3}>
      {title}
    </Typography>
  );
  /* jscpd:ignore-end */
};
