import { Box } from '@mui/joy';
import { Typography as JoyTypography } from '@mui/joy';
import type { ReactElement, ReactNode } from 'react';

import ResponsiveColumn from '../layout/Column/ResponsiveColumn';

export const GDialogDescription = ({ children }: { children: ReactNode }): ReactElement => {
  return (
    <ResponsiveColumn sm={12} lg={10}>
      <Box textAlign="center" pb={2}>
        <JoyTypography color="neutral">{children}</JoyTypography>
      </Box>
    </ResponsiveColumn>
  );
};
