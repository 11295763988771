import { Stack, Typography } from '@mui/joy';
import type { FunctionComponent, ReactNode } from 'react';

const VerticalLabelAndValue: FunctionComponent<{
  variant: 'left' | 'right' | 'center';
  label: ReactNode;
  value: ReactNode;
}> = ({ variant, label, value }) => (
  <Stack alignItems={variant === 'right' ? 'flex-end' : variant === 'left' ? 'flex-start' : 'center'} spacing={0}>
    <Typography color="neutral" level="body-sm">
      {label}
    </Typography>
    <Typography level="body-md" component="div">
      {value}
    </Typography>
  </Stack>
);

export default VerticalLabelAndValue;
