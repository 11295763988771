import type { ApolloError } from '@apollo/client';
import type { PollingProps } from 'components/technical/errors/UseHasErrors';
import { INotificationStatus, useNotificationsQuery } from 'generated/graphql';

type UseHasDeliveredNotifications = {
  hasNotifications: boolean | undefined;
  loaded: boolean;
  error: ApolloError | undefined;
} & PollingProps;
export const useHasDeliveredNotifications = ({
  pollInterval,
}: {
  pollInterval: number;
}): UseHasDeliveredNotifications => {
  const { data, loading, error, startPolling, stopPolling } = useNotificationsQuery({
    variables: {
      pageLimit: { limit: 1 },
      status: INotificationStatus.Delivered,
    },
    pollInterval,
  });

  if (loading) {
    return {
      hasNotifications: undefined,
      loaded: false,
      error: undefined,
      stopPolling,
      startPolling,
    };
  }

  if (error) {
    return {
      loaded: true,
      hasNotifications: undefined,
      error,
      stopPolling,
      startPolling,
    };
  }

  const notificationCount = data!.notification.list.pageInfo.totalResults;

  return {
    hasNotifications: notificationCount > 0,
    loaded: !loading,
    error: undefined,
    stopPolling,
    startPolling,
  };
};
