import { Stack, Typography } from '@mui/joy';
import isNil from 'lodash/fp/isNil';
import { type ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { v4 } from 'uuid';
import {
  type ICostBasisReportDetailsQuery,
  ICostBasisReportStatus,
  useCostBasisReportDetailsQuery,
  useCostBasisReportInputQuery,
  useCostBasisReportQuery,
  useCostBasisReportUrlQuery,
} from '../../../../generated/graphql.tsx';
import { downloadFile } from '../../../file.utils.ts';
import { type Asset, getAssets } from '../../../market/asset/Asset.types.ts';
import { createAssetSelectOptions } from '../../../market/asset/AssetService.tsx';
import { defaultRowSpacing } from '../../../StackSpacing.ts';
import { GraphQLErrorMessage } from '../../../technical/form/GraphQLApiErrorMessage.tsx';
import StaticSingleAutocomplete from '../../../technical/inputs/Autocomplete/StaticSingleAutocomplete.tsx';
import GButton from '../../../technical/inputs/GButton/GButton.tsx';
import { useSubAccountAssetFilters } from '../../../technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import { useDefaultErrorHandling } from '../../../technical/UseDefaultErrorHandling.tsx';

import { useValueChanged } from '../../../UseValueChanged.tsx';

const pollStatusSeconds = 5;

const shouldPollForStatus = ({
  reportId,
  statusData,
  statusChanged,
  currentShouldPollForStatus,
}: {
  reportId: string | undefined;
  statusData: ICostBasisReportDetailsQuery | undefined;
  statusChanged: boolean;
  currentShouldPollForStatus: boolean;
}): boolean => {
  if (isNil(reportId)) {
    return false;
  }

  if (!statusData) {
    return false;
  }

  if (!statusChanged) {
    return currentShouldPollForStatus;
  }

  return statusData.portfolio.journal.costBasisReportDetails.status === ICostBasisReportStatus.Pending;
};

const useDownloadReport = (
  asset: Asset | undefined,
  requestId: string | undefined
): { loading: boolean; error: Error | undefined; url: string | undefined } => {
  const pollingForStatusRef = useRef(false);
  const { subAccountAssetFilters } = useSubAccountAssetFilters();

  // pass requestId so that variables change each time we click on the button
  const reportQuery = useCostBasisReportQuery({
    variables: {
      ...(isNil(asset)
        ? {}
        : {
            subAccountAssetFilters: {
              subFundIds: subAccountAssetFilters.subFundIds,
              assetIds: [asset.id],
            },
            requestId,
          }),
    },
    skip: isNil(asset),
  });

  const reportId = reportQuery.data?.portfolio.journal.costBasisReport.id;
  const detailsQuery = useCostBasisReportDetailsQuery({
    ...(reportId
      ? {
          variables: {
            reportId: reportId,
          },
        }
      : {}),
    skip: isNil(reportId),
  });

  const status = detailsQuery.data?.portfolio.journal.costBasisReportDetails.status;
  const statusChanged = useValueChanged(status);

  const pollForStatus = shouldPollForStatus({
    reportId,
    statusData: detailsQuery.data,
    statusChanged,
    currentShouldPollForStatus: pollingForStatusRef.current,
  });

  pollingForStatusRef.current = pollForStatus;

  useEffect(() => {
    if (pollForStatus) {
      detailsQuery.startPolling(pollStatusSeconds * 1000);
    } else {
      detailsQuery.stopPolling();
    }
  }, [pollForStatus, detailsQuery]);

  const urlQuery = useCostBasisReportUrlQuery({
    ...(!isNil(reportId)
      ? {
          variables: {
            reportId: reportId,
          },
        }
      : {}),
    skip: isNil(status) || status !== ICostBasisReportStatus.Succeeded,
  });

  const finalLoading = reportQuery.loading || detailsQuery.loading || urlQuery.loading || pollForStatus;
  if (finalLoading) {
    return {
      loading: true,
      error: undefined,
      url: undefined,
    };
  }

  const optionalStatusError =
    status === ICostBasisReportStatus.Failed ? new Error('Failed to generate report') : undefined;
  const finalError = reportQuery.error ?? detailsQuery.error ?? urlQuery.error ?? optionalStatusError;
  if (!isNil(finalError)) {
    return {
      loading: false,
      error: finalError,
      url: undefined,
    };
  }

  return {
    loading: false,
    error: undefined,
    url: urlQuery.data?.portfolio.journal.costBasisReportUrl.url,
  };
};

const CostBasisReport = ({ assets }: { assets: Asset[] }): ReactElement => {
  const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null);
  const assetOptions = useMemo(() => createAssetSelectOptions(assets), [assets]);

  const [filtersState, setFiltersState] = useState<
    | {
        asset: Asset;
        requestId: string;
      }
    | undefined
  >(undefined);

  const { loading, error, url } = useDownloadReport(filtersState?.asset, filtersState?.requestId);

  useEffect(() => {
    if (!url) {
      return;
    }

    downloadFile(url);
  }, [url]);

  const width = 'xl2' as const;
  return (
    <Stack spacing={defaultRowSpacing}>
      <Typography>Select an asset to download its historical cost basis</Typography>
      <Stack direction="row" alignItems="end" spacing={defaultRowSpacing}>
        <StaticSingleAutocomplete<Asset>
          label="Asset"
          value={selectedAsset}
          width={width}
          disabled={loading}
          {...assetOptions}
          onChange={(value): void => setSelectedAsset(value)}
        />
        <GButton
          loading={loading}
          disabled={!selectedAsset || loading}
          onClick={() => {
            if (!selectedAsset) {
              throw new Error('Asset is not selected');
            }

            setFiltersState({
              asset: selectedAsset,
              requestId: v4(),
            });
          }}
        >
          Generate report
        </GButton>
      </Stack>
      <GraphQLErrorMessage error={error} />
    </Stack>
  );
};

const CostBasisReportContainer = (): ReactElement => {
  const { loaded, data, Fallback } = useDefaultErrorHandling(useCostBasisReportInputQuery());

  if (!loaded) {
    return <Fallback />;
  }

  return <CostBasisReport assets={getAssets(data.assets.feature)} />;
};

export default CostBasisReportContainer;
