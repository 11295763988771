import type { ReactElement } from 'react';
import FactorBetaChart from './result/FactorBetaChart.tsx';
import { Stack } from '@mui/joy';
import { defaultRowSpacing } from '../../StackSpacing.ts';
import HeaderBar from '../../technical/HeaderBar/HeaderBar.tsx';
import WarningMessage from 'components/technical/WarningMessage/WarningMessage.tsx';
import FactorHistoricalChart from './result/FactorHistoricalChart.tsx';
import type { PortfoliosResult } from './result/PortfolioResult.types.ts';
import FactorAverageChart from './result/FactorAverageChart.tsx';

const FactorRegressionResult = (props: { result: PortfoliosResult }): ReactElement => {
  return (
    <Stack gap={defaultRowSpacing}>
      {props.result.map((res) => (
        <Stack key={res.portfolioDefinition.id} rowGap={2}>
          <HeaderBar title={res.portfolioDefinition.name} />
          {res.message?.map((msg, i) => {
            return <WarningMessage key={i}>{msg}</WarningMessage>;
          })}
          <FactorBetaChart result={res} />
        </Stack>
      ))}
      <FactorHistoricalChart result={props.result} />
      <FactorAverageChart result={props.result} />
    </Stack>
  );
};

export default FactorRegressionResult;
