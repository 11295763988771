import type { FunctionComponent } from 'react';
import type { TableDividerLineProps } from './TableDividerLine.props';
import { Box } from '@mui/joy';

const TableDividerLine: FunctionComponent<TableDividerLineProps> = ({ color }) => {
  return (
    <Box
      sx={{
        boxSizing: 'content-box',
        backgroundClip: 'padding-box',
        height: '1px',
        border: '0.25rem solid transparent',
        borderLeft: 'none',
        borderRight: 'none',
        width: '100%',
        backgroundColor: color,
      }}
    />
  );
};

export default TableDividerLine;
