import type { FunctionComponent } from 'react';
import { useParams } from 'react-router';
import { TwoThirdsLayout } from 'components/TwoThirdsLayout.tsx';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import { type IAsset, usePrivateAssetPriceQuery } from 'generated/graphql';

import { PrivateAssetPriceSection } from './price/PrivateAssetPriceSection';
import { DOLLAR_LABEL, isPrivateAssetWithPrivateDetails, type PrivateAssetWithPrivateDetails } from '../../Asset.types';
import EventSectionContainer from '../event/EventSectionContainer';
import { NoAssetFoundErrorMessage } from '../../NoAssetFoundErrorMessage.tsx';
import isNil from 'lodash/fp/isNil';
import type { RecursivePartial } from '../../../../type.utils.ts';

const PrivateAssetPriceTab: FunctionComponent = () => {
  const { assetId } = useParams();

  const { loaded, Fallback, data } = useDefaultErrorHandling(
    usePrivateAssetPriceQuery({
      variables: {
        filters: {
          assetIds: [assetId!],
        },
      },
      skip: !assetId,
    }),
    {
      disableNoDataFallback: true,
    }
  );

  if (!loaded) {
    return <Fallback />;
  }

  const asset = (data.assets.list ?? [])
    .filter(
      (
        asset: RecursivePartial<IAsset>
      ): asset is PrivateAssetWithPrivateDetails & {
        priceAsset: { id: string; symbol: string };
      } => !isNil(asset.priceAsset) && isPrivateAssetWithPrivateDetails(asset)
    )
    .at(0) as
    | (PrivateAssetWithPrivateDetails & {
        priceAsset: { id: string; symbol: string };
      })
    | undefined;

  if (!asset) {
    return <NoAssetFoundErrorMessage />;
  }

  const dollar = data.assets.feature.find((asset) => asset.label === DOLLAR_LABEL)!;
  return (
    <TwoThirdsLayout
      left={<PrivateAssetPriceSection asset={asset} quoteAsset={dollar} />}
      right={<EventSectionContainer assetId={asset.id} />}
    />
  );
};

export default PrivateAssetPriceTab;
