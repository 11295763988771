import type { ReactNode } from 'react';
import type { Transaction } from './Transaction.types.ts';
import groupBy from 'lodash/fp/groupBy';
import sortBy from 'lodash/fp/sortBy';
import { Box, Stack } from '@mui/joy';
import { AssetIcon } from '../../market/asset/AssetLabel.tsx';
import bigNumMath from '../../../bigNumMath.ts';
import { bignumber } from 'mathjs';
import Typography from '@mui/joy/Typography';
import { formatCash, formatNumber } from '../../formatter.utils.ts';
import isNil from 'lodash/fp/isNil';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const maxLegs = 4;
const TransactionLegSummary = ({ legs }: { legs: Transaction['legs'] }): ReactNode => {
  if (legs.length === 0) {
    return null;
  }

  const assetIdToAsset = new Map<string, Transaction['legs'][number]['asset']>(
    legs.map((leg) => [leg.asset.id, leg.asset])
  );
  const assetIdToFlows = groupBy((leg) => leg.asset.id, legs);

  const sortedAssetsBySymbol = sortBy(([, asset]) => asset.symbol.toLowerCase(), Array.from(assetIdToAsset.entries()));

  return (
    <Stack rowGap={1} direction={'column'} alignItems={'flex-start'}>
      {sortedAssetsBySymbol.slice(0, maxLegs).map(([assetId, asset]) => {
        const assetFlows = assetIdToFlows[assetId];
        const amount = bigNumMath.sum(assetFlows.map((leg) => bignumber(leg.amount)));
        const marketValue = assetFlows.some((flow) => isNil(flow.unitValue))
          ? null
          : bigNumMath.sum(assetFlows.map((flow) => bignumber(flow.unitValue).mul(flow.amount)));
        return (
          <Stack key={assetId} direction={'row'} alignItems={'center'} flexWrap={'wrap'}>
            <Box mr={1}>
              <AssetIcon asset={asset} />
            </Box>
            <Typography level={'title-sm-emphasis'}>
              {amount.isPositive() ? '+' : amount.isNegative() ? '-' : ''}
              {formatNumber(amount.abs())}
            </Typography>
            <Box mr={0.25}>
              <Typography level={'title-sm'}>{asset.symbol}</Typography>
            </Box>
            {marketValue && <Typography level={'title-sm-emphasis'}>({formatCash(marketValue)})</Typography>}
          </Stack>
        );
      })}
      {sortedAssetsBySymbol.length > maxLegs && <MoreHorizIcon sx={{ marginInline: 'auto' }} />}
    </Stack>
  );
};

export default TransactionLegSummary;
