import type { Message } from './Message.ts';
import { MessageCard } from './MessageCard.tsx';
import {
  IAgentResultStatusType,
  IMessageRoleType,
  type IReaction,
  useUpdateReactionMutation,
} from '../../../../generated/graphql.tsx';
import ErrorMessage from '../../../technical/ErrorMessage.tsx';
import GErrorBoundary from '../../../technical/GErrorBoundary.tsx';
import { AgentResultDetailsView } from './AgentResultDetailsView.tsx';
import type { ReactElement } from 'react';
import { Box, Stack, Typography } from '@mui/joy';
import { isMsgPending } from './IsMsgPending.tsx';
import isNil from 'lodash/fp/isNil';
import { GMarkdown } from 'components/technical/GMarkdown.tsx';
import GIcon from 'components/technical/GIcon.tsx';
import { Reaction } from '../../../technical/Reaction.tsx';
import { useFeedback } from '../../../technical/Feedback/UseFeedback.tsx';

const sideMessageSpace = 4;
export const MessageView = (props: {
  msg: Message;
  anchorLastMessage: boolean;
  updateReaction: (reaction: IReaction | null) => void;
}): ReactElement => {
  const [updateReaction, { loading }] = useUpdateReactionMutation();
  const { showGraphqlError } = useFeedback();

  const handleUpdateReaction = async (newReaction: IReaction | null | undefined): Promise<void> => {
    props.updateReaction(newReaction ?? null);
    try {
      await updateReaction({
        variables: { input: { messageId: props.msg.id, reaction: newReaction ?? null } },
      });
    } catch (e) {
      props.updateReaction(props.msg.reaction ?? null);
      showGraphqlError(e);
    }
  };

  const roleDecorator =
    props.msg.role === IMessageRoleType.Assistant ? (
      <GIcon src={props.msg.agent!.icon} alt={props.msg.agent!.name} fontSize={'xl'} />
    ) : (
      'You'
    );
  if (isMsgPending(props.msg)) {
    return (
      <Box mr={sideMessageSpace}>
        <MessageCard role={IMessageRoleType.Assistant} roleDecorator={roleDecorator}>
          <Typography level={'title-md'}>Processing your request. Please wait a moment...</Typography>
          <Typography level={'body-xs'}>Wait a few seconds, in the meantime you can close the chatbot</Typography>
        </MessageCard>
      </Box>
    );
  }

  return (
    <Stack
      rowGap={1}
      sx={{
        [props.msg.role === IMessageRoleType.User ? 'ml' : 'mr']: sideMessageSpace,
      }}
    >
      <MessageCard
        role={props.msg.role}
        roleDecorator={roleDecorator}
        createdAt={props.msg.createdAt}
        anchored={props.msg.anchored && !props.anchorLastMessage}
      >
        {(props.msg.role === IMessageRoleType.User ||
          props.msg.agentResult?.status === IAgentResultStatusType.Success) && (
          <Typography
            level={'body-md'}
            sx={{
              overflowWrap: 'break-word',
            }}
          >
            <GMarkdown>{props.msg.content}</GMarkdown>
          </Typography>
        )}
        {props.msg.agentResult?.status === IAgentResultStatusType.Error && (
          <div>
            <ErrorMessage>{props.msg.content}</ErrorMessage>
            <Typography level={'body-xs2'}>Id : {props.msg.id}</Typography>
          </div>
        )}
        <GErrorBoundary
          onError={({ scope }) => {
            scope.setContext('Agent', {
              messageId: props.msg.id,
            });

            console.log(`Failed to render agent details for message: ${props.msg.id}`);
          }}
        >
          {props.msg.agentResult?.status === IAgentResultStatusType.Success && !isNil(props.msg.agentResult.type) && (
            <AgentResultDetailsView type={props.msg.agentResult.type} value={props.msg.agentResult.value!} />
          )}
        </GErrorBoundary>
      </MessageCard>
      {props.msg.role === IMessageRoleType.Assistant && (
        <Box ml={'auto'}>
          <Reaction
            loading={loading}
            onUpdated={handleUpdateReaction}
            reaction={props.msg.reaction}
            iconFontSize={'small'}
          />
        </Box>
      )}
    </Stack>
  );
};
