import type { ReactElement } from 'react';
import { Controller, type FieldValues, type Path, useFormContext } from 'react-hook-form';

import DateRangeInput, { type DateRangeInputProps } from '../inputs/date/DateRangeInput';
import type { DistributiveOmit } from '../../type.utils.ts';

export type FormDateRangeInputProps = DistributiveOmit<DateRangeInputProps, 'value' | 'onChange' | 'error' | 'type'>;

export const FormDateRangeInput = <T extends FieldValues>(
  props: FormDateRangeInputProps & {
    name: Path<T>;
  }
): ReactElement => {
  /* jscpd:ignore-start */
  const { control } = useFormContext<T>();

  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field: { value, onChange, onBlur, ref }, fieldState, formState }): ReactElement => (
        /* jscpd:ignore-end */
        <DateRangeInput
          {...props}
          ref={ref}
          value={value}
          error={fieldState.error?.message}
          onChange={onChange}
          onBlur={onBlur}
          minDate={props.minDate}
          maxDate={props.maxDate}
          disabled={(props.disabled ?? false) || formState.isSubmitting || formState.disabled}
        />
      )}
    />
  );
};
