import { Box } from '@mui/joy';
import isNil from 'lodash/fp/isNil';
import type { ReactElement } from 'react';
import { defaultHeaderActionProps } from 'components/technical/HeaderBar/DefaultHeaderActionProps.ts';
import { Gear } from 'components/technical/icons';
import DialogButton from 'components/technical/inputs/GButton/DialogButton';

import type { Aggregation } from './PositionAggregationsService.ts';
import EditSunburstAggregationsDialog from './EditSunburstAggregationsDialog.tsx';
import PortfolioSnapshotSunburst, { type PortfolioSnapshotSunburstAsset } from './PortfolioSnapshotSunburst.tsx';
import type { TFallback } from '../../technical/UseDefaultErrorHandling.tsx';
import type { BigNumber } from 'mathjs';
import type { SavedConfiguration } from './PortfolioSnapshotSunburstContainer.tsx';

type PortfolioSunburstWithAggregationsProps<TPosition extends PortfolioSnapshotSunburstAsset> = {
  aggregations: Aggregation<TPosition>[];
  positions: Array<TPosition>;
  savedAggregations: SavedConfiguration['aggregations'];
  onSetAggregationConfig?: (config: SavedConfiguration) => void;
  calculateBalance: (position: TPosition) => BigNumber | undefined;
  calculateExposure?: (position: TPosition) => BigNumber | undefined;
  loaded?: boolean;
  Fallback?: TFallback;
};

export function PortfolioSunburstWithAggregations<TPosition extends PortfolioSnapshotSunburstAsset>({
  loaded,
  positions,
  aggregations,
  Fallback,
  savedAggregations,
  onSetAggregationConfig,
  calculateBalance,
  calculateExposure,
}: PortfolioSunburstWithAggregationsProps<TPosition>): ReactElement {
  const aggregationsByCategory: Record<string, Aggregation<TPosition>> = Object.fromEntries(
    aggregations.map((group) => [group.category, group])
  );

  const savedAggregationCategories = new Set(savedAggregations.map((agg) => agg.category));
  const missingAggregations = aggregations.filter((agg) => !savedAggregationCategories.has(agg.category));

  // in case user creates new asset group during one browser session, we need to add it to saved in session storage aggregations
  const savedAggregationsToUse = [
    ...savedAggregations,
    ...missingAggregations.map((agg) => ({ category: agg.category, active: false })),
  ];

  const filteredAggOrder = savedAggregationsToUse
    .filter((agg) => agg.active && agg.category in aggregationsByCategory)
    .map((agg) => agg.category);

  return (
    <>
      {aggregations.length !== 0 && (
        <Box position="relative" mb="-2rem" textAlign="end" zIndex={1}>
          <DialogButton
            {...defaultHeaderActionProps}
            renderDialog={({ onClose }): ReactElement => (
              <EditSunburstAggregationsDialog
                onClose={onClose}
                activeAggregations={filteredAggOrder}
                availableAggregations={savedAggregationsToUse
                  .map((agg) => ({
                    category: agg.category,
                    label: aggregationsByCategory[agg.category]?.label,
                  }))
                  .filter((agg) => !isNil(agg.label))}
                onEdited={(selectedAggregations: SavedConfiguration['aggregations']): void => {
                  onSetAggregationConfig?.({ aggregations: selectedAggregations });
                  onClose();
                }}
              />
            )}
          >
            <Gear color="inherit" fontSize="xl2" />
          </DialogButton>
        </Box>
      )}
      <PortfolioSnapshotSunburst
        loaded={loaded}
        Fallback={Fallback}
        positions={positions}
        aggregationOrder={filteredAggOrder}
        aggregationsByCategory={aggregationsByCategory}
        calculateBalance={calculateBalance}
        calculateExposure={calculateExposure}
      />
    </>
  );
}

export default PortfolioSunburstWithAggregations;
