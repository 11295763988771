import React, { type ReactNode } from 'react';
import type { GColumnDef } from '../../../technical/GTable/GTable.props.ts';
import GTable from '../../../technical/GTable/GTable.tsx';
import type { CellContext } from '@tanstack/table-core';
import range from 'lodash/fp/range';

export const AgentResultTableView = React.memo(({ value }: { value: Record<string, unknown[]> }): ReactNode => {
  const columns = Object.keys(value).map(
    (colName): GColumnDef<{ id: number }> => ({
      header: colName,
      cell: (props: CellContext<{ id: number }, unknown>): ReactNode => {
        return value[colName][props.row.original.id] as string | number | null | undefined;
      },
    })
  );

  if (columns.length === 0) {
    return null;
  }

  const maxRow = Math.max(
    ...Object.values(value).flatMap((items) =>
      // insted of array we get object with string keys
      Object.keys(items).map((key) => Number.parseInt(key))
    )
  );

  const data = range(0, Number.isFinite(maxRow) ? maxRow + 1 : 0).map((idx) => ({ id: idx }));
  return <GTable data={data} columns={columns} />;
});
