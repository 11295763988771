import { Card } from '@mui/joy';
import dayjs from 'dayjs';
import groupBy from 'lodash/fp/groupBy';
import uniq from 'lodash/fp/uniq';
import { bignumber } from 'mathjs';
import type { FunctionComponent } from 'react';
import {
  type HighchartSeries,
  heatmapBorderColor,
  heatmapBorderWidth,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import HighChartsWrapper from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper';
import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import SectionColumn from 'components/technical/layout/Column/SectionColumn';
import { type IStrategyMonthlyReturnsQuery, useStrategyMonthlyReturnsQuery } from 'generated/graphql';
import { gradientColorsDarkMode, gradientColorsLightMode } from 'theme/colors';

import { useFinalColorScheme } from '../../../useFinalColorScheme';
import { formatPercentage } from '../../formatter.utils.ts';
import type * as Highcharts from 'highcharts';
const months = dayjs.months();

const calculateOptions = (data: IStrategyMonthlyReturnsQuery, colorScheme: 'light' | 'dark'): Highcharts.Options => {
  const years = uniq(data.strategy.monthlyReturns.map((row) => dayjs(row.time).year()))
    .sort()
    .reverse()
    .map((year) => year.toString());

  return {
    colorAxis: {
      min: -0.3,
      max: 0.3,
      stops: colorScheme === 'light' ? gradientColorsLightMode : gradientColorsDarkMode,
    },
    xAxis: {
      categories: months.map((month) => month),
      opposite: true,
      lineWidth: 0,
    },
    yAxis: {
      categories: years.map((year) => year),
      title: {
        text: null,
      },
    },
    tooltip: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
  };
};

const getValuesArray = (
  rowsToYears: Record<string, IStrategyMonthlyReturnsQuery['strategy']['monthlyReturns']>
): (number | null)[][] => {
  const valuesArray: (number | null)[][] = [];
  for (const rows of Object.values(rowsToYears)) {
    const rowsToMonths: (number | null)[] = months.map(() => null);
    for (const row of rows) {
      const currentRowMonthIndex = dayjs(row.time).month();
      rowsToMonths[currentRowMonthIndex] = bignumber(row.value).toNumber();
    }
    valuesArray.push(rowsToMonths);
  }
  return valuesArray.reverse();
};

const calculateChartData = (data: IStrategyMonthlyReturnsQuery): HighchartSeries[] => {
  const rowsToYears = groupBy((row) => dayjs(row.time).year(), data.strategy.monthlyReturns);

  const valuesArray = getValuesArray(rowsToYears);

  const chartData: HighchartSeries[] = [
    {
      data: valuesArray.flatMap((monthlyValues, yearIndex) => {
        return months.map((_, monthIndex) => {
          return {
            x: monthIndex,
            value: monthlyValues[monthIndex] ?? 0,
            y: yearIndex,
            textValue: formatPercentage(monthlyValues[monthIndex]),
          };
        });
      }),

      type: 'heatmap',
      dataLabels: {
        enabled: true,
        format: '{point.textValue}',
      },
      borderWidth: heatmapBorderWidth,
      borderColor: heatmapBorderColor,
    },
  ];

  return chartData;
};

const StrategyMonthlyReturnsContainer: FunctionComponent<{ strategy: Label }> = ({ strategy }) => {
  const queryOutput = useStrategyMonthlyReturnsQuery({ variables: { label: strategy } });
  const colorScheme = useFinalColorScheme();

  return (
    <SectionColumn>
      <HeaderBar title="Monthly returns" />
      <Card>
        <HighChartsWrapper<IStrategyMonthlyReturnsQuery>
          calculateChartData={calculateChartData}
          calculateOptions={(data: IStrategyMonthlyReturnsQuery): Highcharts.Options =>
            calculateOptions(data, colorScheme)
          }
          {...queryOutput}
        />
      </Card>
    </SectionColumn>
  );
};

export default StrategyMonthlyReturnsContainer;
