import type { ReactElement } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import type { UseFieldArrayRemove } from 'react-hook-form';

import type { FormInputFields } from './CreateInvestment.validation';
import { ScheduleRow } from './CreateInvestmentScheduleRow';

export const CreateInvestmentScheduleList = (props: {
  schedules: Record<'id', string>[];
  methods: UseFormReturn<FormInputFields>;
  remove: UseFieldArrayRemove;
}): ReactElement => {
  return (
    <>
      {props.schedules.map((schedule, index) => (
        <ScheduleRow
          key={schedule.id}
          schedule={schedule}
          index={index}
          remove={(): void => props.remove(index)}
          methods={props.methods}
        />
      ))}
    </>
  );
};
