import { Stack } from '@mui/joy';
import GAgGridPreserveState from 'components/technical/grids/GAgGridPreserveState.tsx';
import type { FunctionComponent } from 'react';
import { UserSettings } from 'components/management/UserSettings.types.ts';
import { useSubAccountAssetFilters } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling.tsx';
import { type ICounterpartyRiskReportQuery, useCounterpartyRiskReportQuery } from 'generated/graphql.tsx';

import {
  accountColumn,
  amountColumn,
  balanceColumn,
  exposureColumn,
  nameColumn,
  sideColumn,
  subAccountColumn,
  subFundGroupColumn,
  symbolColumn,
  typeColumn,
  underlyingAssetColumn,
  venueColumn,
} from '../../technical/grids/SharedReportColumns.tsx';
import { mapAccountAndDimensionToSubFund } from 'components/bookkeeping/report/Report.utils.tsx';

const GROUP_COLUMN_MIN_WIDTH = 220;
type Position = ICounterpartyRiskReportQuery['portfolio']['positions']['positions'][number];

const CounterpartyRiskReport: FunctionComponent = () => {
  const { subAccountAssetFilters } = useSubAccountAssetFilters();
  const counterpartyReportQueryResult = useDefaultErrorHandling(
    useCounterpartyRiskReportQuery({
      variables: {
        subAccountAssetFilters,
      },
    })
  );

  if (!counterpartyReportQueryResult.loaded) {
    return <counterpartyReportQueryResult.Fallback />;
  }
  const subFunds = counterpartyReportQueryResult.data.portfolio.subFunds.list;
  const { accountAndDimensionToSubFund, subFundDimensions } = mapAccountAndDimensionToSubFund(subFunds);

  return (
    <Stack spacing={1.5} height="100%">
      <GAgGridPreserveState<Position>
        userSettingsKey={UserSettings.CounterpartyRiskReport}
        rowData={counterpartyReportQueryResult.data.portfolio.positions.positions}
        sideBar={{
          toolPanels: ['columns', 'filters'],
        }}
        enableCharts
        enableRangeSelection
        autoGroupColumnDef={{
          minWidth: GROUP_COLUMN_MIN_WIDTH,
        }}
        defaultColDef={{
          resizable: true,
          sortable: true,
          filter: true,
        }}
        columnDefs={[
          underlyingAssetColumn<Position>({
            initialRowGroup: false,
            initialRowGroupIndex: undefined,
          }),
          nameColumn(),
          symbolColumn({
            initialRowGroup: true,
            initialRowGroupIndex: 1,
          }),
          sideColumn({ initialHide: true }),
          typeColumn({ initialHide: true }),
          {
            headerName: 'Account Details',
            colId: 'account-details',
            marryChildren: true,
            children: [accountColumn(), subAccountColumn(), venueColumn()],
          },
          {
            headerName: 'Sub-funds',
            colId: 'sub-funds',
            marryChildren: true,
            children: subFundDimensions.map((subFundDimension) =>
              subFundGroupColumn(subFundDimension, accountAndDimensionToSubFund)
            ),
          },
          {
            headerName: 'Current positions',
            colId: 'currentPositions',
            marryChildren: true,
            children: [
              balanceColumn(),
              exposureColumn({ sideAware: true, initialHide: true }),
              amountColumn({ sideAware: true, initialHide: true }),
              exposureColumn({ sideAware: false, initialHide: true }),
              amountColumn({ sideAware: false, initialHide: true }),
            ],
          },
        ]}
      />
    </Stack>
  );
};

export default CounterpartyRiskReport;
