import { Stack } from '@mui/joy';
import { type CellContext, type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import capitalize from 'lodash/fp/capitalize';
import isNil from 'lodash/fp/isNil';
import sortBy from 'lodash/fp/sortBy';
import { bignumber } from 'mathjs';
import type { FunctionComponent, ReactElement, ReactNode } from 'react';
import { DateTimeFormat, formatDate, formatPercentage } from 'components/formatter.utils';
import GTable from 'components/technical/GTable/GTable';

import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import SectionColumn from 'components/technical/layout/Column/SectionColumn';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import { useUserTimezone } from 'components/technical/UseUserTimezone.tsx';
import GValueChange from 'components/technical/ValueChange/GValueChange';
import { type IAsset, type IFee, type IPosition, IPositionStatus, useStrategyPositionsQuery } from 'generated/graphql';

import type { PublicAsset } from '../../market/asset/Asset.types';
import AssetLabel from '../../market/asset/AssetLabel';
import AssetValueView from '../../market/asset/AssetValueView';
import VenueLabel from '../../venue/VenueLabel.tsx';

type TradeRow = Omit<
  {
    [key in keyof IPosition]: IPosition[key] extends IAsset ? PublicAsset : IPosition[key];
  },
  'fee'
> & { fee: Omit<IFee, 'asset'> & { asset: PublicAsset } };

const StrategyTradeList: FunctionComponent<{ strategy: Label }> = ({ strategy }): ReactElement => {
  const { data, loaded, Fallback } = useDefaultErrorHandling(
    useStrategyPositionsQuery({ variables: { label: strategy } })
  );

  const timezone = useUserTimezone();

  if (!loaded) {
    return <Fallback />;
  }

  const sortedData = sortBy(
    (row) => [dayjs(row.openAt).toDate(), row.status],
    data.strategy.positionList as TradeRow[]
  ).reverse();

  const columnHelper = createColumnHelper<TradeRow>();
  const columns: ColumnDef<TradeRow>[] = [
    columnHelper.display({
      header: 'Currency pair',
      cell: (props: CellContext<TradeRow, unknown>): ReactElement => {
        const baseAsset = props.row.original.base;
        const quoteAsset = props.row.original.quote;
        return (
          <Stack direction="row" spacing={0}>
            <AssetLabel asset={baseAsset} format="short" link={false} />
            &nbsp;/&nbsp;
            <AssetLabel asset={quoteAsset} format="short" link={false} />
          </Stack>
        );
      },
    }),
    {
      header: 'Opened at',
      cell: (props: CellContext<TradeRow, unknown>) =>
        formatDate(props.row.original.openAt, DateTimeFormat.DateTime, timezone),
      accessorFn: ({ openAt }) => dayjs(openAt).toDate(),
    },
    {
      header: 'Closed at',
      cell: (props: CellContext<TradeRow, unknown>) =>
        formatDate(props.row.original.closedAt, DateTimeFormat.DateTime, timezone),
      accessorFn: ({ closedAt }) => (closedAt ? dayjs(closedAt).toDate() : null),
    },
    columnHelper.display({
      header: 'Venue',
      cell: (props: CellContext<TradeRow, unknown>) => {
        const venue = props.row.original.exchange ?? '';
        return <VenueLabel format="long" venue={venue} />;
      },
    }),
    {
      header: 'Amount',
      cell: (props: CellContext<TradeRow, unknown>): ReactElement => {
        const { amount, base } = props.row.original;
        return <AssetValueView value={bignumber(amount)} asset={base} link={false} />;
      },
    },
    {
      header: 'Side',
      accessorFn: ({ side }): string => {
        return capitalize(side);
      },
    },
    {
      header: 'Status',
      accessorFn: ({ status }): string => {
        return capitalize(status);
      },
    },
    {
      header: 'Open price',
      cell: (props: CellContext<TradeRow, unknown>): ReactElement => {
        const { openPrice, quote } = props.row.original;
        return <AssetValueView value={bignumber(openPrice)} asset={quote} link={false} />;
      },
      accessorFn: ({ openPrice }) => openPrice,
    },
    {
      header: 'Close price',
      cell: (props: CellContext<TradeRow, unknown>): ReactElement => {
        const { closePrice, quote, status } = props.row.original;
        return status === IPositionStatus.Open ? (
          <></>
        ) : (
          <AssetValueView value={bignumber(closePrice)} asset={quote} link={false} />
        );
      },
      accessorFn: ({ closePrice }) => closePrice,
    },
    {
      header: 'Fee',
      cell: (props: CellContext<TradeRow, unknown>): ReactElement => {
        const { fee } = props.row.original;
        if (isNil(fee)) {
          return <></>;
        }
        return <AssetValueView value={bignumber(fee.amount)} asset={fee.asset} link={false} />;
      },
    },

    {
      header: 'Profit & loss',
      cell: (props: CellContext<TradeRow, unknown>): ReactNode => {
        const { netQuoteProfit, quote, netReturn } = props.row.original;
        return (
          <>
            <GValueChange
              level="body-xs2"
              text={
                <>
                  <AssetValueView asset={quote} link={false} value={bignumber(netQuoteProfit)} /> (
                  {formatPercentage(netReturn)})
                </>
              }
              indicatorVariants="color"
              value={netQuoteProfit}
            />
          </>
        );
      },
      accessorFn: ({ netQuoteProfit }) => netQuoteProfit,
    },
  ];

  return (
    <SectionColumn>
      <HeaderBar title="Trade list" />
      <GTable columns={columns} data={sortedData} />
    </SectionColumn>
  );
};
export default StrategyTradeList;
