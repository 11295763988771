import type { Dayjs } from 'dayjs';
import isNil from 'lodash/fp/isNil';
import {
  createContext,
  type FunctionComponent,
  type InputHTMLAttributes,
  type ReactElement,
  type ReactNode,
  useContext,
  useState,
} from 'react';
import DatePicker from 'react-datepicker';
import CalendarWrapper from './CalendarWrapper';
import {
  convertDateInUtcToLocalWithoutOffset,
  convertLocalDateToUTCWithoutOffset,
  getSecondsFromTime,
  isValidTime,
} from '../../../date.utils';
import { DateInputWrapper } from './DateInputWrapper.tsx';

interface TimeContextValue {
  time: Dayjs | null;
  setTime: (val: Dayjs | null) => void;
}

const TimeContext = createContext<TimeContextValue>({
  time: null,
  setTime: (): void => {
    // pass
  },
});

interface DateContextValue {
  date: Dayjs | null;
  onConfirm: (val: Dayjs | null) => void;
  onCancel: () => void;
  showTime: boolean;
}

const DateContext = createContext<DateContextValue>({
  date: null,
  showTime: false,
  onConfirm: (): void => {
    // pass
  },
  onCancel: () => {
    // pass
  },
});

const TimeInput = (): ReactElement => {
  const { time, setTime } = useContext(TimeContext);
  return <DateInputWrapper<'time'> label="Time" format={'time'} width="normal" value={time} onChange={setTime} />;
};

const DatePickerContainer = ({ children }: { children: ReactNode }): ReactNode => {
  const { date, onConfirm, onCancel, showTime } = useContext(DateContext);
  const { time } = useContext(TimeContext);
  const isTimeValid = !showTime || (!isNil(time) && isValidTime(time));
  return (
    <CalendarWrapper
      confirmationDisabled={isNil(date) || !isTimeValid}
      onConfirm={(): void => {
        if (isNil(date)) {
          return;
        }

        if (showTime && isNil(time)) {
          return;
        }

        if (showTime) {
          onConfirm(date.startOf('day').add(getSecondsFromTime(time!), 'seconds'));
        } else {
          onConfirm(date);
        }
      }}
      onCancel={onCancel}
    >
      {children}
    </CalendarWrapper>
  );
};

type DatePickerProps = {
  defaultValue: Dayjs | null;
  onConfirm: (val: Dayjs | null) => void;
  onCancel: () => void;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  disabled?: boolean;
  time?: boolean;
} & Pick<InputHTMLAttributes<HTMLInputElement>, 'onFocus' | 'onBlur'>;

/* 
we try to keep all dates in utc, but DatePicker input/output date are local, we need to convert it to utc 
and we need to convert component inputs maxDate, minDate, selected from utc to local for DatePicker
*/
const DateInputCalendar: FunctionComponent<DatePickerProps> = ({
  defaultValue,
  onConfirm,
  onCancel,
  minDate,
  maxDate,
  disabled,
  time: showTime,
}) => {
  const [date, setDate] = useState<Dayjs | null>(isNil(defaultValue) ? null : defaultValue);
  const [time, setTime] = useState<Dayjs | null>(defaultValue);

  return (
    <TimeContext.Provider value={{ setTime, time }}>
      <DateContext.Provider
        value={{
          date,
          onConfirm,
          onCancel,
          showTime: showTime ?? false,
        }}
      >
        <DatePicker
          disabledKeyboardNavigation
          minDate={convertDateInUtcToLocalWithoutOffset(minDate?.utc())?.toDate()}
          maxDate={convertDateInUtcToLocalWithoutOffset(maxDate?.utc())?.toDate()}
          disabled={disabled}
          selected={isNil(date) || !date.isValid() ? undefined : convertDateInUtcToLocalWithoutOffset(date).toDate()}
          inline
          onChange={(newDateLocal): void => {
            setDate(convertLocalDateToUTCWithoutOffset(newDateLocal));
          }}
          calendarContainer={DatePickerContainer}
          showTimeInput={showTime}
          customTimeInput={showTime ? <TimeInput /> : undefined}
        />
      </DateContext.Provider>
    </TimeContext.Provider>
  );
};

export default DateInputCalendar;
