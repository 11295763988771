import { Card, Stack, Typography } from '@mui/joy';
import IconButton from '@mui/joy/IconButton';
import dayjs from 'dayjs';
import isNil from 'lodash/fp/isNil';
import type { ReactElement } from 'react';
import { useNavigate } from 'react-router';
import { Close } from 'components/technical/icons';
import { INotificationStatus } from 'generated/graphql';

import { type NotificationCategory, notificationData, unknownCategoryData } from './Notification.data';
import type { NotificationItem } from './NotificationItem.ts';
import { truncateText } from '../../text.styles.ts';

const NotificationTile = ({
  notification,
  onClose,
  showDot = true,
  link,
  variant,
}: {
  notification: Omit<NotificationItem, 'requestId'> & { requestId?: NotificationItem['requestId'] };
  onClose?: () => void;
  showDot?: boolean;
  link?: string;
  variant: 'plain' | 'outlined';
}): ReactElement => {
  const navigate = useNavigate();
  const isClosable = !isNil(onClose);

  const categoryData = notificationData[notification.type as NotificationCategory] ?? unknownCategoryData;
  const Icon = categoryData.icon;

  const isNotRead = notification.status !== INotificationStatus.Read;

  return (
    <Card
      onClick={(): void => {
        onClose?.();
        if (link) {
          navigate(link);
        }
      }}
      size={'sm'}
      variant={variant}
      sx={{
        width: '100%',
      }}
    >
      <Stack direction="row" spacing={1}>
        {showDot && (
          <Typography
            fontSize="x-large"
            lineHeight={0}
            sx={{
              alignSelf: 'center',
              minWidth: '0.75rem',
            }}
          >
            {isNotRead ? '•' : ''}
          </Typography>
        )}
        <Stack
          spacing={0.5}
          sx={{
            minWidth: 0,
            ...(isNotRead && {
              fontWeight: 'fontWeight.xl',
            }),
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Icon fontSize="md" color="primary" />
            <Typography color="primary" sx={truncateText}>
              {notification.title}
            </Typography>
            <Typography fontSize="x-large" lineHeight={0}>
              •
            </Typography>
            <Typography
              textColor="text.tertiary"
              sx={{
                flexShrink: 0,
                textAlign: 'end',
              }}
            >
              {dayjs(notification.placedAt).fromNow()}
            </Typography>
            {isClosable && (
              <IconButton
                size={'sm'}
                sx={{
                  marginLeft: 'auto',
                }}
                onClick={(e): void => {
                  e.stopPropagation();
                  onClose();
                }}
                variant="plain"
              >
                <Close />
              </IconButton>
            )}
          </Stack>
          <Typography
            level="body-xs"
            sx={{
              webkitLineClamp: 4,
              wordBreak: 'break-word',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              webkitBoxOrient: 'vertical',
            }}
          >
            {notification.body ?? ''}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};

export default NotificationTile;
