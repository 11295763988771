import { sortBy } from 'lodash/fp';

import type { MarketFiltersState, DrawerFiltersData } from './Filters.types';

// we want to scale down metrcis, but leave them zero based - log10(metricValue+10) >= 0
const LOG_SCALE_OFFSET = 10;
export const pow10ScaleUp = (value: number): number => 10 ** value - LOG_SCALE_OFFSET;
export const log10ScaleDown = (value: number): number => Math.log10(value + LOG_SCALE_OFFSET);

/** transform values to acceptable for form, {min, max} objects to tuples, and scale values down */
export function scaleFilterValuesDown(filterState: MarketFiltersState): DrawerFiltersData {
  return {
    totalValueLocked: minMaxObjectToArrayScaleDown(filterState.totalValueLocked),
    marketCap: minMaxObjectToArrayScaleDown(filterState.marketCap),
    liquidity: minMaxObjectToArrayScaleDown(filterState.liquidity),
  };
}

export const minMaxObjectToArrayScaleDown = (
  minMax: {
    min: number;
    max: number;
  } | null
): [number, number] | null => {
  if (!minMax) {
    return null;
  }

  return [log10ScaleDown(minMax.min), log10ScaleDown(minMax.max)];
};

export const arrayToMinMaxObjectScaleUp = (values: [number, number] | null): { min: number; max: number } | null => {
  if (!values) {
    return null;
  }

  const sorted = sortBy((el) => el, values);
  return {
    min: pow10ScaleUp(sorted[0]),
    max: pow10ScaleUp(sorted[1]),
  };
};
