import { Stack } from '@mui/joy';
import { Checkmark, Close, WarningRound } from '../icons';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Card from '@mui/joy/Card';
import { useSnackbar } from 'notistack';
import type { ReactElement, ReactNode } from 'react';

type Variant = 'danger' | 'success' | 'info';

const iconProps = {
  color: 'inherit',
  fontSize: 'xl',
} as const;

const variantToIcon: Record<Variant, ReactElement | null> = {
  success: <Checkmark {...iconProps} />,
  danger: <WarningRound {...iconProps} />,
  info: null,
};

const variantToTitle: Record<Variant, string> = {
  success: 'Success',
  danger: 'Error',
  info: 'Info',
};

const variantToColor: Record<Variant, 'neutral' | 'danger'> = {
  success: 'neutral',
  danger: 'danger',
  info: 'neutral',
};

const Notification = ({
  children,
  notificationId,
  variant,
}: { children: ReactNode; notificationId: string; variant: Variant }) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <Card variant={'solid'} size={'md'} color={variantToColor[variant]} invertedColors>
      <Stack direction={'row'} alignItems={'center'} gap={1.5}>
        {variantToIcon[variant]}
        <Stack direction={'column'}>
          <Typography level={'title-md'}>{variantToTitle[variant]}</Typography>
          <Typography level={'body-sm'} textColor={'inherit'}>
            {children}
          </Typography>
        </Stack>
        <IconButton
          size={'sm'}
          onClick={(e): void => {
            e.stopPropagation();
            closeSnackbar(notificationId);
          }}
        >
          <Close fontSize={'sm'} />
        </IconButton>
      </Stack>
    </Card>
  );
};

export default Notification;
