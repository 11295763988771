import AssetUniverseStep from './AssetUniverseStep.tsx';
import type { AssetOptimizerInputFields } from '../AssetOptimizer.validation.ts';
import type { StepConfig } from '../../../../../technical/wizard/StepConfig.ts';
import type { NotVerifiedAsset } from '../../../../../market/asset/AssetLabelService.ts';

export const config = (
  assets: (NotVerifiedAsset & { label: string; id: string })[],
  assetIdToClusterToGroup: Record<string, Record<string, string>>,
  goToNextStep: () => void
): StepConfig<AssetOptimizerInputFields> => ({
  label: 'Asset universe',
  element: (
    <AssetUniverseStep assets={assets} goToNextStep={goToNextStep} assetIdToClusterToGroup={assetIdToClusterToGroup} />
  ),
  fields: ['universe'],
});
