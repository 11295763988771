import { Skeleton, Stack } from '@mui/joy';
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import range from 'lodash/fp/range';
import type { ReactElement, ReactNode } from 'react';

import GroupedTable from './GroupedTable';
import type { GroupedTableRow } from './GroupedTable.props';
import GTableCell from './GTableCell/GTableCell';

const fallbackRows = 5;

type EmptyElement = Record<string, never>;

const GTableSkeleton = ({ tableHeader, headers }: { tableHeader?: ReactNode; headers: ReactNode[] }): ReactElement => {
  const columnHelper = createColumnHelper<GroupedTableRow<never, EmptyElement>>();
  const columns: ColumnDef<GroupedTableRow<never, EmptyElement>>[] = headers.map((header, i) =>
    columnHelper.display({
      id: i.toString(),
      header: () => header,
      cell: () => (
        <GTableCell first={i === 0} last={i === headers.length - 1}>
          <Skeleton animation={false} height="1em" width="100%" />
        </GTableCell>
      ),
    })
  );

  const data: EmptyElement[] = range(0, fallbackRows).map(() => ({}));

  return (
    <Stack spacing={1.5}>
      {tableHeader}
      <GroupedTable<never, EmptyElement> columns={columns} data={data} />
    </Stack>
  );
};

export default GTableSkeleton;
