import { createContext } from 'react';

import { type NewsExplorerFilterValues, defaultFilterState } from './NewsExplorerService';

export const NewsExplorerFilterContext = createContext<{
  state: NewsExplorerFilterValues;
  setState: (state: NewsExplorerFilterValues) => void;
  isFilteredByNewsExplorerFilter: boolean;
}>({
  state: defaultFilterState,
  setState: () => {
    throw new Error('News explorer filters not initialized');
  },
  isFilteredByNewsExplorerFilter: false,
});
