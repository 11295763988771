import type { ReactElement } from 'react';
import { Controller, type FieldValues, type Path, useFormContext } from 'react-hook-form';

import GInput from '../inputs/GInput/GInput';
import type { GInputProps } from '../inputs/GInput/GInput.props';
import type { DistributiveOmit } from '../../type.utils.ts';

function FormInput<T extends FieldValues>(
  props: DistributiveOmit<GInputProps, 'value' | 'error' | 'name'> & {
    name: Path<T>;
  }
): ReactElement {
  /* jscpd:ignore-start */
  const { control } = useFormContext();

  return (
    <Controller
      name={props.name}
      control={control}
      /* jscpd:ignore-end */
      render={({ field: { value, onChange, onBlur, ref }, fieldState, formState }): ReactElement => (
        <GInput
          {...props}
          ref={ref}
          value={value ?? ''}
          error={fieldState.error?.message}
          onChange={(text): void => {
            onChange(text);
            props.onChange?.(text);
          }}
          onBlur={(e): void => {
            props?.onBlur?.(e);
            onBlur();
          }}
          disabled={(props.disabled ?? false) || formState.isSubmitting || formState.disabled}
        />
      )}
    />
  );
}

export default FormInput;
