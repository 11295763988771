import { Chip, Tooltip, Stack } from '@mui/joy';
import type { ReactElement } from 'react';

const TAG_MAX_WIDTH = 130;

const TransactionTagsView = ({ tags }: { tags: string[] }): ReactElement => {
  return (
    <Stack spacing={0.5} alignItems="flex-start">
      {tags.map((tag) => (
        <Tooltip title={tag} key={tag} placement="left">
          <Chip
            key={tag}
            sx={{
              cursor: 'default',
              maxWidth: TAG_MAX_WIDTH,
            }}
            color="primary"
          >
            {tag}
          </Chip>
        </Tooltip>
      ))}
    </Stack>
  );
};

export default TransactionTagsView;
