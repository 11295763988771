import isNil from 'lodash/fp/isNil';
import React, { type ForwardedRef, type ReactElement } from 'react';

import { fixedForwardRef } from 'components/technical/fixedForwardRef.ts';
import { createAutocompleteProps } from './CreateAutocompleteProps.tsx';
import GSingleAutocomplete from './GSingleAutocomplete';
import type { StaticAutocompleteOption, StaticSingleAutocompleteProps } from './StaticSingleAutocomplete.props';
import isEqual from 'lodash/fp/isEqual';

function StaticSingleAutocomplete<TValue>(
  props: StaticSingleAutocompleteProps<TValue>,
  ref: ForwardedRef<HTMLElement>
): ReactElement {
  const matchingValue = props.options.find((opt) => {
    if (isNil(props.value)) {
      return false;
    }

    if (props.isValueEqual) {
      return props.isValueEqual(opt.value, props.value);
    }

    return isEqual(opt.value, props.value);
  });

  const restProps = {
    ...createAutocompleteProps(props),
    getOptionLabelIcon: (value: StaticAutocompleteOption<TValue>): ReactElement | undefined => value.icon,
  };

  return (
    <GSingleAutocomplete<StaticAutocompleteOption<TValue>>
      {...props}
      {...restProps}
      value={matchingValue ?? undefined}
      onChange={(option: StaticAutocompleteOption<TValue> | null): void => {
        const finalOpt = option as StaticAutocompleteOption<TValue> | undefined | null;
        props.onChange?.(finalOpt ? finalOpt.value : null);
      }}
      ref={ref}
    />
  );
}

const ForwardedStaticSingleAutocomplete = fixedForwardRef(StaticSingleAutocomplete);
const ForwardedStaticSingleAutocompleteMemoized = React.memo(
  ForwardedStaticSingleAutocomplete
) as typeof ForwardedStaticSingleAutocomplete;

export default ForwardedStaticSingleAutocompleteMemoized;
