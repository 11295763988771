import type { SwitchProps } from '@mui/joy';
import type { ReactElement, ReactNode } from 'react';
import { Controller, type FieldValues, type Path, useFormContext } from 'react-hook-form';
import GSwitch from '../inputs/GSwitch.tsx';

function FormSwitch<T extends FieldValues>(
  props: Omit<SwitchProps, 'value' | 'onChange' | 'error' | 'onBlur' | 'name'> & {
    name: Path<T>;
    label: ReactNode;
    matchInputWithLabelHeight?: boolean;
    preLabel?: ReactNode;
  }
): ReactElement {
  /* jscpd:ignore-start */
  const { control } = useFormContext();

  return (
    <Controller
      name={props.name}
      control={control}
      /* jscpd:ignore-end */
      render={({ field, formState }): ReactElement => {
        return <GSwitch {...props} {...field} disabled={(props.disabled ?? false) || formState.isSubmitting} />;
      }}
    />
  );
}

export default FormSwitch;
