import groupBy from 'lodash/fp/groupBy';
import isNil from 'lodash/fp/isNil';
import sortBy from 'lodash/fp/sortBy';

import type { StaticAutocompleteOption } from 'components/technical/inputs/Autocomplete/StaticSingleAutocomplete.props';
import { AssetIcon } from './AssetLabel';
import type { AssetLabelInput, NotVerifiedAsset } from './AssetLabelService.ts';
import AssetSelectOption, { HEIGHT_PX } from './AssetSelectOption/AssetSelectOption';
import { IconVariant } from './cryptocurrencies/CryptocurrenciesData';
import type { Group } from './Group.utils';
import bigNumMath from '../../../bigNumMath';
import {
  AssetPaginatedDocument,
  type IAsset,
  type IAssetByNameFilters,
  type IAssetPaginatedQuery,
  IAssetType,
  type IDerivativeType,
} from '../../../generated/graphql';
import { getLightChartColor, getRegularChartColor, neutralPlainDisabledColor } from '../../../theme/colors';
import { formatLabelToName } from '../../formatter.utils';
import type { AutocompleteProps } from '@mui/joy';
import type { HTMLAttributes, ReactNode } from 'react';
import { createCancellableQuery } from '../../technical/apolloClientUtils.ts';
import { useApolloClient } from '@apollo/client';
import { GENIE_VENUE } from '../../venue/VenueData.tsx';
import { logWarnOnce } from '../../log.utils.ts';
import type { BigNumber } from 'mathjs';

export type AssetCategoryInput = NotVerifiedAsset;

type AssetCategory = 'Spot' | 'Derivative' | 'Stock' | 'Index' | 'Bond' | 'Funds' | 'Factor' | 'Custom' | 'Other';

const categoryOrder: AssetCategory[] = [
  'Spot',
  'Derivative',
  'Stock',
  'Index',
  'Bond',
  'Funds',
  'Factor',
  'Custom',
  'Other',
];

const assetCategoryOrder: Record<string, number> = Object.fromEntries(
  categoryOrder.map((category, index) => [category, index])
);

export const assetCategorySortingKey = (asset: AssetCategoryInput): unknown[] => [
  assetCategoryOrder[getAssetCategory(asset)],
  asset.symbol,
];

export const getAssetCategory = (asset: AssetCategoryInput): AssetCategory | '' => {
  if (!asset.type) {
    logWarnOnce('Unknown asset type for asset', asset);
    return '';
  }

  if (asset.type === IAssetType.Exchange) {
    return 'Spot';
  }

  if ([IAssetType.Private, IAssetType.Unvested].includes(asset.type)) {
    return 'Custom';
  }

  if (asset.type === IAssetType.Derivative) {
    return 'Derivative';
  }

  if (asset.type !== IAssetType.Public) {
    return 'Custom';
  }

  const [prefix] = (asset.label ?? '').split(':', 2);
  switch (prefix) {
    case 'stc':
      return 'Stock';
    case 'spt':
      return 'Spot';
    case 'etf':
      return 'Funds';
    case 'ind':
      return 'Index';
    case 'fct':
      return 'Factor';
    case 'bnd':
      return 'Bond';
    default:
      return '';
  }
};

export type AssetSelectOptionValue = (NotVerifiedAsset & { id: string }) | AssetLabelInput;

export const createAssetAutocompleteProps = <TValue extends AssetSelectOptionValue>(): {
  getOptionLabel: (value: TValue) => string;
  getOptionKey: (value: TValue) => string;
  renderOption: NonNullable<AutocompleteProps<TValue, false, false, false>['renderOption']>;
  isOptionEqualToValue: NonNullable<AutocompleteProps<TValue, false, false, false>['isOptionEqualToValue']>;
} => ({
  getOptionLabel: (asset: TValue): string => {
    if (!asset.symbol) {
      logWarnOnce('Missing symbol for asset', asset);
      return '';
    }

    return asset.symbol;
  },
  getOptionKey: (asset: TValue): string => {
    return asset.id;
  },
  renderOption: (_props: HTMLAttributes<HTMLLIElement>, asset: TValue): ReactNode => (
    <AssetSelectOption asset={asset} />
  ),
  isOptionEqualToValue: (a: TValue | null | undefined, b: TValue | null | undefined): boolean => {
    if (a && b) {
      return a.id === b.id;
    }

    return !a && !b;
  },
});

export const useAccountAssetPaginatedOptions = (
  filters: Omit<IAssetByNameFilters, 'query' | 'exchanges' | 'assetTypes'> & {
    account?: { venue?: { label?: string | null } | null } | null;
  } = {}
): {
  getOptions: (props: { input: string; signal: AbortSignal; paginationState: unknown }) => Promise<{
    hasMoreResults: boolean;
    paginationState?: string;
    data: {
      id: string;
      symbol: string;
      type: IAssetType;
      derivativeDetails?: {
        derivativeType: IDerivativeType;
      } | null;
    }[];
  }>;
} => {
  const { account, ...restFilters } = filters;
  const venue = account?.venue?.label;
  const allowUnvestedAssets = isNil(venue) || venue === GENIE_VENUE;

  return useAssetPaginatedOptions({
    ...restFilters,
    exchanges: venue ? [venue] : null,
    assetTypes: allowUnvestedAssets
      ? null
      : [IAssetType.Public, IAssetType.Derivative, IAssetType.Exchange, IAssetType.Private],
  });
};

export const useAssetPaginatedOptions = (
  filters: Omit<IAssetByNameFilters, 'query'> = {}
): {
  getOptions: (props: { input: string; signal: AbortSignal; paginationState: unknown }) => Promise<{
    hasMoreResults: boolean;
    paginationState?: string;
    data: {
      id: string;
      symbol: string;
      type: IAssetType;
      derivativeDetails?: {
        derivativeType: IDerivativeType;
      } | null;
    }[];
  }>;
} => {
  const apolloClient = useApolloClient();
  return {
    getOptions: async ({
      input,
      signal,
      paginationState,
    }): Promise<{
      hasMoreResults: boolean;
      paginationState?: string;
      data: {
        id: string;
        symbol: string;
        type: IAssetType;
        derivativeDetails?: {
          derivativeType: IDerivativeType;
        } | null;
      }[];
    }> => {
      if (input === '') {
        return {
          data: [],
          hasMoreResults: false,
          paginationState: undefined,
        };
      }

      const query = await createCancellableQuery<IAssetPaginatedQuery>(apolloClient, {
        query: AssetPaginatedDocument,
        variables: {
          filters: {
            ...filters,
            query: input,
            limit: 100,
          },
          cursors: paginationState ?? undefined,
        },
        signal,
      });

      const paginatedResult = query.assets.paginated;
      return {
        data: paginatedResult.data,
        hasMoreResults: paginatedResult.pageInfo.hasNextPage,
        paginationState: paginatedResult.pageInfo.endCursor ?? undefined,
      };
    },
  };
};

export const createAssetSelectOptions = <T extends AssetSelectOptionValue>(
  assets: T[]
): {
  options: StaticAutocompleteOption<T>[];
  isValueEqual: (a: T | undefined | null, b: T | undefined | null) => boolean;
  optionHeight: number;
  limitTags: number;
  groupBy: (option: StaticAutocompleteOption<T>) => string;
  menuWidth: 'xl3';
  showChipTooltips: boolean;
} => {
  const options = assets.map((asset: T): StaticAutocompleteOption<T> => {
    return {
      searchText: ['name' in asset ? asset.name : undefined, asset.symbol].filter((val) => !!val).join(' '),
      label: <AssetSelectOption asset={asset} />,
      value: asset,
      inputText: asset.symbol,
      icon: <AssetIcon asset={asset} size={IconVariant.MEDIUM} />,
      key: asset.id,
    };
  });

  return {
    options: sortBy((option) => assetCategorySortingKey(option.value), options),
    isValueEqual: (a: T | undefined | null, b: T | undefined | null): boolean => {
      if (a && b) {
        return a.id === b.id;
      }

      return !a && !b;
    },
    optionHeight: HEIGHT_PX,
    limitTags: 1,
    groupBy: (opt) => getAssetCategory(opt.value),
    menuWidth: 'xl3',
    showChipTooltips: true,
  };
};

const NOT_DEFINED_GROUP_NAME = 'Not defined';
export const assignByCluster =
  <ROW,>({
    colorScheme,
    assetValueProvider,
    groupProvider,
    groupIndexProvider,
    showNotAssignedRows,
  }: {
    colorScheme: 'dark' | 'light';
    assetValueProvider: (row: ROW) => BigNumber;
    groupProvider: (row: ROW) => string | undefined;
    groupIndexProvider: (name: string) => number;
    showNotAssignedRows: boolean;
  }) =>
  (rows: ROW[]): Group<ROW> => {
    const filteredRows = showNotAssignedRows ? rows : rows.filter((row) => groupProvider(row) !== undefined);

    const groups: [string, ROW[]][] = Object.entries(
      groupBy((row) => groupProvider(row) ?? NOT_DEFINED_GROUP_NAME, filteredRows)
    );

    const groupByValue = sortBy(([, elements]) => {
      return bigNumMath.sum(...elements.map((element) => assetValueProvider(element))).toNumber();
    }, groups).reverse();

    return {
      subgroups: groupByValue.map(([name, elements]) => {
        const regularColor =
          name === NOT_DEFINED_GROUP_NAME
            ? neutralPlainDisabledColor
            : getRegularChartColor(colorScheme, groupIndexProvider(name));
        return {
          id: `subgroup:${name}`,
          name: formatLabelToName(name),
          color: regularColor,
          elementColor: getLightChartColor(colorScheme, groupIndexProvider(name)),
          elements: elements,
        };
      }),
    };
  };

export type AssetInputLabelWithCategory = AssetCategoryInput;

export const getUnderlyingAsset = <T extends { id: string }>(
  data: T & {
    derivativeDetails?: {
      baseAsset: T;
    } | null;
  }
): T => {
  return data.derivativeDetails?.baseAsset ?? data;
};

/** name which is used by AssetLabel, e.g. should be used in ag-grid reports for consistent sorting by asset column */
export function getAssetName(asset: Pick<IAsset, 'symbol' | 'name'>, format: 'long' | 'short' = 'long'): string {
  return format === 'long' && asset.name ? asset.name : asset.symbol;
}
