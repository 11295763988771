import { IJournalEntryType, IJournalError, ITransactionLegType } from '../../../generated/graphql.tsx';
import AssetValue from '../../market/asset/AssetValueView.tsx';
import GValueChange from '../../technical/ValueChange/GValueChange.tsx';
import { DateTimeFormat, formatCash, formatDate, formatEnum, formatNumber } from '../../formatter.utils.ts';
import type { NonNullableFields } from '../../type.utils.ts';
import WarningTooltip from '../../technical/WarningTooltip/WarningTooltip.tsx';
import type { Transaction } from './Transaction.types.ts';
import type { ReactNode } from 'react';
import isNil from 'lodash/fp/isNil';
import { bignumber } from 'mathjs';
import { Box, Card, Grid, Stack, Typography } from '@mui/joy';
import { SvgIcon } from '@mui/material';
import { calculateLegCost, calculateTotalCost } from './TransactionService.ts';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { AssetIcon } from '../../market/asset/AssetLabel.tsx';
import { useUserTimezone } from '../../technical/UseUserTimezone.tsx';
import sortBy from 'lodash/fp/sortBy';
import dayjs from 'dayjs';
import { KeyValueItem } from '../../technical/KeyValueItem.tsx';
import { IconReceiptDollar } from '@tabler/icons-react';

const gridSize = {
  xl: 6,
  xs: 12,
};

const cardProps = {
  variant: 'plain',
  sx: {
    height: '100%',
  },
} as const;

export const TransactionLegColumn = ({
  legs,
  pnl = false,
}: {
  legs: Transaction['legs'];
  pnl?: boolean;
}): ReactNode => {
  const timezone = useUserTimezone();
  const hasError = legs.some((leg) => !isNil(leg.journalEntry?.error));
  const legsWithJournalEntries = legs.filter(
    (leg): leg is LegWithRequiredJournalEntry => !isNil(leg.journalEntry)
  ) as LegWithRequiredJournalEntry[];

  const totalDebit = calculateTotalCost(
    legsWithJournalEntries.filter((leg) => leg.journalEntry.entryType === IJournalEntryType.Debit)
  );

  const totalCredit = calculateTotalCost(
    legsWithJournalEntries.filter((leg) => leg.journalEntry.entryType === IJournalEntryType.Credit)
  );

  const pnlPerDebitUnit = totalCredit.sub(totalDebit).div(totalDebit);
  return (
    <Grid {...gridSize}>
      {legs.length > 0 && (
        <Card {...cardProps}>
          <Stack direction={'column'} rowGap={1}>
            {sortBy((leg) => dayjs(leg.time).unix(), legs).map((leg) => {
              const amount = bignumber(leg.amount);
              const legDebit = leg.journalEntry ? calculateLegCost(leg.journalEntry) : undefined;
              return (
                <Stack direction="row" columnGap={1} alignItems={'center'} key={leg.id} p={0.5}>
                  {amount.isPositive() ? <ArrowDownwardIcon /> : amount.isNegative() ? <ArrowUpwardIcon /> : null}
                  <Stack direction={'column'} rowGap={1}>
                    <Stack direction={'row'} alignItems={'center'} flexWrap={'wrap'}>
                      <Box mr={1}>
                        <AssetIcon asset={leg.asset} />
                      </Box>
                      <Typography level={'title-sm-emphasis'}>
                        {amount.isPositive() ? '+' : amount.isNegative() ? '-' : ''}
                        {formatNumber(amount.abs())}
                      </Typography>
                      <Typography level={'title-sm'}>{leg.asset.symbol}</Typography>
                      {leg.unitValue && (
                        <>
                          <Typography level={'title-sm'} mx={0.5}>
                            {formatCash(bignumber(leg.unitValue).mul(amount))}
                          </Typography>
                          <Typography level={'title-sm-emphasis'}>@{formatCash(leg.unitValue)}</Typography>
                        </>
                      )}
                    </Stack>
                    <Typography level={'body-xs'}>{formatDate(leg.time, DateTimeFormat.DateTime, timezone)}</Typography>
                  </Stack>
                  {pnl && !hasError && (
                    <Box ml="auto" width={'10rem'}>
                      {!!legDebit && pnlPerDebitUnit.isFinite() && (
                        <GValueChange value={pnlPerDebitUnit} text={formatCash(pnlPerDebitUnit.mul(legDebit))} />
                      )}
                    </Box>
                  )}
                  <SvgIcon
                    sx={{
                      visibility: leg.type === ITransactionLegType.Fee ? 'visible' : 'hidden',
                      marginInline: '1rem',
                    }}
                    component={IconReceiptDollar}
                    fontSize={'xl'}
                  />

                  <Box width={'4rem'} ml={pnl && !hasError ? undefined : 'auto'}>
                    <KeyValueItem
                      label={'Side'}
                      value={leg.side ? formatEnum(leg.side) : undefined}
                      direction={'column'}
                    />
                  </Box>
                  <Box width={'5rem'}>
                    <KeyValueItem label={'State'} value={formatEnum(leg.slot)} direction={'column'} />
                  </Box>
                </Stack>
              );
            })}
          </Stack>
        </Card>
      )}
    </Grid>
  );
};

const renderLegs = (legs: LegWithRequiredJournalEntry[]): ReactNode => {
  const items = [];
  for (let i = 0; i < legs.length; i++) {
    const leg = legs[i];
    items.push(
      <AssetValue key={leg.id} asset={leg.asset} value={leg.journalEntry.remainingAmount} link={false} inline />
    );

    if (i !== legs.length - 1) {
      items.push(', ');
    }
  }

  return <>{items}</>;
};

export const TransactionPnlErrors = ({ errors }: { errors: LegWithRequiredJournalEntry[] }): ReactNode => {
  const costBasisError = errors.filter((leg) => leg.journalEntry?.error === IJournalError.MissingCostBasis);
  const amountError = errors.filter((leg) => leg.journalEntry?.error === IJournalError.MissingAmount);
  return (
    <Stack>
      {amountError.length > 0 && (
        <WarningTooltip fontSize="xl">
          Missing purchase history for [{renderLegs(amountError)}
          ]. Review your transaction history to ensure that all the transactions were captured correctly.
        </WarningTooltip>
      )}
      {costBasisError.length > 0 && (
        <WarningTooltip fontSize="xl">Missing cost basis for [{renderLegs(costBasisError)}].</WarningTooltip>
      )}
    </Stack>
  );
};

export type LegWithRequiredJournalEntry = NonNullableFields<Transaction['legs'][number], 'journalEntry'>;
