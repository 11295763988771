import { Add } from '@mui/icons-material';
import { Modal, ModalDialog, ModalOverflow } from '@mui/joy';
import type { ReactElement } from 'react';
import { now } from 'components/date.utils.ts';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';

import type { GraphQlErrorHandler } from 'components/technical/form/UseGraphQLApiError.tsx';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import type { ErrorHandlingOutput } from 'components/technical/UseDefaultErrorHandling.tsx';
import { useUserTimezone } from 'components/technical/UseUserTimezone.tsx';
import TransactionForm from './TransactionForm';
import { calculateAssetBuySellVisibility, type FormState, noValueOption } from './TransactionForm.validation';
import { getTransactionOutput } from './TransactionService.ts';
import {
  ITransactionStatus,
  ITransactionType,
  TransactionFilterInputDocument,
  TransactionsOldDocument,
  useCreateTransactionOldMutation,
} from '../../../generated/graphql';
import type { AssetSelectOptionValue } from '../../market/asset/AssetService.tsx';
import type { CreateSubAccountIdAutocompleteOptionsInputAccount } from '../../portfolio/account/AccountService.tsx';

const emptyAsset = { asset: undefined, amount: undefined, marketValue: undefined };
const CreateTransactionDialog = (props: {
  onClose: () => void;
  onAdded: () => void;
  accounts: CreateSubAccountIdAutocompleteOptionsInputAccount[];
  assetQuery: ErrorHandlingOutput<{ data: AssetSelectOptionValue[] | undefined }>;
  existingTags: string[];
}): ReactElement => {
  const timezone = useUserTimezone();

  const defaultValues: FormState = {
    type: ITransactionType.Trade,
    subType: '',
    buy: emptyAsset,
    sell: emptyAsset,
    fee: emptyAsset,
    externalId: '',
    trade: {
      orderSide: noValueOption.value,
      orderType: noValueOption.value,
      type: noValueOption.value,
    },
    status: ITransactionStatus.Succeeded,
    tags: [],
    executedAt: now(timezone).startOf('day'),
    comment: '',
    subAccount: props.accounts.length === 1 ? props.accounts[0].id : undefined,
  };
  const [createTransaction] = useCreateTransactionOldMutation({
    refetchQueries: [TransactionsOldDocument, TransactionFilterInputDocument],
  });

  const { showSuccessMessage } = useFeedback();

  const handleFormSubmit = async (data: FormState, onErrorAndThrow: GraphQlErrorHandler): Promise<void> => {
    const transactionType = data.type;
    const visibility = calculateAssetBuySellVisibility(transactionType);
    const { shouldShowBuy, shouldShowSell } = visibility;

    try {
      await createTransaction({
        variables: {
          input: {
            ...getTransactionOutput(data, shouldShowBuy, shouldShowSell),
            subAccount: {
              id: data.subAccount!,
            },
          },
        },
      });

      showSuccessMessage('New transaction successfully added');

      props.onAdded();
    } catch (e) {
      onErrorAndThrow(e);
    }
  };

  const assetQuery = props.assetQuery;
  return (
    <Modal open={true} onClose={props.onClose}>
      <ModalOverflow>
        <ModalDialog size={'lg'}>
          <GDialogHeader>Add new transaction</GDialogHeader>
          {assetQuery.loaded ? (
            <TransactionForm
              {...props}
              onSubmit={handleFormSubmit}
              defaultValues={defaultValues}
              accounts={props.accounts}
              disableSubAccount={false}
              submitButtonIcon={<Add />}
              submitButtonText="Add transaction"
              assets={assetQuery.data}
            />
          ) : (
            <assetQuery.Fallback />
          )}
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default CreateTransactionDialog;
