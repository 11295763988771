import { uniq } from 'lodash/fp';
import { useMemo } from 'react';
import { type TFallback, useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import { TupleKeyMap } from 'components/TupleKeyMap.ts';
import { useAllAssetGroupsQuery } from 'generated/graphql';

import type { GroupWithAssetId } from './portfolio/dashboard/PositionAggregationsService.ts';

function createAssetAndGroupClusterMapToAssetGroup(
  assetGroups: GroupWithAssetId[]
): TupleKeyMap<[string, string], string> {
  const byAssetAndCluster = new TupleKeyMap<[string, string], string>();
  for (const assetGroup of assetGroups) {
    for (const asset of assetGroup.assets) {
      byAssetAndCluster.set([asset.id, assetGroup.clusterName], assetGroup.groupName);
    }
  }
  return byAssetAndCluster;
}

export function getAssetGroupReportMapping(groups: GroupWithAssetId[]): {
  clusters: string[];
  assetAndGroupClusterMapToGroup: TupleKeyMap<[string, string], string>;
} {
  const assetAndGroupClusterMapToGroup = createAssetAndGroupClusterMapToAssetGroup(groups);
  const clusters = uniq(groups.map((group) => group.clusterName));
  return {
    clusters,
    assetAndGroupClusterMapToGroup,
  };
}

export type UseReportAssetGroupResultLoaded = {
  loaded: true;
  Fallback: undefined;
  clusters: string[];
  assetAndGroupClusterMapToGroup: TupleKeyMap<[string, string], string>;
  groups: {
    genieGroups: GroupWithAssetId[];
    userGroups: GroupWithAssetId[];
  };
};

export type UseReportAssetGroupResult =
  | {
      loaded: false;
      Fallback: TFallback;
      clusters: undefined;
      assetAndGroupClusterMapToGroup: undefined;
      groups: undefined;
    }
  | UseReportAssetGroupResultLoaded;

/** load and group asset groups for easier use in reports
 * where each group cluster is a column and value is group name gotten from mapping [asset, cluster] -> asset group
 * */
export function useReportAssetGroup({ skip }: { skip?: boolean } = { skip: false }): UseReportAssetGroupResult {
  const queryResult = useDefaultErrorHandling(
    useAllAssetGroupsQuery({
      skip,
    })
  );
  const { clusters, assetAndGroupClusterMapToGroup, groups } = useMemo(() => {
    if (!queryResult.loaded) {
      return {
        clusters: undefined,
        assetAndGroupClusterMapToGroup: undefined,
        groups: undefined,
      };
    }
    const genieAssetGroups = queryResult.data.assets.assetGroups.genieGroups;
    const userAssetGroups = queryResult.data.assets.assetGroups.userGroups;

    const { clusters, assetAndGroupClusterMapToGroup } = getAssetGroupReportMapping([
      ...genieAssetGroups,
      ...userAssetGroups,
    ]);

    return { clusters, assetAndGroupClusterMapToGroup, groups: queryResult.data.assets.assetGroups };
  }, [queryResult]);

  if (!queryResult.loaded) {
    return {
      loaded: false,
      Fallback: queryResult.Fallback,
      clusters: undefined,
      assetAndGroupClusterMapToGroup: undefined,
      groups: undefined,
    };
  }

  // ts doesn't understand that if loaded clusters and assetAndGroupClusterMapToGroup should be defined

  return {
    loaded: true,
    Fallback: undefined,

    clusters: clusters!,

    assetAndGroupClusterMapToGroup: assetAndGroupClusterMapToGroup!,

    groups: groups!,
  };
}
