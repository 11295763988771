export class ObjectKeyMap<K extends Record<string, unknown>, V> {
  #internalMap: Map<string, V>;

  constructor(entries?: [K, V][]) {
    this.#internalMap = new Map<string, V>();
    for (const [key, value] of entries ?? []) {
      this.set(key, value);
    }
  }

  private _serializeKey(keyObj: K): string {
    // ensure keys order
    return JSON.stringify(keyObj, Object.keys(keyObj).sort());
  }

  set(keyObj: K, value: V): void {
    const key = this._serializeKey(keyObj);
    this.#internalMap.set(key, value);
  }

  get(keyObj: K): V | undefined {
    const key = this._serializeKey(keyObj);
    return this.#internalMap.get(key);
  }

  has(keyObj: K): boolean {
    const key = this._serializeKey(keyObj);
    return this.#internalMap.has(key);
  }

  delete(keyObj: K): boolean {
    const key = this._serializeKey(keyObj);
    return this.#internalMap.delete(key);
  }

  clear(): void {
    this.#internalMap.clear();
  }

  get size(): number {
    return this.#internalMap.size;
  }

  keys(): K[] {
    return Array.from(this.#internalMap.keys()).map((keyStr) => JSON.parse(keyStr));
  }

  values(): V[] {
    return Array.from(this.#internalMap.values());
  }

  entries(): [K, V][] {
    return Array.from(this.#internalMap.entries()).map(([keyStr, value]) => [JSON.parse(keyStr), value]);
  }

  get [Symbol.toStringTag](): string {
    return this.toString();
  }

  toString(): string {
    const entries = Array.from(this.entries())
      .slice(0, 4)
      .map(([key, value]) => `${key} => ${JSON.stringify(value)}`);

    if (this.size > 5) {
      entries.push('...');
    }
    return `size=${this.size} { ${entries.join(', ')} }`;
  }
}
