import { wrapCreateBrowserRouter } from '@sentry/react';
import type { FunctionComponent } from 'react';

import { RouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';
import BreakpointTools from './components/technical/BreakpointTools/BreakpointTools';
import { routes } from './routes/Routes.tsx';
import { useAuth } from './UseAuth.tsx';
import { Box } from '@mui/joy';
import DevtoolsFormatters from './DevtoolsFormatters.tsx';

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter(routes);

const Root: FunctionComponent = () => {
  const { initialized } = useAuth();
  return (
    <BreakpointTools>
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'stretch',
        }}
      >
        <DevtoolsFormatters />
        {initialized && <RouterProvider router={router} />}
      </Box>
    </BreakpointTools>
  );
};

export default Root;
