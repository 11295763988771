import { Stack } from '@mui/joy';
import Card from '@mui/joy/Card';
import type { ReactElement } from 'react';

import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import { Information } from 'components/technical/icons';
import SectionColumn from 'components/technical/layout/Column/SectionColumn';

const SimulationTutorialMessage = (): ReactElement => (
  <Card>
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1.5}>
      <span>Results of the stress test will appear here.</span>
      <Stack justifyContent="center" spacing={1.5}>
        <Information fontSize="xl" />
      </Stack>
    </Stack>
  </Card>
);

export const StressTestResultHeader = (props: { skeleton: boolean }): ReactElement => {
  return (
    <SectionColumn>
      <HeaderBar title="Stress test results" />
      {props.skeleton && <SimulationTutorialMessage />}
    </SectionColumn>
  );
};
