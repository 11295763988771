import { Stack } from '@mui/joy';
import type { ColDef, ValueGetterParams } from 'ag-grid-community';
import uniq from 'lodash/fp/uniq';
import { type ReactElement, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { getAssetsGroups } from 'components/market/asset/groups/GroupService.ts';
import GButton from 'components/technical/inputs/GButton/GButton.tsx';
import type { AssetOptimizerInputFields } from '../AssetOptimizer.validation.ts';
import { sharedCheckboxColumnProps } from '../../universe/Universe.utils.ts';
import Universe from '../../universe/Universe.tsx';
import { getAssetCategory } from '../../../../../market/asset/AssetService.tsx';
import { defaultRowSpacing } from '../../../../../StackSpacing.ts';
import type { NotVerifiedAsset } from '../../../../../market/asset/AssetLabelService.ts';

type AssetUniverseRow = NotVerifiedAsset & { label: string; id: string };

const AssetUniverseStep = ({
  assets,
  assetIdToClusterToGroup,
  goToNextStep,
}: {
  assets: AssetUniverseRow[];
  goToNextStep: () => void;
  assetIdToClusterToGroup: Record<string, Record<string, string>>;
}): ReactElement => {
  const { formState, getValues, setValue } = useFormContext<AssetOptimizerInputFields>();
  const { isSubmitting } = formState;

  const columns: ColDef<AssetUniverseRow>[] = useMemo(() => {
    const clusters = uniq(
      Object.values(assetIdToClusterToGroup).flatMap((clusterToGroup) => Object.keys(clusterToGroup))
    );

    return [
      {
        ...sharedCheckboxColumnProps(isSubmitting),
        colId: 'assetName',
        headerName: 'Asset',
        valueGetter: (params: ValueGetterParams<AssetUniverseRow, string>): string | undefined => {
          if (!params.data) {
            return undefined;
          }

          return params.data.name ?? '';
        },
      },
      {
        headerName: 'Symbol',
        field: 'symbol',
      },
      {
        colId: 'assetType',
        headerName: 'Type',
        valueGetter: (params: ValueGetterParams<AssetUniverseRow, string>): string | undefined => {
          if (!params.data) {
            return undefined;
          }

          return getAssetCategory(params.data);
        },
      },
      ...clusters.map(
        (cluster): ColDef<AssetUniverseRow> => ({
          colId: `cluster-${cluster}`,
          headerName: cluster,
          valueGetter: (params: ValueGetterParams<AssetUniverseRow, string>): string | undefined => {
            if (!params.data) {
              return undefined;
            }

            const underlyingAssetId = params.data.id;
            if (!underlyingAssetId) {
              return undefined;
            }
            return assetIdToClusterToGroup[underlyingAssetId]?.[cluster];
          },
        })
      ),
    ];
  }, [assetIdToClusterToGroup, isSubmitting]);

  return (
    <Stack spacing={defaultRowSpacing}>
      <Universe
        columnDefs={columns}
        rowData={assets}
        onChange={(rows) => {
          const availableAssetIds = new Set(rows.map((asset) => asset.id));
          const groupForSelectedAssets = getAssetsGroups(assetIdToClusterToGroup, assets);
          const constraintsWithGroupsOrExistingAssets = getValues('constraints').filter((con) => {
            switch (con.item?.type) {
              case 'asset':
                return con.item.asset?.id && availableAssetIds.has(con.item.asset.id);
              case 'group':
                return con.item.group?.groupName && groupForSelectedAssets.has(con.item.group.groupName);
              default:
                return true;
            }
          });

          setValue('constraints', constraintsWithGroupsOrExistingAssets);
        }}
      />
      <GButton onClick={goToNextStep} sx={{ marginLeft: 'auto' }}>
        Next
      </GButton>
    </Stack>
  );
};

export default AssetUniverseStep;
