import { Typography } from '@mui/joy';
import type { CellContext, ColumnDef } from '@tanstack/react-table';
import { bignumber, sum } from 'mathjs';
import type { FunctionComponent, ReactNode } from 'react';
import { formatCash } from 'components/formatter.utils';
import GLink from 'components/technical/GLink/GLink';
import GTable from 'components/technical/GTable/GTable';
import type { Subgroup } from '../../Group.utils';
import type { AssetGroupElement } from '../AssetDashboard.types';

type ClusterGroupTable = { name: string; marketCap: string; id: string };

const ClusterGroupList: FunctionComponent<{
  subgroups: Subgroup<AssetGroupElement>[];
  clusterName: string;
  clusterType: 'genie' | 'user';
}> = ({ subgroups, clusterName, clusterType }) => {
  const columns: ColumnDef<ClusterGroupTable>[] = [
    {
      header: 'Group name',
      cell: (context: CellContext<ClusterGroupTable, unknown>): ReactNode => {
        const groupName = context.row.original.name;
        const encodedVariablesPath = [clusterType, clusterName, groupName]
          .map((item) => encodeURIComponent(item))
          .join('/');
        return <GLink to={`/app/market/clusters/${encodedVariablesPath}`}>{context.row.original.name}</GLink>;
      },
    },
    {
      header: 'Market cap',
      accessorFn: (group) => group.marketCap,
    },
  ];

  const clusterGroupTableData = subgroups.map((row) => {
    return {
      name: row.name,
      id: row.id,
      marketCap: formatCash(
        sum(row.elements.map((asset) => bignumber(asset.metrics['met:market_cap'] ?? 0).toNumber()))
      ),
    };
  });
  return (
    <GTable
      columns={columns}
      hideRowsPerPage
      data={clusterGroupTableData}
      sx={{
        height: '100%',
      }}
      cardSx={{
        height: '100%',
      }}
      topBar={
        <Typography level="body-md" color="neutral">
          Cluster Groups
        </Typography>
      }
    />
  );
};

export default ClusterGroupList;
