import { Switch, type SwitchProps, Typography } from '@mui/joy';
import type { ReactElement, ReactNode, Ref } from 'react';
import { fixedForwardRef } from '../fixedForwardRef.ts';

export type GSwitchProps = {
  label: ReactNode;
  matchInputWithLabelHeight?: boolean;
  value: boolean;
  preLabel?: ReactNode;
} & Omit<SwitchProps, 'checked'>;

const GSwitchLabel = ({
  text,
  startDecorator,
  matchInputWithLabelHeight,
}: {
  text: ReactNode;
  startDecorator?: ReactNode;
  matchInputWithLabelHeight?: boolean;
}): ReactElement => {
  return (
    <Typography
      component="label"
      level="body-xs"
      sx={
        matchInputWithLabelHeight
          ? {
              mt: '2rem', // value hardcoded in joy ui input styles for a variable '--Input-minHeight
              mb: '0.5rem', // value hardcoded in joy ui input styles for a variable '--Input-minHeight
            }
          : {}
      }
      startDecorator={startDecorator}
    >
      {text}
    </Typography>
  );
};

const GSwitch = (
  { matchInputWithLabelHeight, preLabel, label, ...props }: GSwitchProps,
  ref?: Ref<HTMLDivElement>
): ReactElement => {
  return (
    <>
      {preLabel && <GSwitchLabel matchInputWithLabelHeight={matchInputWithLabelHeight} text={preLabel} />}
      <GSwitchLabel
        startDecorator={<Switch {...props} ref={ref} checked={props.value} />}
        matchInputWithLabelHeight={matchInputWithLabelHeight}
        text={label}
      />
    </>
  );
};

const ForwardedGSwitch = fixedForwardRef(GSwitch);
export default ForwardedGSwitch;
