import { Close } from '@mui/icons-material';
import { Alert, Box, IconButton, Link, Stack, Tooltip, Typography, useTheme } from '@mui/joy';
import { type FunctionComponent, useContext } from 'react';

import { useAuth } from 'UseAuth.tsx';
import ImpersonationAutocomplete from 'components/management/impersonation/ImpersonationAutocomplete.tsx';
import { REPLAY_UNMASK_CLASS_NAME } from 'setup/index.ts';
import { tooltipEnterDelay } from 'theme/consts.ts';
import { ImpersonationBarContext } from '../ImpersonationBarProvider.tsx';
import { useUsersQuery } from 'generated/graphql.tsx';
import GCheckbox from '../inputs/GCheckbox/GCheckbox.tsx';

const ImpersonationAppbar: FunctionComponent = () => {
  const { impersonationBarOpen, setImpersonationBarOpen } = useContext(ImpersonationBarContext);
  const { isImpersonating, isAdmin, isImpersonatingAdmin, setIsImpersonatingAdmin } = useAuth();
  const theme = useTheme();
  const usersQueryResult = useUsersQuery({
    fetchPolicy: 'cache-first',
    skip: !isAdmin && !isImpersonating,
  });

  if (!impersonationBarOpen) {
    return null;
  }

  const message = isImpersonating ? 'Impersonating' : 'Select';
  return (
    <Alert
      color={isImpersonating ? 'danger' : 'warning'}
      variant="soft"
      sx={{ py: 1, zIndex: 'var(--navbar-z-index)' }}
      className={REPLAY_UNMASK_CLASS_NAME}
    >
      <Stack direction="row" alignItems="center" spacing={1} width="100%" flexWrap="wrap">
        <Box minWidth="100px" textAlign="center" ml="10px">
          {message}
        </Box>
        <Tooltip
          enterDelay={tooltipEnterDelay.Slow}
          enterNextDelay={tooltipEnterDelay.Slow}
          placement="right"
          title="Search by name, email or id"
        >
          <div>
            <ImpersonationAutocomplete usersQueryResult={usersQueryResult} />
          </div>
        </Tooltip>
        <Link href="/app/management/impersonation">
          <Typography level="body-sm" color="primary">
            Users
          </Typography>
        </Link>
        {isImpersonating && (
          <GCheckbox
            label={'Force admin'}
            width={'normal'}
            onChange={setIsImpersonatingAdmin}
            checked={isImpersonatingAdmin}
            color={'danger'}
            sx={(theme) => ({
              color: theme.palette.danger.softColor,
            })}
          />
        )}
      </Stack>
      <IconButton
        sx={{ marginLeft: 'auto', color: theme.vars.palette.text.icon }}
        onClick={(): void => setImpersonationBarOpen(false)}
        variant="plain"
      >
        <Close />
      </IconButton>
    </Alert>
  );
};

export default ImpersonationAppbar;
