import type { ReactElement } from 'react';
import { useFieldArray, useFormContext, useFormState } from 'react-hook-form';

import ErrorMessage from 'components/technical/ErrorMessage.tsx';
import InputLabel from 'components/technical/inputs/InputLabel.tsx';
import { isEntryPriceRequired, PortfolioPositionRow } from './PortfolioPositionRow.tsx';
import type { PortfolioStepInput, StressTestFormAsset } from './PortfoliosStep.validation.tsx';
import AddButton from '../../../technical/AddButton.tsx';
import { Box, Dropdown, Menu, MenuButton, MenuItem, Stack, Typography } from '@mui/joy';
import { defaultRowSpacing } from '../../../StackSpacing.ts';
import RemoveButton from '../../../technical/RemoveButton.tsx';
import { IAssetType, IPositionSide } from '../../../../generated/graphql.tsx';
import { Add } from '@mui/icons-material';
import { bignumber, format } from 'mathjs';
import isNil from 'lodash/fp/isNil';

export const PortfolioPositionList = ({
  index: portfolioIndex,
  remove: removePortfolio,
  portfolioCompositions,
}: {
  index: number;
  remove: () => void;
  portfolioCompositions: {
    name: string;
    composition: {
      asset: StressTestFormAsset;
      weight: number;
      amount: number | string | null | undefined;
      entryPrice: number | string | null | undefined;
    }[];
  }[];
}): ReactElement => {
  const { clearErrors, formState, trigger } = useFormContext();
  const columns = [
    'minmax(250px, max-content)',
    'minmax(150px, max-content)',
    'minmax(125px, max-content)',
    'minmax(125px, max-content)',
    'minmax(125px, max-content)',
    'min-content',
  ]
    .filter((val) => !!val)
    .join(' ');

  const {
    fields: positions,
    append,
    remove,
    replace,
  } = useFieldArray<PortfolioStepInput, `portfolios.${typeof portfolioIndex}.portfolio`>({
    name: `portfolios.${portfolioIndex}.portfolio`,
  });

  const { errors } = useFormState<PortfolioStepInput>({
    name: `portfolios.${portfolioIndex}.portfolioLength`,
  });

  const headerMarginBottom = '-0.5rem';
  const positionLengthError = errors.portfolios?.[portfolioIndex]?.portfolioLength?.message;
  return (
    <Stack gap={defaultRowSpacing}>
      <div>
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography level="title-md">Portfolio {portfolioIndex + 1}</Typography>
          <RemoveButton onClick={() => removePortfolio()} />
        </Stack>
        <Box
          sx={{
            alignItems: 'center',
            display: 'grid',
            gridAutoRows: 'min-content',
            gap: '0.75rem',
            rowGap: '1rem',
            gridTemplateColumns: columns,
          }}
        >
          <InputLabel label="Position" marginBottom={headerMarginBottom} />
          <InputLabel label="Amount" marginBottom={headerMarginBottom} />
          <InputLabel label="Side" marginBottom={headerMarginBottom} />
          <InputLabel label="Premium per contract" marginBottom={headerMarginBottom} />
          <InputLabel label="Entry price" marginBottom={headerMarginBottom} />
          <span />
          {positions.map(
            (position, index: number): ReactElement => (
              <PortfolioPositionRow
                key={position.id}
                portfolioIndex={portfolioIndex}
                positionIndex={index}
                onRemove={(): void => {
                  remove(index);
                  trigger(`portfolios.${portfolioIndex}.portfolioLength`);
                }}
              />
            )
          )}
          <AddButton
            disabled={formState.isSubmitting}
            width="normal"
            onClick={(): void => {
              append({
                asset: null,
                amount: null,
                premium: null,
                side: IPositionSide.Long,
                entryPrice: null,
              });
              clearErrors(`portfolios.${portfolioIndex}.portfolioLength`);
            }}
          >
            Add position
          </AddButton>
          <div
            style={{
              gridColumn: '-4 / -1',
              marginLeft: 'auto',
            }}
          >
            <Dropdown>
              <MenuButton startDecorator={<Add />} disabled={portfolioCompositions.length === 0} color="primary">
                Import from portfolio
              </MenuButton>
              <Menu>
                {portfolioCompositions.map((composition, i) => (
                  <MenuItem
                    key={i}
                    onClick={() => {
                      replace(
                        composition.composition.map((pos) => {
                          const parsedAmount = bignumber(pos.amount);

                          let side: null | IPositionSide = null;
                          if (pos.asset.type === IAssetType.Derivative) {
                            if (parsedAmount.isPositive()) {
                              side = IPositionSide.Long;
                            } else {
                              side = IPositionSide.Short;
                            }
                          }

                          return {
                            asset: pos.asset,
                            amount: format(
                              pos.asset.type === IAssetType.Derivative ? parsedAmount.abs() : parsedAmount,
                              {
                                notation: 'fixed',
                              }
                            ),
                            side: side,
                            premium: null,
                            entryPrice:
                              isNil(pos.entryPrice) || !isEntryPriceRequired(pos.asset)
                                ? null
                                : format(bignumber(pos.entryPrice), {
                                    notation: 'fixed',
                                  }),
                          };
                        })
                      );

                      clearErrors(`portfolios.${portfolioIndex}.portfolio`);
                    }}
                  >
                    {composition.name}
                  </MenuItem>
                ))}
              </Menu>
            </Dropdown>
          </div>
        </Box>
      </div>
      {positionLengthError && <ErrorMessage>{positionLengthError}</ErrorMessage>}
    </Stack>
  );
};
