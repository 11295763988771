import type { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { type FormInputFields, type FormOutputFields, schema } from './MarketRegimeForm.validation.ts';
import gYupResolver from '../../technical/form/gYupResolver.ts';
import GFormProvider from '../../technical/form/GFormProvider.tsx';
import { Stack } from '@mui/joy';
import { defaultRowSpacing } from '../../StackSpacing.ts';
import SubmitButton from '../../technical/form/SubmitButton.tsx';
import type { StaticAutocompleteOption } from '../../technical/inputs/Autocomplete/StaticSingleAutocomplete.props.ts';
import { HEIGHT_PX } from '../../copilot/lab/PortfolioDefinitionLabel.tsx';
import FormStaticMultiAutocomplete from '../../technical/form/FormStaticMultiAutocomplete.tsx';
import type { NotVerifiedAsset } from '../asset/AssetLabelService.ts';
import { createAssetSelectOptions } from '../asset/AssetService.tsx';
import FormStaticSingleAutocomplete from 'components/technical/form/FormStaticSingleAutocomplete.tsx';
import { FormDateInput } from '../../technical/form/FormDateInput.tsx';

interface ModelData {
  id: string;
  name: string;
}

const createModelOptions = (
  models: ModelData[]
): {
  options: StaticAutocompleteOption<ModelData>[];
  optionHeight: number;
  limitTags: number;
  isValueEqual: (a: ModelData | undefined | null, b: ModelData | undefined | null) => boolean;
} => {
  return {
    options: models.map((mod) => ({
      label: mod.name,
      value: mod,
      searchText: mod.name,
      key: mod.id,
    })),
    optionHeight: HEIGHT_PX,
    limitTags: 2,
    isValueEqual: (a: ModelData | undefined | null, b: ModelData | undefined | null): boolean =>
      (!a && !b) || a?.id === b?.id,
  };
};

const MarketRegimeForm = ({
  onSubmit,
  models,
  assets,
}: {
  onSubmit: (val: FormOutputFields) => void;
  models: ModelData[];
  assets: (NotVerifiedAsset & { id: string; symbol: string })[];
}): ReactElement => {
  const modelOptions = createModelOptions(models);
  const assetOptions = createAssetSelectOptions(assets);

  const methods = useForm<FormInputFields>({
    resolver: gYupResolver(schema),
    defaultValues: {
      since: null,
      to: null,
      models: models.length === 1 ? [models[0]] : [],
      asset: null,
    },
  });

  const handleFormSubmit = async (input: FormInputFields): Promise<void> => {
    // submit the kay to avoid updating the same reference to be used in the parent state
    onSubmit({ ...(input as unknown as FormOutputFields) });
  };

  return (
    <GFormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
        <Stack gap={defaultRowSpacing}>
          <Stack direction={'row'} flexWrap={'wrap'} columnGap={1} rowGap={defaultRowSpacing}>
            <FormDateInput
              showClearable
              width="normal"
              name="since"
              label="From"
              onChange={() => {
                methods.trigger('to');
              }}
            />
            <FormDateInput width="normal" name="to" label="To" showClearable />
          </Stack>
          <Stack direction={'row'} flexWrap={'wrap'} columnGap={1} rowGap={defaultRowSpacing}>
            <FormStaticMultiAutocomplete<FormInputFields> label="Model" width="xl2" name={'models'} {...modelOptions} />
            <FormStaticSingleAutocomplete<FormInputFields>
              label="Benchmark"
              width="normal"
              name={'asset'}
              showClearable
              {...assetOptions}
            />
          </Stack>
          <SubmitButton width={'small'}>Submit</SubmitButton>
        </Stack>
      </form>
    </GFormProvider>
  );
};

export default MarketRegimeForm;
