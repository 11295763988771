import { type ReactElement, useState } from 'react';

import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import ConfirmCodeStep from './ConfirmCodeStep';
import type { Steps } from './EnableMfaDialog.types';
import { EnableMfaDialogShell } from './EnableMfaDialogShell';
import ShowCodeStep from './ShowCodeStep';

const SetupMfaSteps = ({
  code,
  email,
  onClose,
}: {
  code: string;
  email: string;
  onClose: () => void;
}): ReactElement => {
  const [step, setStep] = useState<Steps>('SHOW_CODE');
  const { showSuccessMessage } = useFeedback();

  switch (step) {
    case 'SHOW_CODE':
      return (
        <EnableMfaDialogShell onClose={onClose}>
          <ShowCodeStep onNext={(): void => setStep('CONFIRM_CODE')} code={code} email={email} />
        </EnableMfaDialogShell>
      );
    case 'CONFIRM_CODE':
      return (
        <EnableMfaDialogShell onClose={onClose}>
          <ConfirmCodeStep
            onGoBack={(): void => setStep('SHOW_CODE')}
            onNext={(): void => {
              showSuccessMessage('Two-factor authentication was successfully enabled');
              onClose();
            }}
          />
        </EnableMfaDialogShell>
      );
    default:
      throw new Error('Unknown state');
  }
};

export default SetupMfaSteps;
