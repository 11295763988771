import type { ReactElement } from 'react';
import { useFormContext, Controller, type Path, type FieldValues } from 'react-hook-form';
import GSlider, { type SliderProps } from '../inputs/GSlider/GSlider';

interface FormSliderProps<T extends FieldValues> extends Omit<SliderProps, 'value' | 'name'> {
  name: Path<T>;
}

function FormSlider<T extends FieldValues>({
  name,
  min,
  max,
  getLabel,
  showMinMaxValues = false,
  showCurrentValue = true,
  disabled,
  step = 0.1,
  width,
}: FormSliderProps<T>): ReactElement {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { name, value, onChange } }): ReactElement => (
        <GSlider
          name={name}
          min={min}
          max={max}
          getLabel={getLabel}
          showMinMaxValues={showMinMaxValues}
          showCurrentValue={showCurrentValue}
          disabled={disabled}
          step={step}
          value={value}
          width={width}
          onChange={onChange}
        />
      )}
    />
  );
}

export default FormSlider;
