import { pickBy } from 'lodash/fp';
import isEmpty from 'lodash/fp/isEmpty';
import trim from 'lodash/fp/trim';
import * as yup from 'yup';
import { venues } from 'components/venue/VenueData.tsx';
import type { ISecretsInput } from '../../../../generated/graphql.tsx';

export const getInitialValues = (fields: { name: string }[]): Record<string, string> => {
  return Object.fromEntries(fields.map((field) => [field.name, '']));
};

export function getVenueExtraFieldsSchema(venue: string): yup.Schema {
  const venueExtraFields = venues[venue].extraFields ?? [];

  const fieldsSchema = Object.fromEntries(
    venueExtraFields.filter((field) => !field.optional).map((field) => [field.name, yup.string().trim().required()])
  );

  return yup.object().shape(fieldsSchema);
}

export const removeEmptySecrets = (secrets: Required<ISecretsInput>): Record<string, string> => {
  return pickBy((value): value is string => !isEmpty(trim(value ?? '')), secrets);
};

export const API_SECRET_FIELD_NAME = 'secrets';
