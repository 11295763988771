import type { ReactElement } from 'react';
import { useFormState } from 'react-hook-form';

import ErrorMessage from 'components/technical/ErrorMessage';
import InputLabel from 'components/technical/inputs/InputLabel';
import type { OptionsStressTestOutputFields } from './OptionsStressTest.types';
import { OptionsStressTestPositionRow } from './OptionsStressTestPositionRow';
import type { AssetLabelInput } from '../../market/asset/AssetLabelService.ts';
import { Box } from '@mui/joy';

export interface OptionsStressTestPositionListProps {
  onRemove: (index: number) => void;
  positions: Record<'id', string>[];
  supportedOptions: (AssetLabelInput & { id: string; name?: string | null })[];
}

export const OptionsStressTestPositionList = ({
  onRemove,
  positions,
  supportedOptions,
}: OptionsStressTestPositionListProps): ReactElement => {
  const columns = [
    'minmax(250px, max-content)',
    'minmax(150px, max-content)',
    'minmax(125px, max-content)',
    'minmax(125px, max-content)',
    'min-content',
  ]
    .filter((val) => !!val)
    .join(' ');

  const { errors } = useFormState<OptionsStressTestOutputFields>({
    name: 'positionsLength',
  });

  const positionLengthError = errors.positionsLength?.message;

  return (
    <>
      <Box
        style={{
          alignItems: 'center',
          display: 'grid',
          gridAutoRows: 'min-content',
          gap: '0.75rem',
          width: '100%',
          gridTemplateColumns: columns,
        }}
      >
        <>
          <InputLabel label="Contract" />
          <InputLabel label="Amount" />
          <InputLabel label="Side" />
          <InputLabel label="Premium per contract" />
          <span />
        </>
        {positions.map(
          (position, index: number): ReactElement => (
            <OptionsStressTestPositionRow
              supportedOptions={supportedOptions}
              key={position.id}
              index={index}
              onRemove={(): void => onRemove(index)}
            />
          )
        )}
      </Box>
      {positionLengthError && <ErrorMessage>{positionLengthError}</ErrorMessage>}
    </>
  );
};
