import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MenuButton } from '@mui/joy';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import type { ReactNode } from 'react';
import DialogDropdown from '../DialogDropdown/DialogDropdown.tsx';

export interface SeeMoreMenuProps {
  children: ReactNode;
  variant?: 'soft' | 'plain';
}

export const seeMoreDropdownClass = 'genie-see-more-dropdown';
const SeeMoreDropdown = ({ children, variant = 'plain' }: SeeMoreMenuProps) => {
  return (
    <div
      className={seeMoreDropdownClass}
      onClick={(e): void => {
        // cancel propagation on click which by default would expand table row when component is used in a table
        e.stopPropagation();
      }}
    >
      {/* disable inheriting typography styles in modals rendered as part of dropdowns */}
      <DialogDropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{ root: { variant: variant } }}
          sx={{
            width: 'min-content',
          }}
        >
          <MoreVertIcon />
        </MenuButton>
        <Menu placement="auto">{children}</Menu>
      </DialogDropdown>
    </div>
  );
};
export default SeeMoreDropdown;
