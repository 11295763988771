import type { ReactNode } from 'react';
import type { MetricData, MetricParams } from './MetricsData.types.ts';
import { PORTFOLIO_BETA_METRIC } from './PortfolioMetricsData.ts';

export const PORTFOLIO_EXPECTED_BETA_BTC_METRIC = 'pmet:expected_beta_btc' as const;
export const PORTFOLIO_EXPECTED_BETA_ETH_METRIC = 'pmet:expected_beta_eth' as const;

type BETA_METRIC_WITH_BENCHMARK = typeof PORTFOLIO_EXPECTED_BETA_BTC_METRIC | typeof PORTFOLIO_EXPECTED_BETA_ETH_METRIC;

export const PORTFOLIO_EXPECTED_DOWNSIDE_RISK_METRIC = 'pmet:expected_downside_risk' as const;
export const PORTFOLIO_EXPECTED_VOLATILITY_METRIC = 'pmet:expected_volatility' as const;
export const PORTFOLIO_EXPECTED_HISTORIC_VAR99 = 'pmet:expected_historic_var99' as const;
export const PORTFOLIO_EXPECTED_HISTORIC_CVAR99 = 'pmet:expected_gaussian_cvar99' as const;
export const PORTFOLIO_EXPECTED_BETA_METRIC = 'pmet:expected_beta' as const;
export const PORTFOLIO_EXPANDING_BETA_METRIC = 'pmet:expanding_beta' as const;

type RiskMeasureTarget = (typeof riskMeasureTargets)[number];

export const riskMeasureTargets = [
  PORTFOLIO_EXPECTED_VOLATILITY_METRIC,
  PORTFOLIO_EXPECTED_BETA_METRIC,
  'pmet:expected_historic_cvar95' as const,
  'pmet:expected_historic_cvar99' as const,
  'pmet:expected_gaussian_cvar95' as const,
  PORTFOLIO_EXPECTED_HISTORIC_CVAR99,
  'pmet:expected_historic_var95' as const,
  PORTFOLIO_EXPECTED_HISTORIC_VAR99,
  'pmet:expected_gaussian_var95' as const,
  'pmet:expected_gaussian_var99' as const,
  PORTFOLIO_EXPECTED_DOWNSIDE_RISK_METRIC,
];

export function isRiskMeasureTarget(value: unknown): value is RiskMeasureTarget {
  return riskMeasureTargets.includes(value as RiskMeasureTarget);
}

type BETA_METRIC =
  | BETA_METRIC_WITH_BENCHMARK
  | typeof PORTFOLIO_EXPECTED_BETA_METRIC
  | typeof PORTFOLIO_BETA_METRIC
  | typeof PORTFOLIO_EXPANDING_BETA_METRIC;

export function isBetaMetric(value: unknown): value is BETA_METRIC {
  return (
    value === PORTFOLIO_EXPECTED_BETA_BTC_METRIC ||
    value === PORTFOLIO_EXPECTED_BETA_ETH_METRIC ||
    value === PORTFOLIO_EXPECTED_BETA_METRIC ||
    value === PORTFOLIO_BETA_METRIC ||
    value === PORTFOLIO_EXPANDING_BETA_METRIC
  );
}

const portfolioBetaMetricData = {
  name: (params?: MetricParams): string => {
    if (!params || !params.assetSymbol) {
      // there are places in ui where we want to generate a list of metrics first and then allow selecting an asset
      return 'Beta';
    }
    return `Beta ${params.assetSymbol}`;
  },
  format: 'number',
  tooltip: (params?: MetricParams): ReactNode => {
    let symbol = params?.assetSymbol;
    if (!symbol) {
      // there are places in ui where we want to generate a list of metrics first and then allow selecting an asset
      symbol = 'selected asset';
    }
    return `Is how much your portfolio value is expected to change when the value of ${symbol} increases by 1%`;
  },
} as const;

const distributionPortfolioPercentageMetric = (
  name: 'var' | 'cvar',
  dist: 'gaussian' | 'historic',
  percentage: number,
  tooltipPrefix: string
): MetricData => {
  const tooltipPostfix =
    dist === 'historic'
      ? 'using empirical distribution coming from historic data'
      : 'using a Gaussian distribution fitted on recent volatility';
  return {
    name: `${dist === 'gaussian' ? 'Gaussian' : 'Historic'} ${name}${percentage}%`,
    format: 'percentage',
    tooltip: `${tooltipPrefix} ${tooltipPostfix}`,
  } as const;
};

const cvar = (dist: 'historic' | 'gaussian', percentage: number): MetricData => {
  return distributionPortfolioPercentageMetric(
    'cvar',
    dist,
    percentage,
    `Is the average loss that you can expect in the worst ${100 - percentage}% of the cases, 1 day in the future`
  );
};

const var_ = (dist: 'historic' | 'gaussian', percentage: number): MetricData => {
  return distributionPortfolioPercentageMetric(
    'var',
    dist,
    percentage,
    `Is the max loss that you can expect in ${100 - percentage}% of the cases, 1 day in the future`
  );
};

export const metricsData: Record<RiskMeasureTarget | BETA_METRIC_WITH_BENCHMARK, MetricData> = {
  'pmet:expected_volatility': {
    name: 'Volatility',
    format: 'percentage',
    tooltip: 'Is the expected standard deviation of daily returns in the last 30 days',
  },
  'pmet:expected_beta': portfolioBetaMetricData,
  'pmet:expected_historic_cvar95': cvar('historic', 95),
  'pmet:expected_historic_cvar99': cvar('historic', 99),
  'pmet:expected_gaussian_cvar95': cvar('gaussian', 95),
  'pmet:expected_gaussian_cvar99': cvar('gaussian', 99),
  'pmet:expected_historic_var95': var_('historic', 95),
  'pmet:expected_historic_var99': var_('historic', 99),
  'pmet:expected_gaussian_var95': var_('gaussian', 95),
  'pmet:expected_gaussian_var99': var_('gaussian', 99),
  'pmet:expected_downside_risk': {
    name: 'Down side risk',
    format: 'percentage',
    tooltip:
      'The expected standard deviation of negative daily returns in a period of 30days. Positive returns are counted with a value of 0.',
  },
  [PORTFOLIO_EXPECTED_BETA_BTC_METRIC]: {
    name: 'Beta BTC',
    format: 'number',
    tooltip: 'Is how much your portfolio value is expected to change when the value of BTC increases by 1%',
  },
  [PORTFOLIO_EXPECTED_BETA_ETH_METRIC]: {
    name: 'Beta ETH',
    format: 'number',
    tooltip: 'Is how much your portfolio value is expected to change when the value of ETH increases by 1%',
  },
};
