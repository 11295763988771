import type { FunctionComponent } from 'react';
import NavigableTab from 'components/technical/NavigableTabs/NavigableTab.tsx';
import NavigableTabs from 'components/technical/NavigableTabs/NavigableTabs.tsx';

const RiskDashboard: FunctionComponent = () => {
  return (
    <NavigableTabs fullHeight>
      <NavigableTab link="/app/portfolio/risk/overview" text="Overview" />
      <NavigableTab link="/app/portfolio/risk/counterparty-report" text="Counterparty risk" />
      <NavigableTab link="/app/portfolio/risk/asset-risk-metrics" text="Asset risk metrics" />
    </NavigableTabs>
  );
};

export default RiskDashboard;
