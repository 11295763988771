import { type FunctionComponent, type PropsWithChildren, type ReactElement, type ReactNode, useState } from 'react';

import { Context } from './Context.tsx';

const ActionsContextProvider: FunctionComponent<PropsWithChildren<object>> = ({
  children,
}: PropsWithChildren<object>): ReactElement => {
  const [actions, setActions] = useState<ReactNode>([]);
  return (
    <Context.Provider
      value={{
        setActions,
        actions,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ActionsContextProvider;
