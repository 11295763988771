import Avatar from '@mui/joy/Avatar';
import type { CSSProperties, ReactElement } from 'react';

import { getLightChartColor } from '../../../theme/colors';
import { hashcode } from '../../hashcode.utils';
import { useFinalColorScheme } from '../../../useFinalColorScheme.ts';

const UnknownIcon = (props: { text: string | null | undefined; style: CSSProperties }): ReactElement => {
  const hash = hashcode(props.text);
  const firstLetter = props.text?.at(0)?.toUpperCase() ?? '';
  const colorScheme = useFinalColorScheme();

  return (
    // fixed dark color for better contrast
    <Avatar
      sx={{ background: getLightChartColor(colorScheme, hash), color: 'var(--joy-palette-neutral-500)' }}
      style={props.style}
    >
      {firstLetter}
    </Avatar>
  );
};

export default UnknownIcon;
