import { Box, Modal, ModalDialog, Stack } from '@mui/joy';
import { Fragment, type ReactElement } from 'react';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import type { IMultifactorQuery } from 'generated/graphql';
import SubmitButton from 'components/technical/form/SubmitButton';
import { useFieldArray, useForm } from 'react-hook-form';
import CreateMultifactorDialog from './CreateMultifactorDialog';
import DialogButton from 'components/technical/inputs/GButton/DialogButton';
import { FormDateInput } from 'components/technical/form/FormDateInput';
import dayjs from 'dayjs';
import FormStaticSingleAutocomplete from 'components/technical/form/FormStaticSingleAutocomplete';
import { FormInput } from 'components/technical/inputs';
import type { StaticAutocompleteOption } from 'components/technical/inputs/Autocomplete/StaticSingleAutocomplete.props';
import { Add } from '@mui/icons-material';
import AddButton from 'components/technical/AddButton';
import RemoveButton from 'components/technical/RemoveButton';
import {
  DEFAULT_SCREENER,
  getLoadMultifactorFormSchema,
  type LoadMultifactorFormInputFields,
} from './AssetScreenerService';
import GFormProvider from 'components/technical/form/GFormProvider';
import gYupResolver from 'components/technical/form/gYupResolver';
import { defaultRowSpacing } from '../../../StackSpacing.ts';
import InputLabel from '../../../technical/inputs/InputLabel.tsx';

type MultifactorDialogProps = {
  onClose: () => void;
  multifactorQuery: { data: IMultifactorQuery };
  handleSubmit: (data: LoadMultifactorFormInputFields) => void;
};

const LoadMultifactorDialog = ({ onClose, multifactorQuery, handleSubmit }: MultifactorDialogProps): ReactElement => {
  const multifactors = multifactorQuery.data.multifactor.getAllMultifactorsForUser;
  const methods = useForm<LoadMultifactorFormInputFields>({
    resolver: gYupResolver(getLoadMultifactorFormSchema(multifactorQuery.data.multifactor.getAllMultifactorsForUser)),
    mode: 'onBlur',
    defaultValues: {
      date: dayjs(),
      screeners: [{ ...DEFAULT_SCREENER }],
    },
  });

  const {
    fields: screeners,
    remove: removeScreener,
    append: appendScreener,
  } = useFieldArray<LoadMultifactorFormInputFields>({
    control: methods.control,
    name: 'screeners',
  });

  const multifactorOptions: StaticAutocompleteOption<number>[] = multifactors.map((mf) => {
    return {
      searchText: mf.multifactor.name,
      label: mf.multifactor.name,
      key: `${mf.multifactor.id}`,
      value: mf.multifactor.id,
    };
  });

  const updateMinNumberOfFactorsInput = (index: number, value: number | null): void => {
    if (value) {
      methods.setValue(
        `screeners.${index}.minNumberOfFactors`,
        multifactors.find((mf) => mf.multifactor.id === value)!.maxFactors.toString()
      );
      return;
    }
    methods.resetField(`screeners.${index}.minNumberOfFactors`);
  };

  const isMultifactorArrayEmpty = multifactors.length === 0;
  const labelBottomMargin = '-0.5rem';

  return (
    <Modal open={true} onClose={onClose}>
      <ModalDialog size={'lg'}>
        <GFormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <GDialogHeader>Load multifactors</GDialogHeader>
            <Stack spacing={4}>
              <FormDateInput<LoadMultifactorFormInputFields>
                label="Multifactor load date"
                showLabelAboveInput
                name="date"
                maxDate={dayjs.utc()}
                width="xl2"
                disabled={isMultifactorArrayEmpty}
              />
              <Stack gap={3}>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, minmax(100px, 200px)) 50px',
                    rowGap: defaultRowSpacing,
                    columnGap: 2,
                  }}
                >
                  <InputLabel label={'Multifactor'} marginBottom={labelBottomMargin} />
                  <InputLabel label={'Minimum factors'} marginBottom={labelBottomMargin} />
                  <span />
                  {screeners.map((screener, index) => (
                    <Fragment key={screener.id}>
                      <FormStaticSingleAutocomplete<LoadMultifactorFormInputFields>
                        name={`screeners.${index}.multifactorId`}
                        width="fullWidth"
                        placeholder="Choose a multifactor"
                        options={multifactorOptions}
                        onChange={(val) => {
                          updateMinNumberOfFactorsInput(index, val);
                          methods.clearErrors(`screeners.${index}.minNumberOfFactors`);
                        }}
                        disabled={isMultifactorArrayEmpty}
                      />
                      <FormInput<LoadMultifactorFormInputFields>
                        name={`screeners.${index}.minNumberOfFactors`}
                        type="number"
                        width="fullWidth"
                        placeholder="Minimum number of factors"
                        disabled={isMultifactorArrayEmpty}
                      />
                      <RemoveButton
                        disabled={screeners.length <= 1}
                        onClick={(): void => {
                          removeScreener(index);
                        }}
                      />
                    </Fragment>
                  ))}
                </Box>
                <AddButton
                  disabled={methods.formState.isSubmitting || isMultifactorArrayEmpty}
                  width="normal"
                  onClick={(): void => {
                    appendScreener({ ...DEFAULT_SCREENER });
                  }}
                >
                  Add new metric
                </AddButton>
                <DialogButton
                  renderDialog={({ onClose }): ReactElement => <CreateMultifactorDialog onClose={onClose} />}
                  width="normal"
                  startDecorator={<Add />}
                >
                  Create multifactor
                </DialogButton>
              </Stack>
              <Stack direction="row" justifyContent="end">
                <SubmitButton width="smaller" disabled={isMultifactorArrayEmpty}>
                  Load
                </SubmitButton>
              </Stack>
            </Stack>
          </form>
        </GFormProvider>
      </ModalDialog>
    </Modal>
  );
};

export default LoadMultifactorDialog;
