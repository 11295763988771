import isEqual from 'lodash/fp/isEqual';
import { type ReactElement, type ReactNode, useMemo, useState } from 'react';

import { NewsExplorerFilterContext } from './NewsExplorerFilterContext.tsx';
import { type NewsExplorerFilterValues, defaultFilterState } from './NewsExplorerService.ts';

function isNewsExplorerFilterDeafult(state: NewsExplorerFilterValues): boolean {
  return isEqual(state, defaultFilterState);
}

export const NewsExplorerFilterProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [state, setState] = useState<NewsExplorerFilterValues>(defaultFilterState);

  const cachedValue = useMemo(
    () => ({
      state,
      setState,
      isFilteredByNewsExplorerFilter: !isNewsExplorerFilterDeafult(state),
    }),
    [state]
  );

  return <NewsExplorerFilterContext.Provider value={cachedValue}>{children}</NewsExplorerFilterContext.Provider>;
};
