import { Stack, Typography } from '@mui/joy';
import type { FunctionComponent, PropsWithChildren } from 'react';

type LabelValuePairProps = PropsWithChildren<{ label: string }>;

const ValueWithLabel: FunctionComponent<LabelValuePairProps> = ({ label, children }) => {
  if (!children) {
    return null;
  }

  return (
    <Stack direction="row" spacing={2}>
      <Typography component="label" display="inline">
        {label}:
      </Typography>
      <Typography component="span" display="inline">
        {children}
      </Typography>
    </Stack>
  );
};

export default ValueWithLabel;
