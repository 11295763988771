import { Stack, Typography } from '@mui/joy';
import type { ReactElement } from 'react';

import { formatLabelToName } from '../../../../formatter.utils';
import { AssetIcon } from '../../AssetLabel';
import type { AssetLabelInput } from '../../AssetLabelService.ts';
import { IconVariant } from '../../cryptocurrencies/CryptocurrenciesData';
import SourceHelp from '../../SourceHelp';
import BigTile from '../BigTile/BigTile';
import VerticalLabelAndValue from '../VerticalLabelAndValue';

const AssetDetails = ({
  asset,
  clusterToGroup,
}: {
  asset: AssetLabelInput & { name?: string | null };
  clusterToGroup: Record<string, string>;
}): ReactElement => {
  return (
    <BigTile
      topLeft={
        <Stack direction="row" spacing={1} alignItems="center">
          <AssetIcon asset={asset} size={IconVariant.XXL} />
          <Typography level="h2">
            {asset.name ?? asset.symbol} <Typography color="neutral">({asset.symbol})</Typography>
          </Typography>
        </Stack>
      }
      bottomLeft={
        clusterToGroup.sector && (
          <VerticalLabelAndValue
            label={
              <>
                Sector{' '}
                <SourceHelp source="messari">
                  Sectors indicate the specific solution(s) provided by a cryptoasset network. Each sector belongs to a
                  single category.
                </SourceHelp>
              </>
            }
            value={formatLabelToName(clusterToGroup.sector)}
            variant="left"
          />
        )
      }
      bottomRight={
        clusterToGroup.category && (
          <VerticalLabelAndValue
            label={
              <>
                Category <SourceHelp source="messari">A crypto&apos;s primary use-case or application.</SourceHelp>
              </>
            }
            value={formatLabelToName(clusterToGroup.category)}
            variant="right"
          />
        )
      }
    />
  );
};

export default AssetDetails;
