import * as yup from 'yup';
import type { Schema } from 'yup';

import type { SelectOption } from 'components/technical/inputs/Select/Select.props.ts';
import { portfolioConstraintTypeSchema } from './PortfolioPrimaryConstraint.validation';
import { ISecondaryConstrainedQuantity } from '../../../../../generated/graphql.tsx';
import { yupWhen } from '../../../../../validation.ts';
import type { AssetLabelInput } from '../../../../market/asset/AssetLabelService.ts';
import { greaterThanMin, smallerThanMax } from '../MinMax.validation.ts';

import { ConstraintType } from '../ConstraintTypeValues.validation.ts';

export type PortfolioSecondaryConstraintOutput = {
  constrainedQuantity: ISecondaryConstrainedQuantity;
  constraintType: ConstraintType.Equal | ConstraintType.Between;
  constraintValue: { value: number | null; min: number | null; max: number | null };
  benchmark: AssetLabelInput | null;
};

const createSecondaryPortfolioConstraintValueTypeSchema = (value: ConstraintType): yup.Schema => {
  if (value === ConstraintType.Equal) {
    return yup.object({
      value: yup.number().required(),
    });
  }
  if (value === ConstraintType.Between) {
    return yup.object({
      min: yup.number().required().test('minValue', 'Must be smaller or equal to max', smallerThanMax),
      max: yup.number().required().test('maxValue', 'Must be greater or equal to min', greaterThanMin),
    });
  }

  return yup.mixed().nullable().optional();
};

export const secondaryConstraintQuantityLabels: Record<ISecondaryConstrainedQuantity, string> = {
  [ISecondaryConstrainedQuantity.Beta]: 'Expected beta',
  [ISecondaryConstrainedQuantity.NetExposure]: 'Net exposure',
};

export const secondaryConstraintQuantityValues: SelectOption<ISecondaryConstrainedQuantity>[] = [
  ISecondaryConstrainedQuantity.Beta,
  ISecondaryConstrainedQuantity.NetExposure,
].map((val) => ({
  value: val,
  label: secondaryConstraintQuantityLabels[val],
  key: secondaryConstraintQuantityLabels[val],
}));

export const createSecondaryConstraintSchema = (
  allowShortAndLeverage: boolean,
  secondaryConstraints: SelectOption<ISecondaryConstrainedQuantity>[]
  // biome-ignore lint/suspicious/noExplicitAny:
): Schema<unknown, any, any, 'd'> => {
  if (!allowShortAndLeverage) {
    return yup.mixed().nullable().optional().default(null);
  }

  return yup
    .object({
      constrainedQuantity: yup
        .string()
        .oneOf(secondaryConstraints.map((constraint) => constraint.value))
        .required(),
      benchmark: yup.object().when('constrainedQuantity', {
        is: ISecondaryConstrainedQuantity.Beta,
        // biome-ignore lint/suspicious/noThenProperty: yup api
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable().optional(),
      }),
      constraintValue: yupWhen(['constraintType'], ([constraintType]) => {
        return createSecondaryPortfolioConstraintValueTypeSchema(constraintType);
      }).required(),
      constraintType: portfolioConstraintTypeSchema,
    })
    .default(null)
    .nullable();
};
