import { IObjectiveType } from 'generated/graphql';
import type { SelectOption } from 'components/technical/inputs/Select/Select.props.ts';
import { riskMeasureTargets } from '../../metrics/PortfolioRiskMeasures.ts';
import { getName, getNameWithTooltip } from '../../metrics/MetricsData.tsx';

export type Objective = {
  objectiveType: IObjectiveType;
  risk: Label;
  benchmark?: {
    symbol: string;
    id: string;
  } | null;
};

const objectiveTypeLabels: Record<IObjectiveType, string> = {
  [IObjectiveType.MinRisk]: 'Minimize portfolio risk',
  [IObjectiveType.MaxRiskAdjustedReturns]: 'Maximize the portfolio risk-adjusted returns',
  [IObjectiveType.RiskParity]: 'Match risk weights',
};

const objectiveShortLabelsWithMetric: Record<IObjectiveType, (metricLabel: string) => string> = {
  [IObjectiveType.MinRisk]: (metricLabel) => metricLabel,
  [IObjectiveType.MaxRiskAdjustedReturns]: (metricLabel) => `${metricLabel} adjusted returns`,
  [IObjectiveType.RiskParity]: (metricLabel) =>
    metricLabel.endsWith('risk') ? `${metricLabel} imbalance` : `${metricLabel} risk imbalance`,
};

export const portfolioTypeValues: SelectOption<IObjectiveType>[] = [
  IObjectiveType.MaxRiskAdjustedReturns,
  IObjectiveType.MinRisk,
  IObjectiveType.RiskParity,
].map((value) => ({
  value,
  label: objectiveTypeLabels[value],
  key: objectiveTypeLabels[value],
}));

export const riskMeasureValues: SelectOption<string>[] = riskMeasureTargets.map((name) => ({
  value: name,
  label: getNameWithTooltip(name),
  key: getName(name),
}));

export function getObjectiveLabel(objective: Objective, variant: 'short' | 'long' = 'long'): string {
  const metricLabel = getName(objective.risk, {
    assetSymbol: objective.benchmark?.symbol,
  });

  if (variant === 'short') {
    return objectiveShortLabelsWithMetric[objective.objectiveType](metricLabel);
  }
  return `${objectiveTypeLabels[objective.objectiveType]} using ${metricLabel} as a risk metric`;
}
