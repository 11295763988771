import { Box, Button, Link } from '@mui/joy';
import type { CognitoUser } from 'amazon-cognito-identity-js';
import { type FunctionComponent, useState } from 'react';

import { useDrawer } from 'components/technical/Drawer/UseDrawer.tsx';
import { ProvideCredentialsStep } from './ProvideCredentialsStep';
import { ProvideMfaCodeStep } from './ProvideMfaCodeStep';

const LoginForm: FunctionComponent = () => {
  const { closeDrawer } = useDrawer();
  const [state, setState] = useState<
    | {
        step: 'TYPE_CREDENTIALS';
        user: null;
      }
    | { step: 'MFA'; user: CognitoUser }
  >({
    step: 'TYPE_CREDENTIALS',
    user: null,
  });

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: '100%',
        padding: '2rem',
        width: 'var(--login-drawer-width)',
      }}
    >
      {state.step === 'TYPE_CREDENTIALS' ? (
        <ProvideCredentialsStep
          onProvideMfa={(user: CognitoUser): void =>
            setState({
              step: 'MFA',
              user: user,
            })
          }
          onFinishedLogging={(): void => closeDrawer()}
        />
      ) : (
        <ProvideMfaCodeStep
          user={state.user}
          onGoBack={(): void =>
            setState({
              step: 'TYPE_CREDENTIALS',
              user: null,
            })
          }
          onFinishedLogging={(): void => closeDrawer()}
        />
      )}
      <Button
        variant="plain"
        component={Link}
        href="https://genieai.tech/terms-and-policies"
        target="_blank"
        color="neutral"
        sx={{
          marginBlockStart: 1,
        }}
      >
        Terms and policies
      </Button>
    </Box>
  );
};

export default LoginForm;
