import type { ColumnDef } from '@tanstack/react-table';
import { type CellContext, createColumnHelper } from '@tanstack/table-core';
import type { ReactElement, ReactNode } from 'react';
import SeeMoreDropdown from 'components/technical/SeeMoreDropDown/SeeMoreDropdown.tsx';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import ConfirmationDialog from 'components/technical/form/dialog/ConfirmationDialog';

import GTable from 'components/technical/GTable/GTable';

import { type CalendarEvent, EventView } from './EventView';
import { CalendarEventsDocument, useDeleteCalendarEventMutation } from '../../../../../generated/graphql';
import { DialogMenuItem } from '../../../../technical/DialogDropdown/DialogMenuItem.tsx';

export const EventList = ({ events }: { events: CalendarEvent[] }): ReactElement => {
  const [deleteCalendarEvent] = useDeleteCalendarEventMutation({
    ignoreResults: true,
    refetchQueries: [CalendarEventsDocument],
  });

  const { showGraphqlError, showSuccessMessage } = useFeedback();

  const columnHelper = createColumnHelper<CalendarEvent>();
  const columns: ColumnDef<CalendarEvent>[] = [
    columnHelper.display({
      header: 'Main',
      meta: {
        cellStyles: {
          width: 'auto',
        },
      },
      cell: (ctx: CellContext<CalendarEvent, unknown>): ReactNode => {
        return <EventView event={ctx.row.original} />;
      },
    }),
    columnHelper.display({
      header: 'Actions',
      meta: {
        align: 'right',
        cellStyles: {
          width: '50px',
        },
      },
      cell: (props: CellContext<CalendarEvent, unknown>): ReactNode => {
        return (
          <SeeMoreDropdown>
            <DialogMenuItem
              renderDialog={({ onClose }): ReactElement => (
                <ConfirmationDialog
                  onClose={onClose}
                  onApprove={async (): Promise<void> => {
                    try {
                      await deleteCalendarEvent({
                        variables: {
                          id: props.row.original.id,
                        },
                      });
                      showSuccessMessage('Event successfully deleted');
                    } catch (e) {
                      showGraphqlError(e);
                    }
                    onClose();
                  }}
                >
                  Are you sure you want to delete event?
                </ConfirmationDialog>
              )}
            >
              Remove
            </DialogMenuItem>
          </SeeMoreDropdown>
        );
      },
    }),
  ];

  return <GTable columns={columns} data={events} hideHeaders />;
};
