import { Chip, Stack } from '@mui/joy';
import type { ReactElement } from 'react';
import * as yup from 'yup';

import { Add, ContentPasteOutlined } from '@mui/icons-material';
import {
  IPeriodUnit,
  RebalancingRulesDocument,
  useCreateRebalancingRuleMutation,
} from '../../../../../../generated/graphql.tsx';
import type dayjs from 'dayjs';
import { convertDateInUtcToUTCISODate, isValidDayjsDate } from '../../../../../date.utils.ts';
import { useNavigate, useParams } from 'react-router';
import HeaderBar from '../../../../../technical/HeaderBar/HeaderBar.tsx';
import { defaultRowSpacing } from '../../../../../StackSpacing.ts';
import gYupResolver from '../../../../../technical/form/gYupResolver.ts';
import { useGraphQLApiError } from '../../../../../technical/form/UseGraphQLApiError.tsx';
import { useFeedback } from '../../../../../technical/Feedback/UseFeedback.tsx';
import type { FormInputType } from '../../../../../technical/form/Form.types.ts';
import { useForm, useWatch } from 'react-hook-form';
import GFormProvider from '../../../../../technical/form/GFormProvider.tsx';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage.tsx';
import SubmitButton from '../../../../../technical/form/SubmitButton.tsx';
import FormInput from 'components/technical/form/FormInput.tsx';
import { FormDateInput } from '../../../../../technical/form/FormDateInput.tsx';
import FormSelect from '../../../../../technical/form/FormSelect.tsx';
import { periodUnits } from '../../../../../bookkeeping/investments/CreateInvestment.validation.ts';

export interface FormOutputRebalancingRule {
  start: dayjs.Dayjs;
  periodUnit: IPeriodUnit;
  periodValue: number;
  tradingFee: number;
}

export type FormOutputFields = {
  name: string;
  rebalancingRule: FormOutputRebalancingRule;
};

const formSchema = yup.object({
  name: yup.string().required(),
  rebalancingRule: yup
    .object({
      start: yup.mixed().required().test('validDate', 'Date is invalid', isValidDayjsDate),
      periodUnit: yup.string().required().oneOf(Object.values(IPeriodUnit)),
      periodValue: yup.number().required().min(1),
    })
    .required(),
});

type FormInputFields = FormInputType<FormOutputFields>;

export const defaultName = 'Rule 1';
const CreateRebalancingRuleForm = (): ReactElement => {
  const { portfolioDefinitionId } = useParams();
  if (!portfolioDefinitionId) {
    throw new Error('Missing portfolio definition');
  }

  const methods = useForm<FormInputFields>({
    resolver: gYupResolver(formSchema),
    defaultValues: {
      name: defaultName,
    },
  });

  const name = useWatch({
    name: 'name',
    control: methods.control,
  });

  const [createRebalancingRule] = useCreateRebalancingRuleMutation();
  const { onErrorAndThrow } = useGraphQLApiError(methods);
  const { showSuccessMessage } = useFeedback();
  const navigate = useNavigate();

  const handleFormSubmit = async (input: FormInputFields): Promise<void> => {
    const data = input as unknown as FormOutputFields;

    try {
      const rule = data.rebalancingRule;
      await createRebalancingRule({
        variables: {
          input: {
            name: data.name,
            portfolioDefinitionId,
            startAt: convertDateInUtcToUTCISODate(rule.start),
            endAt: null,
            periodUnit: rule.periodUnit,
            periodValue: rule.periodValue,
            maxRebalancingCost: null,
            tradingFee: '0',
          },
        },
        refetchQueries: [RebalancingRulesDocument],
      });

      showSuccessMessage('New rebalancing rule successfully added');

      navigate(`/app/copilot/lab/portfolio/${portfolioDefinitionId}/rebalancing-rules`);
    } catch (e) {
      onErrorAndThrow(e);
    }
  };

  const width = 'normal';
  return (
    <Stack spacing={defaultRowSpacing} alignItems="flex-start">
      <HeaderBar
        title={
          <>
            {name ? name : defaultName} <Chip startDecorator={<ContentPasteOutlined fontSize="small" />}>Draft</Chip>
          </>
        }
      />
      <GFormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
          <Stack spacing={defaultRowSpacing}>
            <FormInput<FormInputFields> type="text" name="name" label="Name" width={width} autoComplete="off" />
            <Stack direction="row" spacing={defaultRowSpacing}>
              <FormDateInput<FormInputFields> name="rebalancingRule.start" label="Start date" width={width} />
              <FormInput<FormInputFields>
                name={'rebalancingRule.periodValue' as const}
                type="number"
                label="Period interval"
                width={width}
              />
              <FormSelect<FormInputFields>
                name={'rebalancingRule.periodUnit' as const}
                options={periodUnits}
                label="Period"
                width={width}
              />
            </Stack>
            <Stack alignItems="flex-start" spacing={defaultRowSpacing}>
              <GraphQLApiFormErrorMessage />
              <SubmitButton width="normal" startDecorator={<Add />}>
                Add rule
              </SubmitButton>
            </Stack>
          </Stack>
        </form>
      </GFormProvider>
    </Stack>
  );
};

export default CreateRebalancingRuleForm;
