import { Stack } from '@mui/joy';
import type { Dayjs } from 'dayjs';
import { type FunctionComponent, useState } from 'react';
import { range7Days } from 'components/predefinedDateRanges';
import PredefinedDateRangeButtonsWithCalendar from 'components/technical/inputs/date/PredefinedDateRangeButtonsWithCalendar';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';

import { useRealizedPnlAnalysisQuery } from 'generated/graphql';
import RealizedPnlGrid from './RealizedPnlGrid.tsx';
import { useReportAssetGroup } from '../../../UseReportAssetGroups';
import { convertDateRangeToSinceToDate } from 'components/technical/inputs/date/dateRange.utils';
import { useSubAccountAssetFilters } from '../../../technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import { defaultRowSpacing } from '../../../StackSpacing.ts';

const RealizedPnl: FunctionComponent = () => {
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(range7Days.value);
  const { subAccountAssetFilters } = useSubAccountAssetFilters();

  const realizedPnlQueryResult = useDefaultErrorHandling(
    useRealizedPnlAnalysisQuery({
      variables: {
        ...convertDateRangeToSinceToDate(dateRange),
        ...subAccountAssetFilters,
      },
    })
  );

  const reportAssetGroup = useReportAssetGroup();

  if (!reportAssetGroup.loaded) {
    return reportAssetGroup.Fallback();
  }

  return (
    <Stack spacing={defaultRowSpacing} height="100%">
      <Stack direction="row" spacing={3} alignItems="center">
        <PredefinedDateRangeButtonsWithCalendar
          alignRight={false}
          defaultValue={range7Days}
          onChange={(val): void => setDateRange(val)}
        />
      </Stack>
      {realizedPnlQueryResult.loaded ? (
        <RealizedPnlGrid
          rows={realizedPnlQueryResult.data.portfolio.realizedPnl}
          subFunds={realizedPnlQueryResult.data.portfolio.subFunds.list}
        />
      ) : (
        <realizedPnlQueryResult.Fallback />
      )}
    </Stack>
  );
};

export default RealizedPnl;
