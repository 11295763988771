// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { type Resolver, yupResolver } from '@hookform/resolvers/yup';

import { getEnvConfig } from '../../../config';

const gYupResolver: Resolver = (...args: Parameters<typeof yupResolver>) => {
  const output = yupResolver(...args);

  const { logFormErrors } = getEnvConfig();
  if (!logFormErrors) {
    return output;
  }

  return async (
    values: Record<string, unknown>,
    context: unknown,
    // biome-ignore lint/suspicious/noExplicitAny:
    options: any
  ) => {
    console.warn('Validation input', values);
    const res = await output(values, context, options);
    console.warn('Validation errors', res.errors);
    return res;
  };
};

export default gYupResolver;
