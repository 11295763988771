import { Stack } from '@mui/joy';
import {
  type IMultifactorScores,
  useComputeMultifactorScoresLazyQuery,
  useMultifactorSuspenseQuery,
} from 'generated/graphql';
import DialogButton from 'components/technical/inputs/GButton/DialogButton';
import { type ReactElement, type ReactNode, Suspense } from 'react';
import type { LoadMultifactorFormInputFields, LoadMultifactorFormOutputFields } from './AssetScreenerService';
import AssetScreenerTable from './AssetScreenerTable';
import { convertDateInUtcToUTCISODate } from 'components/date.utils';
import Loader from 'components/technical/Loader/Loader';
import LoadMultifactorDialog from './LoadMultifactorDialog';

function AssetScreenerTab(): ReactNode {
  const getAllMultifactorsForUserQuery = useMultifactorSuspenseQuery();
  const [fetchScores, { data: computedScores, loading: isComputedScoresLoading }] =
    useComputeMultifactorScoresLazyQuery();

  const handleSubmit = (data: LoadMultifactorFormInputFields): void => {
    const outData = data as unknown as LoadMultifactorFormOutputFields;
    fetchScores({
      variables: {
        input: {
          date: convertDateInUtcToUTCISODate(outData.date),
          screeners: outData.screeners,
        },
      },
    });
  };

  if (isComputedScoresLoading) {
    return <Loader />;
  }

  return (
    <Stack spacing={1.5} height="100%">
      <Stack direction="row" justifyContent="flex-end">
        <DialogButton
          renderDialog={({ onClose }): ReactElement => (
            <LoadMultifactorDialog
              onClose={onClose}
              handleSubmit={handleSubmit}
              multifactorQuery={getAllMultifactorsForUserQuery}
            />
          )}
          width="xl2"
          disabled={!getAllMultifactorsForUserQuery.data.multifactor.getAllMultifactorsForUser.length}
        >
          Load multifactors
        </DialogButton>
      </Stack>

      <Suspense fallback={<Loader />}>
        <AssetScreenerTable
          scores={computedScores?.multifactor.computeMultifactorScores.scores as Array<IMultifactorScores>}
        />
      </Suspense>
    </Stack>
  );
}

export default AssetScreenerTab;
