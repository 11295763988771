import type { StaticAutocompleteOption } from 'components/technical/inputs/Autocomplete/StaticSingleAutocomplete.props';
import { HEIGHT_PX, SubAccountLabel } from './SubAccountLabel.tsx';
import type { IAccount, ISubAccount } from '../../../generated/graphql';
import { IconVariant } from '../../market/asset/cryptocurrencies/CryptocurrenciesData';
import { GENIE_VENUE, venues } from '../../venue/VenueData.tsx';
import { AccountLabel } from './AccountLabel.tsx';
import isNil from 'lodash/fp/isNil';

type SubAccount = Pick<ISubAccount, 'id' | 'name'>;
type Account = Pick<IAccount, 'venue' | 'name' | 'id'>;

export type CreateAccountIdAutocompleteOptionsInputAccount = Account;
export type CreateSubAccountIdAutocompleteOptionsInputAccount = Account & {
  subAccounts: SubAccount[];
};

export type SubAccountOptionValue<TSubAccount extends SubAccount> = TSubAccount & {
  account: Omit<Account, 'subAccounts'>;
};

export type AccountOptionValue<TAccount extends Account> = TAccount;

export const createAccountAutocompleteOptions = <TAccount extends Account>(
  accounts: Array<TAccount>
): {
  options: StaticAutocompleteOption<AccountOptionValue<TAccount>>[];
  optionHeight: number;
  limitTags: number;
  isValueEqual: (
    a: AccountOptionValue<TAccount> | undefined | null,
    b: AccountOptionValue<TAccount> | undefined | null
  ) => boolean;
} => {
  return {
    options: accounts.map((account) => ({
      label: <AccountLabel account={account} size={IconVariant.MEDIUM} wrap={false} plain />,
      value: account,
      searchText: account.name,
      key: account.id,
    })),
    optionHeight: HEIGHT_PX,
    limitTags: 1,
    isValueEqual: (
      a: AccountOptionValue<TAccount> | undefined | null,
      b: AccountOptionValue<TAccount> | undefined | null
    ): boolean => (!a && !b) || a?.id === b?.id,
  };
};

export const createSubAccountAutocompleteOptions = <TSubAccount extends SubAccount>(
  accounts: Array<
    Account & {
      subAccounts: TSubAccount[];
    }
  >
): {
  options: StaticAutocompleteOption<SubAccountOptionValue<TSubAccount & { account: Account }>>[];
  optionHeight: number;
  limitTags: number;
  groupBy: (option: StaticAutocompleteOption<(TSubAccount & { account: Account }) | undefined>) => string;
  isValueEqual: (
    a: SubAccountOptionValue<TSubAccount> | undefined | null,
    b: SubAccountOptionValue<TSubAccount> | undefined | null
  ) => boolean;
} => {
  return {
    options: Object.values(accounts).flatMap((account) =>
      account.subAccounts.map((subAccount) => ({
        label: <SubAccountLabel subAccount={{ ...subAccount, account }} size={IconVariant.MEDIUM} wrap={false} plain />,
        value: {
          ...subAccount,
          account: {
            ...account,
            subAccounts: undefined, // better to avoid circle references
          },
        },
        searchText: subAccount.name,
        key: subAccount.id,
      }))
    ),
    optionHeight: HEIGHT_PX,
    limitTags: 1,
    groupBy: (option: StaticAutocompleteOption<(TSubAccount & { account: Account }) | undefined>): string =>
      option.value !== undefined ? option.value.account.name : '',
    isValueEqual: (
      a: SubAccountOptionValue<TSubAccount> | undefined | null,
      b: SubAccountOptionValue<TSubAccount> | undefined | null
    ): boolean => (!a && !b) || a?.id === b?.id,
  };
};

export const createSubAccountIdAutocompleteOptions = (
  accounts: CreateSubAccountIdAutocompleteOptionsInputAccount[]
): {
  options: StaticAutocompleteOption<string>[];
  optionHeight: number;
  limitTags: number;
  groupBy: (option: StaticAutocompleteOption<string | null>) => string;
  isValueEqual: (a: string | undefined | null, b: string | undefined | null) => boolean;
} => {
  const props = createSubAccountAutocompleteOptions(accounts);
  const idToAccountName = Object.fromEntries(
    accounts.flatMap((account) => account.subAccounts.map((subAccount) => [subAccount.id, account.name]))
  );
  return {
    ...props,
    groupBy: (option: StaticAutocompleteOption<string | null>): string =>
      !isNil(option.value) ? idToAccountName[option.value] : '',
    options: props.options.map((option) => ({
      ...option,
      value: option.value.id,
    })),
    isValueEqual: (a: string | undefined | null, b: string | undefined | null): boolean => (!a && !b) || a === b,
  };
};

export const createAccountIdAutocompleteOptions = (
  accounts: CreateAccountIdAutocompleteOptionsInputAccount[]
): {
  options: StaticAutocompleteOption<string>[];
  optionHeight: number;
  limitTags: number;
  isValueEqual: (a: string | undefined | null, b: string | undefined | null) => boolean;
} => {
  const props = createAccountAutocompleteOptions(accounts);
  const optionsWithIdAsValue = props.options.map((option) => ({
    ...option,
    value: option.value.id,
  }));
  return {
    ...props,
    options: optionsWithIdAsValue,
    isValueEqual: (a: string | undefined | null, b: string | undefined | null): boolean => (!a && !b) || a === b,
  };
};

export const createSubAccountIdSelectOptions = (
  accounts: CreateSubAccountIdAutocompleteOptionsInputAccount[]
): StaticAutocompleteOption<string>[] => {
  return createSubAccountIdAutocompleteOptions(accounts).options;
};

export enum AccountType {
  Blockchain = 'BLOCKCHAIN',
  Custodian = 'CUSTODIAN',
  Virtual = 'VIRTUAL',
}

export const calculateAccountType = (account: { venue: { label: string } }): AccountType => {
  const venueLabel = account.venue.label;
  if (venueLabel === GENIE_VENUE) {
    return AccountType.Virtual;
  }

  const venue = venues[venueLabel];
  if (venue?.blockchain) {
    return AccountType.Blockchain;
  }

  return AccountType.Custodian;
};
