import { Skeleton, Typography } from '@mui/joy';
import type { FunctionComponent, ReactNode } from 'react';

import ChartWithTitle from './ChartWithTitle.tsx';
import { ChartNoAccountFallback } from './portfolio/NoSubAccountFallback.tsx';

export const ChartSkeleton: FunctionComponent<{ title: string; refetch: () => void; skeletonType: ReactNode }> = ({
  title,
  refetch,
  skeletonType,
}) => {
  return (
    <ChartWithTitle title={title} paper showCard>
      <ChartNoAccountFallback refetch={refetch}>
        <Typography level="body-md">
          <Skeleton animation={false} width={100} />
        </Typography>
        {skeletonType}
      </ChartNoAccountFallback>
    </ChartWithTitle>
  );
};
