import { Modal, ModalDialog } from '@mui/joy';
import type { ReactElement, ReactNode } from 'react';

import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';

export const EnableMfaDialogShell = ({
  onClose,
  children,
}: {
  onClose: () => void;
  children: ReactNode;
}): ReactElement => {
  return (
    <Modal open={true} onClose={onClose}>
      <ModalDialog size={'lg'}>
        <GDialogHeader>Setup Two-Factor Authentication</GDialogHeader>
        {children}
      </ModalDialog>
    </Modal>
  );
};
