import { Modal, ModalDialog, Stack } from '@mui/joy';
import type { ReactElement } from 'react';
import { useForm } from 'react-hook-form';

import GFormProvider from 'components/technical/form/GFormProvider';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton.tsx';
import { useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError.tsx';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import { FormInput } from 'components/technical/inputs';
import { formSchema } from './UpdateNameDialogSchema.ts';
import { AccountsDocument, type useUpdateAccountMutation } from '../../../generated/graphql';

interface FormState {
  name: string;
}

const UpdateNameDialog = ({
  onClose,
  onUpdate,
  mutation,
  id,
  title,
}: {
  onClose: () => void;
  onUpdate: () => void;
  id: string;
  mutation: typeof useUpdateAccountMutation;
  title: string;
}): ReactElement => {
  const methods = useForm<FormState>({
    resolver: gYupResolver(formSchema),
    defaultValues: {
      name: '',
    },
  });

  const { onErrorAndThrow } = useGraphQLApiError(methods);

  const [mutate] = mutation({
    refetchQueries: [AccountsDocument],
  });

  const handleFormSubmit = async (data: FormState): Promise<void> => {
    try {
      await mutate({
        variables: {
          input: {
            id: id,
            name: data.name,
          },
        },
      });

      onUpdate();
    } catch (e) {
      onErrorAndThrow(e);
    }
  };

  return (
    <Modal open={true} onClose={onClose}>
      <ModalDialog>
        <GFormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
            <GDialogHeader>{title}</GDialogHeader>
            <Stack spacing={3}>
              <FormInput label="Name" name="name" width="fullWidth" />
              {/* jscpd:ignore-start */}
              <Stack alignItems="center" spacing={1.5}>
                <GraphQLApiFormErrorMessage />
                <SubmitButton width="xl2">Update</SubmitButton>
              </Stack>
              {/* jscpd:ignore-end */}
            </Stack>
          </form>
        </GFormProvider>
      </ModalDialog>
    </Modal>
  );
};

export default UpdateNameDialog;
