import type { ReactElement } from 'react';

import {
  createParamInputElement,
  isSingleParameterOption,
  type ParameterDescription,
  validateRiskMetricParameterValues,
  visibleParameters,
} from './RiskMetricsParameterService.tsx';
import type { AssetLabelInput } from '../../market/asset/AssetLabelService.ts';

const RiskMetricsParameters = (props: {
  values: Record<string, string | string[] | AssetLabelInput[]>;
  metricParameters: ParameterDescription[];
  setValues: (values: Record<string, string | string[] | AssetLabelInput[]>) => void;
}): ReactElement => {
  const validationErrors = validateRiskMetricParameterValues(props.values, props.metricParameters);
  const visible = visibleParameters(props.values, props.metricParameters);
  return (
    <>
      {props.metricParameters
        .filter((param) => !isSingleParameterOption(param))
        .filter((param) => visible.includes(param.name))
        .map((param) => {
          return createParamInputElement({
            param,
            metricParameterValues: props.values,
            onMetricParameterValuesChanged: props.setValues,
            validationErrors,
          });
        })}
    </>
  );
};

export default RiskMetricsParameters;
