import type { ReportPreset } from 'components/technical/grids/GAgGridPresets.types';

// default presets were generated via "See all" -> "Copy current report state as JSON"
export const assetContributionDefaultPresets: ReportPreset[] = [
  {
    name: 'Default',
    isDefault: true,
    settings: {
      filters: {},
      pivotMode: false,
      columns: [
        {
          colId: 'symbol',
          width: 252,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'underlyingAsset',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'cluster-sector',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'cluster-category',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'cluster-cluster_price_action_optics',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'cluster-mcap_groups',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'cluster-cluster',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'cluster-testCluster_up',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'cluster-testCluster',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'lastDay.cumulativeContributionsToReturn',
          width: 243,
          hide: false,
          pinned: null,
          sort: 'desc',
          sortIndex: 0,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'lastDay.cumulativeAssetReturn',
          width: 157,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'firstDay.cashWeight',
          width: 104,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'lastDay.cashWeight',
          width: 103,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: '3',
          width: 103,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'firstDay.exposure',
          width: 132,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'lastDay.exposure',
          width: 133,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: '5',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'firstDay.amount',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'lastDay.amount',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: '7',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'firstDay.openPrice',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'lastDay.closePrice',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: '9',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
      ],
    },
  },
];
