import { Card, Stack } from '@mui/joy';
import isNil from 'lodash/fp/isNil';
import type { ReactElement } from 'react';
import LineChartSkeleton from 'components/technical/charts/LineChart/LineChartSkeleton';
import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import SectionColumn from 'components/technical/layout/Column/SectionColumn';
import { IReturnStatName } from 'generated/graphql';

import { PortfolioReturnsPerformanceChart } from './portfolioOptimizer/results/PortfolioReturnsPerformanceChart';
import { PortfolioReturnsPerformanceList } from './portfolioOptimizer/results/PortfolioReturnsPerformanceList';

export interface PortfolioPerformanceResult {
  returns: number[] | undefined;
  statistics: { name: string; value: number }[] | undefined;
}

const PortfolioPerformanceContainer = (props: PortfolioPerformanceResult): ReactElement => {
  const stats = props.statistics;
  const validStatNames = Object.values(IReturnStatName) as string[];
  return (
    <SectionColumn>
      <HeaderBar title="Portfolio returns" />
      {!isNil(props.returns) || !isNil(props.statistics) ? (
        <Stack spacing={1}>
          {props.returns && (
            <Card>
              <PortfolioReturnsPerformanceChart
                returns={[
                  {
                    data: props.returns,
                    title: 'Returns',
                  },
                ]}
              />
            </Card>
          )}
          {stats && (
            <Card>
              <Stack spacing={1}>
                <PortfolioReturnsPerformanceList
                  returns={stats.filter((stat): stat is { name: IReturnStatName; value: number } =>
                    validStatNames.includes(stat.name)
                  )}
                  title="Probability"
                />
              </Stack>
            </Card>
          )}
        </Stack>
      ) : (
        <Card>
          <LineChartSkeleton />
        </Card>
      )}
    </SectionColumn>
  );
};

export default PortfolioPerformanceContainer;
