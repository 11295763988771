export enum UserSettings {
  CounterpartyRiskReport = 'counterpartyRiskReport',
  AssetRiskMetricsReport = 'assetRiskMetricsReport',
  PositionsSummaryPresets = 'positionsSummaryPresets',
  PositionsSpotPresets = 'positionsSpotPresets',
  PositionsDerivativesPresets = 'positionsDerivativesPresets',
  AssetContributionPresets = 'assetContributionPresets',
  RealizedPnlPresets = 'realizedPnlPresets',
  PortfolioReportPresets = 'portfolioReportPresets',
  TilesDateRange = 'tilesDateRange',
  StressTestResultsRefValues = 'stressTestResultsRefValues',
  StressTestResultsShocks = 'stressTestResultsShocks',
  AssetScreenerPresets = 'assetScreenerPresets',
}
