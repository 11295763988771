import { type ReactElement, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import FormSwitch from 'components/technical/form/FormSwitch.tsx';
import { FormInput } from 'components/technical/inputs';
import type { FormInputType } from '../../../technical/form/Form.types.ts';
import type { ShockStepOutput } from './ModelAndAssumptionsStep.validation.tsx';

const ShockRow = ({
  name,
  label,
}: {
  name: 'shocks.volatilityShock' | 'shocks.priceShock' | 'shocks.interestFreeRateShock';
  label: string;
}): ReactElement => {
  const enabledField = `${name}.enabled` as const;
  const enabled = useWatch({
    name: enabledField,
  });

  const { clearErrors } = useFormContext<FormInputType<ShockStepOutput>>();
  useEffect(() => {
    if (enabled) {
      return;
    }

    const valueField = `${name}.value` as const;
    clearErrors(valueField);
  }, [enabled, clearErrors, name]);

  return (
    <>
      <FormSwitch name={enabledField} label={label} />
      <FormInput name={`${name}.value`} disabled={!enabled} width="xl2" startDecorator="%" />
    </>
  );
};

export default ShockRow;
