import { Divider, Stack } from '@mui/joy';
import type { ReactElement, ReactNode } from 'react';
import React from 'react';

export const GCardContentWithDivider = ({ children }: { children: ReactNode }): ReactElement => {
  const childrenArr = React.Children.toArray(children);
  return (
    <Stack direction="column" height="100%" rowGap={1.5}>
      {childrenArr.slice(0, -1)}
      <Divider />
      {childrenArr.at(-1)}
    </Stack>
  );
};
