import type { ReactElement } from 'react';
import { Navigate, useParams } from 'react-router';
import Message from 'components/technical/Message';

import SinglePrivateAsset from './private/SinglePrivateAsset';
import SinglePublicAsset from './public/SinglePublicAsset';
import { isDerivativeAsset, isPrivateAsset, isPublicAsset } from '../Asset.types';
import { useAsset } from '../UseAsset';

const SingleAssetContainer = (): ReactElement => {
  const { assetId } = useParams();

  const { loaded, data: asset, Fallback } = useAsset(assetId!);
  if (!loaded) {
    return <Fallback />;
  }

  if (isPublicAsset(asset)) {
    return <SinglePublicAsset asset={asset} />;
  }

  if (isPrivateAsset(asset)) {
    return <SinglePrivateAsset asset={asset} />;
  }

  if (isDerivativeAsset(asset)) {
    // redirect to a single asset page for base asset
    return <Navigate to={`/app/market/assets/${asset.derivativeDetails.baseAsset.id}/derivatives`} replace />;
  }

  return <Message>Only public and private assets are supported</Message>;
};

export default SingleAssetContainer;
