import isNil from 'lodash/fp/isNil';

import type { ParameterDescription } from '../copilot/risk/RiskMetricsParameterService.tsx';
import { type IMetricCalculatorParameterInput, IParameterType } from '../../generated/graphql';
import type { AssetLabelInput } from '../market/asset/AssetLabelService.ts';

const validateEmptyValues = (unknownValues: unknown[] | null | undefined, name: string): unknownValues is unknown[] => {
  if (isNil(unknownValues)) {
    console.error('Missing values for param', name);
    return false;
  }

  if (unknownValues.length === 0) {
    console.error('Empty values for param', name);
    return false;
  }

  return true;
};

const calculateParameterDefaultValue = (param: ParameterDescription): string | string[] | AssetLabelInput[] => {
  if (param.type === IParameterType.Int) {
    if (!param.minMax) {
      console.error('Missing minMaxParam value for metric', param.name);
      return '';
    }

    return param.minMax.default.toString();
  }

  if (param.type === IParameterType.Options) {
    if (!validateEmptyValues(param.options, param.name)) {
      return '';
    }

    return [param.options[0].value];
  }

  if (!validateEmptyValues(param.benchmarks, param.name)) {
    return '';
  }

  return [param.benchmarks[0]];
};

export const calculateDefaultParameters = (
  params: ParameterDescription[]
): Record<string, string | string[] | AssetLabelInput[]> => {
  return Object.fromEntries(params.map((param) => [param.name, calculateParameterDefaultValue(param)]));
};

export const createMetricRequestParameters = (
  values: Record<string, string | string[] | AssetLabelInput[]>,
  params: ParameterDescription[]
): IMetricCalculatorParameterInput[] => {
  const input: IMetricCalculatorParameterInput[] = [];
  for (const param of params) {
    const passParam =
      isNil(param.visibleIf) ||
      param.visibleIf.every((visibleIf) => {
        const value = values[visibleIf.parameter] as string[] | undefined;
        if (isNil(value)) {
          return true;
        }

        return value.includes(visibleIf.selectedOption);
      });

    if (!passParam) {
      continue;
    }

    const value = values[param.name];
    if (param.type === IParameterType.Int) {
      input.push({
        name: param.name,
        intValues: (value as string).split(',').map((val) => Number(val)),
      });
    } else if (param.type === IParameterType.Options) {
      const availableValues = new Set((param.options ?? []).map((opt) => opt.value));
      const selectedAvailableOptions = (value as string[]).filter((val) => availableValues.has(val));
      // when previous metrics had options realized, expected and someone selected only expected, then
      // after switching to a new metric with only realized option, there is no value selected. In this case,
      // we want to send a request for only realized option and later when someone switches to a different metric we want
      // to use expected
      if (availableValues.size === 1 && selectedAvailableOptions.length === 0) {
        selectedAvailableOptions.push(...availableValues.values());
      }

      input.push({
        name: param.name,
        strValues: selectedAvailableOptions,
      });
    } else {
      const value = values[param.name] as AssetLabelInput[];
      input.push({
        name: param.name,
        strValues: value.map((benchmark) => benchmark.id) ?? [],
      });
    }
  }

  return input;
};

export const formatMetricParameters = (
  params: {
    asset?: { symbol: string } | null;
    intValue?: number | null;
    name: string;
    strValue?: string | null;
  }[],
  paramDescription: {
    name: string;
    type: IParameterType;
    options?:
      | {
          id: string;
          value: string;
          label: string;
        }[]
      | null
      | undefined;
  }[]
): string => {
  const nameToDescription: Record<string, ParameterDescription> = Object.fromEntries(
    paramDescription.map((param) => [param.name, param])
  );
  return params
    .map((param) => {
      const description = nameToDescription[param.name];
      const paramType = description.type;
      if (paramType === IParameterType.Int) {
        return param.intValue?.toFixed(0) ?? '';
      }

      if (paramType === IParameterType.Options) {
        return (description.options ?? []).find((desc) => desc.value === param.strValue)?.label ?? '';
      }
      return param.asset?.symbol ?? '?';
    })
    .join(' - ');
};
