import type { CSSProperties } from 'react';

const widths = {
  smaller: '100px',
  small: '150px',
  normal: '200px',
  xl: '240px',
  xl2: '280px',
  xl3: '340px',
  xl4: '400px',
} as const;

export { widths };

const widthSx = Object.fromEntries(
  Object.entries(widths).map(([name, width]) => [
    name,
    {
      width,
      maxWidth: '100%',
    },
  ])
) as Record<keyof typeof widths, CSSProperties>;

export default widthSx;
