import { Card, Stack } from '@mui/joy';
import { isNil, uniq } from 'lodash/fp';
import type { FunctionComponent } from 'react';

import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import { IAssetType, type IPortfolioDashboardInputQuery } from '../../../generated/graphql';
import NewsSectionContainer from '../../market/asset/NewsSectionContainer';

const AssetNewsTitle = 'Asset news';
const ASSET_NEWS_HEIGHT = 475;

type SubAccountPositions = IPortfolioDashboardInputQuery['portfolio']['positions']['positions'];

const AssetNews: FunctionComponent<{
  positions: SubAccountPositions;
}> = ({ positions }) => {
  const publicAssetIds = positions
    .map((pos) => (pos.asset.type === IAssetType.Public ? pos.asset.id : undefined))
    .filter((id): id is string => !isNil(id));
  const derivativeBaseAssetIds = positions
    .map((pos) => pos.asset.derivativeDetails?.baseAsset.id)
    .filter((id): id is string => !isNil(id));

  const uniqAssetIds = uniq([...publicAssetIds, ...derivativeBaseAssetIds]);
  return (
    <Stack gap={1.5}>
      <HeaderBar title={AssetNewsTitle} />
      <Card>
        <NewsSectionContainer assetIds={uniqAssetIds} height={ASSET_NEWS_HEIGHT} />
      </Card>
    </Stack>
  );
};

export default AssetNews;
