import type { BigNumber } from 'mathjs';
import type { ReactElement } from 'react';

import AssetLabel, { type AssetLabelProps } from './AssetLabel';
import { formatNumber } from '../../formatter.utils';
import isNil from 'lodash/fp/isNil';
import { Typography } from '@mui/joy';
import type { DefaultColorPalette } from '@mui/joy/styles/types/colorSystem';

const AssetValueView = ({
  asset,
  value,
  link,
  color,
  inline = false,
}: {
  asset: AssetLabelProps['asset'] | null | undefined;
  value: string | BigNumber;
  link: boolean;
  color?: DefaultColorPalette;
  inline?: boolean;
}): ReactElement => {
  return (
    <Typography
      color={color}
      component={inline ? 'span' : 'div'}
      sx={{
        display: inline ? 'span' : 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        columnGap: 0.5,
      }}
    >
      {formatNumber(value)} {!isNil(asset) && <AssetLabel format="short" asset={asset} link={link} hideDebug plain />}
    </Typography>
  );
};

export default AssetValueView;
