import type { ReactElement } from 'react';
import GCardKeyValuePairs from './GCardKeyValuePairs.tsx';
import { Box, Stack } from '@mui/joy';
import type { SystemProps } from '@mui/system';

export const GCardKeyValueContent = ({
  data,
  updateSummary,
  mt,
}: {
  data: { key: string; value: string }[];
  updateSummary: { key: string; value: string }[];
  mt?: SystemProps['mt'];
}): ReactElement => {
  return (
    <Stack flexGrow={1} rowGap={1.5} mt={mt}>
      <GCardKeyValuePairs data={data} />
      <Box mt={'auto'}>
        <GCardKeyValuePairs data={updateSummary} />
      </Box>
    </Stack>
  );
};
