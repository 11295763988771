import { Skeleton, Stack } from '@mui/joy';
import type { SxProps } from '@mui/joy/styles/types';
import range from 'lodash/fp/range';
import type { ReactElement } from 'react';

const fallbackRows = 5;
const KeyValueListSkeleton = ({ sx, animation = false }: { sx?: SxProps; animation?: boolean }): ReactElement => {
  return (
    <Stack spacing={1.5} sx={sx}>
      {range(0, fallbackRows).flatMap((row) => (
        <Stack direction="row" justifyContent="space-between" key={row} spacing={1.5}>
          <Skeleton variant="text" animation={animation ? 'pulse' : false} width="40%" height="1em" />
          <Skeleton variant="text" animation={animation ? 'pulse' : false} width="10%" height="1em" />
        </Stack>
      ))}
    </Stack>
  );
};

export default KeyValueListSkeleton;
