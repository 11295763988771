import { OpenInNew } from '@mui/icons-material';
import { Link, List, ListItem, ListItemButton, ListItemDecorator } from '@mui/joy';
import type { ReactElement } from 'react';

const links: { title: string; href: string }[] = [
  {
    title: 'Disclosures',
    href: 'https://genieai.tech/disclosures',
  },
  {
    title: 'Terms of services',
    href: 'https://app.termly.io/document/terms-of-service/39b1df19-508b-4dd4-ae4e-5c08ef51b771',
  },
  {
    title: 'Privacy policy',
    href: 'https://app.termly.io/document/privacy-policy/d046bf89-172a-4e50-97d3-c957c40c2058',
  },
  {
    title: 'Cookie policy',
    href: 'https://app.termly.io/document/cookie-policy/913138d1-b32a-42bc-a87c-404f027e9f1c',
  },
  {
    title: 'Data Processing Addendum',
    href: 'https://genieai.tech/data-processing-addendum',
  },
  {
    title: 'DSAR',
    href: 'https://app.termly.io/notify/d046bf89-172a-4e50-97d3-c957c40c2058',
  },
];

const TermsAndPolicies = (): ReactElement => {
  return (
    <List>
      {links.map((link) => (
        <ListItem key={link.title}>
          <ListItemButton component={Link} href={link.href} target="_blank">
            <ListItemDecorator>
              <OpenInNew />
            </ListItemDecorator>
            {link.title}
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default TermsAndPolicies;
