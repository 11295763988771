import { Stack } from '@mui/joy';
import isNil from 'lodash/fp/isNil';
import { type ReactElement, useEffect } from 'react';
import { useFieldArray, useFormContext, useFormState, useWatch } from 'react-hook-form';

import AddButton from 'components/technical/AddButton.tsx';
import ErrorMessage from 'components/technical/ErrorMessage.tsx';
import FormInput from 'components/technical/form/FormInput.tsx';
import FormSwitch from 'components/technical/form/FormSwitch.tsx';
import Help from 'components/technical/Help/Help.tsx';
import GButton from 'components/technical/inputs/GButton/GButton.tsx';
import ObjectiveRow from './ObjectiveRow.tsx';
import { getValueKeys } from '../ConstraintValueKeys.ts';
import type { AssetLabelInput } from '../../../../market/asset/AssetLabelService.ts';
import type { AssetOptimizerInputFields } from '../asset/AssetOptimizer.validation.ts';
import { getPrimaryConstraintQuantityOptions } from '../portfolioConstraints/PortfolioPrimaryConstraint.validation.ts';
import { createAssetSelectOptions } from '../../../../market/asset/AssetService.tsx';
import { defaultRowSpacing } from '../../../../StackSpacing.ts';
import type { ObjectivesStepInput } from './ObjectivesStep.validation.ts';
import type { PortfolioLevelConstraintsStepInput } from '../portfolioConstraints/PortfolioConstraintsStep.validation.ts';

const ObjectivesStep = ({
  goToNextStep,
  benchmarks,
  showLongShortSelector,
}: {
  goToNextStep: () => void;
  benchmarks: AssetLabelInput[];
  showLongShortSelector: boolean;
}): ReactElement => {
  const { formState, clearErrors, trigger, resetField, setValue, getValues } = useFormContext<
    ObjectivesStepInput & PortfolioLevelConstraintsStepInput
  >();
  const lengthError = useFormState<ObjectivesStepInput>({
    name: 'objectiveLength',
    exact: true,
  }).errors.objectiveLength;

  const allowShortAndLeverage = useWatch<ObjectivesStepInput, 'allowShortAndLeverage'>({
    name: 'allowShortAndLeverage',
  });

  useEffect(() => {
    if (!allowShortAndLeverage) {
      resetField('portfolioConstraints.secondaryConstraint');
      clearErrors('portfolioConstraints.duplicatedAsset');
    }
  }, [allowShortAndLeverage, resetField, clearErrors]);

  useEffect(() => {
    const primaryConstPrefix = 'portfolioConstraints.primaryConstraint';
    const constrainedQuantity = getValues(`${primaryConstPrefix}.constrainedQuantity`);
    const newOptions = getPrimaryConstraintQuantityOptions(allowShortAndLeverage);
    if (isNil(constrainedQuantity)) {
      return;
    }

    if (newOptions.every((opt) => opt.value !== constrainedQuantity)) {
      resetField(`${primaryConstPrefix}.constrainedQuantity`);
      resetField(`${primaryConstPrefix}.constraintType`);

      for (const key of getValueKeys()) {
        setValue(`${primaryConstPrefix}.constraintValue.${key}`, null);
        clearErrors(`${primaryConstPrefix}.constraintValue.${key}`);
      }
    } else {
      trigger(getValueKeys().map((key) => `${primaryConstPrefix}.constraintValue.${key}` as const));
    }
  }, [allowShortAndLeverage, resetField, setValue, trigger, clearErrors, getValues]);

  const {
    fields: objectives,
    remove,
    append,
  } = useFieldArray<AssetOptimizerInputFields>({
    name: 'objectives',
  });

  const benchmarkOptions = createAssetSelectOptions(benchmarks);

  return (
    <Stack spacing={defaultRowSpacing}>
      <Stack direction="row" spacing={1.5}>
        <FormInput<AssetOptimizerInputFields>
          showLabelAboveInput
          label={
            <>
              Portfolio equity&nbsp;
              <Help>Unleveraged amount of funds in the portfolio</Help>
            </>
          }
          startDecorator="$"
          name="portfolioAmount"
          type="number"
          width="normal"
        />
        {showLongShortSelector && (
          <FormSwitch<AssetOptimizerInputFields>
            name="allowShortAndLeverage"
            label="Allow short and leverage"
            matchInputWithLabelHeight
          />
        )}
      </Stack>
      <Stack direction="column" spacing={defaultRowSpacing} alignItems="flex-start">
        {objectives.map((objective, index) => (
          <ObjectiveRow
            key={objective.id}
            index={index}
            benchmarkOptions={benchmarkOptions}
            onRemove={(): void => {
              remove(index);
              trigger('objectiveLength');
            }}
          />
        ))}
        {lengthError && <ErrorMessage>{lengthError.message}</ErrorMessage>}
        <AddButton
          disabled={formState.isSubmitting}
          onClick={(): void => {
            clearErrors('objectiveLength');

            append({
              type: null,
              riskMetric: null,
              benchmark: null,
            });
          }}
        >
          Add new objective
        </AddButton>
      </Stack>

      <GButton
        onClick={goToNextStep}
        sx={{
          marginLeft: 'auto',
        }}
      >
        Next
      </GButton>
    </Stack>
  );
};

export default ObjectivesStep;
