import { createContext } from 'react';

import type { MarketFiltersState } from './Filters.types';

export type ContextValue = {
  state?: MarketFiltersState;
  setState(state: MarketFiltersState): void;
  defaultState?: MarketFiltersState;
  loading: boolean;
  error?: Error;
};

export const MarketFiltersContext = createContext<ContextValue>({
  loading: true,
  setState: () => {
    throw new Error('Not initialized');
  },
});
