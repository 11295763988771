import { Stack, Typography as JoyTypography, Typography } from '@mui/joy';
import type { ReactElement, ReactNode } from 'react';

import Help from './Help/Help';
import { truncateText } from '../text.styles.ts';

export interface KeyValueItemProps {
  label: ReactNode;
  help?: ReactNode;
  value?: ReactNode;
  direction?: 'row' | 'column';
}

export const KeyValueItem = (props: KeyValueItemProps): ReactElement => {
  const { direction = 'row' } = props;
  return (
    <Stack
      direction={direction}
      justifyContent={direction === 'row' ? 'space-between' : 'flex-start'}
      alignItems={direction === 'row' ? 'center' : 'flex-start'}
      spacing={direction === 'row' ? 1.5 : 0.5}
    >
      <JoyTypography color="neutral" level={direction === 'row' ? 'body-sm' : 'body-sm-bold'}>
        {props.label}
        {direction === 'row' ? '' : ':'}
        {props.help && (
          <>
            {' '}
            <Help>{props.help}</Help>
          </>
        )}
      </JoyTypography>
      <Typography
        component={'div'}
        level={direction === 'row' ? undefined : 'title-sm-emphasis'}
        sx={{
          ...truncateText,
          maxWidth: direction === 'column' ? '100%' : 'auto',
        }}
      >
        {props.value ? props.value : '-'}
      </Typography>
    </Stack>
  );
};
