import type { CellContext, SortingState } from '@tanstack/table-core';
import dayjs, { type Dayjs } from 'dayjs';
import isNil from 'lodash/fp/isNil';
import orderBy from 'lodash/fp/orderBy';
import { type BigNumber, bignumber } from 'mathjs';
import { type FunctionComponent, type ReactElement, type ReactNode, useMemo, useState } from 'react';
import { isValidDayjsDateRange } from 'components/date.utils';
import {
  createAccountIdAutocompleteOptions,
  createSubAccountIdAutocompleteOptions,
} from 'components/portfolio/account/AccountService';
import GTable from 'components/technical/GTable/GTable.tsx';
import { useTablePaginator } from 'components/technical/GTable/UseTablePaginator.tsx';
import StaticMultiAutocomplete from 'components/technical/inputs/Autocomplete/StaticMultiAutocomplete';
import DateRangeInput from 'components/technical/inputs/date/DateRangeInput';
import GCheckbox from 'components/technical/inputs/GCheckbox/GCheckbox';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';

import { useUserTimezone } from 'components/technical/UseUserTimezone.tsx';
import { minSnapshotDiscrepancyUSD } from './Reconciliation.utils.ts';
import {
  type IAccount,
  IAssetType,
  type IReconciliationReportQuery,
  type ISubAccount,
  useReconciliationInputQuery,
  useReconciliationReportQuery,
} from '../../../generated/graphql';
import { DateTimeFormat, formatCash, formatDate } from '../../formatter.utils';
import { type Asset, getAssets, isAsset } from '../../market/asset/Asset.types';
import AssetLabel from '../../market/asset/AssetLabel';
import { createAssetSelectOptions } from '../../market/asset/AssetService';
import { SubAccountLabel } from '../../portfolio/account/SubAccountLabel.tsx';
import { AccountLabel } from '../../portfolio/account/AccountLabel.tsx';

interface ReportRow {
  startTime: Dayjs | undefined;
  endTime: Dayjs;
  transactionChange: BigNumber;
  snapshotChange: BigNumber;
  amountDifference: BigNumber;
  asset: Asset;
  subAccount: Pick<ISubAccount, 'name' | 'id'> & { account: Pick<IAccount, 'id' | 'name' | 'venue'> };
  assetUsdPrice: BigNumber | undefined;
}

const filterByDateRange = (condition: [Dayjs, Dayjs] | undefined | null, value: Dayjs | undefined): boolean => {
  if (isNil(condition) || isNil(value) || !isValidDayjsDateRange(condition)) {
    return true;
  }

  return value.isBetween(condition[0], condition[1], 'millisecond', '[]');
};

const filterByStringArr = (condition: string[], value: string): boolean => {
  if (condition.length === 0) {
    return true;
  }

  return condition.includes(value);
};

const endingDateColumnId = 'endingDateId';
const initialDateColumnId = 'initialDateId';

type ReconciliationRow = IReconciliationReportQuery['bookkeeping']['reconciliation']['report'][0];

const ReconciliationReport: FunctionComponent<{
  assets: Asset[];
  accounts: (Pick<IAccount, 'venue' | 'name' | 'id'> & { subAccounts: Pick<ISubAccount, 'id' | 'name'>[] })[];
}> = ({ assets, accounts }): ReactElement => {
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [selectedSubAccounts, setSelectedSubAccounts] = useState<string[]>([]);
  const [selectedAssets, setSelectedAssets] = useState<Asset[]>([]);
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(null);
  const [hideSmallDifferences, setHideSmallDifferences] = useState<boolean>(true);
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: initialDateColumnId,
      desc: true,
    },
  ]);

  const isValidDate = isValidDayjsDateRange(dateRange);
  const assetOptions = useMemo(() => createAssetSelectOptions(assets), [assets]);
  const subAccountOptions = useMemo(() => createSubAccountIdAutocompleteOptions(accounts), [accounts]);
  const accountOptions = useMemo(() => createAccountIdAutocompleteOptions(accounts), [accounts]);
  const timezone = useUserTimezone();

  const { loading, error, data } = useReconciliationReportQuery({
    variables: {
      minUsdDiscrepancy: hideSmallDifferences ? bignumber(minSnapshotDiscrepancyUSD).toString() : null,
    },
  });

  const rows: ReportRow[] = (data?.bookkeeping.reconciliation.report ?? [])
    .filter(
      (
        row
      ): row is ReconciliationRow & {
        asset: Asset;
      } => {
        if (!isAsset(row.asset)) {
          console.error('IAsset is not an asset', row.asset);
          return false;
        }

        return true;
      }
    )
    .map((row) => {
      return {
        startTime: row.startTime ? dayjs.utc(row.startTime) : undefined,
        endTime: dayjs.utc(row.endTime),
        transactionChange: bignumber(row.transactionBalanceChangeAmount),
        snapshotChange: bignumber(row.snapshotBalanceChangeAmount),
        amountDifference: bignumber(row.snapshotBalanceChangeAmount).sub(row.transactionBalanceChangeAmount),
        asset: row.asset,
        subAccount: row.subAccount,
        assetUsdPrice: isNil(row.assetClosingPriceUsd) ? undefined : bignumber(row.assetClosingPriceUsd),
      };
    });

  const selectedAssetIds = selectedAssets.map((asset) => asset.id);

  const filteredRows = rows.filter(({ asset, subAccount, endTime }) => {
    return (
      filterByStringArr(selectedAssetIds, asset.id) &&
      filterByStringArr(selectedSubAccounts, subAccount.id) &&
      filterByStringArr(selectedAccounts, subAccount.account.id) &&
      filterByDateRange(dateRange, endTime)
    );
  });

  const columns = [
    {
      id: initialDateColumnId,
      header: 'Initial date',
      cell: (context: CellContext<ReportRow, unknown>): ReactNode =>
        formatDate(context.row.original.startTime, DateTimeFormat.DateTime, timezone),
      accessorFn: (row: ReportRow): unknown => row.startTime?.toDate() ?? null,
    },
    {
      id: endingDateColumnId,
      header: 'Ending date',
      cell: (context: CellContext<ReportRow, unknown>): ReactNode =>
        formatDate(context.row.original.endTime, DateTimeFormat.DateTime, timezone),
      accessorFn: (row: ReportRow): unknown => row.endTime.toDate(),
    },
    {
      id: 'account',
      header: 'Acount',
      cell: (context: CellContext<ReportRow, unknown>): ReactNode => {
        const { subAccount } = context.row.original;
        return <AccountLabel account={subAccount.account} />;
      },
      accessorFn: (row: ReportRow): unknown => row.subAccount.name,
    },
    {
      id: 'subaccount',
      header: 'Sub-account',
      cell: (context: CellContext<ReportRow, unknown>): ReactNode => {
        const { subAccount } = context.row.original;
        return <SubAccountLabel subAccount={subAccount} />;
      },
      accessorFn: (row: ReportRow): unknown => row.subAccount.name,
    },
    {
      id: 'asset',
      header: 'Asset',
      cell: (context: CellContext<ReportRow, unknown>): ReactNode => {
        const { asset } = context.row.original;
        return <AssetLabel asset={asset} link={false} />;
      },
      accessorFn: (row: ReportRow): unknown => row.asset.symbol,
    },
    {
      id: 'balanceChange',
      header: 'Ending balance - initial balance (units)',
      cell: (context: CellContext<ReportRow, unknown>): ReactNode => {
        const { snapshotChange } = context.row.original;
        return snapshotChange.toFixed();
      },
      accessorFn: (row: ReportRow): unknown => row.snapshotChange.toNumber(),
    },
    {
      id: 'transactionsChange',
      header: 'Total transactions change (units)',
      cell: (context: CellContext<ReportRow, unknown>): ReactNode => {
        const { transactionChange } = context.row.original;
        return transactionChange.toFixed();
      },
      accessorFn: (row: ReportRow): unknown => row.transactionChange.toNumber(),
    },
    {
      id: 'diffChange',
      header: 'Unreconciled amount (units)',
      cell: (context: CellContext<ReportRow, unknown>): ReactNode => {
        const { amountDifference } = context.row.original;
        return amountDifference.toFixed();
      },
      accessorFn: (row: ReportRow): unknown => row.amountDifference.toNumber(),
    },
    {
      id: 'unreconciledUsd',
      header: 'Unreconciled amount (USD)',
      cell: (context: CellContext<ReportRow, unknown>): ReactNode => {
        const { amountDifference, assetUsdPrice } = context.row.original;
        if (isNil(assetUsdPrice)) {
          return '-';
        }

        return formatCash(amountDifference.mul(assetUsdPrice));
      },
      accessorFn: (row: ReportRow): undefined | number => {
        if (isNil(row.assetUsdPrice)) {
          return undefined;
        }

        return row.amountDifference.mul(row.assetUsdPrice).toNumber();
      },
    },
  ].map((col) => ({
    ...col,
    enableSorting: !!col.accessorFn,
  }));

  const sortingComparator = sorting
    .map((columnSort) => {
      const column = columns.find((col) => col.id === columnSort.id);
      const accessor = column?.accessorFn;
      if (!accessor) {
        return undefined;
      }

      return { accessor: accessor, desc: columnSort.desc };
    })
    .filter((sort): sort is { accessor: (row: ReportRow) => unknown; desc: boolean } => sort !== undefined);

  const sortedRows = orderBy(
    sortingComparator.map((comp) => comp?.accessor),
    sortingComparator.map((comp) => (comp?.desc ? 'desc' : 'asc')),
    filteredRows
  );

  const { tablePaginator, page } = useTablePaginator({
    filters: hideSmallDifferences,
    defaultPageSize: 50,
  });

  const limitedRows = sortedRows.slice(page.offset, page.offset + page.limit);

  const width = 'xl2' as const;
  return (
    <GTable
      filters={[
        {
          component: StaticMultiAutocomplete,
          value: selectedAccounts,
          width,
          ...accountOptions,
          onChange: setSelectedAccounts,
          label: 'Account',
          defaultValue: null,
          defaultFilter: true,
        },
        {
          component: StaticMultiAutocomplete,
          value: selectedSubAccounts,
          width,
          ...subAccountOptions,
          onChange: setSelectedSubAccounts,
          label: 'Sub-account',
          defaultValue: null,
          defaultFilter: true,
        },
        {
          component: StaticMultiAutocomplete,
          ...assetOptions,
          value: selectedAssets,
          width,
          onChange: setSelectedAssets,
          label: 'Asset',
          defaultValue: null,
          defaultFilter: true,
        },
        {
          component: DateRangeInput,
          value: dateRange,
          width,
          error: isValidDate ? undefined : 'Invalid date',
          onChange: setDateRange,
          label: 'Ending date',
          defaultValue: null,
          defaultFilter: true,
        },
        {
          component: GCheckbox,
          checked: hideSmallDifferences,
          width,
          onChange: setHideSmallDifferences,
          label: 'Hide small differences',
          defaultValue: true,
        },
      ]}
      columns={columns}
      data={limitedRows}
      error={error}
      loading={loading}
      sortingState={sorting}
      onSortingChange={setSorting}
      paginator={tablePaginator}
      totalResults={filteredRows.length}
      stickyHeader
    />
  );
};

const ReconciliationReportContainer = (): ReactElement => {
  const { loaded, data, Fallback } = useDefaultErrorHandling(
    useReconciliationInputQuery({
      variables: {
        filters: {
          assetTypes: [IAssetType.Public, IAssetType.Private, IAssetType.Exchange],
        },
      },
    })
  );

  if (!loaded) {
    return <Fallback />;
  }

  return <ReconciliationReport assets={getAssets(data.assets.list)} accounts={data.portfolio.accounts} />;
};

export default ReconciliationReportContainer;
