import * as React from "react";
const SvgOkx = (props) => /* @__PURE__ */ React.createElement("svg", { id: "a", viewBox: "0 0 150 150", "sodipodi:docname": "okx.svg", "inkscape:version": "1.1.2 (0a00cf5339, 2022-02-04)", "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape", "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("sodipodi:namedview", { id: "namedview12", bordercolor: "#000000", borderopacity: 0.25, "inkscape:pageshadow": 2, "inkscape:pageopacity": 0, "inkscape:pagecheckerboard": 0, showgrid: "false", "inkscape:zoom": 5.82, "inkscape:cx": 74.914089, "inkscape:cy": 75, "inkscape:window-width": 1974, "inkscape:window-height": 1088, "inkscape:window-x": 1994, "inkscape:window-y": 27, "inkscape:window-maximized": 1, "inkscape:current-layer": "a" }), /* @__PURE__ */ React.createElement("defs", { id: "defs4" }, /* @__PURE__ */ React.createElement("style", { id: "style2" }, ".e{fill:none;}")), /* @__PURE__ */ React.createElement("g", { id: "b", transform: "matrix(1.5,0,0,1.5,-37.5,-37.5)" }, /* @__PURE__ */ React.createElement("g", { id: "c" }, /* @__PURE__ */ React.createElement("path", { id: "d", className: "e", d: "M 0,0 H 150 V 150 H 0 Z" }))), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  strokeWidth: 0.382733,
  strokeLinecap: "square"
}, d: "M 25.354194,58.456327 C 25.052867,58.397207 24.53096,58.055791 24.1944,57.697629 l -0.611926,-0.651201 -0.07665,-15.250019 c -0.04217,-8.387509 -0.0055,-15.639909 0.08151,-16.116442 0.08699,-0.476534 0.435154,-1.143415 0.773698,-1.481958 l 0.615534,-0.615536 H 40.987488 56.99841 l 0.753369,0.753371 0.753371,0.75337 v 15.754275 c 0,17.353698 0.03708,16.942181 -1.577021,17.504856 -0.568335,0.198124 -5.448234,0.274917 -15.922779,0.250577 -8.306811,-0.01931 -15.349833,-0.08347 -15.65116,-0.14259 z", id: "path3342" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  strokeWidth: 0.382733,
  strokeLinecap: "square"
}, d: "m 95.715019,58.456327 c -0.301327,-0.05911 -0.823234,-0.400536 -1.159793,-0.758698 l -0.611927,-0.651201 -0.07665,-15.250019 c -0.04215,-8.387509 -0.0055,-15.639909 0.08151,-16.116442 0.08698,-0.476534 0.435154,-1.143415 0.773698,-1.481958 l 0.615534,-0.615536 h 16.010919 16.01093 l 0.75337,0.753371 0.75336,0.75337 v 15.754275 c 0,17.622467 0.0598,17.019477 -1.7401,17.519361 -0.92568,0.257078 -30.134036,0.344 -31.410854,0.09348 z", id: "path3381" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  strokeWidth: 0.382733,
  strokeLinecap: "square"
}, d: "M 60.136272,93.05621 C 59.686738,92.858418 59.193825,92.462814 59.040912,92.177093 58.850658,91.8216 58.762887,86.642041 58.762887,75.770314 V 59.88303 l 0.75337,-0.75337 0.753371,-0.753371 h 16.01092 16.010924 l 0.632613,0.632615 0.632616,0.632614 V 75.74711 91.852698 l -0.62189,0.723137 -0.621886,0.723135 -15.679659,0.05842 C 63.685584,93.40564 60.811198,93.35319 60.136272,93.0562 Z", id: "path3420" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  strokeWidth: 0.382733,
  strokeLinecap: "square"
}, d: "m 95.715019,128.04396 c -0.301327,-0.0591 -0.823234,-0.40054 -1.159793,-0.7587 l -0.611927,-0.65121 -0.07665,-15.25001 c -0.04215,-8.38751 -0.0055,-15.63991 0.08151,-16.116443 0.08698,-0.476534 0.435154,-1.143416 0.773698,-1.48196 l 0.615534,-0.615534 h 16.010919 16.01093 l 0.75337,0.753371 0.75336,0.75337 v 15.724696 c 0,9.79228 -0.0966,15.97882 -0.25609,16.39828 -0.5373,1.41322 -0.68321,1.425 -17.19697,1.38673 -8.33251,-0.0194 -15.396568,-0.0834 -15.697894,-0.14265 z", id: "path3459" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  strokeWidth: 0.382733,
  strokeLinecap: "square"
}, d: "m 25.354194,128.04396 c -0.301327,-0.0591 -0.823234,-0.40053 -1.159794,-0.7587 l -0.611926,-0.65119 -0.07822,-14.9923 c -0.04302,-8.24575 -0.0048,-15.499759 0.08508,-16.120006 0.09818,-0.67794 0.407382,-1.371799 0.775273,-1.739691 l 0.611972,-0.61197 h 16.010922 16.010922 l 0.753369,0.75337 0.75337,0.753371 v 15.754276 c 0,17.3537 0.03708,16.94218 -1.57702,17.50485 -0.568335,0.19812 -5.448234,0.27492 -15.922779,0.25058 -8.306812,-0.0194 -15.349833,-0.0834 -15.651161,-0.14265 z", id: "path3498" }));
export default SvgOkx;
