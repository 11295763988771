import { type ReactElement, type ReactNode, useEffect, useState } from 'react';

import { type ImperativeModal, Context } from './ImperativeModalContext.tsx';
import { useLocation } from 'react-router';

const ImperativeModalProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [modal, setModal] = useState<ReactNode | null>(null);
  const location = useLocation();

  // biome-ignore lint/correctness/useExhaustiveDependencies: hide modal when someone wants to navigate back
  useEffect(() => {
    setModal(null);
  }, [location]);

  return (
    <Context.Provider
      value={{
        showModal(confirmation: ImperativeModal): void {
          if (confirmation) {
            setModal(
              confirmation({
                onClose: () => setModal(null),
              })
            );
          }
        },
        modal: modal,
      }}
    >
      {modal}
      {children}
    </Context.Provider>
  );
};

export default ImperativeModalProvider;
