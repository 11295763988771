import { Modal, ModalDialog, Stack } from '@mui/joy';
import type { ReactElement } from 'react';
import { useForm } from 'react-hook-form';

import GFormProvider from 'components/technical/form/GFormProvider.tsx';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage.tsx';
import gYupResolver from 'components/technical/form/gYupResolver.ts';
import SubmitButton from 'components/technical/form/SubmitButton.tsx';
import { useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError.tsx';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import { FormInput } from 'components/technical/inputs';
import { useFeedback } from '../../../../technical/Feedback/UseFeedback.tsx';
import {
  basePortfolioSchema,
  type FormInputFields,
  type FormOutputFields,
} from '../create/CreatePortfolio.validation.ts';
import {
  IPortfolioDefinitionType,
  PortfolioDefListInputDocument,
  useUpdatePortfolioDefinitionMutation,
} from '../../../../../generated/graphql.tsx';
import type { AccountLabelInputAccount } from '../../../../portfolio/account/AccountLabel.tsx';
import { createAccountIdAutocompleteOptions } from '../../../../portfolio/account/AccountService.tsx';
import FormStaticMultiAutocomplete from '../../../../technical/form/FormStaticMultiAutocomplete.tsx';
import { formSchema as extraAccountSchema } from '../realPortfolio/CreateRealPortfolioDialog.validation.tsx';
import {
  createSubfundIdAutocompleteOptions,
  type SubfundAutocompleteOptionInput,
} from '../../../../portfolio/fund/SubFundService.tsx';
import FormErrorMessage from '../../../../technical/form/FormErrorMessage.tsx';

const UpdatePortfolioDialog = ({
  onClose,
  def,
  onUpdate,
  accounts,
  subFunds,
}: {
  onClose: () => void;
  onUpdate: () => void;
  def: {
    id: string;
    name: string;
    description: string;
    type: IPortfolioDefinitionType;
    realDefAccounts?: AccountLabelInputAccount[];
    realDefSubFunds?: SubfundAutocompleteOptionInput[];
  };
  accounts?: AccountLabelInputAccount[];
  subFunds?: SubfundAutocompleteOptionInput[];
}): ReactElement => {
  const methods = useForm<
    FormInputFields & {
      accounts: string[];
      subFunds: number[];
    }
  >({
    resolver: gYupResolver(
      def.type === IPortfolioDefinitionType.Real ? basePortfolioSchema.concat(extraAccountSchema) : basePortfolioSchema
    ),
    defaultValues: {
      name: def.name,
      description: def.description,
      accounts: (def.realDefAccounts ?? []).map((acc) => acc.id),
      subFunds: (def.realDefSubFunds ?? []).map((subFund) => subFund.id),
    },
  });
  const { showSuccessMessage } = useFeedback();

  const { onErrorAndThrow } = useGraphQLApiError(methods);

  const [mutate] = useUpdatePortfolioDefinitionMutation({
    refetchQueries: [PortfolioDefListInputDocument],
  });

  const handleFormSubmit = async (
    data: FormOutputFields & { accounts: string[]; subFunds: number[] }
  ): Promise<void> => {
    try {
      await mutate({
        variables: {
          input: {
            id: def.id,
            name: data.name,
            description: data.description,
            realDefAccounts: data.accounts,
            realDefSubFunds: data.subFunds,
          },
        },
      });

      showSuccessMessage('Portfolio successfully updated');
      onUpdate();
    } catch (e) {
      onErrorAndThrow(e);
    }
  };

  const accountOptions = createAccountIdAutocompleteOptions(accounts ?? []);
  const subFundOptions = createSubfundIdAutocompleteOptions(subFunds ?? []);
  const width = 'xl3';
  return (
    <Modal open={true} onClose={onClose}>
      <ModalDialog minWidth="25rem">
        <GFormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
            <GDialogHeader>Update {def.name} portfolio</GDialogHeader>
            <Stack spacing={3} alignItems={'center'}>
              <FormInput label="Name" name="name" width={width} />
              <FormInput label="Description" name="description" width={width} />
              {def.type === IPortfolioDefinitionType.Real && (
                <>
                  <FormStaticMultiAutocomplete {...accountOptions} name="accounts" label="Accounts" width={width} />
                  <FormStaticMultiAutocomplete
                    {...subFundOptions}
                    name="subFunds"
                    label="Sub-funds"
                    width={width}
                    onChange={(_val, { clearErrors }) => {
                      clearErrors('notEmptyAccountOrSubfunds');
                    }}
                  />
                </>
              )}
              <Stack alignItems="center" spacing={1.5}>
                <FormErrorMessage name={'notEmptyAccountOrSubfunds'} />
                <GraphQLApiFormErrorMessage />
                <SubmitButton width="xl2">Update</SubmitButton>
              </Stack>
            </Stack>
          </form>
        </GFormProvider>
      </ModalDialog>
    </Modal>
  );
};

export default UpdatePortfolioDialog;
