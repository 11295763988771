import { Modal, ModalDialog, Stack } from '@mui/joy';
import type { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import GFormProvider from 'components/technical/form/GFormProvider';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton.tsx';
import { useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError.tsx';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';

import { FormInput } from 'components/technical/inputs';
import {
  getInitialValues,
  getVenueExtraFieldsSchema,
  removeEmptySecrets,
} from './CreateAccountDialog/CredentialsService.ts';
import type { ISecretsInput, useUpdateAccountApiKeysMutation } from '../../../generated/graphql';
import { venues } from '../../venue/VenueData.tsx';

type FormState = Required<ISecretsInput>;

interface UpdateApiKeysDialogProps {
  onClose: () => void;
  onUpdate: () => void;
  name: string;
  venue: string;
  id: string;
  mutation: typeof useUpdateAccountApiKeysMutation;
}

const UpdateApiKeysDialog = ({
  onClose,
  onUpdate,
  name,
  venue,
  id,
  mutation,
}: UpdateApiKeysDialogProps): ReactElement => {
  const requiredFields = venues[venue].extraFields ?? [];
  const defaultValues = getInitialValues(requiredFields);

  const methods = useForm<FormState>({
    resolver: gYupResolver(getVenueExtraFieldsSchema(venue)),
    defaultValues,
  });

  const { onErrorAndThrow } = useGraphQLApiError(methods);

  const [updateApiKeysMutation] = mutation();

  const handleFormSubmit = async (data: FormState): Promise<void> => {
    const secrets = removeEmptySecrets(data);
    try {
      await updateApiKeysMutation({
        variables: {
          input: {
            secrets: secrets,
            id: id,
          },
        },
      });

      onUpdate();
    } catch (e) {
      onErrorAndThrow(e);
    }
  };

  return (
    <Modal open={true} onClose={onClose}>
      <ModalDialog>
        <GFormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
            <GDialogHeader>Change {name} credentials</GDialogHeader>
            <Stack spacing={3}>
              <Stack spacing={1.5}>
                {requiredFields.map((field) => (
                  <FormInput
                    key={`input-${field.name}`}
                    type={field.type ?? 'text'}
                    label={field.label}
                    name={field.name}
                    autoComplete="off"
                    width="fullWidth"
                  />
                ))}
              </Stack>
              {/* jscpd:ignore-start */}
              <Stack alignItems="center" spacing={1.5}>
                <GraphQLApiFormErrorMessage />
                <SubmitButton width="xl2">Update API keys</SubmitButton>
              </Stack>
              {/* jscpd:ignore-end */}
            </Stack>
          </form>
        </GFormProvider>
      </ModalDialog>
    </Modal>
  );
};

export default UpdateApiKeysDialog;
