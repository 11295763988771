import { Card, Grid, Typography } from '@mui/joy';
import type { ReactElement } from 'react';

import { PortfolioReturnsPerformanceChart } from './PortfolioReturnsPerformanceChart.tsx';
import { PortfolioReturnsPerformanceList } from './PortfolioReturnsPerformanceList.tsx';
import type { Solution } from './Results.types.ts';

export const OptimizerSolutionPortfolioReturns = ({
  solution,
  compareToSolution,
}: {
  solution: Solution;
  compareToSolution: Solution | null;
}): ReactElement => {
  const solutionToCompareByStatName = Object.fromEntries(
    compareToSolution?.returnsPredictions.returnStatistics.map((stat) => [stat.statName, stat.statValue]) ?? []
  );
  const returns = [
    {
      title: solution.name,
      data: solution.returnsPredictions.returns,
    },
  ];

  if (compareToSolution) {
    returns.push({
      title: compareToSolution.name,
      data: compareToSolution.returnsPredictions.returns,
    });
  }
  return (
    <>
      <Typography level="h4">Expected returns</Typography>
      <Grid container>
        <Grid md={6} xs={12}>
          <Card sx={{ height: '100%', px: 4, py: 3 }}>
            <PortfolioReturnsPerformanceList
              returns={solution.returnsPredictions.returnStatistics.map((stat) => ({
                name: stat.statName,
                value: stat.statValue,
              }))}
              title={solution.name}
              solutionToCompareByStatName={solutionToCompareByStatName}
              compareToTitle={compareToSolution?.name}
            />
          </Card>
        </Grid>
        <Grid md={6} xs={12}>
          <Card
            sx={{
              height: '100%',
              justifyContent: 'flex-end',
              padding: 4,
            }}
          >
            <PortfolioReturnsPerformanceChart returns={returns} />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
