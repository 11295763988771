import { capitalize } from 'lodash/fp';
import type { StaticAutocompleteOption } from 'components/technical/inputs/Autocomplete/StaticSingleAutocomplete.props.ts';
import {
  IBrinsonAttributionType,
  type ICategoryValue,
  IMultiLevelAttributionType,
  IReturnsType,
} from '../../../../generated/graphql.tsx';

export const skeletonData: ICategoryValue[] = new Array(7).fill({ category: undefined, value: undefined });

export enum AggregationMethod {
  BrinsonFachler = IBrinsonAttributionType.BrinsonFachler,
  BrinsonHoodBeebower = IBrinsonAttributionType.BrinsonHoodBeebower,
  BottomUp = IMultiLevelAttributionType.BottomUp,
}

export const aggregationMethodOptions: StaticAutocompleteOption<AggregationMethod>[] = [
  {
    label: 'Relative (Brinson Fachler)',
    value: AggregationMethod.BrinsonFachler,
    key: AggregationMethod.BrinsonFachler,
    searchText: AggregationMethod.BrinsonFachler,
  },
  {
    label: 'Absolute (Brinson Hood Beebower)',
    value: AggregationMethod.BrinsonHoodBeebower,
    key: AggregationMethod.BrinsonHoodBeebower,
    searchText: AggregationMethod.BrinsonHoodBeebower,
  },
  {
    label: 'Bottom-Up*',
    value: AggregationMethod.BottomUp,
    key: AggregationMethod.BottomUp,
    searchText: 'Bottom-Up',
  },
];

export const returnsTypeOptions: StaticAutocompleteOption<IReturnsType>[] = [
  {
    label: capitalize(IReturnsType.Algebraic),
    value: IReturnsType.Algebraic,
    key: IReturnsType.Algebraic,
    searchText: IReturnsType.Algebraic,
  },
  {
    label: capitalize(IReturnsType.Geometric),
    value: IReturnsType.Geometric,
    key: IReturnsType.Geometric,
    searchText: IReturnsType.Geometric,
  },
];

export const sampleSectors = [
  'Crypto-Backed',
  'Stablecoin',
  'Exchanges',
  'Oracle',
  'Private',
  'Metaverse',
  'Shared Storage',
  'Credit Platform',
  'Media',
];
