import FormLabel from '@mui/joy/FormLabel';
import isEmpty from 'lodash/fp/isEmpty';
import type { FunctionComponent, ReactNode } from 'react';
import { Box } from '@mui/joy';
import widthSx from '../../width.styles.ts';

type InputLabelProps = {
  width?: 'fullWidth' | 'smaller' | 'small' | 'normal' | 'xl' | 'xl2' | 'xl3' | 'xl4' | 'minContent';
  marginBottom?: string;
  label?: ReactNode;
};

const InputLabel: FunctionComponent<InputLabelProps> = ({ label, width = 'fullWidth', marginBottom }) => {
  const allWidthSx = {
    fullWidth: {},
    minContent: {},
    ...widthSx,
  };
  return (
    <Box
      sx={{
        marginBottom: marginBottom,
        ...allWidthSx[width],
      }}
    >
      <FormLabel>{isEmpty(label) ? <>&nbsp;</> : label}</FormLabel>
    </Box>
  );
};

export default InputLabel;
