import React, { type ReactElement, type ReactNode } from 'react';

export type ImperativeModal = ({ onClose }: { onClose: () => void }) => ReactElement;

export interface ImperativeModalContext {
  showModal(element: ImperativeModal): void;
  modal: ReactNode | null;
}

export const Context = React.createContext<ImperativeModalContext>({
  showModal: () => null,
  modal: null,
});
