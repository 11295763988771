import { Stack } from '@mui/joy';
import { type ReactElement, useEffect, useMemo } from 'react';
import { type FieldPath, useFormContext, useWatch } from 'react-hook-form';
import FormInput from 'components/technical/form/FormInput.tsx';
import type { GInputProps } from 'components/technical/inputs/GInput/GInput.props.ts';
import GInput from 'components/technical/inputs/GInput/GInput.tsx';

import type { AssetOptimizerInputFields } from './asset/AssetOptimizer.validation.ts';
import { useValueChanged } from '../../../UseValueChanged.tsx';

import { ConstraintType } from './ConstraintTypeValues.validation.ts';
import type { PortfolioOptimizerInputFields } from './portfolio/PortfolioOptimizer.validation.ts';

export const ConstraintValueInputs = ({
  pathPrefix,
  startAdornment,
  width,
}: {
  pathPrefix: FieldPath<AssetOptimizerInputFields | PortfolioOptimizerInputFields>;
  startAdornment?: string;
  width: GInputProps['width'];
}): ReactElement => {
  const { setValue, clearErrors } = useFormContext<AssetOptimizerInputFields>();

  const constraintType = useWatch({
    name: `${pathPrefix}.constraintType`,
  });

  const allValueFields = useMemo((): string[] => {
    return [
      `${pathPrefix}.constraintValue.min`,
      `${pathPrefix}.constraintValue.max`,
      `${pathPrefix}.constraintValue.value`,
    ];
  }, [pathPrefix]);

  const constraintChanged = useValueChanged(constraintType);
  useEffect(() => {
    if (!constraintChanged) {
      return;
    }

    for (const path of allValueFields) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setValue(path, null);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    clearErrors(allValueFields);
  }, [constraintChanged, clearErrors, setValue, allValueFields]);

  if (constraintType === ConstraintType.None) {
    return <GInput disabled value="" width={width} />;
  }

  if (constraintType === ConstraintType.Equal) {
    return (
      <FormInput<AssetOptimizerInputFields>
        key="value"
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        name={`${pathPrefix}.constraintValue.value` as const}
        type="number"
        startDecorator={startAdornment}
        label="Value"
        width={width}
      />
    );
  }
  if (constraintType === ConstraintType.Between) {
    return (
      <Stack direction="row" spacing={1.5}>
        <FormInput<AssetOptimizerInputFields>
          key="min"
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          name={`${pathPrefix}.constraintValue.min` as const}
          type="number"
          startDecorator={startAdornment}
          label="Min"
          width={width}
        />
        <FormInput<AssetOptimizerInputFields>
          key="max"
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          name={`${pathPrefix}.constraintValue.max` as const}
          type="number"
          startDecorator={startAdornment}
          label="Max"
          width={width}
        />
      </Stack>
    );
  }

  return <></>;
};
