import { Card, CardContent, Typography } from '@mui/joy';
import type { FunctionComponent, PropsWithChildren } from 'react';

type CardWithHeaderProps = PropsWithChildren<{ title: string }>;

const CardWithHeader: FunctionComponent<CardWithHeaderProps> = ({ title, children }) => {
  return (
    <Card sx={{ minWidth: '32rem', flex: 1 }}>
      <CardContent>
        <Typography level="title-lg">{title}</Typography>
        {children}
      </CardContent>
    </Card>
  );
};

export default CardWithHeader;
