import Close from '@mui/icons-material/Close';
import { type AutocompleteRenderGetTagProps, Chip, Tooltip } from '@mui/joy';
import { type ReactNode, useCallback } from 'react';

type RenderTagsFunction<T> = (
  value: Array<T>,
  getTagProps: AutocompleteRenderGetTagProps,
  ownerState: object
) => ReactNode;

export function createRenderTagsFunction<T>({
  showChipTooltips,
  getOptionLabel,
}: {
  showChipTooltips: boolean;
  getOptionLabel: (value: T) => string;
}): RenderTagsFunction<T> {
  return useCallback(
    function renderTags(tags: Array<T>, getTagProps: AutocompleteRenderGetTagProps): ReactNode {
      return tags.map((item, index) => {
        const label = getOptionLabel(item);
        const { key, ...tagProps } = getTagProps({ index });

        const chip = (
          <Chip
            key={key}
            endDecorator={<Close fontSize="small" />}
            sx={{
              maxWidth: '100%',
            }}
            {...tagProps}
          >
            {label}
          </Chip>
        );

        if (showChipTooltips) {
          return (
            <Tooltip title={label} key={label} placement="left">
              {chip}
            </Tooltip>
          );
        }

        return chip;
      });
    },
    [showChipTooltips, getOptionLabel]
  );
}
