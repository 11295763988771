import type { ApolloClient, OperationVariables, QueryOptions } from '@apollo/client';
import type { Params } from 'react-router';

export const parseIntParameter = (params: Params<string>, paramName: string): number | null => {
  const parameter = params[paramName];
  if (!parameter) {
    return null;
  }

  const parsedParam = Number.parseInt(parameter);
  if (Number.isNaN(parsedParam)) {
    console.error('Incorrect param:', paramName, parsedParam);
    return null;
  }

  return parsedParam;
};

export const queryOrNull = async <QUERY = unknown, Variables extends OperationVariables = OperationVariables>(
  client: ApolloClient<unknown>,
  options: QueryOptions<Variables, QUERY>
): Promise<QUERY | null> => {
  try {
    const result = await client.query<QUERY>(options);
    if (result.error) {
      throw result.error;
    }

    return result.data;
  } catch (e) {
    console.error('Error loading data', options, e);
    return null;
  }
};
