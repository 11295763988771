import { ITransactionsUploadStatus, ITransactionsUploadType } from 'generated/graphql';
import { capitalize, isNil } from 'lodash/fp';

export function getStatusText(uploadStatus: {
  status: ITransactionsUploadStatus;
  type: ITransactionsUploadType;
  processedRows?: number | null | undefined;
}): string {
  const defaultStatus = capitalize(uploadStatus.status.replaceAll('_', ' '));
  switch (uploadStatus.status) {
    case ITransactionsUploadStatus.InProgress: {
      return isNil(uploadStatus.processedRows) ? defaultStatus : `${uploadStatus.processedRows} rows processed`;
    }
    case ITransactionsUploadStatus.ValidationFailed:
      return 'Validation failed';
    case ITransactionsUploadStatus.Failed:
      return 'Application error';
    case ITransactionsUploadStatus.Succeeded:
      return uploadStatus.type === ITransactionsUploadType.Validation ? 'Validated' : 'Uploaded';
    default:
      return defaultStatus;
  }
}
