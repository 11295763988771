import isNil from 'lodash/fp/isNil';
import { bignumber } from 'mathjs';

import type { ItemOutlookOutput } from '../assumptionsAndOutlook/AssumptionsAndOutlook.validation.tsx';
import type {
  IConstrainedAssetInput,
  IGivenPortfolioAssetInput,
  IMutationSubmitFundOptimizationArgs,
} from '../../../../../generated/graphql.tsx';
import type { PortfolioOptimizerOutputFields } from './PortfolioOptimizer.validation.ts';
import {
  getConstraintValue,
  getObjectives,
  getPortfolioConstraints,
  getRiskBudget,
  getUserExpectedReturn,
} from '../requestFactory.ts';

const getSubPortfolioConstraints = (
  output: PortfolioOptimizerOutputFields,
  portfolioIdToOutlook: Record<string, ItemOutlookOutput>
): IConstrainedAssetInput[] => {
  const portfolioIdToConstraint = Object.fromEntries(output.constraints.map((cons) => [cons.item.portfolio?.id, cons]));

  return output.universe.map((portfolio) => {
    const constraint = portfolioIdToConstraint[portfolio.id];
    return {
      id: portfolio.id ?? '',
      constraint: constraint
        ? getConstraintValue(
            { constraintValue: constraint.constraintValue, constraintType: constraint.constraintType },
            output.constraintType
          )
        : null,
      riskBudget: getRiskBudget(portfolio.id, output, portfolioIdToOutlook, {}),
      userExpectedReturn: getUserExpectedReturn(portfolio.id, output, portfolioIdToOutlook, {}),
    };
  });
};

function getGivenPortfolioFromInput(
  output: PortfolioOptimizerOutputFields,
  portfolioIdToOutlook: Record<string, ItemOutlookOutput>
): IGivenPortfolioAssetInput[] {
  const givenPortfolio = [];
  for (const [portfolioId, initialPortfolioValue] of Object.entries(output.givenPortfolio)) {
    const value = initialPortfolioValue ? bignumber(initialPortfolioValue) : null;

    if (!isNil(value) && !value.isZero()) {
      givenPortfolio.push({
        id: portfolioId,
        cashWeight: value.div(100).toNumber(), // e.g. 30% (input) -> 0.3 (api)
        riskBudget: getRiskBudget(portfolioId, output, portfolioIdToOutlook, {}),
        userExpectedReturn: getUserExpectedReturn(portfolioId, output, portfolioIdToOutlook, {}),
      });
    }
  }

  return givenPortfolio;
}

export const createRequestInput = (
  output: PortfolioOptimizerOutputFields
): IMutationSubmitFundOptimizationArgs['input'] => {
  const portfolioIdToOutlook = Object.fromEntries(output.outlook.map((out) => [out.id, out]));

  const formattedInput = {
    funds: getSubPortfolioConstraints(output, portfolioIdToOutlook),
    constraintFormulation: output.constraintType,
    description: output.description,
    givenPortfolio: getGivenPortfolioFromInput(output, portfolioIdToOutlook),
    name: output.name,
    objectives: getObjectives(output),
    portfolioAmount: output.portfolioAmount,
    portfolioConstraints: getPortfolioConstraints(output),
  };

  return formattedInput;
};
