import { type BigNumber, bignumber } from 'mathjs';
import bigNumMath from '../../../bigNumMath.ts';
import { IJournalEntryType } from '../../../generated/graphql.tsx';
import type { LegWithRequiredJournalEntry } from './TransactionLegColumn.tsx';

export const calculateLegCost = (journalEntry: LegWithRequiredJournalEntry['journalEntry']): BigNumber => {
  return bignumber(journalEntry.amount)
    .abs()
    .mul(journalEntry.costBasis ?? '0');
};

export const calculateTotalCost = (legs: LegWithRequiredJournalEntry[]): BigNumber => {
  return bigNumMath.sum([bignumber(0), ...legs.map((leg) => calculateLegCost(leg.journalEntry))]);
};

export const calculatePnl = (legs: LegWithRequiredJournalEntry[]): BigNumber => {
  const totalCreditCost = calculateTotalCost(
    legs.filter((leg) => leg.journalEntry.entryType === IJournalEntryType.Credit)
  );

  const totalDebitCost = calculateTotalCost(
    legs.filter((leg) => leg.journalEntry.entryType === IJournalEntryType.Debit)
  );

  return totalCreditCost.sub(totalDebitCost);
};
