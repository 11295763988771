import { partition } from 'lodash/fp';
import type { GTableProps } from './GTable.props.ts';
import { type Dispatch, Fragment, type ReactNode, type SetStateAction } from 'react';
import { Dropdown, IconButton, MenuButton, Stack, Menu, MenuItem } from '@mui/joy';
import sortBy from 'lodash/fp/sortBy';
import isEqual from 'lodash/fp/isEqual';
import { Add, Close } from '@mui/icons-material';

export const GTableFilters = (props: {
  activeFilters: string[];
  setActiveFilters: Dispatch<SetStateAction<string[]>>;
  filters: GTableProps<unknown>['filters'];
}): ReactNode => {
  const [activeFilterDesc, disabledFiltersDesc] = partition(
    (filter) => props.activeFilters.includes(filter.label),
    props.filters
  );

  if ((props.filters ?? []).length === 0) {
    return null;
  }

  return (
    <Stack direction="row" flexWrap="wrap" spacing={1.5} alignItems="center">
      {sortBy((filter) => props.activeFilters.indexOf(filter.label), activeFilterDesc).map((filter) => {
        const { component, label, defaultFilter, defaultValue, ...rest } = filter;
        const Component = component;
        const sharedProps = {
          label: label,
          variant: 'outlined',
          color: 'neutral',
          showLabelAboveInput: false,
        } as const;

        if (defaultFilter) {
          return (
            <Fragment key={label}>
              <Component {...rest} {...sharedProps} onChange={filter.onChange} />
            </Fragment>
          );
        }

        return (
          <Stack direction={'row'} key={filter.label} alignItems={'center'} gap={0.5}>
            <Component
              {...rest}
              {...sharedProps}
              onChange={(val: unknown) => {
                if (isEqual(val, filter.defaultValue)) {
                  props.setActiveFilters((filters) => filters.filter((filt) => filt !== filter.label));
                }

                filter.onChange(val);
              }}
            />
            <IconButton
              onClick={() => {
                props.setActiveFilters((filters) => filters.filter((filt) => filt !== filter.label));
                filter.onChange(filter.defaultValue);
              }}
            >
              <Close />
            </IconButton>
          </Stack>
        );
      })}

      {disabledFiltersDesc.length > 0 && (
        <Dropdown>
          <MenuButton size={'sm'} sx={{ marginLeft: 2 }} variant={'plain'} color="primary">
            <Add />
            Add filter
          </MenuButton>
          <Menu>
            {disabledFiltersDesc.map((filter) => (
              <MenuItem
                key={filter.label}
                onClick={() => {
                  props.setActiveFilters((filters) => [...filters, filter.label]);
                }}
              >
                {filter.label}
              </MenuItem>
            ))}
          </Menu>
        </Dropdown>
      )}
    </Stack>
  );
};
