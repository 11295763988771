import { type BigNumber, bignumber } from 'mathjs';

export const calculateCummulativeReturns = (values: BigNumber[] | number[]): BigNumber[] => {
  if (values.length === 0) {
    return [];
  }

  const result: BigNumber[] = values.map((val) => bignumber(val).add(1));
  for (let i = 1; i < result.length; i++) {
    result[i] = result[i].mul(result[i - 1]);
  }

  return result.map((val) => val.sub(1));
};
