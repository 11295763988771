import { type ReactElement, type ReactNode, useCallback, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { GraphQLErrorMessage } from './form/GraphQLApiErrorMessage.tsx';
import type { DefaultColorPalette } from '@mui/joy/styles/types/colorSystem';
import { useLocation } from 'react-router';
import { usePrevious } from 'react-use';
import type { Scope } from '@sentry/react';

const GErrorBoundary = ({
  children,
  color = 'danger',
  resetOnNavigation = false,
  onError,
}: {
  children: ReactNode;
  color?: DefaultColorPalette;
  resetOnNavigation?: boolean;
  onError?: (options: { error: unknown; scope: Scope }) => void;
}): ReactElement => {
  const Component = useCallback(
    ({ error, resetError }: { error: unknown; resetError: () => void }): ReactElement => {
      // biome-ignore lint/correctness/useHookAtTopLevel: use callbacks returns a component
      const location = useLocation();
      // biome-ignore lint/correctness/useHookAtTopLevel: use callbacks returns a component
      const prevLocationPathname = usePrevious(location.pathname);
      // biome-ignore lint/correctness/useHookAtTopLevel: use callbacks returns a component
      useEffect(() => {
        if (resetOnNavigation && prevLocationPathname && prevLocationPathname !== location.pathname) {
          resetError();
        }
      }, [prevLocationPathname, location, resetError, resetOnNavigation]);

      return <GraphQLErrorMessage error={error} padding={2} color={color} />;
    },
    [color, resetOnNavigation]
  );

  return (
    <Sentry.ErrorBoundary
      fallback={Component}
      beforeCapture={(scope, error) => {
        if (!onError) {
          return;
        }

        onError({ error, scope });
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default GErrorBoundary;
