import { KeyValueItem, type KeyValueItemProps } from '../../technical/KeyValueItem.tsx';
import { SubAccountLabel } from '../../portfolio/account/SubAccountLabel.tsx';
import AssetLabel from '../../market/asset/AssetLabel.tsx';
import { formatEnum } from '../../formatter.utils.ts';
import GTextArea from '../../technical/inputs/GTextArea/GTextArea.tsx';
import { type ReactElement, useState } from 'react';
import {
  TransactionFilterInputDocument,
  TransactionsDocument,
  useUpdateTransactionMutation,
} from '../../../generated/graphql.tsx';
import { Chip, Grid, Stack } from '@mui/joy';
import type { Transaction } from './Transaction.types.ts';
import type { Row } from '@tanstack/table-core';
import { pick } from 'lodash/fp';

const gridSize = {
  lg: 4,
  md: 6,
  xs: 12,
};

const KeyValueGridCell = (props: KeyValueItemProps): ReactElement => {
  return (
    <Grid {...gridSize}>
      <KeyValueItem {...props} />
    </Grid>
  );
};

const TransactionDetailsTab = ({ row }: { row: Row<Transaction> }): ReactElement => {
  const [updateTransaction, { loading, error }] = useUpdateTransactionMutation({
    refetchQueries: [TransactionsDocument, TransactionFilterInputDocument],
  });

  const [comment, setComment] = useState(row.original.comment ?? '');
  return (
    <Grid container>
      <Grid xs={12} lg={8} container spacing={2}>
        <KeyValueGridCell
          label={'Sub-account'}
          direction={'column'}
          value={<SubAccountLabel subAccount={row.original.subAccount} plain />}
        />
        <KeyValueGridCell
          label={'Attributed to asset'}
          direction={'column'}
          value={
            row.original.attributedToAsset ? <AssetLabel asset={row.original.attributedToAsset} plain /> : undefined
          }
        />
        <KeyValueGridCell label={'External id'} direction={'column'} value={row.original.externalId} />
        <KeyValueGridCell
          label={'External type'}
          direction={'column'}
          value={formatEnum(row.original.externalType ?? '')}
        />
        <KeyValueGridCell
          label={'Order side'}
          direction={'column'}
          value={formatEnum(row.original.order?.side ?? '')}
        />
        <KeyValueGridCell
          label={'Order type'}
          direction={'column'}
          value={formatEnum(row.original.order?.type ?? '')}
        />
        <KeyValueGridCell
          label={'Order external id'}
          direction={'column'}
          value={row.original.order?.externalId ?? ''}
        />
        <Grid {...gridSize}>
          <Stack direction={'row'} flexWrap={'wrap'} gap={0.5}>
            {row.original.tags.map((tag) => {
              return (
                <Chip key={tag} color="primary" size={'sm'}>
                  {tag}
                </Chip>
              );
            })}
          </Stack>
        </Grid>
      </Grid>
      <Grid xs={12} lg={4}>
        <GTextArea
          variant={'soft'}
          disabled={loading}
          error={error ? 'Error saving comment' : ''}
          placeholder={'Type a comment'}
          minRows={2}
          fullHeight
          value={loading ? 'Saving...' : comment}
          width="fullWidth"
          onBlur={() => {
            const tran = row.original;
            if (tran.comment === comment) {
              return;
            }

            updateTransaction({
              variables: {
                input: {
                  ...pick(['id', 'userType', 'time', 'externalId', 'externalType', 'status', 'tags', 'order'], tran),
                  subAccount: { id: tran.subAccount.id },
                  attributedToAsset: tran.attributedToAsset ? { id: tran.attributedToAsset.id } : null,
                  order: tran.order ? pick(['side', 'type', 'externalId'], tran.order) : undefined,
                  legs: tran.legs.map((leg) => ({
                    ...pick(['amount', 'side', 'slot', 'time', 'type', 'unitValue'], leg),
                    asset: {
                      id: leg.asset.id,
                    },
                  })),
                  comment,
                },
              },
            });
          }}
          onChange={(val) => setComment(val ?? '')}
        />
      </Grid>
    </Grid>
  );
};

export default TransactionDetailsTab;
