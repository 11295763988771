import { Stack } from '@mui/joy';
import React, { type ReactElement } from 'react';
import AsyncActionButton from 'components/technical/inputs/GButton/AsyncActionButton';
import GCheckbox from 'components/technical/inputs/GCheckbox/GCheckbox';

import NotificationList from './NotificationList';
import { useMarkAllAsRead } from './useMarkAllAsRead';

const NotificationDashboard = (): ReactElement => {
  const [showUnreadOnly, setShowUnreadOnly] = React.useState(false);

  const markNotificationsAsRead = useMarkAllAsRead();

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" alignItems="center" width="100%" spacing={1.5}>
        <GCheckbox
          width="minContent"
          label="Show unread only"
          onChange={(): void => setShowUnreadOnly(!showUnreadOnly)}
        />
        <AsyncActionButton onClick={markNotificationsAsRead}>Mark all as read</AsyncActionButton>
      </Stack>
      <NotificationList hideRowsPerPage defaultPageSize={10} showUnreadOnly={showUnreadOnly} />
    </>
  );
};

export default NotificationDashboard;
