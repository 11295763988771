import type { SelectOption } from 'components/technical/inputs/Select/Select.props.ts';

export enum ConstraintType {
  Equal = 'Equal',
  Between = 'Between',
  None = 'None',
}

const constraintTypeLabels: Record<ConstraintType, string> = {
  [ConstraintType.Between]: 'Between',
  [ConstraintType.Equal]: 'Equal',
  [ConstraintType.None]: 'None',
};

export const constraintTypeValues: SelectOption<ConstraintType>[] = Object.values(ConstraintType).map((value) => ({
  label: constraintTypeLabels[value],
  value: value,
  key: constraintTypeLabels[value],
}));
