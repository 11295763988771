import { Modal, ModalDialog, Stack } from '@mui/joy';
import type { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import GFormProvider from 'components/technical/form/GFormProvider';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import { FormInput } from 'components/technical/inputs';

interface FormState {
  portfolioValue: number;
}

const formSchema = yup.object({
  portfolioValue: yup.number().positive(),
});

export const ImportPortfolioDialog = (props: {
  onClose: () => void;
  portfolioAmount: number | undefined;
  onPortfolioValueProvided: (value: number) => void;
}): ReactElement => {
  const methods = useForm<FormState>({
    resolver: gYupResolver(formSchema),
    defaultValues: {
      portfolioValue: undefined,
    },
  });

  const handleFormSubmit = async (data: FormState): Promise<void> => {
    props.onPortfolioValueProvided(data.portfolioValue ?? props.portfolioAmount);
  };

  return (
    <Modal open={true} onClose={props.onClose}>
      <ModalDialog>
        <div
          onSubmit={(e): void => {
            // prevent propagation of submitEvent to parentForm so that they can work independently
            e.stopPropagation();
          }}
        >
          <GFormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
              <GDialogHeader>Import portfolio</GDialogHeader>
              <Stack justifyContent="center" spacing={1.5}>
                <FormInput
                  showLabelAboveInput
                  label="Portfolio value"
                  name="portfolioValue"
                  type="number"
                  width="xl2"
                  startDecorator="$"
                  placeholder={props.portfolioAmount?.toString()}
                />
                <SubmitButton width="xl2">Import portfolio</SubmitButton>
              </Stack>
            </form>
          </GFormProvider>
        </div>
      </ModalDialog>
    </Modal>
  );
};
