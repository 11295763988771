import React, { type ReactElement, type ReactNode } from 'react';
import { type IconVariant, iconVariantToPx } from './market/asset/cryptocurrencies/CryptocurrenciesData.tsx';
import isNil from 'lodash/fp/isNil';
import { Avatar, AvatarGroup } from '@mui/joy';

export const MaxItemsAvatarGroup = (props: {
  size: IconVariant;
  maxItems?: number;
  children: ReactNode;
}): ReactElement => {
  const children = React.Children.toArray(props.children);
  // don't show +1
  const truncatedItems =
    !isNil(props.maxItems) && children.length > props.maxItems ? children.slice(0, props.maxItems - 1) : children;
  const itemsNumDiff = children.length - truncatedItems.length;
  const iconSize = iconVariantToPx(props.size);
  const digitsLength = itemsNumDiff.toString().length;
  const totalTextLength = digitsLength + 1;
  // add margin for text from each side
  const textSpace = iconSize - 5;
  // at 10px font, each letter takes about 7px. Details depend on the font
  const fontHeight = Math.min(textSpace, ((10 / 7) * textSpace) / totalTextLength);
  const extraAvatar =
    itemsNumDiff !== 0 ? (
      <Avatar
        sx={{
          width: iconSize,
          height: iconSize,
        }}
      >
        <div
          style={{
            fontSize: `${fontHeight.toFixed(2)}px`,
            transform: 'translate(-10%)',
          }}
        >
          +{itemsNumDiff}
        </div>
      </Avatar>
    ) : null;

  return (
    <AvatarGroup>
      {truncatedItems.map((item, i) => (
        <span key={i}>{item}</span>
      ))}
      {extraAvatar}
    </AvatarGroup>
  );
};
