export const sharedCheckboxColumnProps = (
  isSubmitting: boolean
): {
  checkboxSelection: () => boolean;
  headerCheckboxSelection: boolean;
  showDisabledCheckboxes: boolean;
} => ({
  checkboxSelection: () => !isSubmitting,
  headerCheckboxSelection: true,
  showDisabledCheckboxes: true,
});
