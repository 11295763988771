import { Card, Stack } from '@mui/joy';
import sortBy from 'lodash/fp/sortBy';
import type { ReactElement, ReactNode } from 'react';

import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import { KeyValueItem } from 'components/technical/KeyValueItem';
import KeyValueListSkeleton from 'components/technical/KeyValueListSkeleton';
import SectionColumn from 'components/technical/layout/Column/SectionColumn';
import GValueChange from 'components/technical/ValueChange/GValueChange';
import { formatPercentage, formatterForName } from '../formatter.utils';
import { getFormat, getNameWithTooltip } from '../metrics/MetricsData';
import type { MetricParams } from '../metrics/MetricsData.types.ts';
import { isRiskMeasureTarget } from '../metrics/PortfolioRiskMeasures.ts';

export interface PortfolioRiskMetric {
  name: Label;
  value: number;
  params: MetricParams;
  netChange?: number;
}

const getLabel = (metric: PortfolioRiskMetric): ReactNode => {
  return getNameWithTooltip(metric.name, metric.params);
};

const PortfolioRiskMetric = ({ metric }: { metric: PortfolioRiskMetric }): ReactElement => {
  const metricValue = formatterForName(getFormat(metric.name))(metric.value);

  if (!metricValue) {
    return <KeyValueItem label={getLabel(metric)} />;
  }

  return (
    <KeyValueItem
      label={getLabel(metric)}
      value={
        <Stack direction="row" spacing={0.5} alignItems="center">
          <span>{metricValue}</span>
          {metric.netChange !== undefined && (
            <GValueChange
              level="body-sm"
              value={metric.netChange}
              text={formatPercentage(metric.netChange)}
              indicatorVariants={['sign', 'parenthesis']}
            />
          )}
        </Stack>
      }
    />
  );
};

export const PortfolioOptimizerRiskMetrics = (props: { result: PortfolioRiskMetric[] | undefined }): ReactElement => {
  return (
    <SectionColumn>
      <HeaderBar title="Risk metrics" />
      <Card>
        {props.result ? (
          <Stack spacing={1}>
            {sortBy((metric) => metric.name, props.result)
              .filter((metric) => isRiskMeasureTarget(metric.name))
              .map((metric) => (
                <PortfolioRiskMetric metric={metric} key={`${metric.name}_${paramsToKey(metric.params)}`} />
              ))}
          </Stack>
        ) : (
          <KeyValueListSkeleton />
        )}
      </Card>
    </SectionColumn>
  );
};

function paramsToKey(params?: MetricParams): string {
  if (!params) {
    return '';
  }
  return Object.entries(params)
    .map(([key, value]) => `${key}:${value}`)
    .join('_');
}
