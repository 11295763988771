import { Box, Stack } from '@mui/joy';
import { useRef, type ReactElement, useState, useEffect } from 'react';
import {
  IPortfolioDefinitionType,
  type IPortfolioDefListInputQuery,
  usePortfolioDefListInputSuspenseQuery,
} from '../../../../generated/graphql.tsx';
import { defaultRowSpacing } from '../../../StackSpacing.ts';
import { RealPortfolioDefList } from './realPortfolio/RealPortfolioDefList.tsx';
import { RebalancedPortfolioDefList } from './rebalancedPortfolio/RebalancedPortfolioDefList.tsx';
import Selecto, { type OnSelectEvent } from 'react-selecto';
import { selectableItemsClass } from './PortfolioDefList.utils.ts';
import GFab from 'components/technical/inputs/GFab.tsx';
import { ScienceOutlined } from '@mui/icons-material';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import { usePrevious } from '../../../UsePrevious.tsx';
import { motion } from 'framer-motion';
import { pageInsetSpacing, transitionDurationS } from '../../../../theme/consts.ts';
import type { NonNullableFields } from '../../../type.utils.ts';
import { useNavigate } from 'react-router';
import { seeMoreDropdownClass } from '../../../technical/SeeMoreDropDown/SeeMoreDropdown.tsx';

const PortfolioDefListContainer = (): ReactElement => {
  const { data } = usePortfolioDefListInputSuspenseQuery();
  const { definitions, accounts, subFunds } = data.portfolio;
  const realPortfolioDefs = definitions
    .filter((def) => def.type === IPortfolioDefinitionType.Real)
    .map((def) => ({
      ...def,
      realDefAccounts: def.realDefAccounts ?? [],
      realDefSubFunds: def.realDefSubFunds ?? [],
    }));

  const rebalancedDefs = definitions.filter((def) => def.type === IPortfolioDefinitionType.Rebalanced);
  return (
    <PortfolioDefList
      realPortfolioDefs={realPortfolioDefs}
      rebalancedDefs={rebalancedDefs}
      accounts={accounts}
      subFunds={subFunds}
    />
  );
};

type InputQuery = IPortfolioDefListInputQuery['portfolio'];
type DefinitionResult = InputQuery['definitions'][number];
const cardHeight = '10rem';

const isClickOnDropdown = (e: OnSelectEvent<unknown>): boolean => {
  const { inputEvent } = e;
  if (!(inputEvent instanceof MouseEvent)) {
    return false;
  }

  const { target } = inputEvent;
  if (!target) {
    return false;
  }

  const node = target as HTMLElement;
  return node.matches(`.${seeMoreDropdownClass}, .${seeMoreDropdownClass} *`);
};

const PortfolioDefList = ({
  rebalancedDefs,
  realPortfolioDefs,
  accounts,
  subFunds,
}: {
  rebalancedDefs: InputQuery['definitions'];
  realPortfolioDefs: NonNullableFields<DefinitionResult, 'realDefAccounts' | 'realDefSubFunds'>[];
  accounts: InputQuery['accounts'];
  subFunds: InputQuery['subFunds'];
}): ReactElement => {
  const { showInfoMessage } = useFeedback();
  const selectoRef = useRef<Selecto | null>(null);
  const selectionContainerRef = useRef<HTMLDivElement | null>(null);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    // it's possible that we selected some items and then removed them
    const existingIds = new Set([...realPortfolioDefs.map((el) => el.id), ...rebalancedDefs.map((el) => el.id)]);
    setSelectedIds((ids) => {
      return ids.filter((id) => existingIds.has(id));
    });
  }, [rebalancedDefs, realPortfolioDefs]);

  useEffect(() => {
    const onScroll = () => {
      selectoRef.current?.checkScroll();
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const prevSelectedCount = usePrevious(selectedIds.length);
  useEffect(() => {
    if (prevSelectedCount > 0) {
      return;
    }

    if (selectedIds.length === 0) {
      return;
    }

    showInfoMessage({
      msg: "Press 'shift' to select more portfolios",
      preventDuplicates: true,
    });
  }, [prevSelectedCount, selectedIds, showInfoMessage]);

  return (
    <>
      <Stack direction="column" rowGap={defaultRowSpacing} minHeight={'100%'} ref={selectionContainerRef}>
        <Selecto
          ref={selectoRef}
          selectableTargets={[`.${selectableItemsClass}`]}
          selectByClick={true}
          selectFromInside={true}
          toggleContinueSelect={'shift'}
          hitRate={50}
          scrollOptions={{
            container: document.documentElement,
            getScrollPosition: ({ container }) => {
              return [container.scrollLeft, container.scrollTop];
            },
          }}
          onScroll={(e) => {
            // required for selected area to move when we scroll the window
            document.documentElement.scrollBy(e.direction[0] * 10, e.direction[1] * 10);
          }}
          onSelect={(e) => {
            if (isClickOnDropdown(e)) {
              console.debug('Deselecting item');
              selectoRef.current?.setSelectedTargets([]);
              setSelectedIds([]);
              return;
            }

            setSelectedIds(e.selected.map((el) => el.dataset.id!));
          }}
        />
        <RealPortfolioDefList
          defs={realPortfolioDefs}
          selected={selectedIds}
          accounts={accounts}
          cardHeight={cardHeight}
          subFunds={subFunds.list}
        />
        <RebalancedPortfolioDefList defs={rebalancedDefs} cardHeight={cardHeight} selected={selectedIds} />
      </Stack>
      {selectedIds.length > 0 && (
        <Box
          sx={(theme) => ({
            position: 'fixed',
            bottom: theme.spacing(pageInsetSpacing),
            right: theme.spacing(pageInsetSpacing),
          })}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: transitionDurationS.Short }}
          >
            <GFab
              startDecorator={<ScienceOutlined />}
              onClick={() => {
                navigate('/app/copilot/lab/portfolio/backtesting', {
                  state: {
                    portfolioIds: selectedIds,
                  },
                });
              }}
            >
              Analyze
            </GFab>
          </motion.div>
        </Box>
      )}
    </>
  );
};

export default PortfolioDefListContainer;
