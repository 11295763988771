import { Box, Card, type CardProps, Grid, Stack, type Theme, Typography } from '@mui/joy';
import type { FocusEvent, ReactElement, ReactNode, MouseEvent, KeyboardEvent, ForwardedRef } from 'react';
import { AddCircle } from 'components/technical/icons';
import type { Breakpoint, SxProps } from '@mui/system';
import { fixedForwardRef } from '../fixedForwardRef.ts';
import mapKeys from 'lodash/fp/mapKeys';

export type GridWidth = Partial<Record<Breakpoint, number>>;

export interface GCardProps {
  gridWidth: GridWidth;
  height: string;
  onClick?: (e: FocusEvent | MouseEvent | KeyboardEvent) => void;
  selectable?: boolean;
  cardWrapperSx?: SxProps<Theme>;
  cardSx?: SxProps<Theme>;
  addNewText?: string;
  children?: ReactNode;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
  dataset?: Record<string, string>;
  variant?: CardProps['variant'];
}

const BaseGCard = (
  {
    children,
    onClick,
    addNewText,
    height,
    gridWidth,
    cardWrapperSx,
    cardSx,
    size = 'lg',
    className,
    dataset,
    selectable,
    variant,
  }: GCardProps,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement => {
  const dataProps: Record<string, string> = mapKeys((key) => `data-${key}`, dataset ?? {});

  return (
    <Grid xl={gridWidth.xl} lg={gridWidth.lg} md={gridWidth.md} sm={gridWidth.sm} width="100%">
      <Box sx={{ minHeight: height, height: '100%', ...cardWrapperSx }} ref={ref} {...dataProps} className={className}>
        <Card
          onClick={onClick}
          sx={[
            {
              height: '100%',
              cursor: onClick || selectable ? 'pointer' : 'auto',
            },
            ...(Array.isArray(cardSx) ? cardSx : [cardSx]).filter((sx) => !!sx),
          ]}
          size={size}
          variant={variant}
        >
          {children ? (
            children
          ) : (
            <Stack height="100%" direction="column" alignItems="center" justifyContent="center">
              <AddCircle />
              <Typography sx={{ color: 'secondary.200', marginTop: '.5rem' }}>{addNewText}</Typography>
            </Stack>
          )}
        </Card>
      </Box>
    </Grid>
  );
};

const GCard = fixedForwardRef(BaseGCard);

export default GCard;
