import { Fragment, type ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import {
  type IConversationHistoryQuery,
  useConversationHistoryLazyQuery,
  useDeleteConversationMutation,
} from '../../../../generated/graphql.tsx';
import dayjs, { type Dayjs } from 'dayjs';
import { ListItemButton } from '@mui/joy';
import { Box, List, ListDivider, ListItem, ListSubheader, Stack, Typography } from '@mui/joy';
import { groupBy, upperFirst } from 'lodash/fp';
import SeeMoreDropdown from 'components/technical/SeeMoreDropDown/SeeMoreDropdown.tsx';
import { GraphQLErrorMessage } from '../../../technical/form/GraphQLApiErrorMessage.tsx';
import Loader from 'components/technical/Loader/Loader.tsx';
import { useIntersection } from 'react-use';
import { DeleteMenuItem } from '../../../technical/inputs/GButton/DeleteDialogButton.tsx';
interface Conversation {
  title: string;
  id: string;
  updatedAt: Dayjs;
}

const historyReadLimit = 25;
const HistoryTab = ({
  onConversationSelected,
  onConversationDeleted,
}: { onConversationSelected: (convId: string) => void; onConversationDeleted: (id: string) => void }): ReactElement => {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const bottomAnchorRef = useRef<HTMLElement | null>(null);
  const wrapperRef = useRef<HTMLElement | null>(null);
  const lastFetchTime = useRef(0);
  const [deletedConversationIds, setDeletedConversationId] = useState<string[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [deleteConversation] = useDeleteConversationMutation();

  const bottomAnchorIntersection = useIntersection(bottomAnchorRef, {
    root: wrapperRef.current,
    rootMargin: '0px 0px 50px 0px',
  });

  const [hasMoreResults, setHasMoreResults] = useState(true);
  // I'm manually managing error and loading - when notifyOnNetworkStatusChange is true, sometimes client doesnt get updated
  const [loadInitialData, historyQuery] = useConversationHistoryLazyQuery({
    variables: {
      offset: 0,
      limit: historyReadLimit,
    },
  });

  const updateResults = useCallback((res: IConversationHistoryQuery['news']['agents']['conversations']): void => {
    if (res.length < historyReadLimit) {
      setHasMoreResults(false);
    }

    lastFetchTime.current = performance.now();
    setConversations((convs) => {
      const existingIds = new Set(convs.map((conv) => conv.id));
      return [
        ...convs,
        ...res
          .filter((item) => !existingIds.has(item.id))
          .map(
            (item): Conversation => ({
              id: item.id,
              title: item.title,
              updatedAt: dayjs(item.updatedAt),
            })
          ),
      ];
    });
  }, []);

  const convLength = conversations.length;
  useEffect(() => {
    if (
      loading ||
      !bottomAnchorIntersection?.isIntersecting ||
      !hasMoreResults ||
      bottomAnchorIntersection?.time < lastFetchTime.current
    ) {
      return;
    }

    lastFetchTime.current = performance.now();
    setLoading(true);
    setError(null);
    historyQuery
      .fetchMore({
        variables: {
          offset: convLength,
        },
      })
      .then(
        (res) => {
          updateResults(res.data!.news.agents.conversations);
        },
        (e) => setError(e)
      )
      .finally(() => setLoading(false));
  }, [bottomAnchorIntersection, historyQuery, convLength, hasMoreResults, updateResults, loading]);

  useEffect(() => {
    if (!historyQuery.called) {
      lastFetchTime.current = performance.now();
      setLoading(true);
      setError(null);
      loadInitialData()
        .then(
          (res) => {
            updateResults(res.data!.news.agents.conversations);
          },
          (e) => setError(e)
        )
        .finally(() => setLoading(false));
    }
  }, [loadInitialData, historyQuery, updateResults]);

  const groupedConvs = Object.entries(
    groupBy(
      (conv) => conv.updatedAt.startOf('day').unix(),
      conversations.filter((conv) => deletedConversationIds.every((delConvId) => delConvId !== conv.id))
    )
  )
    .map(([, convs]) => ({
      date: convs[0].updatedAt,
      convs: convs.toSorted((a, b) => b.updatedAt.unix() - a.updatedAt.unix()),
    }))
    .toSorted((a, b) => b.date.unix() - a.date.unix());

  return (
    <Stack direction={'column'} m={-2} p={2} minHeight={1}>
      <List
        size={'lg'}
        sx={{
          '--ListDivider-gap': '0rem',
          flexGrow: 0,
        }}
      >
        {groupedConvs.map(({ date, convs }) => {
          return (
            <Fragment key={date.unix()}>
              <ListSubheader>
                <Typography level={'body-xs'} textTransform={'none'}>
                  {upperFirst(date.fromNow())}
                </Typography>
              </ListSubheader>
              <ListDivider inset={'gutter'} />
              <ListItem
                nested
                sx={{
                  '--ListItem-paddingLeft': '1.75rem',
                  pb: 1,
                }}
              >
                <List>
                  {convs.map((conv) => (
                    <ListItem
                      key={conv.id}
                      endAction={
                        <SeeMoreDropdown variant={'soft'}>
                          <DeleteMenuItem
                            deleteMessage={'Are you sure you want to remove the conversation?'}
                            confirmationMessage={'Conversation removed'}
                            deleteItem={async () => {
                              await deleteConversation({
                                variables: {
                                  convId: conv.id,
                                },
                              });

                              setDeletedConversationId((ids) => [...ids, conv.id]);
                              onConversationDeleted(conv.id);
                            }}
                          />
                        </SeeMoreDropdown>
                      }
                    >
                      <ListItemButton
                        variant={'soft'}
                        onClick={() => {
                          onConversationSelected(conv.id);
                        }}
                      >
                        {conv.title}
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </ListItem>
            </Fragment>
          );
        })}
      </List>
      {loading && <Loader fullHeight={groupedConvs.length === 0} />}
      <GraphQLErrorMessage error={error} />
      <Box height={'1px'} width={'100%'} ref={bottomAnchorRef} />
    </Stack>
  );
};

export default HistoryTab;
