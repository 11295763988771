import { Card, Stack, Typography } from '@mui/joy';
import capitalize from 'lodash/fp/capitalize';
import type { ReactElement } from 'react';

import GLink from '../GLink/GLink';

const links = ['input', 'select', 'radio', 'checkbox', 'button', 'see-more-dropdown', 'markdown'];

const Playground = (): ReactElement => {
  return (
    <Card>
      <Stack justifyContent="flex-start" spacing={1.5}>
        {links.map((link) => (
          <GLink to={link} key={link}>
            <Typography level="title-lg">{capitalize(link.replace(/-/gi, ' '))}</Typography>
          </GLink>
        ))}
      </Stack>
    </Card>
  );
};

export default Playground;
