import { Card, Stack } from '@mui/joy';
import dayjs, { type Dayjs } from 'dayjs';
import range from 'lodash/fp/range';
import { bignumber, type BigNumber } from 'mathjs';
import type { ReactElement } from 'react';
import { formatNumber } from 'components/formatter.utils';
import { calculateDateRange } from 'components/technical/charts/Chart.utils.ts';
import {
  dateTimeAxisFormat,
  dateTimeExportFormat,
  type HighchartSeries,
  noYAxisTitle,
  tooltipFormat,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import HighChartsContainer from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper';
import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import SectionColumn from 'components/technical/layout/Column/SectionColumn';
import Message from 'components/technical/Message';

import VestedUnvestedHeader from './VestedUnvestedHeader';
import { type IAssetVestingQuery, useAssetVestingQuery } from '../../../../../../generated/graphql';
import type Highcharts from 'highcharts';

const calculateOptions = (): Highcharts.Options => {
  return {
    ...dateTimeAxisFormat,
    ...dateTimeExportFormat('vesting'),
    ...tooltipFormat,
    ...noYAxisTitle,
    plotOptions: {
      series: {
        step: 'left', // price is used until there is a new one
      },
      area: {
        stacking: 'normal',
        fillOpacity: 0.5,
        marker: {
          symbol: 'circle',
        },
      },
      line: {
        marker: {
          symbol: 'circle',
        },
      },
    },
  };
};

type VestingBalances = IAssetVestingQuery['bookkeeping']['investments']['assetBalance']['investments'][number];
const calculateVesting = (
  investments: VestingBalances[]
): { dates: Dayjs[]; totalVested: BigNumber[]; perInvestment: Record<string, BigNumber[]>; xRange: [Dayjs, Dayjs] } => {
  const dateRange = investments.flatMap((invBalances) => invBalances.balance.map((balance) => dayjs(balance.time)));
  const { minRange, maxRange } = calculateDateRange(dateRange);

  const dates: Dayjs[] = [];
  const totalValues = range(0, maxRange.diff(minRange, 'days') + 1).map(() => bignumber());
  for (let date = minRange; !date.isAfter(maxRange); date = date.add(1, 'day')) {
    dates.push(date);
  }

  const perVestingValues = Object.fromEntries(
    investments.map((inv): [string, BigNumber[]] => [inv.investment.id, [...totalValues]])
  );

  for (const inv of investments) {
    for (let balanceIndex = 0; balanceIndex < inv.balance.length; balanceIndex++) {
      const date = dayjs(inv.balance[balanceIndex].time);
      const amount = bignumber(inv.balance[balanceIndex].amount);
      const currentDateIndex = date.diff(minRange, 'days');
      const limitDate =
        balanceIndex + 1 < inv.balance.length ? dayjs(inv.balance[balanceIndex + 1].time).subtract(1, 'day') : maxRange;
      const limitDateIndex = limitDate.diff(minRange, 'days');
      for (let i = currentDateIndex; i <= limitDateIndex; i++) {
        totalValues[i] = totalValues[i].plus(amount);
        perVestingValues[inv.investment.id][i] = amount;
      }
    }
  }

  return { dates, totalVested: totalValues, perInvestment: perVestingValues, xRange: [minRange, maxRange] };
};
const calculateChartData = (data: IAssetVestingQuery): HighchartSeries[] => {
  const vestingBalances = data.bookkeeping.investments.assetBalance.investments;

  const { dates, totalVested, perInvestment } = calculateVesting(vestingBalances);

  const totalVestedData: HighchartSeries = {
    name: 'Total vested',
    data: dates.map((date, i) => ({
      x: dayjs.utc(date).valueOf(),
      y: totalVested[i].toNumber(),
      textValue: formatNumber(totalVested[i].toNumber()),
    })),
    type: 'area',
  };

  const idToInvestment = Object.fromEntries(
    vestingBalances.map((invBalance) => [invBalance.investment.id, invBalance.investment.name])
  );

  const perInvestmentVesting: HighchartSeries[] = Object.entries(perInvestment).map(
    ([id, values]): HighchartSeries => ({
      name: idToInvestment[id],
      data: dates.map((date, i) => ({
        x: dayjs.utc(date).valueOf(),
        y: values[i].toNumber(),
        textValue: formatNumber(values[i].toNumber()),
      })),
      type: 'line',
    })
  );

  return [totalVestedData, ...perInvestmentVesting];
};

const VestingSection = ({ asset }: { asset: { symbol: string; id: string } }): ReactElement => {
  const queryOutput = useAssetVestingQuery({
    variables: {
      assetId: asset.id,
    },
  });

  const vestingBalances = queryOutput.data?.bookkeeping.investments.assetBalance.investments;
  const vestingDetails = queryOutput.data?.bookkeeping.investments.assetDetails;
  return (
    <SectionColumn>
      <HeaderBar title="Vesting" />
      {vestingBalances?.length === 0 ? (
        <Message>No data</Message>
      ) : (
        <Stack spacing={1.5}>
          <Card>
            <Stack spacing={1.5}>
              {vestingDetails && <VestedUnvestedHeader details={vestingDetails} asset={asset} />}
              <HighChartsContainer<IAssetVestingQuery>
                {...queryOutput}
                calculateOptions={calculateOptions}
                calculateChartData={calculateChartData}
              />
            </Stack>
          </Card>
        </Stack>
      )}
    </SectionColumn>
  );
};

export default VestingSection;
