import { Stack } from '@mui/joy';
import Card from '@mui/joy/Card';
import type { FunctionComponent, ReactNode } from 'react';

const BigTile: FunctionComponent<{
  topLeft?: ReactNode;
  topRight?: ReactNode;
  bottomLeft?: ReactNode;
  bottomRight?: ReactNode;
  center?: ReactNode;
}> = ({ topLeft, topRight, bottomLeft, bottomRight, center }) => {
  return (
    <Card sx={{ height: '100%' }} variant="plain">
      <Stack justifyContent="space-between" sx={{ height: '100%' }} spacing={1.5}>
        <Stack direction="row" justifyContent="space-between" spacing={1.5}>
          <div>{topLeft}</div>
          <div>{topRight}</div>
        </Stack>
        {center && (
          <Stack direction="row" spacing={1.5}>
            {center}
          </Stack>
        )}
        <Stack direction="row" justifyContent="space-between" spacing={1.5}>
          <div>{bottomLeft}</div>
          <div>{bottomRight}</div>
        </Stack>
      </Stack>
    </Card>
  );
};

export default BigTile;
