import isEmpty from 'lodash/fp/isEmpty';
import isNil from 'lodash/fp/isNil';
import type { ReactElement } from 'react';

import StaticMultiAutocomplete from 'components/technical/inputs/Autocomplete/StaticMultiAutocomplete.tsx';
import GInput from 'components/technical/inputs/GInput/GInput.tsx';
import {
  type IParameterDescription,
  IParameterType,
  type IParameterVisibilityPredicate,
  type Maybe,
} from '../../../generated/graphql.tsx';
import type { AssetLabelInput } from '../../market/asset/AssetLabelService.ts';
import { createAssetSelectOptions } from '../../market/asset/AssetService.tsx';

export type ParameterOutput = {
  name: string;
  intValue?: number | null;
  strValue?: string | null;
  asset?: { symbol: string; id: string } | null | undefined;
};

export type ParameterDescription = Omit<IParameterDescription, 'id' | 'benchmarks'> & {
  benchmarks?: Maybe<AssetLabelInput[]>;
};

export const isSingleParameterOption = (param: ParameterDescription): boolean => {
  return (
    (param.type === IParameterType.Options && param.options?.length === 1) ||
    (param.type === IParameterType.Benchmark && param.benchmarks?.length === 1)
  );
};

function isVisible(visibleIf: IParameterVisibilityPredicate, values: Record<string, Array<string>>): boolean {
  const value = values[visibleIf.parameter];
  return !!value?.includes(visibleIf.selectedOption);
}

function areVisible(
  visibleIfs: Maybe<Array<IParameterVisibilityPredicate>> | undefined,
  values: Record<string, Array<string>>
): boolean {
  if (!visibleIfs) {
    return true;
  }
  return visibleIfs.every((visibleIf) => isVisible(visibleIf, values));
}

export const visibleParameters = (
  values: Record<string, string | string[] | AssetLabelInput[]>,
  params: ParameterDescription[]
): Array<string> => {
  const isOption = (param: ParameterDescription): boolean => param.type === IParameterType.Options;

  const optionValues = Object.fromEntries(
    params.filter(isOption).map((param) => [param.name, values[param.name] as string[]])
  );
  return params.filter((param) => areVisible(param.visibleIf, optionValues)).map((param) => param.name);
};

const validateInt = (value: string, param: ParameterDescription): string | undefined => {
  const values = value.split(',');
  if (values.length === 0) {
    return 'Required';
  }

  if (new Set(values).size !== values.length) {
    return 'Has duplicate values';
  }

  for (const val of values) {
    if (isEmpty(val)) {
      return 'Has empty value';
    }

    if (Number.isNaN(Number(val))) {
      return `${val} is not a number`;
    }

    if (isNil(param.minMax)) {
      continue;
    }

    const numberVal = Number(val);
    if (!Number.isInteger(numberVal)) {
      return `${numberVal.toFixed(2)} is not an integer`;
    }

    if (numberVal > param.minMax.max) {
      return `${numberVal} is greater than max ${param.minMax.max}`;
    }

    if (numberVal < param.minMax.min) {
      return `${numberVal} is greater than max ${param.minMax.max}`;
    }
  }
};

const validateOptions = (values: string[], param: ParameterDescription): string | undefined => {
  if (isNil(values) || values.length === 0) {
    return 'required';
  }

  const optionValues = (param.options ?? []).map((opt) => opt.value);
  for (const value of values) {
    if (!optionValues.includes(value)) {
      return `${value} is not a valid option`;
    }
  }
};

const validateBenchmark = (values: AssetLabelInput[], param: ParameterDescription): string | undefined => {
  if (isNil(values) || values.length === 0) {
    return 'required';
  }

  const benchmarkIds = (param.benchmarks ?? []).map((opt) => opt.id);
  for (const value of values) {
    if (!benchmarkIds.includes(value.id)) {
      return `${value} is not a valid benchmark`;
    }
  }
};

export const hasValidationErrors = (
  values: Record<string, string | string[] | AssetLabelInput[]>,
  params: ParameterDescription[]
): boolean => {
  const validationErrors = validateRiskMetricParameterValues(values, params);
  return Object.values(validationErrors).some((error) => !isNil(error) && !isEmpty(error));
};

export const validateRiskMetricParameterValues = (
  values: Record<string, string | string[] | AssetLabelInput[]>,
  params: ParameterDescription[]
): Record<string, string> => {
  const validationErrors: Record<string, string> = {};
  for (const param of params) {
    const value = values[param.name];
    if (param.type === IParameterType.Int) {
      validationErrors[param.name] = validateInt(value as string, param) ?? '';
    } else if (param.type === IParameterType.Options) {
      validationErrors[param.name] = validateOptions(value as string[], param) ?? '';
    } else {
      validationErrors[param.name] = validateBenchmark(value as AssetLabelInput[], param) ?? '';
    }
  }

  return validationErrors;
};

export const createParamInputElement = ({
  param,
  metricParameterValues,
  onMetricParameterValuesChanged,
  validationErrors,
}: {
  param: ParameterDescription;
  metricParameterValues: Record<string, string | string[] | AssetLabelInput[]>;
  onMetricParameterValuesChanged: (values: Record<string, string | string[] | AssetLabelInput[]>) => void;
  validationErrors: Record<string, string>;
}): ReactElement => {
  const commonInputProps = {
    showLabelAboveInput: true,
    label: param.name,
    width: 'normal',
    onChange: (value: string | string[] | AssetLabelInput[] | null): void =>
      onMetricParameterValuesChanged({ [param.name]: value ?? '' }),
    error: validationErrors[param.name],
  } as const;

  if (param.type === IParameterType.Int) {
    return (
      <GInput
        key={param.name}
        type="text"
        {...commonInputProps}
        width="small"
        value={metricParameterValues[param.name] as string}
      />
    );
  }

  if (param.type === IParameterType.Benchmark) {
    const optionProps = createAssetSelectOptions(param.benchmarks ?? []);
    return (
      <StaticMultiAutocomplete
        key={param.name}
        {...commonInputProps}
        {...optionProps}
        value={metricParameterValues[param.name] as AssetLabelInput[]}
      />
    );
  }

  return (
    <StaticMultiAutocomplete
      key={param.name}
      {...commonInputProps}
      value={(metricParameterValues[param.name] as string[]) ?? []}
      error={validationErrors[param.name]}
      options={(param.options ?? []).map((opt) => ({
        label: opt.label,
        searchText: opt.label,
        value: opt.value,
        key: opt.value,
      }))}
      limitTags={1}
    />
  );
};
