import type { StepConfig } from '../../../technical/wizard/StepConfig.ts';
import { PortfolioStep } from './PortforliosStep.tsx';
import type { StressTestFormAsset } from './PortfoliosStep.validation.tsx';
import type { UnifiedStressTestInputFields } from './UnifiedStressTest.validation.ts';

export const config = (
  goToNextStep: () => void,
  compositions: {
    name: string;
    composition: {
      asset: StressTestFormAsset;
      weight: number;
      amount: number | string | null | undefined;
      entryPrice: number | string | null | undefined;
    }[];
  }[]
): StepConfig<UnifiedStressTestInputFields> => ({
  label: 'Portfolios',
  element: <PortfolioStep goToNextStep={goToNextStep} compositions={compositions} />,
  fields: ['portfolios', 'portfolioLength'],
});
