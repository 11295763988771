import dayjs from 'dayjs';
import { formatISODate } from 'components/formatter.utils';
import type { ReportPreset } from 'components/technical/grids/GAgGridPresets.types';

const getDateFrom = (daysAgo: number): string => formatISODate(dayjs.utc().subtract(daysAgo, 'day')).toString();

// presets generated automatically, we need to patch them to apply relative to current date filter
export function getDefaultPresets(): ReportPreset[] {
  const currentDayFilter = {
    date: {
      dateFrom: getDateFrom(1),
      dateTo: null,
      filterType: 'date',
      type: 'equals',
    },
  };

  const presetPatches: Record<string, Partial<ReportPreset['settings']>> = {
    'Balance and exposure by underlying assets': {
      filters: currentDayFilter,
    },
    'Exposure by sector and category': {
      filters: currentDayFilter,
    },
    'Balance by venue': {
      filters: {
        date: {
          dateFrom: getDateFrom(30),
          type: 'greaterThan',
          dateTo: null,
          filterType: 'date',
        },
      },
    },
  };

  const reportNames = defaultPresets.map((preset) => preset.name);
  const patchesWithNoPreset = Object.keys(presetPatches).filter((key) => !reportNames.includes(key));
  if (patchesWithNoPreset.length > 0) {
    throw new Error(`There is patch(s) for ${patchesWithNoPreset.join(',')} preset(s) but no preset with such name(s)`);
  }

  return (defaultPresets as ReportPreset[]).map((preset) => {
    if (presetPatches[preset.name]) {
      return {
        ...preset,
        settings: { ...preset.settings, ...presetPatches[preset.name] },
      };
    }
    return preset;
  });
}

// default presets can be generated via "See all" -> "Copy current report state as JSON"
const defaultPresets: ReportPreset[] = [
  {
    name: 'Balance and exposure by underlying assets',
    isDefault: true,
    settings: {
      filters: {},
      pivotMode: true,
      columns: [
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'ag-Grid-AutoColumn',
          pivot: false,
          width: 220,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'date',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'symbol',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'underlyingAsset',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: true,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: 0,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'cluster-sector',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'cluster-category',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'cluster-cluster_price_action_optics',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'cluster-hey',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'side',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'type',
          pivot: true,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: 0,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'subAccount.name',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'venue',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'exposure-sideAware-true',
          pivot: false,
          width: 297.875,
          pinned: null,
          aggFunc: 'sum',
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'exposure-sideAware-false',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'balance',
          pivot: false,
          width: 297,
          pinned: null,
          aggFunc: 'sum',
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'amount-sideAware-true',
          pivot: false,
          width: 297,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'amount-sideAware-false',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
      ],
    },
  },
  {
    name: 'Balance by venue',
    isDefault: true,
    settings: {
      filters: {},
      pivotMode: true,
      columns: [
        {
          flex: null,
          hide: false,
          sort: 'desc',
          colId: 'date',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: true,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: 0,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'symbol',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'underlyingAsset',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'cluster-sector',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'cluster-category',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'cluster-cluster_price_action_optics',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'cluster-hey',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'side',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'type',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'subAccount.name',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'venue',
          pivot: true,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: 0,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'exposure-sideAware-true',
          pivot: false,
          width: 297.875,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'exposure-sideAware-false',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'balance',
          pivot: false,
          width: 297,
          pinned: null,
          aggFunc: 'sum',
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'amount-sideAware-true',
          pivot: false,
          width: 297,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'amount-sideAware-false',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'ag-Grid-AutoColumn',
          pivot: false,
          width: 220,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'pivot_exchange__balance',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
      ],
    },
  },
  {
    name: 'Balance and amount by asset',
    isDefault: true,
    settings: {
      columns: [
        {
          flex: null,
          hide: false,
          sort: 'desc',
          colId: 'date',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: true,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: 0,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'symbol',
          pivot: true,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: 0,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'underlyingAsset',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'cluster-sector',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'cluster-category',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'cluster-cluster_price_action_optics',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'cluster-hey',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'side',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'type',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'subAccount.name',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'venue',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'exposure-sideAware-true',
          pivot: false,
          width: 297.875,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'exposure-sideAware-false',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'balance',
          pivot: false,
          width: 297,
          pinned: null,
          aggFunc: 'sum',
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'amount-sideAware-true',
          pivot: false,
          width: 297,
          pinned: null,
          aggFunc: 'sum',
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'amount-sideAware-false',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'ag-Grid-AutoColumn',
          pivot: false,
          width: 220,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'pivot_symbol_BTC_balance',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'pivot_symbol_BTC_amount-sideAware-true',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
      ],
      filters: {},
      pivotMode: true,
    },
  },
  {
    name: 'Daily balance and exposure',
    isDefault: true,
    settings: {
      columns: [
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'ag-Grid-AutoColumn',
          pivot: false,
          width: 220,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: 'desc',
          colId: 'date',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: true,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: 0,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'symbol',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'underlyingAsset',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'cluster-sector',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'cluster-category',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'cluster-cluster_price_action_optics',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'cluster-hey',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'side',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'type',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'subAccount.name',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'venue',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'exposure-sideAware-true',
          pivot: false,
          width: 297.875,
          pinned: null,
          aggFunc: 'sum',
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'exposure-sideAware-false',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'balance',
          pivot: false,
          width: 297,
          pinned: null,
          aggFunc: 'sum',
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'amount-sideAware-true',
          pivot: false,
          width: 297,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'amount-sideAware-false',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
      ],
      filters: {},
      pivotMode: true,
    },
  },
  {
    name: 'Exposure by sector and category',
    isDefault: true,
    settings: {
      filters: {},
      pivotMode: true,
      columns: [
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'ag-Grid-AutoColumn',
          pivot: false,
          width: 220,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: 'desc',
          colId: 'date',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'symbol',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'underlyingAsset',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'cluster-sector',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: true,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: 0,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'cluster-category',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: true,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: 1,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'cluster-cluster_price_action_optics',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'cluster-hey',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'side',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'type',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'subAccount.name',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'venue',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'exposure-sideAware-true',
          pivot: false,
          width: 150,
          pinned: null,
          aggFunc: 'sum',
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'exposure-sideAware-false',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'balance',
          pivot: false,
          width: 141,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'amount-sideAware-true',
          pivot: false,
          width: 297,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'amount-sideAware-false',
          pivot: false,
          width: 200,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
      ],
    },
  },
];
