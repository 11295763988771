import { Avatar, SvgIcon, type SvgIconProps } from '@mui/joy';
import type { ReactElement } from 'react';

import { Activity, NotificationActive, Signal, WarningRound } from 'components/technical/icons/index';
import UnknownNotificationCategoryIcon from './icons/unknownNotificationCategory.svg?react';

export type NotificationCategory = 'INVESTMENT' | 'RISK' | 'ANOMALY' | 'MARKET_DATA';

export interface NotificationCategoryDetails {
  icon: (props: SvgIconProps) => ReactElement;
  name: string;
}

export const unknownCategoryData: NotificationCategoryDetails = {
  icon: (props: SvgIconProps): ReactElement => {
    return (
      <Avatar size="sm">
        <SvgIcon viewBox="14.791 4.569 27.021 31.512" component={UnknownNotificationCategoryIcon} {...props} />
      </Avatar>
    );
  },
  name: 'General',
};

export const notificationData: Record<NotificationCategory, NotificationCategoryDetails> = {
  RISK: {
    icon: WarningRound,
    name: 'Risk',
  },
  ANOMALY: {
    icon: Signal,
    name: 'Anomaly',
  },
  INVESTMENT: {
    icon: NotificationActive,
    name: 'Insights',
  },
  MARKET_DATA: {
    icon: Activity,
    name: 'Market',
  },
};
