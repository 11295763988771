import isNil from 'lodash/fp/isNil';
import { type FunctionComponent, type PropsWithChildren, useEffect, useState } from 'react';

import type { MarketFiltersState } from './Filters.types';
import { MarketFiltersContext, type ContextValue } from './MarketFiltersContext';
import bigNumMath from '../../../../bigNumMath';
import { useFilterOptionsQuery } from '../../../../generated/graphql';
import { parseDecimal } from '../../../graphql.utils';

const mapMinMaxToNumbers = (
  minMax: {
    min: string;
    max: string;
  } | null
): { min: number; max: number } | null => {
  if (!minMax) {
    return null;
  }

  return {
    min: bigNumMath.floor(parseDecimal(minMax.min), 2).toNumber(),
    max: bigNumMath.ceil(parseDecimal(minMax.max), 2).toNumber(),
  };
};

export const FiltersContainer: FunctionComponent<PropsWithChildren<object>> = ({ children }) => {
  const { loading, error, data } = useFilterOptionsQuery();
  const [defaultValues, setDefaultValues] = useState<MarketFiltersState | undefined>(undefined);

  useEffect(() => {
    if (!data) {
      return;
    }

    const options = data.assets.metricStats;
    const mappedMinMaxToNumbers: MarketFiltersState = {
      liquidity: mapMinMaxToNumbers(options.liquidity ?? null),
      marketCap: mapMinMaxToNumbers(options.marketCap ?? null),
      totalValueLocked: mapMinMaxToNumbers(options.totalValueLocked ?? null),
    };

    setDefaultValues(mappedMinMaxToNumbers);
    setState(mappedMinMaxToNumbers);
  }, [data]);

  const [state, setState] = useState<MarketFiltersState | undefined>(undefined);

  const value: ContextValue =
    loading || !isNil(error) || isNil(data)
      ? {
          loading: loading,
          error,
          setState: (): never => {
            throw new Error('Not initialized');
          },
        }
      : {
          loading: false,

          state: state!,

          defaultState: defaultValues!,
          setState,
        };

  return <MarketFiltersContext.Provider value={value}>{children}</MarketFiltersContext.Provider>;
};
