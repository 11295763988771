import { bignumber } from 'mathjs';
import * as yup from 'yup';

export type AssetWeight = {
  id: string;
  dollarValue: string;
  maxLeverage: string;
};

export type FineTunerInputFields = {
  portfolioEquity: string;
  assetWeights: AssetWeight[];
};

export const minLeverage = 2;
export const maxLeverage = 10;

export const schema = yup.object({
  portfolioEquity: yup.number().positive().notRequired(),
  assetWeights: yup.array(
    yup.object({
      id: yup.string().required(),
      maxLeverage: yup.number().required().min(minLeverage).max(maxLeverage),
      dollarValue: yup.number().required(),
    })
  ),
});

/**
 * we need to adjust dollar values when equity changes
 * e.g new equity 10$, max leverage 2, dollar value should be in range -20$ - +20$
 */
export function ensureDollarValueWithinBounds(
  dollarValue: string,
  maxLeverage: string,
  portfolioEquityOrDefault: number
): string {
  const max = bignumber(maxLeverage).mul(portfolioEquityOrDefault);
  const sign = bignumber(dollarValue || 0).isPositive() ? 1 : -1;
  return bignumber(dollarValue).abs().greaterThan(max) ? max.mul(sign).toString() : dollarValue;
}
