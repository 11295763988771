import { Stack } from '@mui/joy';
import type { ReactElement } from 'react';

import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage.tsx';
import SubmitButton from 'components/technical/form/SubmitButton.tsx';
import { defaultRowSpacing } from '../../../StackSpacing.ts';

const SubmitStep = (): ReactElement => {
  return (
    <Stack gap={defaultRowSpacing} alignItems="flex-start">
      <GraphQLApiFormErrorMessage />
      <SubmitButton>Submit</SubmitButton>
    </Stack>
  );
};

export default SubmitStep;
