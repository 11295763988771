import { IAgentResultStatusType, IMessageRoleType } from '../../../../generated/graphql.tsx';

export const isMsgPending = (msg: {
  role: IMessageRoleType;
  agentResult?: {
    status: IAgentResultStatusType;
  } | null;
}): boolean => {
  if (msg.role === IMessageRoleType.User) {
    return false;
  }

  const status = msg.agentResult?.status;
  if (status !== IAgentResultStatusType.Success && status !== IAgentResultStatusType.Error) {
    return true;
  }

  return false;
};
