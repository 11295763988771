import type { FunctionComponent } from 'react';
import { Navigate } from 'react-router';

import type { PublicRouteProps } from './PublicRoute.props.ts';
import { useAuth } from '../../UseAuth.tsx';

const PublicRoute: FunctionComponent<PublicRouteProps> = ({ element, redirectOnNavigationTo = '/' }) => {
  const { user } = useAuth();

  return !user ? element : <Navigate to={redirectOnNavigationTo} replace />;
};

export default PublicRoute;
