import type { CellContext, ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';
import isNil from 'lodash/fp/isNil';
import { bignumber } from 'mathjs';
import type { FunctionComponent, ReactNode } from 'react';
import { DateTimeFormat, formatCash, formatDate, formatPercentage, formatterForName } from 'components/formatter.utils';
import { getFormat, getName } from 'components/metrics/MetricsData';
import {
  INDEX_PRICE_METRIC,
  LONG_RATE_METRIC,
  OPEN_INTEREST_AMOUNT_METRIC,
  SHORT_RATE_METRIC,
  TOTAL_VOLUME,
} from 'components/metrics/NonPortfolioMetricsData';
import GTable from 'components/technical/GTable/GTable';

import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import SectionColumn from 'components/technical/layout/Column/SectionColumn';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import { useUserTimezone } from 'components/technical/UseUserTimezone.tsx';
import { type IAssetType, type IDerivativeDetailsQuery, useDerivativeDetailsQuery } from 'generated/graphql';
import VenueLabel from '../../../../../venue/VenueLabel.tsx';

const getMetricColumn = (metric: string): ColumnDef<DerivativePosition> => {
  const formatter = formatterForName(getFormat(metric));

  return {
    header: getName(metric),
    cell: (props: CellContext<DerivativePosition, unknown>): ReactNode => formatter(props.row.original.metrics[metric]),
    accessorFn: (pos): number | undefined => {
      const val = pos.metrics[metric];
      return val ? bignumber(val).toNumber() : undefined;
    },
  };
};

type DerivativePosition = Omit<IDerivativeDetailsQuery['assets']['details'][number], 'asset'> & {
  asset: { symbol: string; derivativeDetails: { exchange: string; expirationDate?: string | null } };
  priceChange: string | null | undefined;
};

const DerivativeList: FunctionComponent<{
  derivatives: {
    id: string;
    symbol: string;
    type: IAssetType;
    derivativeDetails: { exchange: string; expirationDate?: string | null };
  }[];
}> = ({ derivatives }) => {
  const { data, loaded, Fallback } = useDefaultErrorHandling(
    useDerivativeDetailsQuery({
      variables: {
        filters: {
          assets: derivatives.map((asset) => asset.id),
        },
      },
    })
  );

  const timezone = useUserTimezone();

  if (!loaded) {
    return <Fallback />;
  }

  const derivativeIdToAsset = Object.fromEntries(derivatives.map((asset) => [asset.id, asset]));
  const derivativeIdToPriceChange = Object.fromEntries(data.assets.changes.map(({ asset, price }) => [asset, price]));
  const fetchedDerivatives = new Set(data.assets.details.map((details) => details.asset));
  const fetchedDerivativeData: DerivativePosition[] = data.assets.details.map((row) => ({
    ...row,
    asset: derivativeIdToAsset[row.asset],
    priceChange: derivativeIdToPriceChange[row.asset],
  }));

  const noDataDerivatives: DerivativePosition[] = derivatives
    .filter((der) => !fetchedDerivatives.has(der.id))
    .map((der) => ({
      asset: der,
      metrics: {},
      priceChange: undefined,
    }));

  const tableData: DerivativePosition[] = [...fetchedDerivativeData, ...noDataDerivatives];

  const columns: ColumnDef<DerivativePosition>[] = [
    {
      header: 'Label',
      cell: (props: CellContext<DerivativePosition, unknown>) => props.row.original.asset.symbol,
      accessorFn: (pos) => pos.asset.symbol,
    },
    {
      header: 'Venue',
      cell: (props: CellContext<DerivativePosition, unknown>): ReactNode => {
        const venue = props.row.original.asset.derivativeDetails?.exchange ?? '';
        return <VenueLabel format="long" venue={venue} />;
      },
      accessorFn: (pos) => pos.asset.derivativeDetails?.exchange,
    },
    {
      header: 'Price',
      cell: (props: CellContext<DerivativePosition, unknown>) => formatCash(props.row.original.price),
      accessorFn: (pos) => (pos.price ? bignumber(pos.price).toNumber() : undefined),
    },
    getMetricColumn(INDEX_PRICE_METRIC),
    {
      header: 'Price change',
      cell: (props: CellContext<DerivativePosition, unknown>): ReactNode => {
        const priceChange = props.row.original?.priceChange;
        if (!priceChange) {
          return '-';
        }
        return formatPercentage(bignumber(priceChange).abs().toNumber());
      },
      accessorFn: (pos) => bignumber(pos.priceChange).abs().toNumber(),
    },
    {
      header: 'High price',
      cell: (props: CellContext<DerivativePosition, unknown>) => formatCash(props.row.original.maxPrice),
      accessorFn: (pos) => (pos.maxPrice ? bignumber(pos.maxPrice).toNumber() : undefined),
    },
    {
      header: 'Low price',
      cell: (props: CellContext<DerivativePosition, unknown>) => formatCash(props.row.original.minPrice),
      accessorFn: (pos) => (pos.minPrice ? bignumber(pos.minPrice).toNumber() : undefined),
    },
    getMetricColumn(OPEN_INTEREST_AMOUNT_METRIC),
    getMetricColumn(TOTAL_VOLUME),
    getMetricColumn(LONG_RATE_METRIC),
    getMetricColumn(SHORT_RATE_METRIC),
    {
      header: 'Expiry date',
      cell: (props: CellContext<DerivativePosition, unknown>): ReactNode => {
        const { asset } = props.row.original;
        return formatDate(asset.derivativeDetails?.expirationDate, DateTimeFormat.DateTime, timezone);
      },
      accessorFn: ({ asset }) =>
        isNil(asset.derivativeDetails?.expirationDate)
          ? undefined
          : dayjs(asset.derivativeDetails?.expirationDate).toDate(),
    },
  ];

  return (
    <SectionColumn>
      <HeaderBar title="Derivatives" />
      <GTable columns={columns} data={tableData} disablePagination hideBorders />
    </SectionColumn>
  );
};
export default DerivativeList;
