import { type ReactElement, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import gYupResolver from 'components/technical/form/gYupResolver.ts';
import { useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError.tsx';
import { config as allocationConstraintConfig } from '../allocationConstraints/AllocationConstraintsStepConfig.tsx';
import { config as initialPortfolioConfig } from './initialPortfolio/InitialPortfolioStepConfig.tsx';
import { config as portfolioUniverseConfig } from './portfolioUniverse/PortfolioUniverseStepConfig.tsx';
import { config as assumptionsAndOutlookConfig } from '../assumptionsAndOutlook/AssumptionsAndOutlookStepConfig.tsx';
import { config as descriptionStepConfig } from '../description/DescriptionStepConfig.tsx';
import { config as objectivesStepConfig } from '../objective/ObjectivesStepConfig.tsx';
import { config as portfolioLevelConstraintsConfig } from '../portfolioConstraints/PortfolioConstraintsStepConfig.tsx';
import { config as submitConfig } from './submit/SubmitStepConfig.tsx';
import {
  IConstraintFormulation,
  IObjectiveType,
  type IPortfolioOptimizerWizardInputQuery,
  IReturnMeasureNameUi,
  type ISubmitPortfolioOptimizationMutation,
  type ISubmitPortfolioOptimizationMutationVariables,
  usePortfolioOptimizerWizardInputSuspenseQuery,
  useSubmitPortfolioOptimizationMutation,
} from '../../../../../generated/graphql.tsx';
import { type AssetLabelInput, isAssetLabelInput } from '../../../../market/asset/AssetLabelService.ts';
import { useSteps } from '../../../../technical/wizard/UseSteps.ts';
import {
  type PortfolioOptimizerInputFields,
  type PortfolioOptimizerOutputFields,
  schema,
  secondaryConstraintQuantityValues,
} from './PortfolioOptimizer.validation.ts';
import { createRequestInput } from './PortfolioOptimizerRequestFactory.ts';
import type { ColDef, ValueGetterParams } from 'ag-grid-community';
import {
  type ItemOutlookInput,
  returnMeasureValues,
  RiskDistributionOption,
  riskDistributionValues,
} from '../assumptionsAndOutlook/AssumptionsAndOutlook.validation.tsx';

import {
  portfolioDefinitionNameColumn,
  portfolioDefinitionRebalancingRuleColumn,
  portfolioDefinitionTypeColumn,
  portfolioNameGetter,
  portfolioRebalancingRuleGetter,
  portfolioTypeGetter,
} from './PortfolioDefinitionSharedColumns.ts';
import OptimizerWizard from './OptimizerWizard.tsx';
import { OptimizationType } from '../../optimization.utils.ts';

export type Portfolio = IPortfolioOptimizerWizardInputQuery['fundOptimization']['getOptimizableFundsForUser'][number];

const PortfolioOptimizerWizardContainer = (): ReactElement => {
  const strategyOptimizerWizardInputQuery = usePortfolioOptimizerWizardInputSuspenseQuery();

  const { assets } = strategyOptimizerWizardInputQuery.data;
  const benchmarks = assets.benchmark.filter((asset): asset is AssetLabelInput => isAssetLabelInput(asset));
  return (
    <PortfolioOptimizerWizard
      benchmarks={benchmarks}
      portfolios={strategyOptimizerWizardInputQuery.data.fundOptimization.getOptimizableFundsForUser}
    />
  );
};

type PortfolioOptimizerWizardProps = {
  benchmarks: AssetLabelInput[];
  portfolios: Portfolio[];
};

const PortfolioOptimizerWizard = ({ benchmarks, portfolios }: PortfolioOptimizerWizardProps): ReactElement => {
  const methods = useForm<PortfolioOptimizerInputFields>({
    resolver: gYupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      description: '',
      portfolioAmount: '1000',
      allowShortAndLeverage: false,
      objectives: [
        {
          type: IObjectiveType.MaxRiskAdjustedReturns,
          riskMetric: null,
        },
      ],
      constraintType: IConstraintFormulation.Percentage,
      constraints: [],
      universe: [],
      portfolioConstraints: {
        primaryConstraint: null,
        secondaryConstraint: null,
      },
      returnsForecast: IReturnMeasureNameUi.UserSupplied,
      riskBudgetAllocation: RiskDistributionOption.UserSupplied,
      outlook: [],
      givenPortfolio: {},
    },
  });

  const stepApi = useSteps();
  const { goToStep, validateVisitedSteps } = stepApi;

  const name = useWatch({
    name: 'name',
    control: methods.control,
  });

  const steps = useMemo(() => {
    let nextIndex = 1;
    const nextHandler = (): (() => void) => {
      const index = nextIndex++;
      return (): void => goToStep(index);
    };

    const idToPortfolio = Object.fromEntries(portfolios.map((port) => [port.id, port]));
    const assumptionsColumns: ColDef<ItemOutlookInput>[] = [
      {
        ...portfolioDefinitionNameColumn,
        valueGetter: (params: ValueGetterParams<ItemOutlookInput, string>): string | undefined => {
          if (!params.data) {
            return undefined;
          }
          const portfolio = idToPortfolio[params.data.id];
          return portfolioNameGetter(portfolio);
        },
      },
      {
        ...portfolioDefinitionRebalancingRuleColumn,
        valueGetter: (params: ValueGetterParams<ItemOutlookInput, string>): string | undefined => {
          if (!params.data) {
            return undefined;
          }
          const portfolio = idToPortfolio[params.data.id];
          return portfolioRebalancingRuleGetter(portfolio);
        },
      },
      {
        ...portfolioDefinitionTypeColumn,
        valueGetter: (params: ValueGetterParams<ItemOutlookInput, string>): string | undefined => {
          if (!params.data) {
            return undefined;
          }
          const portfolio = idToPortfolio[params.data.id];
          return portfolioTypeGetter(portfolio);
        },
      },
    ];
    return [
      descriptionStepConfig(nextHandler()),
      objectivesStepConfig(benchmarks, false, nextHandler()),
      portfolioLevelConstraintsConfig(benchmarks, secondaryConstraintQuantityValues, nextHandler()),
      initialPortfolioConfig(portfolios, nextHandler()),
      portfolioUniverseConfig(portfolios, nextHandler()),
      allocationConstraintConfig({
        assetGroups: [],
        assetIdToClusterToGroup: {},
        goToNextStep: nextHandler(),
        type: 'portfolio',
      }),

      assumptionsAndOutlookConfig({
        multifactorValues: [],
        columns: assumptionsColumns,
        riskBudgetForecast: {},
        returnsForecast: {},
        riskDistributionValues: riskDistributionValues.filter(
          (opt) => opt.value === RiskDistributionOption.UserSupplied
        ),
        returnMeasureValues: returnMeasureValues.filter((opt) => opt.value === IReturnMeasureNameUi.UserSupplied),
        showYield: false,
        sourceLabels: {
          universe: 'Portfolio universe',
          givenPortfolio: 'Initial portfolio',
        },

        goToNextStep: nextHandler(),
      }),
      submitConfig(),
    ];
  }, [benchmarks, goToStep, portfolios]);

  validateVisitedSteps({
    trigger: methods.trigger,
    steps,
  });

  const { onErrorAndThrow } = useGraphQLApiError(methods);
  const [submitOptimization] = useSubmitPortfolioOptimizationMutation();
  return (
    <OptimizerWizard<
      PortfolioOptimizerInputFields,
      ISubmitPortfolioOptimizationMutationVariables['input'],
      ISubmitPortfolioOptimizationMutation
    >
      type={OptimizationType.portfolio}
      createRequestInput={(input) => createRequestInput(input as unknown as PortfolioOptimizerOutputFields)}
      methods={methods}
      name={name}
      onErrorAndThrow={onErrorAndThrow}
      stepApi={stepApi}
      steps={steps}
      submitOptimization={submitOptimization}
    />
  );
};

export default PortfolioOptimizerWizardContainer;
