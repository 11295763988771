import type { ReactElement } from 'react';
import { usePortfolioDetailsSuspenseQuery } from '../../../../../generated/graphql.tsx';
import type { BacktestingConfiguration } from '../BacktestConfiguration.types.ts';
import PortfolioAllocationSection from './PortfolioAllocationSection.tsx';
import { Stack } from '@mui/joy';
import PortfolioReturnsSection from './PortfolioReturnsSection.tsx';
import type { AssetGroups } from '../../../../market/asset/groups/AssetGroups.types.ts';
import { convertDateInUtcToUTCISODate, parseUtcDate } from '../../../../date.utils.ts';
import HeaderBar from '../../../../technical/HeaderBar/HeaderBar.tsx';

const PortfolioDetails = ({
  backtestingConfig,
  assetGroups,
}: {
  backtestingConfig: BacktestingConfiguration;
  assetGroups: AssetGroups;
}): ReactElement => {
  const query = usePortfolioDetailsSuspenseQuery({
    variables: {
      portfolioIds: backtestingConfig.portfolios.map((p) => p.id) ?? [],
      // we want date to filter returns, whereas backend filters by snapshots, so we need to subtract one day
      since: convertDateInUtcToUTCISODate(parseUtcDate(backtestingConfig.range.since).subtract(1, 'day')),
      to: backtestingConfig.range.to,
    },
  });

  const data = query.data;
  if (!data) {
    return <></>;
  }

  const analysis = data.portfolio.analysis;
  // we want to remove first day 0 returns and first day cash weight. See: GV-7313
  const filteredAnalysis = analysis.map((portfolioAnalysis) => {
    return {
      ...portfolioAnalysis,
      dailyValues: portfolioAnalysis.dailyValues.slice(1),
    };
  });
  return (
    <Stack>
      <HeaderBar title={'Returns'} />
      <PortfolioReturnsSection analysis={filteredAnalysis} />
      <HeaderBar title={'Exposure'} />
      <PortfolioAllocationSection analysis={filteredAnalysis} {...assetGroups} />
    </Stack>
  );
};

export default PortfolioDetails;
