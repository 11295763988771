import { Chip, Stack } from '@mui/joy';
import { type CellContext, createColumnHelper } from '@tanstack/react-table';
import { type Dispatch, type FunctionComponent, type ReactElement, type SetStateAction, useMemo } from 'react';
import SeeMoreDropdown from 'components/technical/SeeMoreDropDown/SeeMoreDropdown.tsx';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import ConfirmationDialog from 'components/technical/form/dialog/ConfirmationDialog';
import GTable from 'components/technical/GTable/GTable';
import type { GColumnDef } from 'components/technical/GTable/GTable.props';

import {
  AssetGroupsDocument,
  type IAssetGroupsQuery,
  useDeleteAssetGroupMutation,
  useUpdateAssetGroupMutation,
} from '../../../../generated/graphql';
import { getAssets } from '../Asset.types';
import type { UserGroup } from './AssetGroupsDashboard';
import { DateTimeFormat, formatDate } from 'components/formatter.utils';
import { useUserTimezone } from 'components/technical/UseUserTimezone';
import CreateRenameAssetGroupDialog from './CreateRenameAssetGroupDialog';
import ChangeGroupAssetsDialog from './ChangeGroupAssetsDialog';
import AssetLabel from '../AssetLabel';
import { IconVariant } from '../cryptocurrencies/CryptocurrenciesData';
import { getAssetName } from '../AssetService';
import { sortBy } from 'lodash/fp';
import { useTablePaginator } from 'components/technical/GTable/UseTablePaginator';
import { DialogMenuItem } from '../../../technical/DialogDropdown/DialogMenuItem.tsx';

type GroupListProps = {
  clusterGroups: UserGroup[];
  assets: IAssetGroupsQuery['assets']['feature'];
  existingGroups: Set<string>;
  setSelectedCluster: Dispatch<SetStateAction<string | null>>;
  clusters: string[];
};

const columnHelper = createColumnHelper<UserGroup>();

const ClusterGroupsTable: FunctionComponent<GroupListProps> = ({
  clusterGroups,
  assets,
  existingGroups,
  setSelectedCluster,
  clusters,
}) => {
  const [deleteAssetGroup] = useDeleteAssetGroupMutation({ refetchQueries: [AssetGroupsDocument] });
  const [updateAssetGroup] = useUpdateAssetGroupMutation();

  const { showGraphqlError, showSuccessMessage } = useFeedback();
  const timezone = useUserTimezone();

  const { tablePaginator, page } = useTablePaginator({
    defaultPageSize: 25,
  });
  // first groups with assets
  const sorted = sortBy((group) => [group.assets.length > 0 ? 0 : 1, group.groupName], clusterGroups);
  const pageRows = sorted.slice(page.offset, page.offset + page.limit);

  // memo - cell is as a new component each rerender, it loses dialog state, rerender can be caused by api error
  const columns: GColumnDef<UserGroup>[] = useMemo(
    () => [
      {
        header: 'Group name',
        meta: {
          headerStyles: { width: '200px' },
        },
        accessorFn: (group) => group.groupName,
      },
      columnHelper.display({
        header: 'Assets',
        meta: {
          align: 'center',
          headerStyles: { flex: 1 },
        },
        cell: (props: CellContext<UserGroup, unknown>) => {
          const assets = sortBy((a) => getAssetName(a), props.row.original.assets);
          return (
            <Stack
              direction="row"
              sx={{
                minWidth: 150,
                alignItems: 'stretch',
                justifyContent: 'center',
                flexWrap: 'wrap',
                gap: 0.5,
              }}
            >
              {assets.map((asset) => (
                // display: 'flex' fixes vertical alignment
                <Chip key={asset.id} sx={{ cursor: 'default' }} slotProps={{ label: { sx: { display: 'flex' } } }}>
                  <AssetLabel asset={asset} size={IconVariant.MEDIUM} />
                </Chip>
              ))}
            </Stack>
          );
        },
      }),
      columnHelper.accessor('createdAt', {
        header: 'Added on',
        meta: {
          headerStyles: { width: '180px' },
        },
        cell: (cellProps: CellContext<UserGroup, string>) =>
          formatDate(cellProps.getValue(), DateTimeFormat.DateTime, timezone),
      }),
      columnHelper.accessor('updatedAt', {
        header: 'Last updated',
        meta: {
          headerStyles: { width: '180px' },
        },
        cell: (cellProps: CellContext<UserGroup, string | undefined>) =>
          formatDate(cellProps.getValue(), DateTimeFormat.DateTime, timezone),
      }),
      columnHelper.display({
        meta: {
          align: 'center',
          headerStyles: { width: '100px' },
        },
        header: 'Actions',
        cell: (cellProps: CellContext<UserGroup, unknown>) => {
          const assetGroupRow = cellProps.row.original;
          return (
            <Stack justifyContent="center" spacing={1.5}>
              <SeeMoreDropdown>
                <DialogMenuItem
                  renderDialog={({ onClose }): ReactElement => (
                    <CreateRenameAssetGroupDialog
                      onClose={onClose}
                      title={`Rename "${assetGroupRow.groupName}"`}
                      submitButtonText="Rename"
                      existingGroups={existingGroups}
                      initialState={{ groupName: assetGroupRow.groupName }}
                      handleFormSubmit={async (assetGroupFormData, onErrorAndThrow): Promise<void> => {
                        try {
                          await updateAssetGroup({
                            variables: {
                              input: {
                                oldGroupName: assetGroupRow.groupName,
                                newGroupName: assetGroupFormData.groupName,
                                clusterName: assetGroupRow.clusterName,
                                assets: assetGroupRow.assets.map((asset) => asset.id),
                              },
                            },
                          });
                        } catch (e) {
                          onErrorAndThrow(e);
                        }

                        onClose();
                        showSuccessMessage('Group successfully renamed');
                      }}
                    />
                  )}
                >
                  Rename
                </DialogMenuItem>

                <DialogMenuItem
                  renderDialog={({ onClose }): ReactElement => (
                    <ChangeGroupAssetsDialog
                      onClose={onClose}
                      assets={getAssets(
                        assets.filter(
                          (asset) =>
                            !clusterGroups.some(
                              // exclude assets already belonging to other groups in the same cluster
                              (group) =>
                                group.groupName !== assetGroupRow.groupName &&
                                group.assets.some((anotherGroupAsset) => anotherGroupAsset.id === asset.id)
                            )
                        )
                      )}
                      initialState={assetGroupRow}
                      handleFormSubmit={async (updatesAssetGroupFormData, onErrorAndThrow): Promise<void> => {
                        try {
                          await updateAssetGroup({
                            variables: {
                              input: {
                                oldGroupName: assetGroupRow.groupName,
                                newGroupName: assetGroupRow.groupName,
                                clusterName: assetGroupRow.clusterName,
                                assets: updatesAssetGroupFormData.assets.map((asset) => asset.id),
                              },
                            },
                          });
                        } catch (e) {
                          onErrorAndThrow(e);
                        }

                        showSuccessMessage('Group successfully updated');
                        onClose();
                      }}
                    />
                  )}
                >
                  Edit
                </DialogMenuItem>

                <DialogMenuItem
                  renderDialog={({ onClose }): ReactElement => (
                    <ConfirmationDialog
                      onClose={onClose}
                      onApprove={async () => {
                        try {
                          await deleteAssetGroup({
                            variables: {
                              input: { groupName: assetGroupRow.groupName },
                            },
                          });
                        } catch (e) {
                          showGraphqlError(e);
                          onClose();
                          return;
                        }

                        if (clusterGroups.length === 1) {
                          setSelectedCluster(clusters.find((cluster) => cluster !== assetGroupRow.clusterName) ?? null);
                        }

                        showSuccessMessage('Group successfully deleted');
                        onClose();
                      }}
                    >
                      {clusterGroups.length === 1
                        ? `Are you sure you want to remove asset group "${assetGroupRow.groupName}"? Removing the last asset group will also delete its associated cluster.`
                        : `Are you sure you want to remove asset group "${assetGroupRow.groupName}"?`}
                    </ConfirmationDialog>
                  )}
                >
                  Remove
                </DialogMenuItem>
              </SeeMoreDropdown>
            </Stack>
          );
        },
      }),
    ],
    [
      assets,
      clusterGroups,
      clusters,
      deleteAssetGroup,
      existingGroups,
      setSelectedCluster,
      showGraphqlError,
      timezone,
      updateAssetGroup,
      showSuccessMessage,
    ]
  );

  return <GTable columns={columns} data={pageRows} paginator={tablePaginator} totalResults={clusterGroups.length} />;
};

export default ClusterGroupsTable;
