import { Box, Stack } from '@mui/joy';
import * as Sentry from '@sentry/react';
import { type FunctionComponent, useState } from 'react';
import { Outlet } from 'react-router';

import { REPLAY_UNMASK_CLASS_NAME } from 'setup/index.ts';
import { getEnvConfig } from '../../../config';
import CookieBanner from '../../../CookieBanner';
import MsClarity from '../../../MsClarity';
import { useAuth } from '../../../UseAuth.tsx';
import NotificationListener from '../../copilot/newsNotification/NotificationListener';
import { FiltersContainer as QuantInsightsFilters } from '../../market/asset/filters/FiltersContainer.tsx';
import { NewsExplorerFilterProvider } from '../../market/newsExplorer/NewsExplorerFilterProvider.tsx';
import Appbar from '../Appbar/Appbar';
import ImpersonationAppbar from '../Appbar/ImpersonationAppbar';
import ImperativeModalProvider from '../ImperativeModal/ImperativeModalProvider.tsx';
import GSnackbarProvider from '../GSnackbarProvider/GSnackbarProvider';
import InpersonationBarProvider from '../ImpersonationBarProvider.tsx';
import Sidebar, { sidebarAnimation } from '../Sidebar/Sidebar';
import GErrorBoundary from '../GErrorBoundary.tsx';
import { useScreenBreakpointDown } from '../screenSizeUtils.ts';
import Drawer from '../Drawer/Drawer.tsx';

const Main: FunctionComponent = () => {
  const { originalUserId } = useAuth();
  const config = getEnvConfig();
  const mobileView = useScreenBreakpointDown('sm');
  const [isSideBarOpen, setSideBarOpen] = useState(!mobileView);

  return (
    <QuantInsightsFilters>
      <NewsExplorerFilterProvider>
        <>
          {config.clarityId && originalUserId && <MsClarity id={config.clarityId} userId={originalUserId} />}
          <CookieBanner />
          <Sentry.ErrorBoundary fallback={<div>Application error</div>}>
            <ImperativeModalProvider>
              <GSnackbarProvider>
                <Drawer>
                  <InpersonationBarProvider>
                    <NotificationListener />
                    <Stack
                      direction="row"
                      sx={{
                        flexGrow: 1,
                      }}
                      width="100%"
                      spacing={0}
                    >
                      <Sidebar isSideBarOpen={isSideBarOpen} setSideBarOpen={setSideBarOpen} />
                      <Stack
                        spacing={0}
                        sx={{
                          ml: isSideBarOpen ? 'var(--sidebar-width)' : 0,
                          transition: `margin ${sidebarAnimation}`,
                          background: 'background.body',
                          flexGrow: 1,
                          minWidth: 0,
                        }}
                      >
                        <ImpersonationAppbar />
                        <Appbar />
                        <Box className={REPLAY_UNMASK_CLASS_NAME} sx={{ height: '100%', flexGrow: 1, minWidth: 0 }}>
                          <GErrorBoundary resetOnNavigation>
                            <Outlet />
                          </GErrorBoundary>
                        </Box>
                      </Stack>
                    </Stack>
                  </InpersonationBarProvider>
                </Drawer>
              </GSnackbarProvider>
            </ImperativeModalProvider>
          </Sentry.ErrorBoundary>
        </>
      </NewsExplorerFilterProvider>
    </QuantInsightsFilters>
  );
};

export default Main;
