import { useContext } from 'react';

import { AuthnContext } from './Authn.tsx';
import type { ICognitoUser } from './generated/graphql.tsx';

export const useImpersonateUser = (): ((user: ICognitoUser) => void) => {
  const state = useContext(AuthnContext);
  if (state.initialized && state.user) {
    return state.impersonate;
  }

  return () => {
    throw new Error('Impersonation not available');
  };
};
