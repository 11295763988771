import type { ReactElement } from 'react';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';

import StressTestSimulator, { type ScenarioLibraryEvent } from './StressTestSimulator';
import { useStressTestSimulatorInputQuery } from '../../../generated/graphql';
import { getPublicAssets } from '../../market/asset/Asset.types';
import { createAssetToClusters } from '../../market/asset/groups/GroupService.ts';
import {
  aggregateSupportedAssetPositions,
  getPublicSpotPositionsWithValue,
} from '../../portfolio/account/SubAccountPositionsService.ts';

const StressTestSimulatorContainer = (): ReactElement => {
  const { data, loaded, Fallback } = useDefaultErrorHandling(useStressTestSimulatorInputQuery());

  if (!loaded) {
    return <Fallback />;
  }

  const assetToClusters = createAssetToClusters(data.assets.assetGroups.genieGroups);
  const publicSupportedAssets = getPublicAssets(data.assets.feature);

  const spotPositions = getPublicSpotPositionsWithValue(data.portfolio.positions.positions);

  const aggregatedPortfolio = aggregateSupportedAssetPositions(spotPositions, publicSupportedAssets).filter(
    (assetValue) => !assetValue.value.isZero()
  );

  return (
    <StressTestSimulator
      portfolioAssets={aggregatedPortfolio}
      assetToClusters={assetToClusters}
      supportedAssets={publicSupportedAssets}
      scenarioLibrary={
        data?.stressTest.scenarioLibrary.map((scenario) => ({
          ...scenario,
          events: scenario.events.map(
            (event): ScenarioLibraryEvent => ({
              ...event,
              netChange: event.netReturn,
            })
          ),
        })) ?? []
      }
    />
  );
};
export default StressTestSimulatorContainer;
