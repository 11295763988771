import isNil from 'lodash/fp/isNil';

import type {
  FormAssetOutputFields,
  FormOutputFields,
  ImmediateScheduleOutputFields,
  RecurringScheduleOutputFields,
} from './CreateInvestment.validation';
import {
  type IAssetValueInput,
  type IImmediateVestingInput,
  type IMutationCreateInvestmentArgs,
  type IRecurringVestingInput,
  type IVestingInput,
  IVestingType,
} from '../../../generated/graphql';

const createRecurringSchedule = (recurring: RecurringScheduleOutputFields, vested: number): IRecurringVestingInput => {
  return {
    schedule: {
      begin: recurring.begin.toISOString(),
      end: recurring.end.toISOString(),
      period: {
        unit: recurring.period.unit,
        value: recurring.period.interval,
      },
    },
    totalVestedAmount: vested.toString(),
  };
};
const createImmediateSchedule = (schedule: ImmediateScheduleOutputFields, vested: number): IImmediateVestingInput => {
  return {
    date: schedule.date.toISOString(),
    totalVestedAmount: vested.toString(),
  };
};

const convertAssetValue = (assetValue: FormAssetOutputFields): IAssetValueInput => {
  return {
    assetId: assetValue.asset.id,
    amount: assetValue.amount.toString(),
    unitValue: isNil(assetValue.marketValue) ? undefined : (assetValue.marketValue / assetValue.amount).toString(),
  };
};
export const createRequestInput = (data: FormOutputFields): IMutationCreateInvestmentArgs['input'] => {
  return {
    name: data.name,
    executedAt: data.executedAt.toISOString(),
    subAccount: {
      id: data.subAccount,
    },
    credit: convertAssetValue(data.buy),
    debit: convertAssetValue(data.sell),
    fee: !isNil(data.fee) ? convertAssetValue(data.fee) : undefined,
    schedules: data.schedules.map((schedule): IVestingInput => {
      if (schedule.type === IVestingType.Recurring) {
        return {
          type: IVestingType.Recurring,

          recurring: createRecurringSchedule(schedule.recurring!, schedule.vested),
        };
      }
      return {
        type: IVestingType.Immediate,

        immediate: createImmediateSchedule(schedule.immediate!, schedule.vested),
      };
    }),
  };
};
