import { Stack, type StackProps } from '@mui/joy';
import type { PropsWithChildren, ReactElement } from 'react';

const SectionColumn = (props: PropsWithChildren<Omit<StackProps, 'spacing' | 'direction'>>): ReactElement => {
  return (
    <Stack {...props} spacing={1.5}>
      {props.children}
    </Stack>
  );
};

export default SectionColumn;
