import type { FunctionComponent } from 'react';
import { Navigate } from 'react-router';

import type { PrivateRouteProps } from './PrivateRoute.props';
import { useAuth } from '../../UseAuth.tsx';

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({ to = '/', element, shouldAllow }) => {
  const { user, isImpersonating, isAdmin } = useAuth();

  if (!user) {
    return <Navigate to={to} replace />;
  }

  if (isAdmin) {
    return element;
  }

  if (
    shouldAllow?.({
      groups: user.groups,
      isImpersonating,
    })
  ) {
    return element;
  }

  return <Navigate to={to} replace />;
};

export default PrivateRoute;
