import {
  type IAssetRiskMetricsReportAssetsAndParametersQuery,
  type IMetricCalculatorInput,
  IParameterType,
  type IPortfolioRiskMetricsContributionQuery,
} from 'generated/graphql.tsx';
import type {
  PortfolioRiskMetricReportMapKey,
  PortfolioRiskMetricReportMapValue,
} from '../../../technical/grids/SharedReportColumns.tsx';
import {
  PORTFOLIO_BETA_METRIC,
  PORTFOLIO_DOWNSIDE_RISK_METRIC,
  PORTFOLIO_VOLATILITY_METRIC,
  annualized,
  benchmarkParameter,
  expected,
  rolling,
  windowParameter,
} from 'components/metrics/PortfolioMetricsData.ts';
import { logWarnOnce } from 'components/log.utils.ts';
import { ObjectKeyMap } from 'components/objectKeyMap.ts';
import type { AssetLabelInput } from 'components/market/asset/AssetLabelService.ts';
import { type BigNumber, bignumber } from 'mathjs';
import { MAX_DRAWDOWN, MAX_DRAWDOWN_1Y } from 'components/metrics/NonPortfolioMetricsData.tsx';

export type PositionRow = Omit<
  IAssetRiskMetricsReportAssetsAndParametersQuery['portfolio']['positions']['positions'][number],
  'asset'
> & {
  asset: AssetLabelInput;
};

export type MetricParameter = IAssetRiskMetricsReportAssetsAndParametersQuery['portfolio']['metricParameters'][number];

export function groupBalanceByAsset(positions: PositionRow[]): { asset: AssetLabelInput; balance: number }[] {
  const groupedMap = new Map<string, { balance: BigNumber; asset: AssetLabelInput }>();

  for (const position of positions) {
    let balance: BigNumber = groupedMap.get(position.asset.id)?.balance ?? bignumber(0);
    if (position.spot) {
      balance = balance.add(bignumber(position.spot.balance));
    }

    if (position.derivative) {
      balance = balance.add(bignumber(position.derivative.balance));
    }
    groupedMap.set(position.asset.id, { balance, asset: position.asset });
  }
  return Array.from(groupedMap.values()).map(({ balance, asset }) => ({ balance: balance.toNumber(), asset }));
}

/** parameters to request data for contribution to portfolio metrics */
export const contributionMetricRequestParams = (benchmarkIds: string[], window: number): IMetricCalculatorInput[] => [
  {
    label: PORTFOLIO_VOLATILITY_METRIC,
    parameters: [annualized, expected, rolling, windowParameter(window)],
  },

  ...benchmarkIds.map((benchmark) => ({
    label: PORTFOLIO_BETA_METRIC,
    parameters: [benchmarkParameter(benchmark), expected, rolling, windowParameter(window)],
  })),

  {
    label: PORTFOLIO_DOWNSIDE_RISK_METRIC,
    parameters: [annualized, expected, rolling, windowParameter(window)],
  },
];

/** metadata corresponding to contributionMetricParams but to select right data for the right column and correct headers */
export const contributionMetricColumns = (benchmarks: { id: string; symbol: string }[]) =>
  [
    {
      label: PORTFOLIO_VOLATILITY_METRIC,
      benchmark: undefined,
    },

    ...benchmarks.map((benchmark) => ({
      label: PORTFOLIO_BETA_METRIC,
      benchmark,
    })),

    {
      label: PORTFOLIO_DOWNSIDE_RISK_METRIC,
      benchmark: undefined,
    },
  ] as const;

export const assetMetricColumnConfigurations = {
  fundamentals: [
    { metricLabel: 'met:market_cap', initialHide: false },
    { metricLabel: 'met:total_val_locked', initialHide: false },
    { metricLabel: 'met:volume-24h', initialHide: false },
    { metricLabel: 'met:liquidity', initialHide: false },
    { metricLabel: MAX_DRAWDOWN, initialHide: false },
    { metricLabel: MAX_DRAWDOWN_1Y, initialHide: false },
  ],

  '30': [
    {
      metricLabel: 'met:rolling_volatility-30',
      initialHide: false,
    },
    { metricLabel: 'met:rolling_beta_btc-30', initialHide: false },
    { metricLabel: 'met:rolling_beta_eth-30', initialHide: false },
    { metricLabel: 'met:rolling_sharpe-30', initialHide: false },
    { metricLabel: 'met:rolling_sortino-30', initialHide: false },
    { metricLabel: 'met:rolling_var95-30', initialHide: false },
    { metricLabel: 'met:rolling_var99-30', initialHide: false },
    { metricLabel: 'met:rolling_skewness-30', initialHide: true },
    { metricLabel: 'met:rolling_kurtosis-30', initialHide: true },
  ],

  '60': [
    { metricLabel: 'met:rolling_volatility-60', initialHide: true },
    { metricLabel: 'met:rolling_beta_btc-60', initialHide: true },
    { metricLabel: 'met:rolling_beta_eth-60', initialHide: true },
    { metricLabel: 'met:rolling_sharpe-60', initialHide: true },
    { metricLabel: 'met:rolling_sortino-60', initialHide: true },
    { metricLabel: 'met:rolling_var95-60', initialHide: true },
    { metricLabel: 'met:rolling_var99-60', initialHide: true },
    { metricLabel: 'met:rolling_skewness-60', initialHide: true },
    { metricLabel: 'met:rolling_kurtosis-60', initialHide: true },
  ],

  '90': [
    { metricLabel: 'met:rolling_volatility-90', initialHide: true },
    { metricLabel: 'met:rolling_beta_btc-90', initialHide: true },
    { metricLabel: 'met:rolling_beta_eth-90', initialHide: true },
    { metricLabel: 'met:rolling_sharpe-90', initialHide: true },
    { metricLabel: 'met:rolling_sortino-90', initialHide: true },
    { metricLabel: 'met:rolling_var95-90', initialHide: true },
    { metricLabel: 'met:rolling_var99-90', initialHide: true },
    { metricLabel: 'met:rolling_skewness-90', initialHide: true },
    { metricLabel: 'met:rolling_kurtosis-90', initialHide: true },
  ],
} as const;

/** return map with compound key {assetId, window, metricLabel, benchmark (for beta or '' for the rest)} -> {value, seriesTotal} */
export function groupPortfolioMetrics(
  metricContributions: IPortfolioRiskMetricsContributionQuery['portfolio']['metricContributions']
): ObjectKeyMap<PortfolioRiskMetricReportMapKey, PortfolioRiskMetricReportMapValue> {
  const portfolioMetricValues = new ObjectKeyMap<PortfolioRiskMetricReportMapKey, PortfolioRiskMetricReportMapValue>();
  for (const metric of metricContributions) {
    for (const series of metric.series) {
      for (const assetValues of series.values) {
        const assetId = assetValues.asset.id;
        const window = series.parameters.find((param) => param.name === 'Window')?.intValue;
        if (!window) {
          logWarnOnce('Unknown window', window, 'for metric', metric.label);
          continue;
        }
        const benchmark = series.parameters.find((param) => param.name === 'Benchmark')?.strValue ?? '';

        portfolioMetricValues.set(
          {
            assetId,
            window,
            metricLabel: metric.label,
            benchmark,
          },
          {
            value: assetValues.value,
            seriesTotal: series.total,
          }
        );
      }
    }
  }
  return portfolioMetricValues;
}

export type ParametersValidationResult = { isValid: false; parameter?: string | undefined } | { isValid: true };

export function validateMetricParameters(
  metricParametersMeta: MetricParameter[],
  metricParameterValues: IMetricCalculatorInput[]
): ParametersValidationResult {
  const metadataParametersLookup = Object.fromEntries(
    metricParametersMeta.map((metric) => [
      metric.label,
      Object.fromEntries(metric.parameters.map((param) => [param.name, param])),
    ])
  );

  for (const metricParameters of metricParameterValues) {
    const metricParams = metadataParametersLookup[metricParameters.label];
    if (!metricParams) {
      logWarnOnce(`Invalid metric label: ${metricParameters.label}`);
      return { isValid: false };
    }

    for (const { name, intValues, strValues } of metricParameters.parameters) {
      const param = metricParams[name];
      const value = intValues?.[0] ?? strValues?.[0];
      if (!param) {
        logWarnOnce(`Invalid parameter name: ${name} for metric ${metricParameters.label}`);
        return { isValid: false, parameter: name };
      }

      switch (param.type) {
        case IParameterType.Options:
          if (!param.options?.some((option) => option.value === value)) {
            logWarnOnce(`Invalid value: ${value} for parameter ${name}, metric ${metricParameters.label}`);
            return { isValid: false, parameter: name };
          }
          break;
        case IParameterType.Benchmark:
          if (!param.benchmarks?.some((option) => option.id === value)) {
            logWarnOnce(`Invalid value: ${value} for parameter ${name}, metric ${metricParameters.label}`);
            return { isValid: false, parameter: name };
          }
          break;
        case IParameterType.Int:
          if (typeof value !== 'number' || value < param.minMax!.min || value > param.minMax!.max) {
            logWarnOnce(`Value: ${value} for parameter ${name} is out of bounds, metric ${metricParameters.label}`);
            return { isValid: false, parameter: name };
          }
          break;
      }
    }
  }

  return { isValid: true };
}

export const portfolioContributionMetricsBenchmarkAssetsSymbols = ['BTC', 'ETH', 'CCI30'];
