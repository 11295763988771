import { Stack, Typography } from '@mui/joy';
import type { CellContext, ColumnDef } from '@tanstack/react-table';
import { sortBy } from 'lodash/fp';
import { bignumber, isZero } from 'mathjs';
import type { FunctionComponent, ReactElement } from 'react';

import GTable from 'components/technical/GTable/GTable.tsx';
import PreciseTooltip from 'components/technical/PreciseTooltip';
import bigNumMath from '../../../bigNumMath';
import type { IBalanceByVenue } from '../../../generated/graphql';
import { formatPercentage } from '../../formatter.utils';
import { venues } from '../../venue/VenueData.tsx';
import VenueLabel from '../../venue/VenueLabel.tsx';
import { AccountType, calculateAccountType } from '../account/AccountService.tsx';

type VenueBalanceName = {
  name: string;
  balance: string;
  venue: string;
};

const VenueBalanceList: FunctionComponent<{
  balanceByVenue: IBalanceByVenue[] | undefined;
}> = ({ balanceByVenue }) => {
  if (!balanceByVenue || balanceByVenue.length === 0) {
    return <></>;
  }

  const dataWithAdditionalFields = balanceByVenue.map((row) => ({
    venue: row.venue,
    balance: row.balance,
    name:
      calculateAccountType({ venue: { label: row.venue } }) === AccountType.Virtual ? row.name : venues[row.venue].name,
  }));

  const sortedData = sortBy((row) => bignumber(row.balance).toNumber(), dataWithAdditionalFields).reverse();

  const totalBalance = bigNumMath.sum(sortedData.map((row) => bignumber(row.balance)));

  const columns: ColumnDef<VenueBalanceName>[] = [
    {
      header: 'Venue',
      cell: (props: CellContext<VenueBalanceName, unknown>): ReactElement => {
        const row = props.row.original;
        return (
          <Typography level="body-sm" noWrap>
            <Stack direction="row" spacing={0.5} flexWrap="wrap" alignItems="center">
              <VenueLabel accountName={row.name} venue={row.venue} format="short" />
              {row.name}
            </Stack>
          </Typography>
        );
      },
      accessorFn: (pos) => pos.name,
    },
    {
      header: 'Balance',
      cell: (props: CellContext<VenueBalanceName, unknown>): ReactElement => {
        const balance = props.row.original.balance;
        return <PreciseTooltip format="cash" value={balance} />;
      },
      accessorFn: (pos) => bignumber(pos.balance).toNumber(),
    },
    {
      header: 'Balance %',
      meta: {
        headerTooltip: 'Contribution to balance',
      },
      cell: (props: CellContext<VenueBalanceName, unknown>): ReactElement => {
        const balance = props.row.original.balance;
        const balanceContribution = isZero(totalBalance) ? 0 : bignumber(balance).div(totalBalance).toNumber();

        return <Typography>{formatPercentage(balanceContribution)}</Typography>;
      },
      accessorFn: (pos) => bignumber(pos.balance).toNumber(),
    },
  ];

  return <GTable columns={columns} data={sortedData} disablePagination />;
};

export default VenueBalanceList;
