import type { ReactElement } from 'react';
import { IUserPortfolioDefinitionType, type IPortfolioDefinitionSubType } from '../../../../../generated/graphql.tsx';
import CreatePortfolioDialog from '../create/CreatePortfolioDialog.tsx';
import type { FormOutputFields } from '../create/CreatePortfolio.validation.ts';

const CreateRebalancedPortfolioDialog = (props: {
  onClose: () => void;
  onAdded: (defId: string) => void;
  composition: { id: string; weight: number }[];
  subType: IPortfolioDefinitionSubType;
}): ReactElement => {
  return (
    <CreatePortfolioDialog
      onClose={props.onClose}
      onAdded={props.onAdded}
      createInput={(data: FormOutputFields) => {
        return {
          name: data.name,
          description: data.description,
          type: IUserPortfolioDefinitionType.Rebalanced,
          subType: props.subType,
          composition: props.composition,
        };
      }}
    />
  );
};

export default CreateRebalancedPortfolioDialog;
