import { Card, Stack, Typography as JoyTypography, Typography } from '@mui/joy';
import type { ReactElement } from 'react';
import { Header4 } from 'components/technical/Header4';
import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import { KeyValueItem } from 'components/technical/KeyValueItem';
import KeyValueListSkeleton from 'components/technical/KeyValueListSkeleton';
import SectionColumn from 'components/technical/layout/Column/SectionColumn';
import GValueChange from 'components/technical/ValueChange/GValueChange';
import type { IPerformStressTestQuery } from '../../../../generated/graphql';
import { formatCash, formatPercentage } from '../../../formatter.utils';

const StressTestPortfolioPerformance = ({
  result,
}: {
  result: IPerformStressTestQuery['stressTest']['stressTest'] | undefined;
}): ReactElement => {
  const measures = result?.keyMeasures.measures;
  const worstAsset = measures?.find((measure) => measure.name === 'WORST_ASSET');
  const balance = measures?.find((measure) => measure.name === 'BALANCE');
  return (
    <SectionColumn>
      <HeaderBar title="Predicted portfolio performance" />
      <Card>
        <Header4 title="Expected portfolio value" />
        {result && balance ? (
          <>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography level={'h3'}>{formatCash(balance.value)}</Typography>
              <GValueChange value={balance.netChange} text={formatPercentage(balance.netChange)} />
            </Stack>
            <JoyTypography color="neutral" level="body-md">
              Expected portfolio value if the shock happens
            </JoyTypography>
          </>
        ) : (
          <KeyValueListSkeleton />
        )}
      </Card>
      <Card>
        {result ? (
          <Stack spacing={1}>
            {worstAsset?.asset && (
              <>
                <KeyValueItem label="Worst performing asset" value={worstAsset.asset.symbol} />
                <KeyValueItem
                  label="Worst performing asset change"
                  value={<GValueChange value={worstAsset.netChange} text={formatPercentage(worstAsset.netChange)} />}
                />
              </>
            )}
          </Stack>
        ) : (
          <KeyValueListSkeleton />
        )}
      </Card>
    </SectionColumn>
  );
};

export default StressTestPortfolioPerformance;
