import { TabPanel } from '@mui/joy';
import type { ReactElement } from 'react';
import { useParams } from 'react-router';

import { errorName, ErrorType } from './ErrorTypes.ts';
import ReconciliationReportContainer from '../../bookkeeping/reconciliation/ReconciliationReport.tsx';
import JournalErrorList from '../../portfolio/error/JournalErrorList.tsx';
import Message from '../Message.tsx';
import NavigableTab from '../NavigableTabs/NavigableTab.tsx';
import NavigableTabs from '../NavigableTabs/NavigableTabs.tsx';

export const url = '/app/errors';
const ErrorDashboard = (): ReactElement => {
  const { error } = useParams();

  if (!Object.values(ErrorType).includes(error as ErrorType)) {
    return <Message>Unknown error type {error}</Message>;
  }

  return (
    <NavigableTabs
      panels={
        <>
          <TabPanel value={`${url}/${ErrorType.JOURNAL}`}>
            <JournalErrorList />
          </TabPanel>
          <TabPanel value={`${url}/${ErrorType.RECONCILIATION}`}>
            <ReconciliationReportContainer />
          </TabPanel>
        </>
      }
    >
      {Object.values(ErrorType).map((errorType) => (
        <NavigableTab key={errorType} link={`${url}/${errorType}`} text={errorName[errorType]} />
      ))}
    </NavigableTabs>
  );
};

export default ErrorDashboard;
