import type { FunctionComponent } from 'react';
import { ITransactionStatus } from '../../../generated/graphql';
import StatusChip from 'components/technical/StatusChip';
import { type Icon, IconCheck, IconClock, IconX } from '@tabler/icons-react';
import type { DefaultColorPalette } from '@mui/joy/styles/types/colorSystem';
import { formatEnum } from '../../formatter.utils.ts';

const TransactionStatusChip: FunctionComponent<{
  status: ITransactionStatus;
}> = ({ status }) => {
  const iconMapping: Record<ITransactionStatus, Icon> = {
    [ITransactionStatus.Unknown]: IconCheck,
    [ITransactionStatus.Failed]: IconX,
    [ITransactionStatus.Succeeded]: IconCheck,
    [ITransactionStatus.Canceled]: IconX,
    [ITransactionStatus.Pending]: IconClock,
    [ITransactionStatus.Unsupported]: IconX,
  };

  const colorMapping: Record<ITransactionStatus, DefaultColorPalette> = {
    [ITransactionStatus.Unknown]: 'success',
    [ITransactionStatus.Failed]: 'danger',
    [ITransactionStatus.Succeeded]: 'success',
    [ITransactionStatus.Canceled]: 'neutral',
    [ITransactionStatus.Pending]: 'warning',
    [ITransactionStatus.Unsupported]: 'danger',
  };

  return (
    <StatusChip color={colorMapping[status]} icon={iconMapping[status]} width={'8rem'} size={'lg'}>
      {formatEnum(status)}
    </StatusChip>
  );
};

export default TransactionStatusChip;
