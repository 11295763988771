import isArray from 'lodash/fp/isArray';
import type { ReactElement, ReactNode } from 'react';

import ExternalLink from 'components/technical/ExternalLink/ExternalLink';
import Help from 'components/technical/Help/Help';

const links = {
  messari: (
    <ExternalLink variant="tooltip" to="https://messari.io/">
      Messari.io
    </ExternalLink>
  ),
  coinmetrics: (
    <ExternalLink variant="tooltip" to="https://coinmetrics.io/">
      Coinmetrics
    </ExternalLink>
  ),
  livecoin: (
    <ExternalLink variant="tooltip" to="https://www.livecoinwatch.com/">
      Live Coin
    </ExternalLink>
  ),
  proprietary: 'proprietary',
  defillama: (
    <ExternalLink variant="tooltip" to="https://defillama.com/">
      Defi Llama
    </ExternalLink>
  ),
};

type DataSource = keyof typeof links;

const getLinks = (source: DataSource | DataSource[]): ReactNode => {
  const children = isArray(source) ? source : [source];
  const joinedChildren = [];
  for (let i = 0; i < children.length; i++) {
    if (i !== 0) {
      joinedChildren.push(<span key={`sep-${i}`}> and </span>);
    }

    joinedChildren.push(<span key={i}>{links[children[i]]}</span>);
  }

  return <>{joinedChildren}</>;
};

const SourceHelp = ({
  children,
  source,
  agg = false,
}: {
  children: ReactNode;
  source: DataSource | DataSource[];
  agg?: boolean;
}): ReactElement => (
  <Help>
    {children}
    <br />
    Source: {agg && 'Aggregation of '}
    {getLinks(source)}
    {agg && ' data '}
  </Help>
);

export default SourceHelp;
