import isNil from 'lodash/fp/isNil';
import { bignumber } from 'mathjs';

export const getRelativeChange = (currentValue: number, pastValue: number): number => {
  if (bignumber(pastValue).isZero()) {
    return currentValue;
  }

  return bignumber(currentValue).sub(bignumber(pastValue)).div(pastValue).toNumber();
};

export const getRelativeValuesChange = (rows: (string | undefined | null)[]): (number | undefined)[] => {
  const result: (number | undefined)[] = [];
  let previousValue: undefined | number = undefined;
  for (const row of rows) {
    if (isNil(row)) {
      previousValue = undefined;
      result.push(undefined);
      continue;
    }

    if (isNil(previousValue)) {
      previousValue = bignumber(row).toNumber();
      result.push(undefined);
      continue;
    }

    result.push(getRelativeChange(bignumber(row).toNumber(), previousValue));
    previousValue = bignumber(row).toNumber();
  }

  return result;
};
