import { ApolloError } from '@apollo/client';
import type { ServerParseError } from '@apollo/client/link/http';
import type { ServerError } from '@apollo/client/link/utils';
import isNil from 'lodash/fp/isNil';

const TIMEOUT_CODE = 'timeout';
const errorMessages = {
  account_invalid_api_keys: 'Incorrect api keys',
  sub_account_duplicate_name: 'Sub-account with the same name already exists',
  account_duplicate_name: 'Account with the same name already exists',
  portfolio_service_unavailable: 'Portfolio service unavailable, try again later',
  [TIMEOUT_CODE]: 'Timeout occurred, try again later',
} as const;
export const UNKNOWN_ERROR_MESSAGE = 'Unknown error';
const isServerError = (
  error: Error | ServerParseError | ServerError | null
): error is ServerError | ServerParseError => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return !isNil(error?.statusCode);
};
export const getErrorDetails = (
  exception: unknown
): undefined | { code: string; message: string | undefined | string[] } => {
  if (!(exception instanceof ApolloError)) {
    return undefined;
  }

  if (isServerError(exception.networkError) && exception.networkError.statusCode === 504) {
    return {
      code: TIMEOUT_CODE,
      message: errorMessages[TIMEOUT_CODE],
    };
  }

  const graphQLErrors = exception.graphQLErrors;

  if (!graphQLErrors || graphQLErrors.length === 0) {
    return undefined;
  }

  const firstError = graphQLErrors[0];
  const code = firstError.extensions?.code as string | undefined;
  if (!code) {
    return undefined;
  }

  return {
    code,
    message: firstError.extensions?.message as string | undefined | string[],
  };
};
export const calculateErrorMessage = (exception: unknown): string | string[] => {
  const details = getErrorDetails(exception);
  if (!details) {
    return UNKNOWN_ERROR_MESSAGE;
  }

  const extensionMessage = details.message;
  const code = details.code;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const knownMessage = errorMessages[code ?? ''] ?? extensionMessage;
  if (knownMessage) {
    return knownMessage;
  }

  return UNKNOWN_ERROR_MESSAGE;
};
