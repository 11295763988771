import { Add } from '@mui/icons-material';
import { Card, Stack, Typography } from '@mui/joy';
import dayjs from 'dayjs';
import isNil from 'lodash/fp/isNil';
import { bignumber } from 'mathjs';
import type { ReactElement } from 'react';
import { formatCash } from 'components/formatter.utils';
import {
  cashFormatter,
  dateTimeAxisFormat,
  dateTimeExportFormat,
  type HighchartSeries,
  noYAxisTitle,
  tooltipFormat,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import HighChartsContainer from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper';
import { defaultHeaderActionProps } from 'components/technical/HeaderBar/DefaultHeaderActionProps.ts';
import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import DialogButton from 'components/technical/inputs/GButton/DialogButton';
import SectionColumn from 'components/technical/layout/Column/SectionColumn';

import { CreateAssetPriceDialog } from './CreateAssetPriceDialog';
import { PrivateAssetPriceList } from './PrivateAssetPriceList';
import { type IAssetPriceChartQuery, IAssetType, useAssetPriceChartQuery } from '../../../../../../generated/graphql';
import type { PrivateAssetWithPrivateDetails } from '../../../Asset.types';
import type Highcharts from 'highcharts';

const calculateChartData = (data: IAssetPriceChartQuery): HighchartSeries[] => {
  return data.assets.price.map((assetPrice) => ({
    name: assetPrice.asset.name ?? assetPrice.asset.symbol,
    data: assetPrice.rows.map((row) => ({
      x: dayjs.utc(row.date.toString()).valueOf(),
      y: bignumber(row.price).toNumber(),
      textValue: formatCash(bignumber(row.price).toNumber()),
    })),
    type: 'line',
  }));
};

const calculateOptions = (): Highcharts.Options => {
  return {
    ...dateTimeAxisFormat,
    ...dateTimeExportFormat('private-asset-price'),
    ...tooltipFormat,
    ...noYAxisTitle,
    yAxis: {
      labels: {
        formatter: cashFormatter,
      },
    },
    plotOptions: {
      series: {
        step: 'left', // price is used until there is a new one
      },
    },
  };
};

export const PrivateAssetPriceSection = ({
  asset,
  quoteAsset,
}: {
  asset: PrivateAssetWithPrivateDetails & {
    priceAsset: { id: string; symbol: string };
  };
  quoteAsset: { id: string };
}): ReactElement => {
  const assets = [asset, asset.type === IAssetType.Private ? asset.privateDetails?.unvestedAsset : null].filter(
    (
      asset
    ): asset is PrivateAssetWithPrivateDetails & {
      priceAsset: { id: string; symbol: string };
    } => !isNil(asset)
  );
  const assetIds = assets.map((asset) => asset.id);
  const queryOutput = useAssetPriceChartQuery({
    variables: {
      assetIds: assetIds,
    },
  });

  const hasLinkedPriceAsset = asset.priceAsset.id !== asset.id;

  return (
    <SectionColumn>
      <HeaderBar title="Price">
        {!hasLinkedPriceAsset && (
          <DialogButton
            {...defaultHeaderActionProps}
            renderDialog={({ onClose }): ReactElement => (
              <CreateAssetPriceDialog
                onClose={onClose}
                onAdded={onClose}
                availableAssets={assets}
                quoteAsset={quoteAsset}
              />
            )}
            startDecorator={<Add />}
          >
            Add price
          </DialogButton>
        )}
      </HeaderBar>

      <Stack spacing={1.5}>
        <Card>
          {hasLinkedPriceAsset && (
            <Typography level="body-sm">Asset price is linked to asset {asset.priceAsset.symbol}</Typography>
          )}
          <HighChartsContainer<IAssetPriceChartQuery>
            {...queryOutput}
            calculateOptions={calculateOptions}
            calculateChartData={calculateChartData}
          />
        </Card>
        {queryOutput.data && !hasLinkedPriceAsset && (
          <PrivateAssetPriceList prices={queryOutput.data.assets.price} quoteAsset={quoteAsset} />
        )}
      </Stack>
    </SectionColumn>
  );
};
