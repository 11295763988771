import { Add } from '@mui/icons-material';
import { Modal, ModalDialog, ModalOverflow } from '@mui/joy';
import type { ReactElement } from 'react';
import { now } from 'components/date.utils.ts';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';

import type { GraphQlErrorHandler } from 'components/technical/form/UseGraphQLApiError.tsx';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import { useUserTimezone } from 'components/technical/UseUserTimezone.tsx';
import TransactionForm from './TransactionForm';
import type { FormOutputFields } from './TransactionForm.validation';
import { createTransactionOutput, transactionDialogProps } from './TransactionCreateService.ts';
import {
  ISlotType,
  ITransactionLegType,
  ITransactionStatus,
  IUserTransactionType,
  TransactionFilterInputDocument,
  TransactionsDocument,
  useCreateTransactionMutation,
} from '../../../../generated/graphql';
import type { CreateSubAccountIdAutocompleteOptionsInputAccount } from '../../../portfolio/account/AccountService.tsx';
import type { FormInputType } from '../../../technical/form/Form.types.ts';
import type { SubAccountLabelInputAccount } from '../../../portfolio/account/SubAccountLabel.tsx';

const getDefaultSubAccount = (
  accounts: CreateSubAccountIdAutocompleteOptionsInputAccount[]
): SubAccountLabelInputAccount | null => {
  if (accounts.length === 0 || accounts.length > 1 || accounts[0].subAccounts.length !== 1) {
    return null;
  }

  const firstAccount = accounts[0];
  return {
    ...firstAccount.subAccounts[0],
    account: firstAccount,
  };
};

const CreateTransactionDialog = (props: {
  onClose: () => void;
  onAdded: () => void;
  accounts: CreateSubAccountIdAutocompleteOptionsInputAccount[];
  existingTags: string[];
}): ReactElement => {
  const timezone = useUserTimezone();

  const defaultValues: FormInputType<FormOutputFields> = {
    attributedToAsset: null,
    comment: '',
    externalId: '',
    externalType: '',
    legs: [
      {
        asset: null,
        amount: null,
        marketValue: null,
        time: null,
        side: null,
        type: ITransactionLegType.Any,
        slot: ISlotType.Free,
      },
    ],
    order: {
      side: null,
      type: null,
      externalId: '',
    },
    tags: [],
    time: now(timezone).startOf('day'),
    status: ITransactionStatus.Succeeded,
    subAccount: getDefaultSubAccount(props.accounts),
    userType: IUserTransactionType.Trade,
  };
  const [createTransaction] = useCreateTransactionMutation({
    refetchQueries: [TransactionsDocument, TransactionFilterInputDocument],
  });

  const { showSuccessMessage } = useFeedback();

  const handleFormSubmit = async (data: FormOutputFields, onErrorAndThrow: GraphQlErrorHandler): Promise<void> => {
    try {
      await createTransaction({
        variables: {
          input: createTransactionOutput(data),
        },
      });

      showSuccessMessage('New transaction successfully added');
      props.onAdded();
    } catch (e) {
      onErrorAndThrow(e);
    }
  };

  return (
    <Modal open={true} onClose={props.onClose}>
      <ModalOverflow>
        <ModalDialog {...transactionDialogProps}>
          <GDialogHeader>Add new transaction</GDialogHeader>
          <TransactionForm
            {...props}
            onSubmit={handleFormSubmit}
            defaultValues={defaultValues}
            accounts={props.accounts}
            disableSubAccount={false}
            submitButtonIcon={<Add />}
            submitButtonText="Add transaction"
          />
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default CreateTransactionDialog;
