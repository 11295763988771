import { Stack } from '@mui/joy';
import { type ReactElement, useState } from 'react';
import { Select } from 'components/technical/inputs';

import { metricValues } from './Correlation.utils';
import HistoricalCorrelationSection from './HistoricalCorrelationSection';
import LatestCorrelationSection from './LatestCorrelationSection';
import type { PublicAsset } from '../Asset.types';

const CorrelationContainer = ({
  defaultAssets,
  supportedAssets,
}: {
  defaultAssets: PublicAsset[];
  supportedAssets: PublicAsset[];
}): ReactElement => {
  const [metric, setMetric] = useState(metricValues[0].value);

  return (
    <Stack spacing={1.5}>
      <Select
        disableClearable
        label="Metric"
        value={metric}
        onChange={(value): void => setMetric(value)}
        options={metricValues}
        width="normal"
      />

      <LatestCorrelationSection metric={metric} supportedAssets={supportedAssets} defaultAssets={defaultAssets} />
      <HistoricalCorrelationSection metric={metric} supportedAssets={supportedAssets} defaultAssets={defaultAssets} />
    </Stack>
  );
};

export default CorrelationContainer;
