import { type ReactNode, useCallback, useContext } from 'react';

import { Context } from './Context.tsx';

type RegisterActionsOutput = {
  deregister: () => void;
};

export const useRegisterActions = (): ((actions: ReactNode) => RegisterActionsOutput) => {
  const { setActions } = useContext(Context);

  return useCallback(
    (node: ReactNode) => {
      setActions(node);
      return {
        deregister: () => setActions(undefined),
      };
    },
    [setActions]
  );
};
