import ObjectivesStep from './ObjectivesStep.tsx';
import type { ObjectivesStepInput } from './ObjectivesStep.validation.ts';
import type { StepConfig } from '../../../../technical/wizard/StepConfig.ts';
import type { AssetLabelInput } from '../../../../market/asset/AssetLabelService.ts';

export const config = (
  benchmarks: AssetLabelInput[],
  showLongShortSelector: boolean,
  goToNextStep: () => void
): StepConfig<ObjectivesStepInput> => ({
  label: 'Objectives',
  element: (
    <ObjectivesStep goToNextStep={goToNextStep} benchmarks={benchmarks} showLongShortSelector={showLongShortSelector} />
  ),
  fields: ['portfolioAmount', 'allowShortAndLeverage', 'objectives', 'objectiveLength'],
});
