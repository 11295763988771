import { Grid, Stack } from '@mui/joy';
import type { ReactElement } from 'react';
import Message from 'components/technical/Message';
import NavigableTab from 'components/technical/NavigableTabs/NavigableTab';
import NavigableTabs from 'components/technical/NavigableTabs/NavigableTabs';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';

import PublicAssetPrice from './PublicAssetPriceTile/PublicAssetPrice';
import { useSinglePublicAssetQuery } from '../../../../../generated/graphql';
import type { Asset, PublicAsset } from '../../Asset.types';
import { createAssetToClusters } from '../../groups/GroupService.ts';
import { useOptionsBaseAssets } from '../../useOptionsBaseAssetId';
import AssetDetailsView from '../AssetDetails/AssetDetails';
import BigTile from '../BigTile/BigTile';
import { SingleAssetTiles } from '../SingleAssetTiles';

const OPTIONS = 'Options';

const singleViewTabs = [
  { name: 'Spot', link: 'spot' },
  { name: 'Holdings', link: 'holdings' },
  { name: OPTIONS, link: 'options' },
  { name: 'Derivatives', link: 'derivatives' },
];

const createLink = (asset: Asset, tab: { link: string }): string => `/app/market/assets/${asset.id}/${tab.link}`;

const SinglePublicAsset = ({ asset }: { asset: PublicAsset }): ReactElement => {
  const { loaded, Fallback, data } = useDefaultErrorHandling(
    useSinglePublicAssetQuery({
      variables: {
        asset: asset.id,
      },
    })
  );

  const optionsBaseAssetQuery = useOptionsBaseAssets();

  if (!loaded) {
    return <Fallback />;
  }

  if (!optionsBaseAssetQuery.loaded) {
    return <optionsBaseAssetQuery.Fallback />;
  }

  const assetDetails = data.assets.details.find((assetDetails) => assetDetails.asset === asset.id);
  if (!assetDetails) {
    return <Message>Couldn&apos;t find asset</Message>;
  }

  const assetChange = data.assets.changes.find((changes) => changes.asset === asset.id) ?? {
    asset: asset.label,
    metrics: {},
    price: null,
  };

  const assetToClusters = createAssetToClusters(data.assets.assetGroups.genieGroups);
  const clusterToGroup = assetToClusters[asset.id] ?? {};

  return (
    <Stack spacing={1.5}>
      <Grid container spacing={1.5}>
        <Grid xs={12} lg={6}>
          <AssetDetailsView asset={asset} clusterToGroup={clusterToGroup} />
        </Grid>
        <Grid xs={12} lg={6}>
          {assetDetails.price ? (
            <PublicAssetPrice
              asset={{ ...asset, name: asset.name ?? asset.symbol }}
              price={assetDetails.price}
              minPrice={assetDetails.minPrice}
              maxPrice={assetDetails.maxPrice}
              priceChange={assetChange.price ?? null}
            />
          ) : (
            <BigTile />
          )}
        </Grid>
      </Grid>
      <SingleAssetTiles asset={assetDetails} assetChange={assetChange} />
      <NavigableTabs>
        {singleViewTabs.map((tab) => {
          const isAssetInOptionsBaseAssets = optionsBaseAssetQuery.data.some(
            (optionAsset) => optionAsset.symbol === asset.symbol
          );
          if (tab.name === OPTIONS && !isAssetInOptionsBaseAssets) {
            return;
          }

          return <NavigableTab link={createLink(asset, tab)} key={tab.link} text={tab.name} />;
        })}
      </NavigableTabs>
    </Stack>
  );
};

export default SinglePublicAsset;
