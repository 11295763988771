import type { BigNumber } from 'mathjs';

import type { SunburstChartData } from 'components/technical/charts/SunburstChart/SunburstChart.props';
import { calculateChartInput, type ElementChild } from 'components/technical/charts/SunburstChart/SunburstChart.utils';

export type Subgroup<Element> = {
  name: string;
  id: string;
  color: string;
  elementColor: string;
  elements: Element[];
};

export type Group<Element> = {
  subgroups: Subgroup<Element>[];
};

export const calculateGroupChartInput = <ELEMENT, TEXT = string>({
  root,
  valueProvider,
  textProvider,
  labelProvider,
  cap = undefined,
  rootValue,
}: {
  root: Group<ELEMENT>;
  valueProvider: (row: ELEMENT, cap?: BigNumber) => BigNumber;
  textProvider: (value: number) => TEXT;
  labelProvider: (row: ELEMENT) => string;
  cap?: BigNumber;
  rootValue: BigNumber;
}): Omit<SunburstChartData<TEXT>, 'hoverinfo' | 'textinfo'> => {
  return calculateChartInput({
    root: {
      label: 'Total',
      color: 'transparent',
      elements: root.subgroups.map((sub) => ({
        color: sub.color,
        label: sub.name,
        elements: sub.elements.map((el) => ({
          label: labelProvider(el),
          color: sub.elementColor,
          value: el,
          elements: [],
        })),
      })),
    },
    valueProvider: (el: ElementChild & { value: ELEMENT }, cap) => valueProvider(el.value, cap),
    textProvider,
    cap,
    rootValue: rootValue,
  });
};
