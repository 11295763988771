import { DndContext, type DragEndEvent, type DraggableAttributes, type UniqueIdentifier } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { SortableContext } from '@dnd-kit/sortable';
import React, { type ReactElement } from 'react';

import DraggableListItem from './DraggableListItem';

interface DraggableListProps<ITEM> {
  items: ITEM[];
  renderItem: (
    item: ITEM,
    draggableAttrs: DraggableAttributes,
    listeners: SyntheticListenerMap | undefined
  ) => ReactElement;
  onDragEnd: (e: DragEndEvent) => void;
  dragHandle: boolean;
}

// eslint-disable-next-line react-refresh/only-export-components
const DraggableList = <
  ITEM extends {
    id: UniqueIdentifier;
  },
>({
  items,
  renderItem,
  onDragEnd,
  dragHandle,
}: DraggableListProps<ITEM>): ReactElement => {
  return (
    <DndContext
      onDragEnd={(e: DragEndEvent): void => {
        onDragEnd(e);
      }}
    >
      <SortableContext items={items}>
        <div>
          {/* so that in markup the list is a single element */}
          {items.map((item) => (
            <DraggableListItem item={item} renderItem={renderItem} key={item.id} dragHandle={dragHandle} />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default React.memo(DraggableList) as typeof DraggableList;
