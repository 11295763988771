import { Card, Stack } from '@mui/joy';
import dayjs, { type Dayjs } from 'dayjs';
import { capitalize } from 'lodash/fp';
import { bignumber } from 'mathjs';
import { type ReactElement, useEffect, useRef, useState } from 'react';
import { formatCash, formatPercentage } from 'components/formatter.utils';
import { getDefaultRange } from 'components/predefinedDateRanges';
import ActionsHeaderBar from 'components/technical/actions/ActionsHeaderBar';
import { useRegisterActions } from 'components/technical/actions/UseRegisterActions';
import {
  cashFormatter,
  dateTimeAxisFormat,
  dateTimeExportFormat,
  type HighChartRef,
  type HighchartSeries,
  percentageFormatter,
  tooltipFormat,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import HighChartsContainer from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper';
import { HeaderActions } from 'components/technical/HeaderBar/HeaderBar';
import PredefinedDateRangeButtonsWithCalendar from 'components/technical/inputs/date/PredefinedDateRangeButtonsWithCalendar';
import GRadioGroup from 'components/technical/inputs/GRadioGroup/GRadioGroup';
import { useSubAccountAssetFilters } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters';

import { type IAllocation, type IPortfolioExposureQuery, usePortfolioExposureQuery } from '../../../generated/graphql';
import { convertDateRangeToSinceToDate } from 'components/technical/inputs/date/dateRange.utils';
import type Highcharts from 'highcharts';

type ExposureDataTypes = 'dollars' | 'percentages';

type Option = {
  value: ExposureDataTypes;
  label: string;
  key: string;
};

const radioOptions: Option[] = [
  { value: 'dollars', label: 'Dollars', key: 'dollars' },
  { value: 'percentages', label: 'Percentages', key: 'percentages' },
];

export const CHART_TITLE = 'Portfolio exposure';
const exposureSummaryFields: (keyof IAllocation)[] = ['cash', 'long', 'short', 'net', 'gross'];

const calculateChartData = (data: IPortfolioExposureQuery, dataType: ExposureDataTypes): HighchartSeries[] => {
  return exposureSummaryFields.map((field) => ({
    name: capitalize(field),
    data: data.portfolio.snapshot
      .map((snapshot) => ({
        date: snapshot.date,
        exposure: snapshot.summary.exposure,
        balance: snapshot.summary.balance,
      }))
      .map(({ exposure, balance, date }) => {
        const yValue =
          dataType === 'dollars'
            ? bignumber(exposure[field]).toNumber()
            : bignumber(balance.total).isZero()
              ? null
              : bignumber(exposure[field]).div(balance.total).toNumber();

        return {
          x: dayjs.utc(date.toString()).valueOf(),
          y: yValue,
          textValue: dataType === 'dollars' ? formatCash(yValue) : formatPercentage(yValue),
        };
      }),
    type: 'line',
  }));
};

const calculateOptions = (dataType: ExposureDataTypes): Highcharts.Options => {
  return {
    ...dateTimeAxisFormat,
    ...dateTimeExportFormat(`portfolio-exposure-${dataType === 'dollars' ? 'dollars' : 'percentages'}`),
    ...tooltipFormat,
    yAxis: {
      labels: {
        enabled: true,
        formatter: dataType === 'dollars' ? cashFormatter : percentageFormatter,
      },
      title: {
        text: dataType === 'dollars' ? 'Dollars' : 'Percentages',
      },
    },
    plotOptions: {
      series: {
        marker: {
          symbol: 'circle',
        },
      },
    },
  };
};

const ExposureChart = (): ReactElement => {
  const defaultDateRange = getDefaultRange();
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(defaultDateRange.value);
  const { subAccountAssetFilters } = useSubAccountAssetFilters();

  const queryOutput = usePortfolioExposureQuery({
    variables: {
      ...convertDateRangeToSinceToDate(dateRange),
      subAccountAssetFilters,
    },
  });

  const registerActions = useRegisterActions();

  const [dataType, setDataType] = useState<ExposureDataTypes>('dollars');
  const chartRef = useRef<null | HighChartRef>(null);

  useEffect(() => {
    return registerActions(
      <HeaderActions>
        <GRadioGroup
          width="minContent"
          value={dataType}
          onChange={(selectedDataType): void => {
            setDataType(selectedDataType);
            chartRef?.current?.chart.zoomOut();
          }}
          options={radioOptions}
        />
      </HeaderActions>
    ).deregister;
  }, [registerActions, dataType]);

  return (
    <Stack spacing={1.5}>
      <ActionsHeaderBar title={CHART_TITLE} />
      <Card>
        <Stack spacing={1.5}>
          <PredefinedDateRangeButtonsWithCalendar
            defaultValue={defaultDateRange}
            onChange={(val): void => setDateRange(val)}
          />
          <HighChartsContainer<IPortfolioExposureQuery>
            ref={chartRef}
            {...queryOutput}
            calculateOptions={(): Highcharts.Options => calculateOptions(dataType)}
            calculateChartData={(data): HighchartSeries[] => calculateChartData(data, dataType)}
          />
        </Stack>
      </Card>
    </Stack>
  );
};

export default ExposureChart;
