import { DialogTitle } from '@mui/joy';
import ModalClose from '@mui/joy/ModalClose';
import type { ReactElement, ReactNode } from 'react';

const GDialogHeader = ({ children }: { children: ReactNode }): ReactElement => (
  <>
    <ModalClose />
    <DialogTitle>{children}</DialogTitle>
  </>
);

export default GDialogHeader;
