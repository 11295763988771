import type { ReportPreset } from 'components/technical/grids/GAgGridPresets.types';

// default presets were generated via "See all" -> "Copy current report state as JSON"
export const realizedPnlDefaultPresets: ReportPreset[] = [
  {
    name: 'Default',
    isDefault: true,
    settings: {
      filters: {},
      pivotMode: false,
      columns: [
        {
          colId: 'symbol',
          width: 144,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'underlyingAsset',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'subAccount.account.name',
          width: 183,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'subAccount.name',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'venue',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'date-date',
          width: 103,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'date-day',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'date-month',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'date-year',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'total-win',
          width: 113,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'total-loss',
          width: 111,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'net',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'n-wins',
          width: 113,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: 'sum',
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'n-losses',
          width: 113,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: 'sum',
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'total-volume',
          width: 177,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'total-volume-usd',
          width: 170,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'win-percentage',
          width: 153,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'win-loss-ratio',
          width: 156,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'fee',
          width: 113,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'fee-volume',
          width: 120,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
      ],
    },
  },
];
