import { Typography } from '@mui/joy';
import type { ReactElement } from 'react';
import { formatterForName } from 'components/formatter.utils';
import { getFormat, getName } from 'components/metrics/MetricsData';

import { type PortfolioConstraints, formatPortfolioConstraintBound } from './OptimizerInputForResult.utils.ts';

function PrimaryPortfolioConstraint({
  constraint,
  quantityLabel,
}: {
  constraint: NonNullable<PortfolioConstraints['primary']>;
  quantityLabel: string;
}): ReactElement {
  let riskMeatricName = '';
  let formatter = formatterForName('percentage');
  if (constraint.riskMetric) {
    riskMeatricName = getName(constraint.riskMetric.metricLabel, {
      assetSymbol: constraint.riskMetric?.benchmark?.symbol,
    });
    formatter = formatterForName(getFormat(constraint.riskMetric.metricLabel));
  }
  return (
    <Typography>
      {quantityLabel} {riskMeatricName} {formatPortfolioConstraintBound(constraint.constraint, formatter)}
    </Typography>
  );
}

export default PrimaryPortfolioConstraint;
