import isNil from 'lodash/fp/isNil';
import * as yup from 'yup';

import type { FormInputType } from 'components/technical/form/Form.types.ts';
import type { SelectOption } from 'components/technical/inputs/Select/Select.props.ts';
import {
  createPrimaryConstraintSchema,
  type PortfolioPrimaryConstraintOutput,
} from './PortfolioPrimaryConstraint.validation.ts';
import {
  createSecondaryConstraintSchema,
  type PortfolioSecondaryConstraintOutput,
} from './PortfolioSecondaryConstraint.validation.ts';
import { yupWhen } from '../../../../../validation.ts';
import type { AssetLabelInput } from '../../../../market/asset/AssetLabelService.ts';
import { ConstraintType, constraintTypeValues } from '../ConstraintTypeValues.validation.ts';
import type { ISecondaryConstrainedQuantity } from '../../../../../generated/graphql.tsx';

export interface PortfolioConstraintsStepOutput {
  portfolioConstraints: {
    primaryConstraint: PortfolioPrimaryConstraintOutput | null;
    secondaryConstraint: PortfolioSecondaryConstraintOutput | null;
    duplicatedAsset?: unknown;
  };
}

export type PortfolioLevelConstraintsStepInput = FormInputType<PortfolioConstraintsStepOutput>;

export const createSchema = (secondaryConstraints: SelectOption<ISecondaryConstrainedQuantity>[]): yup.Schema =>
  yup.object({
    portfolioConstraints: yupWhen(['allowShortAndLeverage'], ([allowShortAndLeverage]) => {
      return yup
        .object({
          primaryConstraint: createPrimaryConstraintSchema(allowShortAndLeverage),
          secondaryConstraint: createSecondaryConstraintSchema(allowShortAndLeverage, secondaryConstraints),
          duplicatedAsset: yupWhen(['primaryConstraint', 'secondaryConstraint'], ([primary, secondary]) => {
            const isDuplicated = isBenchmarksDuplicated(primary?.riskMetric?.benchmark, secondary?.benchmark);
            return yup.mixed().test('uniqueBenchmark', 'Benchmark is duplicated', (): boolean => {
              return !isDuplicated;
            });
          }),
        })
        .required()
        .nullable();
    }).required(),
  });

export const isBenchmarksDuplicated = (
  primaryConstraintBenchmark: AssetLabelInput,
  secondaryConstraintBenchmark: AssetLabelInput
): boolean => {
  if (isNil(primaryConstraintBenchmark?.id)) {
    return false;
  }

  if (isNil(secondaryConstraintBenchmark?.id)) {
    return false;
  }

  return primaryConstraintBenchmark.id === secondaryConstraintBenchmark.id;
};

export const portfolioConstraintTypeValues: SelectOption<ConstraintType>[] = constraintTypeValues.filter((option) =>
  [ConstraintType.Equal, ConstraintType.Between].includes(option.value)
);
