import { Stack } from '@mui/joy';
import isEqual from 'lodash/fp/isEqual';
import isNil from 'lodash/fp/isNil';
import sortBy from 'lodash/fp/sortBy';
import type { FunctionComponent } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import type { AssetLabelInput } from 'components/market/asset/AssetLabelService.ts';
import { HEIGHT_PX } from 'components/market/asset/AssetSelectOption/AssetSelectOption.tsx';
import { getAssetsGroups } from 'components/market/asset/groups/GroupService.ts';
import { defaultRowSpacing } from 'components/StackSpacing.ts';
import FormSelect from 'components/technical/form/FormSelect.tsx';
import FormStaticSingleAutocomplete from 'components/technical/form/FormStaticSingleAutocomplete.tsx';
import type { StaticAutocompleteOption } from 'components/technical/inputs/Autocomplete/StaticSingleAutocomplete.props.ts';
import RemoveButton from 'components/technical/RemoveButton.tsx';
import { IConstraintFormulation } from 'generated/graphql.tsx';

import {
  allocationConstraintTypeValues,
  groupConstrainedQuantityValues,
} from './AllocationConstraintsStep.validation.ts';
import {
  assetCategorySortingKey,
  createAssetSelectOptions,
  getAssetCategory,
} from '../../../../market/asset/AssetService.tsx';
import { ConstraintValueInputs } from '../ConstraintValueInputs.tsx';
import type { PortfolioOptimizerInputFields } from '../portfolio/PortfolioOptimizer.validation.ts';
import type { Portfolio } from '../portfolio/PortfolioOptimizerWizard.tsx';
import { createGroupBy } from '../../../lab/PortfolioDefinitionService.tsx';
import type { AssetOptimizerInputFields } from '../asset/AssetOptimizer.validation.ts';
import type { AssetGroup } from '../asset/AssetOptimizerWizard.tsx';

type OptionValue =
  | { type: 'asset'; asset: AssetLabelInput }
  | { type: 'group'; group: AssetGroup }
  | { type: 'portfolio'; portfolio: Portfolio };

type AllocationRowProps = {
  index: number;
  groups: AssetGroup[];
  assetIdToClusterToGroup: Record<string, Record<string, string>>;
  onRemove: () => void;
  type: 'asset' | 'portfolio';
};

const createOptions = (
  type: 'asset' | 'portfolio',
  universe: AssetOptimizerInputFields['universe'] | PortfolioOptimizerInputFields['universe'],
  assetIdToClusterToGroup: Record<string, Record<string, string>>,
  groups: AssetGroup[]
): {
  isValueEqual: (a: OptionValue | undefined | null, b: OptionValue | undefined | null) => boolean;
  groupBy: (opt: StaticAutocompleteOption<OptionValue>) => string;
  options: StaticAutocompleteOption<OptionValue>[];
} => {
  const portfolioGrouper = createGroupBy(
    type === 'portfolio' ? (universe as PortfolioOptimizerInputFields['universe']) : []
  );

  const groupBy = (opt: StaticAutocompleteOption<OptionValue>): string => {
    const { value } = opt;
    switch (value.type) {
      case 'asset':
        return getAssetCategory(value.asset);
      case 'group':
        return value.group.clusterName;
      case 'portfolio':
        return portfolioGrouper(value.portfolio);
      default:
        console.error('Unknown value for grouping', value);
        return '';
    }
  };

  const isValueEqual = (a: OptionValue | undefined | null, b: OptionValue | undefined | null): boolean => {
    const unifiedNullA = a ?? null;
    const unifiedNullB = b ?? null;
    return isEqual(unifiedNullA, unifiedNullB);
  };

  const options: StaticAutocompleteOption<OptionValue>[] = [];
  if (type === 'asset') {
    const castedUniverse = universe as AssetOptimizerInputFields['universe'];
    const universeOptions = createAssetSelectOptions(
      castedUniverse.filter((asset): asset is AssetLabelInput => !isNil(asset))
    ).options.map(
      (opt): StaticAutocompleteOption<OptionValue> => ({
        ...opt,
        value: {
          type: 'asset',
          asset: opt.value,
        },
      })
    );

    const groupForSelectedAssets = getAssetsGroups(
      assetIdToClusterToGroup,
      castedUniverse.filter((asset): asset is AssetLabelInput => !isNil(asset))
    );

    const groupOptions = groups
      .filter((group) => groupForSelectedAssets.has(group.groupName))
      .map(
        (group): StaticAutocompleteOption<OptionValue> => ({
          value: {
            group: group,
            type: 'group' as const,
          },
          key: `group-${group.id}`,
          label: group.groupName,
          inputText: group.groupName,
          searchText: `${group.clusterName} ${group.groupName}`,
        })
      );

    options.push(...universeOptions, ...groupOptions);
  } else {
    const portfolios = universe as PortfolioOptimizerInputFields['universe'];

    const portfolioOptions = portfolios.map((portfolio) => ({
      searchText: portfolio!.name,
      label: portfolio!.name,
      value: { type: 'portfolio' as const, portfolio },
      key: portfolio!.id,
    }));

    options.push(...portfolioOptions);
  }

  return {
    groupBy,
    options: sortBy((option: StaticAutocompleteOption<OptionValue>): unknown => {
      const value = option.value;
      return [
        value.type,
        value.type === 'asset'
          ? assetCategorySortingKey(value.asset)
          : value.type === 'group'
            ? [value.group.clusterName, value.group.groupName]
            : groupBy(option),
      ];
    }, options),
    isValueEqual,
  };
};

const AllocationRow: FunctionComponent<AllocationRowProps> = ({
  index,
  groups,
  assetIdToClusterToGroup,
  onRemove,
  type,
}) => {
  const { formState, getValues } = useFormContext<AssetOptimizerInputFields | PortfolioOptimizerInputFields>();
  const item = useWatch<AssetOptimizerInputFields>({
    name: `constraints.${index}.item` as const,
    exact: true,
  }) as OptionValue | null;

  const assetConstraintType = useWatch<AssetOptimizerInputFields | PortfolioOptimizerInputFields, 'constraintType'>({
    name: 'constraintType',
    exact: true,
  });

  const universe = getValues('universe');
  const { options, groupBy, isValueEqual } = createOptions(type, universe, assetIdToClusterToGroup, groups);

  return (
    <Stack direction="row" flexWrap="wrap" spacing={defaultRowSpacing} alignItems="flex-end">
      <FormStaticSingleAutocomplete<AssetOptimizerInputFields>
        name={`constraints.${index}.item`}
        label={type === 'asset' ? 'Asset, asset group or factor' : 'Portfolio'}
        width="xl4"
        options={options}
        limitTags={1}
        groupBy={groupBy}
        isValueEqual={isValueEqual}
        menuWidth="xl2"
        optionHeight={HEIGHT_PX}
      />
      {item?.type === 'group' && (
        <FormSelect<AssetOptimizerInputFields>
          name={`constraints.${index}.constrainedQuantity`}
          label="Constrained quantity"
          width="normal"
          options={groupConstrainedQuantityValues}
        />
      )}
      <span>has a</span>
      <FormSelect<AssetOptimizerInputFields>
        name={`constraints.${index}.constraintType` as const}
        width="normal"
        options={allocationConstraintTypeValues}
        label="Condition"
      />
      <ConstraintValueInputs
        startAdornment={
          isNil(item?.type)
            ? ''
            : item?.type === 'group' || assetConstraintType === IConstraintFormulation.Percentage
              ? '%'
              : '$'
        }
        width="normal"
        pathPrefix={`constraints.${index}`}
      />
      <RemoveButton
        disabled={formState.isSubmitting}
        onClick={(): void => {
          onRemove();
        }}
      />
    </Stack>
  );
};

export default AllocationRow;
