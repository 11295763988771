import { Add } from '@mui/icons-material';
import { Stack } from '@mui/joy';
import { type ReactElement, useEffect } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import FormSelect from 'components/technical/form/FormSelect.tsx';
import FormStaticSingleAutocomplete from 'components/technical/form/FormStaticSingleAutocomplete';
import type { StaticAutocompleteProps } from 'components/technical/inputs/Autocomplete/StaticSingleAutocomplete.props';
import GButton from 'components/technical/inputs/GButton/GButton.tsx';
import RemoveButton from 'components/technical/RemoveButton';

import {
  portfolioConstraintTypeValues,
  type PortfolioLevelConstraintsStepInput,
} from './PortfolioConstraintsStep.validation.ts';
import { ISecondaryConstrainedQuantity } from '../../../../../generated/graphql.tsx';
import type { AssetLabelInput } from '../../../../market/asset/AssetLabelService.ts';
import { useValueChanged } from '../../../../UseValueChanged.tsx';
import { ConstraintValueInputs } from '../ConstraintValueInputs.tsx';

import { ConstraintType } from '../ConstraintTypeValues.validation.ts';
import type { SelectOption } from 'components/technical/inputs/Select/Select.props.ts';
import type { ObjectivesStepInput } from '../objective/ObjectivesStep.validation.ts';

const pathPrefix = 'portfolioConstraints.secondaryConstraint' as const;
const PortfolioSecondaryConstraint = ({
  assetOptions,
  secondaryConstraintOptions,
}: {
  assetOptions: Pick<StaticAutocompleteProps<AssetLabelInput>, 'options' | 'optionHeight' | 'limitTags' | 'groupBy'>;
  secondaryConstraintOptions: SelectOption<ISecondaryConstrainedQuantity>[];
}): ReactElement => {
  const { resetField, setValue, clearErrors, trigger } = useFormContext<PortfolioLevelConstraintsStepInput>();
  const constrainedQuantity = useWatch({ name: `${pathPrefix}.constrainedQuantity` });
  const benchmark = useWatch({ name: `${pathPrefix}.benchmark` });

  const constrainedQualityChanged = useValueChanged(constrainedQuantity);
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (!constrainedQualityChanged) {
      return;
    }

    setValue(`${pathPrefix}.constraintType`, ConstraintType.Equal, {
      shouldValidate: true,
    });

    const keys = ['value', 'min', 'max'] as const;
    for (const key of keys) {
      setValue(`${pathPrefix}.constraintValue.${key}`, null);
      clearErrors(`${pathPrefix}.constraintValue.${key}`);
    }
  }, [constrainedQualityChanged, resetField, setValue, clearErrors, trigger]);

  const shouldShowBetaAsset = constrainedQuantity === ISecondaryConstrainedQuantity.Beta;

  const betaAssetChanged = useValueChanged(benchmark);
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    trigger('portfolioConstraints.duplicatedAsset');
  }, [betaAssetChanged, trigger]);

  useEffect(() => {
    if (!shouldShowBetaAsset) {
      clearErrors(`${pathPrefix}.benchmark`);
    }
  }, [clearErrors, shouldShowBetaAsset]);

  const formState = useFormState<PortfolioLevelConstraintsStepInput>({
    name: 'portfolioConstraints.duplicatedAsset',
    exact: true,
  });

  return (
    <Stack direction="row" flexWrap="wrap" spacing={1.5} alignItems="flex-end">
      <FormSelect<PortfolioLevelConstraintsStepInput>
        name={`${pathPrefix}.constrainedQuantity`}
        width="normal"
        label="Metric"
        options={secondaryConstraintOptions}
      />
      {shouldShowBetaAsset && (
        <Stack direction="row" alignItems="flex-end" spacing={1.5}>
          <span>with respect to</span>
          <FormStaticSingleAutocomplete<PortfolioLevelConstraintsStepInput>
            name={`${pathPrefix}.benchmark`}
            label="Asset of Beta"
            width="xl2"
            placeholder="Asset of Beta"
            {...assetOptions}
            error={formState.errors.portfolioConstraints?.duplicatedAsset?.message}
          />
        </Stack>
      )}
      <FormSelect<PortfolioLevelConstraintsStepInput>
        name={`${pathPrefix}.constraintType` as const}
        width="normal"
        options={portfolioConstraintTypeValues}
        label="Condition"
      />
      <ConstraintValueInputs
        startAdornment={constrainedQuantity === ISecondaryConstrainedQuantity.NetExposure ? '%' : ''}
        width="normal"
        pathPrefix={pathPrefix}
      />
      <RemoveButton disabled={formState.isSubmitting} onClick={(): void => resetField(pathPrefix)} />
    </Stack>
  );
};

const DEFAULT_SECONDARY_CONSTRAINT: NonNullable<
  PortfolioLevelConstraintsStepInput['portfolioConstraints']
>['secondaryConstraint'] = {
  constrainedQuantity: ISecondaryConstrainedQuantity.Beta,
  constraintType: ConstraintType.Equal,
  constraintValue: {
    value: '100',
    min: '',
    max: '',
  },
  benchmark: null,
};
const PortfolioSecondaryConstraintContainer = ({
  assetOptions,
  secondaryConstraintOptions,
}: {
  assetOptions: Pick<StaticAutocompleteProps<AssetLabelInput>, 'options' | 'optionHeight' | 'limitTags' | 'groupBy'>;
  secondaryConstraintOptions: SelectOption<ISecondaryConstrainedQuantity>[];
}): ReactElement | null => {
  const { getValues, setValue, formState, trigger } = useFormContext<
    ObjectivesStepInput & PortfolioLevelConstraintsStepInput
  >();
  const secondaryConstraint = useWatch<PortfolioLevelConstraintsStepInput, 'portfolioConstraints.secondaryConstraint'>({
    name: 'portfolioConstraints.secondaryConstraint',
  });
  const primaryConstraint = useWatch<PortfolioLevelConstraintsStepInput, 'portfolioConstraints.primaryConstraint'>({
    name: 'portfolioConstraints.primaryConstraint',
  });
  const allowShortAndLeverage = getValues('allowShortAndLeverage');

  const hasSecondaryConstraint = !!secondaryConstraint;
  const changedSecondaryConstraint = useValueChanged(hasSecondaryConstraint);
  // biome-ignore lint/correctness/useExhaustiveDependencies: we trigger when prop changes
  useEffect(() => {
    trigger('portfolioConstraints.duplicatedAsset');
  }, [changedSecondaryConstraint, trigger]);

  if (!allowShortAndLeverage || !primaryConstraint) {
    return null;
  }

  return (
    <>
      {!hasSecondaryConstraint ? (
        <GButton
          disabled={formState.isSubmitting}
          variant="plain"
          onClick={(): void => {
            setValue(pathPrefix, DEFAULT_SECONDARY_CONSTRAINT, {
              shouldValidate: true,
            });
          }}
          width="xl2"
          startDecorator={<Add />}
        >
          Add portfolio constraint
        </GButton>
      ) : (
        <Stack>
          <PortfolioSecondaryConstraint
            assetOptions={assetOptions}
            secondaryConstraintOptions={secondaryConstraintOptions}
          />
        </Stack>
      )}
    </>
  );
};

export default PortfolioSecondaryConstraintContainer;
