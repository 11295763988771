import { Box, Stack, Typography } from '@mui/joy';
import type { ReactElement } from 'react';
import DebugTooltip from 'components/technical/DebugTooltip.tsx';
import type { IAccount, ISubAccount } from '../../../generated/graphql';
import { IconVariant } from '../../market/asset/cryptocurrencies/CryptocurrenciesData';
import VenueLabel from '../../venue/VenueLabel.tsx';

export const HEIGHT_PX = 32;

export type SubAccountLabelInputAccount = Pick<ISubAccount, 'id' | 'name'> & {
  account: Pick<IAccount, 'id' | 'venue' | 'name'>;
};

export const SubAccountLabel = ({
  subAccount,
  variant = 'long',
  size = IconVariant.MEDIUM,
  wrap = true,
  plain = false,
}: {
  subAccount: SubAccountLabelInputAccount;
  variant?: 'long' | 'short';
  size?: IconVariant;
  wrap?: boolean;
  plain?: boolean;
}): ReactElement => {
  const venueIcon = (
    <VenueLabel
      accountName={subAccount.account.name}
      venue={subAccount.account.venue.label}
      size={size}
      format="short"
    />
  );
  if (variant === 'short') {
    return <DebugTooltip text={subAccount.id}>{venueIcon}</DebugTooltip>;
  }

  const sx = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };
  const textContent = plain ? (
    <Box component={'span'} sx={sx}>
      {subAccount.name}
    </Box>
  ) : (
    <Typography level={'title-sm'} sx={sx}>
      {subAccount.name}
    </Typography>
  );

  return (
    <DebugTooltip text={subAccount.id} component={'div'}>
      <Stack direction="row" spacing={0.5} flexWrap={wrap ? 'wrap' : 'nowrap'} alignItems="center">
        {venueIcon}
        {textContent}
      </Stack>
    </DebugTooltip>
  );
};
