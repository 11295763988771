import type { ReactElement } from 'react';

import { useActions } from './UseActions.tsx';
import HeaderBar, { type HeaderBarProps } from '../HeaderBar/HeaderBar';

const ActionsHeaderBar = (props: Omit<HeaderBarProps, 'children'>): ReactElement => {
  const actions = useActions();
  return <HeaderBar {...props}>{actions}</HeaderBar>;
};

export default ActionsHeaderBar;
