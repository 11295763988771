import type { DraggableAttributes, UniqueIdentifier } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { Theme } from '@mui/joy';
import ListItem from '@mui/joy/ListItem';
import type { CSSProperties, ReactElement } from 'react';

export type DraggableListItemProps<ITEM extends { id: UniqueIdentifier }> = {
  item: ITEM;
  renderItem: (
    item: ITEM,
    draggableAttrs: DraggableAttributes,
    listeners: SyntheticListenerMap | undefined
  ) => ReactElement;
  dragHandle: boolean;
};

const DraggableListItem = <ITEM extends { id: UniqueIdentifier }>({
  item,
  renderItem,
  dragHandle,
}: DraggableListItemProps<ITEM>): ReactElement => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: item.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const listProps = {
    ref: setNodeRef,
    ...(dragHandle
      ? {}
      : {
          ...attributes,
          ...listeners,
        }),
  };

  return (
    <div style={style} {...listProps}>
      <ListItem
        sx={(theme: Theme): CSSProperties => ({
          background: isDragging ? theme.palette.background.level3 : 'inherit',
          paddingInline: 1,
          paddingBlock: 0.5,
        })}
      >
        {renderItem(item, attributes, listeners)}
      </ListItem>
    </div>
  );
};

export default DraggableListItem;
