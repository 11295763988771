import type { FunctionComponent } from 'react';

import type { SavedConfiguration } from 'components/portfolio/dashboard/PortfolioSnapshotSunburstContainer.tsx';
import {
  type Aggregation,
  type AggregationValue,
  assetAggregation,
  getGenieGroupAggregations,
  getUserGroupAggregations,
  type GroupWithAssetId,
  longShortCategory,
} from 'components/portfolio/dashboard/PositionAggregationsService.ts';
import { useSessionStorage } from 'react-use';
import PortfolioSunburstWithAggregations from 'components/portfolio/dashboard/PortfolioSunburstWithAggregations.tsx';
import type { BuilderPortfolioPosition } from './PortfolioBuilder.tsx';
import { bignumber } from 'mathjs';

const SAVED_CONFIG_KEY = 'portfolioBuilderBalanceSunburst';

type PositionsSunburstProps = {
  positions: BuilderPortfolioPosition[];
  assetGroups: {
    genieGroups: GroupWithAssetId[];
    userGroups: GroupWithAssetId[];
  };
};

const PositionsSunburst: FunctionComponent<PositionsSunburstProps> = ({ assetGroups, positions }) => {
  const aggregations: Aggregation<BuilderPortfolioPosition>[] = [
    assetAggregation,
    {
      label: 'Long/short',
      category: longShortCategory,
      calculateValue: (pos: BuilderPortfolioPosition): AggregationValue => {
        const value = pos.value > 0 ? 'Long' : 'Short';
        return {
          id: value,
          label: value,
        };
      },
    },
    ...getGenieGroupAggregations<BuilderPortfolioPosition>(assetGroups.genieGroups),
    ...getUserGroupAggregations<BuilderPortfolioPosition>(assetGroups.userGroups),
  ];

  const [savedAggregationsState, setSavedAggregationsState] = useSessionStorage<SavedConfiguration>(
    SAVED_CONFIG_KEY,
    {
      aggregations: [
        {
          category: assetAggregation.category,
          active: true,
        },
      ],
    },
    false
  );

  return (
    <PortfolioSunburstWithAggregations
      positions={positions}
      aggregations={aggregations}
      savedAggregations={savedAggregationsState.aggregations}
      onSetAggregationConfig={setSavedAggregationsState}
      calculateBalance={(pos) => bignumber(pos.value)}
    />
  );
};

export default PositionsSunburst;
