import type { Theme } from '@mui/joy';
import type { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import type { ReactElement } from 'react';
import type { Location } from 'react-router';

export enum DrawerType {
  Login = 'Login',
  QuantInsightsFilters = 'QuantInsightsFilters',
  NewsExplorerFilters = 'NewsExplorerFilters',
  SubAccountAssetFilter = 'SubAccountAssetFilter',
  Notifications = 'Notifications',
  Agent = 'Agent',
  Presets = 'Presets',
}

export interface DrawerConfig<T extends object> {
  children: (props: T) => ReactElement;
  persistent?: boolean;
  contentSx?: SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>);
  shouldHideDrawer?: (location: Location) => boolean;
}
