import type { ReactElement, ReactNode } from 'react';

import ActionsChartWithTitle from './ActionsChartWithTitle.tsx';
import { TwoThirdsLayout } from './TwoThirdsLayout.tsx';

export const ChartsWrapper = ({
  paper,
  leftTitle,
  rightTitle,
  left,
  right,
}: {
  paper: boolean;
  leftTitle: string | ReactNode;
  rightTitle: string;
  left: ReactNode;
  right: ReactNode;
}): ReactElement => (
  <TwoThirdsLayout
    left={
      <ActionsChartWithTitle title={leftTitle} paper={paper}>
        {left}
      </ActionsChartWithTitle>
    }
    right={
      <ActionsChartWithTitle title={rightTitle} paper={paper}>
        {right}
      </ActionsChartWithTitle>
    }
  />
);
