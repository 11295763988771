import type { ReactElement } from 'react';

import { useActions } from './UseActions.tsx';

const Actions = (): ReactElement => {
  const actions = useActions();
  return <>{actions}</>;
};

export default Actions;
