import type { FunctionComponent } from 'react';
import NavigableTab from 'components/technical/NavigableTabs/NavigableTab';
import NavigableTabs from 'components/technical/NavigableTabs/NavigableTabs';

const OpenPositionsDashboard: FunctionComponent = () => {
  return (
    <NavigableTabs fullHeight>
      <NavigableTab link="/app/portfolio/positions/summary" text="Summary" />
      <NavigableTab link="/app/portfolio/positions/spot" text="Spot" />
      <NavigableTab link="/app/portfolio/positions/derivatives" text="Derivatives" />
    </NavigableTabs>
  );
};

export default OpenPositionsDashboard;
