import { Typography } from '@mui/joy';
import GButton from './GButton/GButton.tsx';
import type { GButtonProps } from './GButton/GButton.props.ts';

const GFab = (props: Omit<GButtonProps, 'slotProps' | 'sx'>) => {
  const { size, children, ...rest } = props;
  return (
    <GButton
      {...rest}
      size={size ?? 'lg'}
      slotProps={{
        startDecorator: {
          sx: {
            mr: 1,
          },
        },
      }}
      sx={(theme) => {
        return {
          borderRadius: theme.radius.xl2,
          py: 1.5,
          px: 2,
        };
      }}
    >
      <Typography level={'title-sm'} textColor={'inherit'} textTransform={'uppercase'}>
        {children}
      </Typography>
    </GButton>
  );
};

export default GFab;
