import type { IAggFuncParams, ValueGetterParams } from 'ag-grid-community';
import type { IRealizedPnlAnalysisQuery } from 'generated/graphql';
import { formatPercentage } from 'components/formatter.utils';
import { bignumber, type BigNumber, sum } from 'mathjs';

type RowData = IRealizedPnlAnalysisQuery['portfolio']['realizedPnl'][number];

type LossesAndWins = { loses: number; wins: number };
export type FeeAndVolume = { fee: BigNumber; volume: BigNumber };

export function calculateWinPercentage(losesAndWins: LossesAndWins): number {
  return losesAndWins.wins / (losesAndWins.wins + losesAndWins.loses);
}

export function calculateWinLossRatio(losesAndWins: LossesAndWins): number {
  return losesAndWins.wins / losesAndWins.loses;
}

export function aggregateLossesAndWins({ values }: IAggFuncParams<RowData, LossesAndWins>): LossesAndWins {
  const cleanValues = values.filter((x) => x !== null) as LossesAndWins[];
  return cleanValues.reduce(
    (acc, curr) => ({
      loses: acc.loses + curr.loses,
      wins: acc.wins + curr.wins,
    }),
    { loses: 0, wins: 0 }
  );
}

export function feeVolumeGetter(params: ValueGetterParams<RowData>): FeeAndVolume | undefined {
  if (!params.data) {
    return undefined;
  }
  const fee = bignumber(params.data?.totalFees);
  const volume = bignumber(params.data?.totalVolumeUsd);
  return {
    fee,
    volume,
  };
}

export function aggregateFeeVolume({ values }: IAggFuncParams<RowData, FeeAndVolume>): FeeAndVolume {
  const cleanValues = values.filter((x) => x !== null) as FeeAndVolume[];
  return cleanValues.reduce(
    (acc, curr) => ({
      fee: acc.fee.add(curr.fee),
      volume: acc.volume.add(curr.volume),
    }),
    { fee: bignumber(0), volume: bignumber(0) }
  );
}

export function winLossGetter(params: ValueGetterParams<RowData>): LossesAndWins | undefined {
  if (!params.data) {
    return undefined;
  }
  const loses = params.data?.numberOfLosses;
  const wins = params.data?.numberOfWins;
  return {
    loses,
    wins,
  };
}

export function winLossCalculator(
  calculation: (losesAndWins: LossesAndWins) => number
): ({ value }: { value: LossesAndWins }) => string {
  return ({ value }: { value: LossesAndWins }): string => {
    if (!value) {
      return '';
    }
    const percentage = calculation(value);
    if (Number.isNaN(percentage) || !Number.isFinite(percentage)) {
      return '-';
    }
    return formatPercentage(percentage);
  };
}

export function decimalAggregate({ values }: IAggFuncParams<RowData, string>): BigNumber {
  return sum(values.map((number) => (number ? bignumber(number) : bignumber(0))));
}
