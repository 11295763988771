import PageShell from '../components/technical/PageShell/PageShell.tsx';
import ManagementDashboard from '../components/management/ManagementDashboard.tsx';
import AccountList from '../components/portfolio/account/AccountList.tsx';
import SubFundsDashboardContainer from '../components/portfolio/fund/SubFundsDashboard.tsx';
import AssetGroupsDashboard from '../components/market/asset/groups/AssetGroupsDashboard.tsx';
import AssetManagementList from '../components/management/asset/AssetManagementList.tsx';
import UserSettings from '../components/management/settings/UserSettings.tsx';
import PrivateRoute from './PrivateRoute/PrivateRoute.tsx';
import ImpersonationDashboard from '../components/management/impersonation/ImpersonationDashboard.tsx';
import NotificationSettings from '../components/copilot/newsNotification/NotificationSettings.tsx';
import PositionsEditorDashboard from '../components/management/snapshotEditor/PositionsEditorDashboard.tsx';
import { createIndexRoute } from './CreateIndexRoute.tsx';

export const managementRoutes = {
  handle: { breadcrumb: 'Management', title: 'Management' },
  path: 'management',
  children: [
    {
      path: 'dashboard',
      handle: { breadcrumb: 'Dashboard' },
      element: (
        <PageShell>
          <ManagementDashboard />
        </PageShell>
      ),
    },
    {
      handle: { breadcrumb: 'Accounts' },
      path: 'account',
      element: (
        <PageShell>
          <AccountList />
        </PageShell>
      ),
    },
    {
      handle: { breadcrumb: 'Sub-funds' },
      path: 'sub-funds',
      element: (
        <PageShell padding={0}>
          <SubFundsDashboardContainer />
        </PageShell>
      ),
    },
    {
      path: 'asset-groups',
      handle: { breadcrumb: 'Groups' },
      element: (
        <PageShell padding={0}>
          <AssetGroupsDashboard />
        </PageShell>
      ),
    },
    {
      handle: { breadcrumb: 'Assets' },
      path: 'asset',
      element: (
        <PageShell>
          <AssetManagementList />
        </PageShell>
      ),
    },
    {
      handle: { breadcrumb: 'Settings' },
      path: 'settings',
      element: (
        <PageShell>
          <UserSettings />
        </PageShell>
      ),
    },
    {
      handle: { breadcrumb: 'Impersonation' },
      path: 'impersonation',
      element: (
        <PrivateRoute
          to="../dashboard"
          element={
            <PageShell>
              <ImpersonationDashboard />
            </PageShell>
          }
          shouldAllow={({ isImpersonating }) => isImpersonating}
        />
      ),
    },
    {
      handle: { breadcrumb: 'Notifications' },
      path: 'notification',
      element: (
        <PageShell>
          <NotificationSettings />
        </PageShell>
      ),
    },
    {
      handle: { breadcrumb: 'Positions' },
      path: 'positions',
      element: (
        <PageShell>
          <PositionsEditorDashboard />
        </PageShell>
      ),
    },
    createIndexRoute('dashboard'),
  ],
};
