import { Stack, Typography as JoyTypography, Typography } from '@mui/joy';
import { type BigNumber, bignumber } from 'mathjs';
import type { FunctionComponent, ReactElement } from 'react';
import type { IAssetVestingQuery } from '../../../../../../generated/graphql';
import { formatNumber, formatPercentage } from '../../../../../formatter.utils';
import VerticalLabelAndValue from '../../VerticalLabelAndValue';

type VestingDetails = IAssetVestingQuery['bookkeeping']['investments']['assetDetails'];

const VestedUnvestedHeaderValue = ({ value, total }: { value: string; total: BigNumber }): ReactElement => (
  <Stack direction="row" alignItems="center" spacing={0.5}>
    <Typography level={'h4'}>{formatNumber(bignumber(value))}</Typography>
    <JoyTypography color="neutral" level="body-md">
      ({formatPercentage(bignumber(value).div(total))})
    </JoyTypography>
  </Stack>
);

type VestedUnvestedHeaderProps = {
  details: VestingDetails;
  asset: { symbol: string };
};

const VestedUnvestedHeader: FunctionComponent<VestedUnvestedHeaderProps> = ({ details, asset }) => {
  const totalToVest = bignumber(details.vested).plus(details.unvested);

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      flexWrap="wrap"
      justifyContent="space-between"
      sx={{
        padding: '1rem 2rem',
      }}
    >
      <VerticalLabelAndValue
        label={asset.symbol}
        variant="left"
        value={<Typography level="h3">{formatNumber(totalToVest)}</Typography>}
      />
      <VerticalLabelAndValue
        label="Vested"
        variant="center"
        value={<VestedUnvestedHeaderValue value={details.vested} total={totalToVest} />}
      />
      <VerticalLabelAndValue
        label="Unvested"
        variant="right"
        value={<VestedUnvestedHeaderValue value={details.unvested} total={totalToVest} />}
      />
    </Stack>
  );
};

export default VestedUnvestedHeader;
