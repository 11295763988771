import { formatEnum } from '../../../../formatter.utils.ts';
import type { PortfolioRow } from './initialPortfolio/InitialPortfolioStep.tsx';
import type { ValueGetterParams } from 'ag-grid-community';
import type { RecursivePartial } from '../../../../type.utils.ts';
import type { IPortfolioDefinition } from '../../../../../generated/graphql.tsx';

export const portfolioRebalancingRuleGetter = (
  portfolio: RecursivePartial<IPortfolioDefinition> | undefined | null
): string | undefined => {
  if (!portfolio || !portfolio.createdFrom || portfolio.genie) {
    return undefined;
  }

  const { name, periodUnit, periodValue } = portfolio.createdFrom;
  return `${name} ${periodUnit} ${periodValue}`;
};

export const portfolioNameGetter = (
  portfolio: RecursivePartial<IPortfolioDefinition> | undefined | null
): string | undefined => {
  return portfolio?.createdFrom?.portfolioDefinition?.name ?? portfolio?.name;
};

export const portfolioTypeGetter = (
  portfolio: RecursivePartial<IPortfolioDefinition> | undefined | null
): string | undefined => {
  return formatEnum(portfolio?.createdFrom?.portfolioDefinition?.type ?? portfolio?.type);
};

export const portfolioDefinitionNameColumn = {
  colId: 'name',
  headerName: 'Name',
  type: 'textColumn',
  valueGetter: (params: ValueGetterParams<PortfolioRow, string>): string | undefined =>
    portfolioNameGetter(params.data),
};

export const portfolioDefinitionRebalancingRuleColumn = {
  colId: 'rebalancingRule',
  headerName: 'Rebalancing rule',
  type: 'textColumn',
  valueGetter: (params: ValueGetterParams<PortfolioRow, number>): string | undefined => {
    return portfolioRebalancingRuleGetter(params.data);
  },
};
export const portfolioDefinitionTypeColumn = {
  colId: 'type',
  headerName: 'Type',
  type: 'textColumn',
  valueGetter: (params: ValueGetterParams<PortfolioRow, string>): string | undefined =>
    portfolioTypeGetter(params.data),
};
export const portfolioDefinitionSharedColumns = [
  portfolioDefinitionNameColumn,
  portfolioDefinitionRebalancingRuleColumn,
  portfolioDefinitionTypeColumn,
] as const;
