import { ArrowDropDown } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import type { IconButtonProps } from '@mui/joy';
import IconButton from '@mui/joy/IconButton';
import { type Dispatch, type ForwardedRef, type ReactElement, type SetStateAction, useEffect } from 'react';

import { fixedForwardRef } from 'components/technical/fixedForwardRef';
import type { BaseDateInputProps } from './BaseDateInputProps';
import styles from './DateInputWrapper.module.css';
import GInput from '../GInput/GInput';
import type { LabelProps } from '../LabelService.ts';
import { useIMask } from 'react-imask';
import type { InputMask } from 'imask';
import { type DateMaskFormat, type DateValueType, useFormatInfo } from '../../../mask.utils.ts';
import { useForkRef } from '@mui/material';
import { useValueChanged } from '../../../UseValueChanged.tsx';
import { usePrevious } from '../../../UsePrevious.tsx';
import isEqual from 'lodash/fp/isEqual';

const inputClass = styles.input;
const clearIconClass = styles.clearIcon;

export type DateInputWrapperProps<FORMAT extends DateMaskFormat> = Pick<
  BaseDateInputProps,
  'width' | 'disabled' | 'error' | 'showClearable' | 'color' | 'placeholder'
> & {
  setOpen?: Dispatch<SetStateAction<boolean>>;
  open?: boolean;
  showOpen?: boolean;
  value: DateValueType<FORMAT>;
  format: FORMAT;
  onChange?: (value: DateValueType<FORMAT>) => void;
} & LabelProps;

function DateInputWrapper<FORMAT extends DateMaskFormat>(
  props: DateInputWrapperProps<FORMAT>,
  ref: ForwardedRef<HTMLInputElement>
): ReactElement {
  const buttonProps: IconButtonProps = {
    variant: 'plain',
    color: 'neutral',
    disabled: props.disabled,
  };

  const { onChange, value, setOpen, showClearable, showOpen, ...rest } = props;
  const { mask, placeholder } = useFormatInfo<FORMAT>(props.format, !!props.placeholder && !props.value);
  const notLazyOptions = useFormatInfo<FORMAT>(props.format, false);

  const format = mask.format;
  const {
    value: maskedValue,
    typedValue,
    setTypedValue,
    setValue,
    ref: maskHookRef,
    maskRef,
    // biome-ignore lint/suspicious/noExplicitAny: library has incorrect typings
  } = useIMask<HTMLInputElement, any>(mask, {
    onAccept: (_value: string, mask: InputMask, e: unknown | undefined): void => {
      if (e === undefined) {
        // initialization
        return;
      }

      if (!isEqual(notLazyOptions.mask, mask)) {
        const cursor = mask.cursorPos;
        // biome-ignore lint/suspicious/noExplicitAny: lib issue with typings
        mask.updateOptions(notLazyOptions.mask as any);
        mask.updateCursor(cursor);
      }

      // it's not possible to type the expression correctly as mask is a return
      // value of a function, so I would need to type the function itself
      // biome-ignore lint/suspicious/noExplicitAny:
      props.onChange?.(mask.typedValue as any);
    },
    onComplete: (_value: string, mask: InputMask): void => {
      // biome-ignore lint/suspicious/noExplicitAny: the same case as in onAccept
      props.onChange?.(mask.typedValue as any);
    },
    defaultTypedValue: value,
    // biome-ignore lint/suspicious/noExplicitAny:
    defaultValue: format(value as any, { value: placeholder }),
  });

  const forkRef = useForkRef(ref, maskHookRef);

  const prevValue = usePrevious(value);
  const propValueChanged = useValueChanged(value);
  // biome-ignore lint/correctness/useExhaustiveDependencies: prev value is used to trigger updates only when data from props changes
  useEffect(() => {
    const mask = maskRef.current;
    if (!mask) {
      return;
    }

    if (!propValueChanged) {
      // value didnt change since previous render, skipping update to avoid infinite loop when
      // mask gets out of sync with value
      return;
    }

    const previousTextValue = format(typedValue, mask.masked);
    // biome-ignore lint/suspicious/noExplicitAny: lib typing issue
    const nextTextValue = format(value as any, mask.masked);
    if (previousTextValue !== nextTextValue) {
      setTypedValue(value);
      setValue(nextTextValue);
    }
  }, [typedValue, prevValue, value, propValueChanged, format, setTypedValue, setValue, maskRef]);

  return (
    <GInput
      ref={forkRef}
      {...rest}
      value={maskedValue}
      autoComplete={'off'}
      className={inputClass}
      readOnly={props.disabled}
      endDecorator={
        <>
          {!props.disabled && showClearable && value && (
            <IconButton
              {...buttonProps}
              sx={{
                alignSelf: 'center',
                ml: 'calc(var(--_Input-paddingBlock) / 2)',
                px: '2px',
                mr: '0px',
              }}
              className={clearIconClass}
              onClick={(e): void => {
                e.stopPropagation();
                onChange?.(null);
              }}
            >
              <ClearIcon />
            </IconButton>
          )}
          {showOpen && (
            <IconButton
              {...buttonProps}
              onClick={(): void => {
                props.setOpen?.((open) => !open);
              }}
              sx={{
                px: '2px',
                ml: 'calc(var(--_Input-paddingBlock) / 2)',
                mr: '0px',
                transform: props.open ? 'rotate(180deg)' : undefined,
              }}
            >
              <ArrowDropDown />
            </IconButton>
          )}
        </>
      }
    />
  );
}

const ForwardedDateInputWrapper = fixedForwardRef(DateInputWrapper);

export { ForwardedDateInputWrapper as DateInputWrapper };
