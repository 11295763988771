import { matchPath, useLocation, type Location } from 'react-router';
import { useAuth } from 'UseAuth';

const visibleOnPaths = [
  '/app/portfolio/dashboard',
  '/app/portfolio/positions',
  '/app/portfolio/profit-loss',
  '/app/portfolio/risk',
  '/app/portfolio/factor',
  '/app/operations/cost-basis',
  '/app/operations/report',
  '/app/operations/trading-activity',
] as const;

type Paths = (typeof visibleOnPaths)[number];

export type Field = 'subFundIds' | 'subAccountIds' | 'assets' | 'accountIds';

type OptionalPathConfiguration = {
  [K in Paths]?: Field[];
};

const pathConfiguration: OptionalPathConfiguration = {
  // for future: on some paths we will not use all the filter fields
  // e.g. '/app/portfolio/factor': ['subFundIds', 'subAccountIds'],
};

export const useShowSubAccountAssetFilter = (): boolean => {
  const location = useLocation();
  return canShowSubAccountAssetFilter(location);
};

export const canShowSubAccountAssetFilter = (location: Location): boolean => {
  return visibleOnPaths.some((path) => matchPath({ path, end: false }, location.pathname));
};

export const useSubAccountAssetFilterFieldsForPath = (): Field[] | undefined => {
  const location = useLocation();
  const { isAdmin } = useAuth();
  const matchedPath = visibleOnPaths.find((path) => matchPath({ path, end: false }, location.pathname));
  if (!matchedPath) {
    return undefined;
  }

  if (!isAdmin) {
    // 'assets' only for genie users for now, it causes edge case issues on backend side
    return pathConfiguration[matchedPath]?.filter((field) => !['assets'].includes(field));
  }

  return pathConfiguration[matchedPath];
};
