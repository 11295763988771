import { Tooltip, type TooltipProps } from '@mui/joy';
import isNil from 'lodash/fp/isNil';
import type { ReactElement } from 'react';

import { getEnvConfig } from '../../config.ts';

const envConfig = getEnvConfig();

const DebugTooltip = ({
  children,
  text,
  placement = 'top',
  hideDebug = false,
  component = 'span',
}: {
  children: ReactElement;
  text: string | undefined | null;
  placement?: TooltipProps['placement'];
  hideDebug?: boolean;
  component?: 'div' | 'span';
}): ReactElement => {
  if (!envConfig.showTestIds || isNil(text) || hideDebug) {
    return children;
  }

  const Component = component ?? 'span';

  return (
    <Tooltip
      placement={placement}
      title={
        <div
          onClick={(e): void => {
            const target = e.target as HTMLDivElement;
            const selection = window.getSelection();
            if (!target || !selection) {
              return;
            }

            // make div selected
            selection.empty();
            const range = new Range();
            range.selectNode(target);
            selection.addRange(range);

            // prevent clicking on a tooltip from selecting an item in a selectbox
            e.stopPropagation();
          }}
        >
          {text}
        </div>
      }
    >
      <Component>{children}</Component>
    </Tooltip>
  );
};

export default DebugTooltip;
