import dayjs, { type Dayjs, type ManipulateType, type QUnitType } from 'dayjs';
import isNil from 'lodash/fp/isNil';
import { bignumber } from 'mathjs';
import * as yup from 'yup';
import type { ArraySchema, NumberSchema, TestContext } from 'yup';

import type { FormInputType } from 'components/technical/form/Form.types';
import type { GRadioOption } from 'components/technical/inputs/GRadioGroup/GRadioGroup.props';
import bigNumMath from '../../../bigNumMath';
import { type IAsset, IPeriodUnit, IVestingType } from '../../../generated/graphql';
import { isDayjsDateNotInTheFuture, isValidDayjsDate } from '../../date.utils';

export interface FormAssetOutputFields {
  asset: Omit<IAsset, 'derivativeDetails' | 'unvestedAsset'>;
  amount: number;
  marketValue: number;
}

type FormAssetInputFields = FormInputType<
  Omit<FormAssetOutputFields, 'asset'> & {
    asset: Omit<IAsset, 'derivativeDetails' | 'unvestedAsset' | 'priceAsset'> | null;
  }
>;
export interface ImmediateScheduleOutputFields {
  date: Dayjs;
}

export interface PeriodOutputFields {
  unit: IPeriodUnit;
  interval: number;
}

export interface RecurringScheduleOutputFields {
  begin: Dayjs;
  end: Dayjs;
  period: PeriodOutputFields;
}

export interface ScheduleOutputFields {
  type: IVestingType;
  vested: number;
  recurring: RecurringScheduleOutputFields | null;
  immediate: ImmediateScheduleOutputFields | null;
}

export interface FormOutputFields {
  name: string;
  subAccount: string;
  executedAt: Dayjs;
  buy: FormAssetOutputFields;
  sell: FormAssetOutputFields;
  fee: FormAssetOutputFields;
  schedules: ScheduleOutputFields[];
}

export type FormInputFields = FormInputType<Omit<FormOutputFields, 'buy' | 'sell' | 'fee'>> & {
  remainingVestedAmount: number | undefined;
  scheduleLength: number | undefined;
  buy: FormAssetInputFields;
  sell: FormAssetInputFields;
  fee: FormAssetInputFields;
};

type RecurringInputFields = NonNullable<FormInputFields['schedules'][number]['recurring']>;

export const hasNonEmptyAmount = (assetValue: AssetInputFields): boolean => {
  return (assetValue.amount ?? '') !== '' || (assetValue.marketValue ?? '') !== '';
};

export const calculateAssetFeeVisibility = (fee: AssetInputFields): boolean => {
  return hasNonEmptyAmount(fee);
};

type AssetInputFields = FormInputFields['buy'];
const positiveRequiredNumber = yup.number().required().positive();

export const requiredAssetSchema = yup.object({
  asset: yup.mixed().required(),
  amount: positiveRequiredNumber,
  marketValue: positiveRequiredNumber,
});

const validRequiredDateSchema = yup.mixed().required().test('validDate', 'Date is invalid', isValidDayjsDate);

const pastRequiredDateSchema = validRequiredDateSchema.test(
  'futureDate',
  'Date cannot be in the future',
  isDayjsDateNotInTheFuture
);

const notEarlierThanVestingMsg = 'Must be after vesting starts';

const isEmptyOrInvalidNumber = (amt: number | undefined | string | null): boolean =>
  Number.isNaN(amt) || isNil(amt) || amt === '';
const createRecurringScheduleSchema = (
  dateNotBeforeVestingStartsValidator: (value: unknown) => boolean
): yup.Schema => {
  return yup
    .object({
      begin: validRequiredDateSchema
        .test('notEarlierThanExecutedAt', notEarlierThanVestingMsg, dateNotBeforeVestingStartsValidator)
        .test('notLaterThanEndDate', 'Start date is later than end date', (value: unknown, context: TestContext) => {
          const parent: RecurringInputFields = context.parent;
          const other = parent.end;
          if (!value || !other || !isValidDayjsDate(value) || !isValidDayjsDate(other)) {
            return true;
          }

          return !value.isAfter(other);
        }),
      end: validRequiredDateSchema
        .test('notEarlierThanExecutedAt', notEarlierThanVestingMsg, dateNotBeforeVestingStartsValidator)
        .test(
          'notEarlierThanStartDate',
          'End date is earlier than start date',
          (value: unknown, context: TestContext) => {
            const parent: RecurringInputFields = context.parent;
            const other = parent.begin;
            if (!value || !other || !isValidDayjsDate(value) || !isValidDayjsDate(other)) {
              return true;
            }

            return !value.isBefore(other);
          }
        ),
      period: yup
        .object({
          unit: yup.string().oneOf(Object.values(IPeriodUnit)).required(),
          interval: positiveRequiredNumber,
        })
        .required(),
    })
    .test('notEmpty', 'Schedule is empty', (input: unknown) => {
      const schedule = input as RecurringInputFields;
      if (
        isNil(schedule.begin) ||
        !isValidDayjsDate(schedule.begin) ||
        isNil(schedule.end) ||
        !isValidDayjsDate(schedule.end)
      ) {
        return true;
      }

      const period = schedule.period;
      if (isNil(period) || isNil(period.unit)) {
        return true;
      }

      const value = period.interval;
      if (isEmptyOrInvalidNumber(value)) {
        return true;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const vestingDate = schedule.begin.add(bignumber(value).toNumber(), periodUnitLDayjsNames[period.unit]);
      return !vestingDate.isAfter(schedule.end);
    });
};

const createImmediateScheduleSchema = (dateNotBeforeVestingStarts: (value: unknown) => boolean): yup.Schema => {
  return yup.object({
    date: validRequiredDateSchema.test(
      'notEarlierThanExecutedAt',
      notEarlierThanVestingMsg,
      dateNotBeforeVestingStarts
    ),
  });
};

const periodUnitLabels: Record<IPeriodUnit, string> = {
  [IPeriodUnit.Day]: 'Day',
  [IPeriodUnit.Week]: 'Week',
  [IPeriodUnit.Month]: 'Month',
  [IPeriodUnit.Quarter]: 'Quarter',
  [IPeriodUnit.Year]: 'Year',
};

const periodUnitLDayjsNames: Record<IPeriodUnit, ManipulateType | QUnitType> = {
  [IPeriodUnit.Day]: 'day',
  [IPeriodUnit.Week]: 'week',
  [IPeriodUnit.Month]: 'month',
  [IPeriodUnit.Quarter]: 'quarter',
  [IPeriodUnit.Year]: 'year',
};

export const periodUnits = [
  IPeriodUnit.Day,
  IPeriodUnit.Week,
  IPeriodUnit.Month,
  IPeriodUnit.Quarter,
  IPeriodUnit.Year,
].map((value) => ({
  label: periodUnitLabels[value],
  value: value,
  key: value,
}));

const vestingTypeLabels: Record<IVestingType, string> = {
  [IVestingType.Recurring]: 'Recurring',
  [IVestingType.Immediate]: 'Immediate',
};

export const vestingTypeValues: GRadioOption<IVestingType>[] = [IVestingType.Recurring, IVestingType.Immediate].map(
  (value) => ({
    value: value,
    label: vestingTypeLabels[value],
    key: value,
  })
);

const createScheduleSchema = (executedAt: unknown): yup.Schema => {
  const dateNotBeforeVestingStarts = (value: unknown): boolean => {
    if (!value || !dayjs.isDayjs(value) || !dayjs.isDayjs(executedAt)) {
      return true;
    }

    return !value.endOf('day').isBefore(executedAt);
  };

  return yup.object({
    type: yup.string().oneOf(Object.values(IVestingType)).required(),
    vested: positiveRequiredNumber,
    immediate: yup.mixed().when('type', ([type], schema: yup.Schema): yup.Schema => {
      if (type === IVestingType.Immediate) {
        return createImmediateScheduleSchema(dateNotBeforeVestingStarts);
      }
      return schema;
    }),
    recurring: yup.mixed().when('type', ([type], schema: yup.Schema): yup.Schema => {
      if (type === IVestingType.Recurring) {
        return createRecurringScheduleSchema(dateNotBeforeVestingStarts);
      }

      return schema;
    }),
  });
};

export const calculateRemainingVestedAmount = (
  schedules: FormInputFields['schedules'],
  buy: FormInputFields['buy']
): undefined | number => {
  const vestedAmounts = schedules.map((schedule) => schedule.vested);
  if (vestedAmounts.some((amt) => isEmptyOrInvalidNumber(amt)) || isEmptyOrInvalidNumber(buy.amount)) {
    return undefined;
  }

  return bignumber(buy.amount)
    .minus(bigNumMath.sum(vestedAmounts.map((val) => bignumber(val))))
    .toNumber();
};

export const formSchema = yup.object({
  name: yup.string().required(),
  subAccount: yup.string().required(),
  executedAt: pastRequiredDateSchema,
  buy: requiredAssetSchema,
  sell: requiredAssetSchema,
  fee: requiredAssetSchema
    .transform((currentValue: AssetInputFields): unknown | null => {
      if (calculateAssetFeeVisibility(currentValue)) {
        return currentValue;
      }

      return null;
    })
    .nullable(),
  schedules: yup.array().when('executedAt', ([executedAt], schema: ArraySchema<unknown[] | undefined, unknown>) => {
    return schema.of(createScheduleSchema(executedAt));
  }),
  remainingVestedAmount: yup.mixed().when(['schedules', 'buy'], ([schedules, buy], schema): yup.Schema => {
    const remainingAmount = calculateRemainingVestedAmount(schedules, buy);
    if (remainingAmount === undefined) {
      return schema.optional();
    }

    return yup.number().test('fullyVested', 'Remaining vesting amount should be 0', () => {
      return bignumber(remainingAmount).isZero();
    });
  }),
  scheduleLength: yup
    .number()
    .when('schedules', ([schedules], schema: NumberSchema) => {
      return schema.default(schedules.length);
    })
    .test('atLeastOneSchedule', 'At least one schedule is required', (val: number | undefined) =>
      isNil(val) ? true : val >= 1
    ),
});
