import { isBigNumber } from 'mathjs';
import dayJs from 'dayjs';
const bigNumFormatter = {
  header: (obj: unknown) => {
    if (!isBigNumber(obj)) {
      return null;
    }

    return ['span', {}, obj.toNumber().toString()];
  },
  hasBody: () => {
    return false;
  },
};

const dayJsFormatter = {
  header: (obj: unknown) => {
    if (!dayJs.isDayjs(obj)) {
      return null;
    }

    return ['span', {}, obj.isValid() ? obj.toISOString() : 'Invalid date'];
  },
  hasBody: () => {
    return false;
  },
};

const DevtoolsFormatters = () => {
  // @ts-ignore
  if (window.devtoolsFormatters === undefined) {
    window.devtoolsFormatters = [];
    window.devtoolsFormatters.push(bigNumFormatter);
    window.devtoolsFormatters.push(dayJsFormatter);
  }

  return null;
};

export default DevtoolsFormatters;
