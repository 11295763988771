import { Card } from '@mui/joy';
import type { ReactElement } from 'react';
import HeaderBar from '../HeaderBar/HeaderBar';
import GCheckbox from '../inputs/GCheckbox/GCheckbox';
import SectionColumn from '../layout/Column/SectionColumn';

const CheckboxPlayground = (): ReactElement => {
  return (
    <SectionColumn>
      <HeaderBar title="Checkbox" />
      <Card
        sx={{
          alignItems: 'center',
          display: 'grid',
          gridTemplateColumns: '200px 1fr',
          gap: '1rem',
        }}
      >
        <span>State</span>
        <span>Checkbox</span>

        <span>Checked</span>
        <GCheckbox width="xl2" checked />

        <span>Unchecked</span>
        <GCheckbox width="xl2" />

        <span>Label and checked</span>
        <GCheckbox width="minContent" checked label="Label" />
      </Card>
    </SectionColumn>
  );
};

export default CheckboxPlayground;
