import { Card, Stack } from '@mui/joy';
import type { ReactElement, ReactNode } from 'react';

import GButton from '../../inputs/GButton/GButton';

const CalendarWrapper = (props: {
  children: ReactNode;
  predefinedRangesSelector?: ReactNode;
  onCancel: () => void;
  confirmationDisabled?: boolean;
  onConfirm: () => void;
  footerMessage?: ReactNode;
}): ReactElement => (
  <Card
    sx={(theme): { background: string } => ({
      background: theme.palette.background.body,
    })}
    variant="outlined"
  >
    <Stack spacing={1.5}>
      <Stack direction="row" justifyContent="center" spacing={1.5}>
        <div style={{ position: 'relative' }}>{props.children}</div>
        {props.predefinedRangesSelector}
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={1.5}
        sx={(theme) => ({
          borderTop: `1px solid ${theme.palette.background.level2}`,
          paddingTop: '0.75rem',
        })}
      >
        <GButton
          variant="plain"
          onClick={(): void => {
            props.onCancel();
          }}
        >
          Cancel
        </GButton>
        {props.footerMessage}
        <GButton
          disabled={props.confirmationDisabled}
          onClick={(): void => {
            props.onConfirm();
          }}
        >
          Confirm
        </GButton>
      </Stack>
    </Stack>
  </Card>
);

export default CalendarWrapper;
