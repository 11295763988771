import { Stack } from '@mui/joy';
import type { ReactElement } from 'react';

import { StressTestPortfolioComposition } from './StressTestPortfolioComposition';
import StressTestPortfolioPerformance from './StressTestPortfolioPerformance';
import { StressTestResultHeader } from './StressTestResultHeader';
import type { IPerformStressTestQuery } from '../../../../generated/graphql';
import { PortfolioOptimizerRiskMetrics } from '../../PortfolioOptimizerRiskMetrics.tsx';
import PortfolioReturnsPerformance from '../../PortfolioReturnsPerformance';

const StressTestResult = (props: {
  result: IPerformStressTestQuery['stressTest']['stressTest'] | undefined;
  assetToClusters: Record<string, Record<string, string>>;
}): ReactElement => {
  return (
    <Stack spacing={1.5}>
      <StressTestResultHeader skeleton={props.result === undefined} />
      <StressTestPortfolioComposition result={props.result?.logicResult.assetResults} />
      <StressTestPortfolioPerformance result={props.result} />
      <PortfolioReturnsPerformance
        returns={props.result?.logicResult.portfolioReturns}
        statistics={props.result?.logicResult.portfolioStatistics.map((stat) => ({
          name: stat.name,
          value: stat.value,
        }))}
      />
      <PortfolioOptimizerRiskMetrics
        result={props.result?.keyMeasures.measures.map((measure) => ({
          name: 'pmet:expected_' + measure.name.toLowerCase(),
          value: measure.value,
          netChange: measure.netChange,
          params: {},
        }))}
      />
    </Stack>
  );
};

export default StressTestResult;
