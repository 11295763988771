import type { ReactNode } from 'react';

export type LabelProps =
  | {
      label?: string;
      showLabelAboveInput?: undefined | false;
      required?: boolean;
    }
  | {
      label?: ReactNode;
      showLabelAboveInput: true;
      required?: boolean;
    };

export const shouldRenderInputLabel = (props: LabelProps): boolean => {
  return !!props.showLabelAboveInput && !!props.label;
};

export const calculatePlaceholder = (props: LabelProps & { placeholder?: string }): string | undefined => {
  if (props.placeholder) {
    return props.placeholder;
  }

  if (props.showLabelAboveInput) {
    return '';
  }

  if (props.label && props.required) {
    return [props.label, ' *'].join('');
  }

  return props.label ? props.label : props.placeholder;
};
