import type { ReactElement } from 'react';
import { IAgentResultType } from '../../../../generated/graphql.tsx';
import HighChartsWrapper from '../../../technical/charts/HighChartsWrapper/HighChartsWrapper.tsx';
import { AgentResultTableView } from './AgentResultTableView.tsx';
import { GMarkdown } from '../../../technical/GMarkdown.tsx';

export const AgentResultDetailsView = ({ type, value }: { type: IAgentResultType; value: JSON }): ReactElement => {
  if (type === IAgentResultType.Number) {
    return <>{value}</>;
  }

  if (type === IAgentResultType.String) {
    return <GMarkdown>{value as unknown as string}</GMarkdown>;
  }

  if (type === IAgentResultType.Dataframe) {
    const castedValue = value as unknown as Record<string, unknown[]>;
    return <AgentResultTableView value={castedValue} />;
  }

  return (
    <HighChartsWrapper
      loading={false}
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      data={(value as any).series}
      calculateOptions={() => {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        return value as any;
      }}
      calculateChartData={(data) => data}
    />
  );
};
