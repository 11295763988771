import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Modal, ModalDialog, ModalOverflow } from '@mui/joy';
import type { ReactElement } from 'react';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';

import type { GraphQlErrorHandler } from 'components/technical/form/UseGraphQLApiError.tsx';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import TransactionForm from './create/TransactionForm';
import type { FormInputFields, FormOutputFields } from './create/TransactionForm.validation';
import {
  createTransactionOutput,
  transactionDialogProps,
  type UpdateableTransaction,
} from './create/TransactionCreateService.ts';
import {
  TransactionFilterInputDocument,
  TransactionsDocument,
  useUpdateTransactionMutation,
} from '../../../generated/graphql';
import type { AssetSelectOptionValue } from '../../market/asset/AssetService.tsx';
import type { CreateSubAccountIdAutocompleteOptionsInputAccount } from '../../portfolio/account/AccountService.tsx';
import dayjs from 'dayjs';
import type { AssetLabelInput } from '../../market/asset/AssetLabelService.ts';
import isNil from 'lodash/fp/isNil';
import { bignumber } from 'mathjs';

const UpdateTransactionDialogContainer = (props: {
  onClose: () => void;
  onAdded: () => void;
  accounts: CreateSubAccountIdAutocompleteOptionsInputAccount[];
  existingTransaction: UpdateableTransaction;
  existingTags: string[];
}): ReactElement => {
  const defaultValues = {
    ...props.existingTransaction,
    attributedToAsset: (props.existingTransaction.attributedToAsset as AssetLabelInput) ?? null,
    externalId: props.existingTransaction.externalId ?? '',
    time: dayjs.utc(props.existingTransaction.time),
    externalType: props.existingTransaction.externalType ?? '',
    comment: props.existingTransaction.comment ?? '',
    order: {
      side: props.existingTransaction.order?.side ?? null,
      type: props.existingTransaction.order?.type ?? null,
      externalId: props.existingTransaction.order?.externalId ?? '',
    },
    legs: (props.existingTransaction.legs ?? []).map((leg) => ({
      ...leg!,
      marketValue: !isNil(leg.unitValue) ? bignumber(leg.amount).mul(leg.unitValue).toString() : null,
      asset: leg.asset as AssetSelectOptionValue,
      side: leg.side ?? null,
      time: dayjs.utc(leg.time),
    })),
  } satisfies FormInputFields;

  const [updateTransaction] = useUpdateTransactionMutation({
    refetchQueries: [TransactionsDocument, TransactionFilterInputDocument],
  });
  const { showSuccessMessage } = useFeedback();

  const handleFormSubmit = async (data: FormOutputFields, onErrorAndThrow: GraphQlErrorHandler): Promise<void> => {
    try {
      const { deduplicationId, ...rest } = createTransactionOutput(data);
      await updateTransaction({
        variables: { input: { ...rest, id: props.existingTransaction.id } },
      });

      showSuccessMessage('Transaction updated successfully');
      props.onAdded();
    } catch (e) {
      onErrorAndThrow(e);
    }
  };

  return (
    <Modal open={true} onClose={props.onClose}>
      <ModalOverflow>
        <ModalDialog {...transactionDialogProps}>
          <GDialogHeader>Edit transaction</GDialogHeader>
          <TransactionForm
            {...props}
            disableSubAccount
            onSubmit={handleFormSubmit}
            defaultValues={defaultValues}
            accounts={props.accounts}
            submitButtonText="Update transaction"
            submitButtonIcon={<BorderColorIcon />}
          />
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default UpdateTransactionDialogContainer;
