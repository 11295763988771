import isNil from 'lodash/fp/isNil';
import sortBy from 'lodash/sortBy';
import { bignumber } from 'mathjs';
import type { FunctionComponent } from 'react';
import { useParams } from 'react-router';
import { GraphQLErrorCardMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import Loader from 'components/technical/Loader/Loader';

import AssetClusterGroupContainer from './AssetClusterGroupChart';
import { genieTabs, getStrategy, isAllowedClusterTypes, useFilteredMarket } from '../AssetDashboardService.ts';
import { useTheme } from '@mui/joy';
import { useFinalColorScheme } from '../../../../../useFinalColorScheme.ts';

const AssetClusterGroupDashboard: FunctionComponent = () => {
  const params = useParams();
  const theme = useTheme();
  const currentCluster = params.cluster ?? '?';
  const currentGroup = params.group ?? '?';
  const clusterType = params.clusterType;
  const colorScheme = useFinalColorScheme();

  if (!isAllowedClusterTypes(clusterType)) {
    throw new Error(`Unsupported cluster type: ${clusterType}`);
  }

  const { data, error, loading } = useFilteredMarket(clusterType);

  if (loading || isNil(data)) {
    return <Loader />;
  }

  if (error) {
    return <GraphQLErrorCardMessage error={error} />;
  }

  const genieClusterId = genieTabs.find((tab) => tab.name === currentCluster)?.cluster ?? null;
  const strategy = getStrategy(colorScheme, theme, clusterType, genieClusterId ?? currentCluster);
  const groups = strategy(data.assets);

  const currentGroupAssets = groups.subgroups.find((group) => group.name === currentGroup)?.elements ?? [];

  const sortedGroupAssets = sortBy(
    currentGroupAssets.map((el) => ({
      ...el,
      market_cap: Math.round(bignumber(el.metrics['met:market_cap'] ?? '0').toNumber()),
    })),
    'market_cap'
  ).reverse();

  const groupAssets = sortedGroupAssets.map((row) => row.asset) ?? [];

  return <AssetClusterGroupContainer groupAssets={groupAssets} />;
};

export default AssetClusterGroupDashboard;
