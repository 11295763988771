import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Alert, Box, Stack, Tooltip, Typography } from '@mui/joy';
import dayjs from 'dayjs';
import type Highcharts from 'highcharts';
import { capitalize, isNil, kebabCase } from 'lodash/fp';
import groupBy from 'lodash/fp/groupBy';
import { bignumber } from 'mathjs';
import React, { type ReactElement } from 'react';

import { formatterForName } from 'components/formatter.utils.ts';
import {
  chartSize,
  dateTimeAxisFormat,
  dateTimeExportFormat,
  getChartClickEvent,
  getFormatter,
  getPointClickEvent,
  type HighChartRef,
  type HighchartSeries,
  type SelectedChartElement,
  shortTooltipFormat,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils.ts';
import HighChartsContainer from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper.tsx';
import type { MetricInput, MetricOutput } from './RiskMetricsChart.types.ts';
import type { ParameterDescription } from './RiskMetricsParameterService.tsx';
import { IIssue, type IRiskDashboardInputQuery } from '../../../generated/graphql.tsx';
import { convertDateInUtcToUTCISODate } from '../../date.utils.ts';
import { getFormat, getName } from '../../metrics/MetricsData.tsx';
import { formatMetricParameters } from '../../portfolio/riskMetrics.utils.ts';
import type { MetricsData } from './RiskMetricsChart.tsx';
import type { ApolloError } from '@apollo/client';
import { useFinalColorScheme } from '../../../useFinalColorScheme.ts';

const MAX_ASSETS_IN_WARNING = 5;
const MAX_ASSETS_IN_WARNING_TOOLTIP = 30;

function truncateArray(array: string[], maxLength: number): [string, boolean] {
  if (array.length <= maxLength) {
    return [array.join(', '), false];
  }

  const truncatedArray = array.slice(0, maxLength);
  return [`${truncatedArray.join(', ')}...`, true];
}

const countIsAre = (count: number): string => (count > 1 ? 'are' : 'is');
const issueMessageMapping: Record<IIssue, (limitedSymbols: string, count: number) => string> = {
  [IIssue.LimitingHistory]: (limitedSymbols, count) =>
    `${count} assets ${countIsAre(count)} limiting history in the computation: ${limitedSymbols}`,
  [IIssue.Unsupported]: (limitedSymbols, count) =>
    `${count} assets ${countIsAre(count)} excluded in the computation: ${limitedSymbols}`,
  [IIssue.RejectedPrivate]: (limitedSymbols, count) =>
    `${count} assets ${countIsAre(count)} excluded due to lacking history in the computation: ${limitedSymbols}`,
};

const formatMetricWarnings = (output: MetricOutput[]): { warning: string; tooltip: string | undefined }[] => {
  const warnings = [];

  // show only 1 warning for one metric, even when we have several types of the same metric like Volatility Realized and Expected
  const groupedByMetric = groupBy((o) => o.label, output);

  for (const [, metricSeries] of Object.entries(groupedByMetric)) {
    const issueTypeToIssue: Partial<Record<IIssue, Set<string>>> = {};
    for (const issue of metricSeries.flatMap((series) => series.series).flatMap((s) => s.issues)) {
      const type = issue.issueType;
      issueTypeToIssue[type] ??= new Set();
      issueTypeToIssue[type].add(issue.asset.symbol);
    }

    for (const [issueType, symbols] of Object.entries(issueTypeToIssue)) {
      const sortedSymbols = Array.from(symbols).sort();
      const [warningAssets, assetsTruncated] = truncateArray(sortedSymbols, MAX_ASSETS_IN_WARNING);
      const tooltip = assetsTruncated ? truncateArray(sortedSymbols, MAX_ASSETS_IN_WARNING_TOOLTIP)[0] : undefined;
      warnings.push({
        warning: issueMessageMapping[issueType as IIssue](warningAssets, sortedSymbols.length),
        tooltip,
      });
    }
  }

  return warnings;
};

const createSeries = (
  output: MetricOutput[],
  params: ParameterDescription[],
  showPortfolio: boolean
): HighchartSeries[] => {
  return output.flatMap(({ series, label, portfolioDefinition }) =>
    series.map(({ values, parameters }): HighchartSeries => {
      const name = [getName(label), formatMetricParameters(parameters, params)];
      if (showPortfolio && !isNil(portfolioDefinition)) {
        name.unshift(capitalize(portfolioDefinition.name));
      }

      return {
        data: values.map(({ date, value }) => ({
          x: dayjs.utc(date.toString()).valueOf(),
          y: bignumber(value).toNumber(),
          textValue: formatterForName(getFormat(label))(bignumber(value).toNumber()),
        })),
        name: name.join(' '),
        type: 'line',
      };
    })
  );
};

export const RiskMetricsChartInner = (props: {
  groups: IRiskDashboardInputQuery['assets']['assetGroups'];
  metricParameters: Record<Label, ParameterDescription[]>;
  metrics: string[];
  title: string;
  showPortfolio: boolean;
  metricInput: MetricInput;
  onDateSelected?: (date: UtcDate) => void;
  loading: boolean;
  metricsData: MetricsData | undefined;
  error?: ApolloError;
}): ReactElement => {
  const chartRef = React.useRef<null | HighChartRef>(null);
  const colorScheme = useFinalColorScheme();
  const onElementSelectedFactory = (selectedEl: SelectedChartElement): void => {
    if (!props.onDateSelected || !props.metricsData) {
      return;
    }
    props.onDateSelected(convertDateInUtcToUTCISODate(selectedEl.xValue));
  };

  const chartData: HighchartSeries[] = createSeries(
    props.metricsData ?? [],
    props.metricParameters[props.metricInput.metric] ?? [],
    props.showPortfolio
  );

  const warnings = formatMetricWarnings(props.metricsData ?? []);

  const calculateChartData = (): HighchartSeries[] => {
    if (props.onDateSelected) {
      return chartData.map((serie) => ({
        ...serie,
        point: {
          events: {
            select: (event: Highcharts.PointInteractionEventObject) =>
              getPointClickEvent(colorScheme, event, chartRef.current, onElementSelectedFactory),
          },
        },
        allowPointSelect: true,
      }));
    }

    return chartData;
  };

  const metricFormat = getFormat(props.metricInput.metric);
  const calculateOptions = (): Highcharts.Options => {
    return {
      ...dateTimeAxisFormat,
      ...dateTimeExportFormat(kebabCase(props.title)),
      ...shortTooltipFormat,
      chart: {
        events: {
          click: (): void => {
            if (props.onDateSelected && chartRef.current?.chart) {
              getChartClickEvent(colorScheme, chartRef.current?.chart, onElementSelectedFactory);
            }
          },
        },
      },
      yAxis: {
        labels: {
          formatter: getFormatter(metricFormat),
        },
        title: {
          text: undefined,
        },
      },
      plotOptions: {
        line: {
          marker: {
            symbol: 'circle',
          },
        },
      },
    };
  };

  return (
    <Stack spacing={1}>
      {warnings.map(({ warning, tooltip }) => (
        <Alert key={warning} startDecorator={<WarningAmberIcon />} variant="soft" color="warning">
          <Tooltip title={tooltip}>
            <Typography level="body-sm" textColor="text.primary">
              {warning}
            </Typography>
          </Tooltip>
        </Alert>
      ))}
      <Box
        sx={{
          height: chartSize,
        }}
      >
        <HighChartsContainer
          data={chartData}
          loading={props.loading}
          error={props.error}
          calculateOptions={calculateOptions}
          ref={chartRef}
          calculateChartData={calculateChartData}
        />
      </Box>
    </Stack>
  );
};
