import type { KeyCreatorParams, ValueFormatterParams } from 'ag-grid-community';
import { formatCash, formatNumber, formatPercentage } from 'components/formatter.utils';
import dayjs from 'dayjs';
import { caseInsensitiveComparator, getValueFormatterCellValue } from './agGrid.utils';

/** good defaults, but individual columns might need overrides */
export const customColumnTypes = {
  percentageColumn: {
    chartDataType: 'series' as const,
    enableValue: true,
    valueFormatter: (params: ValueFormatterParams): string => formatPercentage(getValueFormatterCellValue(params)),
    filter: 'agNumberColumnFilter',
    keyCreator: (params: KeyCreatorParams): string => formatPercentage(getValueFormatterCellValue(params)),
  },
  cashColumn: {
    chartDataType: 'series' as const,
    enableValue: true,
    valueFormatter: (params: ValueFormatterParams): string => formatCash(getValueFormatterCellValue(params)),
    filter: 'agNumberColumnFilter',
  },
  extendedNumericColumn: {
    chartDataType: 'series' as const,
    enableValue: true,
    valueFormatter: (params: ValueFormatterParams): string => formatNumber(getValueFormatterCellValue(params), 'long'),
    filter: 'agNumberColumnFilter',
  },
  textColumn: {
    enablePivot: true,
    enableRowGroup: true,
    cellDataType: 'text',
    chartDataType: 'category' as const,
    comparator: caseInsensitiveComparator,
  },
  // should be used in combination with dateReadableValueGetter, which returns date in readable format for chart export
  dateColumn: {
    chartDataType: 'category' as const,
    filterParams: {
      comparator: (filterLocalDateAtMidnight: Date, cellValue: Date): number => {
        const first = dayjs(cellValue);
        const second = dayjs(filterLocalDateAtMidnight);
        if (first.isSame(second, 'day')) {
          return 0;
        }
        if (first.isBefore(second)) {
          return -1;
        }

        return 1;
      },
    },
    enablePivot: true,
    enableRowGroup: true,
    filter: 'agDateColumnFilter',
    comparator: (valueA: Date, valueB: Date): number => dayjs(valueA).valueOf() - dayjs(valueB).valueOf(),
    // sort columns headers in pivot mode from oldest to newest
    pivotComparator: (valueA: string, valueB: string): number => dayjs(valueA).valueOf() - dayjs(valueB).valueOf(),
  },
};

export type CustomColumnTypes = keyof typeof customColumnTypes;
