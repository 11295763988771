import { type CellContext, type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import sortBy from 'lodash/fp/sortBy';
import type { FunctionComponent, ReactNode } from 'react';
import GLink from 'components/technical/GLink/GLink';
import GTable from 'components/technical/GTable/GTable';
import GButton from 'components/technical/inputs/GButton/GButton';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import { type IStrategyListQuery, useStrategyListQuery } from 'generated/graphql';

type Strategies = IStrategyListQuery['strategy']['list'][number];

const StrategiesList: FunctionComponent = () => {
  const { data, loaded, Fallback } = useDefaultErrorHandling(useStrategyListQuery());
  if (!loaded) {
    return <Fallback />;
  }

  const sortedData = sortBy((row) => row.name, data.strategy.list);
  const columnHelper = createColumnHelper<Strategies>();

  const columns: ColumnDef<Strategies>[] = [
    {
      header: 'Name',
      accessorKey: 'name',
    },
    {
      header: 'Description',
      accessorKey: 'description',
    },
    columnHelper.display({
      header: 'Actions',
      meta: {
        headerStyles: {
          width: '120px',
        },
      },
      cell: (props: CellContext<Strategies, unknown>): ReactNode => {
        const strategyLabel = props.row.original.label;

        return (
          <GButton
            width={'fullWidth'}
            component={GLink}
            to={`/app/copilot/strategies/${encodeURIComponent(strategyLabel)}`}
          >
            Open
          </GButton>
        );
      },
    }),
  ];
  return <GTable disablePagination columns={columns} data={sortedData} />;
};

export default StrategiesList;
