import { Add } from '@mui/icons-material';
import { Card } from '@mui/joy';
import { Fragment, type ReactElement } from 'react';
import HeaderBar from '../HeaderBar/HeaderBar';
import GButton from '../inputs/GButton/GButton';
import type { GButtonProps } from '../inputs/GButton/GButton.props';
import SectionColumn from '../layout/Column/SectionColumn';

const variants: NonNullable<GButtonProps['variant']>[] = ['plain', 'outlined', 'soft', 'solid'];

const colors: GButtonProps['color'][] = ['primary', 'neutral', 'danger', 'success', 'warning'];

const ButtonPlayground = (): ReactElement => {
  return (
    <SectionColumn>
      <HeaderBar title="Buttons" />
      <Card
        sx={{
          display: 'grid',
          gridTemplateColumns: '100px 100px 1fr 1fr',
          gap: '1rem',
          justifyItems: 'center',
        }}
      >
        <span>Variant</span>
        <span>Color</span>
        <span>Button</span>
        <span>Button disabled</span>

        {variants.flatMap((variant, vi) =>
          colors.map((color, ci) => (
            <Fragment key={`${vi}-${ci}`}>
              <span>{variant}</span>
              <span>{color}</span>
              <GButton
                variant={variant}
                color={color}
                key={`${variant}-${color}`}
                width="minContent"
                startDecorator={<Add />}
              >
                Text
              </GButton>
              <GButton
                variant={variant}
                color={color}
                key={`${variant}-${color}-disabled`}
                width="minContent"
                disabled
                startDecorator={<Add />}
              >
                Text
              </GButton>
            </Fragment>
          ))
        )}
      </Card>
    </SectionColumn>
  );
};

export default ButtonPlayground;
