import type { PortfolioOptimizerInputFields } from '../PortfolioOptimizer.validation.ts';
import PortfolioUniverseStep from './PortfolioUniverseStep.tsx';
import type { Portfolio } from '../PortfolioOptimizerWizard.tsx';
import type { StepConfig } from '../../../../../technical/wizard/StepConfig.ts';

export const config = (
  optimizableFunds: Portfolio[],
  goToNextStep: () => void
): StepConfig<PortfolioOptimizerInputFields> => ({
  label: 'Portfolios universe',
  element: <PortfolioUniverseStep optimizableFunds={optimizableFunds} goToNextStep={goToNextStep} />,
  fields: ['universe'],
});
