import * as yup from 'yup';
import type { Schema } from 'yup';

import type { FormInputType } from 'components/technical/form/Form.types.ts';
import { IObjectiveType } from '../../../../../generated/graphql.tsx';
import type { AssetLabelInput } from '../../../../market/asset/AssetLabelService.ts';
import { PORTFOLIO_EXPECTED_BETA_METRIC } from '../../../../metrics/PortfolioRiskMeasures.ts';

export interface ObjectivesStepOutput {
  portfolioAmount: number;
  allowShortAndLeverage: boolean;
  objectives: {
    type: IObjectiveType;
    riskMetric: Label;
    benchmark?: (AssetLabelInput & { label: string }) | null | undefined;
  }[];
  objectiveLength?: unknown;
}

export type ObjectivesStepInput = FormInputType<Omit<ObjectivesStepOutput, 'objectives'>> & {
  objectives: {
    type: IObjectiveType | null;
    riskMetric: Label | null;
    benchmark: AssetLabelInput | null;
  }[];
};

export const schema: Schema<unknown> = yup.object({
  portfolioAmount: yup.number().required().positive(),
  allowShortAndLeverage: yup.boolean().required(),
  objectives: yup
    .array(
      yup.object({
        type: yup.string().required().oneOf(Object.values(IObjectiveType)),
        riskMetric: yup.string().required(),
        benchmark: yup.mixed().when('riskMetric', {
          is: PORTFOLIO_EXPECTED_BETA_METRIC,
          // biome-ignore lint/suspicious/noThenProperty: yup valid property
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.optional().nullable(),
        }),
      })
    )
    .required(),
  objectiveLength: yup
    .mixed()
    .nullable()
    .optional()
    .when('objectives', ([field], schema) =>
      schema.test('atLeastOneObjective', 'At least one objective is required', () => field.length >= 1)
    ),
});
