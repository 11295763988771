import { Stack } from '@mui/joy';
import type { ReactElement } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import AddButton from 'components/technical/AddButton.tsx';
import FormRadioGroup from 'components/technical/form/FormRadioGroup.tsx';
import GButton from 'components/technical/inputs/GButton/GButton.tsx';
import { IConstraintFormulation } from '../../../../../generated/graphql.tsx';
import { defaultRowSpacing } from '../../../../StackSpacing.ts';

import { ConstraintType } from '../ConstraintTypeValues.validation.ts';
import type { PortfolioOptimizerInputFields } from '../portfolio/PortfolioOptimizer.validation.ts';
import type { AssetGroup } from '../asset/AssetOptimizerWizard.tsx';
import type { AssetOptimizerInputFields } from '../asset/AssetOptimizer.validation.ts';
import AllocationRow from './AllocationRow.tsx';
import StepDescription from '../../../../technical/wizard/StepDescription.tsx';

const AllocationConstraintsStep = ({
  goToNextStep,
  assetGroups,
  assetIdToClusterToGroup,
  type,
}: {
  goToNextStep: () => void;
  assetGroups: AssetGroup[];
  assetIdToClusterToGroup: Record<string, Record<string, string>>;
  type: 'asset' | 'portfolio';
}): ReactElement => {
  const { formState } = useFormContext<AssetOptimizerInputFields | PortfolioOptimizerInputFields>();

  const {
    fields: constraints,
    remove,
    append,
  } = useFieldArray<AssetOptimizerInputFields | PortfolioOptimizerInputFields, 'constraints'>({
    name: 'constraints',
  });

  return (
    <Stack spacing={defaultRowSpacing}>
      {type === 'asset' ? (
        <StepDescription>
          Here you can specify constraints on allocation on single assets, sectors or any custom asset group. You can
          express limits to their allocation, contribution to portfolio risk, return metrics
        </StepDescription>
      ) : (
        <StepDescription>
          Here you can specify constraints on allocation on a portfolio. You can express limits to their allocation,
          contribution to portfolio risk, return metrics
        </StepDescription>
      )}
      <Stack direction="row" spacing={1.5}>
        <FormRadioGroup<AssetOptimizerInputFields | PortfolioOptimizerInputFields>
          label="Express constraints as:"
          name="constraintType"
          width="xl2"
          options={[
            {
              label: 'Percentage',
              value: IConstraintFormulation.Percentage,
              key: 'percentage',
            },
            {
              label: 'Dollar value',
              value: IConstraintFormulation.AbsoluteValue,
              key: 'dollar',
            },
          ]}
        />
      </Stack>
      <Stack direction="column" spacing={1.5} alignItems="flex-start">
        {constraints.map((constraint, index) => (
          <AllocationRow
            type={type}
            key={constraint.id}
            index={index}
            groups={assetGroups}
            assetIdToClusterToGroup={assetIdToClusterToGroup}
            onRemove={(): void => {
              remove(index);
            }}
          />
        ))}
        <AddButton
          disabled={formState.isSubmitting}
          onClick={(): void => {
            append({
              item: null,
              constrainedQuantity: null,
              constraintType: ConstraintType.Equal,
              constraintValue: {
                value: null,
                min: null,
                max: null,
              },
            });
          }}
        >
          Add new allocation
        </AddButton>
      </Stack>

      <GButton
        onClick={goToNextStep}
        sx={{
          marginLeft: 'auto',
        }}
      >
        Next
      </GButton>
    </Stack>
  );
};

export default AllocationConstraintsStep;
