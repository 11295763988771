import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import type { CellContext } from '@tanstack/table-core';
import sortBy from 'lodash/fp/sortBy';
import { bignumber } from 'mathjs';
import type { ReactElement } from 'react';

import GTable from 'components/technical/GTable/GTable.tsx';
import GTableSkeleton from 'components/technical/GTable/GTableSkeleton.tsx';
import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import SectionColumn from 'components/technical/layout/Column/SectionColumn';
import type { IPerformStressTestQuery } from '../../../../generated/graphql';
import { formatCash, formatPercentage } from '../../../formatter.utils';
import AssetLabel from '../../../market/asset/AssetLabel';

const AssetTableHeaders = ['Asset', 'Balance'];
type AssetResult = IPerformStressTestQuery['stressTest']['stressTest']['logicResult']['assetResults'][number];

export const StressTestPortfolioComposition = ({
  result,
}: {
  result: AssetResult[] | undefined;
}): ReactElement => {
  const columnHelper = createColumnHelper<AssetResult>();

  const columns: ColumnDef<AssetResult>[] = [
    columnHelper.display({
      header: 'Position',
      cell: (props: CellContext<AssetResult, unknown>) => <AssetLabel asset={props.row.original.asset} link={false} />,
    }),
    columnHelper.display({
      header: 'Position return',
      cell: (cellProps: CellContext<AssetResult, unknown>) =>
        !cellProps.row.original ? '' : formatPercentage(cellProps.row.original.meanReturn),
    }),
    columnHelper.display({
      header: 'Equity change',
      cell: (cellProps: CellContext<AssetResult, unknown>) =>
        !cellProps.row.original ? '' : formatCash(cellProps.row.original.meanEquityChange),
    }),
  ];

  return (
    <SectionColumn>
      <HeaderBar title="Portfolio composition" />
      {result ? (
        <GTable
          columns={columns}
          data={sortBy((result) => -bignumber(result.meanEquityChange).toNumber(), result)}
          hideBorders
          disablePagination
        />
      ) : (
        <GTableSkeleton headers={AssetTableHeaders} />
      )}
    </SectionColumn>
  );
};
