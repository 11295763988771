import { formatISODate } from 'components/formatter.utils';
import dayjs, { type Dayjs } from 'dayjs';
import isNil from 'lodash/fp/isNil';

export const canBeSelected = (
  minDate: Dayjs | null | undefined,
  maxDate: Dayjs | null | undefined,
  dateRange: [Dayjs, Dayjs] | null | undefined
): boolean => {
  if (isNil(dateRange)) {
    return true;
  }

  if (!isNil(minDate) && minDate.isAfter(dateRange[0])) {
    return false;
  }

  if (!isNil(maxDate) && maxDate.isBefore(dateRange[0])) {
    return false;
  }

  return true;
};

export const ensureDateRangeWithinBounds = (
  minDate: Dayjs | null | undefined,
  maxDate: Dayjs | null | undefined,
  range: [Dayjs, Dayjs] | null
): [Dayjs, Dayjs] | null => {
  if (range === null) {
    return null;
  }

  const [start, end] = range;
  const startOrMinDate = isNil(minDate) ? start : (dayjs.max(minDate, start) as Dayjs);
  const endOrMaxDate = isNil(maxDate) ? end : (dayjs.min(maxDate, end) as Dayjs);
  return [startOrMinDate, endOrMaxDate];
};

export const convertDateRangeToSinceToDate = (
  dateRange: [Dayjs, Dayjs] | null
): {
  since: UtcDate | null;
  to: UtcDate | null;
} => {
  return {
    since: isNil(dateRange) ? null : formatISODate(dateRange[0]),
    to: isNil(dateRange) ? null : formatISODate(dateRange[1]),
  };
};

export const convertDateRangeToSinceToDateTime = (
  dateRange: [Dayjs, Dayjs] | null
): {
  since: string | null;
  to: string | null;
} => {
  return {
    since: isNil(dateRange) ? null : dateRange[0].toISOString(),
    to: isNil(dateRange) ? null : dateRange[1].toISOString(),
  };
};
