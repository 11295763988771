import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';

import { useSearchableAssetIdsQuery, useSearchableAssetsQuery } from '../../../../generated/graphql';
import {
  type DerivativeAsset,
  isDerivativeAsset,
  isPrivateAsset,
  isPublicAsset,
  type PrivateAsset,
  type PublicAsset,
} from '../../../market/asset/Asset.types';

export const useSearchableAssets = (): (PublicAsset | DerivativeAsset | PrivateAsset)[] => {
  const { data, loaded } = useDefaultErrorHandling(useSearchableAssetsQuery());

  if (!loaded) {
    return [];
  }

  return data.assets.feature.filter(
    (asset): asset is PublicAsset | DerivativeAsset | PrivateAsset =>
      isPublicAsset(asset) || isDerivativeAsset(asset) || isPrivateAsset(asset)
  );
};

export const useSearchableAssetsIds = (): Set<string> => {
  const { data, loaded } = useDefaultErrorHandling(useSearchableAssetIdsQuery());

  if (!loaded) {
    return new Set();
  }

  return new Set(data.assets.feature.map((asset) => asset.id));
};
