import { bignumber } from 'mathjs';
import type { ReactElement } from 'react';
import { formatPercentage } from 'components/formatter.utils.ts';

import {
  getHighchartColorWithOpacity,
  type HighchartSeries,
  percentageFormatter,
  returnProbabilityTooltipFormatter,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils.ts';
import HighChartsWrapper from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper.tsx';
import type * as Highcharts from 'highcharts';
import { useFinalColorScheme } from '../../../../useFinalColorScheme.ts';
const calculateOptions = (returnsNumber: number): Highcharts.Options => {
  return {
    xAxis: {
      title: { text: 'Return' },
      labels: {
        formatter: percentageFormatter,
      },
    },
    tooltip: {
      formatter: ({ chart }): string => {
        return returnProbabilityTooltipFormatter({ chart, returnsNumber });
      },
    },
    yAxis: {
      title: { text: 'Probability' },
      labels: {
        formatter: ({ value }): string => {
          return `${formatPercentage(bignumber(value).div(returnsNumber).toNumber())}`;
        },
      },
    },
    legend: {
      enabled: true,
    },
    plotOptions: {
      histogram: {
        binsNumber: 20,
      },
    },
  };
};

export const PortfolioReturnsPerformanceChart = (props: {
  returns: { title: string; data: number[] }[];
}): ReactElement => {
  const colorScheme = useFinalColorScheme();
  if (props.returns.length === 0) {
    return <div>No data</div>;
  }

  return (
    <HighChartsWrapper<number[]>
      loading={false}
      key={props.returns.map((r) => r.title).join('-')} // for some reason chart can break on data change, remount helps
      calculateOptions={(): Highcharts.Options => calculateOptions(props.returns[0].data.length)}
      calculateChartData={(): HighchartSeries[] =>
        props.returns.flatMap((returns, index): HighchartSeries[] => [
          {
            // invisible scatter series is used as data input to build histogram
            data: returns.data,
            visible: false,
            id: returns.title,
            showInLegend: false,
          },
          {
            name: returns.title,
            baseSeries: returns.title,
            // 2nd histogram is slightly transparent to prevent one series from obscuring the other completely
            color: getHighchartColorWithOpacity(colorScheme, index, index === 0 ? 1 : 0.55),
            type: 'histogram',
            showInLegend: props.returns.length > 1,
          },
        ])
      }
      data={props.returns[0].data}
    />
  );
};
