import { Stack } from '@mui/joy';
import type { FunctionComponent } from 'react';
import ActionsContextProvider from 'components/technical/actions/ActionsContextProvider.tsx';
import LineChartSkeleton from 'components/technical/charts/LineChart/LineChartSkeleton';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';

import { CHART_TITLE as EXPOSURE_CHART_TITLE } from './ExposureChart';
import ExposureContainer from './ExposureChart';
import PerformanceAttributionChart, { ATTRIBUTION_CHART_TITLE } from './PerformanceAttributionChart';
import ProfitLossChart, { CHART_TITLE as PROFIT_LOSS_CHART_TITLE } from './ProfitLossChart';
import { useProfitLossPerformanceInputQuery } from '../../../generated/graphql';
import { ChartSkeleton } from '../../ChartSkeleton.tsx';

const ProfitAndLossPerformance: FunctionComponent = () => {
  const { loaded, Fallback, data, refetch } = useDefaultErrorHandling(useProfitLossPerformanceInputQuery());

  if (!loaded) {
    return <Fallback />;
  }

  const subAccounts = data.portfolio.accounts.flatMap((acc) => acc.subAccounts);

  return subAccounts.length === 0 ? (
    <>
      <ChartSkeleton title={PROFIT_LOSS_CHART_TITLE} refetch={refetch} skeletonType={<LineChartSkeleton />} />
      <ChartSkeleton title={EXPOSURE_CHART_TITLE} refetch={refetch} skeletonType={<LineChartSkeleton />} />
      <ChartSkeleton title={ATTRIBUTION_CHART_TITLE} refetch={refetch} skeletonType={<LineChartSkeleton />} />
    </>
  ) : (
    <Stack spacing={1.5}>
      <ActionsContextProvider>
        <ProfitLossChart />
      </ActionsContextProvider>
      <ActionsContextProvider>
        <ExposureContainer />
      </ActionsContextProvider>
      <ActionsContextProvider>
        <PerformanceAttributionChart
          supportedClusters={data.assets.clusterByUser}
          supportedTimeWindow={data.factorRegression.supportedTimeWindow.supportedTimeWindow}
        />
      </ActionsContextProvider>
    </Stack>
  );
};

export default ProfitAndLossPerformance;
