import { Card } from '@mui/joy';
import dayjs from 'dayjs';
import type * as Highcharts from 'highcharts';
import isNil from 'lodash/fp/isNil';
import { bignumber } from 'mathjs';
import { type ReactElement, useState } from 'react';
import { formatCash, formatISODate } from 'components/formatter.utils';
import { getDefaultRange } from 'components/predefinedDateRanges';
import {
  cashFormatter,
  dateTimeAxisFormat,
  getHighchartColor,
  type HighchartSeries,
  highChartsNeutralColorIndex,
  tooltipFormat,
  areaChartOpacity,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import HighChartsContainer from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper';
import PredefinedDateRangeButtons from 'components/technical/inputs/date/PredefinedDateRangeButtons';

import {
  type IPublicAssetForecastChartQuery,
  usePublicAssetForecastChartQuery,
} from '../../../../../../generated/graphql';
import type { PublicAsset } from '../../../Asset.types';
import { useFinalColorScheme } from '../../../../../../useFinalColorScheme.ts';

const calculateChartData = (colorScheme: 'dark' | 'light', data: IPublicAssetForecastChartQuery): HighchartSeries[] => {
  const history = (data.assets.price.at(0)?.rows ?? []).filter((row) => !isNil(row.price));
  const forecast = data.assets.forecast;

  const historyTrace = {
    data: history.map(({ date, price }) => ({
      x: dayjs.utc(date.toString()).valueOf(),
      y: bignumber(price).toNumber(),
      textValue: formatCash(bignumber(price).toNumber()),
    })),
    name: 'Price',
    type: 'line',
  } satisfies HighchartSeries;
  const lastDatapoint = historyTrace.data.at(-1);
  if (lastDatapoint === undefined) {
    return [];
  }

  const lastDatapointRange = {
    x: lastDatapoint.x,
    low: lastDatapoint.y,
    high: lastDatapoint.y,
    textValue: `${formatCash(lastDatapoint.y)} - ${formatCash(lastDatapoint.y)}`,
  };

  const priceDate = dayjs.utc(lastDatapoint.x);
  const onlyFutureForecast = forecast.filter((forecastedDay) =>
    priceDate.isBefore(dayjs.utc(forecastedDay.date.toString()))
  );

  const futureTrace: HighchartSeries = {
    data: [
      lastDatapoint,
      ...onlyFutureForecast.map(({ date, value }) => ({
        x: dayjs.utc(date.toString()).valueOf(),
        y: bignumber(value).toNumber(),
        textValue: formatCash(bignumber(value).toNumber()),
      })),
    ],
    name: 'Price forecast',
    color: getHighchartColor(colorScheme, highChartsNeutralColorIndex),
    type: 'line',
  };

  const rangeTrace: HighchartSeries = {
    data: [
      lastDatapointRange,
      ...onlyFutureForecast.map(({ date, lowConfidence, highConfidence }) => ({
        x: dayjs.utc(date.toString()).valueOf(),
        low: bignumber(lowConfidence).toNumber(),
        high: bignumber(highConfidence).toNumber(),
        textValue: `${formatCash(bignumber(lowConfidence).toNumber())} - ${formatCash(
          bignumber(highConfidence).toNumber()
        )}`,
      })),
    ],
    name: 'Confidence range',
    color: getHighchartColor(colorScheme, highChartsNeutralColorIndex),
    type: 'arearange',
  };

  return onlyFutureForecast.length > 0 ? [historyTrace, futureTrace, rangeTrace] : [historyTrace];
};

const calculateOptions = (): Highcharts.Options => {
  return {
    ...dateTimeAxisFormat,
    ...tooltipFormat,
    yAxis: {
      labels: {
        formatter: cashFormatter,
      },
    },
    plotOptions: {
      arearange: {
        fillOpacity: areaChartOpacity,
      },
    },
  };
};

const PublicAssetForecastChart = ({ asset }: { asset: PublicAsset }): ReactElement => {
  const colorScheme = useFinalColorScheme();
  const defaultDateRange = getDefaultRange();
  const [dateRange, setDateRange] = useState(defaultDateRange);

  const dateRangeDates = dateRange.value();

  const queryOutput = usePublicAssetForecastChartQuery({
    variables: {
      asset: asset.label,
      assetId: asset.id,
      fromDate: isNil(dateRangeDates) ? null : formatISODate(dateRangeDates[0]),
    },
  });

  return (
    <Card>
      <PredefinedDateRangeButtons value={dateRange} onChange={setDateRange} />
      <HighChartsContainer<IPublicAssetForecastChartQuery>
        {...queryOutput}
        calculateOptions={calculateOptions}
        calculateChartData={(data) => calculateChartData(colorScheme, data)}
      />
    </Card>
  );
};

export default PublicAssetForecastChart;
