import type { ReactElement } from 'react';
import { Controller, type FieldValues, type Path, useFormContext } from 'react-hook-form';

import GCheckbox from '../inputs/GCheckbox/GCheckbox';
import type { GCheckboxProps } from '../inputs/GCheckbox/GCheckbox.props';

function FormCheckbox<T extends FieldValues = FieldValues, N extends Path<T> = Path<T>>(
  props: {
    name: N;
  } & Pick<GCheckboxProps, 'label' | 'disabled'>
): ReactElement {
  const { control } = useFormContext();

  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field: { value, onChange, onBlur, ref }, formState }): ReactElement => (
        <GCheckbox
          {...props}
          ref={ref}
          checked={value}
          onChange={onChange}
          onBlur={onBlur}
          width="xl2"
          disabled={(props.disabled ?? false) || formState.isSubmitting || formState.disabled}
        />
      )}
    />
  );
}

export default FormCheckbox;
