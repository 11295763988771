import { Add } from '@mui/icons-material';
import { Modal, ModalDialog, Stack } from '@mui/joy';
import type { Dayjs } from 'dayjs';
import type { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import type { FormInputType } from 'components/technical/form/Form.types';
import { FormDateTimeInput } from 'components/technical/form/FormDateTimeInput';
import FormStaticMultiAutocomplete from 'components/technical/form/FormStaticMultiAutocomplete';
import GFormProvider from 'components/technical/form/GFormProvider';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton';
import { useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError.tsx';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import { FormInput } from 'components/technical/inputs';
import { CalendarEventsDocument, type IAsset, useCreateCalendarEventMutation } from '../../../../../generated/graphql';
import { isValidDayjsDate } from '../../../../date.utils';
import type { Asset } from '../../Asset.types';
import { createAssetSelectOptions } from '../../AssetService';

interface FormOutputFields {
  assets: Omit<IAsset, 'derivativeDetails' | 'unvestedAsset' | 'priceAsset'>[];
  time: Dayjs;
  title: string;
  description: string;
}

type FormInputFields = FormInputType<FormOutputFields>;

const formSchema = yup.object({
  assets: yup.array().of(yup.mixed().required()).required().min(1),
  time: yup.mixed().required().test('validDate', 'Date is invalid', isValidDayjsDate),
  title: yup.string().required().max(64),
  description: yup.string(),
});

export const CreateEventDialog = (props: {
  onClose: () => void;
  onAdded: () => void;
  assets: Asset[];
  primaryAsset: Asset;
}): ReactElement => {
  const assetOptions = createAssetSelectOptions(props.assets);

  const methods = useForm<FormInputFields>({
    resolver: gYupResolver(formSchema),
    defaultValues: {
      assets: [props.primaryAsset],
      time: null,
      title: '',
      description: '',
    },
  });

  const { onErrorAndThrow } = useGraphQLApiError(methods);
  const { showSuccessMessage } = useFeedback();

  const [createEvent] = useCreateCalendarEventMutation({
    refetchQueries: [CalendarEventsDocument],
  });

  const handleFormSubmit = async (input: FormInputFields): Promise<void> => {
    const formOutput = input as unknown as FormOutputFields;
    try {
      await createEvent({
        variables: {
          input: {
            time: formOutput.time.toISOString(),
            description: formOutput.description,
            title: formOutput.title,
            assets: formOutput.assets.map((asset) => ({ id: asset.id })),
          },
        },
      });

      showSuccessMessage('New event successfully added');

      props.onAdded();
    } catch (e) {
      onErrorAndThrow(e);
    }
  };

  const inputWidth = 'xl2';
  return (
    <Modal open={true} onClose={props.onClose}>
      <ModalDialog>
        <GFormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
            <GDialogHeader>Add event</GDialogHeader>
            <Stack alignItems="center" spacing={1.5}>
              <FormStaticMultiAutocomplete<FormInputFields>
                {...assetOptions}
                name="assets"
                label="Assets"
                width={inputWidth}
              />
              <FormDateTimeInput<FormInputFields> label="Date and time (UTC)" name="time" width={inputWidth} />
              <FormInput label="Title" name="title" width={inputWidth} />
              <FormInput label="Description" name="description" width={inputWidth} />

              <GraphQLApiFormErrorMessage />
              <SubmitButton width="normal" startDecorator={<Add />}>
                Add new event
              </SubmitButton>
            </Stack>
          </form>
        </GFormProvider>
      </ModalDialog>
    </Modal>
  );
};
