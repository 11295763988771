import { Stack } from '@mui/joy';
import sortBy from 'lodash/fp/sortBy';
import type { ReactElement } from 'react';
import { useForm } from 'react-hook-form';

import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import FormCheckbox from 'components/technical/form/FormCheckbox';
import GFormProvider from 'components/technical/form/GFormProvider';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import SubmitButton from 'components/technical/form/SubmitButton';
import { useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError.tsx';
import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import SectionColumn from 'components/technical/layout/Column/SectionColumn';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import { type NotificationCategory, notificationData } from './Notification.data';
import {
  useNotificationSubscriptionsQuery,
  useUpdateNotificationSubscriptionsMutation,
} from '../../../generated/graphql';

type FormState = {
  [key in NotificationCategory]: boolean;
};

const NotificationSettings = (props: { selectedCategories: string[] }): ReactElement => {
  const methods = useForm<FormState>({
    defaultValues: Object.fromEntries(
      Object.keys(notificationData).map((key) => [key, props.selectedCategories.includes(key)])
    ),
  });

  const { onErrorAndThrow } = useGraphQLApiError(methods);
  const [updateNotifications] = useUpdateNotificationSubscriptionsMutation();
  const { showSuccessMessage } = useFeedback();

  const handleFormSubmit = async (data: FormState): Promise<void> => {
    const selectedNotifications = Object.entries(data)
      .filter((val) => val[1])
      .map((val) => val[0].toUpperCase());

    try {
      await updateNotifications({
        variables: {
          categories: selectedNotifications,
        },
      });

      showSuccessMessage('Notification preferences saved');
    } catch (e) {
      onErrorAndThrow(e);
    }
  };

  return (
    <GFormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
        <SectionColumn>
          <HeaderBar title="Notifications" />
          <Stack spacing={1.5}>
            {sortBy((key) => notificationData[key as NotificationCategory].name, Object.keys(notificationData)).map(
              (notif) => {
                const typeSafeNotif = notif as NotificationCategory;
                const categoryData = notificationData[typeSafeNotif];
                return (
                  <FormCheckbox
                    name={notif}
                    label={
                      <Stack direction="row" spacing={1}>
                        <categoryData.icon fontSize="lg" />
                        <span>{categoryData.name}</span>
                      </Stack>
                    }
                    key={notif}
                  />
                );
              }
            )}
            <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
              <Stack alignItems="flex-end" spacing={1.5}>
                <GraphQLApiFormErrorMessage />
                <SubmitButton>Update preferences</SubmitButton>
              </Stack>
            </Stack>
          </Stack>
        </SectionColumn>
      </form>
    </GFormProvider>
  );
};

const NotificationSettingsContainer = (): ReactElement => {
  const { loaded, Fallback, data } = useDefaultErrorHandling(useNotificationSubscriptionsQuery());
  if (!loaded) {
    return <Fallback />;
  }

  return (
    <NotificationSettings
      selectedCategories={data.notification.subscription.map((sub) => sub.category.toUpperCase())}
    />
  );
};

export default NotificationSettingsContainer;
