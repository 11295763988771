import { OptimizationType } from '../optimization.utils';
import { Tab, TabList, TabPanel, Tabs } from '@mui/joy';
import OptimizerInputForResultTab from './inputForResults/OptimizerInputForResultTab.tsx';
import { type ReactElement, useEffect, useState } from 'react';
import {
  IPortfolioOptimizationResultStatusType,
  useAssetOptimizerResultQuery,
  usePortfolioOptimizerResultQuery,
} from '../../../../generated/graphql.tsx';
import { useParams } from 'react-router';
import OptimizerResult from './OptimizerResult.tsx';
import { GraphQLErrorCardMessage } from '../../../technical/form/GraphQLApiErrorMessage.tsx';
import Loader from '../../../technical/Loader/Loader.tsx';
import { useReportAssetGroup } from '../../../UseReportAssetGroups.tsx';

const REFRESH_S = 20;
const OptimizerResultDashboard = ({ optimizationType }: { optimizationType: OptimizationType }): ReactElement => {
  const [tabIndex, setTabIndex] = useState(1);
  const { optimizationId } = useParams<{ optimizationId: string }>();
  const parsedOptimizationId = Number.parseFloat(optimizationId ?? '');
  const [pollIntervalS, setPollIntervalS] = useState(0);
  if (Number.isNaN(parsedOptimizationId)) {
    throw new Error(`Optimization id is not a number: ${optimizationId}`);
  }

  const sharedOptions = {
    variables: {
      optimizationId: parsedOptimizationId,
    },
    pollInterval: pollIntervalS * 1000,
  };

  const assetQuery = useAssetOptimizerResultQuery({
    ...sharedOptions,
    skip: optimizationType === OptimizationType.portfolio,
  });

  const portfolioQuery = usePortfolioOptimizerResultQuery({
    ...sharedOptions,
    skip: optimizationType === OptimizationType.asset,
  });

  const assetGroups = useReportAssetGroup({
    skip: optimizationType === OptimizationType.portfolio,
  });

  const query = optimizationType === OptimizationType.asset ? assetQuery : portfolioQuery;

  useEffect((): void => {
    if (!query.data) {
      return;
    }

    const status = query.data.optimization.getOptimization.status;
    const expectedRefreshTime = [
      IPortfolioOptimizationResultStatusType.Pending,
      IPortfolioOptimizationResultStatusType.InProgress,
    ].includes(status)
      ? REFRESH_S
      : 0;

    if (expectedRefreshTime !== pollIntervalS) {
      setPollIntervalS(expectedRefreshTime);
    }
  }, [query, pollIntervalS]);

  if (!assetGroups.loaded && optimizationType === OptimizationType.asset) {
    return <assetGroups.Fallback />;
  }

  if (query.error) {
    return <GraphQLErrorCardMessage error={query.error} />;
  }

  if (!query.previousData && query.loading) {
    return <Loader />;
  }

  const finalData = query.data ?? query.previousData!;

  return (
    <Tabs
      value={tabIndex}
      onChange={(_e, val) => {
        setTabIndex(val as number);
      }}
    >
      <TabList>
        <Tab>Input</Tab>
        <Tab>Result</Tab>
      </TabList>
      <TabPanel value={0} keepMounted={true}>
        <OptimizerInputForResultTab
          data={finalData}
          optimizationType={optimizationType}
          clusters={assetGroups.clusters ?? []}
          assetAndGroupClusterMapToGroup={assetGroups?.assetAndGroupClusterMapToGroup}
        />
      </TabPanel>
      <TabPanel value={1} keepMounted={true}>
        <OptimizerResult
          optimization={finalData.optimization.getOptimization}
          optimizationType={optimizationType}
          assetGroups={assetGroups.groups}
        />
      </TabPanel>
    </Tabs>
  );
};

export default OptimizerResultDashboard;
