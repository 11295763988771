import type { Dayjs } from 'dayjs';
import { type FunctionComponent, useState } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import { fixedForwardRef } from 'components/technical/fixedForwardRef';
import type { BaseDateInputProps } from './BaseDateInputProps';
import { DateInputDropdown } from './DateInputDropdown.tsx';
import DateRangeInputCalendar from './DateRangeInputCalendar';
import { defaultCalendarDateRanges } from '../../../predefinedDateRanges';

export type DateRangeInputProps = BaseDateInputProps & {
  value: [Dayjs, Dayjs] | null;
  onChange?: (val: [Dayjs, Dayjs] | null) => void;
};

const DateRangeInput: FunctionComponent<DateRangeInputProps> = (props, ref) => {
  const { onChange, minDate, maxDate, value, ...rest } = props;
  const [open, setOpen] = useState(false);

  return (
    <DateInputDropdown
      {...rest}
      value={value}
      setOpen={setOpen}
      open={open}
      showClearable
      format={'dateRange'}
      onChange={onChange}
      ref={ref}
    >
      <DateRangeInputCalendar
        disabled={props.disabled}
        minDate={minDate}
        maxDate={maxDate}
        defaultValue={value}
        predefinedRanges={defaultCalendarDateRanges}
        onConfirm={(newValue): void => {
          onChange?.(newValue);
          setOpen(false);
        }}
        onCancel={(): void => {
          setOpen(false);
        }}
      />
    </DateInputDropdown>
  );
};

const ForwardedDateRangeInput = fixedForwardRef(DateRangeInput);

export default ForwardedDateRangeInput;
