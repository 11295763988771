import { type IPortfolioDefinition, IPortfolioDefinitionSubType } from '../../../../../generated/graphql.tsx';
import type { NotVerifiedAsset } from '../../../../market/asset/AssetLabelService.ts';
import { memo, type ReactElement } from 'react';
import { useNavigate } from 'react-router';
import GCard from '../../../../technical/GCard/GCard.tsx';
import { calculateItemProps } from '../PortfolioDefList.utils.ts';
import { PortfolioDefTile } from '../PortfolioDefTile.tsx';
import UpdatePortfolioDialogItem from '../update/UpdatePortfolioDialogItem.tsx';
import { MenuItem } from '@mui/joy';
import { AssetIconList } from '../../../../market/asset/AssetIconList.tsx';
import { PortfolioDescriptionText } from '../PortfolioDescriptionText.tsx';
import PortfolioCreatedAtText from '../PortfolioCreatedAt.tsx';

export const gridWidth = { sm: 12, md: 6, lg: 3 };

const RebalancedPortfolioCard = memo(
  (props: {
    height: string;
    selected: boolean;
    def: Pick<IPortfolioDefinition, 'createdAt' | 'description' | 'id' | 'name' | 'type' | 'genie' | 'subType'> & {
      initialAssetComposition: {
        asset: NotVerifiedAsset;
      }[];
    };
  }): ReactElement => {
    const navigate = useNavigate();
    return (
      <GCard height={props.height} gridWidth={gridWidth} {...calculateItemProps(props.selected, props.def.id)}>
        <PortfolioDefTile
          name={props.def.name}
          id={props.def.id}
          hideAllActions={props.def.genie}
          extraActions={
            <>
              <UpdatePortfolioDialogItem def={{ ...props.def, realDefAccounts: [], realDefSubFunds: [] }} />
              {props.def.subType === IPortfolioDefinitionSubType.Default && (
                <MenuItem
                  onClick={() => {
                    navigate(`/app/copilot/lab/portfolio/${props.def.id}/rebalancing-rules`);
                  }}
                >
                  Rebalancing rules
                </MenuItem>
              )}
              {props.def.subType === IPortfolioDefinitionSubType.PositionBased && (
                <MenuItem
                  onClick={() => {
                    navigate(`/app/copilot/lab/portfolio/${props.def.id}/import-positions`);
                  }}
                >
                  Import positions
                </MenuItem>
              )}
            </>
          }
        >
          <AssetIconList assets={props.def.initialAssetComposition?.map((comp) => comp.asset) ?? []} maxItems={6} />
          <PortfolioDescriptionText definition={props.def} />
          {!props.def.genie && <PortfolioCreatedAtText createdAt={props.def.createdAt} />}
        </PortfolioDefTile>
      </GCard>
    );
  }
);

export default RebalancedPortfolioCard;
