import type { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import FormInput from 'components/technical/form/FormInput';
import FormSelect from 'components/technical/form/FormSelect';
import FormStaticSingleAutocomplete from 'components/technical/form/FormStaticSingleAutocomplete';
import RemoveButton from 'components/technical/RemoveButton';

import { type OptionsStressTestInputFields, positionSideValues } from './OptionsStressTest.types';
import type { AssetLabelInput } from '../../market/asset/AssetLabelService.ts';
import { createAssetSelectOptions } from '../../market/asset/AssetService';

interface OptionsStressTestPositionRowProps {
  index: number;
  onRemove: () => void;
  supportedOptions: (AssetLabelInput & { id: string; name?: string | null })[];
}

export const OptionsStressTestPositionRow = ({
  index,
  onRemove,
  supportedOptions,
}: OptionsStressTestPositionRowProps): ReactElement => {
  const { formState } = useFormContext<OptionsStressTestInputFields>();
  const pathPrefix = `positions.${index}` as const;
  const selectOptions = createAssetSelectOptions(supportedOptions);

  return (
    <>
      <FormStaticSingleAutocomplete<OptionsStressTestInputFields>
        name={`${pathPrefix}.asset` as const}
        width="xl3"
        label="Select a contract"
        {...selectOptions}
      />
      <FormInput<OptionsStressTestInputFields>
        name={`${pathPrefix}.amount` as const}
        type="number"
        width="normal"
        placeholder="Add amount"
      />
      <FormSelect<OptionsStressTestInputFields>
        name={`${pathPrefix}.side` as const}
        width="normal"
        options={positionSideValues}
      />
      <FormInput<OptionsStressTestInputFields>
        name={`${pathPrefix}.premium` as const}
        type="number"
        width="normal"
        startDecorator="$"
      />
      <RemoveButton
        disabled={formState.isSubmitting}
        onClick={(): void => {
          onRemove();
        }}
      />
    </>
  );
};
