import { Add } from '@mui/icons-material';
import { Modal, ModalDialog, Stack } from '@mui/joy';

import type { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { DateTimeFormat, formatDate } from 'components/formatter.utils';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import type { FormInputType } from 'components/technical/form/Form.types';
import GFormProvider from 'components/technical/form/GFormProvider';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton';
import { useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError.tsx';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import { FormInput } from 'components/technical/inputs';
import { AssetPriceChartDocument, useCreateUpdateAssetPriceMutation } from '../../../../../../generated/graphql';

interface FormOutputFields {
  price: number;
}

type FormInputFields = FormInputType<FormOutputFields>;

const formSchema = yup.object({
  price: yup.number().required().positive(),
});

export const UpdateAssetPriceDialog = (props: {
  onClose: () => void;
  onUpdate: () => void;
  asset: { id: string; name: string };
  date: UtcDate;
  quoteAsset: { id: string };
}): ReactElement => {
  const methods = useForm<FormInputFields>({
    resolver: gYupResolver(formSchema),
    defaultValues: {
      price: '',
    },
  });

  const { onErrorAndThrow } = useGraphQLApiError(methods);
  const { showSuccessMessage } = useFeedback();

  const [createAssetPrice] = useCreateUpdateAssetPriceMutation({
    refetchQueries: [AssetPriceChartDocument],
  });

  const handleFormSubmit = async (input: FormInputFields): Promise<void> => {
    const formOutput = input as unknown as FormOutputFields;
    try {
      await createAssetPrice({
        variables: {
          input: {
            id: {
              date: props.date,
              baseAssetId: props.asset.id,
              quoteAssetId: props.quoteAsset.id,
            },
            unitValue: formOutput.price.toString(),
          },
        },
      });

      showSuccessMessage('Asset price successfully updated');

      props.onUpdate();
    } catch (e) {
      onErrorAndThrow(e);
    }
  };

  const inputWidth = 'xl2';
  return (
    <Modal open={true} onClose={props.onClose}>
      <ModalDialog>
        <GFormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
            <GDialogHeader>
              Update {props.asset.name} price for {formatDate(props.date, DateTimeFormat.Date)}
            </GDialogHeader>
            <Stack alignItems="center" spacing={1.5}>
              <FormInput label="Price" name="price" width={inputWidth} />
              <GraphQLApiFormErrorMessage />
              <SubmitButton width="normal" startDecorator={<Add />}>
                Update price
              </SubmitButton>
            </Stack>
          </form>
        </GFormProvider>
      </ModalDialog>
    </Modal>
  );
};
