import { type ReactElement, useMemo, useState } from 'react';
import GFormProvider from '../../../technical/form/GFormProvider.tsx';
import { useForm } from 'react-hook-form';
import {
  schema,
  type UnifiedStressTestInputFields,
  type UnifiedStressTestOutputFields,
} from './UnifiedStressTest.validation.ts';
import gYupResolver from '../../../technical/form/gYupResolver.ts';
import { useSteps } from '../../../technical/wizard/UseSteps.ts';
import type { StepConfig } from '../../../technical/wizard/StepConfig.ts';
import { config as portfolioConfig } from './PortforliosStepConfig.tsx';
import { config as submitConfig } from './SubmitStepConfig.tsx';
import { config as shockConfig } from './ModelAndAssumptionsStepConfig.tsx';
import { IPortfolioDefinitionType, useUnifiedStressTestInputSuspenseQuery } from '../../../../generated/graphql.tsx';
import {
  isStressTestFormAsset,
  type StressTestFormAsset,
  useGetStressTestFormAsset,
} from './PortfoliosStep.validation.tsx';
import { Stack } from '@mui/joy';
import UnifiedStressTestResult from '../UnifiedStressTestResult.tsx';
import { cloneDeep } from 'lodash/fp';
import { GStepper } from '../../../technical/wizard/GStepper.tsx';

const defaultShockValues = '-20,-10,-5,0,5,10,20';
const defaultInterestShockValues = '-10,-5,-2,0,2,5,10';

const defaultValues = {
  portfolios: [
    {
      portfolio: [{}],
    },
  ],
  shocks: {
    volatilityShock: {
      enabled: true,
      value: defaultShockValues,
    },
    priceShock: {
      enabled: true,
      value: defaultShockValues,
    },
    interestFreeRateShock: {
      enabled: false,
      value: defaultInterestShockValues,
    },
  },
};

const UnifiedStressTestWizard = (): ReactElement => {
  const [submittedOutput, setSubmittedOutput] = useState<null | UnifiedStressTestOutputFields>(null);
  const query = useUnifiedStressTestInputSuspenseQuery();

  const queryAssets = query.data.assets;
  const benchmarks = useGetStressTestFormAsset(queryAssets.feature);
  const methods = useForm<UnifiedStressTestInputFields>({
    resolver: gYupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const { activeStep, goToStep, validateVisitedSteps, getStepState } = useSteps();

  const compositions = query.data.portfolio.definitions;
  const steps: StepConfig<UnifiedStressTestInputFields>[] = useMemo(() => {
    let nextIndex = 1;
    const nextHandler = (): (() => void) => {
      const index = nextIndex++;
      return (): void => goToStep(index);
    };

    const comps = compositions
      .filter((comp) => comp.type === IPortfolioDefinitionType.Real)
      .map((comp) => ({
        ...comp,
        composition: comp.latestAssetComposition.filter(
          (
            comp
          ): comp is {
            asset: StressTestFormAsset;
          } & Omit<typeof comp, 'asset'> => isStressTestFormAsset(comp.asset)
        ),
      })) as {
      name: string;
      composition: {
        asset: StressTestFormAsset;
        weight: number;
        amount: number | null | undefined;
        entryPrice: number | null | undefined;
      }[];
    }[];

    return [portfolioConfig(nextHandler(), comps), shockConfig(nextHandler(), benchmarks), submitConfig()];
  }, [goToStep, benchmarks, compositions]);

  validateVisitedSteps({
    trigger: methods.trigger,
    steps,
  });

  const handleFormSubmit = async (data: UnifiedStressTestInputFields): Promise<void> => {
    setSubmittedOutput(cloneDeep(data) as unknown as UnifiedStressTestOutputFields);
  };

  return (
    <Stack gap={4}>
      <GFormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
          <GStepper
            activeStep={activeStep}
            steps={steps}
            formState={methods.formState}
            getStepState={getStepState}
            goToStep={goToStep}
          />
        </form>
      </GFormProvider>
      {submittedOutput && <UnifiedStressTestResult input={submittedOutput} />}
    </Stack>
  );
};

export default UnifiedStressTestWizard;
