import { Box, Stack } from '@mui/joy';
import { type ReactElement, type ReactNode, Suspense } from 'react';

import { shellClass, shellPadding } from './PageShell.utils.ts';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs.tsx';
import Loader from '../Loader/Loader.tsx';

const PageShell = ({
  children,
  hideBreadcrumb,
  padding = shellPadding,
}: {
  children: ReactNode;
  hideBreadcrumb?: boolean;
  padding?: number;
}): ReactElement => {
  return (
    <Box height="100%">
      <Stack spacing={2} flexGrow={1} height="100%" p={padding} className={shellClass}>
        {!hideBreadcrumb && (
          <Box ml={-padding}>
            <Breadcrumbs />
          </Box>
        )}
        <Suspense fallback={<Loader />}>{children}</Suspense>
      </Stack>
    </Box>
  );
};

export default PageShell;
