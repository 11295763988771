import type { ReactElement } from 'react';
import type { FieldValues, Path } from 'react-hook-form';
import { BaseFormDateInput, type BaseFormDateInputProps } from './BaseFormDateInput.tsx';
import type { DistributiveOmit } from '../../type.utils.ts';

export type FormDateTimeInputProps = DistributiveOmit<BaseFormDateInputProps, 'showTime'>;

export const FormDateTimeInput = <T extends FieldValues>(
  props: FormDateTimeInputProps & {
    name: Path<T>;
  }
): ReactElement => {
  return <BaseFormDateInput {...props} showTime={true} />;
};
