import { useTheme } from '@mui/joy';
import type { Breakpoint } from '@mui/system';
import { useMedia } from 'react-use';

/** mui joy gives us css format for media queries, to use in js adjustment is needed */
function convertJoyMediaQueryToJs(joyMediaQuery: string): string {
  return joyMediaQuery.replace('@media ', '');
}

export function useScreenBreakpointDown(breakpoint: number | Breakpoint): boolean {
  const theme = useTheme();
  return useMedia(convertJoyMediaQueryToJs(theme.breakpoints.down(breakpoint)));
}

export function useScreenBreakpointUp(breakpoint: number | Breakpoint): boolean {
  const theme = useTheme();
  return useMedia(convertJoyMediaQueryToJs(theme.breakpoints.up(breakpoint)));
}
