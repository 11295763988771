import { Stack } from '@mui/joy';
import { QRCodeSVG } from 'qrcode.react';
import type { ReactElement } from 'react';

import ExternalLink from 'components/technical/ExternalLink/ExternalLink';
import { GDialogDescription } from 'components/technical/GDialogDescription/GDialogDescription.tsx';
import GButton from 'components/technical/inputs/GButton/GButton';
import ResponsiveColumn from 'components/technical/layout/Column/ResponsiveColumn';

const ShowCodeStep = ({ onNext, code, email }: { code: string; email: string; onNext: () => void }): ReactElement => {
  const qrUrl = `otpauth://totp/${email}?secret=${code}&issuer=GenieINC`;
  return (
    <Stack spacing={3}>
      <GDialogDescription>
        Step 1: Download an authenticator App like{' '}
        <ExternalLink to="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US">
          Google Authenticator
        </ExternalLink>{' '}
        or{' '}
        <ExternalLink to="https://play.google.com/store/apps/details?id=com.authy.authy&hl=en&gl=US">
          Authy
        </ExternalLink>
      </GDialogDescription>
      <GDialogDescription>
        Step 2: Scan the QR code below to add the Genie account to the authenticator
      </GDialogDescription>
      <GDialogDescription>Step 3: Click &quot;Next&quot; and enter your 6 digit code</GDialogDescription>
      <Stack alignItems="center">
        <QRCodeSVG value={qrUrl} />
      </Stack>
      <ResponsiveColumn xs={4}>
        <GButton onClick={onNext}>Next</GButton>
      </ResponsiveColumn>
    </Stack>
  );
};

export default ShowCodeStep;
