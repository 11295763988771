import { type ReactElement, useMemo } from 'react';

import type { ColDef, ColGroupDef, ValueGetterParams } from 'ag-grid-community';
import GAgGrid from 'components/technical/grids/GAgGrid.tsx';
import type { PortfolioSolution } from './Results.types.ts';
import {
  portfolioDefinitionNameColumn,
  portfolioDefinitionRebalancingRuleColumn,
  portfolioDefinitionTypeColumn,
  portfolioNameGetter,
  portfolioRebalancingRuleGetter,
  portfolioTypeGetter,
} from '../wizard/portfolio/PortfolioDefinitionSharedColumns.ts';
import isNil from 'lodash/fp/isNil';
import { createDiffColumn, createSolutionColumn } from './PositionGrid.utils.ts';

type PortfolioMeasure = PortfolioSolution['measures'][number];
type PortfolioMeasureWithSolutionToCompare = {
  asset: { symbol: string; id: string } & PortfolioMeasure['item'];
  solutionAssetMeasure?: PortfolioMeasure;
  solutionToCompareAssetMeasure?: PortfolioMeasure;
};

const defaultColDef = {
  resizable: true,
  sortable: true,
  filter: true,
};

const PortfolioOptimizerSolutionPositionsGrid = ({
  solutionName,
  compareToSolution,
  positions,
}: {
  solutionName: string;
  compareToSolution: PortfolioSolution | null;
  positions: PortfolioMeasureWithSolutionToCompare[];
}): ReactElement => {
  const columns: (
    | ColDef<PortfolioMeasureWithSolutionToCompare>
    | ColGroupDef<PortfolioMeasureWithSolutionToCompare>
  )[] = useMemo(() => {
    return [
      {
        ...portfolioDefinitionNameColumn,
        valueGetter: (params: ValueGetterParams<PortfolioMeasureWithSolutionToCompare, number>): string | undefined => {
          return portfolioNameGetter(params.data?.asset);
        },
      },
      {
        ...portfolioDefinitionTypeColumn,
        valueGetter: (params: ValueGetterParams<PortfolioMeasureWithSolutionToCompare, number>): string | undefined => {
          return portfolioTypeGetter(params.data?.asset);
        },
      },
      {
        ...portfolioDefinitionRebalancingRuleColumn,
        valueGetter: (params: ValueGetterParams<PortfolioMeasureWithSolutionToCompare, number>): string | undefined => {
          return portfolioRebalancingRuleGetter(params.data?.asset);
        },
      },
      {
        colId: 'side',
        headerName: 'Side',
        type: 'textColumn',
        initialHide: true,
        valueGetter: (params: ValueGetterParams<PortfolioMeasureWithSolutionToCompare>): string => {
          const amount = params.data?.solutionAssetMeasure?.amount;
          if (isNil(amount) || amount === 0) {
            return '';
          }

          return amount > 0 ? 'Long' : 'Short';
        },
      },
      createSolutionColumn<PortfolioMeasureWithSolutionToCompare>(solutionName, 'solutionAssetMeasure'),
      ...(compareToSolution
        ? [
            createSolutionColumn<PortfolioMeasureWithSolutionToCompare>(
              compareToSolution.name,
              'solutionToCompareAssetMeasure'
            ),
            createDiffColumn<PortfolioMeasureWithSolutionToCompare>(),
          ]
        : []),
    ];
  }, [compareToSolution, solutionName]);

  return (
    <GAgGrid
      rowData={positions}
      autoSizeStrategy={{ type: 'fitCellContents' }}
      sideBar={{ toolPanels: ['columns', 'filters'] }}
      defaultColDef={defaultColDef}
      columnDefs={columns}
    />
  );
};

export default PortfolioOptimizerSolutionPositionsGrid;
