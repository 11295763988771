import { Add } from '@mui/icons-material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Stack } from '@mui/joy';
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import type { CellContext } from '@tanstack/table-core';
import type { ReactElement } from 'react';
import GTable from 'components/technical/GTable/GTable';

import DeleteDialogButton from 'components/technical/inputs/GButton/DeleteDialogButton';
import DialogButton from 'components/technical/inputs/GButton/DialogButton';
import UnknownIcon from 'components/technical/UnknownIcon/UnknownIcon';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';

import { CreatePrivateAssetDialog } from './CreatePrivateAssetDialog';
import UpdatePrivateAssetDialog from './UpdatePrivateAssetDialog';
import {
  PrivateAssetsDocument,
  SearchableAssetIdsDocument,
  SearchableAssetsDocument,
  type IPrivateAssetsQuery,
  useDeletePrivateAssetMutation,
  usePrivateAssetsQuery,
} from '../../../generated/graphql';
import { IconVariant, iconVariantToPx } from '../../market/asset/cryptocurrencies/CryptocurrenciesData';

type PrivateAssetData = IPrivateAssetsQuery['assets']['list'][number];

const PrivateAssetManagementList = (): ReactElement => {
  const { data, Fallback, loaded } = useDefaultErrorHandling(usePrivateAssetsQuery());

  const [deletePrivateAsset] = useDeletePrivateAssetMutation({
    ignoreResults: true,
  });

  const columnHelper = createColumnHelper<PrivateAssetData>();

  if (!loaded) {
    return <Fallback />;
  }

  const iconSize = iconVariantToPx(IconVariant.LARGE);
  const columns: ColumnDef<PrivateAssetData>[] = [
    columnHelper.display({
      header: 'Icon',
      cell: (cellContext) => (
        <UnknownIcon
          text={cellContext.row.original.symbol}
          style={{
            width: iconSize,
            height: iconSize,
          }}
        />
      ),
      meta: {
        headerStyles: {
          width: '100px',
        },
      },
    }),
    {
      header: 'Name',
      accessorFn: (asset) => asset.name,
    },
    {
      header: 'Symbol',
      accessorFn: (asset) => asset.symbol,
    },
    columnHelper.display({
      header: 'Actions',
      meta: {
        headerStyles: {
          width: '200px',
        },
      },
      cell: (props: CellContext<PrivateAssetData, unknown>) => (
        <Stack direction="row" spacing={1.5} justifyContent="flex-end" width="100%">
          {/* jscpd:ignore-start */}
          <DialogButton
            variant="plain"
            renderDialog={({ onClose }): ReactElement => (
              <UpdatePrivateAssetDialog onClose={onClose} onUpdate={onClose} asset={props.row.original} />
            )}
            startDecorator={<BorderColorIcon />}
          >
            Edit
          </DialogButton>
          {/* jscpd:ignore-end */}
          <DeleteDialogButton
            deleteMessage={
              <>
                Are you sure you want to remove asset {props.row.original.name}? This action will remove all trades and
                balances that use it.
              </>
            }
            confirmationMessage="Asset successfully deleted"
            deleteItem={(): Promise<unknown> =>
              deletePrivateAsset({
                variables: {
                  id: props.row.original.id,
                },
                refetchQueries: [SearchableAssetsDocument, SearchableAssetIdsDocument, PrivateAssetsDocument],
              })
            }
          />
        </Stack>
      ),
    }),
  ];

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
        <DialogButton
          renderDialog={({ onClose }): ReactElement => <CreatePrivateAssetDialog onClose={onClose} onAdded={onClose} />}
          startDecorator={<Add />}
        >
          Add new asset
        </DialogButton>
      </Stack>
      <GTable columns={columns} data={data.assets.list} />
    </>
  );
};

export default PrivateAssetManagementList;
