import type { ReactElement } from 'react';
import type { UseFormReturn } from 'react-hook-form';

import ErrorMessage from 'components/technical/ErrorMessage';
import GButton from 'components/technical/inputs/GButton/GButton';
import { calculateAllocationSummary, scaleAllocation, scaleUpEmptyFields } from './NormalizeAllocationService';
import { Typography } from '@mui/joy';

export const NormalizeAllocation = (props: {
  itemsPath: string;
  valuePath: string;
  errorPath: string;
  itemName: string;
  methods: UseFormReturn;
  allowNegativeValues: boolean;
}): ReactElement => {
  const getItemAllocation = (index: number): string =>
    props.methods.getValues(`${props.itemsPath}.${index}.${props.valuePath}`) as unknown as string;

  const setItemAllocation = (index: number, value: string, options?: { shouldValidate: boolean }): void =>
    props.methods.setValue(`${props.itemsPath}.${index}.${props.valuePath}`, value, options);

  const totalError = props.methods.getFieldState(props.errorPath, props.methods.formState).error;
  const rawFormItems: unknown[] = props.methods.getValues(props.itemsPath);
  const summary = calculateAllocationSummary(
    rawFormItems.map((_v, i) => getItemAllocation(i)),
    !props.allowNegativeValues
  );

  return (
    <>
      {totalError && (
        <ErrorMessage>
          The total {props.itemName} allocation is {summary.totalAllocation.toFixed(2)}%, Adjust the values or click{' '}
          <GButton
            variant="plain"
            sx={{
              paddingInline: 1,
            }}
            onClick={(): void => {
              if (summary.emptyFieldsCount === 0) {
                scaleAllocation(rawFormItems.length, summary, getItemAllocation, setItemAllocation);

                return;
              }

              scaleUpEmptyFields(rawFormItems.length, summary, getItemAllocation, setItemAllocation);
            }}
          >
            <Typography sx={{ textDecoration: 'underline' }}>here</Typography>
          </GButton>{' '}
          to automatically normalize them to 100%.
        </ErrorMessage>
      )}
    </>
  );
};
