import { FormControl, Radio, RadioGroup } from '@mui/joy';
import type { ForwardedRef, ReactElement } from 'react';

import { fixedForwardRef } from 'components/technical/fixedForwardRef';
import type { GRadioGroupProps, GRadioOption } from './GRadioGroup.props';
import InputError from '../InputError';
import InputLabel from '../InputLabel';
import { shouldRenderInputLabel } from '../LabelService.ts';
import widthSx from '../../../width.styles.ts';

const getValue = <T,>(
  value: T | undefined,
  options: GRadioOption<T>[],
  isValueEqual: GRadioGroupProps<T>['isValueEqual'] = (a, b): boolean => a === b
): string => {
  if (value === undefined) {
    return '';
  }

  const selectedOption = options.find((opt) => isValueEqual(opt.value, value));
  if (!selectedOption) {
    console.error('Selected option not found');
    return '';
  }

  return selectedOption.key;
};

function GRadioGroup<TValue>(props: GRadioGroupProps<TValue>, ref: ForwardedRef<HTMLDivElement>): ReactElement {
  const { value, options, onChange, disabled, error, isValueEqual } = props;
  const finalValue = getValue(value, options, isValueEqual);
  const sx = {
    ...widthSx,
    minContent: {
      width: 'auto',
    },
    height: 'fit-content',
  };

  return (
    <FormControl sx={sx} error={!!error} disabled={props.disabled}>
      {shouldRenderInputLabel(props) && <InputLabel {...props} />}
      <RadioGroup
        orientation="horizontal"
        name={props.name}
        onChange={(e) => {
          const key = e.target.value;
          const selectedOption = options.find((opt) => opt.key === key);
          if (!selectedOption) {
            console.error(`Unknown option selected. [key=${key}]`);
            return;
          }

          onChange?.(selectedOption.value);
        }}
        sx={{
          gap: '0.25rem',
        }}
        value={finalValue}
        ref={ref}
      >
        {options.map((opt) => (
          <Radio key={opt.key} value={opt.key} label={opt.label} disabled={disabled} />
        ))}
      </RadioGroup>
      <InputError error={error} />
    </FormControl>
  );
}

const ForwardedGRadioGroup = fixedForwardRef(GRadioGroup);
export default ForwardedGRadioGroup;
