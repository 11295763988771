import type { FunctionComponent, PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';

import GButton from '../inputs/GButton/GButton';
import type { GButtonProps } from '../inputs/GButton/GButton.props';

type SubmitButtonProps = Omit<GButtonProps, 'type'>;

const SubmitButton: FunctionComponent<PropsWithChildren<SubmitButtonProps>> = (props) => {
  const formContext = useFormContext();
  const formState = formContext.formState;

  return (
    <GButton
      {...props}
      loading={formState.isSubmitting || props.loading}
      disabled={
        Object.keys(formState.errors).length > 0 || formState.isSubmitting || (props.disabled ?? false) || props.loading
      }
      type="submit"
    >
      {props.children}
    </GButton>
  );
};

export default SubmitButton;
