import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Modal, ModalDialog, ModalOverflow } from '@mui/joy';
import dayjs from 'dayjs';
import isNil from 'lodash/fp/isNil';
import { bignumber } from 'mathjs';
import type { ReactElement } from 'react';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';

import type { GraphQlErrorHandler } from 'components/technical/form/UseGraphQLApiError.tsx';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import type { ErrorHandlingOutput } from 'components/technical/UseDefaultErrorHandling.tsx';
import TransactionForm from './TransactionForm';
import { calculateAssetBuySellVisibility, type FormState } from './TransactionForm.validation';
import { getTransactionOutput } from './TransactionService.ts';
import {
  type IAsset,
  type ITransaction,
  TransactionFilterInputDocument,
  TransactionsOldDocument,
  useUpdateTransactionOldMutation,
} from '../../../generated/graphql';
import type { AssetSelectOptionValue } from '../../market/asset/AssetService.tsx';
import type { CreateSubAccountIdAutocompleteOptionsInputAccount } from '../../portfolio/account/AccountService.tsx';
import type { RecursivePartial } from '../../type.utils';

interface FormAssetPayload {
  asset: AssetSelectOptionValue | undefined;
  amount: number | undefined;
  marketValue: number | undefined;
}

const convertAssetValue = (
  assetValue:
    | { amount: string; unitValue?: string | null | undefined; asset: Partial<IAsset> & { id: string } }
    | null
    | undefined
): FormAssetPayload => {
  if (!assetValue) {
    return { asset: undefined, amount: undefined, marketValue: undefined };
  }

  return {
    asset: assetValue.asset,
    amount: bignumber(assetValue.amount).toNumber(),
    marketValue: isNil(assetValue.unitValue)
      ? undefined
      : bignumber(assetValue.unitValue).mul(bignumber(assetValue.amount)).toNumber(),
  };
};

type AssetValue = { asset: Partial<IAsset> & { id: string }; amount: string; unitValue?: string | null };

const UpdateTransactionDialogContainer = (props: {
  onClose: () => void;
  onAdded: () => void;
  accounts: CreateSubAccountIdAutocompleteOptionsInputAccount[];
  assetQuery: ErrorHandlingOutput<{ data: AssetSelectOptionValue[] }>;
  existingTransaction: Pick<
    ITransaction,
    'comment' | 'executedAt' | 'externalId' | 'id' | 'originalTransactionId' | 'status' | 'subType' | 'tags' | 'type'
  > & {
    trade?: RecursivePartial<ITransaction['trade']>;
    subAccount: {
      id: string;
    };
    fee?: AssetValue | null | undefined;
    credit?: AssetValue | null | undefined;
    debit?: AssetValue | null | undefined;
  };
  existingTags: string[];
}): ReactElement => {
  const {
    id,
    type,
    subType,
    credit: buy,
    debit: sell,
    fee,
    externalId,
    trade,
    status,
    subAccount,
    executedAt,
    tags,
    comment,
  } = props.existingTransaction;

  const defaultValues = {
    type: type,
    subType: subType,
    buy: convertAssetValue(buy),
    sell: convertAssetValue(sell),
    fee: convertAssetValue(fee),
    externalId: isNil(externalId) ? '' : externalId,
    trade: {
      type: !trade?.type ? 'noValue' : trade.type,
      orderType: trade?.order?.type ?? 'noValue',
      orderSide: trade?.order?.side ?? 'noValue',
    },
    status: status,
    subAccount: subAccount.id ?? '',
    executedAt: dayjs.utc(executedAt),
    tags: tags ?? [],
    comment: comment ?? '',
  } satisfies FormState;

  const [updateTransaction] = useUpdateTransactionOldMutation({
    refetchQueries: [TransactionsOldDocument, TransactionFilterInputDocument],
  });
  const { showSuccessMessage } = useFeedback();

  const handleFormSubmit = async (data: FormState, onErrorAndThrow: GraphQlErrorHandler): Promise<void> => {
    const transactionType = data.type;

    const visibility = calculateAssetBuySellVisibility(transactionType);
    const { shouldShowBuy, shouldShowSell } = visibility;

    try {
      await updateTransaction({
        variables: { input: { ...getTransactionOutput(data, shouldShowBuy, shouldShowSell), id } },
      });

      showSuccessMessage('Transaction updated successfully');
      props.onAdded();
    } catch (e) {
      onErrorAndThrow(e);
    }
  };

  const assetQuery = props.assetQuery;
  return (
    <Modal open={true} onClose={props.onClose}>
      <ModalOverflow>
        <ModalDialog size={'lg'}>
          <GDialogHeader>Edit transaction</GDialogHeader>
          {assetQuery.loaded ? (
            <TransactionForm
              {...props}
              disableSubAccount
              onSubmit={handleFormSubmit}
              defaultValues={defaultValues}
              accounts={props.accounts}
              assets={assetQuery.data}
              submitButtonText="Update transaction"
              submitButtonIcon={<BorderColorIcon />}
            />
          ) : (
            <assetQuery.Fallback />
          )}
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default UpdateTransactionDialogContainer;
