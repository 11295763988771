import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/joy';
import { type FunctionComponent, useRef, useState } from 'react';
import { SearchResults } from './SearchResults';
import { useSearchableAssets } from './useSearchableAssets';
import type { Asset, ExchangeAsset, UnvestedAsset } from '../../../market/asset/Asset.types';
import GInput from '../../inputs/GInput/GInput';
import isNil from 'lodash/fp/isNil';

const sanitizeText = (text: string | undefined | null): string =>
  isNil(text) ? '' : text.toLowerCase().replace(/[^a-zA-Z0-9:/-]+/g, ' ');

const matchesCriteria = (asset: Exclude<Asset, UnvestedAsset | ExchangeAsset>, phrases: string[]): boolean => {
  return phrases.every((inputPhrase) =>
    [
      sanitizeText('name' in asset ? asset.name : ''),
      sanitizeText('derivativeDetails' in asset ? asset.derivativeDetails?.baseAsset.name : ''),
      sanitizeText(asset.symbol),
    ]
      .filter((part) => !!part)
      .some((assetText) => assetText.includes(inputPhrase.toLowerCase()))
  );
};

const queryAssets = (
  text: string,
  assets: Exclude<Asset, UnvestedAsset | ExchangeAsset>[]
): Exclude<Asset, UnvestedAsset | ExchangeAsset>[] => {
  const sanitizedTextParts = sanitizeText(text).split(' ');
  return assets.filter((asset) => matchesCriteria(asset, sanitizedTextParts));
};

const AppbarSearch: FunctionComponent = () => {
  const navigableAssets = useSearchableAssets();

  const [text, setText] = useState('');
  const [open, setOpen] = useState(false);
  const refAnchor = useRef<HTMLDivElement>(null);

  return (
    <Box
      ref={refAnchor}
      sx={{
        display: { xs: 'none', sm: 'block' },
        flexGrow: 1,
        maxWidth: '500px',
        flexBasis: '100px',
        flexShrink: 0,
        position: 'relative',
      }}
    >
      <GInput
        width="fullWidth"
        value={text}
        autoComplete="off"
        size="md"
        onChange={(value: string | null): void => {
          const newText = value ?? '';
          setText(newText ?? '');
          if (newText) {
            setOpen(true);
          }
        }}
        onClick={(): void => {
          if (text) {
            setOpen(true);
          }
        }}
        placeholder="Search anything..."
        startDecorator={<SearchIcon color="primary" />}
      />
      {open && text.length > 1 && (
        <SearchResults
          results={queryAssets(text, navigableAssets)}
          inputRef={refAnchor}
          onClose={(): void => setOpen(false)}
          onSearchResultClicked={(): void => setText('')}
        />
      )}
    </Box>
  );
};

export default AppbarSearch;
