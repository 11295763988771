import isEqual from 'lodash/fp/isEqual';

import { usePrevious } from './UsePrevious';
import { useRef } from 'react';

export const useGenerateKeyOnValueChanged = <V,>(value: V): string => {
  const keyRef = useRef<number>(1);
  const previousValue = usePrevious(value);
  if (!isEqual(previousValue, value)) {
    keyRef.current += 1;
  }

  return keyRef.current.toString();
};
