import HighChartsWrapper from '../../technical/charts/HighChartsWrapper/HighChartsWrapper.tsx';
import type { Options } from 'highcharts';
import {
  dateTimeAxisFormat,
  type HighchartSeries,
  percentageFormatter as highPercentageFormatter,
  calculateMultiChartParams,
  defaultTooltipDateHeader,
  defaultTooltipPointColor,
  cashFormatter,
  highChartsNegativeColorIndex,
  highChartsPositiveColorIndex,
  highChartsNeutralColorIndex,
  getHighchartColorWithOpacity,
  highChartAnnotationColor,
} from '../../technical/charts/HighChartsWrapper/Highchart.utils.ts';
import { parseUtcDate } from '../../date.utils.ts';
import {
  DateTimeFormat,
  formatCash,
  formatDate,
  formatEnum,
  formatISODate,
  formatPercentage,
} from 'components/formatter.utils.ts';
import { TupleKeyMap } from '../../TupleKeyMap.ts';
import {
  type IMarketRegimeBenchmarkPriceQuery,
  useMarketRegimeBenchmarkPriceSuspenseQuery,
  useMarketRegimeSuspenseQuery,
} from 'generated/graphql.tsx';
import type { FormOutputFields } from './MarketRegimeForm.validation.ts';
import sortBy from 'lodash/fp/sortBy';
import groupBy from 'lodash/fp/groupBy';
import dayjs from 'dayjs';
import { useFinalColorScheme } from '../../../useFinalColorScheme.ts';
import { bignumber } from 'mathjs';
import isNil from 'lodash/fp/isNil';

interface SerieUserData {
  modelId: number;
}

const regimeColor: Record<string, number> = {
  bear: highChartsNegativeColorIndex,
  bull: highChartsPositiveColorIndex,
  side: highChartsNeutralColorIndex,
};

const calculateOptions = (predictions: { model: { name: string; id: string } }[], assetSymbol: string): Options => {
  const { yAxis, chartHeight } = calculateMultiChartParams({
    items: predictions.length,
    singleItemHeight: 300,
    legendMaxHeight: 0,
  });

  return {
    ...dateTimeAxisFormat,
    tooltip: {
      formatter: function (): string {
        const timestamp = this.x;
        const text: string[][] = [];
        const points = this.points as unknown as {
          color: string;
          point: {
            textValue: string;
          };
          series: {
            name: string;
            yAxis: {
              index: number;
            };
            userOptions: SerieUserData;
          };
        }[];

        const benchmarkPoint = points.find((p) => isNil(p.series.userOptions.modelId));
        if (benchmarkPoint) {
          text.push([`${benchmarkPoint.series.name}: <b>${benchmarkPoint.point.textValue}</b>`]);
        }

        const modelIdToPoints = new Map(
          Object.entries(
            groupBy(
              (p) => p.series.userOptions.modelId,
              points.filter((p) => !isNil(p.series.userOptions.modelId))
            )
          )
        );

        for (const [modelId, modelPoints] of modelIdToPoints.entries()) {
          const portfolioText: string[] = [];
          portfolioText.push(`<b>${predictions[Number(modelId)].model.name}:</b>`);
          const sortedByNamePoints = sortBy((p) => p.series.name, modelPoints);
          for (const point of sortedByNamePoints) {
            portfolioText.push(
              `${defaultTooltipPointColor(point.color)} ${point.series.name}: <b>${point.point.textValue}</b>`
            );
          }
          text.push(portfolioText);
        }

        const header = defaultTooltipDateHeader(formatDate(dayjs.utc(timestamp), DateTimeFormat.LongDate));
        return [header, text.map((itemText) => itemText.join('<br>')).join('<br><br>')].join('<br>');
      },
    },
    yAxis: predictions.flatMap((pred, i) => [
      {
        title: {
          text: pred.model.name,
        },
        ...yAxis(i),
        labels: {
          formatter: (ctx: {
            value: number | string;
          }): string => {
            return highPercentageFormatter({
              value: (typeof ctx.value === 'string' ? Number.parseFloat(ctx.value) : ctx.value) / 100,
            });
          },
        },
      },
      {
        title: {
          text: assetSymbol,
        },
        opposite: true,
        ...yAxis(i),
        labels: {
          formatter: cashFormatter,
        },
      },
    ]),
    chart: {
      height: chartHeight,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      area: {
        stacking: 'percent',
      },
    },
  };
};

const calculateChartData = ({
  colorScheme,
  data,
  price,
  assetSymbol,
}: {
  colorScheme: 'dark' | 'light';
  assetSymbol: string;
  data: {
    model: {
      name: string;
      id: string;
    };
    timeseries: {
      date: UtcDate;
      values: {
        name: string;
        value: number;
      }[];
    }[];
  }[];
  price: IMarketRegimeBenchmarkPriceQuery['assets']['price'][number]['rows'];
}): HighchartSeries[] => {
  const series: HighchartSeries[] = [];
  for (let i = 0; i < data.length; i++) {
    const labels = new Set<string>();
    const dateToRegimeToValue = new TupleKeyMap<[UtcDate, string], number>();
    const values = data[i].timeseries;
    for (const day of values) {
      for (const regime of day.values) {
        labels.add(regime.name);
        dateToRegimeToValue.set([day.date, regime.name], regime.value);
      }
    }

    series.push(
      ...Array.from(labels.values()).map((regime) => ({
        type: 'area' as const,
        yAxis: 2 * i,
        name: formatEnum(regime),
        modelId: i,
        data: values.map((d) => {
          return {
            x: parseUtcDate(d.date as unknown as UtcDate).valueOf(),
            y: dateToRegimeToValue.get([d.date, regime]),
            textValue: formatPercentage(dateToRegimeToValue.get([d.date, regime])),
          };
        }),
        color: getHighchartColorWithOpacity(colorScheme, regimeColor[regime.toLowerCase()], 0.8),
      })),
      {
        type: 'line' as const,
        name: assetSymbol,
        yAxis: 2 * i + 1,
        data: price.map((dayValue) => ({
          x: parseUtcDate(dayValue.date as unknown as UtcDate).valueOf(),
          y: bignumber(dayValue.price).toNumber(),
          textValue: formatCash(dayValue.price),
        })),
        color: highChartAnnotationColor,
      }
    );
  }

  return series;
};

const MarketRegimeResult = ({ input }: { input: FormOutputFields }) => {
  const colorScheme = useFinalColorScheme();
  const query = useMarketRegimeSuspenseQuery({
    variables: {
      modelIds: input.models.map((mod) => mod.id),
      to: input.to ? formatISODate(input.to) : null,
      since: input.since ? formatISODate(input.since) : null,
    },
  });

  const predictions = query.data!.regime.historicalPredictions;
  const dates = predictions.flatMap((model) => model.timeseries.map((ts) => parseUtcDate(ts.date)));

  const priceQuery = useMarketRegimeBenchmarkPriceSuspenseQuery({
    variables: {
      since: formatISODate(dayjs.min(...dates)!),
      to: formatISODate(dayjs.max(...dates)!),
      assetId: input.asset.id,
    },
    skip: dates.length === 0,
  });

  const sortedPredictions = sortBy((pred) => pred.model.name, predictions);
  return (
    <HighChartsWrapper
      data={sortedPredictions}
      loading={false}
      calculateOptions={(data) => calculateOptions(data, input.asset.symbol)}
      calculateChartData={(data) =>
        calculateChartData({
          colorScheme,
          data,
          price: priceQuery.data!.assets.price.at(0)?.rows ?? [],
          assetSymbol: input.asset.symbol,
        })
      }
    />
  );
};

export default MarketRegimeResult;
