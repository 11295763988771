import { Stack, Typography as JoyTypography } from '@mui/joy';
import type { Dayjs } from 'dayjs';
import { type FunctionComponent, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { DrawerType } from 'components/technical/Drawer/Drawer.types.ts';
import { useDrawer } from 'components/technical/Drawer/UseDrawer.tsx';
import FormSelect from 'components/technical/form/FormSelect.tsx';
import FormStaticMultiAutocomplete from 'components/technical/form/FormStaticMultiAutocomplete.tsx';
import GFormProvider from 'components/technical/form/GFormProvider.tsx';
import Button from 'components/technical/inputs/GButton/GButton.tsx';
import type { SelectOption } from 'components/technical/inputs/Select/Select.props.ts';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling.tsx';
import { type INewsFeed, useNewsExplorerFilterInputQuery } from 'generated/graphql';

import { NewsExplorerFilterContext } from './NewsExplorerFilterContext.tsx';
import { createNewsFeedSelectOptions, useShowNewsExplorerFilters } from './NewsExplorerService.ts';
import { isValidDayjsDateRange } from '../../date.utils.ts';
import { FormDateRangeInput } from '../../technical/form/FormDateRangeInput.tsx';
import gYupResolver from '../../technical/form/gYupResolver.ts';
import { type AssetLabelInput, isAssetLabelInput } from '../asset/AssetLabelService.ts';
import { createAssetSelectOptions } from '../asset/AssetService.tsx';

type TertiaryBoolean = 'yes' | 'no' | 'all';
const TertiaryBooleanValues: TertiaryBoolean[] = ['yes', 'no', 'all'];

const fromOptionalBooleanToTertiary = (val: boolean | null): TertiaryBoolean => {
  if (val === null) {
    return 'all';
  }

  return val ? 'yes' : 'no';
};

const tertiaryToOptionalBoolean = (val: TertiaryBoolean): boolean | null => {
  if (val === 'all') {
    return null;
  }

  return val === 'yes';
};

interface FormValues {
  assets: AssetLabelInput[];
  source: INewsFeed[];
  viewed: TertiaryBoolean;
  bookmarked: TertiaryBoolean;
  dateRange: [Dayjs, Dayjs] | null;
}

const viewedOptions: SelectOption<TertiaryBoolean>[] = [
  {
    label: 'Viewed only',
    value: 'yes',
    key: 'viewed',
  },
  {
    label: 'Not viewed',
    value: 'no',
    key: 'not-viewed',
  },
  {
    label: 'All',
    value: 'all',
    key: 'all',
  },
];

const bookmarkedOptions: SelectOption<TertiaryBoolean>[] = [
  {
    label: 'Favoured only',
    value: 'yes',
    key: 'favoured',
  },
  {
    label: 'Not favoured',
    value: 'no',
    key: 'not-favoured',
  },
  {
    label: 'All',
    value: 'all', // null deselects all values in joy ui
    key: 'all',
  },
];

const anyArrayRequiredSchema = yup.array().required();
const tertiaryBooleanRequiredSchema = yup.string().required().oneOf(TertiaryBooleanValues);
const schema = yup.object({
  assets: anyArrayRequiredSchema,
  source: anyArrayRequiredSchema,
  viewed: tertiaryBooleanRequiredSchema,
  bookmarked: tertiaryBooleanRequiredSchema,
  dateRange: yup.mixed().nullable().test('validDateRange', 'Date range is invalid', isValidDayjsDateRange),
});

const NewsExplorerFiltersDrawer: FunctionComponent = () => {
  const filterContext = useContext(NewsExplorerFilterContext);
  const { drawer, closeDrawer } = useDrawer();
  const newsExplorer = useShowNewsExplorerFilters();

  const methods = useForm<FormValues>({
    defaultValues: {
      assets: filterContext.state.assets,
      source: filterContext.state.source,
      viewed: fromOptionalBooleanToTertiary(filterContext.state.viewed),
      bookmarked: fromOptionalBooleanToTertiary(filterContext.state.bookmarked),
      dateRange: null,
    },
    resolver: gYupResolver(schema),
  });

  useEffect(() => {
    if (drawer === DrawerType.NewsExplorerFilters && !newsExplorer) {
      closeDrawer();
    }
  }, [drawer, closeDrawer, newsExplorer]);

  const { loaded, data, Fallback } = useDefaultErrorHandling(useNewsExplorerFilterInputQuery());

  if (!loaded) {
    return <Fallback />;
  }

  const handleFiltersSubmit = (input: FormValues): void => {
    filterContext.setState({
      assets: input.assets,
      source: input.source,
      viewed: tertiaryToOptionalBoolean(input.viewed),
      bookmarked: tertiaryToOptionalBoolean(input.bookmarked),
      dateRange: input.dateRange,
    });
  };

  const assetSelectOptions = createAssetSelectOptions(
    data.news.supportedAssets.filter((asset): asset is AssetLabelInput => isAssetLabelInput(asset))
  );

  const newsFeedSelectOptions = createNewsFeedSelectOptions(data.news.supportedNewsFeeds);
  return (
    <GFormProvider {...methods}>
      <Stack spacing={1.5}>
        <Stack spacing={1.5}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1.5}>
            <JoyTypography color="neutral" level="body-md">
              Filter constraints
            </JoyTypography>
            <Button
              onClick={(): void => {
                methods.reset({
                  assets: [],
                  source: [],
                  viewed: 'all',
                  bookmarked: 'all',
                  dateRange: null,
                });
              }}
              variant="plain"
            >
              <JoyTypography color="primary">Reset filters</JoyTypography>
            </Button>
          </Stack>

          <Stack spacing={1.5}>
            <FormStaticMultiAutocomplete<FormValues>
              name="assets"
              {...assetSelectOptions}
              label="Assets"
              width="fullWidth"
            />
            <FormStaticMultiAutocomplete<FormValues>
              name="source"
              {...newsFeedSelectOptions}
              label="Source"
              width="fullWidth"
            />
            <FormSelect<FormValues> name="viewed" options={viewedOptions} label="Viewed" width="fullWidth" />
            <FormSelect<FormValues> name="bookmarked" options={bookmarkedOptions} label="Favoured" width="fullWidth" />
            <FormDateRangeInput label="Published at" width="fullWidth" name="dateRange" />
          </Stack>
          <Button
            onClick={methods.handleSubmit(handleFiltersSubmit)}
            disabled={Object.keys(methods.formState.errors).length > 0}
          >
            Apply filter
          </Button>
        </Stack>
      </Stack>
    </GFormProvider>
  );
};

export default NewsExplorerFiltersDrawer;
