import { Box, Card, Stack } from '@mui/joy';
import isNil from 'lodash/fp/isNil';
import type { ReactElement, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';

import { calculateErrorMessage } from './GraphQLAPIService.ts';
import { FORM_API_ERROR_FIELD } from './UseGraphQLApiError.tsx';
import ErrorMessage from '../ErrorMessage';
import type { DefaultColorPalette } from '@mui/joy/styles/types/colorSystem';

const convertErrorMessageToElements = (msg: string | string[]): ReactNode => {
  const wrapped = Array.isArray(msg) ? msg : [msg];
  return wrapped.map((message, i) => <span key={i}>{message}</span>);
};

export const GraphQLErrorMessage = ({
  error,
  padding = 0,
  color = 'danger',
}: {
  error: unknown;
  padding?: number;
  color?: DefaultColorPalette;
}): ReactElement | null => {
  if (isNil(error)) {
    return null;
  }

  const message = calculateErrorMessage(error);
  return (
    <Box padding={padding}>
      <ErrorMessage color={color}>
        <Stack spacing={0}>{convertErrorMessageToElements(message)}</Stack>
      </ErrorMessage>
    </Box>
  );
};

export const GraphQLErrorCardMessage = ({ error }: { error: unknown }): ReactElement | null => {
  return (
    <Card>
      <GraphQLErrorMessage error={error} />
    </Card>
  );
};

export const GraphQLApiFormErrorMessage = (): ReactElement | null => {
  const context = useFormContext();
  const error = context.formState.errors[FORM_API_ERROR_FIELD];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage: string[] | string | undefined = error?.message;

  if (errorMessage) {
    return (
      <ErrorMessage>
        <Stack spacing={0}>{convertErrorMessageToElements(errorMessage)}</Stack>
      </ErrorMessage>
    );
  }

  return null;
};
