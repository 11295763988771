import { Stack, Typography } from '@mui/joy';
import type { ReactElement, ReactNode } from 'react';
import { DeleteMenuItem } from '../../../technical/inputs/GButton/DeleteDialogButton.tsx';
import { PortfolioDefListInputDocument, useDeletePortfolioDefinitionMutation } from '../../../../generated/graphql.tsx';
import SeeMoreDropdown from '../../../technical/SeeMoreDropDown/SeeMoreDropdown.tsx';

export const PortfolioDefTile = ({
  id,
  name,
  children,
  extraActions,
  hideAllActions,
}: {
  id: string;
  name: string;
  children?: ReactNode;
  extraActions?: ReactNode;
  hideAllActions?: boolean;
}): ReactElement => {
  const [deletePortfolioDefinition] = useDeletePortfolioDefinitionMutation();

  return (
    <Stack direction="column" justifyContent="space-between" height="100%">
      <Stack justifyContent="space-between" direction="row">
        <Typography level="title-lg" component="h3">
          {name}
        </Typography>
        {!hideAllActions && (
          <SeeMoreDropdown>
            {extraActions}
            <DeleteMenuItem
              deleteMessage={<>Are you sure you want to remove portfolio {name}?</>}
              confirmationMessage="Portfolio definition successfully deleted"
              deleteItem={(): Promise<unknown> => {
                return deletePortfolioDefinition({
                  variables: {
                    input: id,
                  },
                  refetchQueries: [PortfolioDefListInputDocument],
                });
              }}
            />
          </SeeMoreDropdown>
        )}
      </Stack>
      {children}
    </Stack>
  );
};
