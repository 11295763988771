import { Stack, Typography } from '@mui/joy';
import type { ReactElement } from 'react';

import { BorderedActionItem } from 'components/technical/BorderedActionItem';
import { Key, QrCode } from 'components/technical/icons';
import DialogButton from 'components/technical/inputs/GButton/DialogButton';
import SectionPanel from 'components/technical/layout/SectionPanel';
import { DisableMfaDialog } from './DisableMfaDialog';
import EnableMFADialog from './EnableMfaDialog/EnableMfaDialog';
import UpdatePasswordDialog from './UpdatePasswordDialog';
import { useAuth } from '../../../UseAuth.tsx';

const AccountSecuritySettings = (): ReactElement => {
  const authState = useAuth();

  const mfaAction =
    authState.mfa === 'NO_MFA' ? (
      <BorderedActionItem
        icon={QrCode}
        title="Two factor authentication"
        description={
          <>
            Two factors authentication is currently <Typography level={'title-md'}>disabled</Typography> on this
            account, click on the button to enable it.
          </>
        }
        action={({ width, color }): ReactElement => (
          <DialogButton
            renderDialog={({ onClose }): ReactElement => <EnableMFADialog onClose={onClose} />}
            width={width}
            color={color}
          >
            Enable 2FA
          </DialogButton>
        )}
      />
    ) : (
      <BorderedActionItem
        icon={QrCode}
        title="Two factor authentication"
        description={
          <>
            Two factors authentication is currently <Typography level={'title-md'}>enabled</Typography> on this account,
            click on the button to disable it.
          </>
        }
        action={({ width, color }): ReactElement => (
          <DialogButton
            renderDialog={({ onClose }): ReactElement => <DisableMfaDialog onClose={onClose} />}
            width={width}
            color={color}
          >
            Disable 2FA
          </DialogButton>
        )}
      />
    );

  return (
    <SectionPanel title="Account security">
      <Stack spacing={1.5}>
        <BorderedActionItem
          icon={Key}
          title="Login password"
          description="Login password is used to log into your account."
          action={({ width, color }): ReactElement => (
            <DialogButton
              renderDialog={({ onClose }): ReactElement => <UpdatePasswordDialog onClose={onClose} />}
              width={width}
              color={color}
            >
              Change
            </DialogButton>
          )}
        />
        {mfaAction}
      </Stack>
    </SectionPanel>
  );
};

export default AccountSecuritySettings;
