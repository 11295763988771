import type { ReactElement } from 'react';
import FormSingleAutocomplete from '../../../technical/form/FormSingleAutocomplete.tsx';
import { createAssetAutocompleteProps, useAccountAssetPaginatedOptions } from '../../../market/asset/AssetService.tsx';
import {
  type FormInputFields,
  type FormOutputFields,
  positionSide,
  slotType,
  transactionLegType,
} from './TransactionForm.validation.ts';
import FormInput from '../../../technical/form/FormInput.tsx';
import { Card, Grid, Stack } from '@mui/joy';
import { FormDateTimeInput } from '../../../technical/form/FormDateTimeInput.tsx';
import dayjs from 'dayjs';
import FormSelect from '../../../technical/form/FormSelect.tsx';
import RemoveButton from '../../../technical/RemoveButton.tsx';
import { useFormState, useWatch } from 'react-hook-form';
import InputSuggestion from '../../../technical/form/InputSugestion.tsx';
import { useMarketValueAutocomplete } from '../../UseMarketValueAutocomplete.tsx';
import type { SubAccountLabelInputAccount } from '../../../portfolio/account/SubAccountLabel.tsx';
import type { FormInputType } from 'components/technical/form/Form.types.ts';
import { DateTimeFormat } from 'components/formatter.utils.ts';
import { formatDate } from '../../../formatter.utils.ts';
import { useUserTimezone } from '../../../technical/UseUserTimezone.tsx';

const twoColumnGrid = {
  md: 6,
  xs: 12,
};

const threeColumnGrid = {
  md: 4,
  xs: 12,
};

const TransactionLeg = ({
  index,
  onRemove,
  subAccount,
}: {
  index: number;
  onRemove: () => void;
  subAccount: FormInputType<SubAccountLabelInputAccount> | null;
}): ReactElement => {
  const timezone = useUserTimezone();
  const { isSubmitting } = useFormState<FormInputFields>();
  const pathPrefix = `legs.${index}` as const;
  const amount = useWatch<FormInputFields, `${typeof pathPrefix}.amount`>({
    name: `${pathPrefix}.amount`,
  });

  const { getOptions } = useAccountAssetPaginatedOptions({
    account: subAccount?.account,
  });

  const transactionTime = useWatch<FormInputFields, 'time'>({
    name: 'time',
  });

  const legTime = useWatch<FormInputFields, `${typeof pathPrefix}.time`>({
    name: `${pathPrefix}.time`,
  });

  const asset = useWatch<FormInputFields, `${typeof pathPrefix}.asset`>({
    name: `${pathPrefix}.asset`,
  });

  const sharedInputProps = {
    width: 'fullWidth',
  } as const;

  const autocompleteValue = useMarketValueAutocomplete(legTime ?? transactionTime, asset?.id);
  return (
    <Card>
      <Stack direction={'row'} alignItems={'stretch'} gap={3}>
        <Grid container spacing={3} flexGrow={1}>
          <Grid {...twoColumnGrid}>
            <FormSingleAutocomplete<FormInputFields>
              name={`${pathPrefix}.asset` as const}
              menuWidth="xl2"
              label="Asset"
              required
              {...createAssetAutocompleteProps()}
              getOptions={getOptions}
              {...sharedInputProps}
            />
          </Grid>
          <Grid {...twoColumnGrid}>
            <FormDateTimeInput<FormInputFields>
              label="Date and time (UTC)"
              showClearable
              name={`${pathPrefix}.time`}
              placeholder={formatDate(transactionTime, DateTimeFormat.ShortDateTime, timezone)}
              maxDate={dayjs.utc()}
              {...sharedInputProps}
            />
          </Grid>
          <Grid {...twoColumnGrid}>
            <FormInput name={`${pathPrefix}.amount`} label="Amount" required {...sharedInputProps} />
          </Grid>
          <Grid {...twoColumnGrid}>
            <InputSuggestion
              suggestions={
                !autocompleteValue.loading &&
                !autocompleteValue.error &&
                amount &&
                !Number.isNaN(Number.parseFloat(amount))
                  ? autocompleteValue.prices?.map((suggestion) => `${suggestion.mul(amount)}`)
                  : undefined
              }
            >
              <FormInput<FormOutputFields>
                type="number"
                name={`${pathPrefix}.marketValue` as const}
                label="Total market value"
                startDecorator="$"
                {...sharedInputProps}
              />
            </InputSuggestion>
          </Grid>
          <Grid {...threeColumnGrid}>
            <FormSelect<FormInputFields>
              options={slotType}
              name={`${pathPrefix}.slot`}
              label="State"
              required
              {...sharedInputProps}
            />
          </Grid>
          <Grid {...threeColumnGrid}>
            <FormSelect<FormInputFields>
              options={transactionLegType}
              name={`${pathPrefix}.type`}
              label="Type"
              required
              {...sharedInputProps}
            />
          </Grid>
          <Grid {...threeColumnGrid}>
            <FormSelect<FormInputFields>
              options={positionSide}
              name={`${pathPrefix}.side`}
              label="Side"
              {...sharedInputProps}
            />
          </Grid>
        </Grid>
        <RemoveButton
          color={'danger'}
          disabled={isSubmitting}
          onClick={(): void => {
            onRemove();
          }}
        />
      </Stack>
    </Card>
  );
};

export default TransactionLeg;
