import type { StaticAutocompleteOption } from '../../technical/inputs/Autocomplete/StaticSingleAutocomplete.props.ts';

export const HEIGHT_PX = 24;

export interface SubfundAutocompleteOptionInput {
  name: string;
  id: number;
  dimension: string;
}

export const createSubfundAutocompleteOptions = <T extends SubfundAutocompleteOptionInput>(
  subFunds: T[]
): {
  options: StaticAutocompleteOption<T>[];
  optionHeight: number;
  limitTags: number;
  groupBy: (option: StaticAutocompleteOption<T | null>) => string;
  isValueEqual: (a: T | undefined | null, b: T | undefined | null) => boolean;
} => {
  return {
    limitTags: 3,
    optionHeight: HEIGHT_PX,
    groupBy: (option: StaticAutocompleteOption<T | null>): string => option.value?.dimension ?? '',
    options: subFunds.map((subFund) => ({
      searchText: subFund.name,
      value: subFund,
      label: subFund.name,
      key: subFund.name,
    })),
    isValueEqual: (a: T | undefined | null, b: T | undefined | null): boolean => (!a && !b) || a?.id === b?.id,
  };
};

export const createSubfundIdAutocompleteOptions = (
  subFunds: SubfundAutocompleteOptionInput[]
): {
  options: StaticAutocompleteOption<number>[];
  optionHeight: number;
  limitTags: number;
  groupBy: (option: StaticAutocompleteOption<number | null>) => string;
  isValueEqual: (a: number | undefined | null, b: number | undefined | null) => boolean;
} => {
  const idToSubfund = new Map(subFunds.map((subfund) => [subfund.id, subfund]));
  return {
    ...createSubfundAutocompleteOptions(subFunds),
    groupBy: (option: StaticAutocompleteOption<number | null>): string => {
      const subfund = idToSubfund.get(option.value ?? -1);
      if (subfund) {
        return subfund.dimension;
      }

      return '';
    },
    options: subFunds.map((subFund) => ({
      searchText: subFund.name,
      value: subFund.id,
      label: subFund.name,
      key: subFund.name,
    })),
    isValueEqual: (a: number | undefined | null, b: number | undefined | null): boolean => (!a && !b) || a === b,
  };
};
