import { Stack } from '@mui/joy';
import type { ReactElement } from 'react';

import GButton from 'components/technical/inputs/GButton/GButton.tsx';
import PortfolioPrimaryConstraint from './PortfolioPrimaryConstraint.tsx';
import PortfolioSecondaryConstraint from './PortfolioSecondaryConstraint.tsx';
import type { AssetLabelInput } from '../../../../market/asset/AssetLabelService.ts';
import { createAssetSelectOptions } from '../../../../market/asset/AssetService.tsx';
import { defaultRowSpacing } from '../../../../StackSpacing.ts';
import StepDescription from '../../../../technical/wizard/StepDescription.tsx';
import type { SelectOption } from 'components/technical/inputs/Select/Select.props.ts';
import type { ISecondaryConstrainedQuantity } from 'generated/graphql.tsx';

const PortfolioConstraintsStep = ({
  goToNextStep,
  benchmarks,
  secondaryConstraintOptions,
}: {
  goToNextStep: () => void;
  benchmarks: AssetLabelInput[];
  secondaryConstraintOptions: SelectOption<ISecondaryConstrainedQuantity>[];
}): ReactElement => {
  const benchmarkOptions = createAssetSelectOptions(benchmarks);
  return (
    <Stack spacing={defaultRowSpacing}>
      <StepDescription>
        If you want the portfolio to meet specific exposure, risk, or performance requirements you can select them as
        constraints in this section. The optimization engine will find the best portfolio that meets your objective
        satisfying the constraints provided below.
      </StepDescription>
      <PortfolioPrimaryConstraint assetOptions={benchmarkOptions} />
      <PortfolioSecondaryConstraint
        assetOptions={benchmarkOptions}
        secondaryConstraintOptions={secondaryConstraintOptions}
      />
      <GButton
        onClick={goToNextStep}
        sx={{
          marginLeft: 'auto',
        }}
      >
        Next
      </GButton>
    </Stack>
  );
};

export default PortfolioConstraintsStep;
