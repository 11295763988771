import type { SvgIconProps } from '@mui/joy';
import type { ReactElement } from 'react';

import { GenieBorder } from '../../icons';
import GLink from '../../GLink/GLink';

const AppbarLogo = (props: SvgIconProps): ReactElement => (
  <GLink to="/">
    <GenieBorder
      sx={{
        width: '3rem',
        height: 'auto',
        aspectRatio: '1',
      }}
      {...props}
    />
  </GLink>
);

export default AppbarLogo;
