import {
  type AllocationConstraintsInput,
  type AllocationConstraintsOutput,
  schema as allocationConstraintsSchema,
} from '../allocationConstraints/AllocationConstraintsStep.validation.ts';
import {
  type AssetUniverseStepInput,
  type AssetUniverseStepOutput,
  schema as assetUniverseSchema,
} from './assetUniverse/AssetUniverseStep.validation.ts';
import {
  type AssumptionsAndOutlookStepInput,
  type AssumptionsAndOutlookStepOutput,
  schema as assumptionsAndOutlookSchema,
} from '../assumptionsAndOutlook/AssumptionsAndOutlook.validation.tsx';
import {
  type DescriptionStepInput,
  type DescriptionStepOutput,
  schema as descriptionSchema,
} from '../description/DescriptionStep.validation.ts';
import {
  type ObjectivesStepInput,
  type ObjectivesStepOutput,
  schema as objectivesSchema,
} from '../objective/ObjectivesStep.validation.ts';
import {
  createSchema as createPortfolioConstraintSchema,
  type PortfolioConstraintsStepOutput,
  type PortfolioLevelConstraintsStepInput,
} from '../portfolioConstraints/PortfolioConstraintsStep.validation.ts';
import type {
  InitialPortfolioStepInput,
  InitialPortfolioStepOutput,
} from '../initialPortfolio/InitialPortfolio.validation.ts';
import { secondaryConstraintQuantityValues } from '../portfolioConstraints/PortfolioSecondaryConstraint.validation.ts';

export type AssetOptimizerOutputFields = DescriptionStepOutput &
  ObjectivesStepOutput &
  PortfolioConstraintsStepOutput &
  AssetUniverseStepOutput &
  AllocationConstraintsOutput &
  InitialPortfolioStepOutput &
  AssumptionsAndOutlookStepOutput;

export type AssetOptimizerInputFields = DescriptionStepInput &
  ObjectivesStepInput &
  PortfolioLevelConstraintsStepInput &
  InitialPortfolioStepInput &
  AllocationConstraintsInput &
  AssetUniverseStepInput &
  AssumptionsAndOutlookStepInput;

export const schema = descriptionSchema
  .concat(objectivesSchema)
  .concat(createPortfolioConstraintSchema(secondaryConstraintQuantityValues))
  .concat(assetUniverseSchema)
  .concat(allocationConstraintsSchema)
  .concat(assumptionsAndOutlookSchema);
