import type { BigNumber } from 'mathjs';
import InitialPortfolioStep from './InitialPortfolioStep.tsx';
import { fields, label } from '../../initialPortfolio/InitialPortfolioStepConfig.tsx';
import type { StepConfig } from '../../../../../technical/wizard/StepConfig.ts';
import type { InitialPortfolioStepInput } from '../../initialPortfolio/InitialPortfolio.validation.ts';
import type { NotVerifiedAsset } from '../../../../../market/asset/AssetLabelService.ts';

export const config = (
  assets: (NotVerifiedAsset & { label: string; id: string })[],
  assetIdToClusterToGroup: Record<string, Record<string, string>>,
  aggregatedPortfolioValuesByAsset: Map<string, BigNumber>,
  goToNextStep: () => void
): StepConfig<InitialPortfolioStepInput> => ({
  label,
  element: (
    <InitialPortfolioStep
      assets={assets}
      goToNextStep={goToNextStep}
      assetIdToClusterToGroup={assetIdToClusterToGroup}
      aggregatedPortfolioValuesByAsset={aggregatedPortfolioValuesByAsset}
    />
  ),
  fields,
});
