import { groupBy, mapValues } from 'lodash/fp';

import type { StaticAutocompleteOption } from 'components/technical/inputs/Autocomplete/StaticSingleAutocomplete.props';
import type { IAssetGroup } from '../../../../generated/graphql.tsx';

export const priceActionClusterId = 'cluster_price_action_optics';

export const genieClusterNames = {
  category: 'Category',
  sector: 'Sector',
  [priceActionClusterId]: 'Price action',
  mcap_groups: 'Market cap',
} as const;

export const getClusterOptions = (userClusters: string[]): StaticAutocompleteOption<string>[] => {
  return [
    ...userClusters.map((name) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const finalName = genieClusterNames[name] ?? name;
      return {
        label: finalName,
        value: name,
        key: name,
        searchText: finalName,
        inputText: finalName,
      };
    }),
  ];
};

const createClusterToGroup = (groups: { group: string; cluster: string | null }[]): Record<string, string> => {
  return Object.fromEntries(groups.map((group) => [group.cluster, group.group]));
};

export const createAssetToClusters = <A extends { id: string }>(
  groups: Array<Pick<IAssetGroup, 'clusterName' | 'groupName'> & { assets: A[] }>
): Record<string, Record<string, string>> => {
  const assetToGroups = groupBy(
    (group) => group.asset.id,
    groups.flatMap((group) =>
      group.assets.map((asset) => ({ asset, group: group.groupName, cluster: group.clusterName }))
    )
  );

  return mapValues((groups) => createClusterToGroup(groups), assetToGroups);
};

export const getAssetsGroups = (
  assetIdToClusterToGroup: Record<string, Record<string, string>>,
  assets: { id: string }[]
): Set<string> => {
  const groupForAssets = new Set<string>();
  for (const asset of assets) {
    for (const group of Object.values(assetIdToClusterToGroup[asset.id] ?? {})) {
      groupForAssets.add(group);
    }
  }

  return groupForAssets;
};
