import { CookieOutlined } from '@mui/icons-material';
import { Stack } from '@mui/joy';
import isNil from 'lodash/fp/isNil';
import type { ReactElement } from 'react';

import { BorderedActionItem } from 'components/technical/BorderedActionItem';
import GButton from 'components/technical/inputs/GButton/GButton';
import SectionPanel from 'components/technical/layout/SectionPanel';
import AccountSecuritySettings from './AccountSecuritySettings';
import { useAuth } from '../../../UseAuth.tsx';

const UserSettings = (): ReactElement => {
  const { localUser } = useAuth();
  return (
    <Stack spacing={1.5}>
      {!isNil(localUser) && localUser && <AccountSecuritySettings />}
      <SectionPanel title="Privacy">
        <BorderedActionItem
          icon={CookieOutlined}
          title="Cookie preferences"
          description="We use different types of cookies in our website. Click to update your preferences at any time."
          action={({ width, color }): ReactElement => (
            <GButton
              type="button"
              width={width}
              color={color}
              onClick={(): void => {
                window.displayPreferenceModal?.();
              }}
            >
              Change
            </GButton>
          )}
        />
      </SectionPanel>
    </Stack>
  );
};

export default UserSettings;
