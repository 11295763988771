import { Button as Btn } from '@mui/joy';
import type { ButtonTypeMap } from '@mui/joy/Button/ButtonProps';
import type { ElementType, ReactElement, Ref } from 'react';

import { fixedForwardRef } from 'components/technical/fixedForwardRef';
import type { GButtonProps } from './GButton.props';
import widthSx from '../../../width.styles.ts';

const GButton = <D extends ElementType = ButtonTypeMap['defaultComponent'], P = Record<string, unknown>>(
  props: GButtonProps<D, P>,
  ref: Ref<HTMLButtonElement>
): ReactElement => {
  const widthVariant = props.width ?? 'minContent';
  return (
    <Btn
      {...props}
      ref={ref}
      sx={[
        widthVariant === 'minContent' ? {} : widthVariant === 'fullWidth' ? { width: '100%' } : widthSx[widthVariant],
        props.sx,
      ]}
    />
  );
};

const ForwardedGButton = fixedForwardRef(GButton);
export default ForwardedGButton;
