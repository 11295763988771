import * as yup from 'yup';
import { yupWhen } from '../../../../../validation.ts';
import isNil from 'lodash/fp/isNil';

export const formSchema = yup.object({
  accounts: yup.array(yup.string().required()).required(),
  subFunds: yup.array(yup.number().required()).required(),
  notEmptyAccountOrSubfunds: yupWhen(['accounts', 'subFunds'], ([accounts, subFunds]) => {
    return yup.mixed().test('notEmptyAccountOrSubfunds', 'At least one account or sub-fund need to be provided', () => {
      if (isNil(accounts) || isNil(subFunds)) {
        return true;
      }

      return accounts.length > 0 || subFunds.length > 0;
    });
  }),
});
