import React from 'react';

import type { DrawerType } from './Drawer.types.ts';
import type { drawerContent } from './Drawer.constants.tsx';

interface DrawerContext {
  setDrawer<Drawer extends DrawerType>(
    drawer: Drawer | null,
    openKey?: string,
    extraProps?: Omit<(typeof drawerContent)[Drawer]['children'], 'open'>
  ): void;
  drawer: DrawerType | null;
  openKey: string;
}

export const Context = React.createContext<DrawerContext>({
  setDrawer: () => null,
  drawer: null,
  openKey: '',
});
