import type { ReactElement } from 'react';
import { Typography } from '@mui/joy';
import { truncate } from 'lodash/fp';

export const maxLength = 80;
export const PortfolioDescriptionText = (props: { definition: { description: string } }): ReactElement => {
  return (
    <Typography level="body-md">
      {truncate(
        {
          length: maxLength,
        },
        props.definition.description
      )}
    </Typography>
  );
};
