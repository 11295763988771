import Card from '@mui/joy/Card';
import type { ReactElement, ReactNode } from 'react';

import SectionColumn from './Column/SectionColumn';
import { Typography } from '@mui/joy';

const SectionPanel = ({ title, children }: { title: string; children: ReactNode }): ReactElement => (
  <Card>
    <SectionColumn>
      <Typography level={'h4'}>{title}</Typography>
      {children}
    </SectionColumn>
  </Card>
);

export default SectionPanel;
