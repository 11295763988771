import { Card, Stack } from '@mui/joy';

import { Warning } from '../icons';
import type { ReactElement, ReactNode } from 'react';

export interface WarmingMessageProps {
  children: ReactNode;
  showCard?: boolean;
}

const WarningMessage = ({ children, showCard }: WarmingMessageProps): ReactElement => {
  const body = (
    <Stack direction="row" alignItems="center" spacing={1.5}>
      <Stack justifyContent="center" spacing={1.5}>
        <Warning fontSize="xl" color="warning" />
      </Stack>

      <span>{children}</span>
    </Stack>
  );

  if (showCard) {
    return <Card>{body}</Card>;
  }

  return body;
};

export default WarningMessage;
