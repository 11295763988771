import { Stack } from '@mui/joy';
import type { FunctionComponent } from 'react';

import AppbarLogo from './AppbarLogo/AppbarLogo';
import Navbar from './Navbar';
import { DrawerType } from '../Drawer/Drawer.types.ts';
import { useDrawer } from '../Drawer/UseDrawer.tsx';
import Button from '../inputs/GButton/GButton';

const IncognitoAppbar: FunctionComponent = () => {
  const { openDrawer } = useDrawer();

  return (
    <Navbar>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={4} width="100%">
        <AppbarLogo />
        <Button color="primary" width="normal" onClick={(): void => openDrawer(DrawerType.Login)}>
          Log in
        </Button>
      </Stack>
    </Navbar>
  );
};

export default IncognitoAppbar;
