import { useState } from 'react';

export const useOpen = (): {
  open: boolean;
  setOpen: (val: boolean) => void;
  onOpen: () => void;
  onClose: () => void;
} => {
  const [open, setOpen] = useState(false);
  return {
    open,
    setOpen,
    onOpen: (): void => {
      setOpen(true);
    },
    onClose: (): void => {
      setOpen(false);
    },
  };
};
