import { Grid, Stack } from '@mui/joy';
import type { ReactElement } from 'react';
import NavigableTab from 'components/technical/NavigableTabs/NavigableTab';
import NavigableTabs from 'components/technical/NavigableTabs/NavigableTabs';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';

import PrivateAssetPriceTile from './PrivateAssetPriceTile/PrivateAssetPriceTile';
import { useSinglePrivateAssetQuery } from '../../../../../generated/graphql';
import type { PrivateAsset } from '../../Asset.types';
import AssetDetails from '../AssetDetails/AssetDetails';
import BigTile from '../BigTile/BigTile';

const singleViewTabs = [
  { name: 'Price', link: 'price' },
  { name: 'Vesting', link: 'vesting' },
];

const SinglePrivateAsset = ({ asset }: { asset: PrivateAsset }): ReactElement => {
  const { loaded, Fallback, data } = useDefaultErrorHandling(
    useSinglePrivateAssetQuery({
      variables: {
        assetId: asset.id,
      },
    })
  );

  if (!loaded) {
    return <Fallback />;
  }

  const price = data.assets.pricePerformance.at(0)?.rows.at(-1);
  return (
    <Stack spacing={1.5}>
      <Grid container justifyContent="flex-start">
        <Grid md={6} xs={12}>
          <AssetDetails asset={asset} clusterToGroup={{}} />
        </Grid>
        <Grid md={6} xs={12}>
          {price ? <PrivateAssetPriceTile asset={asset} price={price.price} priceDate={price.date} /> : <BigTile />}
        </Grid>
      </Grid>
      <NavigableTabs>
        {singleViewTabs.map((tab) => (
          <NavigableTab link={`/app/market/assets/${asset.id}/${tab.link}`} key={tab.link} text={tab.name} />
        ))}
      </NavigableTabs>
    </Stack>
  );
};

export default SinglePrivateAsset;
