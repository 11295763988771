import { Card } from '@mui/joy';
import { bignumber } from 'mathjs';
import type { FunctionComponent } from 'react';
import { formatCash } from 'components/formatter.utils';
import {
  cashFormatter,
  type HighchartSeries,
  tooltipFormat,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import HighChartsContainer from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper';

import type { OpenInterestChartProps } from './OpenInterestExpiryChart';
import { calculateOpenInterestChartData, type OpenInterestDataType } from './OpenInterestService.ts';
import type * as Highcharts from 'highcharts';
const calculateOptions = (filename: string, strikePrices: number[]): Highcharts.Options => {
  return {
    xAxis: {
      tickInterval: 5,
      categories: strikePrices.map((price) => formatCash(price)),
      title: {
        text: 'Strike price',
      },
    },
    exporting: {
      filename,
    },
    ...tooltipFormat,
    yAxis: {
      labels: {
        formatter: cashFormatter,
      },
      title: {
        text: 'Open interest',
      },
    },
  };
};

const OpenInterestStrikePriceChart: FunctionComponent<OpenInterestChartProps> = ({ filename, data }) => {
  const strikePrices = Object.keys(data).map((item) => bignumber(item).toNumber());
  const sortedStrikePrice = strikePrices.sort((a, b) => {
    return a - b;
  });

  return (
    <Card>
      <HighChartsContainer<OpenInterestDataType>
        data={data}
        loading={false}
        calculateChartData={(data: OpenInterestDataType): HighchartSeries[] =>
          calculateOpenInterestChartData(
            data,
            sortedStrikePrice.map((price) => price.toString())
          )
        }
        calculateOptions={(): Highcharts.Options => calculateOptions(filename, sortedStrikePrice)}
      />
    </Card>
  );
};

export default OpenInterestStrikePriceChart;
