import { isNil } from 'lodash/fp';
import type { IBottomUpPerformanceAttributionQuery } from 'generated/graphql';

type TreeDataNodeOriginal =
  IBottomUpPerformanceAttributionQuery['portfolio']['multilevelPerformanceAttributions']['data'];
type TreeDataNode = Omit<TreeDataNodeOriginal, 'children'> & {
  // not precise type for the bottom level, but should work as we have levels count check in BottomUpAttributionReport.tsx
  children?: TreeDataNode[];
};

export type BottomUpRowData = {
  asset: TreeDataNode['asset'];
  name: TreeDataNode['name'];
  values: TreeDataNode['values'];
  path: string[];
};

/**
 * recursive tree data structure from backend {name, values, children: [{name, values, children: [...]}]}
 * should be converted to ag-grid flat format with array path hierarchy field
 * [{path: ['parentNode'], name: 'parent'}, {path: ['parentNode', 'child'], name: 'child'}]
 */
export function flatTreeDataAddPathHierarchy(
  node: TreeDataNode,
  parentPath: string[] = [],
  flatData: BottomUpRowData[] = []
): BottomUpRowData[] {
  const nodePathName = node.name ?? node.asset?.symbol;
  if (isNil(nodePathName)) {
    throw new Error(
      `The 'multilevelPerformanceAttributions' node must include either a 'name' or an 'asset'. Current node content: ${JSON.stringify(
        node
      )}`
    );
  }
  const currentPath = [...parentPath, nodePathName];

  const transformedNode = {
    asset: node.asset,
    name: node.name,
    values: node.values,
    path: currentPath,
  };

  flatData.push(transformedNode);

  for (const child of node.children ?? []) {
    flatTreeDataAddPathHierarchy(child, currentPath, flatData);
  }

  return flatData;
}
