import type { ApolloError } from '@apollo/client';
import Card from '@mui/joy/Card';
import type Highcharts from 'highcharts';
import isNil from 'lodash/fp/isNil';
import { bignumber } from 'mathjs';
import type { FunctionComponent } from 'react';
import {
  getHighchartColor,
  type HighchartSeries,
  percentageFormatter,
  returnProbabilityTooltipFormatter,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import HighChartsWrapper from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper';
import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import SectionColumn from 'components/technical/layout/Column/SectionColumn';
import { type IStrategyPerformanceQuery, useStrategyPerformanceQuery } from 'generated/graphql';

import { formatPercentage } from '../../formatter.utils.ts';
import { useFinalColorScheme } from '../../../useFinalColorScheme.ts';

const calculateOptions = (data: IStrategyPerformanceQuery): Highcharts.Options => {
  const returns = [...data.strategy.cumulativeReturns.backtest, ...data.strategy.cumulativeReturns.live];

  return {
    xAxis: {
      title: { text: 'Return' },
      labels: {
        formatter: percentageFormatter,
      },
    },
    tooltip: {
      formatter: ({ chart }): string => {
        return returnProbabilityTooltipFormatter({ chart, returnsNumber: returns.length });
      },
    },
    yAxis: {
      title: { text: 'Probability' },
      labels: {
        formatter: ({ value }): string => {
          return `${formatPercentage(bignumber(value).div(returns.length).toNumber())}`;
        },
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      histogram: {
        binsNumber: 20,
      },
    },
  };
};

const calculateChartData = (colorScheme: 'dark' | 'light', data: IStrategyPerformanceQuery): HighchartSeries[] => {
  const returns = [...data.strategy.cumulativeReturns.backtest, ...data.strategy.cumulativeReturns.live];
  // we need to convert from cumulative to daily returns
  const finalReturns = returns
    .map((current, i) => {
      if (i > 0) {
        const previous = returns[i - 1];
        return current.value / previous.value - 1;
      }
    })
    .filter((data): data is number => !isNil(data));
  return [
    {
      // Scatter series is used to calculate the histogram and accepts data as number[]
      data: finalReturns,
      type: 'scatter',
      visible: false,
    },
    {
      name: 'Probability',
      baseSeries: 0,
      color: getHighchartColor(colorScheme, 0),
      type: 'histogram',
    },
  ];
};

const StrategyReturnDistributionChart: FunctionComponent<{
  queryOutput: {
    data: IStrategyPerformanceQuery | undefined;
    loading: boolean;
    error?: ApolloError;
  };
}> = ({ queryOutput }) => {
  const colorScheme = useFinalColorScheme();
  return (
    <Card>
      <HighChartsWrapper<IStrategyPerformanceQuery>
        {...queryOutput}
        calculateOptions={calculateOptions}
        calculateChartData={(data) => calculateChartData(colorScheme, data)}
        exporting={false}
      />
    </Card>
  );
};

const StrategyReturnDistributionContainer: FunctionComponent<{ strategy: Label }> = ({ strategy }) => {
  const queryOutput = useStrategyPerformanceQuery({ variables: { label: strategy } });

  return (
    <SectionColumn>
      <HeaderBar title="Return distribution" />
      <StrategyReturnDistributionChart queryOutput={queryOutput} />
    </SectionColumn>
  );
};

export default StrategyReturnDistributionContainer;
