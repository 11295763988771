import { Box, Stack } from '@mui/joy';
import type { ColDef, ValueGetterParams } from 'ag-grid-community';
import uniq from 'lodash/fp/uniq';
import { type ReactElement, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import GButton from 'components/technical/inputs/GButton/GButton.tsx';
import { isNil, mapValues } from 'lodash/fp';
import { type BigNumber, bignumber } from 'mathjs';
import GAgGrid from '../../../../../technical/grids/GAgGrid.tsx';
import { defaultRowSpacing } from '../../../../../StackSpacing.ts';
import { getAssetCategory } from '../../../../../market/asset/AssetService.tsx';
import { gridWithInputStyles } from 'components/technical/grids/gridStyles.ts';
import bigNumMath from 'bigNumMath.ts';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import { defaultCol } from '../../initialPortfolio/InitialPortfolioStep.utils.tsx';
import type { AssetOptimizerInputFields } from '../AssetOptimizer.validation.ts';
import { syncOutlookWithGivenUniverseFields } from '../../assumptionsAndOutlook/AssumptionsAndOutlook.utils.ts';
import { InputCellRenderer } from '../../initialPortfolio/InputCellRenderer.tsx';
import type { NotVerifiedAsset } from '../../../../../market/asset/AssetLabelService.ts';

type InitialPortfolioRow = NotVerifiedAsset & { label: string; id: string };

const InitialPortfolioStep = ({
  assets,
  assetIdToClusterToGroup,
  aggregatedPortfolioValuesByAsset,
  goToNextStep,
}: {
  assets: InitialPortfolioRow[];
  goToNextStep: () => void;
  aggregatedPortfolioValuesByAsset: Map<string, BigNumber>;
  assetIdToClusterToGroup: Record<string, Record<string, string>>;
}): ReactElement => {
  const { getValues, setValue } = useFormContext<AssetOptimizerInputFields>();
  const { showErrorMessage } = useFeedback();

  const columns: ColDef<InitialPortfolioRow>[] = useMemo(() => {
    // { assetId: { cluster: group } } -values-> { cluster: group } -keys-> [cluster]
    const clusters = uniq(
      Object.values(assetIdToClusterToGroup).flatMap((clusterToGroup) => Object.keys(clusterToGroup))
    );

    return [
      {
        headerName: 'Symbol',
        field: 'symbol',
      },
      {
        colId: 'assetType',
        headerName: 'Type',
        valueGetter: (params: ValueGetterParams<InitialPortfolioRow, string>): string | undefined => {
          if (!params.data) {
            return undefined;
          }

          return getAssetCategory(params.data);
        },
      },
      ...clusters.map(
        (cluster): ColDef<InitialPortfolioRow> => ({
          colId: `cluster-${cluster}`,
          headerName: cluster,
          valueGetter: (params: ValueGetterParams<InitialPortfolioRow, string>): string | undefined => {
            if (!params.data) {
              return undefined;
            }

            const underlyingAssetId = params.data.id;
            if (!underlyingAssetId) {
              return undefined;
            }
            return assetIdToClusterToGroup[underlyingAssetId]?.[cluster];
          },
        })
      ),
      {
        colId: 'givenPortfolio',
        headerName: 'Cash weight',
        cellRenderer: InputCellRenderer,
        cellStyle: gridWithInputStyles.inputCellStyle,
        filter: 'agNumberColumnFilter',
        valueGetter: (params: ValueGetterParams<InitialPortfolioRow, number>): number | undefined => {
          if (!params.data) {
            return undefined;
          }
          const assetId = params.data.id;
          const value = getValues(`givenPortfolio.${assetId}`);

          return value ? bignumber(value).toNumber() : undefined;
        },
      },
    ];
  }, [assetIdToClusterToGroup, getValues]);

  return (
    <Stack spacing={defaultRowSpacing}>
      <Stack spacing={defaultRowSpacing} direction="row-reverse">
        <GButton
          onClick={() => {
            const importedGivenPortfolioInCash = Object.fromEntries(
              assets.map((asset) => [asset.id, aggregatedPortfolioValuesByAsset.get(asset.id) ?? null])
            );
            const portfolioSum = bigNumMath.sum(
              Object.values(importedGivenPortfolioInCash).map((v) => v ?? bignumber(0))
            );
            if (portfolioSum.toDP(2).isZero()) {
              showErrorMessage('Can not import portfolio: equity is zero');
            }
            const importedGivenPortfolioInPercent = mapValues((value) => {
              if (isNil(value)) {
                return null;
              }
              // multiply by 100 for easier render as input field operate in %
              const rounded = bignumber(value).div(portfolioSum).mul(100).toDP(2);

              return rounded.isZero() ? null : rounded.toString();
            }, importedGivenPortfolioInCash);
            setValue('givenPortfolio', importedGivenPortfolioInPercent);
            syncOutlookWithGivenUniverseFields(getValues, setValue);
          }}
        >
          Import from portfolio
        </GButton>
        <GButton
          variant="plain"
          onClick={(): void => {
            const emptyGivenPortfolio = Object.fromEntries(assets.map((asset) => [asset.id, null]));
            setValue('givenPortfolio', emptyGivenPortfolio);
            syncOutlookWithGivenUniverseFields(getValues, setValue);
          }}
        >
          Clear all
        </GButton>
      </Stack>
      <Box height="500px">
        <GAgGrid<InitialPortfolioRow>
          rowSelection="multiple"
          suppressRowClickSelection
          rowData={assets}
          columnDefs={columns}
          defaultColDef={defaultCol}
          autoSizeStrategy={{ type: 'fitGridWidth' }}
          rowHeight={gridWithInputStyles.rowHeight}
        />
      </Box>
      <GButton onClick={goToNextStep} sx={{ marginLeft: 'auto' }}>
        Next
      </GButton>
    </Stack>
  );
};

export default InitialPortfolioStep;
