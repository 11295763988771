import { Grid, Tab, TabList, TabPanel, Tabs } from '@mui/joy';
import type { ReactElement } from 'react';
import { useSyncSubAccountAssetFilterWithCurrentSubAccounts } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import type { AccountType } from './Account.types.ts';
import { CreateAccountDialog } from './CreateAccountDialog/CreateAccountDialog.tsx';
import { useAccountsQuery } from 'generated/graphql';
import { GENIE_VENUE, rawVenues } from '../../venue/VenueData.tsx';
import GCard from 'components/technical/GCard/GCard.tsx';
import AccountCard from './AccountCard.tsx';
import WithDialog from 'components/technical/inputs/withDialogHoc/WithDialog.tsx';
import { AccountType as AccountTypeEnum } from './AccountService.tsx';

const GCardWithDialog = WithDialog(GCard);
const cardHeight = '14rem';

const AccountList = (): ReactElement => {
  const { data, Fallback, loaded } = useDefaultErrorHandling(useAccountsQuery());

  useSyncSubAccountAssetFilterWithCurrentSubAccounts(data?.portfolio.accounts.flatMap((a) => a.subAccounts));

  if (!loaded) {
    return <Fallback />;
  }

  const gridWidth = { sm: 12, md: 6, lg: 4, xl: 3 };

  const [CEXsCustodiansAccounts, blockchainWalletsAccounts, OTCAccounts] = data.portfolio.accounts.reduce<
    [AccountType[], AccountType[], AccountType[]]
  >(
    (accumulator, account) => {
      if (rawVenues[account.venue.label].blockchain) {
        accumulator[1].push(account);
      } else if (account.venue.label === GENIE_VENUE) {
        accumulator[2].push(account);
      } else {
        accumulator[0].push(account);
      }
      return accumulator;
    },
    [[], [], []]
  );

  return (
    <>
      <Tabs aria-label="Tabs" defaultValue={0}>
        <TabList>
          <Tab>CEXs/Custodians</Tab>
          <Tab>Blockchain wallets</Tab>
          <Tab>OTC</Tab>
        </TabList>
        <TabPanel value={0}>
          <Grid container spacing={3}>
            <GCardWithDialog
              renderDialog={({ onClose }): ReactElement => (
                <CreateAccountDialog onConnected={onClose} onClose={onClose} accountsType={AccountTypeEnum.Custodian} />
              )}
              height={cardHeight}
              gridWidth={gridWidth}
              addNewText="Add new account"
            />
            {CEXsCustodiansAccounts.map((account) => (
              <AccountCard key={account.id} account={account} gridWidth={gridWidth} height={cardHeight} />
            ))}
          </Grid>
        </TabPanel>
        <TabPanel value={1}>
          <Grid container spacing={3}>
            <GCardWithDialog
              renderDialog={({ onClose }): ReactElement => (
                <CreateAccountDialog
                  onConnected={onClose}
                  onClose={onClose}
                  accountsType={AccountTypeEnum.Blockchain}
                />
              )}
              height={cardHeight}
              gridWidth={gridWidth}
              addNewText="Add new account"
            />
            {blockchainWalletsAccounts.map((account) => (
              <AccountCard key={account.id} account={account} gridWidth={gridWidth} height={cardHeight} />
            ))}
          </Grid>
        </TabPanel>
        <TabPanel value={2}>
          <Grid container spacing={3}>
            <GCardWithDialog
              renderDialog={({ onClose }): ReactElement => (
                <CreateAccountDialog onConnected={onClose} onClose={onClose} accountsType={AccountTypeEnum.Virtual} />
              )}
              height={cardHeight}
              gridWidth={gridWidth}
              addNewText="Add new account"
            />
            {OTCAccounts.map((account) => (
              <AccountCard key={account.id} account={account} gridWidth={gridWidth} height={cardHeight} />
            ))}
          </Grid>
        </TabPanel>
      </Tabs>
    </>
  );
};

export default AccountList;
