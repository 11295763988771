import PortfolioConstraintsStep from './PortfolioConstraintsStep.tsx';
import type { AssetLabelInput } from '../../../../market/asset/AssetLabelService.ts';
import type { StepConfig } from '../../../../technical/wizard/StepConfig.ts';
import type { PortfolioLevelConstraintsStepInput } from './PortfolioConstraintsStep.validation.ts';
import type { SelectOption } from '../../../../technical/inputs/Select/Select.props.ts';
import type { ISecondaryConstrainedQuantity } from '../../../../../generated/graphql.tsx';

export const config = (
  benchmarks: AssetLabelInput[],
  secondaryConstraintQuantityValues: SelectOption<ISecondaryConstrainedQuantity>[],
  goToNextStep: () => void
): StepConfig<PortfolioLevelConstraintsStepInput> => ({
  label: 'Portfolio level constraints',
  element: (
    <PortfolioConstraintsStep
      goToNextStep={goToNextStep}
      benchmarks={benchmarks}
      secondaryConstraintOptions={secondaryConstraintQuantityValues}
    />
  ),
  fields: ['portfolioConstraints'],
});
