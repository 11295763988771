import * as yup from 'yup';

export const updatePasswordSchema = yup.object().shape({
  currentPassword: yup.string().required(),
  newPassword: yup.string().required().min(8),
  newPasswordRepeat: yup
    .string()
    .required()
    .test('equalNewPassword', 'passwords do not match', (value, context) => {
      return value === context.parent.newPassword;
    }),
});
