import { Stack } from '@mui/joy';
import type { ReactElement } from 'react';

import ErrorMessage from 'components/technical/ErrorMessage';
import Loader from 'components/technical/Loader/Loader';
import { EnableMfaDialogShell } from './EnableMfaDialogShell';
import SetupMfaSteps from './SetupMfaSteps';
import useSetupTotp from './UseSetupTotp';
import { useAuth } from '../../../../UseAuth.tsx';

const EnableMFADialogContainer = ({ onClose }: { onClose: () => void }): ReactElement => {
  const auth = useAuth();
  const { code, error } = useSetupTotp();

  if (error) {
    return (
      <EnableMfaDialogShell onClose={onClose}>
        <Stack alignItems="center" spacing={1.5}>
          <ErrorMessage>{error.message}</ErrorMessage>
        </Stack>
      </EnableMfaDialogShell>
    );
  }

  if (!code || !auth.user) {
    return (
      <EnableMfaDialogShell onClose={onClose}>
        <Stack alignItems="center" spacing={1.5}>
          <Loader />
        </Stack>
      </EnableMfaDialogShell>
    );
  }

  return <SetupMfaSteps code={code} email={auth.user.email} onClose={onClose} />;
};

export default EnableMFADialogContainer;
