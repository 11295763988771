import { Card, Divider, Grid, Stack } from '@mui/joy';
import type { Dayjs } from 'dayjs';
import { type ReactElement, useState } from 'react';
import { convertDateInUtcToUTCISODate } from 'components/date.utils';
import { getDefaultRange } from 'components/predefinedDateRanges';

import type { SelectedChartElement } from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import HeaderBar from 'components/technical/HeaderBar/HeaderBar.tsx';
import { useSubAccountAssetFilters } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import { PortfolioSnapshotBalanceChart } from './PortfolioSnapshotBalanceChart.tsx';
import PortfolioSnapshotSunburstContainer from './PortfolioSnapshotSunburstContainer';
import { useSubAccountTwrQuery } from '../../../generated/graphql';
import { convertDateRangeToSinceToDate } from 'components/technical/inputs/date/dateRange.utils.ts';

const PortfolioSnapshotContainer = (): ReactElement => {
  const defaultDateRange = getDefaultRange();
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(defaultDateRange.value());
  const { subAccountAssetFilters } = useSubAccountAssetFilters();

  const queryOutput = useSubAccountTwrQuery({
    variables: {
      dates: convertDateRangeToSinceToDate(dateRange),
      subAccountAssetFilters,
    },
  });

  const [sunburstDate, setSunburstDate] = useState<Dayjs>(defaultDateRange.value()![1]);

  return (
    <Stack gap={1.5}>
      <HeaderBar title="Portfolio equity" />
      <Card>
        <Grid container spacing={5}>
          <Grid xs={12} sm={8}>
            <PortfolioSnapshotBalanceChart
              setDateRange={(range) => {
                setSunburstDate(range?.[1] ?? defaultDateRange.value()![1]);
                setDateRange(range);
              }}
              onElementSelected={(element: SelectedChartElement): void => setSunburstDate(element.xValue)}
              queryOutput={queryOutput}
            />
          </Grid>
          <Divider
            sx={{ height: '90%', marginY: 'auto', display: { xs: 'none', sm: 'block' } }}
            orientation="vertical"
          />
          <Grid sm={4} sx={{ height: '90%', marginY: 'auto', display: { xs: 'none', sm: 'block' } }}>
            <PortfolioSnapshotSunburstContainer
              subAccountAssetFilters={subAccountAssetFilters}
              date={convertDateInUtcToUTCISODate(sunburstDate)}
            />
          </Grid>
        </Grid>
      </Card>
    </Stack>
  );
};

export default PortfolioSnapshotContainer;
