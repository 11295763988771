import isNil from 'lodash/fp/isNil';
import { bignumber } from 'mathjs';
import { type ReactElement, Suspense } from 'react';
import {
  type IMetricCalculatorParameterInput,
  type IPortfolioRiskMetricsContributionQueryVariables,
  type IPortfolioRiskMetricsInput,
  usePortfolioRiskMetricsContributionSuspenseQuery,
} from 'generated/graphql.tsx';

import type { AvailableContributionMetrics } from './AvailableContributionMetrics.ts';
import type { ParameterDescription } from './RiskMetricsParameterService.tsx';
import RiskMetricsContributionChart, { type RiskMetricWithValues } from './RiskMetricsContributionChart.tsx';
import Loader from '../../technical/Loader/Loader.tsx';
import { useAssetExposureOptions } from './RiskAggregationsService.ts';
import type { GroupWithAssetId } from '../../portfolio/dashboard/PositionAggregationsService.ts';
import { groupBy } from 'lodash/fp';
import GErrorBoundary from '../../technical/GErrorBoundary.tsx';
import { useGenerateKeyOnValueChanged } from '../../UseGenerateKeyOnValueChanged.tsx';

const mergeParameterValues = (
  paramOutputs: {
    name: string;
    intValue?: number | null | undefined;
    strValue?: string | null | undefined;
    asset?: { id: string } | null | undefined;
  }[][]
): IMetricCalculatorParameterInput[] => {
  const params = new Map<string, { name: string; intValues: Set<number>; strValues: Set<string> }>();

  for (const paramCombination of paramOutputs) {
    for (const paramOutput of paramCombination) {
      let param = params.get(paramOutput.name);
      if (isNil(param)) {
        param = {
          name: paramOutput.name,
          intValues: new Set<number>(),
          strValues: new Set(),
        };

        params.set(paramOutput.name, param);
      }

      if (!isNil(paramOutput.intValue)) {
        param.intValues.add(paramOutput.intValue);
      }

      if (!isNil(paramOutput.strValue)) {
        param.strValues.add(paramOutput.strValue);
      }

      if (!isNil(paramOutput.asset)) {
        param.strValues.add(paramOutput.asset?.id);
      }
    }
  }

  return Array.from(params.values()).map((param) => ({
    name: param.name,
    intValues: Array.from(param.intValues),
    strValues: Array.from(param.strValues),
  }));
};

const createRequestInput = (
  availableContributionMetrics: AvailableContributionMetrics | null,
  filters: Pick<IPortfolioRiskMetricsInput, 'portfolioDefinitionIds' | 'subAccountAssetFilter'>
): IPortfolioRiskMetricsContributionQueryVariables | undefined => {
  if (isNil(availableContributionMetrics)) {
    return undefined;
  }

  return {
    input: {
      portfolioDefinitionIds: filters.portfolioDefinitionIds,
      subAccountAssetFilter: filters.subAccountAssetFilter,
      date: availableContributionMetrics.date,
      metrics: Object.entries(availableContributionMetrics.metrics).map(([metric, paramCombs]) => ({
        label: metric,
        parameters: mergeParameterValues(paramCombs),
      })),
    },
  };
};

const RiskMetricsAssetContributionChart = ({
  availableContributionMetrics,
  groups,
  filters,
  parameterDescription,
}: {
  availableContributionMetrics: AvailableContributionMetrics;
  groups: GroupWithAssetId[];
  filters: Pick<IPortfolioRiskMetricsInput, 'portfolioDefinitionIds' | 'subAccountAssetFilter'>;
  parameterDescription: Map<string, ParameterDescription[]>;
}): ReactElement => {
  const exposureOptions = useAssetExposureOptions({ groups });
  const requestInput = createRequestInput(availableContributionMetrics, filters);
  const skip = isNil(requestInput);
  const { data } = usePortfolioRiskMetricsContributionSuspenseQuery({
    variables: requestInput,
    skip,
  });

  return (
    <RiskMetricsContributionChart
      contributions={
        skip
          ? []
          : Object.values(
              groupBy((contrib) => contrib.portfolioDefinition?.id ?? '', data.portfolio.metricContributions)
            ).flatMap((contribs) =>
              contribs.map((cont) => ({
                portfolio: cont.portfolioDefinition ?? {
                  id: '',
                  name: '',
                },
                riskMetrics: cont.series.map(
                  (serie): RiskMetricWithValues => ({
                    label: cont.label,
                    parameters: serie.parameters,
                    paramDescriptions: parameterDescription.get(cont.label) ?? [],
                    values: serie.values.map((val) => ({
                      item: val.asset,
                      value: bignumber(val.value).mul(serie.total),
                    })),
                  })
                ),
              }))
            )
      }
      exposureOptions={exposureOptions}
      showLegend={skip ? false : data.portfolio.metricContributions.length > 1}
    />
  );
};

const RiskMetricsAssetContributionChartContainer = ({
  availableContributionMetrics,
  groups,
  filters,
  parameterDescription,
}: {
  availableContributionMetrics: AvailableContributionMetrics;
  groups: GroupWithAssetId[];
  filters: Pick<IPortfolioRiskMetricsInput, 'portfolioDefinitionIds' | 'subAccountAssetFilter'>;
  parameterDescription: Map<string, ParameterDescription[]>;
}): ReactElement => {
  // pass key to clear error in tabs when form values change
  const boundaryKey = useGenerateKeyOnValueChanged(availableContributionMetrics);
  return (
    <GErrorBoundary key={boundaryKey}>
      <Suspense fallback={<Loader />}>
        <RiskMetricsAssetContributionChart
          availableContributionMetrics={availableContributionMetrics}
          groups={groups}
          filters={filters}
          parameterDescription={parameterDescription}
        />
      </Suspense>
    </GErrorBoundary>
  );
};

export default RiskMetricsAssetContributionChartContainer;
