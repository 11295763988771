import { sortBy } from 'lodash/fp';
import type { FunctionComponent } from 'react';
import { useAuth } from 'UseAuth';
import { useImpersonateUser } from 'UseImpersonateUser';
import { logErrorOnce } from 'components/log.utils';
import StaticSingleAutocomplete from 'components/technical/inputs/Autocomplete/StaticSingleAutocomplete';
import Loader from 'components/technical/Loader/Loader';
import type { useUsersQuery } from 'generated/graphql';

const ImpersonationAutocomplete: FunctionComponent<{
  usersQueryResult: ReturnType<typeof useUsersQuery>;
}> = ({ usersQueryResult }) => {
  const auth = useAuth();
  const impersonateUser = useImpersonateUser();

  if (!auth.user) {
    return null;
  }

  if (usersQueryResult.loading) {
    return <Loader width="normal" variant="small" />;
  }

  if (usersQueryResult.error) {
    logErrorOnce('Error loading users for impersonation autocomplete', usersQueryResult.error);
    return null;
  }

  if (!usersQueryResult.data) {
    logErrorOnce('Error loading users for impersonation autocomplete, data is null');
    return null;
  }

  const users = usersQueryResult.data.management.users;

  let options = users.map((user) => {
    // length > 1 to avoid showing common "-" placeholder in name
    const label = user.name && user.name.length > 1 ? user.name : user.email;
    return {
      value: user.id,
      label,
      key: user.id,
      inputText: label,
      searchText: [user.name, user.email, user.id].join('|'),
      isOriginalUser: user.id === auth.originalUserId,
    };
  });

  // original user should be first option for easy unimpersonation
  options = sortBy((opt) => [!opt.isOriginalUser, opt.label.toLowerCase()], options);

  // keep filtered options in so that we know which user would appear as next when we go through all users
  return (
    <StaticSingleAutocomplete
      value={auth.user.id}
      width="normal"
      filterSelectedOptions={false}
      options={options}
      onChange={(value): void => {
        const user = users.find((user) => user.id === value);
        if (user) {
          impersonateUser(user);
          // app would fetch new user data anyway
          // but we would have moment when part of page shows old user data
          window.location.reload();
        }
      }}
    />
  );
};

export default ImpersonationAutocomplete;
