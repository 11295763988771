import {
  type DescriptionStepInput,
  type DescriptionStepOutput,
  schema as descriptionSchema,
} from '../description/DescriptionStep.validation';
import {
  type ObjectivesStepInput,
  type ObjectivesStepOutput,
  schema as objectivesSchema,
} from '../objective/ObjectivesStep.validation';
import {
  createSchema as createPortfolioConstraintSchema,
  type PortfolioConstraintsStepOutput,
  type PortfolioLevelConstraintsStepInput,
} from '../portfolioConstraints/PortfolioConstraintsStep.validation';
import {
  type AllocationConstraintsInput,
  type AllocationConstraintsOutput,
  schema as allocationConstraintsSchema,
} from '../allocationConstraints/AllocationConstraintsStep.validation';
import {
  type PortfolioUniverseStepInput,
  type PortfolioUniverseStepOutput,
  schema as portfolioUniverseSchema,
} from './portfolioUniverse/PortfolioUniverseStep.validation';
import {
  type AssumptionsAndOutlookStepInput,
  type AssumptionsAndOutlookStepOutput,
  schema as assumptionsAndOutlookSchema,
} from '../assumptionsAndOutlook/AssumptionsAndOutlook.validation';
import type {
  InitialPortfolioStepInput,
  InitialPortfolioStepOutput,
} from '../initialPortfolio/InitialPortfolio.validation';
import type { SelectOption } from '../../../../technical/inputs/Select/Select.props.ts';
import { ISecondaryConstrainedQuantity } from '../../../../../generated/graphql.tsx';

export const secondaryConstraintQuantityValues: SelectOption<ISecondaryConstrainedQuantity>[] = [
  {
    value: ISecondaryConstrainedQuantity.Beta,
    label: 'Expected beta',
    key: 'Expected beta',
  },
];

export type PortfolioOptimizerOutputFields = DescriptionStepOutput &
  ObjectivesStepOutput &
  PortfolioConstraintsStepOutput &
  InitialPortfolioStepOutput &
  PortfolioUniverseStepOutput &
  AllocationConstraintsOutput &
  AssumptionsAndOutlookStepOutput;

export type PortfolioOptimizerInputFields = DescriptionStepInput &
  ObjectivesStepInput &
  PortfolioLevelConstraintsStepInput &
  InitialPortfolioStepInput &
  PortfolioUniverseStepInput &
  AllocationConstraintsInput &
  AssumptionsAndOutlookStepInput;

export const schema = descriptionSchema
  .concat(objectivesSchema)
  .concat(createPortfolioConstraintSchema(secondaryConstraintQuantityValues))
  .concat(portfolioUniverseSchema)
  .concat(allocationConstraintsSchema)
  .concat(assumptionsAndOutlookSchema);
