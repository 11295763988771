import dayjs from 'dayjs';
import type { FunctionComponent } from 'react';
import { useParams } from 'react-router';
import { convertDateInUtcToUTCISODate } from 'components/date.utils';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import { IAssetType, IDerivativeType, useAllDerivativesQuery } from 'generated/graphql';

import DerivativeList from './DerivativeList/DerivativeList';
import { type DerivativeAsset, isDerivativeAsset } from '../../Asset.types.ts';

const PublicAssetDerivativesTab: FunctionComponent = () => {
  const params = useParams();

  const assetId = params.assetId!;
  const { loaded, Fallback, data } = useDefaultErrorHandling(
    useAllDerivativesQuery({
      variables: {
        filters: {
          expirationSince: convertDateInUtcToUTCISODate(dayjs.utc()).toString(),
          baseAssetIds: [assetId],
          assetTypes: [IAssetType.Derivative],
          derivativeTypes: [IDerivativeType.Future, IDerivativeType.PerpetualFuture],
        },
      },
    })
  );

  if (!loaded) {
    return <Fallback />;
  }

  const derivativesByAsset: DerivativeAsset[] = data.assets.list
    .filter((asset) => asset.derivativeDetails?.baseAsset.id === assetId)
    .filter((asset): asset is DerivativeAsset => isDerivativeAsset(asset));

  if (derivativesByAsset.length === 0) {
    return <>No derivatives for this asset</>;
  }

  return <DerivativeList derivatives={derivativesByAsset} />;
};
export default PublicAssetDerivativesTab;
