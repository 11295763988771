import { Box, Typography } from '@mui/joy';
import type { FunctionComponent } from 'react';

import type { GTableCellProps } from './GTableCell.props';

const GTableCell: FunctionComponent<GTableCellProps> = ({
  align = 'left',
  children,
  first = false,
  last = false,
  hideFirstLastPadding = false,
  body = true,
}) => {
  const alignmentSx = {
    left: { textAlign: 'left', justifyContent: 'flex-start' },
    center: { textAlign: 'center', justifyContent: 'center' },
    right: { textAlign: 'right', justifyContent: 'flex-end' },
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '0.5rem',
        background: 'inherit',
        ...alignmentSx[align],
        ...(first && hideFirstLastPadding && { paddingLeft: 0 }),
        ...(last && hideFirstLastPadding && { paddingRight: 0 }),
      }}
    >
      {body ? (
        <Typography level="body-xs" display="contents" component="div">
          {children}
        </Typography>
      ) : (
        children
      )}
    </Box>
  );
};

export default GTableCell;
