import type { Element, Root } from 'hast';
import { visit } from 'unist-util-visit';
import type { ReactElement } from 'react';
import Markdown from 'react-markdown';
import { Box, Divider, List, ListItem, ListItemContent, type Theme, Typography } from '@mui/joy';
import GLink from './GLink/GLink.tsx';

const rehypeInlineCodeProperty = () => {
  return (tree: Root) => {
    visit(
      tree,
      (el) => el.type === 'element' && (el as Element).tagName === 'code',
      (node, _index, parent): void => {
        const castedNode = node as Element;
        const castedParent = parent as Element;
        if (parent && castedParent.tagName === 'pre') {
          castedNode.properties.inline = false;
        } else {
          castedNode.properties.inline = true;
        }
      }
    );
  };
};

export const GMarkdown = ({ children }: { children: string }): ReactElement => {
  return (
    <Markdown
      disallowedElements={['table']}
      rehypePlugins={[rehypeInlineCodeProperty]}
      components={{
        hr: () => <Divider />,
        a: ({ href, children }) => (
          <GLink to={href!} underline={'always'}>
            {children}
          </GLink>
        ),

        p: ({ children }) => <Typography sx={{ mt: 1 }}>{children}</Typography>,
        del: ({ children }) => <Typography sx={{ textDecoration: 'line-through' }}>{children}</Typography>,
        em: ({ children }) => (
          <Typography sx={{ fontStyle: 'italic' }} component={'em'} display={'inline'}>
            {children}
          </Typography>
        ),
        strong: ({ children }) => (
          <Typography sx={{ fontWeight: 'bold' }} component={'strong'} display={'inline'}>
            {children}
          </Typography>
        ),
        b: ({ children }) => (
          <Typography sx={{ fontWeight: 'bold' }} component={'b'} display={'inline'}>
            {children}
          </Typography>
        ),
        h1: ({ children }) => <Typography level={'h1'}>{children}</Typography>,
        h2: ({ children }) => <Typography level={'h2'}>{children}</Typography>,
        h3: ({ children }) => <Typography level={'h3'}>{children}</Typography>,
        h4: ({ children }) => <Typography level={'h4'}>{children}</Typography>,
        h5: ({ children }) => <Typography level={'title-lg'}>{children}</Typography>,
        h6: ({ children }) => <Typography level={'title-md'}>{children}</Typography>,

        ol: ({ children }) => (
          <List
            sx={{
              '--_List-markerType': 'decimal',
              '& .MuiListItem-root': {
                display: 'list-item',
              },
            }}
          >
            {children}
          </List>
        ),
        ul: ({ children }) => {
          return (
            <List
              sx={{
                '--_List-markerType': 'disc',
                '& .MuiListItem-root': {
                  display: 'list-item',
                },
              }}
            >
              {children}
            </List>
          );
        },
        li: ({ children }) => {
          return (
            <ListItem sx={{ ml: 2 }}>
              <ListItemContent component={'div'}>{children}</ListItemContent>
            </ListItem>
          );
        },
        code: ({ children, ...props }) => {
          // added by plugin
          // biome-ignore lint/suspicious/noExplicitAny: prop added dynamically by a rehypeInlineCode plugin
          const inline = (props as any).inline;
          const sx = (theme: Theme) => ({ px: 0.5, background: theme.palette.neutral.softBg });

          if (inline) {
            return (
              <Box component={'span'} sx={sx}>
                {children}
              </Box>
            );
          }

          return (
            <Box component={'div'} sx={sx}>
              {children}
            </Box>
          );
        },
      }}
    >
      {children}
    </Markdown>
  );
};
