import { Chip, Radio } from '@mui/joy';
import type { ReactElement } from 'react';

import type { ReportPreset } from './GAgGridPresets.types';

const PresetChip = ({
  checked,
  preset,
  onChange,
}: {
  preset: ReportPreset;
  checked: boolean;
  onChange: (presetId: string) => void;
}): ReactElement => {
  const checkedUncheckedColor = checked ? 'primary' : 'neutral';
  return (
    <Chip variant="plain" color={checkedUncheckedColor}>
      <Radio
        variant="outlined"
        color={checkedUncheckedColor}
        slotProps={{
          input: checked
            ? {
                sx: { cursor: 'default' },
              }
            : {},
        }}
        disableIcon
        overlay
        label={preset.name}
        value={preset.name}
        checked={checked}
        onChange={(event): void => {
          if (event.target.checked) {
            onChange(preset.name);
          }
        }}
      />
    </Chip>
  );
};

export default PresetChip;
