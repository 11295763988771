import { Box, Stack, Typography } from '@mui/joy';
import type { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import type { ReactElement } from 'react';
import { useForm } from 'react-hook-form';

import CognitoApiErrorMessage from 'components/technical/form/CognitoErrorMessage';
import GFormProvider from 'components/technical/form/GFormProvider';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton.tsx';
import { useCognitoApiError } from 'components/technical/form/UseCognitoApiError.tsx';
import { FormInput } from 'components/technical/inputs';
import GoBackButton from 'components/technical/inputs/GButton/GoBackButton';
import { mfaCodeSchema } from './MfaCodeSchema.tsx';

export const ProvideMfaCodeStep = ({
  onGoBack,
  onFinishedLogging,
  user,
}: {
  onGoBack: () => void;
  onFinishedLogging: () => void;
  user: CognitoUser;
}): ReactElement => {
  const methods = useForm<{ code: string }>({
    resolver: gYupResolver(mfaCodeSchema),
    defaultValues: {
      code: '',
    },
  });

  const onError = useCognitoApiError(methods);
  const handleFormSubmit = async (data: { code: string }): Promise<void> => {
    const { code } = data;

    try {
      await Auth.confirmSignIn(
        user,
        code.replace(/ /g, ''),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        user.challengeName
      );
      methods.reset();
      onFinishedLogging();
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (e.code === 'CodeMismatchException') {
        onError('Incorrect code');
        return;
      }

      onError(e);
    }
  };

  return (
    <GFormProvider {...methods}>
      <Box
        component={'form'}
        onSubmit={methods.handleSubmit(handleFormSubmit)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '1rem',
          height: '100%',
          width: '100%',
        }}
      >
        <Stack spacing={4}>
          <Typography level={'h3'}>Verify your device</Typography>
          <Stack spacing={2}>
            <FormInput name="code" label="Code" type="password" width="fullWidth" autoComplete="off" />
            <CognitoApiErrorMessage justify="flex-start" />
          </Stack>
        </Stack>
        <Stack spacing={1.5}>
          <SubmitButton width="fullWidth">Confirm</SubmitButton>
          <GoBackButton onClick={onGoBack} disabled={methods.formState.isSubmitting} />
        </Stack>
      </Box>
    </GFormProvider>
  );
};
