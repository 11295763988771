import type { ReactElement } from 'react';

import ChartSkeleton from './SunburstChartSkeleton.svg?react';
import { defaultHeight } from '../Chart.constants';

const SunburstChartSkeleton = (): ReactElement => {
  return (
    <ChartSkeleton
      style={{
        height: defaultHeight,
        width: '100%',
      }}
    />
  );
};

export default SunburstChartSkeleton;
