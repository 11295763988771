import { Add } from '@mui/icons-material';
import { Card } from '@mui/joy';
import type { FunctionComponent, ReactElement } from 'react';
import { defaultHeaderActionProps } from 'components/technical/HeaderBar/DefaultHeaderActionProps.ts';
import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import DialogButton from 'components/technical/inputs/GButton/DialogButton';
import SectionColumn from 'components/technical/layout/Column/SectionColumn';
import Message from 'components/technical/Message';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';

import { CreateEventDialog } from './CreateEventDialog';
import { EventList } from './EventList';
import { IAssetType, useCalendarEventsQuery } from '../../../../../generated/graphql';
import { type Asset, getAssets } from '../../Asset.types';
import { useAsset } from '../../UseAsset';

const EventSection = ({ asset }: { asset: Asset }): ReactElement => {
  const { loaded, Fallback, data } = useDefaultErrorHandling(
    useCalendarEventsQuery({
      variables: {
        assetId: asset.id,
        assetTypes: [IAssetType.Public, IAssetType.Private],
      },
    })
  );

  if (!loaded) {
    return <Fallback />;
  }

  const supportedAssets = getAssets(data.assets.list).filter((asset) =>
    [IAssetType.Private, IAssetType.Public].includes(asset.type)
  );

  const events = data.calendar.calendarEvents;
  return (
    <SectionColumn>
      <HeaderBar title="Events">
        <DialogButton
          {...defaultHeaderActionProps}
          renderDialog={({ onClose }): ReactElement => (
            <CreateEventDialog onClose={onClose} onAdded={onClose} assets={supportedAssets} primaryAsset={asset} />
          )}
          startDecorator={<Add />}
        >
          Add event
        </DialogButton>
      </HeaderBar>
      {events.length === 0 ? (
        <Card size="sm">
          <Message>No events</Message>
        </Card>
      ) : (
        <EventList events={events} />
      )}
    </SectionColumn>
  );
};

const EventSectionContainer: FunctionComponent<{ assetId: string }> = ({ assetId }) => {
  const { loaded, data: asset, Fallback } = useAsset(assetId);

  if (!loaded) {
    return <Fallback />;
  }

  return <EventSection asset={asset} />;
};

export default EventSectionContainer;
