import type { FormInputFields } from './TransactionForm.validation.ts';
import { ISlotType, ITransactionLegType } from '../../../../generated/graphql.tsx';
import { useFieldArray, useFormState, useWatch } from 'react-hook-form';
import { Fragment, type ReactElement } from 'react';
import { Divider, Stack } from '@mui/joy';
import AddButton from '../../../technical/AddButton.tsx';
import type { PortfolioStepInput } from '../../../copilot/unifiedStressTest/wizard/PortfoliosStep.validation.tsx';
import TransactionLeg from './TransactionLeg.tsx';

export const TransactionLegList = (): ReactElement => {
  const { isSubmitting } = useFormState<PortfolioStepInput>();
  const { fields, append, remove } = useFieldArray<FormInputFields>({
    name: 'legs',
  });

  const subAccount = useWatch<FormInputFields, 'subAccount'>({
    name: 'subAccount',
  });

  return (
    <Stack gap={2}>
      {fields.map((field, index, allFields) => {
        return (
          <Fragment key={field.id}>
            <TransactionLeg
              subAccount={subAccount}
              index={index}
              onRemove={() => {
                remove(index);
              }}
            />
            {index < allFields.length - 1 && <Divider orientation={'horizontal'} key={field.id} />}
          </Fragment>
        );
      })}
      <AddButton
        disabled={isSubmitting}
        width="small"
        variant={'plain'}
        onClick={(): void => {
          append({
            asset: null,
            amount: null,
            marketValue: null,
            time: null,
            side: null,
            type: ITransactionLegType.Any,
            slot: ISlotType.Free,
          });
        }}
      >
        Add leg
      </AddButton>
    </Stack>
  );
};
