import { Grid, Stack } from '@mui/joy';
import type { SxProps } from '@mui/system';
import type { GridBaseProps } from '@mui/system/Unstable_Grid';
import type { ReactElement, ReactNode } from 'react';

const ResponsiveColumn = ({
  spacing = 0,
  children,
  sx,
  ...otherProps
}: {
  children: ReactNode;
  spacing?: number;
  sx?: SxProps;
} & Omit<GridBaseProps, 'spacing'>): ReactElement => {
  return (
    <Grid container justifyContent="center" sx={sx} width="100%">
      <Grid {...otherProps}>
        <Stack spacing={spacing}>{children}</Stack>
      </Grid>
    </Grid>
  );
};

export default ResponsiveColumn;
