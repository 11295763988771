import { type ReactElement, useState } from 'react';
import type * as Highcharts from 'highcharts';
import {
  dateTimeAxisFormat,
  dateTimeExportFormat,
  type HighchartSeries,
  noYAxisTitle,
  tooltipFormat,
} from '../../../technical/charts/HighChartsWrapper/Highchart.utils.ts';
import HeaderBar from '../../../technical/HeaderBar/HeaderBar.tsx';
import HighChartsContainer from '../../../technical/charts/HighChartsWrapper/HighChartsWrapper.tsx';
import type { PortfoliosResult } from './PortfolioResult.types.ts';
import { Stack } from '@mui/joy';
import { formatNumber } from '../../../formatter.utils.ts';
import dayjs, { type Dayjs } from 'dayjs';
import DateRangeInput from '../../../technical/inputs/date/DateRangeInput.tsx';
import { isValidDayjsDateRange, parseUtcDate } from '../../../date.utils.ts';
import isNil from 'lodash/fp/isNil';
import sortBy from 'lodash/fp/sortBy';
import groupBy from 'lodash/fp/groupBy';
import bigNumMath from '../../../../bigNumMath.ts';
import uniqBy from 'lodash/fp/uniqBy';

type GroupItem = {
  portfolio: string;
  portfolioId: string;
  factor: string;
  factorId: string;
  value: number;
};

const calculateOptions = (sortedCategories: { factor: string; factorId: string }[]): Highcharts.Options => {
  return {
    xAxis: {
      ...dateTimeAxisFormat.xAxis,
      categories: sortedCategories.map((cat) => cat.factor),
    },
    ...tooltipFormat,
    ...dateTimeExportFormat('historical-factor-stats'),
    ...noYAxisTitle,
  };
};

const calculateGroups = (data: PortfoliosResult, dateRange: [Dayjs, Dayjs] | null): GroupItem[] => {
  const betaPortfolioFactorRows = data.flatMap((portfolioResult) =>
    portfolioResult.results.flatMap((dayResult) => {
      const parsedDate = parseUtcDate(dayResult.date);
      if (!isNil(dateRange) && !parsedDate.isBetween(dateRange[0], dateRange[1])) {
        return [];
      }

      return dayResult.results
        .filter((factorRes) => !!factorRes.factor && !isNil(factorRes.beta))
        .map((factorResult) => ({
          portfolio: portfolioResult.portfolioDefinition,
          factor: factorResult.factor!,
          beta: factorResult.beta!,
        }));
    })
  );

  const groupedPerFactor = groupBy((row) => row.factor.id, betaPortfolioFactorRows);
  const rows: GroupItem[] = [];
  for (const factorGroup of Object.values(groupedPerFactor)) {
    const groupPerPortfolio = groupBy((row) => row.portfolio.id, factorGroup);

    for (const portfolioGroup of Object.values(groupPerPortfolio)) {
      const factor = portfolioGroup[0].factor;
      const portfolio = portfolioGroup[0].portfolio;
      const beta = bigNumMath.mean(portfolioGroup.map((row) => row.beta));
      rows.push({
        value: beta,
        factor: factor.symbol,
        factorId: factor.id,
        portfolio: portfolio.name,
        portfolioId: portfolio.id,
      });
    }
  }

  return rows;
};

const calculateChartData = (
  groupItems: GroupItem[],
  sortedCategories: { factor: string; factorId: string }[]
): HighchartSeries[] => {
  return Object.values(groupBy((item) => item.portfolioId, groupItems)).map((portfolioData) => {
    return {
      name: portfolioData[0].portfolio,
      data: sortedCategories.map((category) => {
        const group = portfolioData.find((item) => item.factorId === category.factorId);
        return isNil(group) ? { y: null } : { y: group.value, textValue: formatNumber(group.value) };
      }),
      type: 'column',
    };
  });
};

const FactorAverageChart = (props: { result: PortfoliosResult }): ReactElement => {
  const dates = props.result.flatMap((res) => res.results.map((row) => parseUtcDate(row.date)));
  const minDate = dayjs.min(dates);
  const maxDate = dayjs.max(dates);

  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(
    isNil(minDate) || isNil(maxDate) ? null : [minDate, maxDate]
  );

  const groups = calculateGroups(props.result, dateRange);
  const categories = sortBy(
    (group: GroupItem) => group.factor,
    uniqBy((group) => group.factorId, groups)
  );

  return (
    <>
      <HeaderBar title={'Average factors'} />
      <Stack spacing={2}>
        <DateRangeInput
          value={dateRange}
          width={'xl2'}
          onChange={setDateRange}
          minDate={minDate}
          maxDate={maxDate}
          error={isValidDayjsDateRange(dateRange) ? '' : 'Invalid range'}
        />
        <HighChartsContainer<GroupItem[]>
          loading={false}
          data={groups}
          calculateOptions={() => calculateOptions(categories)}
          calculateChartData={(groups): HighchartSeries[] => calculateChartData(groups, categories)}
        />
      </Stack>
    </>
  );
};

export default FactorAverageChart;
