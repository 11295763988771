import { Stack, Typography } from '@mui/joy';
import type { FunctionComponent } from 'react';

import type { FilterProps } from './Filter.props';
import { FormSlider } from '../inputs';

const Filter: FunctionComponent<FilterProps> = ({ name, title, min, max, getLabel }) => {
  return (
    <Stack
      spacing={1}
      sx={{
        padding: '0 1rem',
      }}
    >
      <Typography>{title}</Typography>
      <FormSlider name={name} showCurrentValue={false} min={min} max={max} showMinMaxValues getLabel={getLabel} />
    </Stack>
  );
};

export default Filter;
