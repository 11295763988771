import { Typography } from '@mui/joy';
import type { ReactElement } from 'react';
import { formatterForName } from 'components/formatter.utils';
import { getFormat } from 'components/metrics/MetricsData';

import { type PortfolioConstraints, formatPortfolioConstraintBound } from './OptimizerInputForResult.utils.ts';

function SecondaryPortfolioConstraint({
  constraint,
  quantityLabel,
}: {
  constraint: NonNullable<PortfolioConstraints['secondary']>;
  quantityLabel: string;
}): ReactElement {
  let betaAssetSymbol: string | undefined = '';
  let formatter = formatterForName('percentage');
  if (constraint.riskMetric) {
    // for secondary constraint quantityLable is "Expected beta" we need to avoid showing "Expected beta beta" and show only benchmark asset
    betaAssetSymbol = constraint.riskMetric?.benchmark?.symbol;
    betaAssetSymbol = betaAssetSymbol ? `${betaAssetSymbol} ` : '';
    formatter = formatterForName(getFormat(constraint.riskMetric.metricLabel));
  }
  return (
    <Typography>
      {quantityLabel} {betaAssetSymbol}
      {formatPortfolioConstraintBound(constraint.constraint, formatter)}
    </Typography>
  );
}

export default SecondaryPortfolioConstraint;
