import { type ReactElement, useEffect } from 'react';
import { type UseFormReturn, useWatch } from 'react-hook-form';

import ErrorMessage from 'components/technical/ErrorMessage';
import { FormDateTimeInput } from 'components/technical/form/FormDateTimeInput';
import FormRadioGroup from 'components/technical/form/FormRadioGroup';
import FormSelect from 'components/technical/form/FormSelect';
import { FormInput } from 'components/technical/inputs';
import RemoveButton from 'components/technical/RemoveButton';
import { type FormInputFields, periodUnits, vestingTypeValues } from './CreateInvestment.validation';
import { IVestingType } from '../../../generated/graphql';
import { useValueChanged } from '../../UseValueChanged';
import { Box } from '@mui/joy';

const RecurringSchedule = (props: { index: number }): ReactElement => {
  return (
    <>
      <FormDateTimeInput<FormInputFields>
        name={`schedules.${props.index}.recurring.begin` as const}
        label="Start date"
        width="fullWidth"
      />
      <FormDateTimeInput<FormInputFields>
        name={`schedules.${props.index}.recurring.end` as const}
        label="End date"
        width="fullWidth"
      />
      <FormInput<FormInputFields>
        name={`schedules.${props.index}.recurring.period.interval` as const}
        type="number"
        label="Period interval"
        width="fullWidth"
      />
      <FormSelect<FormInputFields>
        name={`schedules.${props.index}.recurring.period.unit` as const}
        options={periodUnits}
        label="Period"
        width="fullWidth"
      />
    </>
  );
};

const ImmediateSchedule = (props: { index: number }): ReactElement => {
  return (
    <FormDateTimeInput<FormInputFields>
      name={`schedules.${props.index}.immediate.date` as const}
      label="Date"
      width="fullWidth"
    />
  );
};

const vestingTypeWidth = '200px';
const dateWidth = '170px';
const vestingWidth = 'minmax(80px, 0.8fr)';
const periodWidth = 'minmax(60px, 1fr)';
const periodUnitWidth = 'minmax(100px, 1fr)';
const removeButtonWidth = 'minmax(32px, 0.4fr)';
const recurringGridColumns = [
  vestingTypeWidth,
  dateWidth,
  dateWidth,
  periodWidth,
  periodUnitWidth,
  vestingWidth,
  removeButtonWidth,
].join(' ');
const immediateGridColumns = [vestingTypeWidth, dateWidth, vestingWidth, removeButtonWidth].join(' ');

export const ScheduleRow = (props: {
  schedule: Record<'id', string>;
  remove: () => void;
  index: number;
  methods: UseFormReturn<FormInputFields>;
}): ReactElement => {
  const index = props.index;
  const typePath = `schedules.${index}.type` as const;
  const vestedPath = `schedules.${index}.vested` as const;
  const recurringSchedulePath = `schedules.${index}.recurring` as const;

  const vested = useWatch<FormInputFields>({
    name: vestedPath,
  });

  const vestedAmountChanged = useValueChanged(vested);
  const trigger = props.methods.trigger;
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    trigger('remainingVestedAmount');
  }, [vestedAmountChanged, trigger]);

  const type = useWatch<FormInputFields>({
    name: typePath,
  });

  const resetField = props.methods.resetField;
  useEffect(() => {
    if (type === IVestingType.Recurring) {
      resetField(`schedules.${index}.immediate` as const);
    } else {
      resetField(`schedules.${index}.recurring` as const);
    }
  }, [type, resetField, index]);

  const beginDate = useWatch<FormInputFields>({
    name: `schedules.${index}.recurring.begin` as const,
  });

  const beginDateChanged = useValueChanged(beginDate);
  const isSubmitted = props.methods.formState.isSubmitted;

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (isSubmitted) {
      trigger(`schedules.${index}.recurring.end` as const);
      trigger(recurringSchedulePath);
    }
  }, [beginDateChanged, trigger, index, isSubmitted, recurringSchedulePath]);

  const endDate = useWatch<FormInputFields>({
    name: `schedules.${index}.recurring.end` as const,
  });

  const endDateChanged = useValueChanged(endDate);
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (isSubmitted) {
      trigger(`schedules.${index}.recurring.begin` as const);
      trigger(recurringSchedulePath);
    }
  }, [endDateChanged, trigger, index, isSubmitted, recurringSchedulePath]);

  const periodUnit = useWatch<FormInputFields>({
    name: `schedules.${index}.recurring.period.unit` as const,
  });

  const periodUnitChanged = useValueChanged(periodUnit);
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (isSubmitted) {
      trigger(recurringSchedulePath);
    }
  }, [periodUnitChanged, trigger, isSubmitted, recurringSchedulePath]);

  const periodInterval = useWatch<FormInputFields>({
    name: `schedules.${index}.recurring.period.interval` as const,
  });

  const periodValueChanged = useValueChanged(periodInterval);
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (isSubmitted) {
      trigger(recurringSchedulePath);
    }
  }, [periodValueChanged, trigger, isSubmitted, recurringSchedulePath]);

  const recurringScheduleErrors = props.methods.getFieldState(recurringSchedulePath, props.methods.formState).error;

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridGap: '0.75rem',
          alignItems: 'end',
          justifyItems: 'start',
          gridTemplateColumns: type === IVestingType.Recurring ? recurringGridColumns : immediateGridColumns,
        }}
      >
        <FormRadioGroup<FormInputFields> name={typePath} label="Vesting type" options={vestingTypeValues} width="xl3" />
        {type === IVestingType.Recurring ? <RecurringSchedule index={index} /> : <ImmediateSchedule index={index} />}
        <FormInput<FormInputFields> name={vestedPath} type="number" label="Total vested amount" width="fullWidth" />
        <RemoveButton
          disabled={props.methods.formState.isSubmitting}
          onClick={(): void => {
            props.remove();
          }}
        />
      </Box>
      {recurringScheduleErrors && <ErrorMessage>{recurringScheduleErrors.message}</ErrorMessage>}
    </>
  );
};
