import type { ReactElement } from 'react';
import { Controller, type FieldPathValue, type FieldValues, type Path, useFormContext } from 'react-hook-form';

import SelectedChipsBoard, { type SelectedChipsBoardProps } from '../inputs/Autocomplete/SelectedChipsBoard';

// biome-ignore lint/suspicious/noExplicitAny:
export type FormSelectedChipsBoardProps<T extends FieldValues, N extends Path<T> = any> = Omit<
  SelectedChipsBoardProps<FieldPathValue<T, N>>,
  'value' | 'onDelete' | 'error'
> & {
  name: N;
};

// biome-ignore lint/suspicious/noExplicitAny:
const FormSelectedChipsBoard = <T extends FieldValues, N extends Path<T> = any>(
  props: FormSelectedChipsBoardProps<T, N>
): ReactElement => {
  /* jscpd:ignore-start */
  const { control } = useFormContext();

  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field: { value, onChange, onBlur, ref }, fieldState, formState }): ReactElement => (
        /* jscpd:ignore-end */
        <SelectedChipsBoard
          {...props}
          ref={ref}
          value={value}
          error={fieldState.error?.message}
          onDelete={(itemToDelete) => onChange(value.filter((item: unknown) => item !== itemToDelete))}
          onBlur={onBlur}
          disabled={(props.disabled ?? false) || formState.isSubmitting || formState.disabled}
        />
      )}
    />
  );
};

export default FormSelectedChipsBoard;
