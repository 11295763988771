import { Box, Textarea } from '@mui/joy';
import FormControl from '@mui/joy/FormControl';
import { type ChangeEvent, type ForwardedRef, forwardRef, type ReactElement } from 'react';

import type { GTextAreaProps } from './GTextAreaProps.ts';
import InputError from '../InputError';
import InputLabel from '../InputLabel';
import { calculatePlaceholder, shouldRenderInputLabel } from '../LabelService.ts';
import widthSx from '../../../width.styles.ts';

const GTextArea = (props: GTextAreaProps, ref: ForwardedRef<HTMLTextAreaElement>): ReactElement => {
  const { error, placeholder, fullHeight, ...rest } = props;
  const allWidthSx = {
    fullWidth: {
      width: '100%',
    },
    ...widthSx,
  };
  return (
    <Box
      sx={{
        ...allWidthSx[props.width],
        height: fullHeight ? '100%' : 'auto',
      }}
    >
      <FormControl
        error={!!props.error}
        disabled={props.disabled}
        size={props.size}
        style={{
          height: fullHeight ? '100%' : 'auto',
        }}
      >
        {shouldRenderInputLabel(props) ? <InputLabel {...props} /> : <></>}
        <Textarea
          placeholder={calculatePlaceholder(props)}
          {...rest}
          error={!!props.error}
          slotProps={{
            textarea: {
              ref,
            },
          }}
          sx={{
            height: fullHeight ? '100%' : 'auto',
          }}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>): void => {
            props.onChange?.(e.target.value);
          }}
        />
        <InputError error={props.error} />
      </FormControl>
    </Box>
  );
};

const ForwardedGTextArea = forwardRef(GTextArea);
export default ForwardedGTextArea;
