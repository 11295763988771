import { Link } from '@mui/joy';
import type { ReactElement, ReactNode } from 'react';

const ExternalLink = ({
  to,
  children,
  variant = 'normal',
}: {
  to: string;
  variant?: 'normal' | 'tooltip';
  children: ReactNode;
}): ReactElement => {
  return (
    <Link
      href={to}
      sx={{
        ...(variant === 'tooltip' && {
          color: 'inherit',
          textDecoration: 'underline',
          display: 'inline',
        }),
      }}
    >
      {children}
    </Link>
  );
};

export default ExternalLink;
