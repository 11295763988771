import type { ApolloError } from '@apollo/client';
import { Card } from '@mui/joy';
import dayjs from 'dayjs';
import { kebabCase } from 'lodash/fp';
import { bignumber } from 'mathjs';
import type { FunctionComponent } from 'react';
import { formatNumber, formatPercentage } from 'components/formatter.utils';
import { TwoThirdsLayout } from 'components/TwoThirdsLayout.tsx';
import {
  dateTimeAxisFormat,
  dateTimeExportFormat,
  getFormatter,
  type HighchartSeries,
  tooltipFormat,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import HighChartsContainer from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper';
import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import SectionColumn from 'components/technical/layout/Column/SectionColumn';
import type { IStrategyRollingMetricsQuery } from 'generated/graphql';
import type * as Highcharts from 'highcharts';
type StrategyRollingMetricsChartProps = {
  queryOutput: {
    data: IStrategyRollingMetricsQuery | undefined;
    error?: ApolloError;
    loading: boolean;
  };
  metricLabel: string;
  metricName: string;
  colorOfMean: string;
  colorOfData: string;
  fillOfData?: boolean;
  yAxisFormat?: 'number' | 'percentage';
};

const StrategyRollingMetricsChart: FunctionComponent<StrategyRollingMetricsChartProps> = ({
  queryOutput,
  metricLabel,
  metricName,
  colorOfMean,
  colorOfData,
  fillOfData,
  yAxisFormat = 'number',
}) => {
  const calculateChartData = (data: IStrategyRollingMetricsQuery): HighchartSeries[] => {
    const metricData = data.strategy.strategyRiskMetricsRolling.filter((row) => row.label === metricLabel)[0];

    return [
      {
        data: metricData.timeSeries.map((row) => ({
          x: dayjs.utc(row.time).valueOf(),
          y: bignumber(row.value).toNumber(),
          textValue:
            yAxisFormat === 'number'
              ? formatNumber(bignumber(row.value).toNumber())
              : formatPercentage(bignumber(row.value).toNumber()),
        })),

        name: metricName,
        type: fillOfData ? ('area' as const) : ('line' as const),
        color: colorOfData,
      },
      {
        data: metricData.timeSeries.map((row) => ({
          x: dayjs.utc(row.time).valueOf(),
          y: metricData.mean,
          textValue: yAxisFormat === 'number' ? formatNumber(metricData.mean) : formatPercentage(metricData.mean),
        })),
        type: 'line',
        color: colorOfMean,
        name: 'Mean',
        lineWidth: 2,
        dashStyle: 'LongDash',
      },
    ];
  };

  const calculateOptions = (): Highcharts.Options => {
    return {
      ...dateTimeAxisFormat,
      ...dateTimeExportFormat(`${kebabCase(metricName)}`),
      ...tooltipFormat,
      yAxis: {
        labels: {
          formatter: getFormatter(yAxisFormat),
        },
        title: {
          text: undefined,
        },
      },
      plotOptions: {
        line: {
          marker: {
            symbol: 'circle',
          },
        },
      },
    };
  };

  return (
    <TwoThirdsLayout
      left={
        <SectionColumn>
          <HeaderBar title={metricName} />
          <Card>
            <HighChartsContainer<IStrategyRollingMetricsQuery>
              {...queryOutput}
              calculateOptions={calculateOptions}
              calculateChartData={calculateChartData}
            />
          </Card>
        </SectionColumn>
      }
      right={null}
    />
  );
};
export default StrategyRollingMetricsChart;
