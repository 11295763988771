import { Stack, Typography } from '@mui/joy';
import type { ReactElement } from 'react';
import { useMatches } from 'react-router';

import AppbarActions from './AppbarActions/AppbarActions';
import AppBarProfile from './AppbarProfile/AppBarProfile';
import AppbarSearch from './AppbarSearch/AppbarSearch';
import Navbar from './Navbar';
import type { MatchedRoute } from '../../../routes/Route.types.ts';

const Appbar = (): ReactElement => {
  const routeMatches = useMatches() as MatchedRoute[];
  const title = routeMatches
    .reverse()
    .map((route): string | undefined => route.handle?.title)
    .find((title) => !!title);

  return (
    <Navbar>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        rowGap={0.5}
        flexWrap="wrap"
      >
        <Stack spacing={1}>{title && <Typography level={'h2'}>{title}</Typography>}</Stack>
        <AppbarSearch />
        <Stack direction="row" spacing={1} alignItems="center" flexWrap="wrap">
          <AppbarActions />
          <AppBarProfile />
        </Stack>
      </Stack>
    </Navbar>
  );
};

export default Appbar;
