import { Auth } from 'aws-amplify';
import type { ReactElement } from 'react';

import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import ConfirmationDialog from 'components/technical/form/dialog/ConfirmationDialog';

export const DisableMfaDialog = ({ onClose }: { onClose: () => void }): ReactElement => {
  const { showSuccessMessage } = useFeedback();

  const disableMfa = async (): Promise<void> => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    await Auth.setPreferredMFA(cognitoUser, 'NOMFA');
    showSuccessMessage('Two-factor authentication was successfully disabled');

    onClose();
  };

  return (
    <ConfirmationDialog
      onClose={onClose}
      onApprove={disableMfa}
      title="Disable two-factor authentication"
      okButtonText="Disable"
      cancelButtonText="Cancel"
    >
      You are about to disable the Two-Factor authentication. Are you sure you want to proceed?
    </ConfirmationDialog>
  );
};
