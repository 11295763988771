import type { DragEndEvent, DraggableAttributes } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { Box, Checkbox, Modal, ModalDialog, Stack } from '@mui/joy';
import ListItemContent from '@mui/joy/ListItemContent';
import { type ReactElement, useCallback, useState } from 'react';
import DraggableList from 'components/technical/DraggableList/DraggableList';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';

import { Header3 } from 'components/technical/Header3';
import { Pencil } from 'components/technical/icons';
import GButton from 'components/technical/inputs/GButton/GButton';
import type { Aggregation } from './PositionAggregationsService.ts';
import { swap } from '../../../arrayUtils';

interface Item {
  id: string;
  label: string;
  category: string;
  checked: boolean;
}

const EditSunburstAggregationsDialog = ({
  onClose,
  onEdited,
  availableAggregations,
  activeAggregations,
}: {
  onClose: () => void;
  onEdited: (aggs: { category: string; active: boolean }[]) => void;
  availableAggregations: Pick<Aggregation<unknown>, 'label' | 'category'>[];
  activeAggregations: string[];
}): ReactElement => {
  const [items, setItems] = useState<Item[]>(
    availableAggregations.map((agg) => ({
      id: agg.category,
      label: agg.label,
      category: agg.category,
      checked: activeAggregations.includes(agg.category),
    }))
  );

  const renderItem = useCallback(
    (item: Item, draggableAttrs: DraggableAttributes, listeners: SyntheticListenerMap | undefined): ReactElement => {
      return (
        <Stack direction="row" alignItems="center" spacing={1.5}>
          <Checkbox
            onChange={(e): void => {
              const newValue = e.target.checked;
              setItems((prev) =>
                prev.map((prevItem) => {
                  if (prevItem.id === item.id) {
                    return {
                      ...prevItem,
                      checked: newValue,
                    };
                  }
                  return prevItem;
                })
              );
            }}
            checked={item.checked}
          />
          <Box {...draggableAttrs} {...listeners} sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }}>
            <DragHandleIcon />
          </Box>
          <ListItemContent>{item.label}</ListItemContent>
        </Stack>
      );
    },
    []
  );

  const onDragEnd = (e: DragEndEvent): void => {
    const { active, over } = e;
    if (!over) {
      return;
    }

    if (over.id === active.id) {
      return;
    }

    const ids = items.map((item) => item.id);
    const oldIndex = ids.indexOf(active.id as string);
    const newIndex = ids.indexOf(over.id as string);
    const newItems = swap(items, oldIndex, newIndex);
    setItems(newItems);
  };

  return (
    <Modal open={true} onClose={onClose}>
      <ModalDialog>
        <GDialogHeader>Configure portfolio balance chart</GDialogHeader>
        <Stack spacing={1.5} alignItems="center">
          <div>
            <Header3 title="Aggregations" />
            <DraggableList<Item> dragHandle items={items} renderItem={renderItem} onDragEnd={onDragEnd} />
          </div>
          <GButton
            color="primary"
            onClick={(): void => {
              onEdited(
                items.map((item) => ({
                  category: item.category,
                  active: item.checked,
                }))
              );
            }}
            startDecorator={<Pencil />}
          >
            Save configuration
          </GButton>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};

export default EditSunburstAggregationsDialog;
