import { Stack } from '@mui/joy';
import { Auth } from 'aws-amplify';
import type { ReactElement } from 'react';
import { useForm } from 'react-hook-form';

import CognitoApiErrorMessage from 'components/technical/form/CognitoErrorMessage';
import GFormProvider from 'components/technical/form/GFormProvider';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton.tsx';
import { useCognitoApiError } from 'components/technical/form/UseCognitoApiError.tsx';
import { GDialogDescription } from 'components/technical/GDialogDescription/GDialogDescription.tsx';
import { FormInput } from 'components/technical/inputs';
import GoBackButton from 'components/technical/inputs/GButton/GoBackButton';
import ResponsiveColumn from 'components/technical/layout/Column/ResponsiveColumn';
import { confirmCodeSchema } from './ConfirmCodeSchema.tsx';

interface Input {
  confirmationCode: string;
}

const ConfirmCodeStep = ({ onNext, onGoBack }: { onNext: () => void; onGoBack: () => void }): ReactElement => {
  const methods = useForm<Input>({
    defaultValues: {
      confirmationCode: '',
    },
    resolver: gYupResolver(confirmCodeSchema),
  });

  const onError = useCognitoApiError(methods);
  const handleFormSubmit = async (data: Input): Promise<void> => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      await Auth.verifyTotpToken(cognitoUser, data.confirmationCode.replace(/ /g, ''));
      await Auth.setPreferredMFA(cognitoUser, 'TOTP');
      onNext();
    } catch (e) {
      onError(e);
      throw e;
    }
  };

  return (
    <GFormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
        <Stack spacing={1.5}>
          <GDialogDescription>Enter the 6 digit verification code from your app.</GDialogDescription>
          <FormInput type="password" name="confirmationCode" label="Verification code" width="fullWidth" />
          <CognitoApiErrorMessage />
          <ResponsiveColumn xs={4} spacing={1.5}>
            <SubmitButton>Confirm</SubmitButton>
            <GoBackButton onClick={onGoBack} />
          </ResponsiveColumn>
        </Stack>
      </form>
    </GFormProvider>
  );
};

export default ConfirmCodeStep;
