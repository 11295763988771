import AssumptionsAndOutlookStep from './AssumptionsAndOutlookStep.tsx';
import type { StepConfig } from '../../../../technical/wizard/StepConfig.ts';
import type { AssetOptimizerInputFields } from '../asset/AssetOptimizer.validation.ts';
import type { PortfolioOptimizerInputFields } from '../portfolio/PortfolioOptimizer.validation.ts';
import type { ColDef } from 'ag-grid-enterprise';
import type {
  ItemOutlookInput,
  ItemOutlookSource,
  RiskDistributionOption,
} from './AssumptionsAndOutlook.validation.tsx';
import type { SelectOption } from '../../../../technical/inputs/Select/Select.props.ts';
import type { IReturnMeasureNameUi } from '../../../../../generated/graphql.tsx';

export const config = ({
  columns,
  returnsForecast,
  riskBudgetForecast,
  multifactorValues,
  sourceLabels,
  goToNextStep,
  returnMeasureValues,
  riskDistributionValues,
  showYield,
}: {
  columns: ColDef<ItemOutlookInput>[];
  returnsForecast: Record<string, string>;
  riskBudgetForecast: Record<string, string>;
  sourceLabels: Record<ItemOutlookSource, string>;
  returnMeasureValues: SelectOption<IReturnMeasureNameUi>[];
  riskDistributionValues: SelectOption<RiskDistributionOption>[];
  multifactorValues: SelectOption<{
    id: number;
    maxFactors: number;
  }>[];
  showYield: boolean;
  goToNextStep: () => void;
}): StepConfig<AssetOptimizerInputFields | PortfolioOptimizerInputFields> => ({
  label: 'Assumptions and outlook',
  element: (
    <AssumptionsAndOutlookStep
      columns={columns}
      returnsForecast={returnsForecast}
      riskBudgetForecast={riskBudgetForecast}
      multifactorValues={multifactorValues}
      returnMeasureValues={returnMeasureValues}
      riskDistributionValues={riskDistributionValues}
      sourceLabels={sourceLabels}
      goToNextStep={goToNextStep}
      showYield={showYield}
    />
  ),
  fields: ['returnsForecast', 'riskBudgetAllocation', 'outlook', 'multifactor'],
});
