import capitalize from 'lodash/fp/capitalize';

import type { FormInputType } from 'components/technical/form/Form.types';
import type { SelectOption } from 'components/technical/inputs/Select/Select.props';
import { IPositionSide } from '../../../generated/graphql';

export interface OptionsStressTestPositionOutputFields {
  asset: {
    id: string;
  };
  amount: number;
  side: IPositionSide;
  premium: number;
}

export interface ShockConfiguration {
  enabled: boolean;
  value: string;
}

export interface OptionsStressTestOutputFields {
  positions: OptionsStressTestPositionOutputFields[];
  positionsLength?: unknown;
  shocks: {
    volatilityShock: ShockConfiguration;
    priceShock: ShockConfiguration;
    interestFreeRateShock: ShockConfiguration;
  };
}

export type OptionsStressTestPositionInputFields = Omit<
  FormInputType<OptionsStressTestPositionOutputFields>,
  'asset'
> & {
  asset: null | {
    id: string;
  };
};

export type OptionsStressTestInputFields = Omit<FormInputType<OptionsStressTestOutputFields>, 'positions'> & {
  positions: OptionsStressTestPositionInputFields[];
};

export const positionSideValues: SelectOption<IPositionSide>[] = Object.values(IPositionSide).map((value) => ({
  label: capitalize(value),
  value: value,
  key: value,
}));
