import { Box, Card, Stack, Typography } from '@mui/joy';
import type { ReactElement } from 'react';
import DialogButton from 'components/technical/inputs/GButton/DialogButton';
import { CreateAccountDialog } from './account/CreateAccountDialog/CreateAccountDialog.tsx';

const ConnectAccountMessage = ({ onConnected }: { onConnected: () => void }): ReactElement => {
  return (
    <Box
      sx={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '100%',
        width: 'max-content',
      }}
    >
      <Card variant="outlined">
        <Stack
          alignItems="center"
          sx={{
            padding: {
              sm: '0 2rem',
            },
          }}
          spacing={1.5}
        >
          <Typography level="body-md">Connect your sub-account and see some data</Typography>
          <DialogButton
            width="minContent"
            variant="plain"
            renderDialog={({ onClose }): ReactElement => (
              <CreateAccountDialog
                onClose={onClose}
                onConnected={() => {
                  onConnected();
                  onClose();
                }}
              />
            )}
          >
            Connect sub-account
          </DialogButton>
        </Stack>
      </Card>
    </Box>
  );
};

export default ConnectAccountMessage;
