import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Alert, type ColorPaletteProp, Stack, Typography } from '@mui/joy';
import { type ReactElement, type ReactNode, useState } from 'react';
import type { IOptimizationResultStatus } from 'generated/graphql';

import { OptimizerSolutionPortfolioExposure } from './PortfolioExposure';
import { OptimizerSolutionPortfolioReturns } from './PortfolioReturns.tsx';
import { OptimizerSolutionPortfolioRisk } from './PortfolioRisk';
import StaticSingleAutocomplete from 'components/technical/inputs/Autocomplete/StaticSingleAutocomplete.tsx';
import { OptimizationType } from '../optimization.utils.ts';
import {
  type AssetOptimization,
  type AssetSolution,
  getSolutionName,
  type PortfolioOptimization,
  type PortfolioSolution,
  type Solution,
} from './Results.types.ts';
import type { StaticAutocompleteOption } from '../../../technical/inputs/Autocomplete/StaticSingleAutocomplete.props.ts';
import { defaultRowSpacing } from '../../../StackSpacing.ts';
import isNil from 'lodash/fp/isNil';
import type { GroupWithAssetId } from 'components/portfolio/dashboard/PositionAggregationsService.ts';
import AssetOptimizerSolutionPositions from './AssetOptimizerSolutionPositions.tsx';
import PortfolioOptimizerSolutionPositions from './PortfolioOptimizerSolutionPositions.tsx';

const SolutionAlert = ({
  title,
  children,
  color,
}: { title: string; children: ReactNode; color: ColorPaletteProp }): ReactElement => (
  <Alert startDecorator={<WarningAmberIcon />} variant="soft" color={color}>
    <Typography level={'body-md'} textColor={'inherit'}>
      {title}
    </Typography>{' '}
    {children}
  </Alert>
);

const statusMessage: Record<
  IOptimizationResultStatus,
  (optimizationType: OptimizationType, statusMessage: string | undefined | null) => ReactNode
> = {
  MAX_ITER_REACHED: (_optimizationType, statusMessage) => (
    <SolutionAlert color="danger" title={'Max number of iterations reached'}>
      {statusMessage}
    </SolutionAlert>
  ),
  OTHER: (_optimizationType, statusMessage) => (
    <SolutionAlert color="danger" title={'Other'}>
      {statusMessage}
    </SolutionAlert>
  ),
  SUCCESS: (optimizationType, statusMessage) => (
    <SolutionAlert color="success" title={'Success'}>
      <div>
        <Typography level="title-lg" title={'Success'}>
          {statusMessage}
        </Typography>
        <Typography level="body-sm">
          {optimizationType === OptimizationType.asset
            ? `The number of assets and assets groups with a constraint must be strictly smaller than the number of assets in
          the portfolio composition`
            : 'The number of portfolios with a constraint must be strictly smaller than the number of portfolios in the portfolio composition'}
        </Typography>
      </div>
    </SolutionAlert>
  ),
  UNFEASIBLE_EQUALITY: (_optimizationType, statusMessage) => (
    <SolutionAlert color="danger" title={'Unfeasible equality'}>
      {statusMessage}
    </SolutionAlert>
  ),
};

const OptimizerSolutionDetails = ({
  solutions,
  defaultSolution,
  optimizationType,
  assetGroups,
}: {
  solutions: Solution[];
  defaultSolution: Solution;
  optimizationType: OptimizationType;
  assetGroups:
    | {
        genieGroups: GroupWithAssetId[];
        userGroups: GroupWithAssetId[];
      }
    | undefined;
}): ReactElement => {
  const [solutionId, setSolutionId] = useState<string>(defaultSolution?.solutionId.toString());
  const [compareToSolutionId, setCompareToSolutionId] = useState<string | null>(null);
  const options = solutions.map(
    (sol): StaticAutocompleteOption<string> => ({
      key: sol.solutionId.toString(),
      label: sol.name,
      value: sol.solutionId.toString(),
      searchText: sol.name,
    })
  );

  const solution = solutions.find((sol) => sol.solutionId.toString() === solutionId);
  const compareToSolution = solutions.find((sol) => sol.solutionId.toString() === compareToSolutionId) ?? null;

  return (
    <>
      <Stack direction="row" gap={defaultRowSpacing} alignItems="center">
        <StaticSingleAutocomplete
          options={options}
          value={solutionId}
          onChange={(newSolution) => {
            if (newSolution) {
              setSolutionId(newSolution);
            }
            if (newSolution === compareToSolutionId) {
              setCompareToSolutionId(null);
            }
          }}
          width="normal"
        />
        <Typography level="body-sm">Compare to</Typography>
        <StaticSingleAutocomplete
          options={options.filter((opt) => opt.key !== solutionId)}
          value={compareToSolutionId}
          onChange={setCompareToSolutionId}
          width="normal"
        />
      </Stack>
      {solution?.status && statusMessage[solution.status](optimizationType, solution.statusMessage)}
      {solution && (
        <>
          {optimizationType === OptimizationType.asset ? (
            <AssetOptimizerSolutionPositions
              solution={solution as AssetSolution}
              compareToSolution={compareToSolution as AssetSolution | null}
              assetGroups={assetGroups}
            />
          ) : (
            <PortfolioOptimizerSolutionPositions
              solution={solution as PortfolioSolution}
              compareToSolution={compareToSolution as PortfolioSolution | null}
            />
          )}
          <OptimizerSolutionPortfolioExposure solution={solution} />
          <OptimizerSolutionPortfolioRisk
            solution={solution}
            compareToSolution={compareToSolution}
            optimizationType={optimizationType}
          />
          <OptimizerSolutionPortfolioReturns solution={solution} compareToSolution={compareToSolution} />
        </>
      )}
    </>
  );
};

const OptimizerSolutionDetailsContainer = ({
  optimizationType,
  optimization,
  assetGroups,
}: {
  optimization: AssetOptimization | PortfolioOptimization;
  optimizationType: OptimizationType;
  assetGroups:
    | {
        genieGroups: GroupWithAssetId[];
        userGroups: GroupWithAssetId[];
      }
    | undefined;
}): ReactElement => {
  const solutions: Solution[] = [
    ...(optimization.givenPortfolioOutput ? [optimization.givenPortfolioOutput] : []),
    ...optimization.output,
  ].map((out): Solution => {
    return {
      ...out,
      name: getSolutionName(out.solutionId),
    };
  });

  const defaultSolution = solutions.at(-1);

  return (
    <>
      <Typography level="h3">Solution details</Typography>
      {!isNil(defaultSolution) && (
        <OptimizerSolutionDetails
          optimizationType={optimizationType}
          solutions={solutions}
          defaultSolution={defaultSolution}
          assetGroups={assetGroups}
        />
      )}
    </>
  );
};

export default OptimizerSolutionDetailsContainer;
