import type { ReactElement } from 'react';
import { useTheme, type SvgIconProps } from '@mui/joy';
import { useFinalColorScheme } from '../../useFinalColorScheme.ts';

const GIcon = ({
  src,
  alt,
  fontSize,
}: { src: string; alt: string; fontSize: NonNullable<SvgIconProps['fontSize']> }): ReactElement => {
  const theme = useTheme();
  const colorScheme = useFinalColorScheme();
  return (
    <img
      src={src}
      alt={alt}
      style={{
        fontSize: fontSize === 'inherit' ? 'inherit' : theme.fontSize[fontSize],
        filter: colorScheme === 'dark' ? 'invert(1)' : 'none',
        width: '1em',
        height: '1em',
      }}
    />
  );
};

export default GIcon;
