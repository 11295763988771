import type { ReactElement } from 'react';
import { Controller, type FieldPathValue, type FieldValues, type Path, useFormContext } from 'react-hook-form';
import type { GSingleAutocompleteProps } from '../inputs/Autocomplete/GSingleAutocomplete.props.ts';
import GSingleAutocomplete from '../inputs/Autocomplete/GSingleAutocomplete.tsx';
import type { DistributiveOmit } from '../../type.utils.ts';

// biome-ignore lint/suspicious/noExplicitAny:
export type FormSingleAutocompleteProps<T extends FieldValues, N extends Path<T> = any> = DistributiveOmit<
  GSingleAutocompleteProps<FieldPathValue<T, N>>,
  'value'
> & {
  name: N;
};

// biome-ignore lint/suspicious/noExplicitAny:
const FormSingleAutocomplete = <T extends FieldValues, N extends Path<T> = any>(
  props: FormSingleAutocompleteProps<T, N>
): ReactElement => {
  /* jscpd:ignore-start */
  const { control } = useFormContext();

  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field: { value, onChange, onBlur, ref }, fieldState, formState }): ReactElement => {
        return (
          <GSingleAutocomplete
            {...props}
            ref={ref}
            value={value}
            error={fieldState.error?.message ?? props.error}
            onChange={(value) => {
              props.onChange?.(value);
              onChange(value);
            }}
            onBlur={onBlur}
            disabled={(props.disabled ?? false) || formState.isSubmitting || formState.disabled}
          />
        );
      }}
    />
  );
};

export default FormSingleAutocomplete;
