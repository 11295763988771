import { SnackbarProvider } from 'notistack';
import type { ReactElement, ReactNode } from 'react';

import styles from './GSnackbarProvider.module.css';

const GSnackbarProvider = ({ children }: { children: ReactNode }): ReactElement => (
  <SnackbarProvider maxSnack={3} hideIconVariant classes={styles}>
    {children}
  </SnackbarProvider>
);

export default GSnackbarProvider;
