import IMask from 'imask';
import dayjs, { type Dayjs } from 'dayjs';
import isNil from 'lodash/fp/isNil';
import { UTC } from './date.utils.ts';
import { DateTimeFormat, formatDate } from './formatter.utils.ts';
import { useMemo } from 'react';

const maskBlocks = {
  DD: {
    mask: IMask.MaskedRange,
    placeholderChar: 'D',
    from: 1,
    to: 31,
    maxLength: 2,
  },
  MM: {
    mask: IMask.MaskedRange,
    from: 1,
    to: 12,
    placeholderChar: 'M',
    maxLength: 2,
  },
  YY: {
    mask: IMask.MaskedRange,
    placeholderChar: 'Y',
    from: 0,
    to: 99,
    maxLength: 2,
  },
  hh: {
    mask: IMask.MaskedRange,
    placeholderChar: 'h',
    from: 0,
    to: 12,
    maxLength: 2,
  },
  mm: {
    mask: IMask.MaskedRange,
    placeholderChar: 'm',
    from: 0,
    to: 59,
    maxLength: 2,
  },
  ss: {
    mask: IMask.MaskedRange,
    placeholderChar: 's',
    from: 0,
    to: 59,
    maxLength: 2,
  },
  A: {
    // I could use enumMask with am and pm, but it doesnt work for the scenario
    // when you have input 11/11/11 | 11:11:11 AM and you position your cursor on month or day
    // an type 9 to override a value. Overridin will fail, but am/pm will disappear
    blocks: {
      AM: {
        mask: 'A',
        placeholderChar: '_',
        definitions: {
          A: /[AP]/i,
        },
        prepareChar: (str: string) => str.toUpperCase(),
      },
    },
    // '`' keeps the characters in place. Removing text before A block won't erase it
    mask: '`AM{M}',
  },
};

export type DateMaskFormat = 'dateTime' | 'date' | 'time' | 'dateRange';
export type DateValueType<Format> = Format extends 'dateRange' ? [Dayjs, Dayjs] | null : Dayjs | null;

const createParserFormatter = (
  format: DateTimeFormat,
  placeholder: string
): {
  format: (value: Dayjs | null, masked: { value: string }) => string;
  parse: (value: string) => Dayjs | null;
} => {
  return {
    format: (value: Dayjs | null, masked: { value: string }) => {
      if (isNil(value)) {
        return placeholder;
      }

      if (!value.isValid()) {
        return masked.value;
      }

      return formatDate(value, format, UTC);
    },
    parse: (value: string) => {
      if (value === placeholder) {
        return null;
      }

      return dayjs.utc(value, format, true);
    },
  };
};

const dateRangePlaceholder = 'MM/DD/YY - MM/DD/YY';
const timePlaceholder = 'hh:mm:ss __';
const dateTimePlaceholder = 'MM/DD/YY | hh:mm:ss __';
const datePlaceholder = 'MM/DD/YY';

const timeMask = '`hh:`mm:`ss A';
const shortDateMask = '`MM/`DD/`YY';
const shortDateTimeMask = 'MM/`DD/`YY | `hh:`mm:`ss A';
export const formatInfo = {
  time: {
    mask: {
      blocks: maskBlocks,
      ...createParserFormatter(DateTimeFormat.Time, timePlaceholder),
      mask: timeMask,
    },
    placeholder: timePlaceholder,
  },
  dateTime: {
    mask: {
      blocks: maskBlocks,
      ...createParserFormatter(DateTimeFormat.ShortDateTime, dateTimePlaceholder),
      mask: shortDateTimeMask,
    },
    placeholder: dateTimePlaceholder,
  },
  date: {
    mask: {
      blocks: maskBlocks,
      ...createParserFormatter(DateTimeFormat.ShortDate, datePlaceholder),
      mask: shortDateMask,
    },
    placeholder: datePlaceholder,
  },
  dateRange: {
    mask: {
      blocks: maskBlocks,
      format: (value: [Dayjs, Dayjs] | null, masked: { value: string }): string => {
        if (isNil(value)) {
          return dateRangePlaceholder;
        }

        if (!value[0].isValid() || !value[1].isValid()) {
          return masked.value;
        }

        return value.map((val) => formatDate(val, DateTimeFormat.ShortDate, UTC)).join(' - ');
      },
      parse: (value: string) => {
        if (value === dateRangePlaceholder) {
          return null;
        }

        const parts = value.split(' - ');
        return [
          dayjs.utc(parts[0], DateTimeFormat.ShortDate, true),
          dayjs.utc(parts[1], DateTimeFormat.ShortDate, true),
        ];
      },
      mask: [shortDateMask, shortDateMask].join(' - '),
    },
    placeholder: dateRangePlaceholder,
  },
};

export const useFormatInfo = <FORMAT extends DateMaskFormat>(
  format: FORMAT,
  lazy: boolean
): { placeholder: string; mask: (typeof formatInfo)[FORMAT]['mask'] & { lazy: boolean; overwrite: boolean } } => {
  return useMemo(() => {
    const info: (typeof formatInfo)[FORMAT] = formatInfo[format];
    return {
      placeholder: info.placeholder,
      mask: {
        ...info.mask,
        eager: true,
        overwrite: true,
        lazy,
      },
    };
  }, [format, lazy]);
};
