import dayjs from 'dayjs';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import { NotificationsDocument, useMarkNotificationsAsReadMutation } from 'generated/graphql';

export const useMarkAllAsRead = (): (() => Promise<void>) => {
  const [markAllAsRead] = useMarkNotificationsAsReadMutation({
    refetchQueries: [NotificationsDocument],
  });
  const { showGraphqlError } = useFeedback();

  const markNotificationsAsRead = async (): Promise<void> => {
    try {
      await markAllAsRead({
        variables: {
          acknowledgedPlacedAt: dayjs().toISOString(),
        },
      });
    } catch (e) {
      showGraphqlError(e);
    }
  };

  return markNotificationsAsRead;
};
