import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import { Chip, Stack, Typography } from '@mui/joy';
import { isNil } from 'lodash/fp';
import { isNegative, isPositive } from 'mathjs';
import type { FunctionComponent } from 'react';
import { formatPercentage } from '../../formatter.utils';
import { getChangeColor } from '../../portfolio/dashboard/PortfolioTilesService';

const TitleWithChip: FunctionComponent<{ title: string; change: number | undefined }> = ({ title, change }) => {
  return (
    <Stack lineHeight={2} direction="row" justifyContent="space-between" alignItems="center">
      {title}
      {!isNil(change) && (
        <Chip size="sm" color={getChangeColor(change)}>
          <Stack alignItems="center" direction="row">
            {isPositive(change) && <CallMadeIcon fontSize="inherit" />}
            {isNegative(change) && <CallReceivedIcon fontSize="inherit" />}
            <Typography level="body-xs2">{formatPercentage(change)}</Typography>
          </Stack>
        </Chip>
      )}
    </Stack>
  );
};

export default TitleWithChip;
