import dayjs, { type Dayjs } from 'dayjs';

export type PredefinedRangeLabel = '1m' | '1d' | '3m' | 'MTD' | 'QTD' | 'YTD' | 'All' | '7D' | '1y';

export type PredefinedRange = {
  label: PredefinedRangeLabel;
  name: string;
  value: () => [Dayjs, Dayjs] | null;
};

export const rangeOneMonth: PredefinedRange = {
  label: '1m',
  name: 'Last 30 days',
  value: (): [Dayjs, Dayjs] => [dayjs.utc().subtract(30, 'day'), dayjs.utc()],
};

export const rangeOneDay: PredefinedRange = {
  label: '1d',
  name: 'Last 1 day',
  value: (): [Dayjs, Dayjs] => [dayjs.utc().subtract(1, 'day'), dayjs.utc()],
};

export const rangeThreeMonths: PredefinedRange = {
  label: '3m',
  name: 'Last 90 days',
  value: () => [dayjs.utc().subtract(90, 'day'), dayjs.utc()],
};

export const rangeLastYear: PredefinedRange = {
  label: '1y',
  name: 'Last 365 days',
  value: () => [dayjs.utc().subtract(365, 'day'), dayjs.utc()],
};

export const rangeMonthToDate: PredefinedRange = {
  label: 'MTD',
  name: 'Month to date',
  value: () => [dayjs.utc().startOf('month'), dayjs.utc()],
};

export const rangeQuarterToDate: PredefinedRange = {
  label: 'QTD',
  name: 'Quarter to date',
  value: () => [dayjs.utc().startOf('quarter'), dayjs.utc()],
};

export const rangeYearToDate: PredefinedRange = {
  label: 'YTD',
  name: 'Quarter to date',
  value: () => [dayjs.utc().startOf('year'), dayjs.utc()],
};

export const rangeAll: PredefinedRange = {
  label: 'All',
  name: 'All',
  value: () => null,
};

export const range7Days: PredefinedRange = {
  label: '7D',
  name: 'Last 7 days',
  value: () => [dayjs.utc().subtract(7, 'day'), dayjs.utc()],
};

export const defaultCalendarDateRanges: PredefinedRange[] = [
  rangeOneDay,
  range7Days,
  rangeOneMonth,
  rangeThreeMonths,
  rangeLastYear,
  rangeAll,
];

export const defaultQuickAccessDateRanges: PredefinedRange[] = [
  range7Days,
  rangeMonthToDate,
  rangeQuarterToDate,
  rangeYearToDate,
  rangeAll,
];

export const getDefaultRange = (): PredefinedRange => (dayjs.utc().date() < 7 ? range7Days : rangeMonthToDate);

const allRanges = [
  rangeOneMonth,
  rangeOneDay,
  rangeThreeMonths,
  rangeLastYear,
  rangeMonthToDate,
  rangeQuarterToDate,
  rangeYearToDate,
  rangeAll,
  range7Days,
];

const labelToRangeMap = new Map<PredefinedRangeLabel, PredefinedRange>(allRanges.map((range) => [range.label, range]));

export const getRangeByLabel = (label: PredefinedRangeLabel): PredefinedRange | undefined => {
  return labelToRangeMap.get(label);
};
