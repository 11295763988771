import { Stack, Typography, type TypographyProps } from '@mui/joy';
import type { ColorPaletteProp } from '@mui/joy/styles/types';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import { bignumber, type BigNumber, isNegative, isPositive } from 'mathjs';
import type { ReactElement, ReactNode } from 'react';

import { formatPercentage } from '../../formatter.utils';

export const GValueWithChangeCell = ({ value, change }: { value: ReactNode; change?: number | null }): ReactElement => {
  if (!value) {
    return <></>;
  }

  return (
    <Stack alignItems="flex-start" justifyItems="center">
      <span>{value}</span>
      {change !== undefined && change !== null ? (
        <GValueChange value={change} level="body-xs2" text={`(${formatPercentage(change)})`} />
      ) : (
        <></>
      )}
    </Stack>
  );
};

const getChangeColor = (value: number | BigNumber): ColorPaletteProp | undefined => {
  return bignumber(value).isNegative() ? 'danger' : 'success';
};

type IndicatorVariant = 'color' | 'sign' | 'parenthesis' | 'icon';

const GValueChange = ({
  value,
  text,
  level,
  indicatorVariants = 'color',
}: {
  value: number | BigNumber;
  text: ReactNode;
  level?: TypographyProps['level'];
  indicatorVariants?: IndicatorVariant[] | IndicatorVariant;
}): ReactElement => {
  const variants = Array.isArray(indicatorVariants) ? indicatorVariants : [indicatorVariants];
  const parenthesis = variants.includes('parenthesis');
  const icon = variants.includes('icon');
  return (
    <Typography
      color={variants.includes('color') ? getChangeColor(value) : undefined}
      level={level}
      component="div"
      display="flex"
      alignItems="center"
    >
      {parenthesis && '('}
      {variants.includes('sign') && bignumber(value).isPositive() && '+'}
      {icon && isPositive(value) && <CallMadeIcon fontSize="inherit" />}
      {icon && isNegative(value) && <CallReceivedIcon fontSize="inherit" />}
      {text}
      {parenthesis && ')'}
    </Typography>
  );
};

export default GValueChange;
