import type { ReactElement, ReactNode } from 'react';
import { Box } from '@mui/joy';

const BorderedItem = ({ children, padding = 'xs' }: { children: ReactNode; padding?: 'sm' | 'xs' }): ReactElement => {
  const paddingSize = {
    xs: '0.5rem',
    sm: '0.75rem',
  } as const;

  return (
    <Box
      sx={(theme) => ({
        border: `1px solid ${theme.palette.background.level2}`,
        borderRadius: 'radius.xs',
        padding: paddingSize[padding ?? 'xs'],
      })}
    >
      {children}
    </Box>
  );
};

export default BorderedItem;
