import { Stack, Typography } from '@mui/joy';
import Card from '@mui/joy/Card';
import type { ReactElement } from 'react';
import type { TileProps } from './Tile.props';

const Tile = ({ title, children, fullHeight, alignment }: TileProps): ReactElement => {
  return (
    <Card sx={fullHeight ? { height: '100%' } : {}} variant="outlined">
      <Stack
        spacing={1}
        sx={{
          height: '100%',
          justifyContent: alignment,
        }}
      >
        <Typography level="title-xs" component="h3">
          {title}
        </Typography>
        {children}
      </Stack>
    </Card>
  );
};

export default Tile;
