import { Stack, Typography } from '@mui/joy';
import dayjs from 'dayjs';
import { type ReactElement, useRef } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import AddButton from 'components/technical/AddButton';
import GFormProvider from 'components/technical/form/GFormProvider';

import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton';
import { useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError.tsx';
import GButton from 'components/technical/inputs/GButton/GButton';
import KeyValueListSkeleton from 'components/technical/KeyValueListSkeleton';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import type {
  OptionsStressTestInputFields,
  OptionsStressTestOutputFields,
  ShockConfiguration,
} from './OptionsStressTest.types';
import { schema } from './OptionsStressTest.validation';
import { OptionsStressTestPositionList } from './OptionsStressTestPositionList';
import OptionsStressTestShockList from './OptionsStressTestShockList';
import OptionsStressTestResults from './results/OptionsStressTestResults';
import {
  IAssetType,
  IPositionSide,
  useEvaluateOptionsPortfolioLazyQuery,
  useOptionsStressTestInputQuery,
} from '../../../generated/graphql';
import type { DerivativeAsset } from '../../market/asset/Asset.types';
import { getFloatStringValues } from '../../number.utils';

const DEFAULT_POSITION = {
  asset: null,
  side: IPositionSide.Long,
  premium: '0',
  amount: null,
} as const;

const getShockValue = (shock: ShockConfiguration): number[] | undefined => {
  if (shock.enabled) {
    return getFloatStringValues(shock.value).map((val) => val / 100);
  }

  return undefined;
};

const headerStyle = 'title-md' as const;
const sectionIndentation = 2;
const defaultShockValues = '-50,-20,-10,-5,-2,0,2,5,10,20,50';
const OptionsStressTestBaseAssetSimulator = ({ assetId }: { assetId: string }): ReactElement => {
  const expirationSince = useRef(dayjs());
  const { data, Fallback, loaded } = useDefaultErrorHandling(
    useOptionsStressTestInputQuery({
      variables: {
        baseAssetId: assetId,
        expirationSince: expirationSince.current.toISOString(),
      },
    })
  );

  const [evaluateOptionsPortfolio, { data: portfolioEvaluation }] = useEvaluateOptionsPortfolioLazyQuery();
  const defaultValues = {
    positions: [{ ...DEFAULT_POSITION }],
    shocks: {
      volatilityShock: {
        enabled: true,
        value: defaultShockValues,
      },
      priceShock: {
        enabled: true,
        value: defaultShockValues,
      },
      interestFreeRateShock: {
        enabled: true,
        value: defaultShockValues,
      },
    },
  };

  const methods = useForm<OptionsStressTestInputFields>({
    resolver: gYupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const { onErrorAndThrow } = useGraphQLApiError(methods);

  const {
    fields: positions,
    remove: removePosition,
    append: appendPosition,
  } = useFieldArray<OptionsStressTestInputFields>({
    control: methods.control,
    name: 'positions',
  });

  if (!loaded) {
    return <Fallback />;
  }

  const onSubmit = async (formOutput: OptionsStressTestInputFields): Promise<void> => {
    const output = formOutput as unknown as OptionsStressTestOutputFields;
    const { error } = await evaluateOptionsPortfolio({
      variables: {
        input: {
          portfolio: output.positions.map((pos) => ({
            assetId: pos.asset.id,
            amount: pos.amount,
            side: pos.side,
            premiumPerContract: pos.premium,
          })),
          parameterGridChange: {
            price: getShockValue(output.shocks.priceShock),
            riskFree: getShockValue(output.shocks.interestFreeRateShock),
            volatility: getShockValue(output.shocks.volatilityShock),
          },
        },
      },
    });

    if (error) {
      onErrorAndThrow(error);
    }
  };

  return (
    <GFormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack spacing={1.5} pl={sectionIndentation}>
          <Stack spacing={2}>
            <div>
              <Typography level={headerStyle} ml={-sectionIndentation}>
                Add list of positions
              </Typography>
              <OptionsStressTestPositionList
                positions={positions}
                onRemove={(index: number): void => {
                  removePosition(index);

                  methods.trigger('positionsLength');
                }}
                supportedOptions={data.assets.list
                  .filter(
                    (
                      asset
                    ): asset is Pick<DerivativeAsset, 'id' | 'symbol'> & {
                      derivativeDetails: { exchange: string };
                    } => !!asset.derivativeDetails
                  )
                  .map((asset) => ({
                    ...asset,
                    type: IAssetType.Derivative,
                  }))}
              />
            </div>
            <AddButton
              disabled={methods.formState.isSubmitting}
              width="normal"
              onClick={(): void => {
                appendPosition({ ...DEFAULT_POSITION });
                methods.clearErrors('positionsLength');
              }}
            >
              Add position
            </AddButton>
          </Stack>
          <Stack spacing={1}>
            <div>
              <Typography level={headerStyle} ml={-sectionIndentation}>
                Settings
              </Typography>
              <OptionsStressTestShockList />
            </div>
            <Stack direction="row" gap={1}>
              <SubmitButton width="minContent" color="primary">
                Run
              </SubmitButton>
              <GButton
                width="minContent"
                variant="plain"
                onClick={(): void => {
                  methods.reset();
                }}
              >
                Clear all
              </GButton>
            </Stack>
            <GraphQLApiFormErrorMessage />
          </Stack>
          <Stack>
            <Typography level={headerStyle} ml={-sectionIndentation} pb={1.5}>
              Stress test results
            </Typography>
            {portfolioEvaluation ? (
              <OptionsStressTestResults portfolioEvaluation={portfolioEvaluation} />
            ) : (
              <KeyValueListSkeleton sx={{ maxWidth: '64rem' }} /> // aligned with the total length of the list of positions
            )}
          </Stack>
        </Stack>
      </form>
    </GFormProvider>
  );
};

export default OptionsStressTestBaseAssetSimulator;
