import type { FunctionComponent } from 'react';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling.tsx';

import RiskMetricsContainer from './RiskMetricsContainer.tsx';
import { useRiskDashboardInputQuery } from '../../../generated/graphql.tsx';
import { useSubAccountAssetFilters } from '../../technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import ChartsSkeleton from '../../ChartsSkeleton.tsx';

const RiskOverview: FunctionComponent = () => {
  const { subAccountAssetFilters } = useSubAccountAssetFilters();
  const { loaded, Fallback, data, refetch } = useDefaultErrorHandling(
    useRiskDashboardInputQuery({
      variables: { subAccountAssetFilters },
    })
  );

  if (!loaded) {
    return <Fallback />;
  }

  if (data.portfolio.accounts.length === 0 || data.portfolio.metricParameters.length === 0) {
    return (
      <ChartsSkeleton
        titleLeft="Risk metrics"
        titleRight="Asset contributions"
        onSubAccountConnected={(): void => {
          refetch();
        }}
      />
    );
  }

  return (
    <RiskMetricsContainer
      groups={data.assets.assetGroups}
      metricParameters={data.portfolio.metricParameters}
      showDatePicker
      showPortfolio={false}
      filters={{ subAccountAssetFilter: subAccountAssetFilters }}
    />
  );
};

export default RiskOverview;
