import type { UnifiedStressTestInputFields } from './UnifiedStressTest.validation.ts';
import type { StepConfig } from '../../../technical/wizard/StepConfig.ts';
import { ModelAndAssumptionsStep } from './ModelAndAssumptionsStep.tsx';
import type { AssetLabelInput } from '../../../market/asset/AssetLabelService.ts';

export const config = (
  goToNextStep: () => void,
  benchmarks: AssetLabelInput[]
): StepConfig<UnifiedStressTestInputFields> => ({
  label: 'Model and assumptions',
  element: <ModelAndAssumptionsStep goToNextStep={goToNextStep} benchmarks={benchmarks} />,
  fields: ['shocks', 'benchmark'],
});
