import type { ReactElement } from 'react';

import GButton from './GButton';

const GoBackButton = ({ disabled, onClick }: { disabled?: boolean; onClick: () => void }): ReactElement => (
  <GButton type="button" width="fullWidth" variant="plain" color="neutral" onClick={onClick} disabled={disabled}>
    Go Back
  </GButton>
);

export default GoBackButton;
