import { ClickAwayListener } from '@mui/base';
import { Popper } from '@mui/base/Popper';
import { Card, Stack, Typography as JoyTypography, Box } from '@mui/joy';
import type { MutableRefObject, ReactElement } from 'react';

import { SearchResult } from './SearchResult';
import { clickedOutsideElement } from '../../../clickAwayListener.utils';
import type { DerivativeAsset, PrivateAsset, PublicAsset } from '../../../market/asset/Asset.types';

export const SearchResults = (props: {
  inputRef: MutableRefObject<HTMLDivElement | null>;
  results: (PublicAsset | PrivateAsset | DerivativeAsset)[];
  onClose: () => void;
  onSearchResultClicked: () => void;
}): ReactElement => {
  return (
    <ClickAwayListener
      onClickAway={(e): void => {
        if (clickedOutsideElement(e, props.inputRef.current)) {
          props.onClose();
        }
      }}
    >
      <Popper
        slotProps={{
          root: {
            style: { width: '100%', minWidth: '300px', zIndex: 10 },
          },
        }}
        anchorEl={props.inputRef.current}
        placement="bottom"
        open
        container={props.inputRef.current}
      >
        <Card size="sm" sx={{ marginTop: '0.5rem', paddingInline: 0 }}>
          <Stack spacing={1}>
            <JoyTypography color="neutral" sx={{ padding: '0 0.75rem' }}>
              Results
            </JoyTypography>
            <Box sx={{ overflow: 'auto' }}>
              {props.results
                .filter((_v, i) => i < 10)
                .map((asset) => (
                  <SearchResult
                    key={asset.id}
                    asset={asset}
                    onClick={(): void => {
                      props.onClose();
                      props.onSearchResultClicked();
                    }}
                  />
                ))}

              {!props.results.length && (
                <JoyTypography
                  color="neutral"
                  level="body-md"
                  sx={{
                    padding: '0 0.75rem',
                  }}
                >
                  None
                </JoyTypography>
              )}
            </Box>
          </Stack>
        </Card>
      </Popper>
    </ClickAwayListener>
  );
};
