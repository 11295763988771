import { getEnvConfig } from 'config';

export const downloadLocalCsvFile = (content: string, filename: string): void => {
  downloadLocalFile({
    contentType: 'text/csv;charset=utf-8',
    content,
    filename,
  });
};

export const downloadLocalFile = ({
  contentType,
  content,
  filename,
}: { contentType: string; content: string; filename: string }): void => {
  const blob = new Blob([content], { type: `${contentType}` });
  const url = URL.createObjectURL(blob);
  downloadFile(url, filename);

  URL.revokeObjectURL(url);
};

export const downloadFile = (downloadLink: string, filename: string | undefined = undefined): void => {
  const a = document.createElement('a');
  a.href = downloadLink;
  if (filename) {
    a.download = filename;
  }
  a.click();
};

export const downloadTransactionOrderCsv = (token: string): void => {
  const config = getEnvConfig();
  const url = new URL(config.apiHostnameAndPort || window.location.origin);
  url.pathname = '/api/download/csv';
  url.search = new URLSearchParams({ token }).toString();

  downloadFile(url.toString());
};

export const uploadFile = async ({
  blob,
  uploadUrl,
  signal,
}: {
  blob: Blob;
  uploadUrl: string;
  signal?: AbortSignal;
}): Promise<void> => {
  const response = await fetch(uploadUrl, {
    signal,
    method: 'PUT',
    body: blob,
    headers: {
      'Content-Type': blob.type,
    },
  });

  if (!response.ok) {
    throw new Error('File upload failed');
  }

  console.debug('Uploaded file to', uploadUrl);
};
