import type { ReactElement } from 'react';
import { Grid, Typography } from '@mui/joy';

const KeyValuePair = ({ keyData, value }: { keyData: string; value: string }): ReactElement => {
  return (
    <>
      <Grid xs={6}>
        <Typography level="body-sm">{keyData}:</Typography>
      </Grid>
      <Grid xs={6}>
        <Typography level="title-sm-emphasis">{value}</Typography>
      </Grid>
    </>
  );
};

const GCardKeyValuePairs = ({ data }: { data: { key: string; value: string }[] }): ReactElement => {
  return (
    <Grid container rowGap={1.5} rowSpacing={0}>
      {data
        .filter(({ value }) => !!value)
        .map(({ key, value }) => (
          <KeyValuePair key={`${key}-${value}`} keyData={key} value={value} />
        ))}
    </Grid>
  );
};

export default GCardKeyValuePairs;
