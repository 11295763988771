import { Stack, Tooltip } from '@mui/joy';
import type { FunctionComponent } from 'react';
import { usePortfolioRiskMetricsContributionLazyQuery } from 'generated/graphql.tsx';

import dayjs from 'dayjs';
import { formatISODate } from 'components/formatter.utils.ts';

import { logErrorOnce } from 'components/log.utils.ts';
import GButton from 'components/technical/inputs/GButton/GButton.tsx';
import { isNil } from 'lodash/fp';
import {
  type MetricParameter,
  type ParametersValidationResult,
  contributionMetricRequestParams,
  groupPortfolioMetrics,
  portfolioContributionMetricsBenchmarkAssetsSymbols,
  validateMetricParameters,
} from './assetRiskMetricsReport.utils.ts';
import ErrorMessage from 'components/technical/ErrorMessage.tsx';
import type { ObjectKeyMap } from 'components/objectKeyMap.ts';
import type {
  PortfolioRiskMetricReportMapKey,
  PortfolioRiskMetricReportMapValue,
} from 'components/technical/grids/SharedReportColumns.tsx';
import type { ApolloError } from '@apollo/client';
import { Download } from '@mui/icons-material';
import { useSubAccountAssetFilters } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';

type PortfolioContributionMetricsLoaderProps = {
  knownAssets: { id: string; symbol: string }[];
  portfolioMetricParametersDescription: MetricParameter[];
  onContributionMetricsLoaded: (
    data: ObjectKeyMap<PortfolioRiskMetricReportMapKey, PortfolioRiskMetricReportMapValue>
  ) => void;
};

function getLoadButtonTooltip(loadContributionMetricsButtonDisabled: boolean, windowNotSupported: boolean): string {
  let contributionMetricsTooltip = '';
  if (!loadContributionMetricsButtonDisabled) {
    contributionMetricsTooltip = 'Loading contribution metrics might be slow';
  }

  if (windowNotSupported) {
    contributionMetricsTooltip =
      'Portfolio does not have enough data to calculate contribution to portfolio risk metrics';
  }
  return contributionMetricsTooltip;
}

function getErrorText(
  knownAssets: { id: string; symbol: string }[],
  requestError: ApolloError | undefined,
  contributionMetricParametersValidation: ParametersValidationResult,
  windowNotSupported: boolean
): string | undefined {
  const notFoundAssets = portfolioContributionMetricsBenchmarkAssetsSymbols.filter(
    (symbol) => !knownAssets.map((a) => a.symbol).includes(symbol)
  );
  if (notFoundAssets.length > 0) {
    return `Error ${notFoundAssets.join(',')} assets not found in known asset list`;
  }

  if (requestError) {
    logErrorOnce('Error loading contribution to portfolio risk metrics', requestError);
    return 'Error loading contribution to portfolio risk metrics*';
  }
  if (!contributionMetricParametersValidation.isValid && !windowNotSupported) {
    return 'Invalid contribution to portfolio risk metrics parameters*';
  }
  return undefined;
}

const PortfolioContributionMetricsLoader: FunctionComponent<PortfolioContributionMetricsLoaderProps> = ({
  knownAssets,
  portfolioMetricParametersDescription,
  onContributionMetricsLoaded,
}) => {
  const { subAccountAssetFilters } = useSubAccountAssetFilters();

  const portfolioContributionMetricsBenchmarkAssets = portfolioContributionMetricsBenchmarkAssetsSymbols

    .map((symbol) => knownAssets.find((asset) => asset.symbol === symbol)!)
    .filter(Boolean);

  const contributionMetricsRequestParams = [30, 60, 90].flatMap((window) =>
    contributionMetricRequestParams(
      portfolioContributionMetricsBenchmarkAssets.map((a) => a.id),
      window
    )
  );

  const contributionMetricParametersValidation = validateMetricParameters(
    portfolioMetricParametersDescription,
    contributionMetricsRequestParams
  );

  // opt out from default error handling, as we want to load slow query on button click in the background
  // if we optimize this query, we can merge it with assetRiskMetricsQuery
  const [loadPortfolioContributionMetrics, portfolioRiskMetricsContributionResult] =
    usePortfolioRiskMetricsContributionLazyQuery({
      variables: {
        input: {
          date: formatISODate(dayjs.utc()),
          subAccountAssetFilter: subAccountAssetFilters,
          metrics: contributionMetricsRequestParams,
        },
      },
      onCompleted(data) {
        onContributionMetricsLoaded(groupPortfolioMetrics(data.portfolio.metricContributions));
      },
    });

  const portfolioContributionMetricsLoadedSuccessfully =
    !isNil(portfolioRiskMetricsContributionResult.data) && isNil(portfolioRiskMetricsContributionResult.error);

  const windowNotSupported =
    !contributionMetricParametersValidation.isValid && contributionMetricParametersValidation.parameter === 'Window';

  const errorText = getErrorText(
    knownAssets,
    portfolioRiskMetricsContributionResult.error,
    contributionMetricParametersValidation,
    windowNotSupported
  );

  if (errorText) {
    return <ErrorMessage>{errorText}</ErrorMessage>;
  }

  const loadContributionMetricsButtonDisabled =
    portfolioRiskMetricsContributionResult.called || !contributionMetricParametersValidation.isValid;

  return (
    <Stack direction="row-reverse" width="100%">
      <Tooltip title={getLoadButtonTooltip(loadContributionMetricsButtonDisabled, windowNotSupported)}>
        {/* span needed so tooltip is shown even when button is disabled */}
        <span>
          <GButton
            width="xl2"
            disabled={loadContributionMetricsButtonDisabled}
            onClick={() => loadPortfolioContributionMetrics()}
            loading={portfolioRiskMetricsContributionResult.loading}
            startDecorator={<Download />}
          >
            {portfolioContributionMetricsLoadedSuccessfully
              ? 'Contribution to portfolio loaded'
              : 'Contribution to portfolio'}
          </GButton>
        </span>
      </Tooltip>
    </Stack>
  );
};

export default PortfolioContributionMetricsLoader;
