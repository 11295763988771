import { Add } from '@mui/icons-material';
import { Modal, ModalDialog, Stack } from '@mui/joy';
import type { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import GFormProvider from 'components/technical/form/GFormProvider';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import { getErrorDetails } from 'components/technical/form/GraphQLAPIService.ts';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton';
import { useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError.tsx';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import { FormInput } from 'components/technical/inputs';
import {
  PrivateAssetsDocument,
  SearchableAssetIdsDocument,
  SearchableAssetsDocument,
  useCreatePrivateAssetMutation,
} from '../../../generated/graphql';
import { defaultRowSpacing } from '../../StackSpacing.ts';

interface FormState {
  name: string;
  symbol: string;
}

const formSchema = yup.object({
  name: yup.string().required(),
  symbol: yup.string().required().max(16),
});

export const CreatePrivateAssetDialog = (props: { onClose: () => void; onAdded: () => void }): ReactElement => {
  const methods = useForm<FormState>({
    resolver: gYupResolver(formSchema),
    defaultValues: {
      name: '',
      symbol: '',
    },
  });

  const { onError } = useGraphQLApiError(methods);
  const { showSuccessMessage } = useFeedback();

  const [createPrivateAsset] = useCreatePrivateAssetMutation({
    refetchQueries: [SearchableAssetsDocument, SearchableAssetIdsDocument, PrivateAssetsDocument],
  });

  const handleFormSubmit = async (data: FormState): Promise<void> => {
    try {
      await createPrivateAsset({
        variables: {
          input: {
            name: data.name,
            symbol: data.symbol,
          },
        },
      });

      showSuccessMessage('New asset successfully added');

      props.onAdded();
    } catch (e) {
      const details = getErrorDetails(e);
      if (details?.code === 'asset_duplicate_name') {
        methods.setError('name', {
          type: 'custom',
          message: 'Asset with given name already exists',
        });
      } else if (details?.code === 'asset_duplicate_symbol') {
        methods.setError('symbol', {
          type: 'custom',
          message: 'Asset with given symbol already exists',
        });
      } else {
        onError(e);
      }

      throw e;
    }
  };

  return (
    <Modal open={true} onClose={props.onClose}>
      <ModalDialog>
        <GFormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
            <GDialogHeader>Add new asset</GDialogHeader>
            <Stack spacing={1.5}>
              <Stack spacing={defaultRowSpacing}>
                <FormInput label="Name" name="name" width="fullWidth" />
                <FormInput label="Symbol" name="symbol" width="fullWidth" />
              </Stack>
              <Stack alignItems="center" spacing={1.5}>
                <GraphQLApiFormErrorMessage />
                <SubmitButton width="xl2" startDecorator={<Add />}>
                  Add asset
                </SubmitButton>
              </Stack>
            </Stack>
          </form>
        </GFormProvider>
      </ModalDialog>
    </Modal>
  );
};
