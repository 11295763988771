import type { ReactElement } from 'react';
import { Box, Stack } from '@mui/joy';
import GCard, { type GridWidth } from 'components/technical/GCard/GCard.tsx';
import SeeMoreDropdown from 'components/technical/SeeMoreDropDown/SeeMoreDropdown.tsx';
import UpdateApiKeysDialog from './UpdateApiKeysDialog';
import UpdateNameDialog from './UpdateNameDialog';
import {
  AccountsDocument,
  useDeleteAccountMutation,
  useUpdateAccountApiKeysMutation,
  useUpdateAccountMutation,
} from 'generated/graphql';
import type { AccountType } from './Account.types';
import VenueLabel from 'components/venue/VenueLabel';
import { IconVariant } from 'components/market/asset/cryptocurrencies/CryptocurrenciesData';
import AccountStatusChip from './AccountStatusChip';
import { GENIE_VENUE } from 'components/venue/VenueData';
import { DateTimeFormat, formatDate } from 'components/formatter.utils';
import { useUserTimezone } from 'components/technical/UseUserTimezone';
import { Pencil } from 'components/technical/icons';
import AccountCardSubAccounts from './AccountCardSubAccounts';
import { BUTTON_MENU_POSITION_STYLE } from 'components/technical/GCard/consts';
import GCardKeyValuePairs from 'components/technical/GCard/GCardKeyValuePairs';
import { DeleteMenuItem } from '../../technical/inputs/GButton/DeleteDialogButton.tsx';
import { GCardContentWithDivider } from '../../technical/GCard/GCardContentWithDivider.tsx';
import { DialogMenuItem } from '../../technical/DialogDropdown/DialogMenuItem.tsx';

const AccountCard = ({
  account,
  gridWidth,
  height,
}: {
  account: AccountType;
  gridWidth: GridWidth;
  height: string;
}): ReactElement => {
  const [deleteAccount] = useDeleteAccountMutation({ ignoreResults: true });
  const timezone = useUserTimezone();

  const accountKeyValueData = [
    { key: 'A/C Name', value: account.name },
    { key: 'Added On', value: formatDate(account.createdAt, DateTimeFormat.DateTime, timezone) },
    {
      key: 'Last Updated',
      value: formatDate(account.snapshotSyncStatus.latestCompleted?.time, DateTimeFormat.DateTime, timezone),
    },
  ];

  return (
    <GCard height={height} gridWidth={gridWidth} cardWrapperSx={{ position: 'relative' }}>
      <GCardContentWithDivider>
        <Box sx={BUTTON_MENU_POSITION_STYLE}>
          <SeeMoreDropdown>
            <DialogMenuItem
              renderDialog={({ onClose }): ReactElement => (
                <UpdateNameDialog
                  onClose={onClose}
                  onUpdate={onClose}
                  mutation={useUpdateAccountMutation}
                  id={account.id}
                  title={`Rename "${account.name}" account`}
                />
              )}
            >
              <Stack direction="row" gap={1} alignItems="center">
                <Pencil fontSize="md" />
                Edit name
              </Stack>
            </DialogMenuItem>
            {account.venue.label !== GENIE_VENUE && (
              <DialogMenuItem
                renderDialog={({ onClose }): ReactElement => (
                  <UpdateApiKeysDialog
                    onClose={onClose}
                    onUpdate={onClose}
                    mutation={useUpdateAccountApiKeysMutation}
                    name={account.name}
                    venue={account.venue.label}
                    id={account.id}
                  />
                )}
              >
                <Stack direction="row" gap={1} alignItems="center">
                  <Pencil fontSize="md" />
                  Edit API credentials
                </Stack>
              </DialogMenuItem>
            )}
            <DeleteMenuItem
              deleteItem={async (): Promise<void> => {
                await deleteAccount({
                  variables: {
                    id: account.id,
                  },
                  refetchQueries: [AccountsDocument],
                });
              }}
              confirmationMessage={'Account successfully deleted'}
              deleteMessage={
                <>Are you sure you want to remove account {account.name}? This action will remove all snapshots.</>
              }
            />
          </SeeMoreDropdown>
        </Box>
        <Stack justifyContent="space-between" direction="row">
          <VenueLabel
            accountName={account.name}
            venue={account.venue.label}
            size={IconVariant.BIGGEST}
            venueNameComponent="h3"
            format="long"
            additionalInfo={
              <AccountStatusChip
                resultCode={account.snapshotSyncStatus.latestCompleted?.resultCode}
                lastSync={formatDate(
                  account.snapshotSyncStatus.latestSuccessfulTime,
                  DateTimeFormat.DateTime,
                  timezone
                )}
              />
            }
          />
          <AccountCardSubAccounts subAccounts={account.subAccounts} />
        </Stack>
        <GCardKeyValuePairs data={accountKeyValueData} />
      </GCardContentWithDivider>
    </GCard>
  );
};

export default AccountCard;
