import { bignumber } from 'mathjs';

import type { ILatestCorrelationsQuery } from '../../../../generated/graphql';

export const createCorrelationMap = (
  twoAssetMetrics: ILatestCorrelationsQuery['assets']['twoAssetLatestMetrics']
): Map<string, Map<string, number>> => {
  const assetToCorrelation = new Map<string, Map<string, number>>();
  for (const corr of twoAssetMetrics) {
    let primaryAssetMapping = assetToCorrelation.get(corr.primaryAsset.id);
    if (!primaryAssetMapping) {
      primaryAssetMapping = new Map<string, number>();
      assetToCorrelation.set(corr.primaryAsset.id, primaryAssetMapping);
    }

    primaryAssetMapping.set(corr.secondaryAsset.id, bignumber(corr.value).toNumber());

    let secondaryAssetMapping = assetToCorrelation.get(corr.secondaryAsset.id);
    if (!secondaryAssetMapping) {
      secondaryAssetMapping = new Map<string, number>();
      assetToCorrelation.set(corr.secondaryAsset.id, secondaryAssetMapping);
    }

    secondaryAssetMapping.set(corr.primaryAsset.id, bignumber(corr.value).toNumber());
  }

  return assetToCorrelation;
};
export const createCorrelation = (
  correlationMap: Map<string, Map<string, number>>,
  x: string[]
): (number | null)[][] => {
  const correlation: (number | null)[][] = [];
  for (let j = x.length - 1; j >= 0; j--) {
    const jAsset = x[j];
    const assetCorrelation: (number | null)[] = [];
    for (let i = 0; i < x.length; i++) {
      const iAsset = x[i];
      if (iAsset === jAsset) {
        assetCorrelation.push(1);
      } else if (i > j) {
        // y axis is reversed

        const value = correlationMap.get(iAsset)?.get(jAsset);
        assetCorrelation.push(value ?? null);
      } else {
        assetCorrelation.push(null);
      }
    }
    correlation.push(assetCorrelation);
  }

  return correlation;
};
