import { Stack, Typography } from '@mui/joy';
import type { ReactElement } from 'react';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import { type IMultiFactorDetails, useFactorSuspenseQuery } from 'generated/graphql';
import SubmitButton from 'components/technical/form/SubmitButton';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormInput } from 'components/technical/inputs';
import FormStaticSingleAutocomplete from 'components/technical/form/FormStaticSingleAutocomplete';
import FormSwitch from 'components/technical/form/FormSwitch';
import AddButton from 'components/technical/AddButton';
import RemoveButton from 'components/technical/RemoveButton';
import {
  createFactorSelectOptions,
  type CreateOrUpdateMultifactorFormInputFields,
  DEFAULT_FACTOR_WEIGHT,
  groupFactorsByCategory,
} from './AssetScreenerService';
import { defaultRowSpacing } from '../../../StackSpacing.ts';

type MultiFactorDetails = Omit<IMultiFactorDetails, 'multifactor'>;

type CreateAndUpdateMultifactorDialogBodyProps = {
  multifactorDetails?: MultiFactorDetails;
};

const CreateAndUpdateMultifactorDialogBody = ({
  multifactorDetails,
}: CreateAndUpdateMultifactorDialogBodyProps): ReactElement => {
  const methods = useFormContext<CreateOrUpdateMultifactorFormInputFields>();
  const {
    fields: factorWeights,
    remove: removeFactorWeight,
    append: appendFactorWeight,
  } = useFieldArray<CreateOrUpdateMultifactorFormInputFields>({
    control: methods.control,
    name: 'factorWeights',
  });

  const factorSuspenseQuery = useFactorSuspenseQuery();

  const factorOptions = createFactorSelectOptions(factorSuspenseQuery.data.multifactor.getAllSupportedFactors);

  return (
    <>
      <GDialogHeader>{multifactorDetails ? 'Update' : 'Create'} multifactor</GDialogHeader>
      <Stack spacing={3}>
        <Stack spacing={defaultRowSpacing}>
          <Stack direction="row" spacing={4}>
            <FormInput<CreateOrUpdateMultifactorFormInputFields>
              name={'name' as const}
              type="text"
              width="xl2"
              label="Multifactor name"
            />
            <FormInput<CreateOrUpdateMultifactorFormInputFields>
              name={'description' as const}
              type="text"
              width="xl2"
              label="Multifactor description"
            />
          </Stack>
          <Typography>Custom scoring criterion</Typography>
          {factorWeights.map((factorWeight, index) => (
            <Stack key={factorWeight.id} direction="row" alignItems="flex-end" spacing={4}>
              <FormStaticSingleAutocomplete<CreateOrUpdateMultifactorFormInputFields>
                name={`factorWeights.${index}.metricId`}
                width="normal"
                menuWidth={'xl2'}
                options={factorOptions}
                label="Metric"
                groupBy={groupFactorsByCategory}
                isValueEqual={(val1, val2) => Number(val1) === Number(val2)}
              />
              <FormInput<CreateOrUpdateMultifactorFormInputFields>
                name={`factorWeights.${index}.weight`}
                type="number"
                width="normal"
                label="Weight"
                startDecorator="%"
              />
              <Stack direction="row" width="max-content" spacing={1} mb={1}>
                <FormSwitch
                  name={`factorWeights.${index}.betterIfLower`}
                  preLabel="Take highest values"
                  label="Take smallest values"
                />
              </Stack>
              <RemoveButton
                disabled={factorWeights.length <= 2 || methods.formState.isSubmitting}
                onClick={(): void => {
                  removeFactorWeight(index);
                }}
              />
            </Stack>
          ))}
        </Stack>
        <Stack>
          <AddButton
            disabled={methods.formState.isSubmitting}
            width="normal"
            onClick={(): void => {
              appendFactorWeight({ ...DEFAULT_FACTOR_WEIGHT });
            }}
          >
            Add new metric
          </AddButton>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
          <SubmitButton width="smaller">Save</SubmitButton>
        </Stack>
      </Stack>
    </>
  );
};

export default CreateAndUpdateMultifactorDialogBody;
