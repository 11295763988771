import type { ReactElement } from 'react';
import type { AssetLabelInput } from '../../../market/asset/AssetLabelService.ts';
import ShockList from './ShockList.tsx';
import { Stack, Typography } from '@mui/joy';
import { createAssetSelectOptions } from '../../../market/asset/AssetService.tsx';
import FormStaticSingleAutocomplete from '../../../technical/form/FormStaticSingleAutocomplete.tsx';
import type { FormInputType } from '../../../technical/form/Form.types.ts';
import type { ShockStepOutput } from './ModelAndAssumptionsStep.validation.tsx';
import { defaultRowSpacing } from '../../../StackSpacing.ts';
import GButton from '../../../technical/inputs/GButton/GButton.tsx';

export const ModelAndAssumptionsStep = (props: {
  goToNextStep: () => void;
  benchmarks: AssetLabelInput[];
}): ReactElement => {
  const benchmarkOptions = createAssetSelectOptions(props.benchmarks);

  return (
    <Stack gap={defaultRowSpacing}>
      <Stack>
        <Typography level="title-md">Assumptions</Typography>
        <FormStaticSingleAutocomplete<FormInputType<ShockStepOutput>>
          name={'benchmark' as const}
          width="xl3"
          label="Benchmark"
          placeholder="Select a benchmark"
          {...benchmarkOptions}
        />
      </Stack>
      <ShockList />
      <GButton
        onClick={props.goToNextStep}
        sx={{
          marginLeft: 'auto',
        }}
      >
        Next
      </GButton>
    </Stack>
  );
};
