import * as yup from 'yup';
import type { ObjectSchema, Schema } from 'yup';

import type {
  OptionsStressTestOutputFields,
  OptionsStressTestPositionOutputFields,
  ShockConfiguration,
} from './OptionsStressTest.types';
import { IPositionSide } from '../../../generated/graphql';
import { isValidFloatString, minFloatString } from '../../number.utils';

const shockSchema: Schema<ShockConfiguration> = yup.object({
  enabled: yup.boolean().required(),
  value: yup
    .string()
    .default('')
    .when('enabled', ([enabled], schema) => (enabled ? schema.required() : schema.transform(() => '')))
    .test('validFloatString', isValidFloatString)
    .test('minValue', minFloatString(-100)),
});

const positionSchema: ObjectSchema<OptionsStressTestPositionOutputFields> = yup.object({
  asset: yup
    .object({
      id: yup.string().required(),
    })
    .required(),
  amount: yup.number().required(),
  side: yup.string().required().oneOf(Object.values(IPositionSide)),
  premium: yup.number().required(),
});

export const schema: Schema<OptionsStressTestOutputFields> = yup.object({
  positions: yup.array(positionSchema).required().min(1),
  shocks: yup.object({
    volatilityShock: shockSchema,
    priceShock: shockSchema,
    interestFreeRateShock: shockSchema,
  }),
  positionsLength: yup
    .mixed()
    .optional()
    .when('positions', ([positions], schema) =>
      schema.test('atLeastOnePosition', 'At least one position is required', () => positions.length >= 1)
    ),
});
