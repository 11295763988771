import * as yup from 'yup';
import type { Schema } from 'yup';

import type { SelectOption } from 'components/technical/inputs/Select/Select.props.ts';
import { IPrimaryConstrainedQuantity } from '../../../../../generated/graphql.tsx';
import { yupLocale } from '../../../../../setup/yupLocale.ts';
import { yupWhen } from '../../../../../validation.ts';
import type { AssetLabelInput } from '../../../../market/asset/AssetLabelService.ts';

import { isBetaMetric, PORTFOLIO_EXPECTED_BETA_METRIC } from '../../../../metrics/PortfolioRiskMeasures.ts';
import { greaterThanMin, smallerThanMax } from '../MinMax.validation.ts';

import { ConstraintType } from '../ConstraintTypeValues.validation.ts';

export const shouldShowPrimaryConstraintRiskMetric = (constrainedQuantity: IPrimaryConstrainedQuantity): boolean => {
  return constrainedQuantity === IPrimaryConstrainedQuantity.TargetRisk;
};

export interface PortfolioPrimaryConstraintOutput {
  constrainedQuantity: IPrimaryConstrainedQuantity;
  constraintType: ConstraintType.Equal | ConstraintType.Between;
  constraintValue: { value: number | null; min: number | null; max: number | null };
  riskMetric: {
    name: Label;
    benchmark: AssetLabelInput | null;
  };
}

const createPrimaryPortfolioConstraintValueSchema = (
  allowShortLeverage: boolean,
  constrainedQuantity: IPrimaryConstrainedQuantity,
  riskMetric: { name: string | null } | null,
  value: ConstraintType
): yup.Schema<unknown> => {
  const maxConstraint =
    !allowShortLeverage && constrainedQuantity !== IPrimaryConstrainedQuantity.TargetRisk
      ? yup.number().max(100)
      : yup.number();

  const minConstraint =
    allowShortLeverage &&
    constrainedQuantity === IPrimaryConstrainedQuantity.TargetRisk &&
    isBetaMetric(riskMetric?.name ?? '')
      ? yup.number()
      : yup.number().moreThan(0);

  const fallbackSchema = yup.number().nullable();
  return yup
    .object({
      value:
        value === ConstraintType.Equal
          ? yup.number().concat(minConstraint).concat(maxConstraint).required(yupLocale.number.required)
          : fallbackSchema,
      min:
        value === ConstraintType.Between
          ? yup
              .number()
              .test('minValue', 'Must be smaller or equal to max', smallerThanMax)
              .concat(minConstraint)
              .concat(maxConstraint)
              .required(yupLocale.number.required)
          : fallbackSchema,
      max:
        value === ConstraintType.Between
          ? yup
              .number()
              .required()
              .test('maxValue', 'Must be greater or equal to min', greaterThanMin)
              .concat(minConstraint)
              .concat(maxConstraint)
              .required(yupLocale.number.required)
          : fallbackSchema,
    })
    .required();
};
export const getPrimaryConstraintQuantityOptions = (
  allowShortAndLeverage: boolean
): SelectOption<IPrimaryConstrainedQuantity>[] => {
  return allowShortAndLeverage ? primaryConstraintQuantityLongShort : primaryConstraintQuantityLongOnly;
};

export const portfolioConstraintTypeSchema: Schema<PortfolioPrimaryConstraintOutput['constraintType']> = yup
  .string()
  .oneOf([ConstraintType.Equal, ConstraintType.Between])
  .required();

export const createPrimaryConstraintSchema = (allowShortAndLeverage: boolean): Schema<unknown> =>
  yup
    .object({
      constrainedQuantity: yup
        .string()
        .oneOf(getPrimaryConstraintQuantityOptions(allowShortAndLeverage).map((opt) => opt.value))
        .required(),
      constraintValue: yupWhen(
        ['constraintType', 'constrainedQuantity', 'riskMetric'],
        ([constraintType, constrainedQuantity, riskMetric]) => {
          return createPrimaryPortfolioConstraintValueSchema(
            allowShortAndLeverage,
            constrainedQuantity,
            riskMetric,
            constraintType
          );
        }
      ),
      constraintType: portfolioConstraintTypeSchema,

      riskMetric: yupWhen(['constrainedQuantity'], ([constrainedQuantity]) => {
        return yup.object({
          name: yup.string().when([], (_values, schema) => {
            const shouldShowRiskMetric = shouldShowPrimaryConstraintRiskMetric(constrainedQuantity);
            if (!shouldShowRiskMetric) {
              return schema.nullable().optional();
            }

            return schema.required();
          }),
          benchmark: yup.object().when('name', {
            is: PORTFOLIO_EXPECTED_BETA_METRIC,
            // biome-ignore lint/suspicious/noThenProperty: yup api
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.nullable().optional(),
          }),
        });
      }),
    })
    .nullable();

export const primaryConstraintQuantityLabels: Record<IPrimaryConstrainedQuantity, string> = {
  [IPrimaryConstrainedQuantity.GrossExposure]: 'Gross exposure',
  [IPrimaryConstrainedQuantity.TargetReturn]: 'Target return',
  [IPrimaryConstrainedQuantity.TargetRisk]: 'Target risk',
};

const primaryConstraintQuantityValues: SelectOption<IPrimaryConstrainedQuantity>[] = [
  IPrimaryConstrainedQuantity.GrossExposure,
  IPrimaryConstrainedQuantity.TargetReturn,
  IPrimaryConstrainedQuantity.TargetRisk,
].map((val) => ({
  value: val,
  label: primaryConstraintQuantityLabels[val],
  key: primaryConstraintQuantityLabels[val],
}));

export const primaryConstraintQuantityLongOnly: SelectOption<IPrimaryConstrainedQuantity>[] =
  primaryConstraintQuantityValues.filter((opt) =>
    [IPrimaryConstrainedQuantity.GrossExposure, IPrimaryConstrainedQuantity.TargetRisk].includes(opt.value)
  );

export const primaryConstraintQuantityLongShort: SelectOption<IPrimaryConstrainedQuantity>[] =
  primaryConstraintQuantityValues;
