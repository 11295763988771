import type { PortfolioOptimizerInputFields } from '../portfolio/PortfolioOptimizer.validation.ts';
import { syncOutlookWithGivenUniverseFields } from '../assumptionsAndOutlook/AssumptionsAndOutlook.utils.ts';
import { gridWithInputStyles } from '../../../../technical/grids/gridStyles.ts';
import ErrorMessage from '../../../../technical/ErrorMessage.tsx';
import { Controller, useFormContext } from 'react-hook-form';
import type { ReactElement } from 'react';
import { defaultCol } from '../initialPortfolio/InitialPortfolioStep.utils.tsx';
import GAgGrid from '../../../../technical/grids/GAgGrid.tsx';
import { Box } from '@mui/joy';
import type { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import type { AssetOptimizerInputFields } from '../asset/AssetOptimizer.validation.ts';

const Universe = <ROW extends { id: string }>({
  rowData,
  columnDefs,
  onChange: onChangeProp,
}: {
  rowData: ROW[];
  columnDefs: (ColDef<ROW> | ColGroupDef<ROW>)[];
  onChange?: (rows: ROW[]) => void;
}): ReactElement => {
  const { control, getValues, setValue } = useFormContext<PortfolioOptimizerInputFields | AssetOptimizerInputFields>();
  return (
    <Controller<PortfolioOptimizerInputFields | AssetOptimizerInputFields, 'universe'>
      name={'universe'}
      control={control}
      /* jscpd:ignore-end */
      render={({ field: { value, onChange }, fieldState }): ReactElement => {
        return (
          <>
            <Box height="500px">
              <GAgGrid<ROW>
                onRowSelected={(e): void => {
                  const items: ROW[] = e.api.getSelectedRows();
                  onChange(items);
                  onChangeProp?.(items);

                  syncOutlookWithGivenUniverseFields(getValues, setValue);
                }}
                rowSelection="multiple"
                suppressRowClickSelection
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultCol}
                autoSizeStrategy={{ type: 'fitGridWidth' }}
                onGridReady={(e): void => {
                  const selectedRowIds = new Set<string>(Array.isArray(value) ? value.map((item) => item.id) : []);
                  let scrolledToFirstSelected = false;
                  e.api.forEachNodeAfterFilter((node) => {
                    if (selectedRowIds.has(node.data?.id ?? '')) {
                      node.setSelected(true);
                      if (!scrolledToFirstSelected) {
                        // scroll for first selected
                        e.api.ensureNodeVisible(node, 'middle');
                        scrolledToFirstSelected = true;
                      }
                    }
                  });
                }}
                rowHeight={gridWithInputStyles.rowHeight}
              />
            </Box>
            {fieldState.error && <ErrorMessage>{fieldState.error.message}</ErrorMessage>}
          </>
        );
      }}
    />
  );
};

export default Universe;
