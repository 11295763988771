import InitialPortfolioStep from './InitialPortfolioStep';
import type { StepConfig } from 'components/technical/wizard/StepConfig';
import type { AssetOptimizerInputFields } from '../../asset/AssetOptimizer.validation.ts';
import type { Portfolio } from '../PortfolioOptimizerWizard.tsx';
import { fields, label } from '../../initialPortfolio/InitialPortfolioStepConfig.tsx';

export const config = (
  optimizableFunds: Portfolio[],
  goToNextStep: () => void
): StepConfig<AssetOptimizerInputFields> => ({
  label,
  element: <InitialPortfolioStep optimizableFunds={optimizableFunds} goToNextStep={goToNextStep} />,
  fields,
});
