import { Box, List, ListDivider, ListItem, Stack, Tooltip, Typography, useColorScheme } from '@mui/joy';
import { VenueIconList } from 'components/venue/VenueIconList';
import type { SubAccountType } from './Account.types';
import VenueLabel from 'components/venue/VenueLabel';
import { Fragment, type ReactNode } from 'react';

const AccountCardSubAccounts = ({ subAccounts }: { subAccounts: SubAccountType[] }): ReactNode => {
  const { mode } = useColorScheme();

  const mapSubAccountsToVenues = (): {
    name: string;
    venue: {
      label: string;
    };
  }[] => {
    return subAccounts.map((subAccount) => ({
      name: subAccount.name,
      venue: {
        label: subAccount.account.venue.label,
      },
    }));
  };

  const mappedSubAccounts = mapSubAccountsToVenues();

  return (
    <Stack direction="column" spacing={0.5} paddingRight="1.25rem" alignItems="flex-end" justifyContent="space-between">
      <Typography fontSize="12px">Sub Accounts</Typography>
      <Tooltip
        placement="bottom-end"
        variant="outlined"
        sx={{
          border: mode === 'dark' ? 'none' : '1px solid neutral.200',
        }}
        title={
          <List
            size="md"
            variant="plain"
            sx={{
              maxWidth: '13rem',
            }}
          >
            {mappedSubAccounts.map((subAccount, i) => (
              <Fragment key={`${subAccount.name}-${subAccount.venue.label}`}>
                <ListItem
                  sx={{
                    padding: '0 .8rem',
                    cursor: 'default',
                    '&:not(.Mui-selected, [aria-selected="true"]):hover': {
                      bgcolor: 'transparent',
                    },
                  }}
                >
                  <VenueLabel accountName={subAccount.name} venue={subAccount.venue.label} format="short" />
                  <Typography level="body-sm" marginLeft=".6rem">
                    {subAccount.name}
                  </Typography>
                </ListItem>
                {i < mappedSubAccounts.length - 1 && <ListDivider inset="gutter" />}
              </Fragment>
            ))}
          </List>
        }
      >
        {/* Without Box VenueIconList will not trigger the Tooltip */}
        <Box>
          <VenueIconList maxItems={4} accounts={mappedSubAccounts} />
        </Box>
      </Tooltip>
    </Stack>
  );
};

export default AccountCardSubAccounts;
