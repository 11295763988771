import { Stack, TabPanel, useTheme } from '@mui/joy';
import uniq from 'lodash/fp/uniq';
import type { FunctionComponent } from 'react';
import { useLocation, useParams } from 'react-router';
import { TwoThirdsLayout } from 'components/TwoThirdsLayout.tsx';

import { useSearchableAssetsIds } from 'components/technical/Appbar/AppbarSearch/useSearchableAssets';
import { GraphQLErrorCardMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import Loader from 'components/technical/Loader/Loader';
import Message from 'components/technical/Message';
import NavigableTab from 'components/technical/NavigableTabs/NavigableTab';
import NavigableTabs from 'components/technical/NavigableTabs/NavigableTabs';
import type { AssetGroupElement } from './AssetDashboard.types';
import {
  createLink,
  genieTabs,
  getStrategy,
  isAllowedClusterTypes,
  type Tab,
  useFilteredMarket,
} from './AssetDashboardService.ts';
import ClusterGroupList from './AssetGroup/ClusterGroupList';
import AssetGroupChart from './AssetGroupChart/AssetGroupChart';
import AssetList from './AssetList/AssetList';
import { useFinalColorScheme } from '../../../../useFinalColorScheme.ts';

const AssetDashboardContainer: FunctionComponent = () => {
  const params = useParams();
  const cluster = params.cluster;
  const clusterType = params.clusterType;

  if (!isAllowedClusterTypes(clusterType)) {
    throw new Error(`Unsuported cluster type ${clusterType}`);
  }
  const currentCluster = cluster ?? '';

  const { data, error, loading } = useFilteredMarket(clusterType);

  if (error) {
    return <GraphQLErrorCardMessage error={error} />;
  }

  if (loading) {
    return <Loader />;
  }

  if (!data) {
    return <Message>Missing market data</Message>;
  }

  return (
    <AssetDashboard
      assets={data.assets}
      userGroups={data.userGroups}
      currentClusterName={currentCluster}
      clusterType={clusterType}
    />
  );
};

const AssetDashboard: FunctionComponent<{
  assets: AssetGroupElement[];
  userGroups: { clusterName: string }[];
  currentClusterName: string;
  clusterType: 'genie' | 'user';
}> = ({ assets, userGroups, currentClusterName, clusterType }) => {
  const location = useLocation();
  const navigableAssetIds = useSearchableAssetsIds();
  const colorScheme = useFinalColorScheme();

  const userClusters = uniq(userGroups.map((group) => group.clusterName));
  const userTabs = userClusters.map((cluster) => ({
    name: cluster,
    link: encodeURIComponent(cluster),
    cluster: cluster,
    type: 'user',
    showNotAssignedAssets: false,
  }));

  const allTabs: (Tab & { type: string })[] = [...genieTabs.map((tab) => ({ ...tab, type: 'genie' })), ...userTabs];
  const selectedTab = allTabs.find((tab) => tab.name === currentClusterName);
  const theme = useTheme();
  if (!selectedTab) {
    return <Message>Missing cluster</Message>;
  }

  const strategy = getStrategy(colorScheme, theme, clusterType, selectedTab.cluster);
  const group = strategy(assets);

  return (
    <NavigableTabs
      panels={
        <TabPanel value={location.pathname} sx={{ padding: 0 }}>
          <Stack spacing={2} justifyContent="stretch">
            <TwoThirdsLayout
              left={<AssetGroupChart group={group} />}
              right={
                <ClusterGroupList
                  clusterName={currentClusterName}
                  clusterType={clusterType}
                  subgroups={group.subgroups}
                />
              }
            />

            <AssetList group={group} navigableAssetIds={navigableAssetIds} />
          </Stack>
        </TabPanel>
      }
    >
      {allTabs.map((tab) => (
        <NavigableTab link={createLink(tab)} key={tab.link} text={tab.name} tooltip={tab?.tooltip} />
      ))}
    </NavigableTabs>
  );
};

export default AssetDashboardContainer;
