import { Add } from '@mui/icons-material';
import { Stack } from '@mui/joy';
import type { ReactElement } from 'react';
import { type DefaultValues, type FieldValues, type Path, useForm } from 'react-hook-form';

import type { Schema } from 'yup';
import GFormProvider from 'components/technical/form/GFormProvider';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton';
import { type GraphQlErrorHandler, useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError.tsx';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import { FormInput } from 'components/technical/inputs';
import GoBackButton from 'components/technical/inputs/GButton/GoBackButton';

const InputGenieAccountDetailsStep = <TFormValues extends FieldValues>({
  onGoBack,
  handleSubmit,
  defaultValues,
  formSchema,
  title,
  fields,
}: {
  onGoBack?: () => void;
  handleSubmit: (
    data: TFormValues,
    onError: GraphQlErrorHandler,
    setError: (field: Path<TFormValues>, error: { type: string; message: string }) => void
  ) => Promise<void>;
  defaultValues: DefaultValues<TFormValues>;
  formSchema: Schema;
  fields: { label: string; name: string }[];
  title: string;
}): ReactElement => {
  const methods = useForm<TFormValues>({
    resolver: gYupResolver(formSchema),
    defaultValues: defaultValues,
  });

  // jscpd:ignore-start
  const { onError } = useGraphQLApiError(methods);

  const handleFormSubmit = async (data: TFormValues): Promise<void> => {
    await handleSubmit(data, onError, methods.setError);
  };
  // jscpd:ignore-end

  return (
    <GFormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
        <GDialogHeader>{title}</GDialogHeader>
        <Stack spacing={3}>
          <Stack spacing={1.5}>
            {fields.map((field) => (
              <FormInput key={field.name} label={field.label} name={field.name} width="fullWidth" />
            ))}
          </Stack>
          <Stack alignItems="center" spacing={1.5}>
            <GraphQLApiFormErrorMessage />
            <Stack spacing={1.5}>
              <SubmitButton width="xl2" startDecorator={<Add />}>
                Create
              </SubmitButton>
              {onGoBack && <GoBackButton onClick={onGoBack} disabled={methods.formState.isSubmitting} />}
            </Stack>
          </Stack>
        </Stack>
      </form>
    </GFormProvider>
  );
};

export default InputGenieAccountDetailsStep;
