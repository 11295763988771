export const transitionDurationS = {
  Short: 0.1,
  Standard: 0.3,
};

export const transitionDuration = {
  Short: `${transitionDurationS}s`,
  Standard: `${transitionDurationS.Standard}s`,
};

export const tooltipEnterDelay = {
  Default: 100,
  Slow: 500,
};

export const pageInsetSpacing = 2;
