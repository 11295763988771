import { ApolloError } from '@apollo/client';
import { useCallback, useRef, useState } from 'react';

export type LoadingDestructor = () => void;
export type AbortableLoader = (abort: AbortSignal) => Promise<void>;

export interface UseLoading {
  loading: boolean;
  load: (cb: AbortableLoader) => LoadingDestructor;
}

export const useLoading = (): UseLoading => {
  const [loading, setLoading] = useState(false);
  const loadingId = useRef(0);

  const load = useCallback((cb: AbortableLoader): LoadingDestructor => {
    setLoading(true);
    const currentInvocationId = ++loadingId.current;

    const controller = new AbortController();
    cb(controller.signal)
      .catch((e) => {
        if (controller.signal.aborted) {
          return;
        }

        // there is also a chance that we unrendered a component which did the request, which also cancels it
        if (e instanceof ApolloError && e.networkError?.name === 'AbortError') {
          return;
        }

        console.error('Problem with fetching data', e);
      })
      .finally(() => {
        if (loadingId.current === currentInvocationId) {
          setLoading(false);
        }
      });

    return () => {
      controller.abort();
      setLoading(false);
    };
  }, []);

  return {
    loading,
    load,
  };
};
