export const statData = {
  fValue: {
    name: 'F Value',
    tooltip: '',
  },
  rSquared: {
    name: 'R Squared',
    tooltip: '',
  },
  alphaValue: {
    name: 'Alpha',
    tooltip: '',
  },
} satisfies Record<string, { name: string; tooltip: string }>;

type RegressionStats = keyof typeof statData;
export const visibleStats: RegressionStats[] = ['rSquared', 'fValue', 'alphaValue'];
