import PageShell from '../components/technical/PageShell/PageShell.tsx';
import AssetDashboard from '../components/market/asset/dashboard/AssetDashboard.tsx';
import AssetClusterGroupDashboard from '../components/market/asset/dashboard/AssetGroup/AssetClusterGroupDashboard.tsx';
import { createIndexRoute } from './CreateIndexRoute.tsx';
import { genieTabs as assetMarketTabs } from '../components/market/asset/dashboard/AssetDashboardService.ts';
import { getAssetById } from '../components/market/asset/UseAsset.tsx';
import {
  AssetGuardRoute,
  OptionsGuardRoute,
  SingleAssetPageContainer,
} from '../components/market/asset/singleAsset/SingleAssetRoutes.tsx';
import { IAssetType } from '../generated/graphql.tsx';
import PublicAssetSpotTab from '../components/market/asset/singleAsset/public/PublicAssetSpotTab.tsx';
import PublicAssetDerivativesTab from '../components/market/asset/singleAsset/public/PublicAssetDerivativesTab.tsx';
import PublicAssetOptionsTab from '../components/market/asset/singleAsset/public/PublicAssetOptionsTab.tsx';
import PublicAssetHoldingsTab from '../components/market/asset/singleAsset/public/PublicAssetHoldingsTab.tsx';
import PrivateAssetPriceTab from '../components/market/asset/singleAsset/private/PrivateAssetPriceTab.tsx';
import PrivateAssetVestingTab from '../components/market/asset/singleAsset/private/PrivateAssetVestingTab.tsx';
import { NotFoundRoute } from './NotFoundRoute.tsx';
import CorrelationDashboard from '../components/market/asset/correlation/CorrelationDashboard.tsx';
import AssetScreenerDashboard from '../components/market/asset/assetScreener/AssetScreenerDashboard.tsx';
import type { ApolloClient } from '@apollo/client';
import type { Params } from 'react-router';
import MarketRegime from '../components/market/regime/MarketRegime.tsx';
import { hasGroups } from '../groupGuards.ts';
import PrivateRoute from './PrivateRoute/PrivateRoute.tsx';
import { MARKET_REGIME } from '../Permissions.ts';

export const marketRoutes = {
  handle: { breadcrumb: 'Research', title: 'Research' },
  path: 'market',

  children: [
    {
      path: 'clusters',
      children: [
        {
          path: ':clusterType',
          children: [
            {
              handle: {
                breadcrumb: ({ cluster }: { cluster: string }): string => (cluster ? cluster : '?'),
              },
              path: ':cluster',
              element: (
                <PageShell>
                  <AssetDashboard />
                </PageShell>
              ),
            },
            {
              handle: {
                breadcrumb: ({ group }: { group: string }): string => (group ? group : '?'),
              },
              path: ':cluster/:group',
              element: (
                <PageShell>
                  <AssetClusterGroupDashboard />
                </PageShell>
              ),
            },
            createIndexRoute(`/app/market/clusters/genie/${assetMarketTabs[0].link}`),
          ],
        },
        createIndexRoute(`genie/${assetMarketTabs[0].link}`),
      ],
    },
    {
      path: 'assets',
      children: [
        {
          handle: {
            breadcrumb: async (params: Params, apolloClient: ApolloClient<unknown>): Promise<string> => {
              try {
                const assetId = params.assetId;
                if (!assetId) {
                  return '?';
                }

                const asset = await getAssetById(apolloClient, assetId);
                return asset.name ?? '?';
              } catch (e: unknown) {
                console.error("Couldn't find asset", e);
                return '?';
              }
            },
          },
          path: ':assetId',
          element: <SingleAssetPageContainer />,
          children: [
            {
              handle: {
                breadcrumb: 'Spot',
              },
              path: 'spot',
              element: (
                <AssetGuardRoute assetType={IAssetType.Public}>
                  <PublicAssetSpotTab />
                </AssetGuardRoute>
              ),
            },
            {
              handle: {
                breadcrumb: 'Derivatives',
              },
              path: 'derivatives',
              element: (
                <AssetGuardRoute assetType={IAssetType.Public}>
                  <PublicAssetDerivativesTab />
                </AssetGuardRoute>
              ),
            },
            {
              handle: {
                breadcrumb: 'Options',
              },
              path: 'options',
              element: (
                <OptionsGuardRoute>
                  <PublicAssetOptionsTab />
                </OptionsGuardRoute>
              ),
            },
            {
              handle: {
                breadcrumb: 'Holdings',
              },
              path: 'holdings',
              element: (
                <AssetGuardRoute assetType={IAssetType.Public}>
                  <PublicAssetHoldingsTab />
                </AssetGuardRoute>
              ),
            },
            {
              handle: {
                breadcrumb: 'Price',
              },
              path: 'price',
              element: (
                <AssetGuardRoute assetType={IAssetType.Private}>
                  <PrivateAssetPriceTab />
                </AssetGuardRoute>
              ),
            },
            {
              handle: {
                breadcrumb: 'Vesting',
              },
              path: 'vesting',
              element: (
                <AssetGuardRoute assetType={IAssetType.Private}>
                  <PrivateAssetVestingTab />
                </AssetGuardRoute>
              ),
            },
            {
              // catching index route and redirect happens in SingleAsset component
              index: true,
              element: <div />,
            },
          ],
        },
        NotFoundRoute,
      ],
    },
    {
      path: 'correlations',
      handle: {
        breadcrumb: 'Correlations',
      },
      element: (
        <PageShell>
          <CorrelationDashboard />
        </PageShell>
      ),
    },
    {
      path: 'asset-screener',
      handle: {
        breadcrumb: 'Asset screener',
      },
      element: (
        <PageShell>
          <AssetScreenerDashboard />
        </PageShell>
      ),
    },
    {
      path: 'regime',
      handle: {
        breadcrumb: 'Market regimes',
      },
      element: (
        <PrivateRoute
          to="/"
          element={
            <PageShell>
              <MarketRegime />
            </PageShell>
          }
          shouldAllow={hasGroups(MARKET_REGIME)}
        />
      ),
    },
    {
      path: 'news-explorer',
      handle: {
        breadcrumb: 'News explorer',
      },
      lazy: async () => ({
        Component: (await import('../components/market/newsExplorer/NewsExplorer.tsx')).default,
      }),
    },
    createIndexRoute(`clusters/genie/${assetMarketTabs[0].link}`),
  ],
};
