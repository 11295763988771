import isEmpty from 'lodash/fp/isEmpty';
import { bignumber } from 'mathjs';
import type { ReactElement } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { formatPercentage } from 'components/formatter.utils';
import FormStaticSingleAutocomplete from 'components/technical/form/FormStaticSingleAutocomplete';
import { FormInput } from 'components/technical/inputs';
import RemoveButton from 'components/technical/RemoveButton';

import { type FineTunerInputFields, ensureDollarValueWithinBounds } from './PortfolioBuilder.utils.ts';
import type { PublicAsset, PrivateAsset } from '../../market/asset/Asset.types';
import { createAssetSelectOptions } from '../../market/asset/AssetService';
import { isNil } from 'lodash/fp';
import GSlider from 'components/technical/inputs/GSlider/GSlider.tsx';

type FineTunerAssetRowProps = {
  index: number;
  onRemove: () => void;
  supportedAssets: Array<PublicAsset | PrivateAsset>;
  showDollarValues: boolean;
  portfolioEquityOrDefault: number;
};

const getWeightMinMax = (maxLeverage: string): [number, number] => {
  if (isEmpty(maxLeverage)) {
    return [-2, 2];
  }
  const parsedMaxLeverage = bignumber(maxLeverage).toNumber();
  return [-parsedMaxLeverage, parsedMaxLeverage];
};

const PortfolioBuilderAssetRow = ({
  index,
  onRemove,
  supportedAssets,
  showDollarValues,
  portfolioEquityOrDefault,
}: FineTunerAssetRowProps): ReactElement => {
  const { setValue, getFieldState, formState, control } = useFormContext<FineTunerInputFields>();

  const pathPrefix = `assetWeights.${index}` as const;

  const [maxLeverage, id, assetWeights, dollarValue] = useWatch({
    name: [`${pathPrefix}.maxLeverage`, `${pathPrefix}.id`, 'assetWeights', `${pathPrefix}.dollarValue`],
    control,
  });

  const assetWeightRowFieldState = getFieldState(pathPrefix, formState);
  const maxLeverageFieldState = getFieldState(`${pathPrefix}.maxLeverage`, formState);

  const normalizedAssetOptions = createAssetSelectOptions(supportedAssets);

  const alreadyAddedAssets = new Set(assetWeights.map((asset) => asset.id));
  alreadyAddedAssets.delete(id); // autocomplete should have it's own value in option list

  const assetOptions = normalizedAssetOptions.options
    .map((opt) => ({
      ...opt,
      value: opt.value.id,
    }))
    // avoid asset duplication
    .filter((opt) => !alreadyAddedAssets.has(opt.value));

  const idToOption = Object.fromEntries(normalizedAssetOptions.options.map((opt) => [opt.value.id, opt]));
  const [minWeight, maxWeight] = getWeightMinMax(maxLeverage);
  return (
    <>
      <FormStaticSingleAutocomplete<FineTunerInputFields>
        name={`${pathPrefix}.id` as const}
        placeholder="Choose asset"
        error={assetWeightRowFieldState.error?.message}
        {...normalizedAssetOptions}
        options={assetOptions}
        groupBy={(opt): string => normalizedAssetOptions.groupBy(idToOption[opt.value])}
        isValueEqual={(a, b): boolean => a === b}
      />
      <FormInput<FineTunerInputFields>
        name={`${pathPrefix}.maxLeverage` as const}
        type="number"
        onBlur={() =>
          setValue(
            `${pathPrefix}.dollarValue`,
            ensureDollarValueWithinBounds(dollarValue, maxLeverage, portfolioEquityOrDefault)
          )
        }
      />
      <GSlider
        value={bignumber(dollarValue || 0)
          .div(portfolioEquityOrDefault)
          .toNumber()}
        onChange={(_e, newValue) => {
          //@ts-expect-error: for single knobbed slider value is always number
          const newDollarValue = bignumber(portfolioEquityOrDefault * newValue)
            .toDP(2)
            .toString();
          setValue(`${pathPrefix}.dollarValue`, newDollarValue);
        }}
        showCurrentValue
        step={0.001}
        getLabel={formatPercentage}
        disabled={!isNil(maxLeverageFieldState.error)}
        min={minWeight}
        max={maxWeight}
      />
      {showDollarValues && (
        <FormInput<FineTunerInputFields> startDecorator="$" name={`${pathPrefix}.dollarValue`} type="number" />
      )}
      <RemoveButton disabled={formState.isSubmitting || assetWeights.length === 1} onClick={onRemove} />
    </>
  );
};

export default PortfolioBuilderAssetRow;
