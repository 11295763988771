import type { ReactElement } from 'react';
import { Pencil } from '../../icons';
import { Stack } from '@mui/joy';
import { DialogMenuItem, type DialogItemProps } from '../../DialogDropdown/DialogMenuItem.tsx';

const EditDialogButton = (props: Omit<DialogItemProps, 'children'>): ReactElement => (
  <DialogMenuItem {...props}>
    <Stack alignItems={'center'} direction={'row'} columnGap={0.5}>
      <Pencil fontSize="md" />
      Edit
    </Stack>
  </DialogMenuItem>
);

export default EditDialogButton;
