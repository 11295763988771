import type { ReactElement } from 'react';
import IncognitoAppbar from '../Appbar/IncognitoAppbar.tsx';
import Home from '../../Home/Home.tsx';

const MainIncognito = (): ReactElement => {
  return (
    <div>
      <IncognitoAppbar />
      <Home />
    </div>
  );
};

export default MainIncognito;
