import { Add } from '@mui/icons-material';
import { Stack } from '@mui/joy';
import { type ReactElement, useEffect } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import FormSelect from 'components/technical/form/FormSelect.tsx';
import FormStaticSingleAutocomplete from 'components/technical/form/FormStaticSingleAutocomplete';
import type { StaticAutocompleteProps } from 'components/technical/inputs/Autocomplete/StaticSingleAutocomplete.props';

import GButton from 'components/technical/inputs/GButton/GButton.tsx';
import RemoveButton from 'components/technical/RemoveButton.tsx';
import {
  portfolioConstraintTypeValues,
  type PortfolioLevelConstraintsStepInput,
} from './PortfolioConstraintsStep.validation.ts';
import {
  getPrimaryConstraintQuantityOptions,
  shouldShowPrimaryConstraintRiskMetric,
} from './PortfolioPrimaryConstraint.validation';
import { IPrimaryConstrainedQuantity } from '../../../../../generated/graphql.tsx';
import type { AssetLabelInput } from '../../../../market/asset/AssetLabelService.ts';
import { getFormat } from '../../../../metrics/MetricsData.tsx';
import { PORTFOLIO_EXPECTED_BETA_METRIC } from '../../../../metrics/PortfolioRiskMeasures.ts';
import { useValueChanged } from '../../../../UseValueChanged.tsx';
import { ConstraintValueInputs } from '../ConstraintValueInputs.tsx';
import { getValueKeys } from '../ConstraintValueKeys.ts';
import { riskMeasureValues } from '../../objective.utils.ts';
import { ConstraintType } from '../ConstraintTypeValues.validation.ts';
import type { ObjectivesStepInput } from '../objective/ObjectivesStep.validation.ts';

const formatToAdornment: Record<'number' | 'cash' | 'percentage' | 'percentage_4', string> = {
  cash: '$',
  percentage: '%',
  percentage_4: '%',
  number: '',
};

const calculateValueStartAdornment = (constrainedQuantity: IPrimaryConstrainedQuantity, riskMetric: string): string => {
  if (constrainedQuantity === IPrimaryConstrainedQuantity.TargetRisk) {
    if (riskMetric) {
      return formatToAdornment[getFormat(riskMetric)];
    }

    return '';
  }

  return '%';
};

const DEFAULT_PRIMARY_CONSTRAINTS = {
  constrainedQuantity: IPrimaryConstrainedQuantity.GrossExposure,
  constraintType: ConstraintType.Equal,
  constraintValue: {
    value: null,
    min: null,
    max: null,
  },
  riskMetric: {
    name: '',
    benchmark: null,
  },
} as const;

const PortfolioPrimaryConstraint = ({
  assetOptions,
}: {
  assetOptions: Pick<StaticAutocompleteProps<AssetLabelInput>, 'options' | 'optionHeight' | 'limitTags' | 'groupBy'>;
}): ReactElement => {
  const prefix = 'portfolioConstraints.primaryConstraint' as const;
  const { resetField, formState, setValue, clearErrors, trigger, getValues } = useFormContext<
    PortfolioLevelConstraintsStepInput & ObjectivesStepInput
  >();
  const constrainedQuantity = useWatch({ name: `${prefix}.constrainedQuantity` });
  const riskMetric = useWatch({ name: `${prefix}.riskMetric.name` });
  const benchmark = useWatch({ name: `${prefix}.riskMetric.benchmark` });
  const secondaryConstraint = useWatch<PortfolioLevelConstraintsStepInput, 'portfolioConstraints.secondaryConstraint'>({
    name: 'portfolioConstraints.secondaryConstraint',
  });
  const duplicatedAssetFieldError = useFormState<PortfolioLevelConstraintsStepInput>({
    name: 'portfolioConstraints.duplicatedAsset',
    exact: true,
  }).errors?.portfolioConstraints?.duplicatedAsset;
  const allowShortAndLeverage = getValues('allowShortAndLeverage');
  const options = getPrimaryConstraintQuantityOptions(allowShortAndLeverage);
  const constrainedQualityChanged = useValueChanged(constrainedQuantity);
  const riskChanged = useValueChanged(riskMetric);
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (!constrainedQualityChanged && !riskChanged) {
      return;
    }

    resetField(`${prefix}.constraintType`);
    for (const key of getValueKeys()) {
      setValue(`${prefix}.constraintValue.${key}`, null);
      clearErrors(`${prefix}.constraintValue.${key}`);
    }
  }, [constrainedQualityChanged, riskChanged, resetField, setValue, trigger, clearErrors]);

  const betaAssetChanged = useValueChanged(benchmark);
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    trigger('portfolioConstraints.duplicatedAsset');
  }, [betaAssetChanged, trigger]);

  const shouldShowBetaAsset = riskMetric === PORTFOLIO_EXPECTED_BETA_METRIC;
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (!shouldShowBetaAsset) {
      clearErrors(`${prefix}.riskMetric.benchmark`);
    }
  }, [clearErrors, shouldShowBetaAsset]);

  const showPrimaryConstraintRiskMeasure = shouldShowPrimaryConstraintRiskMetric(constrainedQuantity);
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (!showPrimaryConstraintRiskMeasure) {
      resetField(`${prefix}.riskMetric`);
    }
  }, [showPrimaryConstraintRiskMeasure, resetField]);

  const valueStartAdornment = calculateValueStartAdornment(constrainedQuantity, riskMetric);

  return (
    <>
      <Stack direction="row" flexWrap="wrap" spacing={1.5} alignItems="flex-end">
        <FormSelect<PortfolioLevelConstraintsStepInput>
          name={`${prefix}.constrainedQuantity`}
          width="normal"
          label="Metric"
          options={options}
        />
        {showPrimaryConstraintRiskMeasure && (
          <FormSelect<PortfolioLevelConstraintsStepInput>
            name={`${prefix}.riskMetric.name`}
            width="xl2"
            label="Risk"
            options={riskMeasureValues}
          />
        )}
        {shouldShowBetaAsset && (
          <Stack direction="row" alignItems="flex-end" spacing={1.5}>
            <span>with respect to</span>
            <FormStaticSingleAutocomplete<PortfolioLevelConstraintsStepInput>
              name={`${prefix}.riskMetric.benchmark`}
              label="Asset of Beta"
              width="xl2"
              placeholder="Asset of Beta"
              {...assetOptions}
              error={duplicatedAssetFieldError?.message}
            />
          </Stack>
        )}
        <FormSelect<PortfolioLevelConstraintsStepInput>
          name={`${prefix}.constraintType` as const}
          width="normal"
          options={portfolioConstraintTypeValues}
          label="Condition"
        />
        <ConstraintValueInputs startAdornment={valueStartAdornment} width="normal" pathPrefix={prefix} />
        {!secondaryConstraint && (
          <RemoveButton disabled={formState.isSubmitting} onClick={(): void => resetField(prefix)} />
        )}
      </Stack>
    </>
  );
};

const PortfolioSecondaryConstraintContainer = ({
  assetOptions,
}: {
  assetOptions: Pick<StaticAutocompleteProps<AssetLabelInput>, 'options' | 'optionHeight' | 'limitTags' | 'groupBy'>;
}): ReactElement | null => {
  const { setValue, formState } = useFormContext<PortfolioLevelConstraintsStepInput>();
  const primaryConstraint = useWatch<PortfolioLevelConstraintsStepInput, 'portfolioConstraints.primaryConstraint'>({
    name: 'portfolioConstraints.primaryConstraint',
  });

  const hasPrimaryConstraint = !!primaryConstraint;
  return !hasPrimaryConstraint ? (
    <GButton
      disabled={formState.isSubmitting}
      variant="plain"
      onClick={(): void => {
        setValue('portfolioConstraints.primaryConstraint', DEFAULT_PRIMARY_CONSTRAINTS, {
          shouldValidate: true,
        });
      }}
      width="xl2"
      startDecorator={<Add />}
    >
      Add portfolio constraint
    </GButton>
  ) : (
    <PortfolioPrimaryConstraint assetOptions={assetOptions} />
  );
};

export default PortfolioSecondaryConstraintContainer;
