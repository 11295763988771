import { ColorLensOutlined, DateRange } from '@mui/icons-material';
import { Stack } from '@mui/joy';
import type { ReactElement } from 'react';

import { BorderedActionItem } from 'components/technical/BorderedActionItem';
import GLink from 'components/technical/GLink/GLink';
import { Coin, Group, Key, NotificationEmpty, Wallet, WalletGroup, Paragraph } from 'components/technical/icons';
import GButton from 'components/technical/inputs/GButton/GButton';
import SectionPanel from 'components/technical/layout/SectionPanel';
import ColorSchemeChanger from './ColorSchemeToggler';
import TilesDateRangeSelector from './TilesDateRangeSelector';

const ManagementDashboard = (): ReactElement => {
  return (
    <>
      <SectionPanel title="Portfolio">
        <Stack spacing={1.5}>
          <BorderedActionItem
            icon={Coin}
            title="Assets"
            description="View and edit your assets."
            action={({ width, color }): ReactElement => (
              <GLink to="/app/management/asset">
                <GButton width={width} color={color}>
                  Open
                </GButton>
              </GLink>
            )}
          />
          <BorderedActionItem
            icon={Wallet}
            title="Accounts"
            description="View and edit your accounts."
            action={({ width, color }): ReactElement => (
              <GLink to="/app/management/account">
                <GButton width={width} color={color}>
                  Open
                </GButton>
              </GLink>
            )}
          />
          <BorderedActionItem
            icon={WalletGroup}
            title="Sub-funds"
            description="Create sub-funds by grouping sub-account/exchange accounts"
            action={({ width, color }): ReactElement => (
              <GLink to="/app/management/sub-funds">
                <GButton width={width} color={color}>
                  Open
                </GButton>
              </GLink>
            )}
          />
          <BorderedActionItem
            icon={Paragraph}
            title="Positions"
            description="Edit portfolio positions for a specific date."
            action={({ width, color }): ReactElement => (
              <GLink to="/app/management/positions">
                <GButton width={width} color={color}>
                  Open
                </GButton>
              </GLink>
            )}
          />
        </Stack>
      </SectionPanel>
      <SectionPanel title="Market">
        <Stack spacing={1.5}>
          <BorderedActionItem
            icon={Group}
            title="Groups"
            description="View and edit your asset groups."
            action={({ width, color }): ReactElement => (
              <GLink to="/app/management/asset-groups">
                <GButton width={width} color={color}>
                  Open
                </GButton>
              </GLink>
            )}
          />
        </Stack>
      </SectionPanel>
      <SectionPanel title="Notifications">
        <BorderedActionItem
          icon={NotificationEmpty}
          title="Notifications"
          description="Subscribe and unsubscribe from different types of notifications"
          action={({ width, color }): ReactElement => (
            <GLink to="/app/management/notification">
              <GButton width={width} color={color}>
                Open
              </GButton>
            </GLink>
          )}
        />
      </SectionPanel>
      <SectionPanel title="Settings">
        <BorderedActionItem
          icon={Key}
          title="Account settings"
          description="Configure credentials, multi factor authentication and privacy"
          action={({ width, color }): ReactElement => (
            <GLink to="/app/management/settings">
              <GButton width={width} color={color}>
                Open
              </GButton>
            </GLink>
          )}
        />
        <BorderedActionItem
          icon={DateRange}
          title="Default tiles time frame"
          description="Select the default time frame for the tiles in portfolio overview"
          action={(): ReactElement => {
            return <TilesDateRangeSelector />;
          }}
        />
        <BorderedActionItem
          icon={ColorLensOutlined}
          title="Viewing mode"
          description="Configure dark and light mode"
          action={({ color }): ReactElement => {
            // doesn't allow for width configuration
            return <ColorSchemeChanger color={color} variant="button-group" />;
          }}
        />
      </SectionPanel>
    </>
  );
};

export default ManagementDashboard;
