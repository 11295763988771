import { Card } from '@mui/joy';
import type { FunctionComponent } from 'react';
import {
  cashFormatter,
  type HighchartSeries,
  tooltipFormat,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import HighChartsContainer from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper';

import { calculateOpenInterestChartData, type OpenInterestDataType } from './OpenInterestService.ts';
import type * as Highcharts from 'highcharts';

export type OpenInterestChartProps = {
  filename: string;
  data: OpenInterestDataType;
};

const calculateOptions = (filename: string, sortedAvailableDates: string[]): Highcharts.Options => {
  return {
    xAxis: {
      categories: sortedAvailableDates,
      title: {
        text: 'Expiration date',
      },
    },
    exporting: {
      filename,
    },
    ...tooltipFormat,
    yAxis: {
      labels: {
        formatter: cashFormatter,
      },
      title: {
        text: 'Open interest',
      },
    },
  };
};

const OpenInterestExpiryChart: FunctionComponent<OpenInterestChartProps & { sortedAvailableDates: string[] }> = ({
  filename,
  data,
  sortedAvailableDates,
}) => {
  return (
    <Card>
      <HighChartsContainer<OpenInterestDataType>
        loading={false}
        data={data}
        calculateChartData={(data): HighchartSeries[] => calculateOpenInterestChartData(data, sortedAvailableDates)}
        calculateOptions={(): Highcharts.Options => calculateOptions(filename, sortedAvailableDates)}
      />
    </Card>
  );
};

export default OpenInterestExpiryChart;
