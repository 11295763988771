import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { Typography as JoyTypography } from '@mui/joy';
import type { ReactNode } from 'react';

import { ISentiment } from '../../generated/graphql';

export const getIconBySentiment = (sentiment: ISentiment): ReactNode => {
  const iconProps = {
    fontSize: 'small',
  } as const;

  if (sentiment === ISentiment.Negative) {
    return (
      <>
        <JoyTypography color="danger">Negative</JoyTypography>
        <SentimentVeryDissatisfiedIcon color="error" {...iconProps} />
      </>
    );
  }

  if (sentiment === ISentiment.Positive) {
    return (
      <>
        <JoyTypography color="success">Positive</JoyTypography>
        <SentimentSatisfiedAltIcon color="success" {...iconProps} />
      </>
    );
  }

  return (
    <>
      <JoyTypography color="neutral">Neutral</JoyTypography>
      <SentimentNeutralIcon {...iconProps} />
    </>
  );
};
