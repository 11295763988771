import type { FunctionComponent } from 'react';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import { useStrategyRiskMetricsQuery } from 'generated/graphql';

import { PortfolioOptimizerRiskMetrics } from '../PortfolioOptimizerRiskMetrics.tsx';

const StrategyRiskMetricsContainer: FunctionComponent<{ strategy: Label }> = ({ strategy }) => {
  const { loaded, Fallback, data } = useDefaultErrorHandling(
    useStrategyRiskMetricsQuery({ variables: { label: strategy } })
  );

  if (!loaded) {
    return <Fallback />;
  }

  return (
    <PortfolioOptimizerRiskMetrics
      result={data.strategy.strategyRiskMetrics.map((metric) => ({ ...metric, params: {} }))}
    />
  );
};

export default StrategyRiskMetricsContainer;
