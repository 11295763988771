import { Stack, Typography as JoyTypography } from '@mui/joy';
import type { ReactElement } from 'react';

import { SelectableIPAddresses } from './SelectableIPAddresses.tsx';

export const DefaultIPWhitelistingSection = (): ReactElement => {
  return (
    <Stack spacing={1.5}>
      <JoyTypography color="neutral" level="body-md">
        IP whitelisting is required to receive all information from the exchange. Please whitelist the following IP
        addresses:
      </JoyTypography>
      <SelectableIPAddresses />
    </Stack>
  );
};
