import { Modal, ModalDialog, Stack } from '@mui/joy';
import type { FunctionComponent, ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import GFormProvider from 'components/technical/form/GFormProvider';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton';
import { type GraphQlErrorHandler, useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import { FormInput } from 'components/technical/inputs';

type FormState = {
  groupName: string;
};

type CreateRenameAssetGroupDialogProps = {
  onClose: () => void;
  handleFormSubmit: (data: FormState, graphQlErrorHandler: GraphQlErrorHandler) => Promise<void>;
  initialState?: FormState;
  submitButtonText: string;
  submitButtonDecorator?: ReactElement;
  title: string;
  existingGroups: Set<string>;
};

const newGroupInitialState: FormState = {
  groupName: '',
};

const CreateRenameAssetGroupDialog: FunctionComponent<CreateRenameAssetGroupDialogProps> = (props) => {
  const formSchema = yup.object({
    groupName: yup
      .string()
      .required()
      .test('unique', 'Group already exists', (value) => !props.existingGroups.has(value)),
  });

  const methods = useForm<FormState>({
    resolver: gYupResolver(formSchema),
    defaultValues: props.initialState ?? newGroupInitialState,
  });
  const { onErrorAndThrow } = useGraphQLApiError(methods);

  return (
    <Modal open onClose={props.onClose}>
      <ModalDialog>
        <GFormProvider {...methods}>
          <form onSubmit={methods.handleSubmit((data) => props.handleFormSubmit(data, onErrorAndThrow))}>
            <GDialogHeader>{props.title}</GDialogHeader>
            <Stack spacing={3} alignItems="center">
              <Stack spacing={1.5}>
                <FormInput type="input" label="Group name" name="groupName" autoComplete="off" width="xl2" />
              </Stack>
              <Stack alignItems="center" spacing={1.5}>
                <GraphQLApiFormErrorMessage />
                <SubmitButton width="xl2" startDecorator={props.submitButtonDecorator}>
                  {props.submitButtonText}
                </SubmitButton>
              </Stack>
            </Stack>
          </form>
        </GFormProvider>
      </ModalDialog>
    </Modal>
  );
};

export default CreateRenameAssetGroupDialog;
