import type { ReactElement } from 'react';

import InputLabel from 'components/technical/inputs/InputLabel';
import type { AssetWeight } from './PortfolioBuilder.utils.ts';
import PortfolioBuilderAssetRow from './PortfolioBuilderAssetRow';
import type { PublicAsset, PrivateAsset } from '../../market/asset/Asset.types';
import { defaultRowSpacing } from 'components/StackSpacing.ts';
import { Box } from '@mui/joy';
import { widths } from '../../width.styles.ts';

type PortfolioBuilderAssetListProps = {
  onRemove: (index: number) => void;
  assetWeights: (AssetWeight & Record<'id', string>)[];
  supportedAssets: Array<PublicAsset | PrivateAsset>;
  showDollarValues: boolean;
  portfolioEquityOrDefault: number;
};

const PortfolioBuilderAssetList = ({
  onRemove,
  assetWeights,
  supportedAssets,
  showDollarValues,
  portfolioEquityOrDefault,
}: PortfolioBuilderAssetListProps): ReactElement => {
  return (
    <Box
      width="100%"
      columnGap={defaultRowSpacing}
      rowGap={0.5}
      alignItems="center"
      display="grid"
      gridTemplateColumns={[
        `minmax(150px, ${widths.xl3})`,
        `minmax(100px, ${widths.normal})`,
        `minmax(150px, ${widths.xl4})`,
        showDollarValues ? `minmax(150px, ${widths.xl2})` : undefined,
        'min-content',
      ]
        .filter(Boolean)
        .join(' ')}
    >
      <InputLabel label="Asset" />
      <InputLabel label="Max leverage" />
      <InputLabel label="Weight" />
      {showDollarValues && <InputLabel label="Value" />}

      {/*  delete button placeholder */}
      <span />

      {assetWeights.map((asset, index: number) => (
        <PortfolioBuilderAssetRow
          supportedAssets={supportedAssets}
          showDollarValues={showDollarValues}
          key={asset.id}
          index={index}
          onRemove={(): void => onRemove(index)}
          portfolioEquityOrDefault={portfolioEquityOrDefault}
        />
      ))}
    </Box>
  );
};

export default PortfolioBuilderAssetList;
