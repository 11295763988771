import dayjs from 'dayjs';
import type Highcharts from 'highcharts';
import { type BigNumber, bignumber, isBigNumber } from 'mathjs';
import { type ReactElement, useMemo } from 'react';
import { formatPercentage } from 'components/formatter.utils.ts';
import {
  dateTimeExportFormat,
  DEFAULT_DATE_FORMAT,
  type HighchartSeries,
  percentageFormatter,
  tooltipFormat,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils.ts';
import HighChartsWrapper from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper.tsx';
import { calculateCummulativeReturns } from '../../returnsMath.ts';
import GAgGrid from '../../../../technical/grids/GAgGrid.tsx';
import { yearMonthDayColumns } from '../../../../technical/grids/SharedReportColumns.tsx';
import type { IAggFuncParams, ValueGetterParams } from 'ag-grid-community';
import { capitalize, isNil } from 'lodash/fp';
import { defaultRowSpacing } from '../../../../StackSpacing.ts';
import { Stack } from '@mui/joy';

interface PortfolioReturnsSectionProps {
  analysis: {
    portfolioDefinition: {
      id: string;
      name: string;
    };
    dailyValues: {
      date: UtcDate;
      portfolioWeightedReturn: string;
    }[];
  }[];
}

const calculateChartData = (data: PortfolioReturnsSectionProps['analysis']): HighchartSeries[] => {
  return data.map(({ dailyValues, portfolioDefinition }) => {
    const dates = dailyValues.map((row) => dayjs.utc(row.date.toString()).valueOf());
    const values = calculateCummulativeReturns(dailyValues.map((val) => bignumber(val.portfolioWeightedReturn)));
    return {
      name: portfolioDefinition.name,
      data: dates.map((date, i) => ({
        x: date,
        y: values[i].toNumber(),
        textValue: formatPercentage(values[i]),
      })),
      type: 'line' as const,
    };
  });
};

const calculateOptions = (): Highcharts.Options => {
  return {
    ...dateTimeExportFormat('backtest-returns'),
    ...tooltipFormat,
    xAxis: {
      type: 'datetime' as const,
      labels: {
        format: DEFAULT_DATE_FORMAT,
      },
    },
    yAxis: {
      labels: {
        formatter: percentageFormatter,
      },
      title: {
        text: undefined,
      },
    },
  };
};

interface RowData {
  date: UtcDate;
  returns: Map<string, BigNumber>;
}

const compoundReturnsAgg = ({ values }: IAggFuncParams<unknown, number | BigNumber>): number | null => {
  if (values.length === 0) {
    return null;
  }

  const hasNullIllegalValues = values.some((val) => {
    if (isNil(val)) {
      return true;
    }

    if (typeof val !== 'number' && !isBigNumber(val)) {
      return true;
    }

    return false;
  });

  if (hasNullIllegalValues) {
    return null;
  }

  const safeNumbers = values as BigNumber[] | number[];
  return calculateCummulativeReturns(safeNumbers).at(-1)!.toNumber();
};

const PortfolioReturnsSection = ({ analysis }: PortfolioReturnsSectionProps): ReactElement => {
  const customAgg = useMemo(
    () => ({
      compounded: compoundReturnsAgg,
    }),
    []
  );

  const idToPortfolio = new Map<string, string>(
    analysis
      .filter(({ dailyValues }) => dailyValues.length > 0) // keep track of only portfolios which have returns
      .map(({ portfolioDefinition }) => [portfolioDefinition.id, portfolioDefinition.name])
  );

  const allPortfolioDailyReturns = useMemo(() => {
    const allPortfolioDailyReturns = new Map<UtcDate, Map<string, BigNumber>>();
    for (const { dailyValues, portfolioDefinition } of analysis) {
      for (const { date, portfolioWeightedReturn } of dailyValues) {
        let dailyReturns = allPortfolioDailyReturns.get(date);
        if (isNil(dailyReturns)) {
          dailyReturns = new Map();
          allPortfolioDailyReturns.set(date, dailyReturns);
        }

        dailyReturns.set(portfolioDefinition.id, bignumber(portfolioWeightedReturn));
      }
    }

    return allPortfolioDailyReturns;
  }, [analysis]);

  return (
    <Stack gap={defaultRowSpacing}>
      <HighChartsWrapper<PortfolioReturnsSectionProps['analysis']>
        loading={false}
        data={analysis}
        calculateChartData={calculateChartData}
        calculateOptions={calculateOptions}
      />
      <GAgGrid<RowData>
        height="800px"
        // @ts-expect-error it's not possible to correctly type the function
        aggFuncs={customAgg}
        rowData={Array.from(allPortfolioDailyReturns.entries()).map(([date, returns]) => ({
          date,
          returns,
        }))}
        columnDefs={[
          yearMonthDayColumns<RowData>('date', true),
          {
            colId: 'dailyReturns',
            headerName: 'Returns',
            children: Array.from(idToPortfolio.entries()).map(([id, name]) => ({
              colId: `${id}-returns`,
              headerName: capitalize(name),
              chartDataType: 'series',
              allowedAggFuncs: ['compounded'],
              type: ['numericColumn', 'percentageColumn'],
              valueGetter: (params: ValueGetterParams<RowData>): number | undefined => {
                if (!params.data) {
                  return undefined;
                }

                const returns = params.data.returns.get(id);
                if (!returns) {
                  return undefined;
                }

                return bignumber(returns).toNumber();
              },
            })),
          },
        ]}
        defaultColDef={{
          resizable: true,
          sortable: true,
          filter: true,
        }}
        enableCharts
        enableRangeSelection
        autoSizeStrategy={{ type: 'fitGridWidth' }}
        sideBar={{
          toolPanels: ['columns', 'filters'],
        }}
      />
    </Stack>
  );
};

export default PortfolioReturnsSection;
