import { Link as JoyLink, type LinkProps as JoyLinkProps } from '@mui/joy';
import { type ForwardedRef, forwardRef, type ReactElement } from 'react';
import { Link as RouterLink, type LinkProps } from 'react-router-dom';

const GLink = forwardRef<HTMLAnchorElement, LinkProps & JoyLinkProps>(
  (props: LinkProps & JoyLinkProps, ref: ForwardedRef<HTMLAnchorElement>): ReactElement => {
    return (
      <JoyLink {...props} component={RouterLink} ref={ref}>
        {props.children}
      </JoyLink>
    );
  }
);

GLink.displayName = 'GLink';

export default GLink;
