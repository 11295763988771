import { Typography } from '@mui/joy';
import ModalClose from '@mui/joy/ModalClose';
import type { ReactElement, ReactNode } from 'react';

const GDrawerHeader = ({ children }: { children: ReactNode }): ReactElement => (
  <>
    <ModalClose />
    <Typography level={'h3'} pb={2} pt={1.5}>
      {children}
    </Typography>
  </>
);

export default GDrawerHeader;
