import { IconCheck, IconX, IconAlertTriangle, IconClockHour1Filled } from '@tabler/icons-react';
import type { FunctionComponent } from 'react';
import { IPortfolioOptimizationResultStatusType } from '../../../generated/graphql';
import StatusChip from 'components/technical/StatusChip';

const OptimizationStatusChip: FunctionComponent<{
  resultCode: IPortfolioOptimizationResultStatusType;
}> = ({ resultCode }) => {
  const size = 'md';
  switch (resultCode) {
    case IPortfolioOptimizationResultStatusType.Completed:
      return (
        <StatusChip color="success" icon={IconCheck} size={size}>
          Completed
        </StatusChip>
      );
    case IPortfolioOptimizationResultStatusType.Failed:
      return (
        <StatusChip color="danger" icon={IconX} size={size}>
          Failed
        </StatusChip>
      );
    case IPortfolioOptimizationResultStatusType.Canceled:
      return (
        <StatusChip color="warning" icon={IconAlertTriangle} size={size}>
          Canceled
        </StatusChip>
      );
    case IPortfolioOptimizationResultStatusType.Pending:
      return (
        <StatusChip color="neutral" icon={IconClockHour1Filled} size={size}>
          Pending
        </StatusChip>
      );
    default:
      return (
        <StatusChip color="neutral" icon={IconClockHour1Filled} size={size}>
          In progress
        </StatusChip>
      );
  }
};

export default OptimizationStatusChip;
