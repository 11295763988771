import type { ReactElement } from 'react';

import InputLabel from 'components/technical/inputs/InputLabel.tsx';
import ShockRow from './ShockRow.tsx';
import { Box, Stack, Typography } from '@mui/joy';

const ShockList = (): ReactElement => {
  return (
    <Stack>
      <Typography level="title-md">Scenario definition</Typography>
      <Box
        sx={{
          alignItems: 'center',
          display: 'grid',
          gridAutoRows: 'min-content',
          gridTemplateColumns: 'max-content 1fr',
          gap: '0.75rem',
          rowGap: '1rem',
        }}
      >
        <span />
        <InputLabel label="Shock values" />
        <ShockRow name="shocks.volatilityShock" label="Volatility shock" />
        <ShockRow name="shocks.priceShock" label="Price shock" />
        <ShockRow name="shocks.interestFreeRateShock" label="Interest free rate shock" />
      </Box>
    </Stack>
  );
};

export default ShockList;
