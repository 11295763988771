import type { ReactElement } from 'react';
import {
  Controller,
  type FieldError,
  type FieldPathValue,
  type FieldValues,
  type Path,
  type UseFormClearErrors,
  useFormContext,
  type UseFormTrigger,
} from 'react-hook-form';
import type * as yup from 'yup';
import StaticMultiAutocomplete from '../inputs/Autocomplete/StaticMultiAutocomplete';
import type { StaticMultiAutocompleteProps } from '../inputs/Autocomplete/StaticMultiAutocomplete.props';
import isNil from 'lodash/fp/isNil';
import type { DistributiveOmit } from '../../type.utils.ts';
// biome-ignore lint/suspicious/noExplicitAny:
export type FormStaticMultiAutocompleteProps<T extends FieldValues, N extends Path<T> = any> = DistributiveOmit<
  StaticMultiAutocompleteProps<FieldPathValue<T, N>>,
  'value' | 'error' | 'onChange'
> & {
  name: N;
  onChange?: (
    option: T[],
    methods: {
      trigger: UseFormTrigger<T>;
      clearErrors: UseFormClearErrors<T>;
    }
  ) => void;
};

const aggregateErrors = (err: FieldError | undefined | null | FieldError[]): string => {
  if (isNil(err)) {
    return '';
  }

  if (!Array.isArray(err)) {
    return err.message ?? '';
  }

  return (err as yup.ValidationError[]).map((e) => e.message).join('. ');
};

// biome-ignore lint/suspicious/noExplicitAny:
const FormStaticMultiAutocomplete = <T extends FieldValues, N extends Path<T> = any>(
  props: FormStaticMultiAutocompleteProps<T, N>
): ReactElement => {
  /* jscpd:ignore-start */
  const { control, trigger, clearErrors } = useFormContext();

  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field: { value, onChange, onBlur, ref }, fieldState, formState }): ReactElement => {
        return (
          <StaticMultiAutocomplete
            {...props}
            ref={ref}
            value={value}
            error={aggregateErrors(fieldState.error)}
            onChange={(val) => {
              props.onChange?.(val, {
                trigger: trigger as UseFormTrigger<T>,
                clearErrors: clearErrors as UseFormClearErrors<T>,
              });
              onChange(val);
            }}
            onBlur={onBlur}
            disabled={(props.disabled ?? false) || formState.isSubmitting || formState.disabled}
          />
        );
      }}
    />
  );
};

export default FormStaticMultiAutocomplete;
