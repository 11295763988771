import dayjs, { type Dayjs } from 'dayjs';
import isNil from 'lodash/fp/isNil';
import { type ReactElement, useMemo, useState } from 'react';

import type { AvailableContributionMetrics } from './AvailableContributionMetrics.ts';
import type ColorIndexService from './ColorIndexService.ts';
import RiskMetricsContribution from './RiskMetricsContribution.tsx';
import type { MetricInput } from './RiskMetricsChart.types.ts';
import { RiskMetricsChartInner } from './RiskMetricsChartInner.tsx';
import { RiskMetricsChartOptions } from './RiskMetricsChartOptions.tsx';
import { hasValidationErrors, type ParameterDescription } from './RiskMetricsParameterService.tsx';
import {
  type IPortfolioMetricsQuery,
  type IPortfolioRiskMetricsInput,
  type IRiskDashboardInputQuery,
  usePortfolioMetricsQuery,
} from '../../../generated/graphql.tsx';
import { convertDateInUtcToUTCISODate } from '../../date.utils.ts';
import { range7Days, rangeAll, rangeLastYear, rangeOneMonth, rangeThreeMonths } from '../../predefinedDateRanges.ts';
import PredefinedDateRangeButtonsWithCalendar from '../../technical/inputs/date/PredefinedDateRangeButtonsWithCalendar.tsx';
import ChartWithTitle from '../../ChartWithTitle.tsx';
import { calculateDefaultParameters, createMetricRequestParameters } from '../../portfolio/riskMetrics.utils.ts';
import { TwoThirdsLayout } from '../../TwoThirdsLayout.tsx';
import { convertDateRangeToSinceToDate } from 'components/technical/inputs/date/dateRange.utils.ts';
import { Tooltip } from '@mui/joy';
import { tooltipEnterDelay } from 'theme/consts.ts';
import { uniq } from 'lodash/fp';
import { WarmUpInput } from './WarmUpInput.tsx';

const calculateAvailableContributionMetrics = (
  data?: IPortfolioMetricsQuery,
  date?: UtcDate
): AvailableContributionMetrics | null => {
  if (!data) {
    return null;
  }

  const contributionMetrics = data.portfolio.metrics
    .map((met) => {
      const seriesWithContrib = met.series.filter((series) => series.hasContributions);
      return {
        label: met.label,
        series: seriesWithContrib,
      };
    })
    .filter((met) => met.series.length > 0);

  const metrics = Object.fromEntries(
    contributionMetrics.map((met): [string, AvailableContributionMetrics['metrics'][string]] => [
      met.label,
      met.series.map((series) => series.parameters),
    ])
  );

  const dayJsDate = dayjs.max(
    contributionMetrics
      .flatMap((met) => met.series.flatMap((ser) => ser.values.flatMap((val) => val.date)))
      .map((date) => dayjs.utc(date.toString()))
  );

  return isNil(dayJsDate)
    ? null
    : {
        metrics,
        date: date ? date : convertDateInUtcToUTCISODate(dayJsDate),
        portfolioDefinition: uniq(
          data.portfolio.metrics
            .map((met) => met.portfolioDefinition)
            .filter((val): val is { name: string; id: string } => !isNil(val))
        ),
      };
};

const warmUpPeriodDefault = 10;
const warmUpPeriodEnableThreshold = 60;

export type MetricsData = {
  label: Label;
  portfolioDefinition?:
    | {
        name: string;
      }
    | null
    | undefined;
  series: IPortfolioMetricsQuery['portfolio']['metrics'][number]['series'];
}[];

function metricHasExpandingWindow(metricSeries: MetricsData[number]['series'][number]): boolean {
  return metricSeries.parameters.find((p) => p.name === 'Window type')?.strValue === 'expanding';
}

function isWarmUpPeriodEnabled(metricsData: MetricsData | undefined): boolean {
  const metricSeries = metricsData?.flatMap((met) => met.series) ?? [];
  // only expanding metrics have extreme values in the beginning
  const metricSeriesWithExpandingWindow = metricSeries.find(metricHasExpandingWindow);

  return (
    !isNil(metricSeriesWithExpandingWindow) &&
    metricSeriesWithExpandingWindow.values.length > warmUpPeriodEnableThreshold
  );
}

function filterByWarmUpPeriod(
  warmUpPeriod: string,
  warmUpPeriodEnabled: boolean,
  metricsData: MetricsData | undefined
): MetricsData | undefined {
  const parsedWarmUpPeriod = Number.parseFloat(warmUpPeriod);
  const verifiedWarmUpPeriod = warmUpPeriodEnabled && Number.isSafeInteger(parsedWarmUpPeriod) ? parsedWarmUpPeriod : 0;

  return metricsData
    ? metricsData.map((met) => ({
        ...met,
        series: met.series.map((ser) => {
          if (metricHasExpandingWindow(ser)) {
            return {
              ...ser,
              values: ser.values.slice(verifiedWarmUpPeriod),
            };
          }
          return ser;
        }),
      }))
    : undefined;
}

const RiskMetricsChart = (props: {
  groups: IRiskDashboardInputQuery['assets']['assetGroups'];
  metricParameters: Record<Label, ParameterDescription[]>;
  withContribution?: boolean;
  metrics: string[];
  title: string;
  categoryService: ColorIndexService;
  filters: Pick<IPortfolioRiskMetricsInput, 'portfolioDefinitionIds' | 'subAccountAssetFilter' | 'since' | 'to'>;
  showDatePicker: boolean;
  showPortfolio: boolean;
}): ReactElement => {
  const [date, setDate] = useState<UtcDate | undefined>(undefined);
  const defaultDateRange = rangeAll;
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(defaultDateRange.value);
  const [metricInput, setMetricInput] = useState<MetricInput>({
    metric: props.metrics[0],
    params: calculateDefaultParameters(props.metricParameters[props.metrics[0]] ?? []),
  });
  const [warmUpPeriod, setWarmUpPeriod] = useState<string>(String(warmUpPeriodDefault));
  const isValid = !hasValidationErrors(metricInput.params, props.metricParameters[metricInput.metric] ?? []);

  const queryOutput = usePortfolioMetricsQuery({
    variables: {
      input: {
        ...convertDateRangeToSinceToDate(dateRange),
        metrics: [
          {
            label: metricInput.metric,
            parameters:
              !isValid || isNil(props.metricParameters[metricInput.metric])
                ? []
                : createMetricRequestParameters(metricInput.params, props.metricParameters[metricInput.metric]!),
          },
        ],
        ...props.filters,
      },
    },
    skip: !isValid || isNil(props.metricParameters[metricInput.metric]),
  });

  const availableContributionMetrics = useMemo(
    () => calculateAvailableContributionMetrics(queryOutput.data, date),
    [queryOutput.data, date]
  );

  const warmUpPeriodEnabled = isWarmUpPeriodEnabled(queryOutput.data?.portfolio.metrics);
  const metricsData = filterByWarmUpPeriod(warmUpPeriod, warmUpPeriodEnabled, queryOutput.data?.portfolio.metrics);

  const renderContribution = props.withContribution && availableContributionMetrics;
  const mainGraph = (
    <RiskMetricsChartInner
      {...props}
      error={queryOutput.error}
      metricInput={metricInput}
      loading={queryOutput.loading}
      metricsData={metricsData}
      onDateSelected={setDate}
    />
  );
  const chart = renderContribution ? (
    <TwoThirdsLayout
      left={mainGraph}
      right={
        <RiskMetricsContribution
          availableContributionMetrics={availableContributionMetrics}
          groups={props.groups.genieGroups}
          filters={props.filters}
          parameterDescription={new Map(Object.entries(props.metricParameters))}
        />
      }
    />
  ) : (
    mainGraph
  );

  return (
    <ChartWithTitle title={props.title} paper>
      <RiskMetricsChartOptions
        metricParameters={props.metricParameters}
        metricInput={metricInput}
        setMetricInput={setMetricInput}
        metrics={props.metrics}
        extraParams={
          <>
            <Tooltip
              enterDelay={tooltipEnterDelay.Slow}
              title="Excludes the first N days from risk analysis to remove expanding metric extreme values"
            >
              <WarmUpInput
                disabled={!warmUpPeriodEnabled}
                onChange={(val) => setWarmUpPeriod(val)}
                value={warmUpPeriod}
              />
            </Tooltip>
            {props.showDatePicker && (
              <PredefinedDateRangeButtonsWithCalendar
                defaultValue={defaultDateRange}
                onChange={(val): void => setDateRange(val)}
                // defaults without 1 day, metrics data doesn't make sense for 1 day
                calendarDateRanges={[range7Days, rangeOneMonth, rangeThreeMonths, rangeLastYear, rangeAll]}
                alignItems="end"
              />
            )}
          </>
        }
      />
      {chart}
    </ChartWithTitle>
  );
};

export default RiskMetricsChart;
