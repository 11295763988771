import { Modal, ModalDialog } from '@mui/joy';
import { type ReactElement, Suspense } from 'react';
import { MultifactorDocument, useSaveNewMultifactorMutation } from 'generated/graphql';
import { useForm } from 'react-hook-form';
import gYupResolver from 'components/technical/form/gYupResolver';
import GFormProvider from 'components/technical/form/GFormProvider';
import { type GraphQlErrorHandler, useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import {
  CREATE_OR_UPDATE_MULTIFACTOR_FORM_SCHEMA,
  type CreateOrUpdateMultifactorFormInputFields,
  type CreateOrUpdateMultifactorFormOutputFields,
  DEFAULT_FACTOR_WEIGHT,
} from './AssetScreenerService';
import CreateAndUpdateMultifactorDialogBody from './CreateAndUpdateMultifactorDialogBody';
import Loader from 'components/technical/Loader/Loader';

type CreateMultifactorDialogProps = {
  onClose: () => void;
};

const CreateMultifactorDialog = ({ onClose }: CreateMultifactorDialogProps): ReactElement => {
  const methods = useForm<CreateOrUpdateMultifactorFormInputFields>({
    resolver: gYupResolver(CREATE_OR_UPDATE_MULTIFACTOR_FORM_SCHEMA),
    mode: 'onBlur',
    defaultValues: {
      description: '',
      factorWeights: [{ ...DEFAULT_FACTOR_WEIGHT }, { ...DEFAULT_FACTOR_WEIGHT }],
    },
  });

  const [saveNewMultifactorMutation] = useSaveNewMultifactorMutation();
  const { showSuccessMessage } = useFeedback();
  const { onErrorAndThrow } = useGraphQLApiError(methods);

  const handleFormSubmit = async (
    data: CreateOrUpdateMultifactorFormOutputFields,
    onErrorAndThrow: GraphQlErrorHandler
  ): Promise<void> => {
    const formattedData = {
      ...data,
      factorWeights: data.factorWeights.map((factorWeight) => ({
        ...factorWeight,
        weight: (factorWeight.weight && factorWeight.weight / 100).toPrecision(8),
      })),
    };
    try {
      await saveNewMultifactorMutation({
        variables: { input: formattedData },
        refetchQueries: [MultifactorDocument],
      });

      showSuccessMessage('Multifactor created successfully');
      onClose();
    } catch (e) {
      onErrorAndThrow(e);
    }
  };

  return (
    <Modal open={true} onClose={onClose}>
      <ModalDialog minWidth="55rem" size={'lg'}>
        <GFormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit((data) =>
              handleFormSubmit(data as unknown as CreateOrUpdateMultifactorFormOutputFields, onErrorAndThrow)
            )}
          >
            <Suspense fallback={<Loader />}>
              <CreateAndUpdateMultifactorDialogBody />
            </Suspense>
          </form>
        </GFormProvider>
      </ModalDialog>
    </Modal>
  );
};

export default CreateMultifactorDialog;
