import type { ReactElement } from 'react';
import { Controller, type FieldValues, type Path, useFormContext } from 'react-hook-form';

import type { BaseDateInputProps } from '../inputs/date/BaseDateInputProps';
import DateTimeInput from '../inputs/date/DateTimeInput';
import type { Dayjs } from 'dayjs';
import type { DistributiveOmit } from '../../type.utils.ts';

export type BaseFormDateInputProps = DistributiveOmit<BaseDateInputProps, 'error' | 'type'> & {
  onChange?: (value: Dayjs | null) => void;
  showTime: boolean;
};

export const BaseFormDateInput = <T extends FieldValues>(
  props: BaseFormDateInputProps & {
    name: Path<T>;
  }
): ReactElement => {
  const { control } = useFormContext<T>();
  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field: { value, onChange, onBlur, ref }, fieldState, formState }): ReactElement => (
        <DateTimeInput
          {...props}
          ref={ref}
          value={value}
          error={fieldState.error?.message}
          onChange={(value) => {
            onChange(value);
            props.onChange?.(value);
          }}
          onBlur={() => {
            onBlur();
            props.onBlur?.();
          }}
          disabled={(props.disabled ?? false) || formState.isSubmitting || formState.disabled}
          showTime={props.showTime}
        />
      )}
    />
  );
};
