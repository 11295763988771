// default presets can be generated via "See all" -> "Copy current report state as JSON"

export const positionsSummaryDefaultPresets = [
  {
    name: 'Default',
    isDefault: true,
    settings: {
      filters: {},
      pivotMode: false,
      columns: [
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'ag-Grid-AutoColumn',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'symbol',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'underlyingAsset',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: true,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: 0,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'cluster-sector',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'cluster-category',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'cluster-cluster_price_action_optics',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'cluster-hey',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'subAccount.name',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'type',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'side',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'balance',
          pivot: false,
          pinned: null,
          aggFunc: 'sum',
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'exposure-sideAware-true',
          pivot: false,
          pinned: null,
          aggFunc: 'sum',
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'amount-sideAware-true',
          pivot: false,
          pinned: null,
          aggFunc: 'sum',
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'unrealizedPnl',
          pivot: false,
          pinned: null,
          aggFunc: 'sum',
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'price',
          pivot: false,
          pinned: null,
          aggFunc: 'avg',
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'balanceContribution',
          pivot: false,
          pinned: null,
          aggFunc: 'sum',
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'exposure-sideAware-false',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: true,
          sort: null,
          colId: 'amount-sideAware-false',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'price-change-24h',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'past-price-latest',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'price-change-MTD',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'past-price-MTD',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'price-change-QTD',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'past-price-QTD',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'price-change-YTD',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'past-price-YTD',
          pivot: false,
          pinned: null,
          aggFunc: null,
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
      ],
    },
  },
];

export const positionsSpotsDefaultPresets = [
  {
    name: 'Default',
    isDefault: true,
    settings: {
      filters: {},
      pivotMode: false,
      columns: [
        {
          colId: 'ag-Grid-AutoColumn',
          width: 220,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'symbol',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: true,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'underlyingAsset',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: true,
          rowGroupIndex: 0,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'cluster-sector',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'cluster-category',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'cluster-cluster_price_action_optics',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'cluster-hey',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'subAccount.name',
          width: 107,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'type',
          width: 100,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'side',
          width: 81,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'balance',
          width: 137,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: 'sum',
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'exposure-sideAware-true',
          width: 145,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: 'sum',
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'amount-sideAware-true',
          width: 136,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: 'sum',
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'unrealizedPnl',
          width: 145,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: 'sum',
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'price',
          width: 140,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: 'avg',
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'balanceContribution',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: 'sum',
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'exposure-sideAware-false',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'amount-sideAware-false',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'price-change-24h',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'past-price-latest',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'price-change-MTD',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'past-price-MTD',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'price-change-QTD',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'past-price-QTD',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'price-change-YTD',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'past-price-YTD',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
      ],
    },
  },
];

export const positionsDerivativesDefaultPresets = [
  {
    name: 'Default',
    isDefault: true,
    settings: {
      filters: {},
      pivotMode: false,
      columns: [
        {
          colId: 'ag-Grid-AutoColumn',
          width: 220,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'symbol',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'cluster-sector',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'cluster-category',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'cluster-cluster_price_action_optics',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'cluster-hey',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'subAccount.name',
          width: 107,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'side',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'balance',
          width: 137,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: 'sum',
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'exposure-sideAware-true',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: 'sum',
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'amount-sideAware-true',
          width: 136,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: 'sum',
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          flex: null,
          hide: false,
          sort: null,
          colId: 'unrealizedPnl',
          pivot: false,
          pinned: null,
          aggFunc: 'sum',
          rowGroup: false,
          sortIndex: null,
          pivotIndex: null,
          rowGroupIndex: null,
        },
        {
          colId: 'price',
          width: 142,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: 'avg',
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'balanceContribution',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: 'sum',
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'exposure-sideAware-false',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'amount-sideAware-false',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'price-change-24h',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'past-price-latest',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'price-change-MTD',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'past-price-MTD',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'price-change-QTD',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'past-price-QTD',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'price-change-YTD',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'past-price-YTD',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
      ],
    },
  },
];
