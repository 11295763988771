import { Card } from '@mui/joy';
import type { ReactElement, ReactNode } from 'react';

import ActionsContextProvider from 'components/technical/actions/ActionsContextProvider.tsx';
import ActionsHeaderBar from 'components/technical/actions/ActionsHeaderBar.tsx';
import SectionColumn from 'components/technical/layout/Column/SectionColumn.tsx';
import type { HeaderBarProps } from './technical/HeaderBar/HeaderBar.tsx';

const ChartWithTitle = ({
  title,
  children,
  paper,
  headerLevel,
  showCard,
}: {
  title: string | ReactNode;
  paper: boolean;
  actions?: ReactNode;
  children: ReactNode;
  headerLevel?: HeaderBarProps['level'];
  showCard?: boolean;
}): ReactElement => {
  return (
    <ActionsContextProvider>
      <SectionColumn
        sx={{
          minWidth: 0,
        }}
      >
        <ActionsHeaderBar title={title} level={headerLevel} showCard={showCard} />
        {paper ? (
          <Card
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {children}
          </Card>
        ) : (
          children
        )}
      </SectionColumn>
    </ActionsContextProvider>
  );
};

export default ChartWithTitle;
