import type { FunctionComponent } from 'react';
import { useParams } from 'react-router';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import { useExchangesQuery } from 'generated/graphql';

import OptionsTab from './Options/OptionsTab';

const PublicAssetOptionsTab: FunctionComponent = () => {
  const { assetId } = useParams();

  const { loaded, Fallback, data } = useDefaultErrorHandling(useExchangesQuery());

  if (!loaded) {
    return <Fallback />;
  }

  return <OptionsTab venues={data.assets.options.exchanges} assetId={assetId!} />;
};
export default PublicAssetOptionsTab;
