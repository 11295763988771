import type { ReactElement } from 'react';

import ChartSkeleton from './LineChartSkeleton.svg?react';
import { defaultHeight } from '../Chart.constants';

const LineChartSkeleton = (): ReactElement => {
  const color = 'var(--joy-palette-background-level3)';
  return (
    <ChartSkeleton
      style={{
        height: defaultHeight,
        width: '100%',
        fill: color,
        stroke: color,
      }}
    />
  );
};

export default LineChartSkeleton;
