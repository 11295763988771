import { Stack, Typography as JoyTypography, Typography } from '@mui/joy';
import isNil from 'lodash/fp/isNil';
import { bignumber } from 'mathjs';
import type { FunctionComponent } from 'react';
import GLinearProgress from 'components/technical/GLinearProgress/GLinearProgress';
import Help from 'components/technical/Help/Help';
import GValueChange from 'components/technical/ValueChange/GValueChange';
import { neutralPlainDisabledColor } from 'theme/colors';
import { formatCash, formatPercentage } from '../../../../../formatter.utils';
import BigTile from '../../BigTile/BigTile';
import VerticalLabelAndValue from '../../VerticalLabelAndValue';

const toNumber = (value: string | null | undefined): number => {
  return bignumber(value).toNumber();
};

const PublicAssetPrice: FunctionComponent<{
  price: string | null;
  priceChange: string | null;
  minPrice: string | null | undefined;
  maxPrice: string | null | undefined;
  asset: { name: string };
}> = ({ price, priceChange, minPrice, maxPrice, asset }) => {
  const progressValue = [price, minPrice, maxPrice].some((val) => isNil(val))
    ? null
    : ((toNumber(price) - toNumber(minPrice)) * 100) / (toNumber(maxPrice) - toNumber(minPrice));

  return (
    <BigTile
      topLeft={
        price && (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography level={'h3'}>{formatCash(price)}</Typography>
            {priceChange && (
              <>
                <GValueChange
                  value={Number.parseFloat(priceChange)}
                  text={`${formatPercentage(Number.parseFloat(priceChange))}`}
                />
                <Typography level="body-xs" textColor={neutralPlainDisabledColor}>
                  (24h change) <Help>Price change in the past 24h. Recalculated every minute.</Help>
                </Typography>
              </>
            )}
          </Stack>
        )
      }
      topRight={
        <JoyTypography color="neutral" level="body-sm">
          {asset.name} price
        </JoyTypography>
      }
      bottomLeft={minPrice && <VerticalLabelAndValue variant="left" label="24h low" value={formatCash(minPrice)} />}
      bottomRight={maxPrice && <VerticalLabelAndValue variant="right" label="24h high" value={formatCash(maxPrice)} />}
      center={<GLinearProgress value={progressValue} />}
    />
  );
};

export default PublicAssetPrice;
