import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { List, ListItemButton, ListItemContent, ListItemDecorator, Typography } from '@mui/joy';
import ListItem from '@mui/joy/ListItem';
import { type ReactElement, useState } from 'react';

import type { GroupMenuItem } from './Menu';
import SidebarSingleItem from './SidebarSingleItem';

export const SidebarGroupItem = ({
  item,
  activePath,
  isAdmin,
}: { item: GroupMenuItem; activePath: string; isAdmin: boolean }): ReactElement => {
  const [open, setOpen] = useState(true);

  return (
    <ListItem nested>
      <List>
        <ListItem>
          <ListItemButton color="primary" onClick={(): void => setOpen((open) => !open)}>
            <ListItemDecorator>{item.icon}</ListItemDecorator>
            <ListItemContent>
              <Typography level="body-md" textColor="inherit">
                {item.title}
              </Typography>
            </ListItemContent>
            <ListItemDecorator>
              <KeyboardArrowUpIcon sx={{ transform: open ? 'initial' : 'rotateX(180deg)' }} />
            </ListItemDecorator>
          </ListItemButton>
        </ListItem>
        {open &&
          item.items.map((child) => (
            <SidebarSingleItem
              key={`${child.title}-${child.adminOnly}`}
              item={child}
              activePath={activePath}
              level={1}
              isAdmin={isAdmin}
            />
          ))}
      </List>
    </ListItem>
  );
};
