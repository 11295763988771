import type { ReactElement, ReactNode } from 'react';

import { useActions } from 'components/technical/actions/UseActions.tsx';
import ChartWithTitle from './ChartWithTitle.tsx';

const ActionsChartWithTitle = ({
  title,
  paper,
  children,
  showCard,
}: {
  title: ReactNode;
  paper: boolean;
  children: ReactNode;
  showCard?: boolean;
}): ReactElement => {
  const actions = useActions();
  return (
    <ChartWithTitle title={title} paper={paper} actions={actions} showCard={showCard}>
      {children}
    </ChartWithTitle>
  );
};

export default ActionsChartWithTitle;
