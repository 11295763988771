import { bignumber } from 'mathjs';
import type { HighchartSeries } from 'components/technical/charts/HighChartsWrapper/Highchart.utils.ts';

import bigNumMath from '../../../../../../bigNumMath.ts';
import { type IOptionsQuery, IOptionType } from '../../../../../../generated/graphql.tsx';
import { formatCash } from '../../../../../formatter.utils.ts';

export type OpenInterestDataType = Record<string, IOptionsQuery['assets']['options']['pricedOptions']>;

export const calculateOpenInterestChartData = (
  data: OpenInterestDataType,
  sortedXValues: string[]
): HighchartSeries[] => {
  const chartData = [];

  for (const optionType in IOptionType) {
    const option = IOptionType[optionType as keyof typeof IOptionType];

    const datas = sortedXValues.map((date) => {
      const filtered = data[date].filter((row) => row.option.derivativeDetails?.optionType === option);
      return bigNumMath.sum(filtered.map(({ openInterest }) => bignumber(openInterest ?? 0).toNumber()));
    });

    chartData.push({
      name: option === IOptionType.Call ? 'Calls' : 'Puts',
      type: 'column' as const,
      data: datas.map((val) => ({ y: val, textValue: formatCash(val) })),
    });
  }

  return chartData;
};
