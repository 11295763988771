export class TupleKeyMap<K extends (number | string | UtcDate)[], V> {
  #internalMap: Map<string, V> = new Map();

  constructor(entries?: ReadonlyArray<readonly [K, V]>) {
    if (entries) {
      for (const [key, value] of entries) {
        this.set(key, value);
      }
    }
  }

  set(key: K, value: V): this {
    const stringifiedKey = JSON.stringify(key);
    this.#internalMap.set(stringifiedKey, value);
    return this;
  }

  get(key: K): V | undefined {
    const stringifiedKey = JSON.stringify(key);
    return this.#internalMap.get(stringifiedKey);
  }

  has(key: K): boolean {
    const stringifiedKey = JSON.stringify(key);
    return this.#internalMap.has(stringifiedKey);
  }

  delete(key: K): boolean {
    const stringifiedKey = JSON.stringify(key);
    return this.#internalMap.delete(stringifiedKey);
  }

  clear(): void {
    this.#internalMap.clear();
  }

  get size(): number {
    return this.#internalMap.size;
  }

  *values(): IterableIterator<V> {
    for (const value of this.#internalMap.values()) {
      yield value;
    }
  }

  *keys(): IterableIterator<K> {
    for (const key of this.#internalMap.keys()) {
      yield JSON.parse(key) as K;
    }
  }

  *entries(): IterableIterator<[K, V]> {
    for (const [key, value] of this.#internalMap.entries()) {
      yield [JSON.parse(key) as K, value];
    }
  }

  get [Symbol.toStringTag](): string {
    return this.toString();
  }

  toString(): string {
    const entries = Array.from(this.entries())
      .slice(0, 4)
      .map(([key, value]) => `[${key.join(', ')}] => ${JSON.stringify(value)}`);
    if (this.size > 5) {
      entries.push('...');
    }
    return `size=${this.size} { ${entries.join(', ')} }`;
  }
}
