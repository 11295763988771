import { Card, Grid, Stack } from '@mui/joy';
import isNil from 'lodash/fp/isNil';
import { bignumber, type BigNumber } from 'mathjs';
import { type ReactElement, useCallback, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import type { UseFormReturn } from 'react-hook-form';

import FormRadioGroup from 'components/technical/form/FormRadioGroup';
import GFormProvider from 'components/technical/form/GFormProvider';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton';
import { useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError.tsx';
import { Header3 } from 'components/technical/Header3';
import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import { FormInput } from 'components/technical/inputs';
import DialogButton from 'components/technical/inputs/GButton/DialogButton';
import GButton from 'components/technical/inputs/GButton/GButton';
import SectionColumn from 'components/technical/layout/Column/SectionColumn';
import { ScenarioDialog } from 'components/technical/ScenarioDialog/ScenarioDialog';
import StressTestResult from './result/StressTestSimulatorResult';
import StressTestInputList, { type ImportComponentProps } from './StressTestInputList';
import {
  type ImportScenarioDialogScenarioEvent,
  StressTestConstraintType,
  type StressTestInputFields,
  type StressTestOutputFields,
  type StressTestScenario,
} from './StressTestSimulator.types';
import { schema, stressTestOptions } from './StressTestSimulator.validation';
import bigNumMath from '../../../bigNumMath';
import { ILeverageType, IPositionValueType, usePerformStressTestLazyQuery } from '../../../generated/graphql';
import type { PublicAsset } from '../../market/asset/Asset.types';
import { useValueChanged } from '../../UseValueChanged';
import {
  assetMinPercentageContribution,
  defaultAvailableCapital,
  ImportPortfolioButton,
} from '../ImportPortfolioButton';
import { NormalizeAllocation } from '../NormalizeAllocation';
import type { NotVerifiedAsset } from '../../market/asset/AssetLabelService.ts';

export type ScenarioLibraryEvent = Omit<ImportScenarioDialogScenarioEvent, 'asset'> & {
  asset?: (NotVerifiedAsset & { id: string }) | null;
};

const calculateDefaultPortfolioAmount = (portfolioAssets: { id: string; value: BigNumber }[]): number => {
  const minCashValue = assetMinPercentageContribution * defaultAvailableCapital;

  const newAssets: { id: string; value: BigNumber }[] = portfolioAssets.filter((asset) => {
    return asset.value.abs().greaterThan(minCashValue);
  });

  if (newAssets.length === 0) {
    return defaultAvailableCapital;
  }

  return bigNumMath
    .sum(newAssets.map((asset) => asset.value.abs()))
    .toDP(2)
    .toNumber();
};

const StressTestSimulator = ({
  assetToClusters,
  supportedAssets,
  portfolioAssets,
  scenarioLibrary,
}: {
  assetToClusters: Record<string, Record<string, string>>;
  supportedAssets: PublicAsset[];
  portfolioAssets: { id: string; value: BigNumber }[];
  scenarioLibrary: (Omit<StressTestScenario, 'events'> & {
    events: ScenarioLibraryEvent[];
  })[];
}): ReactElement => {
  const defaultPortfolioAmount = calculateDefaultPortfolioAmount(portfolioAssets);

  const methods = useForm<StressTestInputFields>({
    resolver: gYupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      constraintType: StressTestConstraintType.Cash,
      portfolioAmount: defaultPortfolioAmount.toString(),
      assets: [{ asset: null, value: '' }],
      scenario: [{ asset: null, value: '' }],
    },
  });

  const { onErrorAndThrow } = useGraphQLApiError(methods);

  const secondaryButtonProps = {
    variant: 'plain',
    width: 'fullWidth',
    disabled: methods.formState.isSubmitting,
  } as const;

  const {
    fields: assets,
    remove: removeAsset,
    append: appendAsset,
    replace: replaceAsset,
  } = useFieldArray<StressTestInputFields>({
    control: methods.control,
    name: 'assets',
  });

  const {
    fields: scenario,
    remove: removeScenario,
    append: appendScenario,
    replace: replaceScenario,
  } = useFieldArray<StressTestInputFields>({
    control: methods.control,
    name: 'scenario',
  });

  const [performStressTest, { data }] = usePerformStressTestLazyQuery();
  const portfolioAmountText = methods.watch('portfolioAmount');
  const portfolioAmount =
    portfolioAmountText !== '' && !isNil(portfolioAmountText) ? Number.parseFloat(portfolioAmountText) : undefined;
  const constraintType = methods.watch('constraintType');
  const constraintTypeChanged = useValueChanged(constraintType);

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    methods.resetField('assets');
    methods.clearErrors('assetsLength');
    methods.clearErrors('assetPercentageAllocation');
  }, [constraintTypeChanged, methods]);

  const onAssetValueChangeEffect = useCallback((): void => {
    methods.trigger('assetPercentageAllocation' as const);
  }, [methods]);

  return (
    <>
      <GFormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(async (formInput) => {
            const formOutput = formInput as unknown as StressTestOutputFields;
            const assetAbsSum = bigNumMath.sum(formOutput.assets.map((a) => Math.abs(a.value)));
            const { error } = await performStressTest({
              variables: {
                input: {
                  portfolio: {
                    positionValueType:
                      formOutput.constraintType === StressTestConstraintType.Percentage
                        ? IPositionValueType.Percentage
                        : IPositionValueType.UsdValue,
                    portfolioUsdValue:
                      formOutput.constraintType === StressTestConstraintType.Percentage
                        ? formOutput.portfolioAmount
                        : assetAbsSum,
                    assets: formOutput.assets.map((assetRow) => ({
                      asset: assetRow.asset.label!,
                      value:
                        formOutput.constraintType === StressTestConstraintType.Percentage
                          ? assetRow.value / assetAbsSum
                          : assetRow.value,
                    })),
                  },
                  scenario: {
                    events: formOutput.scenario.map((scenarioRow) => ({
                      asset: scenarioRow.asset.label!,
                      netReturn: scenarioRow.value / 100,
                    })),
                  },
                },
              },
            });

            if (error) {
              onErrorAndThrow(error);
            }
          })}
        >
          <Grid container>
            <Grid lg={8} xs={12}>
              <SectionColumn>
                <HeaderBar title="Portfolio stress test" />
                <Card>
                  <Stack spacing={1.5}>
                    <Header3 title="Step 1: Select portfolio" />
                    <Stack spacing={1}>
                      <FormRadioGroup
                        label="Constraint type"
                        name="constraintType"
                        options={stressTestOptions}
                        width="normal"
                      />
                      {constraintType === StressTestConstraintType.Percentage && (
                        <FormInput
                          label="Portfolio balance"
                          name="portfolioAmount"
                          width="normal"
                          showLabelAboveInput
                        />
                      )}
                      <StressTestInputList
                        items={assets}
                        supportedAssets={supportedAssets}
                        path="assets"
                        valueConfig={{
                          startAdornment: constraintType === StressTestConstraintType.Percentage ? '%' : '$',
                          label: 'Amount',
                          onChangeEffect: onAssetValueChangeEffect,
                        }}
                        methods={methods}
                        remove={(index?: number | number[]): void => {
                          removeAsset(index);
                          methods.trigger('assetPercentageAllocation');
                        }}
                        lengthPath="assetsLength"
                        add={(): void => {
                          appendAsset({
                            asset: null,
                            value: null,
                          });
                        }}
                        addNewLabel="Add new position"
                        importComponent={(props: ImportComponentProps): ReactElement => (
                          <ImportPortfolioButton
                            disabled={props.disabled || portfolioAssets.length === 0}
                            constraintFormulation={
                              constraintType === StressTestConstraintType.Cash ? 'cash' : 'percentage'
                            }
                            portfolioAssets={portfolioAssets}
                            replaceAssets={(assets: { id: string; value: BigNumber }[]): void => {
                              methods.clearErrors('assetsLength');
                              methods.clearErrors('assetPercentageAllocation');
                              methods.clearErrors('assets');

                              const idToAsset = Object.fromEntries(supportedAssets.map((asset) => [asset.id, asset]));
                              replaceAsset(
                                assets
                                  .map((asset) => ({
                                    asset: idToAsset[asset.id],
                                    value: asset.value.toDP(2).toNumber().toString(),
                                  }))
                                  .filter((entry) => !!entry.asset)
                              );
                            }}
                            portfolioAmount={portfolioAmount}
                            leverageType={ILeverageType.LongShort}
                          />
                        )}
                      />
                    </Stack>
                    <NormalizeAllocation
                      errorPath="assetPercentageAllocation"
                      itemName=""
                      itemsPath="assets"
                      valuePath="value"
                      methods={methods as unknown as UseFormReturn}
                      allowNegativeValues
                    />
                    <Header3 title="Step 2: Specify scenario conditions" />
                    <StressTestInputList
                      items={scenario}
                      supportedAssets={supportedAssets}
                      methods={methods}
                      remove={removeScenario}
                      path="scenario"
                      lengthPath="scenarioLength"
                      valueConfig={{
                        startAdornment: '%',
                        label: 'Variation',
                      }}
                      add={(): void =>
                        appendScenario({
                          asset: null,
                          value: '',
                        })
                      }
                      addNewLabel="Add condition"
                      importComponent={(props: ImportComponentProps): ReactElement => (
                        <DialogButton
                          {...props}
                          disabled={props.disabled}
                          variant="solid"
                          renderDialog={({ onClose }): ReactElement => (
                            <ScenarioDialog<StressTestScenario>
                              onClose={onClose}
                              title="Select scenario to import"
                              onSelected={(scenario): void => {
                                replaceScenario(
                                  scenario.events.map((event) => ({
                                    asset: event.asset ? event.asset : null,
                                    value:
                                      event.netChange !== undefined
                                        ? bignumber(event.netChange).mul(100).toString()
                                        : null,
                                  }))
                                );

                                methods.clearErrors('scenarioLength');
                                methods.clearErrors('scenario');
                                onClose();
                              }}
                              scenarios={scenarioLibrary.map((scenario) => ({
                                ...scenario,
                                events: scenario.events,
                              }))}
                            />
                          )}
                        >
                          Import scenario from library
                        </DialogButton>
                      )}
                    />
                    <Grid container justifyContent="flex-end">
                      <Grid xs={6} md={6}>
                        <Stack alignItems="center" spacing={1.5}>
                          <GraphQLApiFormErrorMessage />
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-end">
                      <Grid xs={6} md={3}>
                        <GButton
                          {...secondaryButtonProps}
                          onClick={(): void => {
                            methods.reset();
                          }}
                        >
                          Clear all
                        </GButton>
                      </Grid>
                      <Grid xs={6} md={3}>
                        <SubmitButton color="primary" width="fullWidth">
                          Calculate
                        </SubmitButton>
                      </Grid>
                    </Grid>
                  </Stack>
                </Card>
              </SectionColumn>
            </Grid>
            <Grid lg={4} xs={12}>
              <StressTestResult result={data?.stressTest?.stressTest} assetToClusters={assetToClusters} />
            </Grid>
          </Grid>
        </form>
      </GFormProvider>
    </>
  );
};

export default StressTestSimulator;
