import { Tooltip } from '@mui/joy';
import type { ReactElement, ReactNode } from 'react';

import { alignInlineStyles } from '../../../iconStyles';
import { Information } from '../icons';

const Help = ({ children }: { children: NonNullable<ReactNode> }): ReactElement => {
  const iconWithRequiredWrapper = (
    <span>
      <Information
        style={{
          ...alignInlineStyles,
          fontSize: 'inherit',
        }}
      />
    </span>
  );

  return <Tooltip title={children}>{iconWithRequiredWrapper}</Tooltip>;
};

export default Help;
