import { Box, ListItem, ListItemButton, ListItemContent, ListItemDecorator, Chip, Typography } from '@mui/joy';
import type { ReactElement } from 'react';

import { isActive, type SingleMenuItem } from './Menu';
import GLink from '../GLink/GLink';
import { Stack } from '@mui/joy';

const SidebarSingleItem = ({
  item,
  activePath,
  level,
  isAdmin,
}: {
  item: Omit<SingleMenuItem, 'type'>;
  activePath: string;
  level: number;
  isAdmin: boolean;
}): ReactElement => {
  const active = isActive(item, activePath);

  return (
    <ListItem component={GLink} to={item.link} target={item.newTab ? '_blank' : '_self'} underline="none" fontSize="sm">
      <ListItemButton selected={active}>
        <Box pl={level * 2}>
          <ListItemDecorator
            sx={{
              display: 'flex',
            }}
          >
            {item.icon}
          </ListItemDecorator>
        </Box>
        <ListItemContent>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems="center" columnGap={1}>
            <Typography>
              {item.title}
              {isAdmin && (item.adminOnly || (item.groups ?? []).length > 0) ? '*' : ''}
            </Typography>
            {item.new && (
              <Chip color="success" size={'sm'}>
                {'New'}
              </Chip>
            )}
          </Stack>
        </ListItemContent>
      </ListItemButton>
    </ListItem>
  );
};

export default SidebarSingleItem;
