import FormHelperText from '@mui/joy/FormHelperText';
import type { ReactElement } from 'react';

import { Box } from '@mui/joy';

const InputError = ({ error }: { error: string | undefined }): ReactElement => {
  return (
    <Box sx={{ position: 'absolute', top: '100%', zIndex: 1, '--FormHelperText-margin': '0' }}>
      <FormHelperText color="danger">{error}</FormHelperText>
    </Box>
  );
};

export default InputError;
