import { Grid, Stack, Typography } from '@mui/joy';
import type { ReactElement } from 'react';
import {
  RebalancingRulesDocument,
  useDeleteRebalancingRuleMutation,
  useRebalancingRulesSuspenseQuery,
} from '../../../../../../generated/graphql.tsx';
import { useNavigate, useParams } from 'react-router';
import Message from '../../../../../technical/Message.tsx';
import SeeMoreDropdown from '../../../../../technical/SeeMoreDropDown/SeeMoreDropdown.tsx';
import { DeleteMenuItem } from '../../../../../technical/inputs/GButton/DeleteDialogButton.tsx';
import { DateTimeFormat, formatDate } from '../../../../../formatter.utils.ts';
import capitalize from 'lodash/fp/capitalize';
import isNil from 'lodash/fp/isNil';
import GCard from '../../../../../technical/GCard/GCard.tsx';

const cardHeight = '10rem';

const RebalancingRuleList = (): ReactElement => {
  const gridWidth = { sm: 12, md: 6, lg: 3 };
  const { portfolioDefinitionId } = useParams();
  const navigate = useNavigate();
  const { data } = useRebalancingRulesSuspenseQuery({
    variables: {
      id: portfolioDefinitionId!,
    },
  });

  const [deleteRebalancingRule] = useDeleteRebalancingRuleMutation();

  const def = data.portfolio.definition;
  if (def.genie) {
    return <Message>Cannot display rebalancing rules for Genie portfolios</Message>;
  }

  return (
    <Grid container>
      {def.rebalancingRules.map((rule) => (
        <GCard key={rule.id} height={cardHeight} gridWidth={gridWidth}>
          <Stack direction="column" justifyContent="space-between" height="100%">
            <Stack justifyContent="space-between" direction="row">
              <Typography level="title-lg" component="h3">
                {rule.name}
              </Typography>
              <SeeMoreDropdown>
                <DeleteMenuItem
                  deleteMessage={<>Are you sure you want to remove rule {rule.name}?</>}
                  confirmationMessage="Rule successfully deleted"
                  deleteItem={(): Promise<unknown> => {
                    return deleteRebalancingRule({
                      variables: {
                        id: rule.id,
                      },
                      refetchQueries: [RebalancingRulesDocument],
                    });
                  }}
                />
              </SeeMoreDropdown>
            </Stack>
            <Typography level="body-sm">
              {!isNil(rule.periodUnit) && <div>Period: {capitalize(rule.periodUnit)}</div>}
              {!isNil(rule.periodValue) && <div>Interval: {rule.periodValue}</div>}
              <div>Starting at: {formatDate(rule.start, DateTimeFormat.Date)}</div>
            </Typography>
          </Stack>
        </GCard>
      ))}
      <GCard
        onClick={() => {
          navigate(`/app/copilot/lab/portfolio/${portfolioDefinitionId}/rebalancing-rules/new`);
        }}
        height={cardHeight}
        gridWidth={gridWidth}
        addNewText="Add new"
      ></GCard>
    </Grid>
  );
};

export default RebalancingRuleList;
