import type { ColumnState } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import GAgGrid, { type GAgGridProps } from './GAgGrid.tsx';
import { debounce } from 'lodash/fp';
import type { ReactElement, Ref } from 'react';
import { useAuth } from 'UseAuth.tsx';
import type { UserSettings } from 'components/management/UserSettings.types.ts';
import { fixedForwardRef } from 'components/technical/fixedForwardRef.ts';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling.tsx';
import {
  type IUpdateUserSettingsMutationFn,
  useUserSettingsQuery,
  useUpdateUserSettingsMutation,
} from 'generated/graphql.tsx';

type GAgGridPreserveStateProps<RowData> = GAgGridProps<RowData> & {
  userSettingsKey: UserSettings;
};

function updateReportSettings(
  updateSettings: IUpdateUserSettingsMutationFn,
  userSettingsKey: UserSettings,
  settings: ColumnState[]
): void {
  updateSettings({
    variables: {
      settings: { [userSettingsKey]: settings },
    },
    ignoreResults: true,
  });
}

const DEBOUNCE_TIMEOUT_MS = 3000;
// outside of component to proper debounce
const debouncedUpdateReportSettings = debounce(DEBOUNCE_TIMEOUT_MS, updateReportSettings);

/** wrapper around GAgGrid, saves column state using UpdateUserSettingsMutation */
function GAgGridPreserveState<RowData>(
  props: GAgGridPreserveStateProps<RowData>,
  ref?: Ref<AgGridReact>
): ReactElement {
  const [updateSettings] = useUpdateUserSettingsMutation();
  const userSettingsQueryResult = useDefaultErrorHandling(
    useUserSettingsQuery({
      variables: {
        field: props.userSettingsKey,
      },
    }),
    { disableNoDataFallback: true }
  );
  const { isImpersonating } = useAuth();

  if (!userSettingsQueryResult.loaded) {
    return <userSettingsQueryResult.Fallback />;
  }
  const savedColumnState = userSettingsQueryResult.data.management.userSettings;

  return (
    <GAgGrid<RowData>
      ref={ref}
      {...props}
      onColumnEverythingChanged={(event): void => {
        if (!isImpersonating) {
          debouncedUpdateReportSettings(updateSettings, props.userSettingsKey, event.api.getColumnState());
        }
        props.onColumnEverythingChanged?.(event);
      }}
      onGridReady={(agGrid): void => {
        if (savedColumnState) {
          const state = savedColumnState.map((column: ColumnState) => ({
            ...column,
            width: undefined, // avoid setting width to not interfere with auto size strategy
          }));
          agGrid.api.applyColumnState({
            state,
            // ag-grid doesn't allow applying order with group columns marryChildren prop, and without marryChildren grouped columns don't behave
            applyOrder: false,
          });
          setTimeout(() => {
            // auto size doesn't work without timeout
            agGrid.api.autoSizeAllColumns();
          }, 1);
        }
        props.onGridReady?.(agGrid);
      }}
    />
  );
}

const ForwardedGAgGridPreserveState = fixedForwardRef(GAgGridPreserveState);
export default ForwardedGAgGridPreserveState;
