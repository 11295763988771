import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Alert, Stack, Typography } from '@mui/joy';
import { type ReactElement, type ReactNode, useState } from 'react';
import { defaultRowSpacing } from 'components/StackSpacing';
import Loader from 'components/technical/Loader/Loader';
import {
  type IAssetOptimizerResultQuery,
  IPortfolioOptimizationResultStatusType,
  type IPortfolioOptimizerResultQuery,
} from 'generated/graphql';

import PortfolioOptimizerSolutionDetails from './SolutionDetails';
import type { GroupWithAssetId } from 'components/portfolio/dashboard/PositionAggregationsService.ts';
import OptimizerCompareSolutions from './OptimizerCompareSolutions';
import type { OptimizationType } from '../optimization.utils.ts';

const ErrorOptimizationStatus = ({ children }: { children: ReactNode }): ReactElement => {
  return (
    <Alert startDecorator={<WarningAmberIcon />} variant="soft" color="danger">
      {children}
    </Alert>
  );
};

const InProgressOptimizationStatus = ({ children }: { children: ReactNode }): ReactElement => {
  return (
    <Alert startDecorator={<Loader variant="small" />} variant="soft" color="success">
      {children}
    </Alert>
  );
};

const optimizationStatusMessage: Record<IPortfolioOptimizationResultStatusType, ReactNode> = {
  CANCELED: <ErrorOptimizationStatus>Optimization was cancelled</ErrorOptimizationStatus>,
  FAILED: <ErrorOptimizationStatus>Optimization failed</ErrorOptimizationStatus>,
  IN_PROGRESS: <InProgressOptimizationStatus>Optimization in progress</InProgressOptimizationStatus>,
  PENDING: <InProgressOptimizationStatus>Optimization is pending</InProgressOptimizationStatus>,
  COMPLETED: <></>,
};

const OptimizerResult = <
  TYPE extends NonNullable<
    IAssetOptimizerResultQuery | IPortfolioOptimizerResultQuery
  >['optimization']['getOptimization'],
>({
  optimization,
  optimizationType,
  assetGroups,
}: {
  optimization: TYPE;
  optimizationType: OptimizationType;
  assetGroups:
    | {
        genieGroups: GroupWithAssetId[];
        userGroups: GroupWithAssetId[];
      }
    | undefined;
}): ReactElement => {
  const [nonDominated, setNonDominated] = useState(false);
  const [suggested, setSuggested] = useState(true);

  const filteredOptimization = {
    ...optimization,
    output: optimization.output.filter((output): boolean => {
      if (nonDominated && !output.nonDominated) {
        return false;
      }

      return !(suggested && !output.suggested);
    }),
  };

  return (
    <Stack gap={2}>
      <Typography level="h2">{filteredOptimization.name}</Typography>
      {optimizationStatusMessage[filteredOptimization.status]}
      {[IPortfolioOptimizationResultStatusType.Completed, IPortfolioOptimizationResultStatusType.InProgress].includes(
        filteredOptimization.status
      ) && (
        <Stack gap={defaultRowSpacing}>
          <OptimizerCompareSolutions
            optimization={filteredOptimization}
            nonDominated={nonDominated}
            setNonDominated={setNonDominated}
            suggested={suggested}
            setSuggested={setSuggested}
            objectives={optimization.objectives}
          />
          <PortfolioOptimizerSolutionDetails
            optimizationType={optimizationType}
            optimization={filteredOptimization}
            assetGroups={assetGroups}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default OptimizerResult;
