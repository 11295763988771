import type { Dayjs } from 'dayjs';
import isNil from 'lodash/fp/isNil';
import sortBy from 'lodash/fp/sortBy';
import { type Location, matchPath, useLocation } from 'react-router';

import type { StaticAutocompleteOption } from 'components/technical/inputs/Autocomplete/StaticSingleAutocomplete.props.ts';
import type { NewsGraphNodeId } from './NewsGraph.tsx';
import { type IGraphNodeIdInput, IGraphNodeType, type INewsFeed } from '../../../generated/graphql.tsx';
import type { AssetLabelInput } from '../asset/AssetLabelService.ts';

const HEIGHT_PX = 21;

export type NewsExplorerFilterValues = {
  assets: AssetLabelInput[];
  source: INewsFeed[];
  viewed: null | boolean;
  bookmarked: null | boolean;
  dateRange: null | [Dayjs, Dayjs];
};

export const createNodeIdInput = (nodeId: NewsGraphNodeId | undefined): IGraphNodeIdInput | undefined => {
  if (isNil(nodeId)) {
    return undefined;
  }

  if (nodeId.type === IGraphNodeType.Asset && !isNil(nodeId.asset)) {
    return {
      assetId: nodeId.asset.id,
    };
  }
  if (nodeId.type === IGraphNodeType.Topic && !isNil(nodeId.topic)) {
    return {
      topicId: nodeId.topic.id,
    };
  }

  console.error('Missing asset or topic to create news nodeid', nodeId);
  return undefined;
};

const visibleOnPaths = ['/app/market/news-explorer'];
export const useShowNewsExplorerFilters = (): boolean => {
  const location = useLocation();
  return canShowNewsExplorer(location);
};

export const canShowNewsExplorer = (loc: Location): boolean => {
  return visibleOnPaths.some((path) => matchPath({ path, end: false }, loc.pathname));
};

export const createNewsFeedSelectOptions = (
  newsFeeds: INewsFeed[]
): {
  options: StaticAutocompleteOption<INewsFeed>[];
  isValueEqual: (a: INewsFeed | undefined | null, b: INewsFeed | undefined | null) => boolean;
  optionHeight: number;
  limitTags: number;
} => {
  return {
    options: sortBy((news) => news.humanName, newsFeeds).map((feed) => ({
      label: feed.humanName,
      key: feed.id.toString(),
      value: feed,
      searchText: feed.humanName,
    })),
    isValueEqual: (a, b) => a?.id === b?.id,
    optionHeight: HEIGHT_PX,
    limitTags: 2,
  };
};

export const defaultFilterState: NewsExplorerFilterValues = {
  bookmarked: null,
  viewed: null,
  assets: [],
  source: [],
  dateRange: null,
};
