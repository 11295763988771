import { Stack } from '@mui/joy';
import { type ReactElement, useRef } from 'react';

import ColorIndexService from './ColorIndexService.ts';
import RiskMetricsChart from './RiskMetricsChart.tsx';
import type { ParameterDescription } from './RiskMetricsParameterService.tsx';
import type { IPortfolioRiskMetricsInput, IRiskDashboardInputQuery } from '../../../generated/graphql.tsx';
import { type AssetLabelInput, isAssetLabelInput } from '../../market/asset/AssetLabelService.ts';
import {
  PORTFOLIO_ALPHA_METRIC,
  PORTFOLIO_BETA_METRIC,
  PORTFOLIO_CALMAR_RATIO_METRIC,
  PORTFOLIO_CAPTURE_METRIC,
  PORTFOLIO_CORRELATION_METRIC,
  PORTFOLIO_CVAR_METRIC,
  PORTFOLIO_DOWNSIDE_RISK_METRIC,
  PORTFOLIO_DRAWDOWN_METRIC,
  PORTFOLIO_KURTOSIS_METRIC,
  PORTFOLIO_MAR_RATIO_METRIC,
  PORTFOLIO_SHARPE_METRIC,
  PORTFOLIO_SKEWNESS_METRIC,
  PORTFOLIO_SORTINO_METRIC,
  PORTFOLIO_TRACKING_ERROR_METRIC,
  PORTFOLIO_VAR_METRIC,
  PORTFOLIO_VOLATILITY_METRIC,
} from '../../metrics/PortfolioMetricsData.ts';

const riskAdjustedMetrics = [
  PORTFOLIO_SORTINO_METRIC,
  PORTFOLIO_SHARPE_METRIC,
  PORTFOLIO_ALPHA_METRIC,
  PORTFOLIO_CAPTURE_METRIC,
  PORTFOLIO_CALMAR_RATIO_METRIC,
  PORTFOLIO_MAR_RATIO_METRIC,
];

const riskMetrics = [
  PORTFOLIO_VOLATILITY_METRIC,
  PORTFOLIO_DRAWDOWN_METRIC,
  PORTFOLIO_KURTOSIS_METRIC,
  PORTFOLIO_SKEWNESS_METRIC,
  PORTFOLIO_BETA_METRIC,
  PORTFOLIO_CORRELATION_METRIC,
  PORTFOLIO_DOWNSIDE_RISK_METRIC,
  PORTFOLIO_TRACKING_ERROR_METRIC,
];

const varMetrics = [PORTFOLIO_VAR_METRIC, PORTFOLIO_CVAR_METRIC];

const RiskMetricsContainer = (props: {
  groups: IRiskDashboardInputQuery['assets']['assetGroups'];
  metricParameters: IRiskDashboardInputQuery['portfolio']['metricParameters'];
  showDatePicker: boolean;
  showPortfolio: boolean;
  filters: Pick<IPortfolioRiskMetricsInput, 'portfolioDefinitionIds' | 'subAccountAssetFilter' | 'since' | 'to'>;
}): ReactElement => {
  const labelToMetricParams: Record<Label, ParameterDescription[]> = Object.fromEntries(
    props.metricParameters.map((metric): [Label, ParameterDescription[]] => [
      metric.label,
      metric.parameters.map((param): ParameterDescription => {
        return {
          ...param,
          benchmarks: (param.benchmarks ?? []).filter((bench): bench is AssetLabelInput => isAssetLabelInput(bench)),
        };
      }),
    ])
  );

  const categoryService = useRef(new ColorIndexService());

  const availableRiskAdjustedMetrics = riskAdjustedMetrics.filter((metric) => metric in labelToMetricParams);
  const availableRiskMetrics = riskMetrics.filter((metric) => metric in labelToMetricParams);
  const availableVarMetrics = varMetrics.filter((metric) => metric in labelToMetricParams);

  return (
    <Stack spacing={1}>
      {availableRiskAdjustedMetrics.length > 0 && (
        <RiskMetricsChart
          {...props}
          metrics={availableRiskAdjustedMetrics}
          title="Risk-adjusted performance metrics"
          metricParameters={labelToMetricParams}
          categoryService={categoryService.current}
        />
      )}
      {availableRiskMetrics.length > 0 && (
        <RiskMetricsChart
          {...props}
          metrics={availableRiskMetrics}
          withContribution
          title="Risk metrics"
          metricParameters={labelToMetricParams}
          categoryService={categoryService.current}
        />
      )}
      {availableVarMetrics.length > 0 && (
        <RiskMetricsChart
          {...props}
          metrics={availableVarMetrics}
          withContribution
          title="Value at risk"
          metricParameters={labelToMetricParams}
          categoryService={categoryService.current}
        />
      )}
    </Stack>
  );
};

export default RiskMetricsContainer;
