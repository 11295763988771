import { Tab } from '@mui/joy';
import type { FunctionComponent } from 'react';
import { useLocation } from 'react-router';

import type { NavigableTabProps } from './NavigableTab.props';
import GLink from '../GLink/GLink';
import Help from '../Help/Help';

const NavigableTab: FunctionComponent<NavigableTabProps> = ({ link, text, tooltip }) => {
  const location = useLocation();
  return (
    <Tab
      value={link}
      component={GLink}
      to={link}
      underline="none"
      color={location.pathname === link ? 'primary' : 'neutral'}
      variant="plain"
    >
      {text}
      {tooltip && (
        <>
          &nbsp;<Help>{tooltip}</Help>
        </>
      )}
    </Tab>
  );
};

export default NavigableTab;
