import { Stack } from '@mui/joy';
import { memo, type ReactElement } from 'react';

import OptionsStressTestPortfolioValue from './OptionsStressTestPortfolioValue';
import OptionsStressTestResultTable from './OptionsStressTestResultTable';
import type { IEvaluateOptionsPortfolioQuery } from '../../../../generated/graphql';

const OptionsStressTestResults = ({
  portfolioEvaluation,
}: {
  portfolioEvaluation: IEvaluateOptionsPortfolioQuery;
}): ReactElement => {
  const scenarioAnalysis = portfolioEvaluation.optionsAnalysis.scenarioAnalysis;
  return (
    <Stack spacing={2}>
      <div>
        <OptionsStressTestPortfolioValue data={scenarioAnalysis.referenceValue} />
      </div>
      <div>
        <OptionsStressTestResultTable data={scenarioAnalysis.simulatedResults} />
      </div>
    </Stack>
  );
};

const MemoizedOptionsStressTestResults = memo(OptionsStressTestResults);

export default MemoizedOptionsStressTestResults;
