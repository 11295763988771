import type { ReactElement } from 'react';
import { Controller, type FieldPathValue, type FieldValues, type Path, useFormContext } from 'react-hook-form';

import StaticSingleAutocomplete from '../inputs/Autocomplete/StaticSingleAutocomplete';
import type { StaticSingleAutocompleteProps } from '../inputs/Autocomplete/StaticSingleAutocomplete.props';
import type { DistributiveOmit } from '../../type.utils.ts';

// biome-ignore lint/suspicious/noExplicitAny:
export type FormStaticAutocompleteProps<T extends FieldValues, N extends Path<T> = any> = DistributiveOmit<
  StaticSingleAutocompleteProps<FieldPathValue<T, N>>,
  'value'
> & {
  name: N;
};

// biome-ignore lint/suspicious/noExplicitAny:
const FormStaticSingleAutocomplete = <T extends FieldValues, N extends Path<T> = any>(
  props: FormStaticAutocompleteProps<T, N>
): ReactElement => {
  /* jscpd:ignore-start */
  const { control } = useFormContext();

  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field: { value, onChange, onBlur, ref }, fieldState, formState }): ReactElement => {
        return (
          <StaticSingleAutocomplete
            {...props}
            ref={ref}
            value={value}
            error={fieldState.error?.message ?? props.error}
            onChange={(value) => {
              props.onChange?.(value);
              onChange(value);
            }}
            onBlur={onBlur}
            disabled={(props.disabled ?? false) || formState.isSubmitting || formState.disabled}
          />
        );
      }}
    />
  );
};

export default FormStaticSingleAutocomplete;
