import isNil from 'lodash/fp/isNil';
import { bignumber } from 'mathjs';

import {
  type ItemOutlookOutput,
  RiskDistributionOption,
} from '../assumptionsAndOutlook/AssumptionsAndOutlook.validation.tsx';
import type { AssetOptimizerOutputFields } from './AssetOptimizer.validation.ts';
import {
  type IConstrainedAssetInput,
  IConstraintFormulation,
  type IGivenPortfolioAssetInput,
  type IGroupConstrainedQuantity,
  type IGroupConstraintInput,
  ILeverageType,
  type IMutationSubmitOptimizationArgs,
} from '../../../../../generated/graphql.tsx';
import {
  getConstraintValue,
  getObjectives,
  getPortfolioConstraints,
  getRiskBudget,
  getUserExpectedReturn,
} from '../requestFactory.ts';

const getGroupConstraints = (data: AssetOptimizerOutputFields): IGroupConstraintInput[] => {
  return data.constraints
    .filter((cons) => cons.item.type === 'group')
    .map((cons) => {
      const group = cons.item.group;
      if (!group) {
        console.error('Missing group for constraint', cons);
        throw new Error('Missing group');
      }

      if (isNil(cons.constrainedQuantity)) {
        throw new Error('Missing constrained quantity for group ' + group.id);
      }

      return {
        groupId: Number.parseInt(group.id),
        constrainedQuantity: cons.constrainedQuantity as IGroupConstrainedQuantity,
        constraint: getConstraintValue(cons, IConstraintFormulation.Percentage) ?? {
          lowerBound: 0,
          upperBound: 0,
        },
      };
    });
};

const getAssetLeverage = (
  assetId: string,
  output: AssetOptimizerOutputFields,
  outlook: Record<string, ItemOutlookOutput>
): number | null => {
  if (output.constraintType === IConstraintFormulation.Percentage) {
    return null;
  }

  if (!output.allowShortAndLeverage) {
    return 1;
  }

  const assetOutlook = outlook[assetId];
  if (isNil(assetOutlook)) {
    throw new Error(`Missing outlook for ${assetId}`);
  }

  if (typeof assetOutlook.leverage === 'number') {
    return assetOutlook.leverage;
  }

  return 2;
};

const getAssetConstraints = (
  output: AssetOptimizerOutputFields,
  forecast: Record<string, { expectedReturn: number; targetRiskBudget: number | null }>,
  assetIdToOutlook: Record<string, ItemOutlookOutput>
): IConstrainedAssetInput[] => {
  const assetIdToConstraint = Object.fromEntries(
    output.constraints.filter((cons) => cons.item.type === 'asset').map((cons) => [cons.item.asset?.id, cons])
  );

  return output.universe.map((asset) => {
    const maxYield = assetIdToOutlook[asset.id]?.yield;
    const constraint = assetIdToConstraint[asset.id];
    return {
      id: asset.id,
      constraint: constraint
        ? getConstraintValue(
            { constraintValue: constraint.constraintValue, constraintType: constraint.constraintType },
            output.constraintType
          )
        : null,
      riskBudget: getRiskBudget(asset.id, output, assetIdToOutlook, forecast),
      userExpectedReturn: getUserExpectedReturn(asset.id, output, assetIdToOutlook, forecast),
      maxLeverage: getAssetLeverage(asset.id, output, assetIdToOutlook),
      expectedYield: isNil(maxYield) ? undefined : bignumber(maxYield).div(100).toNumber(),
    };
  });
};

function getGivenPortfolioFromInput(
  output: AssetOptimizerOutputFields,
  forecast: Record<string, { expectedReturn: number; targetRiskBudget: number | null }>,
  assetIdToOutlook: Record<string, ItemOutlookOutput>
): IGivenPortfolioAssetInput[] {
  const givenPortfolio = [];
  for (const [assetId, initialPortfolioValue] of Object.entries(output.givenPortfolio)) {
    const value = initialPortfolioValue ? bignumber(initialPortfolioValue) : null;

    if (!isNil(value) && !value.isZero()) {
      const maxYield = assetIdToOutlook[assetId]?.yield;
      givenPortfolio.push({
        id: assetId,
        cashWeight: value.div(100).toNumber(), // e.g. 30% (input) -> 0.3 (api)
        riskBudget: getRiskBudget(assetId, output, assetIdToOutlook, forecast),
        userExpectedReturn: getUserExpectedReturn(assetId, output, assetIdToOutlook, forecast),
        expectedYield: isNil(maxYield) ? undefined : bignumber(maxYield).div(100).toNumber(),
      });
    }
  }

  return givenPortfolio;
}

export const createRequestInput = (
  output: AssetOptimizerOutputFields,
  forecast: {
    returnsForecast: Record<string, string>;
    riskBudgetForecast: Record<string, string>;
  }
): IMutationSubmitOptimizationArgs['input'] => {
  const assetIdToOutlook = Object.fromEntries(output.outlook.map((out) => [out.id, out]));
  const assetIdToForecast = Object.fromEntries(
    Object.keys(assetIdToOutlook).map((id) => {
      return [
        id,
        {
          expectedReturn: Number.parseFloat(forecast.returnsForecast[id]),
          targetRiskBudget:
            output.riskBudgetAllocation === RiskDistributionOption.Forecast ||
            output.riskBudgetAllocation === RiskDistributionOption.MultifactorScore
              ? Number.parseFloat(forecast.riskBudgetForecast[id])
              : null,
        },
      ];
    })
  );

  return {
    name: output.name,
    description: output.description,
    constraintFormulation: output.constraintType,
    returnMeasureName: output.returnsForecast,
    assets: getAssetConstraints(output, assetIdToForecast, assetIdToOutlook),
    portfolioAmount: output.portfolioAmount,
    leverageType: output.allowShortAndLeverage ? ILeverageType.LongShort : ILeverageType.LongOnly,
    portfolioConstraints: getPortfolioConstraints(output),
    groupConstraints: getGroupConstraints(output),
    objectives: getObjectives(output),
    givenPortfolio: getGivenPortfolioFromInput(output, assetIdToForecast, assetIdToOutlook),
  };
};
