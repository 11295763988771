import dayjs, { type Dayjs } from 'dayjs';

import { defaultHeight } from './Chart.constants';

const selectedMarkSize = 10;
const chartMargin = 5;

export const calculateDateRange = (values: Dayjs[]): { minRange: Dayjs; maxRange: Dayjs } => {
  const minValue = values.length > 1 ? (dayjs.min(...values) as Dayjs) : values[0];
  const maxValue = values.length > 1 ? (dayjs.max(...values) as Dayjs) : values[0];
  const span = maxValue.diff(minValue);
  const msInAPixel = span / defaultHeight;
  const chartMarginMs = (chartMargin + selectedMarkSize) * msInAPixel;
  const minRange = minValue.subtract(chartMarginMs, 'ms');
  const maxRange = maxValue.add(chartMarginMs, 'ms');
  return { minRange, maxRange };
};
