import { Modal, ModalDialog, Stack } from '@mui/joy';
import type { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import GFormProvider from 'components/technical/form/GFormProvider';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton.tsx';
import { useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError.tsx';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import { FormInput } from 'components/technical/inputs';
import FormCheckbox from '../form/FormCheckbox.tsx';

export type FormType = {
  name: string;
  isFavorite: boolean;
};

const CreatePresetDialog = (props: {
  onClose: () => void;
  onAddPreset: (presetData: FormType) => Promise<void>;
  existingPresetNames: string[];
}): ReactElement => {
  const formSchema = yup.object({
    name: yup
      .string()
      .required()
      .max(64)
      .test('unique', 'Preset with such name already exists', (value) => !props.existingPresetNames.includes(value)),
  });

  const methods = useForm<FormType>({
    resolver: gYupResolver(formSchema),
    defaultValues: {
      name: '',
      isFavorite: true,
    },
  });

  const { onErrorAndThrow } = useGraphQLApiError(methods);
  const { showSuccessMessage } = useFeedback();

  const handleFormSubmit = async (data: FormType): Promise<void> => {
    try {
      await props.onAddPreset(data);
      props.onClose();

      showSuccessMessage('Preset successfully created');
    } catch (e) {
      onErrorAndThrow(e);
    }
  };

  return (
    <Modal open={true} onClose={props.onClose}>
      <ModalDialog>
        <GDialogHeader>Create new preset</GDialogHeader>
        <GFormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
            <Stack alignItems="center" spacing={2.5}>
              <FormInput label="Preset name" name="name" autoComplete="off" width="fullWidth" errorInTooltip />
              <FormCheckbox label="Mark as favorite" name="isFavorite" />
              <GraphQLApiFormErrorMessage />

              <SubmitButton width="fullWidth">Create</SubmitButton>
            </Stack>
          </form>
        </GFormProvider>
      </ModalDialog>
    </Modal>
  );
};

export default CreatePresetDialog;
