import type { ApolloClient, OperationVariables, WatchQueryOptions } from '@apollo/client';

// biome-ignore lint/suspicious/noExplicitAny:
export const createCancellableQuery = async <T = any, TVariables extends OperationVariables = OperationVariables>(
  apolloClient: ApolloClient<object>,
  options: WatchQueryOptions<TVariables, T> & { signal?: AbortSignal }
): Promise<T> => {
  const query = apolloClient.watchQuery({
    ...options,
  });

  options.signal?.throwIfAborted();
  return new Promise((resolve, reject) => {
    const observable = query.subscribe(
      ({ data }) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );

    options.signal?.addEventListener('abort', () => {
      observable.unsubscribe();
    });
  });
};
