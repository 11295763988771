import { type ReactElement, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import FormInput from 'components/technical/form/FormInput';
import FormStaticSingleAutocomplete from 'components/technical/form/FormStaticSingleAutocomplete';
import RemoveButton from 'components/technical/RemoveButton';

import type { StressTestArrayFields, StressTestInputFields } from './StressTestSimulator.types';
import type { PublicAsset } from '../../market/asset/Asset.types';
import { createAssetSelectOptions } from '../../market/asset/AssetService';
import { usePrevious } from '../../UsePrevious';
import { useValueChanged } from '../../UseValueChanged';

const getPathsWithSameId = ({
  value,
  index,
  total,
  getValues,
  pathPrefix,
}: {
  value: { id: string } | null;
  index: number;
  total: number;
  getValues: (path: string) => unknown;
  pathPrefix: string;
}): string[] => {
  const fieldsWithSameValue = [];
  for (let i = 0; i < total; i++) {
    const otherPath = `${pathPrefix}.${i}.asset`;
    // biome-ignore lint/suspicious/noExplicitAny:
    if (value && i !== index && (getValues(otherPath) as any)?.id === value) {
      fieldsWithSameValue.push(otherPath);
    }
  }

  return fieldsWithSameValue;
};

export interface StressTestInputRowProps {
  supportedAssets: PublicAsset[];
  onRemove: () => void;
  onChangeEffect?: () => void;
  validateFields: boolean;
  index: number;
  total: number;
  listPath: StressTestArrayFields;
  valueStartAdornment: string;
}

export const StressTestInputRow = ({
  index,
  onRemove,
  validateFields,
  supportedAssets,
  listPath,
  total,
  valueStartAdornment,
  onChangeEffect,
}: StressTestInputRowProps): ReactElement => {
  const normalizedAssetOptions = createAssetSelectOptions(supportedAssets);

  const { trigger, getValues, formState, control } = useFormContext<StressTestInputFields>();
  const pathPrefix = `${listPath}.${index}` as const;

  const asset = useWatch({
    name: `${pathPrefix}.asset`,
    control,
  });

  const prevAsset = usePrevious(asset);
  useEffect(() => {
    // validate asset ids
    if (prevAsset?.id === asset?.id) {
      return;
    }

    if (!validateFields) {
      return;
    }

    const fieldsWithSameValue = getPathsWithSameId({ value: prevAsset, index, total, getValues, pathPrefix });
    fieldsWithSameValue.push(...getPathsWithSameId({ value: asset, index, total, getValues, pathPrefix }));

    // biome-ignore lint/suspicious/noExplicitAny:
    trigger(fieldsWithSameValue as any);
  }, [asset, prevAsset, getValues, trigger, total, index, validateFields, pathPrefix]);

  const value = useWatch({
    name: `${pathPrefix}.value`,
  });

  const valueChanged = useValueChanged(value);
  const onChangesEffectChanged = useValueChanged(onChangeEffect);
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (valueChanged || onChangesEffectChanged) {
      onChangeEffect?.();
    }
  }, [onChangeEffect, value, valueChanged, onChangesEffectChanged]);

  return (
    <>
      <FormStaticSingleAutocomplete<StressTestInputFields>
        name={`${pathPrefix}.asset` as const}
        width="fullWidth"
        placeholder="Choose asset"
        {...normalizedAssetOptions}
      />
      <FormInput<StressTestInputFields>
        startDecorator={valueStartAdornment}
        name={`${pathPrefix}.value` as const}
        type="number"
        width="fullWidth"
      />
      <RemoveButton
        disabled={formState.isSubmitting}
        onClick={(): void => {
          onRemove();

          if (!validateFields) {
            return;
          }

          const fieldsWithSameValue = getPathsWithSameId({
            value: asset,
            index: -1,
            total,
            pathPrefix: listPath,
            getValues,
          });

          // biome-ignore lint/suspicious/noExplicitAny:
          trigger(fieldsWithSameValue as any);
        }}
      />
    </>
  );
};
