import type { ReactElement } from 'react';
import { Grid } from '@mui/joy';
import type { Transaction } from './Transaction.types.ts';
import type { Row } from '@tanstack/table-core';
import { partition } from 'lodash/fp';
import { TransactionLegColumn } from './TransactionLegColumn.tsx';
import { bignumber } from 'mathjs';
import { IUserTransactionType } from '../../../generated/graphql.tsx';

const nonPnlUserTransactionTypes = [IUserTransactionType.Deposit, IUserTransactionType.Withdrawal];
const TransactionLegTab = ({ row }: { row: Row<Transaction> }): ReactElement => {
  const { legs } = row.original;
  const [positive, negativeZero] = partition((leg) => bignumber(leg.amount).isPositive(), legs);
  return (
    <Grid container>
      <TransactionLegColumn legs={positive} />
      <TransactionLegColumn legs={negativeZero} pnl={!nonPnlUserTransactionTypes.includes(row.original.userType)} />
    </Grid>
  );
};

export default TransactionLegTab;
