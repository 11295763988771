import { ButtonGroup, Stack } from '@mui/joy';
import type { FunctionComponent } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

import { REPLAY_UNMASK_CLASS_NAME } from 'setup/initializeSentry';
import { type PredefinedRange, defaultQuickAccessDateRanges } from '../../../predefinedDateRanges';
import GButton from '../GButton/GButton';

type PredefinedDateRangeButtonsProps = {
  value: PredefinedRange | undefined;
  alignRight?: boolean;
  onChange: (rangeLabel: PredefinedRange) => void;
  dateRanges?: PredefinedRange[];
};

const PredefinedDateRangeButtons: FunctionComponent<PredefinedDateRangeButtonsProps> = ({
  onChange,
  alignRight = true,
  value,
  dateRanges = defaultQuickAccessDateRanges,
}) => {
  return (
    <Stack
      direction="row"
      marginLeft={alignRight ? 'auto' : undefined}
      className={REPLAY_UNMASK_CLASS_NAME}
      justifyContent="flex-end"
      alignItems="center"
    >
      <ButtonGroup>
        {dateRanges.map((range) => (
          <GButton
            key={range.label}
            variant={value?.label === range.label ? 'soft' : 'outlined'}
            color="primary"
            onClick={(): void => {
              onChange(range);
            }}
          >
            {range.label}
          </GButton>
        ))}
      </ButtonGroup>
    </Stack>
  );
};

export default PredefinedDateRangeButtons;
