import { type ReactElement, useEffect } from 'react';

const clarityScriptId = 'clarity';

const MsClarity = ({ id, userId }: { id: string; userId: string }): ReactElement => {
  useEffect(() => {
    const claritySelector = `#${clarityScriptId}`;
    const el = document.querySelector(claritySelector);
    if (el) {
      return;
    }

    const script = document.createElement('script');
    script.src = `https://www.clarity.ms/tag/${id}`;
    script.id = clarityScriptId;
    script.async = true;
    document.body.appendChild(script);
  }, [id]);

  useEffect(() => {
    window.clarity('identify', userId);
  }, [userId]);

  return <></>;
};

export default MsClarity;
