import { Box, Stack, Typography } from '@mui/joy';
import dayjs from 'dayjs';
import capitalize from 'lodash/fp/capitalize';
import type { ReactElement } from 'react';
import type { ICalendarEventsQuery } from '../../../../../generated/graphql';

export type CalendarEvent = ICalendarEventsQuery['calendar']['calendarEvents'][number];
export const EventView = ({ event }: { event: CalendarEvent }): ReactElement => (
  <Stack
    spacing={0}
    sx={{
      minWidth: 0,
    }}
  >
    <Stack direction="row" spacing={2} width="100%">
      <Typography
        color="primary"
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
          minWidth: '2ch',
        }}
      >
        {capitalize(event.title)}
      </Typography>
      <Typography color="neutral" level="body-md">
        •
      </Typography>
      <Typography color="neutral" level="body-md" sx={{ flexShrink: 0 }}>
        {dayjs(event.time).fromNow()}
      </Typography>
    </Stack>
    <Box
      component={'span'}
      sx={{
        webkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        wordBreak: 'break-word',
        display: '-webkit-box',
        webkitBoxOrient: 'vertical',
      }}
    >
      {capitalize(event.description ?? '')}
    </Box>
  </Stack>
);
