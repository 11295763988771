import type { ReactElement } from 'react';
import { usePortfolioRiskInputSuspenseQuery } from '../../../../../generated/graphql.tsx';
import type { BacktestingConfiguration } from '../BacktestConfiguration.types.ts';
import RiskMetricsContainer from '../../../risk/RiskMetricsContainer.tsx';
import type { AssetGroups } from '../../../../market/asset/groups/AssetGroups.types.ts';

const PortfolioRisk = ({
  backtestingConfig,
  assetGroups,
}: {
  backtestingConfig: BacktestingConfiguration;
  assetGroups: AssetGroups;
}): ReactElement => {
  const query = usePortfolioRiskInputSuspenseQuery();

  return (
    <RiskMetricsContainer
      groups={assetGroups}
      metricParameters={query.data.portfolio.metricParameters}
      showDatePicker={false}
      showPortfolio
      filters={{
        portfolioDefinitionIds: backtestingConfig.portfolios.map((port) => port.id),
        to: backtestingConfig.range.to,
        since: backtestingConfig.range.since,
      }}
    />
  );
};

export default PortfolioRisk;
