import { type ErrorHandlingOutput, useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import { usePublicAssetsQuery } from '../../../generated/graphql';

const supportedOptionAssets = ['BTC', 'ETH'];

export const useOptionsBaseAssets = (): ErrorHandlingOutput<{
  loaded: boolean;
  data: { symbol: string; id: string }[];
}> => {
  const { loaded, data, Fallback } = useDefaultErrorHandling(usePublicAssetsQuery());
  if (!loaded) {
    return { loaded: false, data: undefined, Fallback };
  }

  return {
    loaded: true,
    data: data.assets.list.filter((asset) => supportedOptionAssets.includes(asset.symbol)),
    Fallback: undefined,
  };
};
