import type { FormInputType } from 'components/technical/form/Form.types';
import type { NotVerifiedAsset } from '../../market/asset/AssetLabelService.ts';

export type StressTestArrayFields = 'assets' | 'scenario';

export interface StressTestAssetInputFields {
  asset: (NotVerifiedAsset & { id: string }) | null;
  value: string | null;
}

export interface StressTestAssetOutputFields {
  asset: NotVerifiedAsset & { id: string };
  value: number;
}

export enum StressTestConstraintType {
  Cash = 'CASH',
  Percentage = 'PERCENTAGE',
}

export interface StressTestOutputFields {
  portfolioAmount: number;
  assets: StressTestAssetOutputFields[];
  constraintType: StressTestConstraintType;

  assetsLength?: undefined;
  assetPercentageAllocation?: undefined;
  scenario: StressTestAssetOutputFields[];
  scenarioLength?: undefined;
}

export type StressTestInputFields = Omit<FormInputType<StressTestOutputFields>, 'assets' | 'scenario'> & {
  assets: StressTestAssetInputFields[];
  scenario: StressTestAssetInputFields[];
};

export interface ImportScenarioDialogScenarioEvent {
  asset?: (NotVerifiedAsset & { id: string }) | null;
  netChange?: number;
}

export interface StressTestScenario {
  name: string;
  description: string;
  events: ImportScenarioDialogScenarioEvent[];
}
