import { Grid } from '@mui/joy';
import type { ReactElement, ReactNode } from 'react';

export const TwoThirdsLayout = ({ left, right }: { left: ReactNode; right: ReactNode }): ReactElement => {
  return (
    <Grid container spacing={1.5}>
      <Grid lg={8} xs={12}>
        {left}
      </Grid>
      <Grid lg={4} xs={12}>
        {right}
      </Grid>
    </Grid>
  );
};
