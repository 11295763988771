export const clickedOutsideElement = (e: MouseEvent | TouchEvent, element: HTMLElement | undefined | null): boolean => {
  if (!element) {
    return false;
  }

  const target = e.target;
  if (!target) {
    return false;
  }

  if (!(target instanceof Node)) {
    return false;
  }

  return !element.contains(target);
};
