import { Box, Stack } from '@mui/joy';
import {
  getPaginationRowModel,
  getSortedRowModel,
  type SortingOptions,
  type SortingTableState,
} from '@tanstack/react-table';
import type { CoreInstance, PaginationOptions, PaginationTableState } from '@tanstack/table-core';
import type { ReactElement } from 'react';
import type { GTableDynamicProps, GTableProps } from './GTable.props';
import { GraphQLErrorCardMessage } from '../form/GraphQLApiErrorMessage';
import Loader from '../Loader/Loader.tsx';
import Message from '../Message';
import type { SxProps } from '@mui/system';

export const isDynamic = <ROW,>(props: GTableProps<ROW>): props is GTableDynamicProps<ROW> => {
  // biome-ignore lint/suspicious/noExplicitAny:
  return !!(props as any).paginator;
};

export const calculateSortingProps = <ROW,>(props: GTableProps<ROW>): SortingOptions<ROW> => {
  if (isDynamic(props)) {
    return {
      manualSorting: true,
      enableSorting: true,
      enableMultiSort: false,
      onSortingChange: props.onSortingChange,
      enableSortingRemoval: false,
    };
  }

  return {
    manualSorting: false,
    getSortedRowModel: getSortedRowModel(),
    enableSorting: true,
    enableMultiSort: true,
  };
};

export const getBackgroundColor = (): string => {
  return 'none';
};

export const createTableElementProps = (): SxProps => ({
  width: '100%',
  borderCollapse: 'separate',
  borderSpacing: 0,
  tr: {
    border: 0,
    td: {
      padding: 0,
    },
  },
});

export const getBorderColor = (): string => {
  return 'var(--joy-palette-background-level2)';
};

export const calculateExtraRows = <ROW,>(props: GTableProps<ROW>, table: CoreInstance<ROW>): ReactElement[] => {
  const extraRows = [];
  if (isDynamic(props)) {
    if (props.loading) {
      extraRows.push(
        <tr key="loading">
          <td
            colSpan={table.getAllColumns().length}
            style={{
              borderBottomWidth: 0,
            }}
          >
            <Stack direction="row" justifyContent="center" spacing={1.5}>
              <Loader />
            </Stack>
          </td>
        </tr>
      );
    }

    if (props.error) {
      console.error('Error from api', props.error);
      extraRows.push(
        <tr key="error">
          <td
            colSpan={table.getAllColumns().length}
            style={{
              borderBottomWidth: 0,
              textAlign: 'center',
            }}
          >
            <GraphQLErrorCardMessage error={props.error} />
          </td>
        </tr>
      );
    }
  }

  if (!props.hideNoDataMessage && extraRows.length === 0 && (props.data?.length ?? 0) === 0) {
    extraRows.push(
      <tr key="no-data">
        <Box
          component={'td'}
          colSpan={table.getAllColumns().length}
          sx={{ borderBottomWidth: '0 !important', textAlign: 'center', padding: '0.75rem' }}
        >
          <Message>No data</Message>
        </Box>
      </tr>
    );
  }

  return extraRows;
};

export const calculatePaginationProps = (
  // biome-ignore lint/suspicious/noExplicitAny:
  props: GTableProps<any>
): PaginationOptions => {
  if (isDynamic(props)) {
    return {
      getPaginationRowModel: getPaginationRowModel(),
      manualPagination: true,
      pageCount: Math.ceil((props.totalResults ?? 0) / props.paginator.state.pageSize),
      onPaginationChange: props.paginator.onChange,
    };
  }

  if (props.disablePagination) {
    return {};
  }

  return {
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: false,
  };
};

export const calculateState = <ROW,>(props: GTableProps<ROW>): Partial<SortingTableState & PaginationTableState> => {
  if (isDynamic(props)) {
    const pagination = props.paginator?.state;
    const sorting = props.sortingState;
    return {
      sorting,
      pagination,
    };
  }

  return {};
};
