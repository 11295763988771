import type { FormState } from 'react-hook-form';
import type { StepConfig } from './StepConfig.ts';
import type { GetStepStateInput } from './UseSteps.ts';
import type { ReactElement } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  Step,
  StepButton,
  StepIndicator,
  Stepper,
  Typography,
} from '@mui/joy';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import WarningIcon from '@mui/icons-material/Warning';

export const GStepper = (props: {
  activeStep: number;
  // biome-ignore lint/suspicious/noExplicitAny:
  steps: StepConfig<any>[];
  goToStep: (index: number) => void;
  getStepState: (input: GetStepStateInput) => { completed: boolean; hasErrors: boolean; visited: boolean };
  // biome-ignore lint/suspicious/noExplicitAny:
  formState: FormState<any>;
}): ReactElement => {
  return (
    <AccordionGroup disableDivider>
      <Stepper orientation="vertical">
        {props.steps.map((api, index): ReactElement => {
          const { completed, hasErrors, visited } = props.getStepState({
            steps: props.steps,
            index,
            formState: props.formState,
          });

          return (
            <Step
              key={api.label}
              completed={completed}
              active={index === props.activeStep}
              indicator={
                hasErrors ? (
                  <WarningIcon color={'error'} fontSize={'medium'} />
                ) : (
                  <StepIndicator
                    color={props.activeStep === index || visited || completed ? 'primary' : 'neutral'}
                    variant={'solid'}
                  >
                    {completed ? (
                      <CheckRoundedIcon />
                    ) : (
                      <Typography textColor={'inherit'} level={'title-xs'}>
                        {index + 1}
                      </Typography>
                    )}
                  </StepIndicator>
                )
              }
            >
              <StepButton onClick={(): void => props.goToStep(index)}>
                <Typography color={hasErrors ? 'danger' : undefined}>{api.label}</Typography>
              </StepButton>
              {/* by default accordian takes at least over 30px.
              As we don't use its labels, we need to override the default high */}
              <Accordion expanded={index === props.activeStep} sx={{ '--ListItem-minHeight': '1rem' }}>
                <AccordionDetails>{api.element}</AccordionDetails>
              </Accordion>
            </Step>
          );
        })}
      </Stepper>
    </AccordionGroup>
  );
};
