import type { FunctionComponent } from 'react';
import { useStrategyRollingMetricsQuery } from 'generated/graphql';

import StrategyRollingMetricsChart from './StrategyRollingMetricsChart';
import { useTheme } from '@mui/joy';
import { useColorFactory } from '../../../theme/colors.ts';

const StrategySortinoSharpeContainer: FunctionComponent<{ strategy: string }> = ({ strategy }) => {
  const queryOutput = useStrategyRollingMetricsQuery({ variables: { label: strategy } });
  const theme = useTheme();
  const colorFactory = useColorFactory();

  return (
    <>
      <StrategyRollingMetricsChart
        queryOutput={queryOutput}
        metricLabel="pmet:realized_sortino"
        metricName="Rolling sortino"
        colorOfMean={theme.palette.danger['500']}
        colorOfData={colorFactory.getRegularChartColor(0)}
      />
      <StrategyRollingMetricsChart
        queryOutput={queryOutput}
        metricLabel="pmet:realized_sharpe"
        metricName="Rolling sharpe"
        colorOfMean={theme.palette.danger['500']}
        colorOfData={colorFactory.getRegularChartColor(0)}
      />
    </>
  );
};

export default StrategySortinoSharpeContainer;
