import type { FunctionComponent } from 'react';
import { useStrategyRollingMetricsQuery } from 'generated/graphql';

import StrategyRollingMetricsChart from './StrategyRollingMetricsChart';
import { useTheme } from '@mui/joy';
import { useColorFactory } from '../../../theme/colors.ts';

const StrategyUnderwaterContainer: FunctionComponent<{ strategy: Label }> = ({ strategy }) => {
  const queryOutput = useStrategyRollingMetricsQuery({ variables: { label: strategy } });
  const theme = useTheme();
  const colorFactory = useColorFactory();

  return (
    <StrategyRollingMetricsChart
      queryOutput={queryOutput}
      metricLabel="pmet:realized_draw_down"
      metricName="Drawdown"
      colorOfMean={colorFactory.getRegularChartColor(0)}
      colorOfData={theme.palette.danger['500']}
      fillOfData
      yAxisFormat="percentage"
    />
  );
};

export default StrategyUnderwaterContainer;
