import * as Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import accessibility from 'highcharts/modules/accessibility';
import boost from 'highcharts/modules/boost';
import exportDate from 'highcharts/modules/export-data';
import enableExporting from 'highcharts/modules/exporting';
import heatmap from 'highcharts/modules/heatmap';
import histogram from 'highcharts/modules/histogram-bellcurve';
import sunburst from 'highcharts/modules/sunburst';

enableExporting(Highcharts);
exportDate(Highcharts);
accessibility(Highcharts);
boost(Highcharts);
highchartsMore(Highcharts);
heatmap(Highcharts);
histogram(Highcharts);
sunburst(Highcharts);
