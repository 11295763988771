import type { StepConfig } from '../../../../technical/wizard/StepConfig.ts';
import type { PortfolioOptimizerInputFields } from '../portfolio/PortfolioOptimizer.validation.ts';
import type { AssetOptimizerInputFields } from '../asset/AssetOptimizer.validation.ts';
import type { AssetGroup } from '../asset/AssetOptimizerWizard.tsx';
import AllocationConstraintsStep from './AllocationConstraintsStep.tsx';

export const config = ({
  type,
  assetGroups,
  assetIdToClusterToGroup,
  goToNextStep,
}: {
  assetGroups: AssetGroup[];
  assetIdToClusterToGroup: Record<string, Record<string, string>>;
  goToNextStep: () => void;
  type: 'asset' | 'portfolio';
}): StepConfig<AssetOptimizerInputFields | PortfolioOptimizerInputFields> => ({
  label: 'Allocation constraints',
  element: (
    <AllocationConstraintsStep
      goToNextStep={goToNextStep}
      assetGroups={assetGroups}
      assetIdToClusterToGroup={assetIdToClusterToGroup}
      type={type}
    />
  ),
  fields: ['constraintType', 'constraints'],
});
