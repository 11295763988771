import { type FunctionComponent, useMemo } from 'react';

import GAgGrid from 'components/technical/grids/GAgGrid.tsx';
import { clusterColumn, nameColumn, symbolColumn } from 'components/technical/grids/SharedReportColumns.tsx';
import type { UseReportAssetGroupResultLoaded } from 'components/UseReportAssetGroups.tsx';
import type { ColDef, ColGroupDef, ValueGetterParams } from 'ag-grid-community';
import type { BuilderPortfolioPosition } from './PortfolioBuilder.tsx';

const defaultColDef = {
  resizable: true,
  sortable: true,
  filter: true,
};

type PositionsGridProps = {
  reportAssetGroup: UseReportAssetGroupResultLoaded;
  positions: BuilderPortfolioPosition[];
};

const PositionsGrid: FunctionComponent<PositionsGridProps> = ({ positions, reportAssetGroup }) => {
  const columns: (ColDef<BuilderPortfolioPosition> | ColGroupDef<BuilderPortfolioPosition>)[] = useMemo(
    () => [
      {
        headerName: 'Asset Details',
        colId: 'asset-details',
        marryChildren: true,
        children: [
          nameColumn({ initialHide: false }),
          symbolColumn({ initialHide: false }),
          ...reportAssetGroup.clusters.map((cluster) =>
            clusterColumn(cluster, reportAssetGroup.assetAndGroupClusterMapToGroup)
          ),
        ],
      },
      {
        colId: 'side',
        headerName: 'Side',
        type: 'textColumn',
        valueGetter: (params: ValueGetterParams<BuilderPortfolioPosition>): string => {
          if (!params.data) {
            return '';
          }

          return params.data.value > 0 ? 'Long' : 'Short';
        },
      },
      {
        headerName: 'Current positions',
        colId: 'currentPositions',
        marryChildren: true,
        children: [
          { headerName: 'Weight', field: 'weight', type: ['numericColumn', 'percentageColumn'], initialAggFunc: 'sum' },
          { headerName: 'Value', field: 'value', type: ['numericColumn', 'cashColumn'], initialAggFunc: 'sum' },
        ],
      },
    ],
    [reportAssetGroup.clusters, reportAssetGroup.assetAndGroupClusterMapToGroup]
  );

  return (
    <GAgGrid<BuilderPortfolioPosition>
      rowData={positions}
      autoSizeStrategy={{ type: 'fitGridWidth' }}
      sideBar={{
        toolPanels: ['columns', 'filters'],
      }}
      defaultColDef={defaultColDef}
      columnDefs={columns}
    />
  );
};

export default PositionsGrid;
