import { Stack } from '@mui/joy';
import type { ReactElement } from 'react';
import { useFormState, useWatch } from 'react-hook-form';

import FormSelect from 'components/technical/form/FormSelect.tsx';
import FormStaticSingleAutocomplete from 'components/technical/form/FormStaticSingleAutocomplete.tsx';
import RemoveButton from 'components/technical/RemoveButton.tsx';
import type { createAssetSelectOptions } from '../../../../market/asset/AssetService.tsx';
import { PORTFOLIO_EXPECTED_BETA_METRIC } from '../../../../metrics/PortfolioRiskMeasures.ts';
import { portfolioTypeValues, riskMeasureValues } from '../../objective.utils.ts';
import type { ObjectivesStepInput } from './ObjectivesStep.validation.ts';
import type { AssetLabelInput } from '../../../../market/asset/AssetLabelService.ts';

const ObjectiveRow = ({
  index,
  benchmarkOptions,
  onRemove,
}: {
  index: number;
  benchmarkOptions: ReturnType<typeof createAssetSelectOptions<AssetLabelInput>>;
  onRemove: () => void;
}): ReactElement => {
  const formState = useFormState<ObjectivesStepInput>();
  const riskMetric = useWatch<ObjectivesStepInput>({
    name: `objectives.${index}.riskMetric`,
  });

  return (
    <Stack direction="row" flexWrap="wrap" spacing={1.5} alignItems="center">
      <FormSelect<ObjectivesStepInput>
        name={`objectives.${index}.type`}
        label="Optimization objective"
        width="xl4"
        options={portfolioTypeValues}
      />
      <span>using</span>
      <FormSelect<ObjectivesStepInput>
        name={`objectives.${index}.riskMetric`}
        label="Risk metric"
        width="xl2"
        options={riskMeasureValues}
      />
      {riskMetric === PORTFOLIO_EXPECTED_BETA_METRIC && (
        <>
          <span>with respect to</span>
          <FormStaticSingleAutocomplete<ObjectivesStepInput>
            name={`objectives.${index}.benchmark`}
            label="Asset of Beta"
            placeholder="Asset of Beta"
            width="xl2"
            {...benchmarkOptions}
          />
        </>
      )}
      as risk metric
      <RemoveButton
        disabled={formState.isSubmitting}
        onClick={(): void => {
          onRemove();
        }}
      />
    </Stack>
  );
};

export default ObjectiveRow;
