import { Box, Divider, Dropdown, IconButton, MenuButton, MenuItem, Stack, SvgIcon, Typography, Menu } from '@mui/joy';
import { type ReactElement, useCallback, useEffect, useState } from 'react';
import {
  type IMessageHistoryQuery,
  IMessageRoleType,
  type IReaction,
  useGetMessageUpdateQuery,
  useMessageHistoryQuery,
  useSendMessageMutation,
} from '../../../../generated/graphql.tsx';
import { IconSend } from '@tabler/icons-react';
import { GraphQLErrorMessage } from '../../../technical/form/GraphQLApiErrorMessage.tsx';
import dayjs from 'dayjs';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling.tsx';
import { v4 } from 'uuid';
import type { Message } from './Message.ts';
import Messages from './MessageList.tsx';
import { isMsgPending } from './IsMsgPending.tsx';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import GTextArea from 'components/technical/inputs/GTextArea/GTextArea.tsx';
import { useFeedback } from '../../../technical/Feedback/UseFeedback.tsx';
import GIcon from '../../../technical/GIcon.tsx';

interface ConversationThreadProps {
  conversationId?: string;
  agent: { id: string; name: string } | null;
  agents: { id: string; name: string; icon: string }[];
  onAgentSelected: (agent: { id: string; name: string }) => void;
  onConversationStarted: (conversationId: string) => void;
}

const ConversationView = ({
  conversationId,
  agent,
  onConversationStarted,
  agents,
  onAgentSelected,
}: ConversationThreadProps): ReactElement => {
  const historyPageSize = 10;
  const { showInfoMessage } = useFeedback();
  const [currentConversationId, setCurrentConversationId] = useState(conversationId);
  const [messageItems, setMessageItems] = useState<Message[]>([]);
  const [sendMessage, { loading: sendMessageLoading, error: sendMessageError, reset: resetSendMessage }] =
    useSendMessageMutation();
  const [message, setMessage] = useState('');
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [loadedInitialHistory, setLoadedInitialHistory] = useState(false);
  const [lastHistoryFetchPefTime, setLastHistoryFetchPefTime] = useState<DOMHighResTimeStamp>(0);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [loadingHistoryError, setLoadingHistoryError] = useState<Error | null>(null);

  const updateReaction = (id: string, newReaction: IReaction | null): void => {
    setMessageItems((msgs) => msgs.map((msg) => (msg.id === id ? { ...msg, reaction: newReaction } : msg)));
  };

  const messageInProgressId = messageItems.find((item) => {
    if (item.role === IMessageRoleType.User) {
      return false;
    }

    return isMsgPending(item);
  })?.id;

  const messageUpdateQuery = useDefaultErrorHandling(
    useGetMessageUpdateQuery({
      variables: {
        messageId: messageInProgressId ?? '',
      },
      pollInterval: 2000,
      skip: !messageInProgressId,
    })
  );

  useEffect(() => {
    if (messageUpdateQuery.loading) {
      return;
    }

    if (messageUpdateQuery.loaded) {
      const response = messageUpdateQuery.data!.news.agents.message!;
      if (isMsgPending(response)) {
        // continue polling
        return;
      }

      setMessageItems((msgs) => {
        return msgs.map((msg): Message => {
          if (msg.id !== messageInProgressId) {
            return msg;
          }

          return {
            ...response,
            agent: agents.find((ag) => ag.id === response.agentId)!,
            createdAt: dayjs.utc(response.createdAt),
            anchored: false,
            reaction: response.reaction?.reaction,
          };
        });
      });

      return;
    }
  }, [messageUpdateQuery, messageInProgressId, agents]);

  useEffect(() => {
    if (conversationId === currentConversationId) {
      return;
    }

    setMessageItems([]);
    setCurrentConversationId(conversationId);
    setMessage('');
    resetSendMessage();
    setHasMoreItems(true);
    setLoadedInitialHistory(false);
    setLoadingHistory(false);
    setLoadingHistoryError(null);
  }, [conversationId, currentConversationId, resetSendMessage]);

  const onMessageHistoryQueryCompleted = useCallback(
    (results: IMessageHistoryQuery) => {
      const newMessages = results.news.agents.messages;
      const resCount = newMessages.length;

      if (resCount < historyPageSize) {
        setHasMoreItems(false);
      }

      const newMessageIds = new Set(newMessages.map((msg) => msg.id));

      setMessageItems((msgs) => [
        ...newMessages.toReversed().map(
          (msg): Message => ({
            anchored: false,
            id: msg.id,
            content: msg.content,
            agentResult: msg.agentResult,
            role: msg.role,
            createdAt: dayjs.utc(msg.createdAt),
            agent: agents.find((ag) => ag.id === msg.agentId)!,
            reaction: msg.reaction?.reaction,
          })
        ),
        ...msgs.filter((msg) => !newMessageIds.has(msg.id)),
      ]);

      setLoadedInitialHistory(true);
      setLastHistoryFetchPefTime(performance.now());
    },
    [agents]
  );

  // the query doesnt report statuses when using fetchMore function
  // toggling on notification via notifyOnNetworkStatusChange triggers onCompleted with stale data
  // so we need to use query for initial load and then handle loading state and errors manually
  const messageHistoryQuery = useMessageHistoryQuery({
    variables: {
      offset: 0,
      limit: historyPageSize,
      conversationId: conversationId!,
    },
    skip: !conversationId || loadedInitialHistory,
    onCompleted: onMessageHistoryQueryCompleted,
  });

  const fetchMore = useCallback(async () => {
    setLoadingHistoryError(null);
    setLoadingHistory(true);

    setLastHistoryFetchPefTime(performance.now());
    setMessageItems((msgs) =>
      msgs.map((msg) => ({
        ...msg,
        anchored: true,
      }))
    );

    try {
      const res = await messageHistoryQuery.fetchMore({
        variables: {
          offset: messageItems.length,
        },
      });
      onMessageHistoryQueryCompleted(res.data);
    } catch (e) {
      setLoadingHistoryError(e as Error);
    } finally {
      setLoadingHistory(false);
    }
  }, [messageItems, messageHistoryQuery.fetchMore, onMessageHistoryQueryCompleted]);

  const submitMessage = async () => {
    if (!agent) {
      showInfoMessage('Please select an agent');
      return;
    }

    const convId = conversationId ?? v4();

    const result = await sendMessage({
      variables: {
        input: {
          agentId: agent!.id,
          content: message,
          conversationId: convId,
        },
      },
    });

    const data = result.data!;

    setCurrentConversationId(convId);
    setMessageItems((msgs) => [
      ...msgs,
      {
        id: data.SendMessage.userMessage.id,
        anchored: false,
        content: message,
        role: IMessageRoleType.User,
        createdAt: dayjs.utc(data.SendMessage.userMessage.createdAt),
        reaction: null,
      } satisfies Message,
      {
        id: data.SendMessage.agentMessage.id,
        anchored: false,
        content: '',
        agent: agents.find((ag) => ag.id === data.SendMessage.agentMessage.agentId)!,
        role: IMessageRoleType.Assistant,
        createdAt: dayjs.utc(data.SendMessage.agentMessage.createdAt),
        reaction: null,
      } satisfies Message,
    ]);
    setMessage('');

    if (!conversationId) {
      onConversationStarted(convId);
    }
  };

  return (
    <Stack height={'100%'} rowGap={2}>
      <Stack rowGap={2} alignItems={'stretch'}>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} gap={1}>
          <Typography level={'title-lg'}>{agent?.name ?? 'Select an agent'}</Typography>
          {agents.length > 1 && (
            <Dropdown>
              <MenuButton
                slots={{ root: IconButton }}
                startDecorator={<AutoAwesomeOutlinedIcon fontSize={'sm'} />}
                slotProps={{
                  root: {
                    variant: 'soft',
                  },
                }}
              />
              <Menu>
                {agents
                  .filter((otherAgent) => otherAgent.id !== agent?.id)
                  .map((ag) => (
                    <MenuItem
                      onClick={() => {
                        onAgentSelected(ag);
                      }}
                      key={ag.id}
                    >
                      <GIcon src={ag.icon} alt={''} fontSize={'md'} />
                      {ag.name}
                    </MenuItem>
                  ))}
              </Menu>
            </Dropdown>
          )}
        </Stack>
        <Divider />
      </Stack>
      <Messages
        key={currentConversationId}
        messageItems={messageItems}
        fetchMore={fetchMore}
        hasMoreItems={hasMoreItems}
        loadingData={messageHistoryQuery.loading || loadingHistory}
        loadingError={messageHistoryQuery.error || loadingHistoryError}
        loadedInitialHistory={loadedInitialHistory}
        lastFetchPerfTime={lastHistoryFetchPefTime}
        updateReaction={updateReaction}
      />
      <GraphQLErrorMessage error={messageUpdateQuery.errors} />

      <Box mx={'auto'}>
        <GraphQLErrorMessage error={sendMessageError} />
      </Box>
      <Stack direction={'row'} mt={'auto'} gap={1} alignItems={'center'}>
        <GTextArea
          disabled={sendMessageLoading || !!messageInProgressId}
          placeholder={'Ask a question...'}
          autoComplete={'off'}
          width={'fullWidth'}
          sx={{
            height: '2.5rem',
          }}
          value={message}
          onChange={(value) => setMessage(value ?? '')}
          onKeyDown={(e) => {
            if (!e.shiftKey && e.key === 'Enter') {
              submitMessage();
            }
          }}
          endDecorator={
            <Box ml={'auto'}>
              <IconButton
                disabled={!message || !!messageInProgressId}
                loading={sendMessageLoading || !!messageInProgressId}
                color={'primary'}
                variant={'solid'}
                size={'md'}
                onClick={submitMessage}
              >
                <SvgIcon component={IconSend} />
              </IconButton>
            </Box>
          }
        />
      </Stack>
    </Stack>
  );
};

export default ConversationView;
