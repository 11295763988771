import { Card, Stack, Typography } from '@mui/joy';
import type { Options } from 'highcharts';
import capitalize from 'lodash/fp/capitalize';
import get from 'lodash/fp/get';
import sortBy from 'lodash/fp/sortBy';
import uniq from 'lodash/fp/uniq';
import { bignumber } from 'mathjs';
import type { ReactElement } from 'react';
import type { Path } from 'react-hook-form';
import { greeks } from 'components/market/asset/singleAsset/public/Options/Greeks';

import { defaultBottomSpacing, defaultTopSpacing } from 'components/technical/charts/Chart.constants';
import {
  heatmapBorderColor,
  heatmapBorderWidth,
  type HighchartSeries,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import HighChartsWrapper from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper';
import type { IGreeks } from '../../../../generated/graphql';
import { gradientColorsDarkMode, gradientColorsLightMode } from '../../../../theme/colors.ts';
import { useFinalColorScheme } from '../../../../useFinalColorScheme.ts';
import { formatNumber, formatterForName } from '../../../formatter.utils';
import HeaderBar from '../../../technical/HeaderBar/HeaderBar.tsx';

interface PortfolioValue {
  npvPnl: number;
  marketPnl?: number | null;
  greeks: IGreeks;
}
interface ChartInput {
  label: string;
  valuePath: Path<PortfolioValue>;
  format: 'cash' | 'number';
}

const charts: ChartInput[] = [
  {
    valuePath: 'npvPnl',
    label: 'P&L (net present value)',
    format: 'cash',
  },
  ...greeks.map(
    (opt): ChartInput => ({
      valuePath: `greeks.${opt}`,
      label: capitalize(opt),
      format: 'number',
    })
  ),
];

type Component = 'price' | 'volatility' | 'riskFree';
const componentLabels: Record<Component, string> = {
  volatility: 'Volatility shock',
  price: 'Price shock',
  riskFree: 'Interest rate shock',
};

const chartCombinations: [Component, Component][] = [
  ['price', 'volatility'],
  ['price', 'riskFree'],
  ['volatility', 'riskFree'],
];

const getComponentValues = (data: { parameterChange: Record<Component, number> }[], component: Component): number[] => {
  return sortBy((el) => el, uniq(data.map((row) => row.parameterChange[component])));
};

const axisPercentageMultiplier = 100;
const createAxis = (
  categories: Record<string, number[]>,
  category: Component
): { categories: string[]; title: { text: string } } => ({
  categories: categories[category].map((cat) => formatNumber(cat * axisPercentageMultiplier)),
  title: {
    text: componentLabels[category],
  },
});

const createCombinationKey = (change: Record<Component, number>): string => {
  return `${change.riskFree}-${change.volatility}-${change.price}`;
};

const getFixedComponent = (combination: [Component, Component]): Component => {
  return Object.keys(componentLabels).find((el) => !combination.includes(el as Component)) as Component;
};

const titleHeight = 25;
const axisHeight = 25;
const legendAndAxisWidth = 150;
const targetTileWidth = 90;
const targetTileHeight = 50;
// shorten legend so that labels will fit
const legendSpacing = 10;

const OptionsStressTestResultTable = ({
  data,
}: {
  data: {
    parameterChange: Record<Component, number>;
    portfolioValue: { npvPnl: number; marketPnl?: number | null; greeks: IGreeks };
  }[];
}): ReactElement => {
  const colorScheme = useFinalColorScheme();
  const categories: Record<string, number[]> = Object.fromEntries(
    Object.keys(componentLabels).map((key) => [key, getComponentValues(data, key as Component)], componentLabels)
  );

  const normalizedData: Record<string, PortfolioValue> = Object.fromEntries(
    data.map((row) => [createCombinationKey(row.parameterChange), row.portfolioValue])
  );

  return (
    <>
      <HeaderBar title="Stress test" />
      <Stack rowGap={3}>
        {charts.map((chart) => (
          <Card key={chart.valuePath}>
            <Typography level="h4">{chart.label}</Typography>
            <Stack spacing={2} justifyContent="stretch">
              {chartCombinations.map((comb) => {
                const xValues = categories[comb[0]];
                const yValues = categories[comb[1]];
                const combinationData = xValues.flatMap((xValue, xIndex) =>
                  yValues.map((yValue, yIndex): { x: number; y: number; value: number } => {
                    const combinationKey = createCombinationKey({
                      [getFixedComponent(comb)]: 0,
                      [comb[0]]: xValue,
                      [comb[1]]: yValue,
                    } as Record<Component, number>);

                    const value: number | string = get(chart.valuePath, normalizedData[combinationKey]);

                    return {
                      x: xIndex,
                      y: yIndex,
                      value: bignumber(value).toNumber(),
                    };
                  })
                );

                const absValues = combinationData.map((data) => Math.abs(data.value));
                const max = Math.max(...absValues);
                const chartHeightPx =
                  titleHeight +
                  axisHeight +
                  defaultTopSpacing +
                  defaultBottomSpacing +
                  yValues.length * targetTileHeight;

                const legendHeight =
                  chartHeightPx - titleHeight - axisHeight - defaultBottomSpacing - defaultTopSpacing - legendSpacing;
                const yOffset = (titleHeight + axisHeight + defaultTopSpacing - legendSpacing / 2) / 2;

                return (
                  <div
                    style={{
                      width: `${legendAndAxisWidth + xValues.length * targetTileWidth}px`,
                      maxWidth: '100%',
                    }}
                    key={comb.join('-')}
                  >
                    <HighChartsWrapper
                      loading={false}
                      data={data}
                      calculateChartData={(): HighchartSeries[] => {
                        const formatter = formatterForName(chart.format);
                        return [
                          {
                            data: combinationData.map((row) => ({
                              ...row,
                              textValue: formatter(row.value),
                            })),
                            type: 'heatmap',
                            dataLabels: {
                              enabled: true,
                              format: '{point.textValue}',
                            },
                            borderWidth: heatmapBorderWidth,
                            borderColor: heatmapBorderColor,
                          },
                        ];
                      }}
                      calculateOptions={(): Options => {
                        return {
                          colorAxis: {
                            min: -max,
                            max: max,
                            stops: colorScheme === 'light' ? gradientColorsLightMode : gradientColorsDarkMode,
                            visible: true,
                            reversed: false,
                          },
                          xAxis: { ...createAxis(categories, comb[0]), opposite: true, lineWidth: 0 },
                          yAxis: {
                            ...createAxis(categories, comb[1]),
                          },
                          tooltip: {
                            enabled: false,
                          },
                          exporting: {
                            enabled: false,
                          },
                          chart: {
                            type: 'heatmap',
                            height: chartHeightPx,
                          },
                          legend: {
                            align: 'right',
                            layout: 'vertical',
                            margin: 0,
                            y: yOffset,
                            verticalAlign: 'middle',
                            symbolHeight: legendHeight,
                          },
                        };
                      }}
                    />
                  </div>
                );
              })}
            </Stack>
          </Card>
        ))}
      </Stack>
    </>
  );
};

export default OptionsStressTestResultTable;
