import { Stack } from '@mui/joy';
import type { Dayjs } from 'dayjs';
import { type ReactElement, useCallback, useMemo, useState } from 'react';
import { getDefaultRange } from 'components/predefinedDateRanges';
import ActionsContextProvider from 'components/technical/actions/ActionsContextProvider.tsx';
import StaticMultiAutocomplete from 'components/technical/inputs/Autocomplete/StaticMultiAutocomplete';
import PredefinedDateRangeButtonsWithCalendar from 'components/technical/inputs/date/PredefinedDateRangeButtonsWithCalendar';

import HistoricalCorrelation from './HistoricalCorrelation';
import ActionsChartWithTitle from '../../../ActionsChartWithTitle.tsx';
import type { PublicAsset } from '../Asset.types';
import { createAssetSelectOptions } from '../AssetService';
import { convertDateRangeToSinceToDate } from 'components/technical/inputs/date/dateRange.utils';

const MAX_ASSETS = 5;

const HistoricalCorrelationSection = ({
  metric,
  defaultAssets,
  supportedAssets,
}: {
  defaultAssets: PublicAsset[];
  supportedAssets: PublicAsset[];
  metric: string;
}): ReactElement => {
  const defaultDateRange = getDefaultRange();
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(defaultDateRange.value);
  const [selectedAssets, setSelectedAssets] = useState<PublicAsset[]>(defaultAssets.slice(0, MAX_ASSETS));
  const assetOptions = useMemo(() => createAssetSelectOptions(supportedAssets), [supportedAssets]);

  const labelProvider = useCallback((firstAsset: { symbol: string }, secondAsset: { symbol: string }): string => {
    const labels = [firstAsset.symbol, secondAsset.symbol].sort();
    return labels.join(' / ');
  }, []);

  const selectedAssetLabels = selectedAssets.map((asset) => asset.label);

  return (
    <ActionsContextProvider>
      <ActionsChartWithTitle title="Historical correlations" paper>
        <Stack spacing={1}>
          <Stack direction="row" spacing={1.5}>
            <StaticMultiAutocomplete
              {...assetOptions}
              value={selectedAssets}
              width="xl2"
              onChange={(vals): void => setSelectedAssets(vals)}
              label="Assets"
            />
            <PredefinedDateRangeButtonsWithCalendar
              defaultValue={defaultDateRange}
              onChange={(val): void => setDateRange(val)}
            />
          </Stack>
          {selectedAssets.length > MAX_ASSETS ? (
            <Stack direction="row" justifyContent="center" spacing={1.5}>
              Too many assets selected
            </Stack>
          ) : (
            <HistoricalCorrelation
              queryInput={{
                input: {
                  primaryAssets: selectedAssetLabels,
                  secondaryAssets: selectedAssetLabels,
                  metric: metric,
                },
                filters: {
                  date: convertDateRangeToSinceToDate(dateRange),
                },
              }}
              selectedAssets={supportedAssets}
              filename="historical-asset-correlation"
              labelProvider={labelProvider}
            />
          )}
        </Stack>
      </ActionsChartWithTitle>
    </ActionsContextProvider>
  );
};

export default HistoricalCorrelationSection;
