import HeaderBar from '../../../../technical/HeaderBar/HeaderBar.tsx';
import GCard from 'components/technical/GCard/GCard.tsx';
import CreateRealPortfolioDefDialog from './CreateRealPortfolioDialog.tsx';
import { Grid } from '@mui/joy';
import type { ReactElement } from 'react';
import type { CreateAccountIdAutocompleteOptionsInputAccount } from '../../../../portfolio/account/AccountService.tsx';
import WithDialog from 'components/technical/inputs/withDialogHoc/WithDialog.tsx';
import type { SubfundAutocompleteOptionInput } from '../../../../portfolio/fund/SubFundService.tsx';
import type { IPortfolioDefinition } from '../../../../../generated/graphql.tsx';
import RealPortfolioCard, { gridWidth } from './RealPortfolioCard.tsx';

const GCardWithDialog = WithDialog(GCard);

export const RealPortfolioDefList = ({
  accounts,
  subFunds,
  defs,
  cardHeight,
  selected,
}: {
  accounts: CreateAccountIdAutocompleteOptionsInputAccount[];
  subFunds: SubfundAutocompleteOptionInput[];
  defs: (Pick<IPortfolioDefinition, 'createdAt' | 'description' | 'id' | 'name' | 'type' | 'genie'> & {
    realDefAccounts: { id: string; name: string; venue: { label: string } }[];
    realDefSubFunds: { id: number; dimension: string; name: string }[];
  })[];
  cardHeight: string;
  selected: string[];
}): ReactElement => {
  return (
    <>
      <HeaderBar
        title="Current portfolios"
        sx={{
          cursor: 'default',
        }}
      />

      <Grid container>
        <GCardWithDialog
          renderDialog={({ onClose }): ReactElement => (
            <CreateRealPortfolioDefDialog onClose={onClose} onAdded={onClose} accounts={accounts} subFunds={subFunds} />
          )}
          height={cardHeight}
          gridWidth={gridWidth}
          addNewText="Add new"
        />
        {defs.map((definition) => (
          <RealPortfolioCard
            definition={definition}
            key={definition.id}
            selected={selected.includes(definition.id)}
            cardHeight={cardHeight}
            subFunds={subFunds}
            accounts={accounts}
          />
        ))}
      </Grid>
    </>
  );
};
