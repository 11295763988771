import { Typography } from '@mui/joy';
import type { DefaultColorPalette } from '@mui/joy/styles/types';
import type { ReactElement, ReactNode } from 'react';

const ErrorMessage = ({
  children,
  color = 'danger',
}: {
  children: ReactNode;
  color?: DefaultColorPalette;
}): ReactElement => (
  <Typography level="body-md" color={color} component="div">
    {children}
  </Typography>
);

export default ErrorMessage;
