import type { ReactElement } from 'react';
import { Controller, type FieldPathValue, type FieldValues, type Path, useFormContext } from 'react-hook-form';

import GRadioGroup from '../inputs/GRadioGroup/GRadioGroup';
import type { GRadioGroupProps } from '../inputs/GRadioGroup/GRadioGroup.props';

function FormRadioGroup<T extends FieldValues = FieldValues, N extends Path<T> = Path<T>>(
  props: Omit<GRadioGroupProps<FieldPathValue<T, N>>, 'name' | 'value' | 'onChange' | 'error'> & {
    name: N;
  }
): ReactElement {
  const { control } = useFormContext();

  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field: { value, onChange, ref }, fieldState, formState }): ReactElement => (
        <GRadioGroup
          {...props}
          ref={ref}
          value={value}
          error={fieldState.error?.message}
          onChange={onChange}
          disabled={(props.disabled ?? false) || formState.isSubmitting || formState.disabled}
        />
      )}
    />
  );
}

export default FormRadioGroup;
