import type { FunctionComponent } from 'react';
import { useSubAccountAssetFilters } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';

import AssetNews from './AssetNews.tsx';
import PortfolioExposureRisk from './PortfolioExposureRisk.tsx';
import PortfolioSnapshotContainer from './PortfolioSnapshotContainer';
import { FIRST_CHART_TITLE, SND_CHART_TITLE } from './PortfolioSnapshotContainerService.tsx';
import PortfolioTiles from './PortfolioTiles';
import { usePortfolioDashboardInputQuery } from '../../../generated/graphql';
import ChartsSkeleton from '../../ChartsSkeleton.tsx';

const PortfolioDashboard: FunctionComponent = () => {
  const { subAccountAssetFilters } = useSubAccountAssetFilters();
  const { loaded, Fallback, data, refetch } = useDefaultErrorHandling(
    usePortfolioDashboardInputQuery({
      variables: { subAccountAssetFilters },
    })
  );

  if (!loaded) {
    return <Fallback />;
  }

  return data.portfolio.accounts.length === 0 ? (
    <ChartsSkeleton
      titleLeft={FIRST_CHART_TITLE}
      titleRight={SND_CHART_TITLE}
      onSubAccountConnected={(): void => {
        refetch();
      }}
    />
  ) : (
    <>
      <PortfolioTiles metricParameters={data.portfolio.metricParameters} />
      <PortfolioSnapshotContainer />
      <PortfolioExposureRisk assets={data.assets.feature} />
      <AssetNews positions={data.portfolio.positions.positions} />
    </>
  );
};

export default PortfolioDashboard;
