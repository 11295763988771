import { type ReactElement, useMemo, useRef } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { createAssetToClusters, priceActionClusterId } from 'components/market/asset/groups/GroupService.ts';

import gYupResolver from 'components/technical/form/gYupResolver.ts';
import { useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError.tsx';
import {
  type ErrorHandlingOutput,
  type TFallback,
  useDefaultErrorHandling,
} from 'components/technical/UseDefaultErrorHandling.tsx';
import { config as allocationConstraintConfig } from '../allocationConstraints/AllocationConstraintsStepConfig.tsx';
import { config as initialPortfolioConfig } from './initialPortfolio/InitialPortfolioStepConfig.tsx';
import { config as assetUniverseConfig } from './assetUniverse/AssetUniverseStepConfig.tsx';
import {
  type ItemOutlookInput,
  multifactorSchema,
  returnMeasureValues,
  RiskDistributionOption,
  riskDistributionValues,
  shouldShowMultifactor,
} from '../assumptionsAndOutlook/AssumptionsAndOutlook.validation.tsx';
import { config as assumptionsAndOutlookConfig } from '../assumptionsAndOutlook/AssumptionsAndOutlookStepConfig.tsx';
import { config as descriptionStepConfig } from '../description/DescriptionStepConfig.tsx';
import { config as objectivesStepConfig } from '../objective/ObjectivesStepConfig.tsx';
import { config as portfolioLevelConstraintsConfig } from '../portfolioConstraints/PortfolioConstraintsStepConfig.tsx';
import {
  type AssetOptimizerInputFields,
  type AssetOptimizerOutputFields,
  schema,
} from './AssetOptimizer.validation.ts';
import { createRequestInput } from './AssetOptimizerRequestFactory.ts';
import { config as submitConfig } from './submit/SubmitStepConfig.tsx';

import { aggregatePositionsForInitialPortfolio } from '../initialPortfolio/InitialPortfolioStep.utils.tsx';
import { isNil, mapValues } from 'lodash/fp';
import { useSteps } from '../../../../technical/wizard/UseSteps.ts';
import {
  type AssetOptimizerForecastQueryResult,
  type AssetOptimizerMultifactorForecastQueryResult,
  type IAsset,
  type IAssetOptimizerWizardInputQuery,
  IConstraintFormulation,
  IObjectiveType,
  IReturnMeasureNameUi,
  type ISubmitAssetOptimizationMutation,
  type ISubmitAssetOptimizationMutationVariables,
  useAssetOptimizerForecastQuery,
  useAssetOptimizerMultifactorForecastQuery,
  useAssetOptimizerWizardInputQuery,
  useSubmitAssetOptimizationMutation,
} from '../../../../../generated/graphql.tsx';
import { type AssetLabelInput, isAssetLabelInput } from '../../../../market/asset/AssetLabelService.ts';
import type { RecursivePartial } from '../../../../type.utils.ts';
import { type AssetInputLabelWithCategory, getAssetCategory } from '../../../../market/asset/AssetService.tsx';
import type { ColDef, ValueGetterParams } from 'ag-grid-enterprise';
import { secondaryConstraintQuantityValues } from '../portfolioConstraints/PortfolioSecondaryConstraint.validation.ts';
import OptimizerWizard from '../portfolio/OptimizerWizard.tsx';
import { OptimizationType } from '../../optimization.utils.ts';
import { logOnce } from '../../../../log.utils.ts';
import dayjs from 'dayjs';
import { convertDateInUtcToUTCISODate } from '../../../../date.utils.ts';
import { bignumber } from 'mathjs';
import Loader from 'components/technical/Loader/Loader.tsx';
import { GraphQLErrorMessage } from '../../../../technical/form/GraphQLApiErrorMessage.tsx';

export type Position = IAssetOptimizerWizardInputQuery['portfolio']['positions']['positions'][number];

const AssetOptimizerWizardContainer = (): ReactElement => {
  const { loaded, Fallback, data } = useDefaultErrorHandling(useAssetOptimizerWizardInputQuery());

  if (!loaded) {
    return <Fallback />;
  }

  const assets = data.assets;
  const benchmarks = assets.benchmark.filter((asset): asset is AssetLabelInput => isAssetLabelInput(asset));
  const availableAssets = assets.feature.filter(
    (asset: RecursivePartial<IAsset>): asset is AssetInputLabelWithCategory & { label: string; id: string } => {
      const valid = !!asset.label && !!asset.id;
      if (!valid) {
        logOnce('Asset doesnt have id and label', asset);
        return false;
      }

      return true;
    }
  ) as (AssetInputLabelWithCategory & { label: string; id: string })[];

  const groups = [...assets.assetGroups.genieGroups, ...assets.assetGroups.userGroups];
  return (
    <AssetOptimizerWizard
      multifactors={data.multifactor.getAllMultifactorsForUser}
      benchmarks={benchmarks}
      availableAssets={availableAssets}
      assetGroups={groups}
      positions={data.portfolio.positions.positions}
    />
  );
};

export type AssetGroup = {
  clusterName: string;
  groupName: string;
  assets: Array<{ id: string }>;
  id: string;
};

export type AvailableAsset = AssetInputLabelWithCategory & { label: string; id: string };

type AssetOptimizerWizardProps = {
  multifactors: IAssetOptimizerWizardInputQuery['multifactor']['getAllMultifactorsForUser'];
  benchmarks: AssetLabelInput[];
  availableAssets: AvailableAsset[];
  assetGroups: AssetGroup[];
  positions: Position[];
};

const calculateForecasts = (
  forecastQuery: ErrorHandlingOutput<AssetOptimizerForecastQueryResult>,
  multifactorForecastQuery: ErrorHandlingOutput<AssetOptimizerMultifactorForecastQueryResult>,
  showMultifactor: boolean
): {
  returnsForecast: Record<string, string>;
  riskBudgetForecast: Record<string, string>;
  loaded: boolean;
  Fallback?: TFallback;
} => {
  if (!forecastQuery.loaded) {
    return {
      riskBudgetForecast: {},
      returnsForecast: {},
      loaded: false,
      Fallback: forecastQuery.Fallback,
    };
  }

  const idToForecast: Record<
    string,
    {
      expectedReturn: number;
      targetRiskBudget: number;
    }
  > = Object.fromEntries(forecastQuery.data?.portfolioOptimization.forecast.map((row) => [row.asset.id, row]) ?? []);

  const returnsForecast = mapValues((row) => (row.expectedReturn * 100).toFixed(2), idToForecast);
  const riskBudgetForecast = mapValues((row) => (row.targetRiskBudget * 100).toFixed(2), idToForecast);

  if (!multifactorForecastQuery.loaded) {
    return {
      riskBudgetForecast: {},
      returnsForecast: returnsForecast,
      loaded: !!multifactorForecastQuery.errors,
      Fallback: !multifactorForecastQuery.errors
        ? () => <Loader />
        : () => <GraphQLErrorMessage error={multifactorForecastQuery.errors} color={'warning'} />,
    };
  }

  const riskBudgetMultifactorForecast = Object.fromEntries(
    multifactorForecastQuery.data?.multifactor.computeBiasFromMultifactor.assetBias.map((bias) => [
      bias.asset.id,
      bignumber(bias.score).mul(100).toFixed(2),
    ]) ?? []
  );

  return {
    returnsForecast,
    riskBudgetForecast: showMultifactor ? riskBudgetMultifactorForecast : riskBudgetForecast,
    loaded: true,
  };
};

const AssetOptimizerWizard = ({
  multifactors,
  benchmarks,
  availableAssets,
  assetGroups,
  positions,
}: AssetOptimizerWizardProps): ReactElement => {
  const now = useRef(dayjs());

  const methods = useForm<AssetOptimizerInputFields>({
    resolver: gYupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      description: '',
      portfolioAmount: '1000',
      allowShortAndLeverage: true,
      objectives: [
        {
          type: IObjectiveType.MaxRiskAdjustedReturns,
          riskMetric: null,
        },
      ],
      constraintType: IConstraintFormulation.Percentage,
      constraints: [],
      universe: [],
      portfolioConstraints: {
        primaryConstraint: null,
        secondaryConstraint: null,
      },
      multifactor: {
        useAbsoluteScores: false,
      },
      returnsForecast: IReturnMeasureNameUi.ForecastedReturns,
      riskBudgetAllocation: RiskDistributionOption.Forecast,
      outlook: [],
      givenPortfolio: {},
    },
  });

  const stepApi = useSteps();
  const { goToStep, validateVisitedSteps } = stepApi;

  const name = useWatch({
    name: 'name',
    control: methods.control,
  });

  const outlookAssets = useWatch<AssetOptimizerInputFields, 'outlook'>({
    name: 'outlook',
    control: methods.control,
  });

  const assetIds = outlookAssets.filter((out) => !isNil(out)).map((out) => out.id);

  const skippedForecast = assetIds.length === 0;
  const forecastQuery = useDefaultErrorHandling(
    useAssetOptimizerForecastQuery({
      variables: {
        assets: assetIds,
      },
      skip: skippedForecast,
    }),
    {
      disableNoDataFallback: true,
    }
  );

  const riskBudgetAllocationSelector = useWatch<AssetOptimizerInputFields, 'riskBudgetAllocation'>({
    name: 'riskBudgetAllocation',
    control: methods.control,
  });

  const multifactor = useWatch<AssetOptimizerInputFields, 'multifactor'>({
    name: 'multifactor',
    control: methods.control,
  });

  const showMultifactor = shouldShowMultifactor(riskBudgetAllocationSelector);
  const parsedMultifactor = multifactorSchema.isValidSync(multifactor)
    ? multifactorSchema.validateSync(multifactor)
    : null;
  const skippedMultifactorForecast = assetIds.length === 0 || !showMultifactor || !parsedMultifactor;

  const multifactorForecastQuery = useDefaultErrorHandling(
    useAssetOptimizerMultifactorForecastQuery({
      variables: {
        input: {
          date: convertDateInUtcToUTCISODate(now.current),
          assetIds,
          multifactorId: parsedMultifactor?.factor.id ?? 0,
          minNumberOfFactors: parsedMultifactor?.minNumberOfFactors ?? 0,
          useAbsoluteScores: parsedMultifactor?.useAbsoluteScores ?? false,
        },
      },
      skip: skippedMultifactorForecast,
    }),
    {
      disableNoDataFallback: true,
    }
  );

  const {
    returnsForecast,
    riskBudgetForecast,
    Fallback: forecastFallback,
  } = useMemo(() => {
    return calculateForecasts(forecastQuery, multifactorForecastQuery, showMultifactor);
  }, [forecastQuery, multifactorForecastQuery, showMultifactor]);

  const steps = useMemo(() => {
    let nextIndex = 1;
    const nextHandler = (): (() => void) => {
      const index = nextIndex++;
      return (): void => goToStep(index);
    };

    const filteredAssetGroups = assetGroups.filter((group) => group.clusterName !== priceActionClusterId);
    const assetIdToClusterToGroup = createAssetToClusters(filteredAssetGroups);
    const idToAsset = Object.fromEntries(availableAssets.map((asset) => [asset.id, asset]));
    const aggregatedPortfolioValuesByAsset = aggregatePositionsForInitialPortfolio(positions, availableAssets);

    const assumptionsColumns: ColDef<ItemOutlookInput>[] = [
      {
        colId: 'assetName',
        headerName: 'Asset',
        valueGetter: (params: ValueGetterParams<ItemOutlookInput, string>): string | undefined => {
          if (!params.data) {
            return undefined;
          }

          const asset = idToAsset[params.data.id];
          if (!asset) {
            return undefined;
          }

          if ('name' in asset) {
            return asset.name ?? asset.symbol;
          }

          return asset.symbol;
        },
        sortable: true,
      },
      {
        colId: 'assetSymbol',
        headerName: 'Symbol',
        valueGetter: (params: ValueGetterParams<ItemOutlookInput, string>): string | undefined => {
          if (!params.data) {
            return undefined;
          }

          return idToAsset[params.data.id]?.symbol;
        },
        sortable: true,
      },
      {
        colId: 'assetType',
        headerName: 'Type',
        valueGetter: (params: ValueGetterParams<ItemOutlookInput, string>): string | undefined => {
          if (!params.data) {
            return undefined;
          }

          const asset = idToAsset[params.data.id];
          if (!asset) {
            return undefined;
          }

          return getAssetCategory(asset);
        },
      },
    ];

    return [
      descriptionStepConfig(nextHandler()),
      objectivesStepConfig(benchmarks, true, nextHandler()),
      portfolioLevelConstraintsConfig(benchmarks, secondaryConstraintQuantityValues, nextHandler()),
      initialPortfolioConfig(availableAssets, assetIdToClusterToGroup, aggregatedPortfolioValuesByAsset, nextHandler()),
      assetUniverseConfig(availableAssets, assetIdToClusterToGroup, nextHandler()),
      allocationConstraintConfig({
        assetGroups,
        assetIdToClusterToGroup,
        goToNextStep: nextHandler(),
        type: 'asset',
      }),
      assumptionsAndOutlookConfig({
        returnsForecast,
        riskBudgetForecast,
        multifactorValues: multifactors.map((fact) => ({
          value: {
            id: fact.multifactor.id,
            maxFactors: fact.maxFactors,
          },
          label: fact.multifactor.name,
          key: fact.multifactor.id.toString(),
        })),
        columns: assumptionsColumns,
        goToNextStep: nextHandler(),
        riskDistributionValues: riskDistributionValues.filter(
          (opt) => opt.value !== RiskDistributionOption.MultifactorScore || multifactors.length > 0
        ),
        returnMeasureValues,
        showYield: true,
        sourceLabels: {
          universe: 'Asset universe',
          givenPortfolio: 'Initial portfolio',
        },
      }),
      submitConfig(forecastFallback, skippedForecast || skippedMultifactorForecast),
    ];
  }, [
    benchmarks,
    availableAssets,
    assetGroups,
    goToStep,
    forecastFallback,
    returnsForecast,
    riskBudgetForecast,
    skippedForecast,
    skippedMultifactorForecast,
    positions,
    multifactors,
  ]);

  validateVisitedSteps({
    trigger: methods.trigger,
    steps,
  });

  const { onErrorAndThrow } = useGraphQLApiError(methods);
  const [submitOptimization] = useSubmitAssetOptimizationMutation();

  return (
    <OptimizerWizard<
      AssetOptimizerInputFields,
      ISubmitAssetOptimizationMutationVariables['input'],
      ISubmitAssetOptimizationMutation
    >
      type={OptimizationType.asset}
      createRequestInput={(input) =>
        createRequestInput(input as unknown as AssetOptimizerOutputFields, {
          returnsForecast,
          riskBudgetForecast,
        })
      }
      methods={methods}
      name={name}
      onErrorAndThrow={onErrorAndThrow}
      stepApi={stepApi}
      steps={steps}
      submitOptimization={submitOptimization}
    />
  );
};

export default AssetOptimizerWizardContainer;
