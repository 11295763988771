import type React from 'react';
import type { ReactNode } from 'react';

import type { GAutocompleteProps } from './GSingleAutocomplete.props.ts';
import type { StaticAutocompleteOption, StaticAutocompleteProps } from './StaticSingleAutocomplete.props.ts';

export const createAutocompleteProps = <T,>(
  props: StaticAutocompleteProps<T>
): Pick<
  GAutocompleteProps<StaticAutocompleteOption<T>>,
  | 'getOptionLabel'
  | 'getOptionKey'
  | 'isOptionEqualToValue'
  | 'renderOption'
  | 'getOptions'
  | 'groupBy'
  | 'renderGroup'
  | 'getOptionSearchText'
> => {
  return {
    getOptionLabel: (option: StaticAutocompleteOption<T>): string => {
      return option.inputText ?? option.searchText;
    },
    getOptionSearchText: (option: StaticAutocompleteOption<T>): string => {
      return option.searchText;
    },
    isOptionEqualToValue: (option1: StaticAutocompleteOption<T>, option2: StaticAutocompleteOption<T>): boolean => {
      if (props.isValueEqual) {
        return props.isValueEqual(option1.value, option2.value);
      }

      return option1.value === option2.value;
    },
    renderOption: (_props: React.HTMLAttributes<HTMLLIElement>, option: StaticAutocompleteOption<T>): ReactNode =>
      option.label,
    getOptionKey: (option: StaticAutocompleteOption<T>) => option.key,
    getOptions: (): Promise<{
      data: StaticAutocompleteOption<T>[];
      hasMoreResults: boolean;
    }> => {
      return Promise.resolve({
        data: props.options,
        hasMoreResults: false,
      });
    },
    groupBy: props.groupBy,
  };
};
