import { bignumber } from 'mathjs';
import { useEffect } from 'react';

import type { AssetGroupElement, AssetListGroup, MinMaxFilter } from './AssetDashboard.types.ts';
import { assignByClusterForRows, findGroupAssignmentStrategy, getMarketCap } from './AssetDashboard.utils.ts';
import { useAssetDashboardLazyQuery } from '../../../../generated/graphql.tsx';
import { getPublicAssets } from '../Asset.types.ts';
import type { FilterType, MarketFiltersState } from '../filters/Filters.types.ts';
import { useMarketFilters } from '../filters/UseMarketFilters.ts';
import { createAssetToClusters } from '../groups/GroupService.ts';
import type { Theme } from '@mui/joy';

export interface Tab {
  name: string;
  link: string;
  cluster: string;
  tooltip?: string;
  showNotAssignedAssets: boolean;
}

export const genieTabs: Tab[] = [
  { cluster: 'price-change', name: 'Price change - 24h', showNotAssignedAssets: true },
  { cluster: 'category', name: 'Category', showNotAssignedAssets: true },
  { cluster: 'sector', name: 'Sector', showNotAssignedAssets: true },
  { cluster: 'market', name: 'Market', showNotAssignedAssets: true },
  {
    cluster: 'cluster_price_action_optics',
    name: 'Price action',
    tooltip: 'Dynamic asset grouping based on similar price action over the last 30 days',
    showNotAssignedAssets: false,
  },
  { cluster: 'mcap_groups', name: 'Market cap', showNotAssignedAssets: true },
].map((tab) => ({ ...tab, link: encodeURIComponent(tab.name) }));

const formatDecimal = (filter: MarketFiltersState[FilterType]): MinMaxFilter | null => {
  if (!filter) {
    return null;
  }

  return {
    min: bignumber(filter.min).toString(),
    max: bignumber(filter.max).toString(),
  };
};

export const useFilteredMarket = (
  clusterType: 'genie' | 'user'
): {
  loading: boolean;
  error?: Error;
  data?: {
    assets: AssetGroupElement[];
    userGroups: {
      assets: { id: string }[];
      clusterName: string;
      groupName: string;
      id: string;
    }[];
  };
} => {
  const { state: filtersState, loading: loadingFilters, error: filtersError } = useMarketFilters();
  const [fetchMarket, { data, loading, error }] = useAssetDashboardLazyQuery();

  useEffect(() => {
    if (!filtersState) {
      return;
    }

    fetchMarket({
      variables: {
        filters: {
          liquidity: formatDecimal(filtersState.liquidity),
          totalValueLocked: formatDecimal(filtersState.totalValueLocked),
          marketCap: formatDecimal(filtersState.marketCap),
        },
      },
    });
  }, [filtersState, fetchMarket]);

  if (filtersError) {
    return { error: filtersError, loading: false };
  }

  if (error) {
    return { error: error, loading: false };
  }

  if (loading || loadingFilters || !data) {
    return { loading: true };
  }

  const assets = data.assets;

  const assetIdToAsset = Object.fromEntries(getPublicAssets(assets.feature).map((asset) => [asset.id, asset]));
  const assetIdToChange = Object.fromEntries(assets.changes.map((change) => [change.asset, change]));

  const assetIdToClusters = createAssetToClusters(
    assets.assetGroups[clusterType === 'user' ? 'userGroups' : 'genieGroups']
  );

  const finalAssets: AssetGroupElement[] = assets.details
    .filter((el) => el.asset in assetIdToAsset)
    .map((el) => ({
      ...el,
      change: assetIdToChange[el.asset] ?? { metrics: {} },
      asset: assetIdToAsset[el.asset],
      clusters: assetIdToClusters[el.asset] ?? {},
    }));

  return { data: { assets: finalAssets, userGroups: assets.assetGroups.userGroups }, loading: false };
};

export const isAllowedClusterTypes = (cluster: unknown): cluster is 'genie' | 'user' => {
  if (typeof cluster !== 'string') {
    return false;
  }
  return ['user', 'genie'].includes(cluster);
};
export const getStrategy = (
  colorScheme: 'dark' | 'light',
  theme: Theme,
  clusterType: string,
  currentCluster: string
): ((assets: AssetGroupElement[]) => AssetListGroup) => {
  if (clusterType === 'genie') {
    return findGroupAssignmentStrategy(colorScheme, theme, currentCluster, false);
  }
  return assignByClusterForRows<AssetGroupElement>(colorScheme, currentCluster, getMarketCap, false);
};
export const createLink = (tab: Tab & { type: string }): string => `/app/market/clusters/${tab.type}/${tab.link}`;
