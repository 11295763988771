import { Box, Stack, Typography as JoyTypography } from '@mui/joy';
import { type FunctionComponent, useContext, useEffect } from 'react';
import { useForm, type UseFormReset } from 'react-hook-form';
import { formatCash } from 'components/formatter.utils.ts';
import Filter from 'components/technical/Filter/Filter';
import GFormProvider from 'components/technical/form/GFormProvider';

import { GraphQLErrorCardMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import Button from 'components/technical/inputs/GButton/GButton.tsx';
import Loader from 'components/technical/Loader/Loader';
import type { DrawerFiltersData, MarketFiltersState } from './Filters.types.ts';
import { arrayToMinMaxObjectScaleUp, log10ScaleDown, pow10ScaleUp, scaleFilterValuesDown } from './Filters.utils.ts';
import { type ContextValue, MarketFiltersContext } from './MarketFiltersContext.tsx';
import { getNameWithTooltip } from '../../../metrics/MetricsData';

const getLabel = (value: number): string => formatCash(pow10ScaleUp(value));

const FiltersDrawer: FunctionComponent<{ open: boolean }> = ({ open }: { open: boolean }) => {
  const context = useContext(MarketFiltersContext);
  if (context.state && context.defaultState) {
    return (
      <InitializedFiltersDrawer
        open={open}
        context={{
          state: context.state,
          defaultState: context.defaultState,
          setState: context.setState,
        }}
      />
    );
  }

  return <div>{context.error ? <GraphQLErrorCardMessage error={context.error} /> : <Loader />}</div>;
};

function resetFormValues(reset: UseFormReset<DrawerFiltersData>, resetTo: MarketFiltersState): void {
  reset(scaleFilterValuesDown(resetTo));
}

const InitializedFiltersDrawer: FunctionComponent<{
  context: Required<Pick<ContextValue, 'state' | 'setState' | 'defaultState'>>;
  open: boolean;
}> = ({ context, open }) => {
  const methods = useForm<DrawerFiltersData>({
    defaultValues: scaleFilterValuesDown(context.state),
  });

  const handleFiltersSubmit = (data: DrawerFiltersData): void => {
    context.setState({
      liquidity: arrayToMinMaxObjectScaleUp(data.liquidity),
      totalValueLocked: arrayToMinMaxObjectScaleUp(data.totalValueLocked),
      marketCap: arrayToMinMaxObjectScaleUp(data.marketCap),
    });
  };

  const defValue = context.defaultState;

  useEffect(() => {
    if (open) {
      return;
    }

    resetFormValues(methods.reset, context.state);
  }, [context, open, methods]);

  return (
    <GFormProvider {...methods}>
      <Stack
        spacing={1}
        sx={{
          height: '100%',
        }}
      >
        <Stack
          spacing={1.5}
          sx={{
            overflow: 'auto',
          }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1.5}>
            <JoyTypography color="neutral" level="body-md">
              Filter constraints
            </JoyTypography>
            <Button onClick={(): void => resetFormValues(methods.reset, defValue)} variant="plain">
              <JoyTypography color="primary">Reset filters</JoyTypography>
            </Button>
          </Stack>
          <Box
            sx={{
              borderRadius: 'radius.xs',
              mb: 2,
            }}
          >
            <Stack spacing={4}>
              {defValue.marketCap && (
                <Filter
                  name="marketCap"
                  title={getNameWithTooltip('met:market_cap')}
                  getLabel={getLabel}
                  min={log10ScaleDown(defValue.marketCap.min)}
                  max={log10ScaleDown(defValue.marketCap.max)}
                />
              )}
              {defValue.totalValueLocked && (
                <Filter
                  name="totalValueLocked"
                  title={getNameWithTooltip('met:total_val_locked')}
                  getLabel={getLabel}
                  min={log10ScaleDown(defValue.totalValueLocked.min)}
                  max={log10ScaleDown(defValue.totalValueLocked.max)}
                />
              )}
              {defValue.liquidity && (
                <Filter
                  name="liquidity"
                  title="Liquidity"
                  getLabel={getLabel}
                  min={log10ScaleDown(defValue.liquidity.min)}
                  max={log10ScaleDown(defValue.liquidity.max)}
                />
              )}
            </Stack>
          </Box>
          <Button onClick={methods.handleSubmit(handleFiltersSubmit)}>Apply filter</Button>
        </Stack>
      </Stack>
    </GFormProvider>
  );
};
export default FiltersDrawer;
