import { Tab, TabPanel, Tabs, TabList } from '@mui/joy';
import AssetScreenerTab from './AssetScreenerTab';
import MultifactorsTab from './MultifactorsTab';
import type { ReactNode } from 'react';

function AssetScreenerDashboard(): ReactNode {
  return (
    <Tabs aria-label="Tabs" defaultValue={0} sx={{ height: '100%' }}>
      <TabList>
        <Tab>Asset screener</Tab>
        <Tab>Multifactors</Tab>
      </TabList>
      <TabPanel value={0}>
        <AssetScreenerTab />
      </TabPanel>
      <TabPanel value={1}>
        <MultifactorsTab />
      </TabPanel>
    </Tabs>
  );
}

export default AssetScreenerDashboard;
