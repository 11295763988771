import { Add } from '@mui/icons-material';
import { Modal, ModalDialog, Stack } from '@mui/joy';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/fp/cloneDeep';
import { bignumber } from 'mathjs';
import { type ReactElement, useEffect } from 'react';
import { useFieldArray, useForm, type UseFormReturn, useWatch } from 'react-hook-form';

import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import ErrorMessage from 'components/technical/ErrorMessage';
import { FormDateTimeInput } from 'components/technical/form/FormDateTimeInput';
import FormInput from 'components/technical/form/FormInput';
import FormSelect from 'components/technical/form/FormSelect';
import FormStaticSingleAutocomplete, {
  type FormStaticAutocompleteProps,
} from 'components/technical/form/FormStaticSingleAutocomplete';
import GFormProvider from 'components/technical/form/GFormProvider';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton.tsx';
import { useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError.tsx';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import { Header3 } from 'components/technical/Header3';
import GButton from 'components/technical/inputs/GButton/GButton';
import { createRequestInput } from './CreateInvestment.requestInputFactory';
import {
  calculateRemainingVestedAmount,
  type FormInputFields,
  type FormOutputFields,
  formSchema,
} from './CreateInvestment.validation';
import { CreateInvestmentScheduleList } from './CreateInvestmentScheduleList';
import {
  type IAsset,
  IAssetType,
  IPeriodUnit,
  IVestingType,
  useCreateInvestmentMutation,
} from '../../../generated/graphql';
import { type Asset, getAssets } from '../../market/asset/Asset.types';
import { createAssetSelectOptions } from '../../market/asset/AssetService';
import {
  type CreateSubAccountIdAutocompleteOptionsInputAccount,
  createSubAccountIdSelectOptions,
} from '../../portfolio/account/AccountService.tsx';
import { useValueChanged } from '../../UseValueChanged';

const AssetValueInputs = (props: {
  prefix: 'buy' | 'sell' | 'fee';
  assetOptions: Pick<FormStaticAutocompleteProps<Asset>, 'options' | 'isValueEqual' | 'optionHeight'>;
  error: string | undefined;
  methods: UseFormReturn<FormInputFields, unknown>;
}): ReactElement => {
  return (
    <Stack spacing={1}>
      <Stack direction="row" flexWrap="wrap" spacing={1.5}>
        <FormStaticSingleAutocomplete<FormInputFields>
          {...props.assetOptions}
          name={`${props.prefix}.asset`}
          label="Asset"
          width="xl2"
          limitTags={2}
        />
        <FormInput<FormInputFields> type="number" name={`${props.prefix}.amount` as const} label="Amount" width="xl2" />
        <FormInput<FormInputFields>
          type="number"
          name={`${props.prefix}.marketValue` as const}
          label="Total market value"
          startDecorator="$"
          width="xl2"
        />
      </Stack>
      {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
    </Stack>
  );
};

const DEFAULT_IMMEDIATE_SCHEDULE: FormInputFields['schedules'][number]['immediate'] = {
  date: null,
};

const DEFAULT_RECURRING_SCHEDULE: FormInputFields['schedules'][number]['recurring'] = {
  period: {
    unit: IPeriodUnit.Year,
    interval: '5',
  },
  begin: null,
  end: null,
};

const DEFAULT_SCHEDULE = {
  type: IVestingType.Recurring,
  recurring: DEFAULT_RECURRING_SCHEDULE,
  immediate: DEFAULT_IMMEDIATE_SCHEDULE,
  vested: '',
} satisfies FormInputFields['schedules'][number];

const CreateInvestmentForm = (props: {
  onClose: () => void;
  onAdded: () => void;
  accounts: CreateSubAccountIdAutocompleteOptionsInputAccount[];
  assets: Asset[];
}): ReactElement => {
  const methods = useForm<FormInputFields>({
    resolver: gYupResolver(formSchema),
    defaultValues: {
      name: '',
      subAccount: '',
      executedAt: null,
      buy: {
        asset: null,
        amount: '',
        marketValue: '',
      },
      sell: {
        asset: null,
        amount: '',
        marketValue: '',
      },
      fee: {
        asset: null,
        amount: '',
        marketValue: '',
      },
      schedules: [cloneDeep(DEFAULT_SCHEDULE)],
    },
  });

  const subAccountOptions = createSubAccountIdSelectOptions(props.accounts);
  const privateAndPublicAssetOptions = createAssetSelectOptions(props.assets);
  const privateAssetOptions = createAssetSelectOptions(
    getAssets(props.assets).filter((asset) => asset.type === IAssetType.Private)
  );

  const { onErrorAndThrow } = useGraphQLApiError(methods);
  const { showSuccessMessage } = useFeedback();
  const [createInvestment] = useCreateInvestmentMutation();

  const handleFormSubmit = async (input: FormInputFields): Promise<void> => {
    const data = input as unknown as FormOutputFields;

    try {
      await createInvestment({
        variables: {
          input: createRequestInput(data),
        },
      });

      showSuccessMessage('New investment successfully added');

      props.onAdded();
    } catch (e) {
      onErrorAndThrow(e);
    }
  };

  const validateFields = methods.formState.isSubmitted;
  const buyAmount = useWatch<FormInputFields>({
    name: 'buy.amount',
    control: methods.control,
  });

  const trigger = methods.trigger;
  const buyAmountChanged = useValueChanged(buyAmount);
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    trigger('remainingVestedAmount');
  }, [buyAmountChanged, trigger]);

  const executedAt = useWatch<FormInputFields>({
    name: 'executedAt',
    control: methods.control,
  });

  const scheduleLengthError = methods.getFieldState('scheduleLength', methods.formState).error;

  const {
    fields: schedules,
    append,
    remove,
  } = useFieldArray<FormInputFields>({
    control: methods.control,
    name: 'schedules',
  });

  const executedAtChanged = useValueChanged(executedAt);
  const schedulesNum = schedules.length;
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (validateFields) {
      for (let i = 0; i < schedulesNum; i++) {
        trigger(`schedules.${i}.immediate.date`);
        trigger(`schedules.${i}.recurring.begin`);
        trigger(`schedules.${i}.recurring.end`);
      }

      trigger('remainingVestedAmount');
      trigger('scheduleLength');
    }
  }, [executedAtChanged, trigger, validateFields, schedulesNum]);

  const scheduleInputs = useWatch({
    name: 'schedules',
    control: methods.control,
  });

  const buy = useWatch({
    name: 'buy',
    control: methods.control,
  });

  const remainingVestedAmount = calculateRemainingVestedAmount(scheduleInputs, buy);

  return (
    <GFormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
        <Stack spacing={3}>
          <Stack spacing={1}>
            <div>
              <Header3 title="General information" />
              <Stack direction="row" flexWrap="wrap" spacing={1.5}>
                <FormInput<FormInputFields> type="text" name="name" label="Name" width="xl2" />
                <FormSelect<FormInputFields>
                  options={subAccountOptions}
                  name="subAccount"
                  label="Sub-account"
                  width="xl2"
                />
                <FormDateTimeInput<FormInputFields>
                  label="Date and time (UTC)"
                  name="executedAt"
                  maxDate={dayjs.utc()}
                  width="xl2"
                />
              </Stack>
            </div>
            <div>
              <Header3 title="Transaction details" />
              <Header3 title="Buy" />
              <AssetValueInputs
                prefix="buy"
                assetOptions={privateAssetOptions}
                error={methods.getFieldState('buy', methods.formState).error?.message}
                methods={methods}
              />
            </div>
            <div>
              <Header3 title="Sell" />
              <AssetValueInputs
                prefix="sell"
                assetOptions={privateAndPublicAssetOptions}
                error={methods.getFieldState('sell', methods.formState).error?.message}
                methods={methods}
              />
            </div>
            <div>
              <Header3 title="Fee" />
              <AssetValueInputs
                prefix="fee"
                assetOptions={privateAndPublicAssetOptions}
                error={methods.getFieldState('fee', methods.formState).error?.message}
                methods={methods}
              />
            </div>
            <div>
              <Header3 title="Vesting schedule" />
              <Stack spacing={1.5}>
                <CreateInvestmentScheduleList schedules={schedules} methods={methods} remove={remove} />
                <GButton
                  variant="plain"
                  width="normal"
                  disabled={methods.formState.isSubmitting}
                  onClick={(): void => {
                    append(cloneDeep(DEFAULT_SCHEDULE));
                  }}
                  startDecorator={<Add />}
                >
                  Add new schedule
                </GButton>

                {validateFields && scheduleLengthError && <ErrorMessage>{scheduleLengthError.message}</ErrorMessage>}
                {!!buy?.asset && remainingVestedAmount !== undefined && !bignumber(remainingVestedAmount).isZero() && (
                  <ErrorMessage>
                    {remainingVestedAmount > 0 ? (
                      <>
                        Remaining vesting amount to distribute: {remainingVestedAmount} {buy.asset.symbol}
                      </>
                    ) : (
                      <>
                        Too much amount distributed in vesting schedule: {remainingVestedAmount * -1} {buy.asset.symbol}
                      </>
                    )}
                  </ErrorMessage>
                )}
              </Stack>
            </div>
          </Stack>
          <Stack alignItems="center">
            <GraphQLApiFormErrorMessage />
            <SubmitButton width="xl3" startDecorator={<Add />}>
              Add investment
            </SubmitButton>
          </Stack>
        </Stack>
      </form>
    </GFormProvider>
  );
};

const CreateInvestmentDialogContainer = ({
  assets,
  ...props
}: {
  onClose: () => void;
  onAdded: () => void;
  accounts: CreateSubAccountIdAutocompleteOptionsInputAccount[];
  assets: Omit<IAsset, 'derivativeDetails' | 'unvestedAsset' | 'priceAsset'>[];
}): ReactElement => {
  return (
    <Modal open={true} onClose={props.onClose}>
      <ModalDialog size={'lg'}>
        <GDialogHeader>Add new investment</GDialogHeader>
        <CreateInvestmentForm {...props} assets={getAssets(assets)} />
      </ModalDialog>
    </Modal>
  );
};

export default CreateInvestmentDialogContainer;
