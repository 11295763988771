import { Card } from '@mui/joy';
import type { ReactElement } from 'react';
import HeaderBar from '../HeaderBar/HeaderBar';
import GRadioGroup from '../inputs/GRadioGroup/GRadioGroup';
import SectionColumn from '../layout/Column/SectionColumn';

// biome-ignore lint/suspicious/noExplicitAny:
const showRadio = (prop: any): ReactElement => {
  const options = [
    { value: 'text', label: 'label 1', key: 'key1' },
    { value: 'text2', label: 'label 2', key: 'key2' },
    { value: 'text3', label: 'label 3', key: 'key3' },
  ];

  return (
    <>
      <GRadioGroup label="label" value="text" name="test" error="error" options={options} {...prop} />
    </>
  );
};

const RadioPlayground = (): ReactElement => {
  return (
    <SectionColumn>
      <HeaderBar title="Radio" />
      <Card
        sx={{
          alignItems: 'center',
          display: 'grid',
          gridTemplateColumns: '200px 1fr',
          gap: '1rem',
        }}
      >
        <span>State</span>
        <span>Radio</span>

        <span>No value selected and label</span>
        {showRadio({ error: '', value: undefined })}

        <span>Selected and label</span>
        {showRadio({ error: '' })}

        <span>Label and error</span>
        {showRadio({ value: undefined })}

        <span>Only error, no label</span>
        {showRadio({ value: undefined, error: 'error', label: '' })}
      </Card>
    </SectionColumn>
  );
};

export default RadioPlayground;
