import { CanRequestPortfolioSnapshotDocument, useRequestPortfolioSnapshotMutation } from 'generated/graphql';

export const useRequestSubAccountSnapshot = (): (() => void) => {
  const [sync] = useRequestPortfolioSnapshotMutation({
    ignoreResults: true,
    refetchQueries: [CanRequestPortfolioSnapshotDocument],
  });

  return sync;
};
