import { Tab, TabList, TabPanel, Tabs } from '@mui/joy';
import type { ReactElement } from 'react';
import type { Row } from '@tanstack/table-core';
import type { Transaction } from './Transaction.types.ts';
import TransactionDetailsTab from './TransactionDetailsTab.tsx';
import TransactionLegTab from './TransactionLegTab.tsx';

const TransactionDetailsPanel = ({ row }: { row: Row<Transaction> }): ReactElement => {
  return (
    <Tabs defaultValue={0}>
      <TabList>
        <Tab>Details</Tab>
        <Tab>Legs</Tab>
      </TabList>
      <TabPanel value={0}>
        <TransactionDetailsTab row={row} />
      </TabPanel>
      <TabPanel value={1}>
        <TransactionLegTab row={row} />
      </TabPanel>
    </Tabs>
  );
};

export default TransactionDetailsPanel;
