import type { ColDef, ColGroupDef, ValueGetterParams } from 'ag-grid-enterprise';
import type { Solution } from './Results.types.ts';

export type Measure = Solution['measures'][number];
export type MeasureWithSolutionToCompare = {
  solutionAssetMeasure?: Measure;
  solutionToCompareAssetMeasure?: Measure;
};

export const createSolutionColumn = <T extends MeasureWithSolutionToCompare>(
  solutionName: string,
  fieldPrefix: 'solutionAssetMeasure' | 'solutionToCompareAssetMeasure'
): ColGroupDef<T> & ColDef<T> => {
  return {
    headerName: solutionName,
    colId: `solution-${fieldPrefix}`,
    marryChildren: true,
    children: [
      {
        headerName: 'Percentage',
        // biome-ignore lint/suspicious/noExplicitAny:
        field: `${fieldPrefix}.cashWeight` as any,
        type: ['numericColumn', 'percentageColumn'],
        initialAggFunc: 'sum',
        initialSort: 'desc',
      },
      {
        headerName: 'Value',
        // biome-ignore lint/suspicious/noExplicitAny:
        field: `${fieldPrefix}.amount` as any,
        type: ['numericColumn', 'cashColumn'],
        initialAggFunc: 'sum',
      },
    ],
  };
};

export const createDiffColumn = <T extends MeasureWithSolutionToCompare>(): ColGroupDef<T> & ColDef<T> => ({
  headerName: 'Difference',
  colId: 'diff',
  marryChildren: true,
  children: [
    {
      headerName: 'Percentage',
      valueGetter: (params: ValueGetterParams<T>): number | undefined => {
        if (!params.data) {
          return undefined;
        }
        const cswToCompare = params.data.solutionToCompareAssetMeasure?.cashWeight ?? 0;
        const csw = params.data.solutionAssetMeasure?.cashWeight ?? 0;

        return csw - cswToCompare;
      },
      type: ['numericColumn', 'percentageColumn'],
      initialAggFunc: 'sum',
    },
    {
      headerName: 'Value',
      valueGetter: (params: ValueGetterParams<T>): number | undefined => {
        if (!params.data) {
          return undefined;
        }
        const amountToCompare = params.data.solutionToCompareAssetMeasure?.amount ?? 0;
        const amount = params.data.solutionAssetMeasure?.amount ?? 0;

        return amount - amountToCompare;
      },
      type: ['numericColumn', 'cashColumn'],
      initialAggFunc: 'sum',
    },
  ],
});
