import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Stack, Typography, Typography as JoyTypography } from '@mui/joy';
import dayjs from 'dayjs';
import type { FunctionComponent } from 'react';

import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import GLink from 'components/technical/GLink/GLink';
import { getIconBySentiment } from './SentimentService';
import { type IBulletPoint, type IReaction, useUpdateBulletPointReactionMutation } from '../../generated/graphql';

import { Reaction } from '../technical/Reaction.tsx';

const iconSizeProp = { fontSize: 'small' as const };

export const NewsTile: FunctionComponent<{
  item: Pick<
    IBulletPoint,
    'reaction' | 'id' | 'summary' | 'url' | 'newsFeed' | 'sentiment' | 'publishedAt' | 'isTrending'
  >;
  updateItem: (id: string, newReaction: IReaction | null) => void;
}> = ({ item, updateItem }) => {
  const [updateReaction, { loading }] = useUpdateBulletPointReactionMutation();
  const { showGraphqlError } = useFeedback();

  const handleUpdateReaction = async (newReaction: IReaction | null | undefined): Promise<void> => {
    updateItem(item.id, newReaction ?? null);
    try {
      await updateReaction({
        variables: { bulletPointReactionInput: { bulletPointId: item.id, reaction: newReaction ?? null } },
      });
    } catch (e) {
      updateItem(item.id, item.reaction ?? null);
      showGraphqlError(e);
    }
  };

  return (
    <Typography level="body-xs" component="div">
      <Stack direction="row" justifyContent="space-between" spacing={0.5} mb={0.75}>
        <Stack spacing={1}>
          <JoyTypography textColor="text.primary" textAlign="justify">
            {item.summary}
          </JoyTypography>
        </Stack>
        <Reaction
          loading={loading}
          reaction={item.reaction}
          onUpdated={handleUpdateReaction}
          iconFontSize={iconSizeProp.fontSize}
        />
      </Stack>
      <Stack direction="row" spacing={0.75} alignItems="center" flexWrap="wrap">
        <GLink
          to={item.url}
          alignItems="center"
          target="_blank"
          fontSize="sm"
          startDecorator={<OpenInNewIcon fontSize="inherit" />}
        >
          {item.newsFeed && <Typography level="body-xs">{item.newsFeed.humanName}</Typography>}
        </GLink>
        <span>•</span>
        <span>{dayjs(item.publishedAt).fromNow()}</span>
        <span>•</span>
        {item.sentiment && getIconBySentiment(item.sentiment)}
        {item.isTrending && <LocalFireDepartmentIcon {...iconSizeProp} color="error" />}
      </Stack>
    </Typography>
  );
};
