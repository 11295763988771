import * as yup from 'yup';
import type { Schema } from 'yup';

export interface DescriptionStepOutput {
  name: string;
  description: string;
}

export type DescriptionStepInput = DescriptionStepOutput;

export const schema: Schema<unknown> = yup.object({
  name: yup.string().required().min(1),
  description: yup.string(),
});
