import { Box, Breadcrumbs as BreadcrumbsContainer } from '@mui/joy';
import type { FunctionComponent } from 'react';

import { REPLAY_UNMASK_CLASS_NAME } from 'setup';
import { useBreadcrumbMatch } from '../../../routes/useBreadcrumbMatch';
import GLink from '../GLink/GLink';

const Breadcrumbs: FunctionComponent = () => {
  const crumbs = useBreadcrumbMatch();
  return (
    <Box sx={{ display: { xs: 'none', sm: 'block' } }} className={REPLAY_UNMASK_CLASS_NAME}>
      <BreadcrumbsContainer>
        {crumbs.map((crumb, index) =>
          index !== crumbs.length - 1 ? (
            <GLink to={crumb.pathname} key={crumb.breadcrumb}>
              {crumb.breadcrumb}
            </GLink>
          ) : (
            <span key={crumb.breadcrumb}>{crumb.breadcrumb}</span>
          )
        )}
      </BreadcrumbsContainer>
    </Box>
  );
};

export default Breadcrumbs;
