import { Stack } from '@mui/joy';
import type { ReactElement } from 'react';

import LatestCorrelationContainer from './LatestCorrelationContainer';
import ChartWithTitle from '../../../ChartWithTitle.tsx';
import type { PublicAsset } from '../Asset.types';

const LatestCorrelationSection = ({
  metric,
  supportedAssets,
  defaultAssets,
}: {
  metric: Label;
  supportedAssets: PublicAsset[];
  defaultAssets: PublicAsset[];
}): ReactElement => (
  <ChartWithTitle title="Latest correlation" paper>
    <Stack spacing={1.5}>
      <LatestCorrelationContainer metric={metric} supportedAssets={supportedAssets} defaultAssets={defaultAssets} />
    </Stack>
  </ChartWithTitle>
);

export default LatestCorrelationSection;
