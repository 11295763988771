import { Chip, Tooltip } from '@mui/joy';
import type { SvgIconProps as MSvgIconProps } from '@mui/material';
import type { FunctionComponent, PropsWithChildren, ReactElement, ReactNode } from 'react';
import { joyColorsPaletteToMui } from 'theme/colors';
import type { DefaultColorPalette } from '@mui/joy/styles/types';
import type { Icon } from '@tabler/icons-react';

// this Chips is special in such a way that it has fixes width, which doesnt depend on the content
const StatusChip: FunctionComponent<
  PropsWithChildren<{
    size: 'lg' | 'md';
    color: DefaultColorPalette;
    icon: Icon | ((props: MSvgIconProps) => ReactElement);
    tooltip?: string | ReactNode;
    width?: string;
  }>
> = ({ children, color, icon: Icon, size, tooltip, width }) => {
  return (
    <Tooltip title={tooltip}>
      <Chip
        color={color}
        size={size}
        startDecorator={<Icon color={joyColorsPaletteToMui(color)} />}
        sx={{
          '&': {
            width: width,
            minWidth: width,
            padding: '0.25rem 0.375rem',
          },
          '& > .MuiChip-label': {
            flexGrow: '0',
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        {children}
      </Chip>
    </Tooltip>
  );
};

export default StatusChip;
