import { Box, Card } from '@mui/joy';
import { flexRender, getCoreRowModel, getExpandedRowModel, useReactTable } from '@tanstack/react-table';
import { Fragment, type ReactElement, useMemo } from 'react';

import type { GroupedTableProps, GroupedTableRow } from './GroupedTable.props';
import { createTableElementProps, getBackgroundColor, getBorderColor } from './GTable.utils';
import GTableCell from './GTableCell/GTableCell';
import { GTableHead } from './GTableHead';
import TableDividerLine from './GTableDividerLine/TableDividerLine.tsx';

const GroupedTable = <CHILD_ROW, PARENT_ROW extends { subRows?: CHILD_ROW[] }>({
  data,
  columns,
}: GroupedTableProps<CHILD_ROW, PARENT_ROW>): ReactElement => {
  const mappedData: GroupedTableRow<CHILD_ROW, PARENT_ROW>[] = useMemo(
    () =>
      data.map((el) => ({
        parent: true,
        ...el,
      })),
    [data]
  );

  const table = useReactTable<GroupedTableRow<CHILD_ROW, PARENT_ROW>>({
    columns: columns,
    data: mappedData,
    initialState: {
      expanded: true,
    },
    getSubRows: (row: GroupedTableRow<CHILD_ROW, PARENT_ROW>) =>
      row.parent
        ? (row.subRows ?? []).map((row) => ({
            parent: false,
            ...row,
          }))
        : [],
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <Card
      sx={{
        padding: 0,
        background: getBackgroundColor(),
        rowGap: 0,
      }}
    >
      <Box sx={{ width: '100%', minHeight: 0 }}>
        <Box component={'table'} sx={createTableElementProps()}>
          <GTableHead table={table} />
          <tbody>
            {table.getRowModel().rows.map((row, rowIndex, allRows) => {
              return (
                <Fragment key={row.id}>
                  <tr>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td key={cell.id}>
                          {row.original.parent ? (
                            flexRender(cell.column.columnDef.cell, cell.getContext())
                          ) : (
                            <GTableCell>{flexRender(cell.column.columnDef.cell, cell.getContext())}</GTableCell>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  {!row.original.parent && rowIndex !== allRows.length - 1 && (
                    <tr>
                      <td colSpan={row.getVisibleCells().length}>
                        <TableDividerLine color={getBorderColor()} />
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </Box>
      </Box>
    </Card>
  );
};

export default GroupedTable;
