import { Box, Stack, Typography } from '@mui/joy';
import type { ReactElement } from 'react';
import DebugTooltip from 'components/technical/DebugTooltip.tsx';

export const HEIGHT_PX = 32;

export type PortfolioDefinitionLabelInputDefinition = { id?: string; name: string };

const sx = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
} as const;

export const PortfolioDefinitionLabel = ({
  definition,
  plain = false,
}: {
  definition: PortfolioDefinitionLabelInputDefinition;
  plain?: boolean;
}): ReactElement => {
  const textContent = plain ? (
    <Box component={'span'} sx={sx}>
      {definition.name}
    </Box>
  ) : (
    <Typography sx={sx}>{definition.name}</Typography>
  );

  return (
    <DebugTooltip text={definition.id}>
      <Stack direction="row" spacing={0.5} flexWrap="nowrap" alignItems="center">
        {textContent}
      </Stack>
    </DebugTooltip>
  );
};
