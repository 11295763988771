import { Dropdown, MenuButton, useTheme } from '@mui/joy';
import Avatar from '@mui/joy/Avatar';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import { Auth } from 'aws-amplify';
import isNil from 'lodash/fp/isNil';
import { type FunctionComponent, useContext } from 'react';

import { ImpersonationBarContext } from 'components/technical/ImpersonationBarProvider.tsx';
import { useAuth } from '../../../../UseAuth.tsx';
import GLink from '../../GLink/GLink';

const handleLogout = (): void => {
  Auth.signOut();
};

const AppBarProfile: FunctionComponent = () => {
  const { user, localUser, isAdmin, isImpersonating } = useAuth();
  const { setImpersonationBarOpen } = useContext(ImpersonationBarContext);
  const theme = useTheme();

  let initials = (user?.name ?? '')
    .split(' ')
    .map((word) => word.at(0))
    .filter((el) => !isNil(el))
    .join('')
    .toUpperCase();

  if (initials.length === 0) {
    initials = user?.email.slice(0, 3) ?? '';
  }

  return (
    <Dropdown>
      <MenuButton
        slots={{ root: Avatar }}
        slotProps={{
          root: {
            size: 'md',
            color: isImpersonating ? 'danger' : 'primary',
            sx: {
              '&:hover': {
                // align with IconButton hover style as avatar is a bit bigger
                backgroundColor: theme.palette.neutral.plainHoverBg,
              },
            },
          },
        }}
      >
        <IconButton size="md">{initials}</IconButton>
      </MenuButton>
      <Menu placement="bottom-start">
        {(isAdmin || isImpersonating) && <MenuItem onClick={() => setImpersonationBarOpen(true)}>Impersonate</MenuItem>}
        {localUser && (
          // xs: 'none' till we make settings page responsive
          <MenuItem component={GLink} to="/app/management/settings" sx={{ display: { xs: 'none', sm: 'block' } }}>
            Settings
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </Dropdown>
  );
};

export default AppBarProfile;
