import isNil from 'lodash/fp/isNil';
import type { TestContext } from 'yup';

import { isBlankOrNan } from '../../../../validation.ts';

export const greaterThanMin = (value: number | undefined, context: TestContext): boolean => {
  if (isNil(value)) {
    return true;
  }

  const min = context.parent.min;
  if (isBlankOrNan(min)) {
    return true;
  }

  return value >= min;
};

export const smallerThanMax = (value: number | null | undefined, context: TestContext): boolean => {
  if (isNil(value)) {
    return true;
  }

  const max = context.parent.max;
  if (isBlankOrNan(max)) {
    return true;
  }

  return value <= max;
};
