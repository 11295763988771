import isNil from 'lodash/fp/isNil';

class ColorIndexService {
  private categoryIndex: Record<string, Record<string, number>> = {};

  getOrIncreaseCategoryIndex(category: string, categoryValue: string): number {
    const categoryValueIndex = this.categoryIndex[category];
    if (!categoryValueIndex) {
      const value = 0;
      this.categoryIndex[category] = {
        [categoryValue]: value,
      };

      return value;
    }

    const index = categoryValueIndex[categoryValue];
    if (!isNil(index)) {
      return index;
    }

    categoryValueIndex[categoryValue] = Object.keys(categoryValueIndex).length;
    return categoryValueIndex[categoryValue];
  }
}

export default ColorIndexService;
