import { Auth } from 'aws-amplify';
import { useEffect, useRef, useState } from 'react';

const useSetupTotp = (): { code: string; error: Error | null } => {
  const [totpCode, setTOTPCode] = useState('');
  const [error, setError] = useState<null | Error>(null);
  const effectCalled = useRef(false);
  useEffect(() => {
    if (effectCalled.current) {
      return;
    }

    const getToken = async (): Promise<void> => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const code = await Auth.setupTOTP(cognitoUser);
        setTOTPCode(code);
      } catch (e) {
        setError(e as Error);
        effectCalled.current = false;
      }
    };

    // react strict mode hack
    effectCalled.current = true;
    getToken();
  }, []);

  return { code: totpCode, error: error };
};

export default useSetupTotp;
