import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import { Suspense, useState } from 'react';
import MarketRegimeForm from './MarketRegimeForm.tsx';
import { useMarketRegimeInputSuspenseQuery } from 'generated/graphql.tsx';
import type { FormOutputFields } from './MarketRegimeForm.validation.ts';
import Loader from 'components/technical/Loader/Loader.tsx';
import MarketRegimeResult from './MarketRegimeResult.tsx';

const MarketRegime = () => {
  const { data } = useMarketRegimeInputSuspenseQuery();
  const [formData, setFormData] = useState<FormOutputFields | null>(null);

  return (
    <>
      <HeaderBar title="Market regime" hideTopPadding />
      <MarketRegimeForm
        onSubmit={(val) => setFormData(val)}
        models={data!.regime.availableModels}
        assets={data!.assets.list}
      />
      {formData && (
        <Suspense fallback={<Loader />}>
          <MarketRegimeResult input={formData} />
        </Suspense>
      )}
    </>
  );
};

export default MarketRegime;
