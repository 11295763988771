import * as yup from 'yup';
import type { Schema } from 'yup';
import type { Portfolio } from '../PortfolioOptimizerWizard.tsx';

export interface PortfolioUniverseStepOutput {
  universe: Portfolio[];
}

export interface PortfolioUniverseStepInput {
  universe: Portfolio[];
}

export const schema: Schema<unknown> = yup.object({
  universe: yup
    .array(yup.object().required())
    .required('At least one portfolio is required')
    .min(1, 'At least one portfolio is required'),
  givenPortfolio: yup.mixed(),
});
