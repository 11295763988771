import { UploadFile } from '@mui/icons-material';
import type { ButtonProps } from '@mui/joy';
import { type ChangeEvent, type FunctionComponent, type PropsWithChildren, useRef } from 'react';
import GButton from './GButton/GButton';
import type { GButtonProps } from './GButton/GButton.props';

type FileUploadButtonProps = {
  onFileSelect: (file: File) => void;
  width?: GButtonProps['width'];
} & ButtonProps;

/**
 * @deprecated use FileUploadButton instead. This components is too low level and requires further postprocessing,
 * whereas FileUploadButton gives you a handle to already uploaded file
 */
const FileUploadInput: FunctionComponent<PropsWithChildren<FileUploadButtonProps>> = ({
  onFileSelect,
  children,
  ...props
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = (): void => {
    fileInputRef.current?.click();
  };

  const handleFileInput = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files?.[0]) {
      onFileSelect(event.target.files[0]);
      event.target.value = '';
    }
  };

  return (
    <>
      <input type="file" ref={fileInputRef} onChange={handleFileInput} style={{ display: 'none' }} />
      <GButton variant="outlined" onClick={handleButtonClick} startDecorator={<UploadFile />} {...props}>
        {children}
      </GButton>
    </>
  );
};

export default FileUploadInput;
