export const iconVariantToPx = (variant: IconVariant): number => {
  switch (variant) {
    case IconVariant.SMALL:
      return 17.6;
    case IconVariant.MEDIUM:
      return 20;
    case IconVariant.LARGE:
      return 24;
    case IconVariant.XL:
      return 32;
    case IconVariant.XXL:
      return 46.4;
    case IconVariant.BIGGEST:
      return 56;
  }
};

export enum IconVariant {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XL = 'extraLarge',
  XXL = 'extraExtraLarge',
  BIGGEST = 'biggest',
}

export const createAssetLink = ({ id }: { id: string }): string => {
  return `/app/market/assets/${id}`;
};
