import type { StepConfig } from './StepConfig.ts';
import { usePrevious } from '../../UsePrevious.tsx';
import { useValueChanged } from '../../UseValueChanged.tsx';
import type { FormState } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import isNil from 'lodash/fp/isNil';
import get from 'lodash/fp/get';

export interface ValidateVisitedStepsInput {
  // biome-ignore lint/suspicious/noExplicitAny:
  trigger: (fields: any[]) => void;
  // biome-ignore lint/suspicious/noExplicitAny:
  steps: StepConfig<any>[];
}

export interface GetStepStateInput {
  // biome-ignore lint/suspicious/noExplicitAny:
  steps: StepConfig<any>[];
  index: number;
  // biome-ignore lint/suspicious/noExplicitAny:
  formState: FormState<any>;
}

export const useSteps = (): {
  activeStep: number;
  validateVisitedSteps: (input: ValidateVisitedStepsInput) => void;
  goToStep: (index: number) => void;
  getStepState: (input: GetStepStateInput) => { completed: boolean; hasErrors: boolean; visited: boolean };
} => {
  const [{ activeStep, visitedStepAndLeft }, setStepState] = useState<{
    activeStep: number;
    visitedStepAndLeft: Record<number, boolean>;
  }>({
    activeStep: 0,
    visitedStepAndLeft: {},
  });

  const goToStep = useCallback((index: number): void => {
    setStepState((prev) => ({
      activeStep: index,
      visitedStepAndLeft: {
        ...prev.visitedStepAndLeft,
        [prev.activeStep]: true,
      },
    }));
  }, []);

  const previousStep = usePrevious(activeStep);
  const stepChanged = useValueChanged(activeStep);
  const validateVisitedSteps = ({ trigger, steps }: ValidateVisitedStepsInput): void => {
    // biome-ignore lint/correctness/useHookAtTopLevel:
    useEffect(() => {
      if (isNil(previousStep) || !stepChanged) {
        return;
      }

      trigger(steps[previousStep].fields as readonly unknown[] as string[]);
    }, [steps, trigger]);
  };

  const getStepState = ({
    steps,
    index,
    formState,
  }: GetStepStateInput): { completed: boolean; hasErrors: boolean; visited: boolean } => {
    const fields = steps[index]?.fields;
    const hasFieldError = fields.some((field) => !isNil(get(field, formState.errors)));
    return {
      hasErrors: hasFieldError && (visitedStepAndLeft[index] || activeStep === index || formState.isSubmitted),
      completed: !hasFieldError && activeStep !== index && (visitedStepAndLeft[index] || formState.isSubmitted),
      visited: visitedStepAndLeft[index],
    };
  };

  return {
    activeStep,
    goToStep,
    validateVisitedSteps,
    getStepState,
  };
};
