export const assetScreenerDefaultPresets = [
  {
    name: 'Default',
    isDefault: true,
    settings: {
      filters: {},
      pivotMode: false,
      columns: [
        {
          colId: 'name',
          width: 195,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'symbol',
          width: 160,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'cluster-sector',
          width: 201,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'cluster-category',
          width: 209,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'cluster-mcap_groups',
          width: 182,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'cluster-Favourites',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:market_cap',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:total_val_locked',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:volume-24h',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:liquidity',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:circulating_supply',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:current_supply',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:max_supply',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:network_val_adj_transfer_val',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:volume-24h_1',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'past-price-Latest',
          width: 140,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'past-price-24h',
          width: 140,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'past-price-MTD',
          width: 139,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'past-price-QTD',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'past-price-YTD',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'price-change-24h',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'price-change-MTD',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'price-change-QTD',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'price-change-YTD',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:expanding_max_drawdown',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_max_drawdown-365',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_volatility-30',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_beta_btc-30',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_beta_eth-30',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_sharpe-30',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_sortino-30',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_var95-30',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_var99-30',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_skewness-30',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_kurtosis-30',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_volatility-60',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_beta_btc-60',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_beta_eth-60',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_sharpe-60',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_sortino-60',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_var95-60',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_var99-60',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_skewness-60',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_kurtosis-60',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_volatility-90',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_beta_btc-90',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_beta_eth-90',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_sharpe-90',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_sortino-90',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_var95-90',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_var99-90',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_skewness-90',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'met:rolling_kurtosis-90',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
      ],
    },
  },
];
