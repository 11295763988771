import { Tooltip, Typography } from '@mui/joy';
import { isNil } from 'lodash/fp';
import { bignumber } from 'mathjs';
import type { FunctionComponent } from 'react';

import { formatCash, formatNumber } from '../formatter.utils';

const getFormat = (format: 'cash' | 'number'): ((val: number, variant: 'long' | 'normal' | 'short') => string) => {
  if (format === 'number') {
    return formatNumber;
  }
  return formatCash;
};

const PreciseTooltip: FunctionComponent<{
  value: number | string | undefined | null;
  format?: 'cash' | 'number';
}> = ({ format, value }) => {
  const formatter = getFormat(format ?? 'number');

  const formattedValue = !isNil(value) ? formatter(bignumber(value).toNumber(), 'normal') : '-';
  const preciseValue = !isNil(value) ? formatter(bignumber(value).toNumber(), 'long') : undefined;

  return (
    <Tooltip title={preciseValue} placement="left">
      <Typography>{formattedValue}</Typography>
    </Tooltip>
  );
};

export default PreciseTooltip;
