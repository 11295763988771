import { isNil } from 'lodash/fp';
import { useContext } from 'react';

import type { DrawerFiltersData, UseMarketFilters } from './Filters.types.ts';
import { scaleFilterValuesDown } from './Filters.utils.ts';
import { MarketFiltersContext } from './MarketFiltersContext.tsx';

const PRECISION = 0.001;

const metricValuesEqual = (metric1: [number, number], metric2: [number, number]): boolean =>
  Math.abs(metric1[0] - metric2[0]) < PRECISION && Math.abs(metric1[1] - metric2[1]) < PRECISION;

export const useMarketFilters = (): UseMarketFilters => {
  const context = useContext(MarketFiltersContext);

  let isDefaultValue = true;

  if (!isNil(context.state) && !isNil(context.defaultState)) {
    // values can be slightly different after scaling down for form and scaling back up to the context
    const stateScaledDown = scaleFilterValuesDown(context.state);
    const defaultScaledDown = scaleFilterValuesDown(context.defaultState);

    isDefaultValue = Object.keys(stateScaledDown).every((key: string) => {
      const stateValue = stateScaledDown[key as keyof DrawerFiltersData];
      const defaultValue = defaultScaledDown[key as keyof DrawerFiltersData];
      return isNil(defaultValue) || isNil(stateValue) || metricValuesEqual(defaultValue, stateValue);
    });
  }

  return {
    loading: context.loading,
    state: context.state,
    error: context.error,
    isFilteredByMarketFilter: !isDefaultValue,
  };
};
