import * as React from "react";
const SvgPhemex = (props) => /* @__PURE__ */ React.createElement("svg", { "xmlns:xodm": "http://www.corel.com/coreldraw/odm/2003", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 2500 2500", xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { y: 0, style: {
  fill: "none"
}, width: 2500, height: 2500 }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#38D102"
}, d: "M472,1875c0,345.2,278.7,625,622.4,625V1250C1094.4,1595.2,815.8,1875,472,1875L472,1875L472,1875z" }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#0B5BE0"
}, d: "M1405.6,1250c0,345.2,278.7,625,622.4,625V625C2028,970.2,1749.4,1250,1405.6,1250L1405.6,1250z M472,625 v1250c343.8,0,622.4-279.8,622.4-625S815.8,625,472,625L472,625L472,625z" })), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#3CEAF3"
}, d: "M1405.6,0v1250c343.8,0,622.4-279.8,622.4-625S1749.4,0,1405.6,0L1405.6,0z" }))));
export default SvgPhemex;
