import { Slider, Stack, Typography as JoyTypography } from '@mui/joy';
import type { ReactElement } from 'react';
import type { SxProps } from '@mui/system';
import widthSx from '../../../width.styles.ts';

export interface SliderProps {
  name?: string;
  min: number;
  max: number;
  getLabel: (value: number) => string;
  showMinMaxValues?: boolean;
  showCurrentValue?: boolean;
  disabled?: boolean;
  step?: number;
  onChange?: ((event: Event, value: number | number[], activeThumb: number) => void) | undefined;
  value?: number | number[];
  width?: 'fullWidth' | 'smaller' | 'small' | 'normal' | 'xl2' | 'xl3' | 'xl4';
}
const MinMaxLabel = (props: { label: string; sx: SxProps }): ReactElement => {
  return (
    <JoyTypography sx={props.sx} color="neutral" level="body-sm">
      {props.label}
    </JoyTypography>
  );
};

const sharedMinMaxSx = {
  position: 'absolute',
  bottom: '-0.5em',
} as const;

function GSlider({
  name,
  min,
  max,
  getLabel,
  showMinMaxValues = false,
  showCurrentValue = true,
  disabled,
  step = 0.1,
  value,
  onChange,
  width = 'fullWidth',
}: SliderProps): ReactElement {
  return (
    <Stack
      sx={{
        position: 'relative',
        ...(width !== 'fullWidth' && widthSx[width]),
      }}
      spacing={0}
    >
      {showMinMaxValues && Array.isArray(value) && (
        <JoyTypography color="neutral" level="body-sm">
          From {getLabel(value[0])} to {getLabel(value[1])}
        </JoyTypography>
      )}
      <Slider
        name={name}
        value={value}
        disabled={disabled}
        size="sm"
        min={min}
        max={max}
        step={step}
        valueLabelFormat={(value: number): string => getLabel(value)}
        valueLabelDisplay={showCurrentValue ? 'on' : 'off'}
        onChange={onChange}
      />
      {showMinMaxValues && (
        <>
          <MinMaxLabel
            label={getLabel(min)}
            sx={{
              ...sharedMinMaxSx,
              left: 0,
            }}
          />
          <MinMaxLabel
            label={getLabel(max)}
            sx={{
              ...sharedMinMaxSx,
              right: 0,
            }}
          />
        </>
      )}
    </Stack>
  );
}

export default GSlider;
