import { Modal, ModalDialog } from '@mui/joy';
import { type ReactElement, Suspense } from 'react';
import {
  MultifactorDocument,
  useMultifactorDetailsSuspenseQuery,
  useSaveEditedMultifactorMutation,
} from 'generated/graphql';
import { useForm } from 'react-hook-form';
import gYupResolver from 'components/technical/form/gYupResolver';
import GFormProvider from 'components/technical/form/GFormProvider';
import { type GraphQlErrorHandler, useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import {
  CREATE_OR_UPDATE_MULTIFACTOR_FORM_SCHEMA,
  type CreateOrUpdateMultifactorFormInputFields,
  type CreateOrUpdateMultifactorFormOutputFields,
} from './AssetScreenerService';
import CreateAndUpdateMultifactorDialogBody from './CreateAndUpdateMultifactorDialogBody';
import Loader from 'components/technical/Loader/Loader';

type UpdateMultifactorDialogProps = {
  onClose: () => void;
  multifactorId: number;
};

const UpdateMultifactorDialog = ({ onClose, multifactorId }: UpdateMultifactorDialogProps): ReactElement => {
  const getMultifactorDetailsQuery = useMultifactorDetailsSuspenseQuery({
    variables: {
      multifactorId,
    },
  });
  const multifactorDetails = getMultifactorDetailsQuery.data.multifactor.getMultifactorDetails;

  const methods = useForm<CreateOrUpdateMultifactorFormInputFields>({
    resolver: gYupResolver(CREATE_OR_UPDATE_MULTIFACTOR_FORM_SCHEMA),
    mode: 'onBlur',
    defaultValues: {
      name: multifactorDetails.multifactor.name,
      description: multifactorDetails.multifactor.description,
      factorWeights: multifactorDetails.factorWeights.map((factorWeight) => {
        const percentageWeight = Number.parseFloat(factorWeight.weight) * 100;
        return {
          ...factorWeight,
          metricId: factorWeight.metricId.toString(),
          weight: percentageWeight.toPrecision(4),
        };
      }),
    },
  });

  const [saveEditedMultifactorMutation] = useSaveEditedMultifactorMutation();
  const { showSuccessMessage } = useFeedback();
  const { onErrorAndThrow } = useGraphQLApiError(methods);

  const handleFormSubmit = async (
    data: CreateOrUpdateMultifactorFormInputFields,
    onErrorAndThrow: GraphQlErrorHandler
  ): Promise<void> => {
    const output = data as unknown as CreateOrUpdateMultifactorFormOutputFields;
    const formattedData = {
      ...output,
      factorWeights: output.factorWeights.map((factorWeight) => ({
        ...factorWeight,
        weight: (factorWeight.weight / 100).toPrecision(8),
      })),
    };
    try {
      await saveEditedMultifactorMutation({
        variables: {
          input: formattedData,
          multifactorId: multifactorDetails.multifactor.id,
        },
        refetchQueries: [MultifactorDocument],
      });

      showSuccessMessage('Multifactor updated successfully');
      onClose();
    } catch (e) {
      onErrorAndThrow(e);
    }
  };

  return (
    <Modal open={true} onClose={onClose}>
      <ModalDialog minWidth="55rem">
        <GFormProvider {...methods}>
          <form onSubmit={methods.handleSubmit((data) => handleFormSubmit(data, onErrorAndThrow))}>
            <Suspense fallback={<Loader />}>
              <CreateAndUpdateMultifactorDialogBody multifactorDetails={multifactorDetails} />
            </Suspense>
          </form>
        </GFormProvider>
      </ModalDialog>
    </Modal>
  );
};

export default UpdateMultifactorDialog;
