import { Card, Grid, Stack } from '@mui/joy';
import bigNumMath from 'bigNumMath';
import { bignumber } from 'mathjs';
import { type FunctionComponent, type ReactElement, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import AddButton from 'components/technical/AddButton';
import GFormProvider from 'components/technical/form/GFormProvider';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import gYupResolver from 'components/technical/form/gYupResolver';
import { Header4 } from 'components/technical/Header4';
import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import Help from 'components/technical/Help/Help';
import { FormInput } from 'components/technical/inputs';
import GButton from 'components/technical/inputs/GButton/GButton';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';

import {
  type AssetWeight,
  ensureDollarValueWithinBounds,
  type FineTunerInputFields,
  schema,
} from './PortfolioBuilder.utils.ts';
import PortfolioBuilderAssetList from './PortfolioBuilderAssetList';
import {
  type IPortfolioBuilderInputQuery,
  IPortfolioDefinitionSubType,
  usePortfolioBuilderInputQuery,
} from '../../../generated/graphql';
import { getPrivateAndPublicAssets, type PublicAsset, type PrivateAsset } from '../../market/asset/Asset.types';
import {
  aggregateSupportedAssetPositions,
  getPublicSpotPositionsWithValue,
} from '../../portfolio/account/SubAccountPositionsService.ts';
import { assetMinPercentageContribution } from '../ImportPortfolioButton';

import ConfirmationDialog from 'components/technical/form/dialog/ConfirmationDialog.tsx';
import { defaultRowSpacing } from 'components/StackSpacing.ts';
import { isNil } from 'lodash/fp';
import { useReportAssetGroup, type UseReportAssetGroupResultLoaded } from 'components/UseReportAssetGroups.tsx';
import PositionsGrid from './PositionsGrid.tsx';
import PositionsSunburst from './PositionsSunburst.tsx';
import { useDebounce } from 'react-use';
import type { GroupWithAssetId } from 'components/portfolio/dashboard/PositionAggregationsService.ts';
import DialogButton from '../../technical/inputs/GButton/DialogButton.tsx';
import { Add } from '@mui/icons-material';
import CreateRebalancedPortfolioDialog from '../lab/definition/rebalancedPortfolio/CreateRebalancedPortfolioDialog.tsx';
import { useNavigate } from 'react-router';
import { useImperativeModal } from '../../technical/ImperativeModal/UseImperativeModal.tsx';

const defaultMaxLeverage = '2';

const getDefaultAsset = (): AssetWeight => ({
  maxLeverage: defaultMaxLeverage,
  dollarValue: '0',
  id: '',
});

const getDefaultValues = (): FineTunerInputFields => ({
  portfolioEquity: '',
  assetWeights: [getDefaultAsset()],
});

type PortfolioBuilderProps = {
  reportAssetGroup: UseReportAssetGroupResultLoaded;
  assetGroups: {
    genieGroups: GroupWithAssetId[];
    userGroups: GroupWithAssetId[];
  };
  assets: IPortfolioBuilderInputQuery['assets']['feature'];
  portfolioPositions: IPortfolioBuilderInputQuery['portfolio']['positions']['positions'];
};

export type BuilderPortfolioPosition = {
  asset: PublicAsset | PrivateAsset;
  weight: number;
  value: number;
};

const positionRecalculationDebounceMs = 200;

const PortfolioBuilder: FunctionComponent<PortfolioBuilderProps> = ({
  assetGroups,
  assets,
  portfolioPositions,
  reportAssetGroup,
}) => {
  const defaultValues = getDefaultValues();
  const navigate = useNavigate();

  const methods = useForm<FineTunerInputFields>({
    resolver: gYupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const { showModal } = useImperativeModal();

  const {
    fields: assetWeightsWithId,
    append,
    remove,
    replace,
  } = useFieldArray<FineTunerInputFields>({
    control: methods.control,
    name: 'assetWeights',
  });

  const [assetWeights, portfolioEquityInput] = useWatch({
    name: ['assetWeights', 'portfolioEquity'],
    control: methods.control,
  });

  const portfolioEquity = bignumber(portfolioEquityInput || 0).abs();
  const showDollarValues = portfolioEquity.toNumber() !== 0;

  const portfolioEquityOrDefault = portfolioEquity.toNumber() || 100;

  const supportedAssets: Array<PublicAsset | PrivateAsset> = getPrivateAndPublicAssets(assets);
  const assetById = new Map(supportedAssets.map((asset) => [asset.id, asset]));

  const [calculatedDebouncedValidPositions, setValidPositions] = useState<BuilderPortfolioPosition[]>([]);

  useDebounce(
    () => {
      const positions = assetWeights
        .map((row) => ({
          asset: assetById.get(row.id),
          value: bignumber(row.dollarValue || 0).toNumber(),
          weight: bignumber(row.dollarValue || 0)
            .div(portfolioEquityOrDefault)
            .toNumber(),
        }))
        .filter((row): row is BuilderPortfolioPosition => !isNil(row.asset) && row.value !== 0);

      setValidPositions(positions);
    },
    positionRecalculationDebounceMs,
    [assetWeights]
  );

  function importFromPortfolio(): void {
    const publicSpotPositions = getPublicSpotPositionsWithValue(portfolioPositions);
    const aggregatedPositions = aggregateSupportedAssetPositions(publicSpotPositions, supportedAssets);

    const equity = bigNumMath.sum(aggregatedPositions.map((asset) => asset.value.abs()));
    const minCashValue = equity.mul(assetMinPercentageContribution);

    const newWeights = aggregatedPositions
      .filter((pos) => pos.value.abs().greaterThan(minCashValue))
      .map((pos) => ({
        id: pos.id,
        maxLeverage: defaultMaxLeverage,
        dollarValue: pos.value.toDP(2).toString(),
      }));

    replace(newWeights);

    methods.setValue('portfolioEquity', equity.toDP(2).toString());
    methods.clearErrors('assetWeights');
  }
  return (
    <Stack spacing={defaultRowSpacing}>
      <HeaderBar title="Portfolio builder">
        <Stack gap={defaultRowSpacing} direction="row">
          <DialogButton
            variant="outlined"
            width="normal"
            disabled={
              calculatedDebouncedValidPositions.length === 0 || Object.keys(methods.formState.errors).length > 0
            }
            renderDialog={({ onClose }): ReactElement => (
              <CreateRebalancedPortfolioDialog
                onClose={onClose}
                onAdded={() => {
                  navigate('/app/copilot/lab/portfolio');
                  onClose();
                }}
                subType={IPortfolioDefinitionSubType.Default}
                composition={calculatedDebouncedValidPositions.map((comp) => ({
                  id: comp.asset.id,
                  weight: comp.weight,
                }))}
              />
            )}
            startDecorator={<Add />}
          >
            Create new portfolio
          </DialogButton>
          <GButton
            variant="solid"
            width="normal"
            onClick={(): void => {
              if (methods.formState.isDirty) {
                showModal(({ onClose }) => (
                  <ConfirmationDialog
                    onClose={onClose}
                    onApprove={async () => {
                      onClose();
                      importFromPortfolio();
                    }}
                  >
                    Operation will override current portfolio composition. Are you sure you want to proceed?
                  </ConfirmationDialog>
                ));
              } else {
                importFromPortfolio();
              }
            }}
          >
            Import from portfolio
          </GButton>
        </Stack>
      </HeaderBar>
      <Card>
        <Grid container spacing={5}>
          <Grid md={12} lg={8}>
            <GFormProvider {...methods}>
              <form>
                <Stack spacing={defaultRowSpacing}>
                  <FormInput<FineTunerInputFields>
                    showLabelAboveInput
                    startDecorator="$"
                    name="portfolioEquity"
                    type="number"
                    width="xl2"
                    label={
                      <>
                        Portfolio equity&nbsp;
                        <Help>Unleveraged amount of funds in the portfolio</Help>
                      </>
                    }
                    onBlur={() =>
                      replace(
                        assetWeights.map((row) => ({
                          ...row,
                          dollarValue: ensureDollarValueWithinBounds(
                            row.dollarValue,
                            row.maxLeverage,
                            portfolioEquityOrDefault
                          ),
                        }))
                      )
                    }
                  />
                  <Header4 title="Portfolio composition" />
                  <Stack spacing={1.5}>
                    <PortfolioBuilderAssetList
                      supportedAssets={supportedAssets}
                      assetWeights={assetWeightsWithId}
                      showDollarValues={showDollarValues}
                      portfolioEquityOrDefault={portfolioEquityOrDefault}
                      onRemove={(index: number): void => remove(index)}
                    />

                    <Stack alignItems="center" spacing={1.5}>
                      <GraphQLApiFormErrorMessage />
                    </Stack>

                    <Stack direction="row" gap={1}>
                      <AddButton
                        width="normal"
                        disabled={methods.formState.isSubmitting}
                        onClick={() => append(getDefaultAsset())}
                      >
                        Add asset
                      </AddButton>
                      <GButton
                        width="normal"
                        disabled={methods.formState.isSubmitting}
                        variant="plain"
                        onClick={() => methods.reset()}
                      >
                        Clear all
                      </GButton>
                    </Stack>
                  </Stack>
                </Stack>
              </form>
            </GFormProvider>
          </Grid>
          <Grid md={12} lg={4}>
            <PositionsSunburst positions={calculatedDebouncedValidPositions} assetGroups={assetGroups} />
          </Grid>
        </Grid>
      </Card>

      <Card sx={{ height: '700px' }}>
        <PositionsGrid positions={calculatedDebouncedValidPositions} reportAssetGroup={reportAssetGroup} />
      </Card>
    </Stack>
  );
};

const PortfolioBuilderContainer = (): ReactElement => {
  const { data: inputData, loaded, Fallback } = useDefaultErrorHandling(usePortfolioBuilderInputQuery());
  const reportAssetGroup = useReportAssetGroup();

  if (!loaded) {
    return <Fallback />;
  }

  if (!reportAssetGroup.loaded) {
    return <reportAssetGroup.Fallback />;
  }

  return (
    <PortfolioBuilder
      assetGroups={inputData.assets.assetGroups}
      portfolioPositions={inputData.portfolio.positions.positions}
      assets={inputData.assets.feature}
      reportAssetGroup={reportAssetGroup}
    />
  );
};

export default PortfolioBuilderContainer;
