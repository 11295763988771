import { Stack } from '@mui/joy';
import type React from 'react';
import { type ReactElement, type ReactNode, useMemo } from 'react';
import type { MetricInput } from './RiskMetricsChart.types.ts';
import RiskMetricsParameters from './RiskMetricsParameters.tsx';
import { type ParameterDescription, validateRiskMetricParameterValues } from './RiskMetricsParameterService.tsx';
import type { AssetLabelInput } from '../../market/asset/AssetLabelService.ts';
import { getNameWithTooltip } from '../../metrics/MetricsData.tsx';
import type { SelectOption } from '../../technical/inputs/Select/Select.props.ts';
import Select from '../../technical/inputs/Select/Select.tsx';
import { calculateDefaultParameters } from '../../portfolio/riskMetrics.utils.ts';
import pickBy from 'lodash/fp/pickBy';

export const calculateParameters = (
  values: Record<string, string | string[] | AssetLabelInput[]>,
  params: ParameterDescription[]
): Record<string, string | string[] | AssetLabelInput[]> => {
  const otherMetricsParamValues = pickBy((_value, key) => !params.map((param) => param.name).includes(key), values);
  const defaultValues = calculateDefaultParameters(params);
  const validationErrors = validateRiskMetricParameterValues(values, params);
  const currentMetricParamValues = Object.fromEntries(
    params.map((param) => {
      if (validationErrors[param.name]) {
        return [param.name, defaultValues[param.name]];
      }
      return [param.name, values[param.name]];
    })
  );
  return {
    ...otherMetricsParamValues,
    ...currentMetricParamValues,
  };
};

export const RiskMetricsChartOptions = (props: {
  metricInput: MetricInput;
  metrics: string[];
  setMetricInput: React.Dispatch<React.SetStateAction<MetricInput>>;
  metricParameters: Record<Label, ParameterDescription[]>;
  extraParams?: ReactNode;
}): ReactElement => {
  const metricOptions: SelectOption<string>[] = useMemo(
    () =>
      props.metrics.map((metric) => ({
        label: getNameWithTooltip(metric),
        value: metric,
        key: metric,
      })),
    [props.metrics]
  );

  return (
    <Stack direction="row" justifyContent="flex-start" flexWrap="wrap" spacing={1.5}>
      <Select
        label="Metric"
        value={props.metricInput.metric}
        showLabelAboveInput
        disableClearable
        onChange={(value): void => {
          props.setMetricInput((state) => ({
            params: calculateParameters(state.params, props.metricParameters[value]),
            metric: value,
          }));
        }}
        options={metricOptions}
        width="small"
      />
      <RiskMetricsParameters
        values={props.metricInput.params}
        metricParameters={props.metricParameters[props.metricInput.metric]}
        setValues={(values: Record<string, string | string[] | AssetLabelInput[]>): void => {
          props.setMetricInput(
            (state: MetricInput): MetricInput => ({
              metric: state.metric,
              params: {
                ...props.metricInput.params,
                ...values,
              },
            })
          );
        }}
      />
      {props.extraParams}
    </Stack>
  );
};
