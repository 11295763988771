import * as yup from 'yup';
import type { ObjectSchema, TestContext } from 'yup';

import type { FormInputType } from 'components/technical/form/Form.types';
import type { GRadioOption } from 'components/technical/inputs/GRadioGroup/GRadioGroup.props';
import {
  type StressTestAssetOutputFields,
  StressTestConstraintType,
  type StressTestOutputFields,
} from './StressTestSimulator.types';
import { translate, yupLocale } from '../../../setup/yupLocale';
import { arrayLengthSchemaFactory, isBlankOrNan } from '../../../validation.ts';
import { calculateAllocationSummary } from '../NormalizeAllocationService';

const assetSchemaFactory = (arrayField: string): yup.ObjectSchema<{ id: string }> =>
  yup
    .object({ id: yup.string().required() })
    .required(translate(yupLocale.mixed.required))
    .test('uniqueId', 'Asset is duplicated', (value: { id: string } | undefined, context: TestContext): boolean => {
      if (value === undefined) {
        return true;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const assets = context.from[2].value[arrayField];
      return (
        assets.filter(
          (item: FormInputType<StressTestAssetOutputFields>) => item.asset?.id && item.asset.id === value.id
        ).length < 2
      );
    });

const numberRequiredSchema = yup
  .number()
  .typeError('Should be a number')
  .required(translate(yupLocale.number.required));

export const stressTestLabels: Record<StressTestConstraintType, string> = {
  [StressTestConstraintType.Cash]: 'Dollars',
  [StressTestConstraintType.Percentage]: 'Percentage',
};

export const stressTestOptions: GRadioOption<StressTestConstraintType>[] = [
  StressTestConstraintType.Cash,
  StressTestConstraintType.Percentage,
].map((val) => ({
  value: val,
  label: stressTestLabels[val],
  key: val.toString(),
}));

export const schema: ObjectSchema<
  Omit<StressTestOutputFields, 'assetsLength' | 'scenarioLength' | 'assetPercentageAllocation'>
> = yup.object({
  portfolioAmount: yup.number().required().positive(),
  assets: yup
    .array(
      yup.object({
        asset: assetSchemaFactory('assets'),
        value: numberRequiredSchema.test('non-zero', 'Cannot be zero', (value: unknown) => {
          if (isBlankOrNan(value)) {
            return true;
          }

          return value !== 0;
        }),
      })
    )
    .required(),
  scenario: yup
    .array(
      yup.object({
        asset: assetSchemaFactory('scenario'),
        value: numberRequiredSchema.min(-100),
      })
    )
    .required(),
  constraintType: yup
    .mixed<StressTestConstraintType>()
    .required(translate(yupLocale.mixed.required))
    .oneOf(Object.values(StressTestConstraintType)),
  assetsLength: arrayLengthSchemaFactory('assets', 'At least one asset is required'),
  scenarioLength: arrayLengthSchemaFactory('scenario', 'At least one asset is required'),
  assetPercentageAllocation: yup.mixed().when(
    ['assets', 'constraintType'],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ([assets, constraintType]) => {
      if (constraintType === StressTestConstraintType.Cash) {
        return yup.mixed().nullable().optional();
      }

      return yup
        .mixed()
        .nullable()
        .optional()
        .test(
          'totalAllocation',
          'Total position allocation should be 100%',
          () =>
            !calculateAllocationSummary(
              assets.map((asset: StressTestAssetOutputFields) => asset.value),
              true
            ).showError
        );
    }
  ),
});
