import { type ReactElement, useMemo } from 'react';

import { getAssetGroupReportMapping } from 'components/UseReportAssetGroups';
import { clusterColumn, nameColumn, symbolColumn } from 'components/technical/grids/SharedReportColumns';
import type { ColDef, ColGroupDef, ValueGetterParams } from 'ag-grid-community';
import GAgGrid from 'components/technical/grids/GAgGrid.tsx';
import type { GroupWithAssetId } from 'components/portfolio/dashboard/PositionAggregationsService.ts';
import { isNil } from 'lodash/fp';
import type { AssetSolution } from './Results.types.ts';
import { createDiffColumn, createSolutionColumn, type Measure } from './PositionGrid.utils.ts';

type AssetMeasureWithSolutionToCompare = {
  asset: Omit<Measure['item'], 'derivativeDetails'>;
  solutionAssetMeasure?: Measure;
  solutionToCompareAssetMeasure?: Measure;
};

const defaultColDef = {
  resizable: true,
  sortable: true,
  filter: true,
};

const AssetOptimizerSolutionPositionsGrid = ({
  solutionName,
  compareToSolution,
  positions,
  assetGroups,
}: {
  solutionName: string;
  compareToSolution: AssetSolution | null;
  positions: AssetMeasureWithSolutionToCompare[];
  assetGroups: {
    genieGroups: GroupWithAssetId[];
    userGroups: GroupWithAssetId[];
  };
}): ReactElement => {
  const columns: (ColDef<AssetMeasureWithSolutionToCompare> | ColGroupDef<AssetMeasureWithSolutionToCompare>)[] =
    useMemo(() => {
      const { clusters, assetAndGroupClusterMapToGroup } = getAssetGroupReportMapping([
        ...assetGroups.genieGroups,
        ...assetGroups.userGroups,
      ]);

      return [
        {
          headerName: 'Asset Details',
          colId: 'asset-details',
          marryChildren: true,
          children: [
            nameColumn({ initialHide: false }),
            symbolColumn({ initialHide: false }),
            ...clusters.map((cluster) =>
              clusterColumn<AssetMeasureWithSolutionToCompare>(cluster, assetAndGroupClusterMapToGroup)
            ),
          ],
        },
        {
          colId: 'side',
          headerName: 'Side',
          type: 'textColumn',
          initialHide: true,
          valueGetter: (params: ValueGetterParams<AssetMeasureWithSolutionToCompare>): string => {
            const amount = params.data?.solutionAssetMeasure?.amount;
            if (isNil(amount) || amount === 0) {
              return '';
            }

            return amount > 0 ? 'Long' : 'Short';
          },
        },
        createSolutionColumn<AssetMeasureWithSolutionToCompare>(solutionName, 'solutionAssetMeasure'),
        ...(compareToSolution
          ? [
              createSolutionColumn<AssetMeasureWithSolutionToCompare>(
                compareToSolution.name,
                'solutionToCompareAssetMeasure'
              ),
              createDiffColumn<AssetMeasureWithSolutionToCompare>(),
            ]
          : []),
      ];
    }, [assetGroups, compareToSolution, solutionName]);

  return (
    <GAgGrid
      rowData={positions}
      autoSizeStrategy={{ type: 'fitCellContents' }}
      sideBar={{ toolPanels: ['columns', 'filters'] }}
      defaultColDef={defaultColDef}
      columnDefs={columns}
    />
  );
};

export default AssetOptimizerSolutionPositionsGrid;
