import { Grid } from '@mui/joy';
import isNil from 'lodash/fp/isNil';
import type { ReactElement, ReactNode } from 'react';

import { KeyValueItem } from 'components/technical/KeyValueItem';
import Tile from 'components/technical/Tile/Tile';
import GValueChange from 'components/technical/ValueChange/GValueChange';
import type { AssetChange } from './SingleAsset.types';
import type { IAssetDetails } from '../../../../generated/graphql';
import { formatCash, formatPercentage } from '../../../formatter.utils';
import { formatMetric, getName, getTooltip } from '../../../metrics/MetricsData';

interface Row {
  label: string;
  value: ReactNode;
  help?: ReactNode;
}

const TileWithList = ({ title, rows }: { title: string; rows: Row[] }): ReactElement => {
  return (
    <Grid xs={12} sm={6} md={4} lg={2}>
      <Tile title={title} fullHeight>
        {rows.map(({ label, value, help }) => (
          <KeyValueItem label={label} help={help} value={value} key={label} />
        ))}
      </Tile>
    </Grid>
  );
};

export const SingleAssetTiles = ({
  asset,
  assetChange,
}: {
  asset: Pick<IAssetDetails, 'metrics' | 'asset'>;
  assetChange: AssetChange;
}): ReactElement => {
  return (
    <Grid container spacing={1.5}>
      <TileWithList
        title="Market cap"
        rows={[
          {
            label: 'Coin m-cap',
            value: formatMetric('met:market_cap', asset.metrics),
            help: getTooltip('met:market_cap'),
          },
        ]}
      />
      <TileWithList
        title="Volume"
        rows={[
          {
            label: 'Total(24h)',
            value: formatMetric('met:volume-24h', asset.metrics),
            help: getTooltip('met:volume-24h'),
          },
          {
            label: 'Change(24h)',
            value: !isNil(assetChange.metrics['met:volume-24h']) ? (
              <GValueChange
                value={Number.parseFloat(assetChange.metrics['met:volume-24h'])}
                text={`${formatPercentage(Number.parseFloat(assetChange.metrics['met:volume-24h']))}`}
              />
            ) : (
              '-'
            ),
            help: <>Percent changes of trading volumes in the last 24h.</>,
          },
        ]}
      />
      <TileWithList
        title="Supply"
        rows={[
          {
            label: 'Current',
            value: formatMetric('met:current_supply', asset.metrics),
            help: getTooltip('met:current_supply'),
          },
          {
            label: 'Circulating',
            value: formatMetric('met:circulating_supply', asset.metrics),
            help: getTooltip('met:circulating_supply'),
          },
          {
            label: 'Max',
            value: formatMetric('met:max_supply', asset.metrics),
            help: getTooltip('met:max_supply'),
          },
        ]}
      />
      <TileWithList
        title="Risk metrics"
        rows={[
          {
            label: getName('met:rolling_volatility-30'),
            value: formatMetric('met:rolling_volatility-30', asset.metrics),
            help: getTooltip('met:rolling_volatility-30'),
          },
          {
            label: getName('met:rolling_sharpe-30'),
            value: formatMetric('met:rolling_sharpe-30', asset.metrics),
            help: getTooltip('met:rolling_sharpe-30'),
          },
          {
            label: getName('met:rolling_sortino-30'),
            value: formatMetric('met:rolling_sortino-30', asset.metrics),
            help: getTooltip('met:rolling_sortino-30'),
          },
        ]}
      />
      <TileWithList
        title="Total value locked"
        rows={[
          {
            label: 'By coin',
            value: formatCash(asset.metrics['met:total_val_locked']),
            help: getTooltip('met:total_val_locked'),
          },
        ]}
      />
      <TileWithList
        title="Activity"
        rows={[
          {
            label: getName('met:active_address_cnt'),
            value: formatMetric('met:active_address_cnt', asset.metrics),
            help: getTooltip('met:active_address_cnt'),
          },
          {
            label: getName('met:network_val_adj_transfer_val'),
            value: formatMetric('met:network_val_adj_transfer_val', asset.metrics),
            help: getTooltip('met:network_val_adj_transfer_val'),
          },
        ]}
      />
    </Grid>
  );
};
