import type { SelectOption } from 'components/technical/inputs/Select/Select.props';

import VenueLabel from './VenueLabel.tsx';
import { IconVariant } from '../market/asset/cryptocurrencies/CryptocurrenciesData';

export const createVenueOptions = (venues: string[]): SelectOption<string>[] => {
  return venues.map((venueLabel) => ({
    label: <VenueLabel venue={venueLabel} format="long" size={IconVariant.MEDIUM} />,
    value: venueLabel,
    key: venueLabel,
  }));
};
