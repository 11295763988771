import { Stack } from '@mui/joy';
import type { ReactElement, ReactNode } from 'react';

import ConnectAccountMessage from './ConnectAccountMessage.tsx';

export const ChartNoAccountFallback = ({
  children,
  refetch,
}: {
  children: ReactNode;
  refetch: () => void;
}): ReactElement => {
  return (
    <Stack sx={{ position: 'relative' }} spacing={1.5}>
      {children}
      <ConnectAccountMessage
        onConnected={(): void => {
          refetch();
        }}
      />
    </Stack>
  );
};
