import type { Schema } from 'yup';
import * as yup from 'yup';
import { isValidFloatString, minFloatString } from '../../../number.utils.ts';

export interface ShockConfiguration {
  enabled: boolean;
  value: string;
}

export interface ShockStepOutput {
  benchmark: {
    id: string;
  };
  shocks: {
    volatilityShock: ShockConfiguration;
    priceShock: ShockConfiguration;
    interestFreeRateShock: ShockConfiguration;
  };
}

const shockSchema: Schema<ShockConfiguration> = yup.object({
  enabled: yup.boolean().required(),
  value: yup
    .string()
    .default('')
    .when('enabled', ([enabled], schema) => (enabled ? schema.required() : schema.transform(() => '')))
    .test('validFloatString', isValidFloatString)
    .test('minValue', minFloatString(-100)),
});

export const schema = yup.object({
  benchmark: yup.mixed().required(),
  shocks: yup.object({
    volatilityShock: shockSchema,
    priceShock: shockSchema,
    interestFreeRateShock: shockSchema,
  }),
});
