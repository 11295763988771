import { Stack } from '@mui/joy';
import sortBy from 'lodash/fp/sortBy';
import { type ReactElement, useMemo, useState } from 'react';

import {
  type HighchartSeries,
  heatmapBorderColor,
  heatmapBorderWidth,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import HighChartsWrapper from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper';
import StaticMultiAutocomplete from 'components/technical/inputs/Autocomplete/StaticMultiAutocomplete';
import { createCorrelation, createCorrelationMap } from './LatestCorrelationService';
import { type ILatestCorrelationsQuery, useLatestCorrelationsQuery } from '../../../../generated/graphql';
import { gradientColorsDarkMode, gradientColorsLightMode } from '../../../../theme/colors';
import { useFinalColorScheme } from '../../../../useFinalColorScheme';
import type { PublicAsset } from '../Asset.types';
import { createAssetSelectOptions } from '../AssetService';
import type * as Highcharts from 'highcharts';
const calculateChartData = (data: ILatestCorrelationsQuery): HighchartSeries[] => {
  const assetMetrics = data?.assets.twoAssetLatestMetrics;
  const assets = Object.fromEntries(
    assetMetrics.flatMap((row) => [row.primaryAsset, row.secondaryAsset]).map((asset) => [asset.id, asset])
  );
  const sortedAssets = sortBy((asset) => asset.name ?? asset.symbol, Object.values(assets));
  const sortedAssetIds = sortedAssets.map((asset) => asset.id);
  const correlationMap = createCorrelationMap(assetMetrics);
  const correlation = createCorrelation(correlationMap, sortedAssetIds);

  return [
    {
      data: correlation.flatMap((_, xCatIndex) => {
        return correlation.map((_, yCatIndex) => {
          const value = correlation[yCatIndex][xCatIndex];

          return {
            x: xCatIndex,
            value: value ?? 0,
            y: yCatIndex,
            textValue: value?.toFixed(2) ?? '',
          };
        });
      }),
      type: 'heatmap',
      dataLabels: {
        enabled: true,
        format: '{point.textValue}',
      },
      borderWidth: heatmapBorderWidth,
      borderColor: heatmapBorderColor,
    },
  ];
};

const calculateOptions = (data: ILatestCorrelationsQuery, colorScheme: 'light' | 'dark'): Highcharts.Options => {
  const assetMetrics = data?.assets.twoAssetLatestMetrics;
  const assets = Object.fromEntries(
    assetMetrics.flatMap((row) => [row.primaryAsset, row.secondaryAsset]).map((asset) => [asset.id, asset])
  );
  const sortedAssets = sortBy((asset) => asset.name ?? asset.symbol, Object.values(assets));
  const x = sortedAssets.map((asset) => asset.name ?? asset.symbol);
  const y = [...x].reverse();

  return {
    colorAxis: {
      min: -1,
      max: 1,
      stops: colorScheme === 'light' ? gradientColorsLightMode : gradientColorsDarkMode,
    },
    xAxis: {
      categories: x,
      opposite: true,
      lineWidth: 0,
    },
    yAxis: {
      categories: y,
      title: {
        text: null,
      },
    },
    tooltip: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
  };
};

const LatestCorrelationChart = ({
  metric,
  selectedAssets,
}: {
  metric: Label;
  selectedAssets: PublicAsset[];
}): ReactElement => {
  const colorScheme = useFinalColorScheme();
  const assetLabels = selectedAssets.map((asset) => asset.label);
  const queryOutput = useLatestCorrelationsQuery({
    variables: {
      input: {
        metric,
        primaryAssets: assetLabels,
        secondaryAssets: assetLabels,
      },
    },
  });

  return (
    <HighChartsWrapper<ILatestCorrelationsQuery>
      {...queryOutput}
      calculateChartData={calculateChartData}
      calculateOptions={(data: ILatestCorrelationsQuery): Highcharts.Options => calculateOptions(data, colorScheme)}
    />
  );
};

const DEFAULT_VISIBLE_ASSETS = 10;
const LatestCorrelationContainer = ({
  metric,
  supportedAssets,
  defaultAssets,
}: {
  metric: Label;
  supportedAssets: PublicAsset[];
  defaultAssets: PublicAsset[];
}): ReactElement => {
  const [selectedAssets, setSelectedAssets] = useState<PublicAsset[]>(defaultAssets.slice(0, DEFAULT_VISIBLE_ASSETS));
  const assetOptions = useMemo(() => createAssetSelectOptions(supportedAssets), [supportedAssets]);

  return (
    <Stack spacing={1}>
      <StaticMultiAutocomplete
        {...assetOptions}
        value={selectedAssets}
        width="xl2"
        onChange={(vals): void => setSelectedAssets(vals)}
        label="Assets"
      />
      <LatestCorrelationChart metric={metric} selectedAssets={selectedAssets} />
    </Stack>
  );
};

export default LatestCorrelationContainer;
