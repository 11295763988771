import type { ReactElement } from 'react';
import { useFieldArray, useFormContext, useFormState } from 'react-hook-form';
import type { PortfolioStepInput, PortfolioStepOutput, StressTestFormAsset } from './PortfoliosStep.validation.tsx';
import type { FormInputType } from '../../../technical/form/Form.types.ts';
import { PortfolioPositionList } from './PortfolioPositionList.tsx';
import { Stack } from '@mui/joy';
import AddButton from '../../../technical/AddButton.tsx';
import { IPositionSide } from '../../../../generated/graphql.tsx';
import ErrorMessage from '../../../technical/ErrorMessage.tsx';
import GButton from '../../../technical/inputs/GButton/GButton.tsx';

export const PortfolioStep = (props: {
  goToNextStep: () => void;
  compositions: {
    name: string;
    composition: {
      asset: StressTestFormAsset;
      weight: number;
      amount: number | string | null | undefined;
      entryPrice: number | string | null | undefined;
    }[];
  }[];
}): ReactElement => {
  const { clearErrors, trigger } = useFormContext();
  const { isSubmitting } = useFormState();
  const { fields, append, remove } = useFieldArray<FormInputType<PortfolioStepOutput>>({
    name: 'portfolios',
  });

  const { errors } = useFormState<PortfolioStepInput>({
    name: 'portfolioLength',
  });

  const portfolioLengthError = errors.portfolioLength?.message;
  return (
    <Stack gap={3}>
      {fields.map((field, index) => {
        return (
          <PortfolioPositionList
            key={field.id}
            index={index}
            portfolioCompositions={props.compositions}
            remove={() => {
              remove(index);
              trigger('portfolioLength');
            }}
          />
        );
      })}
      {portfolioLengthError && <ErrorMessage>{portfolioLengthError}</ErrorMessage>}
      <AddButton
        disabled={isSubmitting}
        width="normal"
        onClick={(): void => {
          append({
            portfolio: [
              {
                asset: null,
                side: IPositionSide.Long,
                amount: null,
                premium: null,
                entryPrice: null,
              },
            ],
          });
          clearErrors('portfolioLength');
        }}
      >
        Add portfolio
      </AddButton>
      <GButton
        onClick={props.goToNextStep}
        sx={{
          marginLeft: 'auto',
        }}
      >
        Next
      </GButton>
    </Stack>
  );
};
