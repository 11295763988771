import { Grid, Skeleton, Stack, Typography } from '@mui/joy';
import type { FunctionComponent, ReactNode } from 'react';

import Tile from 'components/technical/Tile/Tile';

const gridSettings = {
  xs: 12,
  sm: 6,
  lg: 3,
};

type TitleValueTileProps = {
  title: ReactNode;
  value: ReactNode;
  footer?: ReactNode;
  caption?: ReactNode;
  loading: boolean;
};

const TitleValueTile: FunctionComponent<TitleValueTileProps> = ({ title, footer, value, caption, loading }) => {
  if (loading) {
    return (
      <Grid {...gridSettings}>
        <Tile title={<Skeleton level="title-sm" width="30%" variant="text" />} fullHeight>
          <Stack justifyContent="space-between" height="100%" gap={1}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Skeleton level="h2" variant="text" width="50%" />
              <Skeleton level="title-sm" variant="text" width="25%" />
            </Stack>
            <Stack mb={0.5}>
              <Stack direction="row" justifyContent="space-between">
                <Skeleton level="body-xs2" variant="text" width="25%" />
                <Skeleton level="body-xs2" variant="text" width="25%" />
              </Stack>
              <Skeleton height={8} variant="inline" width="50%" />
            </Stack>
          </Stack>
        </Tile>
      </Grid>
    );
  }

  return (
    <Grid {...gridSettings}>
      <Tile title={title} fullHeight>
        <Stack justifyContent="space-between" height="100%">
          <Stack direction="row" alignItems="center" justifyContent="space-between" flexWrap="wrap">
            <Typography level="h2" component="span">
              {value}
            </Typography>
            <Typography level="title-sm" component="div">
              {caption}
            </Typography>
          </Stack>
          {footer}
        </Stack>
      </Tile>
    </Grid>
  );
};

export default TitleValueTile;
