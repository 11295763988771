import dayjs from 'dayjs';
import { useMemo } from 'react';
import { formatISODate } from 'components/formatter.utils.ts';
import { type TFallback, useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import { TupleKeyMap } from 'components/TupleKeyMap.ts';
import { usePricesForPositionsQuery } from 'generated/graphql';

import type { Price } from '../../technical/grids/SharedReportColumns';

export type PriceChangeDates = {
  today: UtcDate;
  firstDayOfMonth: UtcDate;
  firstDayOfQuarter: UtcDate;
  firstDayOfYear: UtcDate;
};

/**
 * get ISO dates for today, first day of month, quarter and year for price change report columns
 */
export const getPriceChangeDates = (): PriceChangeDates => ({
  today: formatISODate(dayjs.utc()),
  firstDayOfMonth: formatISODate(dayjs.utc().startOf('month')),
  firstDayOfQuarter: formatISODate(dayjs.utc().startOf('quarter')),
  firstDayOfYear: formatISODate(dayjs.utc().startOf('year')),
});

export type UsePriceChangesLoaded = {
  loaded: true;
  Fallback: undefined;
  pricesByAssetAndDate: TupleKeyMap<[string, string], Price>;
  priceChanges24hByAssetId: Map<string, number | undefined>;
  priceChangeDates: PriceChangeDates;
};

export type UsePriceChangesNotLoaded = {
  loaded: false;
  Fallback: TFallback;
  pricesByAssetAndDate: undefined;
  priceChanges24hByAssetId: undefined;
  priceChangeDates: PriceChangeDates;
};

export type UsePriceChanges = UsePriceChangesNotLoaded | UsePriceChangesLoaded;

export function usePriceChanges(assetIds: string[]): UsePriceChanges {
  const priceChangeDates = getPriceChangeDates();
  const pricesQueryResult = useDefaultErrorHandling(
    usePricesForPositionsQuery({
      variables: {
        assetIds,
        dates: [
          priceChangeDates.today,
          priceChangeDates.firstDayOfMonth,
          priceChangeDates.firstDayOfQuarter,
          priceChangeDates.firstDayOfYear,
        ],
      },
      skip: assetIds.length === 0,
    })
  );
  const { pricesByAssetAndDate, priceChanges24hByAssetId } = useMemo(() => {
    if (!pricesQueryResult.loaded) {
      return {
        pricesByAssetAndDate: undefined,
        priceChanges24hByAssetId: undefined,
      };
    }
    const pricesByAssetAndDate = new TupleKeyMap<[string, string], Price>();
    for (const assetPrices of pricesQueryResult.data.assets.priceForDay) {
      for (const price of assetPrices.rows) {
        pricesByAssetAndDate.set([assetPrices.asset.id, price.date.toString()], price);
      }
    }

    const priceChanges24hByAssetId = new Map(
      pricesQueryResult.data.assets.changes.map((change) => [
        change.assetId,
        change.priceChange ? Number.parseFloat(change.priceChange) : undefined,
      ])
    );

    return { pricesByAssetAndDate, priceChanges24hByAssetId };
  }, [pricesQueryResult]);

  if (!pricesQueryResult.loaded) {
    return {
      loaded: false,
      Fallback: pricesQueryResult.Fallback,
      pricesByAssetAndDate: undefined,
      priceChanges24hByAssetId: undefined,
      priceChangeDates,
    };
  }

  // ts doesn't understand that if data loaded fields should be defined

  return {
    loaded: true,
    Fallback: undefined,
    pricesByAssetAndDate: pricesByAssetAndDate!,
    priceChanges24hByAssetId: priceChanges24hByAssetId!,
    priceChangeDates,
  };
}
