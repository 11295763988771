import { Modal, ModalDialog } from '@mui/joy';
import { type CellContext, type ColumnDef, createColumnHelper } from '@tanstack/table-core';
import type { ReactElement } from 'react';

import GTable from 'components/technical/GTable/GTable.tsx';
import GButton from 'components/technical/inputs/GButton/GButton';
import GDialogHeader from '../GDialog/GDialogHeader.tsx';

interface ScenarioDialogProps<T extends { name: string; description: string }> {
  onClose: () => void;
  onSelected: (scenario: T) => void;
  scenarios: T[];
  title: string;
}

export const ScenarioDialog = <T extends { name: string; description: string }>(
  props: ScenarioDialogProps<T>
): ReactElement => {
  const columnHelper = createColumnHelper<T>();
  const columns: ColumnDef<T>[] = [
    {
      header: 'Name',
      accessorKey: 'name',
    },
    {
      header: 'Description',
      accessorKey: 'description',
    },
    columnHelper.display({
      header: 'Actions',
      meta: {
        cellStyles: {
          width: '100px',
        },
      },
      cell: (ctx: CellContext<T, unknown>) => (
        <GButton color="primary" onClick={(): void => props.onSelected(ctx.row.original)}>
          Import
        </GButton>
      ),
    }),
  ];

  return (
    <Modal open={true} onClose={props.onClose}>
      <ModalDialog size={'lg'}>
        <GDialogHeader>{props.title}</GDialogHeader>
        <GTable columns={columns} data={props.scenarios} hideShadow />
      </ModalDialog>
    </Modal>
  );
};
