import { Typography } from '@mui/joy';
import type { ReactElement } from 'react';

export const Header3 = ({ title }: { title: string }): ReactElement => {
  return (
    <Typography level="title-md" color="primary">
      {title}
    </Typography>
  );
};
