import { DateTimeFormat, formatEnum } from '../../../../../formatter.utils.ts';
import { dateReadableValueGetter, getRowDataForRowGroupColumn } from '../../../../../technical/grids/agGrid.utils.tsx';
import {
  type IImportPositionsHistoryQuery,
  IProcessStatus,
  useImportPositionsHistoryQuery,
} from '../../../../../../generated/graphql.tsx';
import { GraphQLErrorMessage } from '../../../../../technical/form/GraphQLApiErrorMessage.tsx';
import Loader from '../../../../../technical/Loader/Loader.tsx';
import GAgGrid from '../../../../../technical/grids/GAgGrid.tsx';
import type { ColDef, ICellRendererParams, ValueGetterParams } from 'ag-grid-community';
import { type ReactElement, type ReactNode, useEffect, useState } from 'react';
import { Box, Stack, Tooltip } from '@mui/joy';

const defaultColDef = {
  resizable: true,
  sortable: true,
  filter: true,
};

type RowData = IImportPositionsHistoryQuery['portfolio']['imports'][number];

const columns: ColDef<RowData>[] = [
  {
    headerName: 'Upload time',
    type: 'dateColumn',
    initialSort: 'desc',
    valueGetter: (params: ValueGetterParams<RowData>): string | undefined => {
      // backend returns string instead of arrow/date
      return params.data
        ? dateReadableValueGetter(params.data.remoteFile.uploadTime, DateTimeFormat.DateTime)
        : undefined;
    },
  },
  {
    headerName: 'Status',
    type: 'textColumn',
    field: 'status',
    cellRenderer: (params: ICellRendererParams<RowData>): ReactElement | undefined => {
      const rowData = getRowDataForRowGroupColumn(params);
      if (!rowData) {
        return undefined;
      }

      if (isInProgress(rowData.status)) {
        return (
          <Stack height={'100%'} alignItems={'center'} direction={'row'}>
            <Loader variant={'small'} positionVariant={'min-content'} />
          </Stack>
        );
      }

      return <div>{formatEnum(rowData.status)}</div>;
    },
  },
  {
    headerName: 'Processed lines',
    type: ['numericColumn', 'extendedNumericColumn'],
    field: 'result.processedLines',
  },
  {
    headerName: 'Total errors',
    type: ['numericColumn', 'extendedNumericColumn'],
    field: 'result.totalErrors',
  },
  {
    headerName: 'Errors',
    type: ['textColumn'],
    cellRenderer: (params: ICellRendererParams<RowData>): ReactNode => {
      const rowData = getRowDataForRowGroupColumn(params);
      if (!rowData) {
        return undefined;
      }

      const errors = rowData.result?.errors;
      if (!errors || errors.length === 0) {
        return undefined;
      }

      const [firstError, ...rest] = errors.map((err) => `Line ${err.lineNo}: ${err.message}`);
      if (errors.length === 1) {
        return firstError;
      }

      console.log(errors);

      return (
        <Tooltip
          title={
            <Stack>
              {rest.map((err, i) => (
                <div key={i}>{err}</div>
              ))}
            </Stack>
          }
        >
          <Box>{firstError}</Box>
        </Tooltip>
      );
    },
  },
];

const isInProgress = (status: IProcessStatus): boolean => {
  return [IProcessStatus.Pending, IProcessStatus.InProgress].includes(status);
};

export const ImportPositionsList = (props: {
  portfolioDefinitionId: string;
}): ReactElement => {
  const [refreshImports, setRefreshImports] = useState(true);
  const historyQuery = useImportPositionsHistoryQuery({
    variables: {
      portfolioDefinitionId: props.portfolioDefinitionId,
    },
    pollInterval: refreshImports ? 5000 : 0,
  });

  const imports = historyQuery.data?.portfolio.imports;
  useEffect(() => {
    if (!imports) {
      return;
    }

    const hasPending = imports.some((imp) => isInProgress(imp.status));
    setRefreshImports(hasPending);
  }, [imports]);

  return (
    <Stack direction={'column'} justifyContent={'space-between'} flexGrow={1}>
      <GraphQLErrorMessage error={historyQuery.error} />
      {!historyQuery.called && historyQuery.loading && <Loader />}
      <GAgGrid<RowData>
        rowData={historyQuery.data?.portfolio.imports ?? historyQuery.previousData?.portfolio.imports}
        minHeight={500}
        defaultColDef={defaultColDef}
        sideBar={{ toolPanels: ['filters'] }}
        suppressAggFuncInHeader
        autoSizeStrategy={{ type: 'fitCellContents' }}
        columnDefs={columns}
      />
    </Stack>
  );
};
