import SubmitStep from './SubmitStep.tsx';
import type { AssetOptimizerInputFields } from '../AssetOptimizer.validation.ts';
import type { StepConfig } from '../../../../../technical/wizard/StepConfig.ts';
import type { TFallback } from '../../../../../technical/UseDefaultErrorHandling.tsx';

export const config = (
  forecastFallback: TFallback | undefined,
  skippedForecast: boolean
): StepConfig<AssetOptimizerInputFields> => ({
  label: 'Submit',
  element: <SubmitStep forecastFallback={forecastFallback} skippedForecast={skippedForecast} />,
  fields: [],
});
