import { Avatar, Stack, Typography } from '@mui/joy';
import type { ElementType, ReactElement } from 'react';

import { createUnsupportedVenueEntry, GENIE_VENUE, venues } from './VenueData.tsx';
import { IconVariant, iconVariantToPx } from '../market/asset/cryptocurrencies/CryptocurrenciesData';
import UnknownIcon from '../technical/UnknownIcon/UnknownIcon.tsx';

const VenueLabel = ({
  venue,
  format = 'long',
  size = IconVariant.MEDIUM,
  accountName,
  venueNameComponent,
  additionalInfo,
}: {
  venue: Label;
  format?: 'short' | 'long';
  size?: IconVariant;
  /** for genie exchange we show name of account in the icon */
  accountName?: string;
  venueNameComponent?: ElementType;
  additionalInfo?: ReactElement;
}): ReactElement => {
  let entry = venues[venue];
  if (!entry) {
    console.warn('Unsupported venue: ', venue, 'fallback to unsupported name and label');
    entry = createUnsupportedVenueEntry(venue);
  }

  if (venue === GENIE_VENUE) {
    // for genie exchange, we want to show an icon with account name placeholder
    const sizePx = iconVariantToPx(size);
    return (
      <Stack direction="row" alignItems="center" height="100%">
        <UnknownIcon
          text={accountName ?? '?'}
          style={{
            width: sizePx,
            height: sizePx,
          }}
        />
      </Stack>
    );
  }

  const icon = entry.icon;
  const convertedIconSize = iconVariantToPx(size);
  const entryName = format === 'long' && (
    <Typography component={venueNameComponent ?? 'span'} sx={{ fontSize: 'inherit', lineHeight: 'inherit' }}>
      {entry.name}
      {entry.adminOnly ? '*' : null}
    </Typography>
  );

  return (
    <Stack direction="row" spacing={1.5} alignItems="center">
      <Avatar
        component={icon}
        sx={{
          width: convertedIconSize,
          height: convertedIconSize,
          background: entry.iconBackground,
          '& .MuiAvatar-img': {
            objectFit: 'fill',
          },
          padding: '1px',
        }}
        key={venue}
        alt={entry.symbol}
      />
      {additionalInfo ? (
        <Stack direction="column" spacing={1.2}>
          {entryName}
          {additionalInfo}
        </Stack>
      ) : (
        entryName
      )}
    </Stack>
  );
};

export default VenueLabel;
