// when using onError, ensure that form handler throws an exception so that form is not submitted
import { useEffect } from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';

import { calculateErrorMessage } from './GraphQLAPIService.ts';

export const FORM_API_ERROR_FIELD = 'apiError';

export type GraphQlErrorHandler = (exception: unknown) => void;
export const useGraphQLApiError = <T extends FieldValues>(
  methods: Pick<UseFormReturn<T>, 'watch' | 'setError'>
): { onError: GraphQlErrorHandler; onErrorAndThrow: GraphQlErrorHandler } => {
  const onError = (exception: unknown): void => {
    const message = calculateErrorMessage(exception);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    methods.setError(FORM_API_ERROR_FIELD, {
      type: 'custom',
      message: message,
    });
  };

  useEffect(() => {
    const subst = methods.watch(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      methods.clearErrors(FORM_API_ERROR_FIELD);
    });

    return (): void => subst.unsubscribe();
  }, [methods]);

  return {
    onError,
    onErrorAndThrow: (exception: unknown): never => {
      onError(exception);
      throw exception;
    },
  };
};
