export type RecursiveDirtyField = {
  [key: string]: RecursiveDirtyField | boolean | RecursiveDirtyField[];
};
export const hasDirtyField = (obj: RecursiveDirtyField): boolean => {
  return Object.values(obj).some((value) => {
    if (typeof value === 'boolean') {
      return value;
    }

    if (Array.isArray(value)) {
      return value.some((item) => hasDirtyField(item));
    }

    return hasDirtyField(value);
  });
};
