import isNil from 'lodash/fp/isNil';

export const hashcode = (text: string | undefined | null): number => {
  if (isNil(text)) {
    return 0;
  }

  if (text.length === 0) {
    return 0;
  }

  let hash = 0;
  for (let i = 0; i < text.length; i++) {
    const chr = text.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return hash;
};
