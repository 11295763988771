import { Box, Stack, Typography } from '@mui/joy';
import type { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import type { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import CognitoApiErrorMessage from 'components/technical/form/CognitoErrorMessage';
import GFormProvider from 'components/technical/form/GFormProvider';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton';
import { useCognitoApiError } from 'components/technical/form/UseCognitoApiError.tsx';
import { FormInput } from 'components/technical/inputs';
import GoogleLoginButton from './GoogleLoginButton';

const credentialsSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export const ProvideCredentialsStep = ({
  onProvideMfa,
  onFinishedLogging,
}: {
  onProvideMfa: (user: CognitoUser) => void;
  onFinishedLogging: () => void;
}): ReactElement => {
  const methods = useForm<{ email: string; password: string }>({
    resolver: gYupResolver(credentialsSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onError = useCognitoApiError(methods);
  const handleFormSubmit = async (data: { email: string; password: string }): Promise<void> => {
    const { email, password } = data;

    return Auth.signIn(email, password)
      .then((response) => {
        methods.reset();
        if (response.challengeName) {
          onProvideMfa(response);
        } else {
          onFinishedLogging();
        }
      })
      .catch((error: Error) => {
        onError(error);
      });
  };

  return (
    <GFormProvider {...methods}>
      <Box
        component={'form'}
        onSubmit={methods.handleSubmit(handleFormSubmit)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '1rem',
          height: '100%',
          width: '100%',
        }}
      >
        <Stack spacing={4}>
          <Typography level={'h3'}>Enter your credentials</Typography>
          <Stack spacing={2}>
            <FormInput name="email" label="Email address" width="fullWidth" autoComplete="off" />
            <FormInput name="password" label="Password" type="password" width="fullWidth" autoComplete="off" />
            <CognitoApiErrorMessage justify="flex-start" />
          </Stack>
        </Stack>
        <SubmitButton sx={{ my: 2 }} width="fullWidth">
          Submit
        </SubmitButton>
      </Box>
      <GoogleLoginButton width="fullWidth" />
    </GFormProvider>
  );
};
