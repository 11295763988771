import { useEffect, useRef } from 'react';

import { termlyId } from './config';

const CookieBanner = (): null => {
  const loaded = useRef(false);
  useEffect(() => {
    if (!loaded.current) {
      loaded.current = true;
      const script = document.createElement('script');
      script.src = `https://app.termly.io/resource-blocker/${termlyId}?autoBlock=off`;
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);
  return null;
};

export default CookieBanner;
