import {
  createAccountIdAutocompleteOptions,
  type CreateAccountIdAutocompleteOptionsInputAccount,
} from '../../../../portfolio/account/AccountService.tsx';
import type { ReactElement } from 'react';
import FormStaticMultiAutocomplete from '../../../../technical/form/FormStaticMultiAutocomplete.tsx';
import { IUserPortfolioDefinitionType } from '../../../../../generated/graphql.tsx';
import CreatePortfolioDialog from '../create/CreatePortfolioDialog.tsx';
import type { FormOutputFields } from '../create/CreatePortfolio.validation.ts';
import { formSchema } from './CreateRealPortfolioDialog.validation.tsx';
import {
  createSubfundIdAutocompleteOptions,
  type SubfundAutocompleteOptionInput,
} from '../../../../portfolio/fund/SubFundService.tsx';
import FormErrorMessage from '../../../../technical/form/FormErrorMessage.tsx';

type FormExtraOutputFields = {
  accounts: string[];
  subFunds: number[];
  notEmptyAccountOrSubfunds?: never;
};

const CreateRealPortfolioDialog = (props: {
  onClose: () => void;
  onAdded: () => void;
  accounts: CreateAccountIdAutocompleteOptionsInputAccount[];
  subFunds: SubfundAutocompleteOptionInput[];
}): ReactElement => {
  const accountOptions = createAccountIdAutocompleteOptions(props.accounts);
  const subFundOptions = createSubfundIdAutocompleteOptions(props.subFunds);

  const width = 'xl2';
  return (
    <CreatePortfolioDialog
      onClose={props.onClose}
      onAdded={props.onAdded}
      extraSchema={formSchema}
      defaultValues={{
        accounts: [],
        subFunds: [],
      }}
      createInput={(data: FormOutputFields & FormExtraOutputFields) => {
        return {
          name: data.name,
          description: data.description,
          type: IUserPortfolioDefinitionType.Real,
          realDefAccounts: data.accounts ?? [],
          realDefSubFunds: data.subFunds ?? [],
        };
      }}
    >
      <FormStaticMultiAutocomplete<FormExtraOutputFields>
        {...accountOptions}
        name="accounts"
        label="Accounts"
        width={width}
        onChange={(_val, { clearErrors }) => {
          clearErrors('notEmptyAccountOrSubfunds');
        }}
      />
      <FormStaticMultiAutocomplete<FormExtraOutputFields>
        {...subFundOptions}
        name="subFunds"
        label="Sub-funds"
        width={width}
        onChange={(_val, { clearErrors }) => {
          clearErrors('notEmptyAccountOrSubfunds');
        }}
      />
      <FormErrorMessage<FormExtraOutputFields> name={'notEmptyAccountOrSubfunds'} />
    </CreatePortfolioDialog>
  );
};

export default CreateRealPortfolioDialog;
