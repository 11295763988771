import { Dropdown } from '@mui/joy';
import { TypographyNestedContext } from '@mui/joy/Typography/Typography';
import { type ReactNode, type ReactElement, useState } from 'react';
import { DialogDropdownContext } from './DialogDropdownContext.tsx';

const DialogDropdown = ({ children }: { children: ReactNode }): ReactElement => {
  const [dialog, setDialog] = useState<ReactNode>(null);

  return (
    <TypographyNestedContext.Provider value={false}>
      <Dropdown>
        <DialogDropdownContext.Provider value={{ setDialog: (dialog) => setDialog(dialog) }}>
          {children}
        </DialogDropdownContext.Provider>
        {dialog}
      </Dropdown>
    </TypographyNestedContext.Provider>
  );
};

export default DialogDropdown;
