import { Card, Grid, Typography } from '@mui/joy';
import type { ReactElement } from 'react';

import RiskMetricsContributionChart, { type RiskMetricWithValues } from '../../risk/RiskMetricsContributionChart.tsx';
import PortfolioRiskMetricsList from './PortfolioRiskMetricsList.tsx';
import { isNil } from 'lodash/fp';
import { createMetricLabelWithBenchmark } from './PortfolioRisk.utils.ts';
import { bignumber } from 'mathjs';
import {
  assetExposure,
  isExposureOptionEqual,
  type OptionValue,
  portfolioDefinitionExposure,
} from '../../risk/RiskAggregationsService.ts';
import type { Solution } from './Results.types.ts';
import { OptimizationType } from '../optimization.utils.ts';
import type { StaticAutocompleteOption } from '../../../technical/inputs/Autocomplete/StaticSingleAutocomplete.props.ts';

const groupMeasuresPerRisk = (solution: Solution): RiskMetricWithValues[] => {
  const riskLabelToValue = new Map<string, number>(
    solution.riskMeasures.map((risk) => [createMetricLabelWithBenchmark(risk.measure, risk.benchmark), risk.value])
  );

  const riskMeasuresPerRisk = new Map<string, RiskMetricWithValues>();
  for (const measure of solution.measures) {
    for (const weight of measure.weights) {
      const label = weight.measure;
      const benchmark = weight.benchmark as { symbol?: string; name: string; id: string };
      const key = createMetricLabelWithBenchmark(label, benchmark);
      let riskValues = riskMeasuresPerRisk.get(key);
      if (!riskValues) {
        riskValues = {
          label,
          paramDescriptions: [],
          parameters: benchmark
            ? [
                {
                  name: 'Benchmark',
                  asset: {
                    id: benchmark.id,
                    symbol: benchmark.symbol ?? benchmark.name,
                  },
                },
              ]
            : [],
          values: [],
        };

        riskMeasuresPerRisk.set(key, riskValues);
      }

      const riskValue = riskLabelToValue.get(key);
      if (isNil(riskValue)) {
        continue;
      }

      const value = weight.value * riskValue;
      if (Number.isNaN(value)) {
        continue;
      }

      const item = measure.item as { symbol?: string; name: string; id: string };
      riskValues.values.push({
        item: {
          id: item.id,
          symbol: item.symbol ?? item.name,
        },
        value: bignumber(value),
      });
    }
  }
  return Array.from(riskMeasuresPerRisk.values());
};

interface OptionsOutput {
  exposureOptions: {
    options: StaticAutocompleteOption<OptionValue>[];
    isValueEqual: typeof isExposureOptionEqual;
  };
  contributions: {
    portfolio: {
      name: string;
    };
    riskMetrics: RiskMetricWithValues[];
  }[];
}

const calculateInput = (optimizationType: OptimizationType, solutions: Solution[]): OptionsOutput => {
  const contributions = solutions.map((solution) => ({
    portfolio: {
      id: solution.solutionId,
      name: solution.name,
    },
    riskMetrics: groupMeasuresPerRisk(solution),
  }));

  if (optimizationType === OptimizationType.asset) {
    const exposureOptions = {
      options: [assetExposure],
      isValueEqual: isExposureOptionEqual,
    };

    return { exposureOptions, contributions };
  }
  const exposureOptions = {
    options: [portfolioDefinitionExposure],
    isValueEqual: isExposureOptionEqual,
  };

  return { exposureOptions, contributions };
};

export const OptimizerSolutionPortfolioRisk = ({
  solution,
  compareToSolution,
  optimizationType,
}: {
  solution: Solution;
  compareToSolution: Solution | null;
  optimizationType: OptimizationType;
}): ReactElement => {
  const input = calculateInput(
    optimizationType,
    [solution, compareToSolution].filter((sol): sol is Solution => sol !== null)
  );

  return (
    <>
      <Typography level="h4">Risk</Typography>
      <Grid container>
        <Grid md={6} xs={12}>
          <Card sx={{ height: '100%', px: 4, py: 3 }}>
            <PortfolioRiskMetricsList
              solution={solution}
              compareToSolution={compareToSolution}
              getLabelOfBenchmark={(
                ben: { symbol: string } | { name: string } | null | undefined
              ): string | undefined => {
                if (isNil(ben)) {
                  return undefined;
                }

                if ('symbol' in ben) {
                  return ben.symbol;
                }

                return ben?.name;
              }}
            />
          </Card>
        </Grid>
        <Grid md={6} xs={12}>
          <Card
            sx={{
              height: '100%',
              justifyContent: 'space-between',
              padding: 4,
            }}
          >
            <RiskMetricsContributionChart {...input} showLegend={!isNil(compareToSolution)} />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
