import type { FunctionComponent } from 'react';
import * as Colors from 'theme/colors';
import type { TableDividerProps } from './TableDivider.props';
import TableDividerLine from '../GTableDividerLine/TableDividerLine';
import { Box, Typography } from '@mui/joy';

const TableDivider: FunctionComponent<TableDividerProps> = ({ text, color, onClick }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        margin: '0.75rem 0',
      }}
    >
      <TableDividerLine color={color} />
      <Box
        component={'button'}
        sx={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          left: '1rem',
          padding: '0.25rem 0.5rem',
          borderRadius: '0.25rem',
          cursor: 'pointer',
          border: 'none',
          backgroundColor: color,
        }}
        onClick={onClick}
      >
        <Typography textColor={Colors.getFontColorByRegularChartBackgroundColors(color)} level={'body-xs'}>
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default TableDivider;
