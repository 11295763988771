import type { ReactElement } from 'react';
import { Controller, type FieldValues, type Path, useFormContext } from 'react-hook-form';

import GTextArea from '../inputs/GTextArea/GTextArea.tsx';
import type { GTextAreaProps } from '../inputs/GTextArea/GTextAreaProps.ts';
import type { DistributiveOmit } from '../../type.utils.ts';

function FormTextArea<T extends FieldValues>(
  props: DistributiveOmit<GTextAreaProps, 'value' | 'onChange' | 'error' | 'onBlur' | 'name'> & {
    name: Path<T>;
  }
): ReactElement {
  /* jscpd:ignore-start */
  const { control } = useFormContext();

  return (
    <Controller
      name={props.name}
      control={control}
      /* jscpd:ignore-end */
      render={({ field: { value, onChange, onBlur, ref }, fieldState, formState }): ReactElement => {
        return (
          <GTextArea
            {...props}
            ref={ref}
            value={value ?? ''}
            error={fieldState.error?.message}
            onChange={onChange}
            onBlur={onBlur}
            disabled={(props.disabled ?? false) || formState.isSubmitting}
          />
        );
      }}
    />
  );
}

export default FormTextArea;
