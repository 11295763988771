const showedLogMessages = new Set<string>();
/** shows the same message only once */
// biome-ignore lint/suspicious/noExplicitAny:
export function logOnce(message: string, ...optionalParams: any[]): void {
  if (!showedLogMessages.has(message)) {
    showedLogMessages.add(message);
    // eslint-disable-next-line no-console
    console.log(message, ...optionalParams);
  }
}

const showedWarnMessages = new Set<string>();

// biome-ignore lint/suspicious/noExplicitAny:
export function logWarnOnce(message: string, ...optionalParams: any[]): void {
  if (!showedWarnMessages.has(message)) {
    showedWarnMessages.add(message);
    console.warn(message, ...optionalParams);
  }
}

const showedErrorMessages = new Set<string>();

// biome-ignore lint/suspicious/noExplicitAny:
export function logErrorOnce(message: string, ...optionalParams: any[]): void {
  if (!showedErrorMessages.has(message)) {
    showedErrorMessages.add(message);
    console.error(message, ...optionalParams);
  }
}
