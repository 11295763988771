import { Card, Stack } from '@mui/joy';
import dayjs, { type Dayjs } from 'dayjs';
import { kebabCase } from 'lodash/fp';
import isNil from 'lodash/fp/isNil';
import { bignumber } from 'mathjs';
import { type ReactElement, useState } from 'react';
import { getDefaultRange } from 'components/predefinedDateRanges';
import {
  dateTimeAxisFormat,
  dateTimeExportFormat,
  getFormatter,
  type HighchartSeries,
  tooltipFormat,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import HighChartsContainer from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper';
import { Select } from 'components/technical/inputs';
import PredefinedDateRangeButtonsWithCalendar from 'components/technical/inputs/date/PredefinedDateRangeButtonsWithCalendar';

import { type IAssetMetricsQuery, useAssetMetricsQuery } from '../../../../../../generated/graphql';
import { formatterForName } from '../../../../../formatter.utils';
import { getFormat, getName, getNameWithTooltip } from '../../../../../metrics/MetricsData';
import { convertDateRangeToSinceToDate } from 'components/technical/inputs/date/dateRange.utils';
import type Highcharts from 'highcharts';

const calculateChartData = (data: IAssetMetricsQuery, metric: string): HighchartSeries[] => {
  const history = data.assets.metricHistory.at(0)?.rows ?? [];

  const nonUndefinedRows = history.filter((row) => !isNil(row.metrics[metric]));

  const chartData: HighchartSeries[] = [
    {
      data: nonUndefinedRows.map((row) => ({
        x: dayjs.utc(row.date.toString()).valueOf(),
        y: bignumber(row.metrics[metric]).toNumber(),
        textValue: formatterForName(getFormat(metric))(bignumber(row.metrics[metric]).toNumber()),
      })),
      name: getName(metric),
      type: 'line',
    },
  ];

  return chartData;
};

const calculateOptions = (metric: string, asset: { symbol: string }): Highcharts.Options => {
  const metricFormat = getFormat(metric);

  return {
    ...dateTimeAxisFormat,
    ...dateTimeExportFormat(`${asset.symbol.toLowerCase()}-${kebabCase(getName(metric))}`),
    ...tooltipFormat,
    legend: {
      enabled: false,
    },
    yAxis: {
      labels: {
        formatter: getFormatter(metricFormat),
      },
      title: {
        text: undefined,
      },
    },
  };
};

const AssetMetricsChart = ({
  asset,
  metrics,
}: {
  asset: { id: string; symbol: string };
  metrics: string[];
}): ReactElement => {
  const defaultDateRange = getDefaultRange();
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(defaultDateRange.value);
  const [metric, setMetric] = useState<string>(metrics[0]);

  const queryOutput = useAssetMetricsQuery({
    variables: {
      assetId: asset.id,
      filters: {
        date: convertDateRangeToSinceToDate(dateRange),
        metricLabels: [metric],
      },
    },
  });

  const metricOptions = metrics.map((metric) => ({
    label: getNameWithTooltip(metric) ?? metric,
    value: metric,
    key: metric,
  }));

  return (
    <Card>
      <Stack spacing="xs">
        <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
          <Select
            label="Metric"
            value={metric}
            disableClearable
            onChange={(value): void => setMetric(value)}
            options={metricOptions}
            width="minContent"
          />
          <PredefinedDateRangeButtonsWithCalendar
            defaultValue={defaultDateRange}
            onChange={(val): void => setDateRange(val)}
          />
        </Stack>
        <Stack>
          <HighChartsContainer<IAssetMetricsQuery>
            {...queryOutput}
            key={metric}
            calculateOptions={(): Highcharts.Options => calculateOptions(metric, asset)}
            calculateChartData={(data): HighchartSeries[] => calculateChartData(data, metric)}
          />
        </Stack>
      </Stack>
    </Card>
  );
};

export default AssetMetricsChart;
