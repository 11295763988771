import type { GAutocompleteProps } from './components/technical/inputs/Autocomplete/GSingleAutocomplete.props.ts';
import type { GOption } from './components/technical/inputs/Autocomplete/GAutocomplete.tsx';
import { isAbortError } from './IsAbortError.tsx';
import { useMemo, useRef, useState } from 'react';
import { useUnmount } from 'react-use';

export const useOptionsLoader = <TValue,>(
  getOptions: GAutocompleteProps<TValue>['getOptions']
): {
  load(input: string): Promise<void>;
  loading: boolean;
  options: GOption<TValue>[];
} => {
  const abortController = useRef<null | AbortController>(null);
  const [loading, setLoading] = useState(false);
  const input = useRef<string>('');
  const paginationState = useRef<null | unknown>(null);
  const [options, setOptions] = useState<GOption<TValue>[]>([]);

  const functions = useMemo(() => {
    const abort = (): void => {
      setLoading(false);
      if (!abortController.current) {
        return;
      }

      if (!abortController.current.signal.aborted) {
        abortController.current.abort();
      }

      abortController.current = null;
    };

    const cleanupOptions = (): void => {
      if (paginationState.current !== null) {
        paginationState.current = null;
      }

      input.current = '';
      setOptions([]);
    };

    return {
      load: async (query: string): Promise<void> => {
        abort();
        // we don't want to clear options, because it causes blinking in selectors based on in memory arrays
        input.current = query;
        const controller = new AbortController();
        abortController.current = controller;
        setLoading(true);

        try {
          const result = await getOptions({
            input: query,
            signal: controller.signal,
            paginationState: null,
          });

          paginationState.current = (result.paginationState as unknown) ?? null;
          input.current = query;
          abortController.current = null;
          setLoading(false);

          const hasMoreOption: GOption<TValue>[] = [];
          if (result.hasMoreResults) {
            hasMoreOption.push({
              type: 'hasMoreResults',
            });
          }

          setOptions([
            ...result.data.map(
              (val): GOption<TValue> => ({
                type: 'regular',
                value: val,
              })
            ),
            ...hasMoreOption,
          ]);
        } catch (e) {
          // cancelled by apollo client itself when unmounting react componennts
          if (abortController.current === controller) {
            abort();
            cleanupOptions();
          }

          // swallow error caused by aborting
          if (isAbortError(e)) {
            return;
          }

          console.error('Unhandled options loader error', e);
        }
      },
      abort: (): void => {
        abort();
        cleanupOptions();
      },
    };
  }, [getOptions]);

  useUnmount(() => {
    functions.abort();
  });

  return {
    ...functions,
    loading,
    options,
  };
};
