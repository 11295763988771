import isNil from 'lodash/fp/isNil';

import { type IAsset, IAssetType, type IAssetValue, type IDerivativeDetails } from '../../../generated/graphql';
import { logWarnOnce } from '../../log.utils.ts';
import type { RecursivePartial } from '../../type.utils.ts';

export type PublicAsset = Pick<IAsset, 'symbol' | 'id' | 'icon' | 'name'> & {
  type: IAssetType.Public;
  label: string;
};

export type ExchangeAsset = Pick<IAsset, 'name' | 'symbol' | 'id'> & {
  type: IAssetType.Exchange;
  exchangeDetails: Label;
};

export type DerivativeAsset = Pick<IAsset, 'symbol' | 'id'> & {
  type: IAssetType.Derivative;
  derivativeDetails: IDerivativeDetails;
};

export type UnvestedAsset = Pick<IAsset, 'symbol' | 'id'> & {
  type: IAssetType.Unvested;
  name: string;
};

export type PrivateAsset = Pick<IAsset, 'symbol' | 'id'> & {
  type: IAssetType.Private;
  name: string;
};

export interface PrivateAssetWithPrivateDetails extends PrivateAsset {
  privateDetails: {
    unvestedAsset?: {
      id: string;
      symbol: string;
    };
  };
}

export type Asset = PublicAsset | ExchangeAsset | DerivativeAsset | PrivateAsset | UnvestedAsset;

export type AssetValue = Omit<IAssetValue, 'asset'> & { asset: Asset };
export type IAssetToAsset<T> = T extends IAsset ? Asset : NonNullable<T> extends IAsset ? Asset | undefined : T;
export type IAssetValueToAssetValue<T> = {
  [key in keyof T]: NonNullable<T[key]> extends IAsset ? IAssetToAsset<T[key]> : T[key];
};

const logAssetIfDoesntMatchTypeGuard = (asset: RecursivePartial<IAsset>, matches: boolean): boolean => {
  if (!matches) {
    logWarnOnce('Asset doesnt have all fields: ' + JSON.stringify(asset));

    return false;
  }

  return true;
};

export const isPublicAsset = (
  asset: Omit<IAsset, 'derivativeDetails' | 'unvestedAsset' | 'priceAsset'>
): asset is PublicAsset => {
  // don't log warning for checking only asset type. Log only once we miss some fields which we expect to be set
  if (asset.type !== IAssetType.Public) {
    return false;
  }

  return logAssetIfDoesntMatchTypeGuard(
    asset,
    !isNil(asset.label) && !isNil(asset.symbol) && !isNil(asset.name) && !isNil(asset.id)
  );
};

export const getPublicAssets = <T extends { label?: Label | null; name?: string | null; type: IAssetType }>(
  assets: T[]
): (Omit<T, 'label' | 'name' | 'derivativeDetails' | 'unvestedAsset' | 'type' | 'icon'> & {
  label: Label;
  name: string;
  type: IAssetType.Public;
})[] =>
  assets
    .filter((asset: T): asset is T & { label: Label; name: string } => {
      return asset.type === IAssetType.Public && !isNil(asset.label) && !isNil(asset.name);
    })
    .map((val) => ({
      ...val,
      type: IAssetType.Public,
    }));

export const getPrivateAndPublicAssets = <
  T extends { id: string; symbol: string; label?: Label | null; name?: string | null; type: IAssetType },
>(
  assets: T[]
): Array<PublicAsset | PrivateAsset> => {
  return assets.filter((asset: T) => isPublicAsset(asset) || isPrivateAsset(asset));
};

export const getAssets = (assets: Omit<IAsset, 'priceAsset'>[]): Asset[] => {
  return assets.filter((asset: Omit<IAsset, 'priceAsset'>): asset is Asset => isAsset(asset));
};

export const isAsset = (asset: Omit<IAsset, 'priceAsset'> | null | undefined): asset is Asset => {
  if (isNil(asset)) {
    return false;
  }

  switch (asset.type) {
    case IAssetType.Derivative:
      return isDerivativeAsset(asset);
    case IAssetType.Exchange:
      return isExchangeAsset(asset);
    case IAssetType.Private:
      return isPrivateAsset(asset);
    case IAssetType.Public:
      return isPublicAsset(asset);
    case IAssetType.Unvested:
      return isUnvestedAsset(asset);
    default:
      console.error('IAsset doesnt have all asset fields', asset);
      return false;
  }
};

export const isDerivativeAsset = (asset: Omit<IAsset, 'unvestedAsset' | 'priceAsset'>): asset is DerivativeAsset => {
  if (asset.type !== IAssetType.Derivative) {
    return false;
  }

  return logAssetIfDoesntMatchTypeGuard(
    asset,
    !isNil(asset.derivativeDetails) && !isNil(asset.symbol) && !isNil(asset.id)
  );
};

export const isPrivateAssetWithPrivateDetails = (
  asset: RecursivePartial<IAsset>
): asset is PrivateAssetWithPrivateDetails => {
  if (asset.type !== IAssetType.Private) {
    return false;
  }

  // private details is optional, when asset doesnt have unvested asset, it may not be present
  return isPrivateAsset(asset);
};

export const isPrivateAsset = (asset: RecursivePartial<IAsset>): asset is PrivateAsset => {
  if (asset.type !== IAssetType.Private) {
    return false;
  }

  return logAssetIfDoesntMatchTypeGuard(asset, !isNil(asset.name) && !isNil(asset.symbol) && !isNil(asset.id));
};

export const isExchangeAsset = (asset: Omit<IAsset, 'unvestedAsset' | 'priceAsset'>): asset is ExchangeAsset => {
  if (asset.type !== IAssetType.Exchange) {
    return false;
  }

  return logAssetIfDoesntMatchTypeGuard(
    asset,
    !isNil(asset.symbol) && !isNil(asset.id) && !isNil(asset.exchangeDetails)
  );
};

export const isUnvestedAsset = (asset: Omit<IAsset, 'unvestedAsset' | 'priceAsset'>): asset is ExchangeAsset => {
  if (asset.type !== IAssetType.Unvested) {
    return false;
  }

  return logAssetIfDoesntMatchTypeGuard(asset, !isNil(asset.symbol) && !isNil(asset.id));
};

export const isNonDerivativeAsset = (asset: Omit<IAsset, 'priceAsset'>): asset is Exclude<Asset, DerivativeAsset> => {
  return asset.type !== IAssetType.Derivative;
};

export const filterByAssetType = <T extends { id: string; symbol: string; type: IAssetType }>(
  idToAsset: Record<string, IAsset>,
  predicate: (val: Omit<IAsset, 'priceAsset'>) => val is T
): Record<string, T> => {
  const assets = Object.values(idToAsset)
    .map((value): [string, T | undefined] => [value.id, predicate(value) ? value : undefined])
    .filter((isToValue): isToValue is [string, T] => !isNil(isToValue[1]));

  return Object.fromEntries(assets);
};

export const DOLLAR_LABEL = 'spt:us-dollar/usd';
