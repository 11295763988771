import { type FunctionComponent, type PropsWithChildren, createContext, useMemo, useState } from 'react';
import { useAuth } from 'UseAuth';

export const ImpersonationBarContext = createContext({
  impersonationBarOpen: false,
  setImpersonationBarOpen: (_open: boolean): void => {
    // pass
  },
});

const ImpersonationBarProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { isImpersonating } = useAuth();
  const [impersonationBarOpen, setImpersonationBarOpen] = useState(isImpersonating);

  const value = useMemo(() => ({ impersonationBarOpen, setImpersonationBarOpen }), [impersonationBarOpen]);

  return <ImpersonationBarContext.Provider value={value}>{children}</ImpersonationBarContext.Provider>;
};

export default ImpersonationBarProvider;
