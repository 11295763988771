import { Tooltip } from '@mui/joy';
import type { SvgIconProps } from '@mui/joy';
import type { ReactElement, ReactNode } from 'react';

import { WarningRound as WarningIcon } from '../icons';

const WarningTooltip = ({
  children,
  fontSize,
}: {
  children: NonNullable<ReactNode>;
  fontSize: SvgIconProps['fontSize'];
}): ReactElement => {
  const iconWithRequiredWrapper = (
    <span>
      <WarningIcon color="warning" fontSize={fontSize} />
    </span>
  );

  return <Tooltip title={children}>{iconWithRequiredWrapper}</Tooltip>;
};

export default WarningTooltip;
