import { Card, Grid, Stack } from '@mui/joy';
import type { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import FormSelect from 'components/technical/form/FormSelect';
import FormStaticMultiAutocomplete from 'components/technical/form/FormStaticMultiAutocomplete';
import GFormProvider from 'components/technical/form/GFormProvider';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton';
import { useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError.tsx';
import { FormInput } from 'components/technical/inputs';
import DialogButton from 'components/technical/inputs/GButton/DialogButton';
import GButton from 'components/technical/inputs/GButton/GButton';
import SectionColumn from 'components/technical/layout/Column/SectionColumn';
import { ScenarioDialog } from 'components/technical/ScenarioDialog/ScenarioDialog';
import { schema } from './FactorRegression.validation';
import FactorRegressionResult from './FactorRegressionResult';
import {
  type IFactorRegressionInputQuery,
  IRegressionModelV2,
  type IRegressionServiceInputV2,
  useFactorRegressionLazyQuery,
} from '../../../generated/graphql';
import { createAssetSelectOptions } from '../../market/asset/AssetService';
import type { NotVerifiedAsset } from '../../market/asset/AssetLabelService.ts';
import type { BacktestingConfiguration } from '../lab/backtesting/BacktestConfiguration.types.ts';
import isNil from 'lodash/fp/isNil';

const regressionModelOptions = [
  {
    label: 'Linear regression',
    value: IRegressionModelV2.Linear,
    key: IRegressionModelV2.Linear,
  },
  {
    label: 'Lasso regression',
    value: IRegressionModelV2.L1Regularized,
    key: IRegressionModelV2.L1Regularized,
  },
];

type FactorRegressionInputFields = {
  factors: (NotVerifiedAsset & { id: string })[];
  lookbackWindowSize: number;
  regressionModel: IRegressionModelV2;
};

const getRegressionServiceInput = (
  input: FactorRegressionInputFields,
  factors: IFactorRegressionInputQuery['factorRegressionV2']['supportedFactors'],
  backtestingConfig: BacktestingConfiguration
): IRegressionServiceInputV2 => {
  const factorIdToFactorType = Object.fromEntries(factors.map(({ asset, type }) => [asset.id, type]));

  return {
    factors: input.factors.map((factor) => {
      return { assetId: factor.id, category: factorIdToFactorType[factor.id] };
    }),
    regressionModel: input.regressionModel,
    lookbackWindowSize: input.lookbackWindowSize,
    portfolioDefinitionIds: backtestingConfig.portfolios.map((portfolio) => portfolio.id),
    since: backtestingConfig.range.since,
    until: backtestingConfig.range.to,
  };
};

export type FactorRegressionProps = {
  backtestingConfig: BacktestingConfiguration;
  factors: IFactorRegressionInputQuery['factorRegressionV2']['supportedFactors'];
  presets: IFactorRegressionInputQuery['factorRegressionV2']['factorPresets'];
};

export const FactorRegressionCalculator = ({
  backtestingConfig,
  factors,
  presets,
}: FactorRegressionProps): ReactElement => {
  const methods = useForm<FactorRegressionInputFields>({
    resolver: gYupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      factors: undefined,
      lookbackWindowSize: 30,
      regressionModel: IRegressionModelV2.Linear,
    },
  });

  const [performFactor, queryOutput] = useFactorRegressionLazyQuery();
  const factorOptions = createAssetSelectOptions(factors.map((fact) => fact.asset).filter((asset) => !!asset));

  const { onErrorAndThrow } = useGraphQLApiError(methods);
  const factorRegressionResult = queryOutput.data?.factorRegressionV2.portfolioFactorRegression;

  return (
    <>
      <GFormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(async (formOutput) => {
            const { error } = await performFactor({
              variables: {
                input: getRegressionServiceInput(formOutput, factors, backtestingConfig),
              },
            });

            if (error) {
              onErrorAndThrow(error);
            }
          })}
        >
          <SectionColumn>
            <Card>
              <Stack spacing={2}>
                <Stack direction="row" flexWrap="wrap" spacing={1.5}>
                  <FormStaticMultiAutocomplete<FactorRegressionInputFields>
                    {...factorOptions}
                    name="factors"
                    label="Factors"
                    placeholder="Factors"
                    width="xl2"
                  />
                  <FormInput<FactorRegressionInputFields>
                    startDecorator="Days"
                    type="number"
                    name="lookbackWindowSize"
                    label="Window size"
                    width="xl2"
                  />
                  <FormSelect<FactorRegressionInputFields>
                    options={regressionModelOptions}
                    name="regressionModel"
                    label="Regression model"
                    width="xl2"
                  />
                </Stack>
                <Stack direction="row" spacing={1.5}>
                  <DialogButton
                    renderDialog={({ onClose }): ReactElement => (
                      <ScenarioDialog
                        onClose={onClose}
                        title="Select factor presets"
                        onSelected={(preset): void => {
                          methods.setValue(
                            'factors',
                            preset.factors.map((factor) => factor.asset)
                          );

                          methods.clearErrors('factors');
                          onClose();
                        }}
                        scenarios={presets}
                      />
                    )}
                  >
                    Select preset from library
                  </DialogButton>
                </Stack>
                <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
                  <GButton
                    disabled={methods.formState.isSubmitting}
                    onClick={(): void => {
                      methods.reset();
                    }}
                    variant="plain"
                  >
                    Clear all
                  </GButton>
                  <SubmitButton color="primary" width="minContent">
                    Calculate
                  </SubmitButton>
                </Stack>
                <Grid container justifyContent="flex-end">
                  <Grid xs={6} md={6}>
                    <Stack alignItems="flex-end" spacing={1.5}>
                      <GraphQLApiFormErrorMessage />
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </Card>
          </SectionColumn>
        </form>
      </GFormProvider>
      {!isNil(factorRegressionResult) && <FactorRegressionResult result={factorRegressionResult} />}
    </>
  );
};
