import { useSearchParams } from 'react-router-dom';
import { type Timezone, UTC, isValidTimezone } from 'components/date.utils';
import { logWarnOnce } from 'components/log.utils';

export const useUserTimezone = (): Timezone => {
  // in future we might want to allow user to set timezone setting
  const [searchParams] = useSearchParams();

  const urlTimezone = searchParams.get('tz');

  if (urlTimezone && !isValidTimezone(urlTimezone)) {
    logWarnOnce(`Invalid timezone from URL: ${urlTimezone}, fallback to UTC`);
    return UTC;
  }
  return urlTimezone ?? UTC;
};
