import { assetMinPercentageContribution } from '../../ImportPortfolioButton.tsx';
import type { Solution } from './Results.types.ts';

export const calculatePosition = <T, SOLUTION extends Omit<Solution, 'name'>>({
  solution,
  compareToSolution,
  getPosition,
}: {
  solution: SOLUTION;
  compareToSolution: SOLUTION | null;
  getPosition: (measure: SOLUTION['measures'][number]['item']) => T;
}): {
  asset: T;
  solutionAssetMeasure?: SOLUTION['measures'][number];
  solutionToCompareAssetMeasure?: SOLUTION['measures'][number];
}[] => {
  const compareToSolutionMeasure = compareToSolution?.measures ?? [];
  const compareToSolutionById: Record<string, Solution['measures'][number]> = Object.fromEntries(
    compareToSolutionMeasure.map((measure) => [measure.item.id, measure]) ?? []
  );

  const filteredOutSmallPortfolios: Solution['measures'][number][] = solution.measures.filter(
    (measure) => Math.abs(measure.cashWeight) > assetMinPercentageContribution
  );

  const solutionAssets = new Set(filteredOutSmallPortfolios.map((measure) => measure.item.id));

  const positions: {
    asset: T;
    solutionAssetMeasure?: Solution['measures'][number];
    solutionToCompareAssetMeasure?: Solution['measures'][number];
  }[] = filteredOutSmallPortfolios.map((measure) => ({
    asset: getPosition(measure.item),
    solutionAssetMeasure: measure,
    solutionToCompareAssetMeasure: compareToSolutionById[measure.item.id],
  }));

  for (const measure of compareToSolutionMeasure) {
    if (!solutionAssets.has(measure.item.id)) {
      if (Math.abs(measure.cashWeight) > assetMinPercentageContribution) {
        positions.push({
          asset: getPosition(measure.item),
          solutionAssetMeasure: undefined,
          solutionToCompareAssetMeasure: measure,
        });
      }
    }
  }

  return positions;
};
