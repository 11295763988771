import { type ReactElement, type ReactNode, useContext } from 'react';
import type { RenderDialogFunction } from '../inputs/GButton/DialogButton.tsx';
import { DialogDropdownContext } from './DialogDropdownContext.tsx';
import { MenuItem } from '@mui/joy';

export interface DialogItemProps {
  children: ReactNode;
  renderDialog: RenderDialogFunction;
}

export const DialogMenuItem = ({ children, renderDialog }: DialogItemProps): ReactElement => {
  const { setDialog } = useContext(DialogDropdownContext);

  return (
    <MenuItem
      onClick={(): void => {
        setDialog(
          renderDialog({
            onClose: (): void => setDialog(null),
          })
        );
      }}
    >
      {children}
    </MenuItem>
  );
};
