import { Stack, Typography } from '@mui/joy';
import type dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import isNil from 'lodash/fp/isNil';
import type { ReactElement } from 'react';
import { canBeSelected, ensureDateRangeWithinBounds } from './dateRange.utils';
import type { PredefinedRange } from '../../../predefinedDateRanges';
import GButton from '../GButton/GButton';

const CalendarPredefinedRangesList = (props: {
  setDateRange: (val: [Dayjs | null, Dayjs | null]) => void;
  minDate?: dayjs.Dayjs | null;
  maxDate?: dayjs.Dayjs | null;
  predefinedRanges: PredefinedRange[];
}): ReactElement => {
  return (
    <Stack
      spacing={1.5}
      alignItems="center"
      justifyContent="center"
      sx={(theme) => ({
        width: '200px',
        borderLeft: `1px solid ${theme.palette.background.level2}`,
        paddingLeft: '0.75rem',
      })}
    >
      <Typography level={'body-xs'}>Predefined ranges:</Typography>
      <Stack spacing={0.5}>
        {props.predefinedRanges.map((predefinedRange) => (
          <GButton
            variant="plain"
            disabled={!canBeSelected(props.minDate, props.maxDate, predefinedRange.value())}
            key={predefinedRange.label}
            onClick={(): void => {
              const range = ensureDateRangeWithinBounds(props.minDate, props.maxDate, predefinedRange.value());
              props.setDateRange(isNil(range) ? [null, null] : range);
            }}
          >
            {predefinedRange.name}
          </GButton>
        ))}
      </Stack>
    </Stack>
  );
};

export default CalendarPredefinedRangesList;
