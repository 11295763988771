import IconButton from '@mui/joy/IconButton';
import type { FunctionComponent } from 'react';
import { TrashBin } from './icons';

type RemoveButtonProps = {
  onClick: () => void;
  disabled?: boolean;
  color?: 'danger' | 'neutral';
};

const RemoveButton: FunctionComponent<RemoveButtonProps> = ({ onClick, disabled = false, color = 'neutral' }) => {
  return (
    <IconButton disabled={disabled} onClick={(): void => onClick()} color={color} variant={'plain'}>
      {<TrashBin fontSize="md" />}
    </IconButton>
  );
};

export default RemoveButton;
