import { Grid, Stack, Typography } from '@mui/joy';
import type { SvgIconProps as MSvgIconProps } from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/joy/SvgIcon';
import type { ReactElement, ReactNode } from 'react';

import BorderedItem from './BorderedItem/BorderedItem';
import type { GButtonProps } from './inputs/GButton/GButton.props';

export const BorderedActionItem = ({
  icon: Icon,
  title,
  description,
  action: Action,
}: {
  icon: ((props: SvgIconProps) => ReactNode) | ((props: MSvgIconProps) => ReactNode);
  title: string;
  description: ReactNode;
  action: (props: Pick<GButtonProps, 'width' | 'color'>) => ReactElement;
}): ReactElement => {
  return (
    <BorderedItem padding="sm">
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid xs={12} sm={8}>
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <Icon />
            <Stack spacing={1}>
              <Typography level="title-md">{title}</Typography>
              <Typography level="body-md">{description}</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid xs="auto">
          <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
            <Action width="smaller" color="primary" />
          </Stack>
        </Grid>
      </Grid>
    </BorderedItem>
  );
};
