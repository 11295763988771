import { matchPath, useLocation, type Location } from 'react-router';

const visibleOnPaths = ['/app/market/clusters'];
export const useShowQuantInsightsFilters = (): boolean => {
  const location = useLocation();
  return canShowQuantInsightsFilters(location);
};

export const canShowQuantInsightsFilters = (location: Location): boolean => {
  return visibleOnPaths.some((path) => matchPath({ path, end: false }, location.pathname));
};
