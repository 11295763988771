import { type ReactElement, type ReactNode, useState } from 'react';

import type { GButtonProps } from './GButton.props';
import Button from '../../inputs/GButton/GButton.tsx';

const AsyncActionButton = ({
  children,
  color = 'primary',
  width,
  onClick,
  disabled = false,
  variant,
  startDecorator,
}: {
  color?: GButtonProps['color'];
  width?: GButtonProps['width'];
  variant?: GButtonProps['variant'];
  children: ReactNode;
  disabled?: boolean;
  onClick: () => Promise<unknown>;
  startDecorator?: GButtonProps['startDecorator'];
}): ReactElement => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        onClick={async (): Promise<void> => {
          setOpen(true);
          try {
            await onClick();
          } finally {
            setOpen(false);
          }
        }}
        color={color}
        width={width}
        variant={variant}
        loading={open}
        disabled={disabled || open}
        startDecorator={startDecorator}
      >
        {children}
      </Button>
    </>
  );
};

export default AsyncActionButton;
