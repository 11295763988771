export const NO_DATA_SUNBURST_ITEM = {
  ids: ['1'],
  values: [0],
  links: [],
  labels: ['No data'],
  parents: [''],
  text: [],
  marker: {
    colors: [],
  },
};
