import PrivateRoute from './PrivateRoute/PrivateRoute.tsx';
import PageShell from '../components/technical/PageShell/PageShell.tsx';
import Playground from '../components/technical/Playground/Playground.tsx';
import InputPlayground from '../components/technical/Playground/InputPlayground.tsx';
import { SelectPlayground } from '../components/technical/Playground/SelectPlayground.tsx';
import RadioPlayground from '../components/technical/Playground/RadioPlayground.tsx';
import CheckboxPlayground from '../components/technical/Playground/CheckboxPlayground.tsx';
import ButtonPlayground from '../components/technical/Playground/ButtonPlayground.tsx';
import SeeMoreDropdownPlayground from '../components/technical/Playground/SeeMoreDropdownPlayground.tsx';
import { Outlet } from 'react-router';
import MarkdownPlayground from '../components/technical/Playground/MarkdownPlayground.tsx';

export const playgroundRoutes = {
  handle: { title: 'Playground', breadcrumb: 'Playground' },
  path: 'playground',
  element: (
    <PrivateRoute
      to="/"
      element={
        <PageShell>
          <Outlet />
        </PageShell>
      }
    />
  ),
  children: [
    {
      handle: { breadcrumb: 'Playground' },
      element: <Playground />,
      index: true,
    },
    {
      handle: { breadcrumb: 'Inputs' },
      path: 'input',
      element: <InputPlayground />,
    },
    {
      handle: { breadcrumb: 'Selects' },
      path: 'select',
      element: <SelectPlayground />,
    },
    {
      handle: { breadcrumb: 'Radio' },
      path: 'radio',
      element: <RadioPlayground />,
    },
    {
      handle: {
        breadcrumb: 'Checkbox',
      },
      path: 'checkbox',
      element: <CheckboxPlayground />,
    },
    {
      handle: { breadcrumb: 'Buttons' },
      path: 'button',
      element: <ButtonPlayground />,
    },
    {
      handle: {
        breadcrumb: 'See more dropdown',
      },
      path: 'see-more-dropdown',
      element: <SeeMoreDropdownPlayground />,
    },
    {
      handle: {
        breadcrumb: 'Markdown',
      },
      path: 'markdown',
      element: <MarkdownPlayground />,
    },
  ],
};
