import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import type { CellContext } from '@tanstack/table-core';
import isNil from 'lodash/fp/isNil';
import type { ReactElement } from 'react';

import GroupedTable from 'components/technical/GTable/GroupedTable';
import type { GroupedTableRow } from 'components/technical/GTable/GroupedTable.props';

import TableDivider from 'components/technical/GTable/GTableDivider/TableDivider';
import TableDividerLine from 'components/technical/GTable/GTableDividerLine/TableDividerLine';
import { GValueWithChangeCell } from 'components/technical/ValueChange/GValueChange';
import { formatCash } from '../../../../formatter.utils';
import { formatMetric, getNameWithTooltip } from '../../../../metrics/MetricsData';
import AssetLabel from '../../AssetLabel';
import type { Subgroup } from '../../Group.utils';
import type { AssetGroupElement, AssetListGroup } from '../AssetDashboard.types';

const MetricCellValue = ({
  values,
  changes,
  metric,
  showChange = false,
}: {
  values: Record<string, Decimal>;
  changes: Record<string, Decimal>;
  metric: string;
  showChange?: boolean;
}): ReactElement => {
  const value = values[metric];
  if (!value) {
    return <></>;
  }

  const change = changes[metric];
  return (
    <GValueWithChangeCell
      value={formatMetric(metric, values)}
      change={showChange && change !== undefined && change !== null ? Number.parseFloat(change) : null}
    />
  );
};

type AssetSubGroup = Subgroup<AssetGroupElement> & {
  subRows: AssetGroupElement[];
};

type AssetRowType = GroupedTableRow<AssetGroupElement, AssetSubGroup>;

const AssetList = ({
  group,
  navigableAssetIds,
}: {
  group: AssetListGroup;
  navigableAssetIds: Set<string>;
}): ReactElement => {
  const columnHelper = createColumnHelper<AssetRowType>();

  const metric = (metric: string, showChange?: boolean): ColumnDef<AssetRowType> =>
    columnHelper.display({
      id: metric,
      header: () => getNameWithTooltip(metric),
      cell: (props: CellContext<AssetRowType, unknown>): ReactElement | null => {
        const originalRow = props.row.original;
        return originalRow.parent ? (
          <TableDividerLine color={originalRow.color} />
        ) : (
          <MetricCellValue
            values={originalRow.metrics}
            changes={originalRow.change.metrics}
            metric={metric}
            showChange={showChange}
          />
        );
      },
    });

  const columns: ColumnDef<AssetRowType>[] = [
    columnHelper.display({
      header: 'Asset',
      size: 300,
      cell: (props: CellContext<AssetRowType, unknown>): ReactElement => {
        return props.row.original.parent ? (
          <TableDivider
            text={props.row.original.name}
            color={props.row.original.color}
            onClick={(): void => props.row.toggleExpanded()}
          />
        ) : (
          <AssetLabel asset={props.row.original.asset} link={navigableAssetIds.has(props.row.original.asset.id)} />
        );
      },
    }),
    columnHelper.display({
      header: 'Market price',
      cell: (props: CellContext<AssetRowType, unknown>): ReactElement | null => {
        const originalRow = props.row.original;
        return originalRow.parent ? (
          <TableDividerLine color={originalRow.color} />
        ) : (
          <GValueWithChangeCell
            value={formatCash(originalRow.price)}
            change={isNil(originalRow.change.price) ? null : Number.parseFloat(originalRow.change.price)}
          />
        );
      },
    }),
    metric('met:volume-24h', true),
    metric('met:circulating_supply', true),
    metric('met:total_val_locked', true),
    metric('met:rolling_volatility-30', true),
    metric('met:rolling_sharpe-30'),
    metric('met:rolling_sortino-30'),
    metric('met:rolling_var95-30'),
    metric('met:rolling_cvar95-30'),
  ];

  const data: AssetSubGroup[] = group.subgroups
    .map((sub) => ({
      ...sub,
      subRows: sub.elements,
    }))
    .filter((subgroup) => subgroup.subRows.length > 0);

  return <GroupedTable<AssetGroupElement, AssetSubGroup> columns={columns} data={data} />;
};

export default AssetList;
