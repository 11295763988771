import { Skeleton, Typography } from '@mui/joy';
import type { ReactElement, ReactNode } from 'react';

import LineChartSkeleton from 'components/technical/charts/LineChart/LineChartSkeleton.tsx';
import SunburstChartSkeleton from 'components/technical/charts/SunburstChart/SunburstChartSkeleton.tsx';
import { ChartsWrapper } from './ChartsWrapper.tsx';
import { ChartNoAccountFallback } from './portfolio/NoSubAccountFallback.tsx';

const ChartsSkeleton = ({
  titleLeft,
  titleRight,
  onSubAccountConnected,
}: {
  titleLeft: string | ReactNode;
  titleRight: string;
  onSubAccountConnected: () => void;
}): ReactElement => {
  return (
    <ChartsWrapper
      leftTitle={titleLeft}
      rightTitle={titleRight}
      left={
        <ChartNoAccountFallback refetch={onSubAccountConnected}>
          <Typography level={'h3'}>
            <Skeleton animation={false} width={100} />
          </Typography>
          <LineChartSkeleton />
        </ChartNoAccountFallback>
      }
      right={
        <ChartNoAccountFallback refetch={onSubAccountConnected}>
          <SunburstChartSkeleton />
        </ChartNoAccountFallback>
      }
      paper
    />
  );
};

export default ChartsSkeleton;
