import type { IAssetOptimizerResultQuery, IPortfolioOptimizerResultQuery } from 'generated/graphql';

export type PortfolioOptimization = IPortfolioOptimizerResultQuery['optimization']['getOptimization'];
export type AssetOptimization = IAssetOptimizerResultQuery['optimization']['getOptimization'];
export type AssetSolution = AssetOptimization['output'][number] & {
  name: string;
};

export type PortfolioSolution = PortfolioOptimization['output'][number] & {
  name: string;
};
export type Solution = (PortfolioOptimization | AssetOptimization)['output'][number] & {
  name: string;
};

const givenPortfolioId = -1;

export function isGivenPortfolio(solution: { id: number }): boolean {
  return solution.id === givenPortfolioId;
}

export const getSolutionName = (solutionId: number): string =>
  solutionId === givenPortfolioId ? 'Initial portfolio' : `Solution ${solutionId}`;
