import { Card } from '@mui/joy';
import dayjs from 'dayjs';
import type Highcharts from 'highcharts';
import { isNil } from 'lodash/fp';
import { bignumber } from 'mathjs';
import { type FunctionComponent, useRef } from 'react';
import {
  dateTimeAxisFormat,
  dateTimeExportFormat,
  type HighchartSeries,
  tooltipFormat,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import HighChartsWrapper from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper';

import { type IAssetPerformanceQuery, useAssetPerformanceQuery } from '../../../../../../generated/graphql';
import { getRelativeValuesChange } from '../../../../../change.utils';
import { convertDateInUtcToUTCISODate } from '../../../../../date.utils';
import { formatPercentage } from '../../../../../formatter.utils';
import type { PublicAsset } from '../../../Asset.types';

const getMetricValue = <T extends Array<Row>, Row extends { date: UtcDate }>(
  rows: T,
  valueProvider: (el: T[number]) => string | null
): { changes: (number | undefined)[]; time: number[] } => {
  const values = rows.map((row) => {
    return valueProvider(row);
  });

  const changes = getRelativeValuesChange(values);
  return {
    time: rows.map((row) => dayjs.utc(row.date.toString()).valueOf()),
    changes: changes,
  };
};

const calculateChartData = (data: IAssetPerformanceQuery): HighchartSeries[] => {
  if (data.assets.pricePerformance.length === 0 || data.assets.metricPerformance.length === 0) {
    return [];
  }

  const pricePerformance = data.assets.pricePerformance[0].rows;
  const metricPerformance = data.assets.metricPerformance[0].rows;

  const columns = [
    {
      label: 'Price',
      values: getMetricValue(
        pricePerformance.map((perf) => ({
          ...perf,
        })),
        (el) => el.price
      ),
    },
    {
      label: 'M-Cap',
      values: getMetricValue(metricPerformance, (el) => el.metrics['met:market_cap']),
    },
    {
      label: 'TVL',
      values: getMetricValue(metricPerformance, (el) => el.metrics['met:total_val_locked']),
    },
    {
      label: 'Volume',
      values: getMetricValue(metricPerformance, (el) => el.metrics['met:volume-24h']),
    },
    {
      label: 'Liquidity',
      values: getMetricValue(metricPerformance, (el) => el.metrics['met:liquidity']),
    },
  ];

  return columns.map((column): HighchartSeries => {
    const onlyValuePoints = column.values.changes.filter((val): val is number => !isNil(val));

    return {
      data: onlyValuePoints.map((val, i) => {
        return {
          y: val,
          x: column.values.time[i],
          textValue: formatPercentage(val),
        };
      }),
      name: column.label,
      type: 'column',
    };
  });
};

const calculateOptions = (asset: string): Highcharts.Options => {
  return {
    ...dateTimeAxisFormat,
    ...dateTimeExportFormat(`${asset}-performance`),
    ...tooltipFormat,
    yAxis: {
      title: {
        text: undefined,
      },
      labels: {
        formatter: ({ value }) => formatPercentage(bignumber(value).toNumber()),
      },
    },
  };
};

const AssetPerformanceChart: FunctionComponent<{ asset: PublicAsset }> = ({ asset }) => {
  const startDate = useRef(dayjs().utc().subtract(7, 'weeks'));

  const queryOutput = useAssetPerformanceQuery({
    variables: {
      assetId: asset.id,
      fromDate: convertDateInUtcToUTCISODate(startDate.current),
    },
  });

  return (
    <Card>
      <HighChartsWrapper<IAssetPerformanceQuery>
        calculateChartData={calculateChartData}
        calculateOptions={(): Highcharts.Options => calculateOptions(asset.symbol)}
        {...queryOutput}
      />
    </Card>
  );
};

export default AssetPerformanceChart;
