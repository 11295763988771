import { type ReactElement, type ReactNode, type Ref, type RefAttributes, forwardRef } from 'react';

// forwardRef breaks generic component types, this is a workaround
// biome-ignore lint/complexity/noBannedTypes:
export function fixedForwardRef<T, P = {}>(
  render: (props: P, ref: Ref<T>) => ReactElement | ReactNode | null
): (props: P & RefAttributes<T>) => ReactElement | ReactNode | null {
  // biome-ignore lint/suspicious/noExplicitAny:
  return forwardRef(render) as any;
}
