import type { FunctionComponent } from 'react';

import AssetLabel from '../AssetLabel';
import type { NotVerifiedAsset } from '../AssetLabelService.ts';
import { IconVariant } from '../cryptocurrencies/CryptocurrenciesData';

export const HEIGHT_PX = 21;
const AssetSelectOption: FunctionComponent<{
  asset: NotVerifiedAsset;
}> = ({ asset }) => {
  return <AssetLabel asset={asset} link={false} size={IconVariant.MEDIUM} wrap={false} plain />;
};
export default AssetSelectOption;
