import type { ReactElement, ReactNode } from 'react';

import { REPLAY_UNMASK_CLASS_NAME } from 'setup';
import { Box } from '@mui/joy';

export const NAVBAR_ID = 'NAVBAR_ID';

const Navbar = ({ children }: { children: ReactNode }): ReactElement => {
  return (
    <Box
      component={'nav'}
      id={NAVBAR_ID}
      className={REPLAY_UNMASK_CLASS_NAME}
      sx={(theme) => ({
        background: theme.palette.background.body,
        filter: 'drop-shadow(0 0 4px rgba(0, 0, 0, .1))',
        flexShrink: 0,
        padding: '0.5rem 1.5rem',
        position: 'sticky',
        top: '0',
        zIndex: 'var(--navbar-z-index)',
      })}
    >
      {children}
    </Box>
  );
};

export default Navbar;
