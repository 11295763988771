import type Highcharts from 'highcharts';
import isNil from 'lodash/fp/isNil';
import sortBy from 'lodash/fp/sortBy';
import { type ReactElement, useState } from 'react';
import { formatterForName } from 'components/formatter.utils.ts';
import { getFormat, getName, getNameWithTooltip, metricParamsFromParameters } from 'components/metrics/MetricsData.tsx';
import {
  getFormatter,
  getHighchartColor,
  type HighchartSeries,
  shortTooltipFormat,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils.ts';
import HighChartsWrapper from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper.tsx';
import { Select } from 'components/technical/inputs';
import type { SelectOption } from 'components/technical/inputs/Select/Select.props.ts';
import { capitalize, isEqual, uniqBy } from 'lodash/fp';
import type { IParameterType } from '../../../generated/graphql.tsx';
import { type BigNumber, bignumber } from 'mathjs';
import stringify from 'json-stable-stringify';
import { Stack } from '@mui/joy';
import type { CategoryId, OptionValue } from './RiskAggregationsService.ts';
import type { StaticAutocompleteProps } from '../../technical/inputs/Autocomplete/StaticSingleAutocomplete.props.ts';
import StaticSingleAutocomplete from '../../technical/inputs/Autocomplete/StaticSingleAutocomplete.tsx';
import { defaultRowSpacing } from '../../StackSpacing.ts';
import { useFinalColorScheme } from '../../../useFinalColorScheme.ts';

export interface RiskMetric {
  label: string;
  paramDescriptions: {
    name: string;
    type: IParameterType;
    options?:
      | {
          id: string;
          value: string;
          label: string;
        }[]
      | null
      | undefined;
  }[];
  parameters: {
    name: string;
    asset?:
      | {
          id: string;
          symbol: string;
        }
      | null
      | undefined;
    intValue?: number | null | undefined;
    strValue?: string | null | undefined;
  }[];
}

export interface RiskMetricWithValues extends RiskMetric {
  values: {
    item: { id: string; symbol: string };
    value: BigNumber;
  }[];
}

const isSameRiskMetric = (a: RiskMetric, b: RiskMetric): boolean => {
  return (
    isEqual(a.label, b.label) &&
    isEqual(a.parameters, b.parameters) &&
    isEqual(a.paramDescriptions, b.paramDescriptions)
  );
};

const calculateChartData = ({
  risk,
  contributions,
  categories,
  calculateCategory,
  colorScheme,
}: {
  risk: RiskMetric;
  contributions: {
    portfolio: {
      name: string;
    };
    riskMetrics: RiskMetricWithValues[];
  }[];
  categories: CategoryId[];
  calculateCategory: (item: { id: string; symbol: string }, value: BigNumber) => CategoryId;
  colorScheme: 'dark' | 'light';
}): HighchartSeries[] => {
  const formatter = formatterForName(getFormat(risk.label));
  const series: HighchartSeries[] = [];
  for (const contrib of contributions) {
    const categoryToValue = new Map<string, BigNumber>();
    for (const riskMetric of contrib.riskMetrics) {
      if (!isSameRiskMetric(riskMetric, risk)) {
        continue;
      }

      for (const { value, item } of riskMetric.values) {
        const category = calculateCategory(item, value);
        const categoryValue = categoryToValue.get(category.id) ?? bignumber(0);
        categoryToValue.set(category.id, categoryValue.add(value));
      }
    }

    series.push({
      name: capitalize(contrib.portfolio.name),
      data: categories.map((cat) => {
        const value = categoryToValue.get(cat.id) ?? bignumber(0);
        return {
          y: value.toNumber(),
          textValue: formatter(value),
        };
      }),
      color: getHighchartColor(colorScheme, series.length),
      type: 'column',
    } as const);
  }

  return series;
};

const calculateOptions = (
  riskMetricLabel: string,
  sortedCategories: CategoryId[],
  showLegend: boolean
): Highcharts.Options => {
  const formatter = getFormatter(getFormat(riskMetricLabel));
  return {
    chart: {
      type: 'bar',
    },
    ...shortTooltipFormat,
    xAxis: {
      categories: sortedCategories.map((cat) => cat.name),
      lineWidth: 0,
    },
    yAxis: {
      title: {
        text: undefined,
      },
      labels: {
        formatter: formatter,
      },
    },
    legend: {
      enabled: showLegend,
    },
  };
};

const fallbackExposure = {
  clusterId: '',
  getCategory: (): CategoryId => ({
    name: '',
    id: '',
  }),
};

const RiskMetricsContributionChart = ({
  contributions,
  exposureOptions,
  showLegend,
}: {
  contributions: {
    portfolio: {
      name: string;
    };
    riskMetrics: RiskMetricWithValues[];
  }[];
  exposureOptions: Pick<StaticAutocompleteProps<OptionValue>, 'options' | 'isValueEqual'>;
  showLegend: boolean;
}): ReactElement => {
  const colorScheme = useFinalColorScheme();
  const allRiskMeasures = uniqBy(
    (risk) => stringify(risk),
    contributions.flatMap((cont) =>
      cont.riskMetrics.map(({ parameters, paramDescriptions, label }) => ({
        parameters,
        paramDescriptions,
        label,
      }))
    )
  );

  const sortedRiskMeasures = sortBy(
    (risk) => getName(risk.label, metricParamsFromParameters(risk.parameters)),
    allRiskMeasures
  );

  const riskMetricOptions = sortedRiskMeasures.map((metric): SelectOption<string> => {
    const value = stringify(metric);
    const params = metricParamsFromParameters(metric.parameters);
    return {
      key: value,
      label: getNameWithTooltip(metric.label, params),
      value: value,
    };
  });

  const [exposure, setExposure] = useState<OptionValue | null>(
    exposureOptions.options.at(0)?.value ?? fallbackExposure
  );
  const [selectedRisk, setSelectedRisk] = useState<string>(riskMetricOptions.at(0)?.value ?? '');
  const parsedRisk: RiskMetric | undefined = selectedRisk ? JSON.parse(selectedRisk) : undefined;
  const categories = contributions
    .flatMap((cont) =>
      cont.riskMetrics.filter((risk) => {
        if (isNil(parsedRisk)) {
          return false;
        }

        return isSameRiskMetric(risk, parsedRisk);
      })
    )
    .flatMap((risk) =>
      risk.values.flatMap((val) => (isNil(exposure) ? [] : exposure.getCategory(val.item, val.value)))
    );

  const sortedCategories = sortBy(
    (cat: CategoryId) => cat.name,
    uniqBy((cat) => cat.id, categories)
  );

  return (
    <>
      <Stack direction="row" flexWrap="wrap" gap={defaultRowSpacing}>
        <Select
          options={riskMetricOptions}
          value={selectedRisk}
          onChange={setSelectedRisk}
          width="normal"
          disableClearable
        />
        {exposureOptions.options.length > 1 && (
          <StaticSingleAutocomplete {...exposureOptions} value={exposure} onChange={setExposure} width="small" />
        )}
      </Stack>
      <HighChartsWrapper
        data={!isNil(parsedRisk) ? contributions : []}
        loading={false}
        calculateChartData={(contributions): HighchartSeries[] => {
          if (!parsedRisk) {
            return [];
          }

          return calculateChartData({
            colorScheme,
            risk: parsedRisk,
            contributions,
            categories: sortedCategories,
            calculateCategory: exposure?.getCategory ?? fallbackExposure.getCategory,
          });
        }}
        calculateOptions={(): Highcharts.Options => {
          if (isNil(parsedRisk)) {
            return {};
          }

          return calculateOptions(parsedRisk.label, sortedCategories, showLegend);
        }}
      />
    </>
  );
};

export default RiskMetricsContributionChart;
