import { Box, Grid, Stack } from '@mui/joy';
import type { ReactElement } from 'react';
import type { FieldPath, UseFieldArrayRemove, UseFormReturn } from 'react-hook-form';

import AddButton from 'components/technical/AddButton';
import ErrorMessage from 'components/technical/ErrorMessage';
import type { GButtonProps } from 'components/technical/inputs/GButton/GButton.props';
import InputLabel from 'components/technical/inputs/InputLabel';
import { StressTestInputRow } from './StressTestInputRow';
import type { StressTestArrayFields, StressTestInputFields } from './StressTestSimulator.types';
import { hasDirtyField } from '../../form.utils';
import type { PublicAsset } from '../../market/asset/Asset.types';

export interface ImportComponentProps {
  variant: GButtonProps['variant'];
  width: GButtonProps['width'];
  disabled: boolean;
}

export interface StressTestInputListProps {
  valueConfig: {
    label: string;
    startAdornment: string;
    onChangeEffect?: () => void;
  };
  remove: UseFieldArrayRemove;
  add: () => void;
  addNewLabel: string;
  importComponent: (props: ImportComponentProps) => ReactElement;
  supportedAssets: PublicAsset[];
  items: Record<'id', string>[];
  path: StressTestArrayFields;
  lengthPath: FieldPath<StressTestInputFields>;
  methods: UseFormReturn<StressTestInputFields>;
}

const StressTestInputList = ({
  valueConfig,
  remove,
  supportedAssets,
  items,
  path,
  lengthPath,
  methods,
  add,
  addNewLabel,
  importComponent: ImportComponent,
}: StressTestInputListProps): ReactElement => {
  const validateFields = methods.formState.isSubmitted;
  const lengthError = methods.getFieldState(lengthPath, methods.formState).error?.message;

  const secondaryButtonProps = {
    width: 'fullWidth',
    disabled: methods.formState.isSubmitting,
  } as const;

  const canImportItems =
    !methods.formState.isSubmitting &&
    (items.length === 0 ||
      !hasDirtyField({
        items: methods.formState.dirtyFields[path] ?? [],
      }));

  return (
    <Stack spacing={1.5} sx={{ width: '100%' }}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'grid',
          gap: '0.75rem',
          gridAutoRows: 'min-content',
          gridTemplateColumns: 'minmax(200px, 1fr) 1fr min-content',
          width: '100%',
        }}
      >
        <InputLabel label="Asset" />
        <InputLabel label={valueConfig.label} />
        <span />
        {items.map(
          (item, index: number): ReactElement => (
            <StressTestInputRow
              listPath={path}
              supportedAssets={supportedAssets}
              validateFields={validateFields}
              key={item.id}
              index={index}
              total={items.length}
              valueStartAdornment={valueConfig.startAdornment}
              onChangeEffect={valueConfig.onChangeEffect}
              onRemove={(): void => {
                remove(index);
                if (validateFields) {
                  methods.trigger(lengthPath);
                }
              }}
            />
          )
        )}
      </Box>
      <div>{validateFields && lengthError && <ErrorMessage>{lengthError}</ErrorMessage>}</div>
      <Grid container>
        <Grid xs={12} md={6}>
          <AddButton
            {...secondaryButtonProps}
            onClick={(): void => {
              add();

              if (validateFields) {
                methods.trigger(lengthPath);
              }
            }}
          >
            {addNewLabel}
          </AddButton>
        </Grid>
        <Grid xs={12} md={6}>
          <ImportComponent
            {...secondaryButtonProps}
            variant="outlined"
            disabled={secondaryButtonProps.disabled || !canImportItems}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default StressTestInputList;
