import sortBy from 'lodash/fp/sortBy';
import { bignumber, type BigNumber } from 'mathjs';

import type { AssetGroupElement, AssetListGroup } from './AssetDashboard.types';
import bigNumMath from '../../../../bigNumMath';
import { getLightFromRegularChartColor } from '../../../../theme/colors';
import { assignByCluster } from '../AssetService';
import type { Group, Subgroup } from '../Group.utils';
import type { Theme } from '@mui/joy';

export const getMarketCap = (row: { metrics: Record<string, string> }, cap?: BigNumber): BigNumber => {
  const value = bignumber(row.metrics['met:market_cap'] ?? 0);

  if (cap) {
    return bigNumMath.min(cap, value);
  }

  return value;
};

export const assignByMarketCap = <EL extends { metrics: Record<string, string> }>(
  theme: Theme,
  rows: EL[]
): Group<EL> => {
  const sortedRows = sortBy((el) => getMarketCap(el).toNumber(), rows).reverse();

  const totalPartitions = 4;
  const partitionSize = sortedRows.length / totalPartitions;
  const partitions: EL[][] = [];

  let i = 0;
  while (i < sortedRows.length) {
    const newPartition = sortedRows.slice(i, Math.round((partitions.length + 1) * partitionSize));
    partitions.push(newPartition);
    i += newPartition.length;
  }

  return {
    subgroups: partitions.map((part, i): Subgroup<EL> => {
      const selectedColors: string[] = [
        theme.palette.success['400'],
        theme.palette.warning['300'],
        theme.palette.warning['300'],
        theme.palette.danger['500'],
      ];

      const title = ['Top 25%', 'Between 25-50%', 'Between 50-75%', 'Lowest 25%'];

      return {
        id: `subgroup:${i}`,
        elements: part,
        name: title[i],
        color: selectedColors[i],
        elementColor: getLightFromRegularChartColor(selectedColors[i]),
      };
    }),
  };
};

const assignByPriceChange = <EL extends { change: { price?: string | null } }>(theme: Theme, rows: EL[]): Group<EL> => {
  const topSubGroup = [];
  const positiveGroup = [];
  const negativeGroup = [];
  const worstSubgroup = [];

  const upperRange = bignumber(0.05);
  const zero = bignumber(0);
  const lowerRange = bignumber(-0.05);

  for (const asset of rows) {
    const assetChange = asset.change;
    const change = bignumber(assetChange?.price ?? 0);
    if (change.gte(upperRange)) {
      topSubGroup.push(asset);
    } else if (change.gte(zero)) {
      positiveGroup.push(asset);
    } else if (change.gte(lowerRange)) {
      negativeGroup.push(asset);
    } else {
      worstSubgroup.push(asset);
    }
  }

  return {
    subgroups: [
      {
        name: 'Gain > 5%',
        id: 'subgroup-gain-1',
        color: theme.palette.success['400'],
        elementColor: theme.palette.success['300'],
        elements: topSubGroup,
      },
      {
        name: 'Gain 0-5%',
        id: 'subgroup-gain-2',
        color: theme.palette.warning['400'],
        elementColor: theme.palette.warning['300'],
        elements: positiveGroup,
      },
      {
        name: 'Loss 0-5%',
        id: 'subgroup-loss-1',
        color: theme.palette.warning['400'],
        elementColor: theme.palette.warning['300'],
        elements: negativeGroup,
      },
      {
        name: 'Loss > 5%',
        id: 'subgroup-loss-2',
        color: theme.palette.danger['500'],
        elementColor: theme.palette.danger['400'],
        elements: worstSubgroup,
      },
    ],
  };
};

export const assignByClusterForRows =
  <ROW extends { clusters: Record<string, string>; asset: { id: string } }>(
    colorScheme: 'dark' | 'light',
    cluster: Label,
    assetValueProvider: (row: ROW) => BigNumber,
    showNotAssignedAssets: boolean
  ) =>
  (rows: ROW[]): Group<ROW> => {
    const assetToClusters: Record<string, Record<string, string>> = Object.fromEntries(
      rows.map((el) => [el.asset.id ?? '', el.clusters ?? {}])
    );

    const allGroups = Object.values(assetToClusters)
      .map((clusterToGroup) => clusterToGroup[cluster])
      .filter((group): group is string => !!group);

    const uniqueGroupValues = [...new Set(allGroups)].sort();

    return assignByCluster<ROW>({
      colorScheme,
      groupProvider: (row) => assetToClusters[row.asset.id]?.[cluster],
      assetValueProvider,
      groupIndexProvider: (name: string) => uniqueGroupValues.indexOf(name),
      showNotAssignedRows: showNotAssignedAssets,
    })(rows);
  };

export const findGroupAssignmentStrategy = (
  colorScheme: 'dark' | 'light',
  theme: Theme,
  genieCluster: string,
  showNotAssignedAssets: boolean
): ((assets: AssetGroupElement[]) => AssetListGroup) => {
  switch (genieCluster) {
    case 'market':
      return (rows) => assignByMarketCap(theme, rows);
    case 'ecosystem':
      return assignByClusterForRows<AssetGroupElement>(colorScheme, 'ecosystem', getMarketCap, showNotAssignedAssets);
    case 'category':
      return assignByClusterForRows<AssetGroupElement>(colorScheme, 'category', getMarketCap, showNotAssignedAssets);
    case 'sector':
      return assignByClusterForRows<AssetGroupElement>(colorScheme, 'sector', getMarketCap, showNotAssignedAssets);
    case 'mcap_groups':
      return assignByClusterForRows<AssetGroupElement>(colorScheme, 'mcap_groups', getMarketCap, showNotAssignedAssets);
    case 'cluster_price_action_optics':
      return assignByClusterForRows<AssetGroupElement>(
        colorScheme,
        'cluster_price_action_optics',
        getMarketCap,
        showNotAssignedAssets
      );
    case 'price-change':
      return (rows) => assignByPriceChange(theme, rows);
    default:
      throw new Error(`Unknown cluster: ${genieCluster}`);
  }
};
