import sortBy from 'lodash/fp/sortBy';
import type { ReactElement } from 'react';
import { formatPercentage } from 'components/formatter.utils.ts';
import { IReturnStatName } from 'generated/graphql.tsx';
import { Stack } from '@mui/joy';
import { isEmpty } from 'lodash/fp';
import GTable from 'components/technical/GTable/GTable';

import { type CellContext, type ColumnDef, createColumnHelper } from '@tanstack/table-core';
import Help from 'components/technical/Help/Help';

const statNames: Record<IReturnStatName, { name: string; tooltip: string }> = {
  [IReturnStatName.GainProbability]: {
    name: 'Gain probability',
    tooltip: 'Is the probability that your portfolio will have positive returns in the next 7 days',
  },
  [IReturnStatName.LossProbability]: {
    name: 'Loss probability',
    tooltip: 'Is the probability that your portfolio will have negative returns in the next 7 days',
  },
  [IReturnStatName.AverageGain]: {
    name: 'Expected upside',
    tooltip: '7-day expected return in case of gain',
  },
  [IReturnStatName.AverageLoss]: {
    name: 'Expected downside',
    tooltip: '7-day expected return in case of loss',
  },
  [IReturnStatName.MeanReturn]: {
    name: 'Mean return',
    tooltip: 'Is the average return of your portfolio in the next 7 days',
  },
  [IReturnStatName.PctQuantile_5]: {
    name: '5th percentile',
    tooltip: "There's a 5% chance that the returns of your portfolio will be lower than this value in the next 7 days",
  },
  [IReturnStatName.PctQuantile_25]: {
    name: '25th percentile',
    tooltip: "There's a 25% chance that the returns of your portfolio will be lower than this value in the next 7 days",
  },
  [IReturnStatName.PctQuantile_50]: {
    name: '50th percentile',
    tooltip: "There's a 50% chance that the returns of your portfolio will be lower than this value in the next 7 days",
  },
  [IReturnStatName.PctQuantile_75]: {
    name: '75th percentile',
    tooltip: "There's a 75% chance that the returns of your portfolio will be lower than this value in the next 7 days",
  },
  [IReturnStatName.PctQuantile_95]: {
    name: '95th percentile',
    tooltip: "There's a 95% chance that the returns of your portfolio will be lower than this value in the next 7 days",
  },
};

const visibleStats = Object.fromEntries(
  [
    IReturnStatName.GainProbability,
    IReturnStatName.LossProbability,
    IReturnStatName.AverageGain,
    IReturnStatName.AverageLoss,
    IReturnStatName.MeanReturn,
    IReturnStatName.PctQuantile_5,
    IReturnStatName.PctQuantile_25,
    IReturnStatName.PctQuantile_50,
    IReturnStatName.PctQuantile_75,
    IReturnStatName.PctQuantile_95,
  ].map((val, index) => [val, index])
);

type Return = {
  name: IReturnStatName;
  value: number;
};

export type PortfolioReturnsPerformanceListInput = {
  returns: Return[];
  title: string;
  solutionToCompareByStatName?: Partial<Record<IReturnStatName, number>>;
  compareToTitle?: string;
};

export const PortfolioReturnsPerformanceList = ({
  returns,
  title,
  solutionToCompareByStatName = {},
  compareToTitle,
}: PortfolioReturnsPerformanceListInput): ReactElement => {
  const sortedReturns = sortBy(
    (stat) => visibleStats[stat.name],
    returns.filter((stat) => stat.name in visibleStats)
  );

  const columnHelper = createColumnHelper<Return>();
  // biome-ignore lint/suspicious/noExplicitAny:
  const columns: ColumnDef<Return, any>[] = [
    columnHelper.accessor((row) => statNames[row.name]?.name ?? row.name, {
      header: 'Statistic',
      cell: (params: CellContext<Return, string>) => {
        const tooltip = statNames[params.row.original.name]?.tooltip;
        if (isEmpty(tooltip)) {
          return params.getValue();
        }
        return (
          <Stack direction="row" gap={0.2}>
            {params.getValue()}
            <Help>{tooltip}</Help>
          </Stack>
        );
      },
    }),
    columnHelper.accessor('value', {
      header: title,
      meta: { align: 'right' },
      cell: (params: CellContext<Return, number>) => formatPercentage(params.getValue()),
    }),
  ];

  if (compareToTitle) {
    columns.push(
      columnHelper.accessor((row) => solutionToCompareByStatName[row.name], {
        header: compareToTitle,
        meta: { align: 'right' },
        cell: (params: CellContext<Return, number>) => formatPercentage(params.getValue()),
      }),
      columnHelper.accessor(
        (row) => {
          const compareToValue = solutionToCompareByStatName[row.name]!;
          return row.value - compareToValue;
        },
        {
          header: 'Difference',
          meta: { align: 'right' },
          cell: (params: CellContext<Return, number>) => formatPercentage(params.getValue()),
        }
      )
    );
  }

  return (
    <GTable
      data={sortedReturns}
      columns={columns}
      disablePagination
      hideHeaders={isEmpty(solutionToCompareByStatName)}
    />
  );
};
