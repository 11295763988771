import UpdatePortfolioDialog from './UpdatePortfolioDialog.tsx';
import { Pencil } from '../../../../technical/icons';
import type { ReactElement } from 'react';
import { Stack } from '@mui/joy';
import type { IPortfolioDefinitionType } from '../../../../../generated/graphql.tsx';
import type { AccountLabelInputAccount } from '../../../../portfolio/account/AccountLabel.tsx';
import type { SubfundAutocompleteOptionInput } from '../../../../portfolio/fund/SubFundService.tsx';
import { DialogMenuItem } from '../../../../technical/DialogDropdown/DialogMenuItem.tsx';

const UpdatePortfolioDialogItem = ({
  def,
  availableAccounts,
  subFunds,
}: {
  def: {
    id: string;
    name: string;
    description: string;
    type: IPortfolioDefinitionType;
    realDefAccounts: AccountLabelInputAccount[];
    realDefSubFunds: SubfundAutocompleteOptionInput[];
  };
  availableAccounts?: AccountLabelInputAccount[];
  subFunds?: SubfundAutocompleteOptionInput[];
}): ReactElement => (
  <DialogMenuItem
    renderDialog={({ onClose }): ReactElement => (
      <UpdatePortfolioDialog
        onClose={onClose}
        onUpdate={onClose}
        def={def}
        accounts={availableAccounts}
        subFunds={subFunds}
      />
    )}
  >
    <Stack direction="row" alignItems="center" gap={0.5}>
      <Pencil fontSize="md" />
      <span>Edit</span>
    </Stack>
  </DialogMenuItem>
);

export default UpdatePortfolioDialogItem;
