import type { AutocompleteProps as JoyAutocompleteProps } from '@mui/joy';
import React from 'react';

import { SEE_MORE_HEIGHT } from './renderRow.utils.ts';

export interface VirtualizedListContextType<T> {
  getOptionKey: (value: T) => string;
  renderOption: NonNullable<JoyAutocompleteProps<T, false, false, false>['renderOption']>;
  optionHeight?: number;
  hasGroups: boolean;
  menuWidth?: 'fullWidth' | 'smaller' | 'small' | 'normal' | 'xl2' | 'xl3' | 'xl4';
}

export const VirtualizedListContext = React.createContext<VirtualizedListContextType<unknown>>({
  getOptionKey: () => '',
  renderOption: () => null,
  optionHeight: SEE_MORE_HEIGHT,
  hasGroups: false,
  menuWidth: 'normal',
});
