import { Checkbox, type Theme } from '@mui/joy';
import type { CSSProperties, ForwardedRef, ReactElement } from 'react';

import { fixedForwardRef } from 'components/technical/fixedForwardRef';
import type { GCheckboxProps } from './GCheckbox.props';
import widthSx from '../../../width.styles.ts';

const GCheckbox = (props: GCheckboxProps, ref: ForwardedRef<HTMLInputElement>): ReactElement => {
  const allWidthSx = {
    ...widthSx,
    minContent: {
      width: 'auto',
    },
    fullWidth: {
      width: '100%',
    },
  };

  const { shiftByLabelHeight, onChange, ...otherProps } = props;

  return (
    <Checkbox
      {...otherProps}
      onChange={(e) => onChange?.(e.target.checked)}
      sx={[
        (theme: Theme): CSSProperties => ({
          paddingTop: props.shiftByLabelHeight ? theme.spacing(3.75) : 0,
          ...allWidthSx[props.width],
        }),
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]).filter((sx) => !!sx),
      ]}
      slotProps={{
        input: {
          ...props.slotProps?.input,
          ref: ref,
        },
        ...props.slotProps,
      }}
    />
  );
};

const ForwardedGCheckbox = fixedForwardRef(GCheckbox);

export default ForwardedGCheckbox;
