import { Modal, ModalDialog, Stack } from '@mui/joy';
import type { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import GFormProvider from 'components/technical/form/GFormProvider';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton';
import { type GraphQlErrorHandler, useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import { FormInput } from 'components/technical/inputs';

type FormState = { cluster: string };

type CreateRenameClusterDialogProps = {
  onClose: () => void;
  handleFormSubmit: (data: FormState, graphQlErrorHandler: GraphQlErrorHandler) => Promise<void>;
  title: string;
  existingClusters: string[];
  submitButtonText: string;
  currentCluster?: string;
};

const CreateRenameClusterDialog: FunctionComponent<CreateRenameClusterDialogProps> = (props) => {
  const formSchema = yup.object({
    cluster: yup
      .string()
      .required()
      .test('unique', 'Cluster already exists', (value) => !props.existingClusters.includes(value)),
  });
  const methods = useForm<FormState>({
    resolver: gYupResolver(formSchema),
    defaultValues: {
      cluster: props.currentCluster ?? '',
    },
  });
  const { onErrorAndThrow } = useGraphQLApiError(methods);

  return (
    <Modal open onClose={props.onClose}>
      <ModalDialog>
        <GFormProvider {...methods}>
          <form onSubmit={methods.handleSubmit((data) => props.handleFormSubmit(data, onErrorAndThrow))}>
            <GDialogHeader>{props.title}</GDialogHeader>
            <Stack spacing={3} alignItems="center">
              <Stack spacing={1.5}>
                <FormInput type="input" label="Cluster" name="cluster" autoComplete="off" width="xl2" />
              </Stack>
              <Stack alignItems="center" spacing={1.5}>
                <GraphQLApiFormErrorMessage />
                <SubmitButton width="xl2">{props.submitButtonText}</SubmitButton>
              </Stack>
            </Stack>
          </form>
        </GFormProvider>
      </ModalDialog>
    </Modal>
  );
};

export default CreateRenameClusterDialog;
