import { ClickAwayListener } from '@mui/base';
import { Popper } from '@mui/base/Popper';
import { type Dispatch, type ForwardedRef, type ReactElement, type SetStateAction, useRef } from 'react';

import { fixedForwardRef } from 'components/technical/fixedForwardRef';
import type { BaseDateInputProps } from './BaseDateInputProps';
import { DateInputWrapper, type DateInputWrapperProps } from './DateInputWrapper';
import wrapperStyles from './DateInputWrapper.module.css';
import { clickedOutsideElement } from '../../../clickAwayListener.utils';
import { Box } from '@mui/joy';
import widthSx from '../../../width.styles.ts';
import type { DistributiveOmit } from '../../../type.utils.ts';
import type { DateMaskFormat } from '../../../mask.utils.ts';

export type DateInputDropdownProps<FORMAT extends DateMaskFormat> = DistributiveOmit<
  DateInputWrapperProps<FORMAT>,
  'showOpen' | 'open' | 'setOpen'
> & {
  onBlur?: BaseDateInputProps['onBlur'];
  children: ReactElement;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

function DateInputDropdown<FORMAT extends DateMaskFormat>(
  props: DateInputDropdownProps<FORMAT>,
  ref: ForwardedRef<HTMLInputElement>
): ReactElement {
  const anchorEl = useRef<HTMLDivElement>(null);

  return (
    <Box
      className={wrapperStyles.container}
      tabIndex={-1}
      sx={{ ...(props.width !== 'fullWidth' && widthSx[props.width]) }}
      ref={anchorEl}
      onBlur={props.onBlur}
    >
      <DateInputWrapper {...props} showOpen ref={ref} />
      <ClickAwayListener
        onClickAway={(e): void => {
          if (clickedOutsideElement(e, anchorEl.current)) {
            props.setOpen(false);
          }
        }}
      >
        <Popper anchorEl={anchorEl.current} placement="bottom-start" open={props.open}>
          {props.children}
        </Popper>
      </ClickAwayListener>
    </Box>
  );
}

const ForwardedDateInputDropDown = fixedForwardRef(DateInputDropdown);
export { ForwardedDateInputDropDown as DateInputDropdown };
