import { type ReactElement, Suspense, useMemo } from 'react';
import type { UnifiedStressTestOutputFields } from './wizard/UnifiedStressTest.validation.ts';
import {
  IAssetType,
  IDerivativeType,
  type IPositionSide,
  type IShockList,
  IShockMode,
  useUnifiedStressTestSuspenseQuery,
} from '../../../generated/graphql.tsx';
import Loader from '../../technical/Loader/Loader.tsx';
import GErrorBoundary from '../../technical/GErrorBoundary.tsx';
import { useGenerateKeyOnValueChanged } from '../../UseGenerateKeyOnValueChanged.tsx';
import { v4 } from 'uuid';
import type { ShockConfiguration } from '../optionsStressTestOld/OptionsStressTest.types.ts';
import { getFloatStringValues } from '../../number.utils.ts';
import UnifiedStressTestResultReferenceValues from './UnifiedStressTestResultReferenceValues.tsx';
import { Stack } from '@mui/joy';
import UnifiedStressTestResultShocks from './UnifiedStressTestResultShocks.tsx';

const getShockValue = (shock: ShockConfiguration): number[] | undefined => {
  if (shock.enabled) {
    return getFloatStringValues(shock.value).map((val) => val / 100);
  }

  return undefined;
};

const convertShockToRequest = (shockConfig: ShockConfiguration): undefined | IShockList => {
  if (shockConfig.enabled) {
    return {
      shocks: getShockValue(shockConfig),
      mode: IShockMode.Percentage,
    };
  }
  return undefined;
};

const UnifiedStressTestResult = ({
  input,
  indexToId,
}: {
  input: UnifiedStressTestOutputFields;
  indexToId: Map<number, string>;
}): ReactElement => {
  const query = useUnifiedStressTestSuspenseQuery({
    variables: {
      input: {
        scenario: {
          benchmarkShocks: {
            assetId: input.benchmark.id,
            price: convertShockToRequest(input.shocks.priceShock),
            volatility: convertShockToRequest(input.shocks.volatilityShock),
          },
          riskFreeShocks: convertShockToRequest(input.shocks.interestFreeRateShock),
        },
        portfolios: input.portfolios.map((port, i) => ({
          externalId: indexToId.get(i) ?? '',
          options: port.portfolio
            .filter(
              (pos) =>
                pos.asset.type === IAssetType.Derivative &&
                pos.asset.derivativeDetails?.derivativeType === IDerivativeType.Option
            )
            .map((pos) => ({
              amount: pos.amount,
              assetId: pos.asset.id,
              premiumPerContract: pos.premium ?? 0,
              side: pos.side as IPositionSide,
            })),
          perps: port.portfolio
            .filter(
              (pos) =>
                pos.asset.type === IAssetType.Derivative &&
                pos.asset.derivativeDetails?.derivativeType === IDerivativeType.PerpetualFuture
            )
            .map((pos) => ({
              amount: pos.amount,
              assetId: pos.asset.id,
              entryPrice: pos.entryPrice!,
              side: pos.side as IPositionSide,
            })),
          spots: port.portfolio
            .filter((pos) => pos.asset.type !== IAssetType.Derivative)
            .map((pos) => ({
              amount: pos.amount,
              assetId: pos.asset.id,
            })),
        })),
      },
    },
  });

  const idToIndex = new Map(Array.from(indexToId.entries()).map(([i, id]) => [id, i]));
  const response = query.data.scenarioAnalysisV2.scenarioAnalysisV2.map((scen) => ({
    ...scen,
    name: `Portfolio ${(idToIndex.get(scen.externalId ?? '') ?? 0) + 1}`,
  }));

  return (
    <Stack gap={3}>
      <UnifiedStressTestResultReferenceValues response={response} />
      <UnifiedStressTestResultShocks response={response} />
    </Stack>
  );
};

const UnifiedStressTestResultContainer = ({ input }: { input: UnifiedStressTestOutputFields }): ReactElement => {
  // pass key to clear error in tabs when form values change
  const boundaryKey = useGenerateKeyOnValueChanged(input);
  const indexToId = useMemo(() => {
    return new Map<number, string>(input.portfolios.map((_v, i) => [i, v4()]));
  }, [input]);
  return (
    <GErrorBoundary key={boundaryKey}>
      <Suspense fallback={<Loader />}>
        <UnifiedStressTestResult input={input} indexToId={indexToId} />
      </Suspense>
    </GErrorBoundary>
  );
};

export default UnifiedStressTestResultContainer;
