import { Stack, Typography as JoyTypography } from '@mui/joy';
import type { ReactElement } from 'react';
import { DateTimeFormat, formatCash, formatDate } from '../../../../../formatter.utils';
import BigTile from '../../BigTile/BigTile';

const PrivateAssetPriceTile = ({
  price,
  priceDate,
  asset,
}: {
  price: string | null;
  priceDate: UtcDate | null;
  asset: { name: string };
}): ReactElement => {
  return (
    <BigTile
      topLeft={
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <JoyTypography level="h2">{formatCash(price)}</JoyTypography>
          <JoyTypography color="neutral" level="title-sm">
            (at {formatDate(priceDate, DateTimeFormat.Date)})
          </JoyTypography>
        </Stack>
      }
      topRight={
        <JoyTypography color="neutral" level="title-sm">
          {asset.name} price
        </JoyTypography>
      }
    />
  );
};

export default PrivateAssetPriceTile;
