import { Stack } from '@mui/joy';
import dayjs, { type Dayjs } from 'dayjs';
import { isNil } from 'lodash/fp';
import { bignumber } from 'mathjs';
import { type FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { convertDateInUtcToUTCISODate, isValidDayjsDate } from 'components/date.utils';
import { formatISODate } from 'components/formatter.utils';
import { type Asset, getAssets } from 'components/market/asset/Asset.types';
import { createAssetSelectOptions } from 'components/market/asset/AssetService';
import {
  AccountType,
  type SubAccountOptionValue,
  calculateAccountType,
  createSubAccountAutocompleteOptions,
} from 'components/portfolio/account/AccountService';
import { defaultRowSpacing } from 'components/StackSpacing';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import GFormProvider from 'components/technical/form/GFormProvider';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton';
import { FormInput } from 'components/technical/inputs';
import StaticSingleAutocomplete from 'components/technical/inputs/Autocomplete/StaticSingleAutocomplete';
import DateTimeInput from 'components/technical/inputs/date/DateTimeInput';
import GCheckbox from 'components/technical/inputs/GCheckbox/GCheckbox';
import Message from 'components/technical/Message';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import {
  useSnapshotEditorInputQuery,
  useUpdateSnapshotPositionMutation,
  IAssetType,
  useSnapshotPositionsQuery,
  usePositionAvailableAssetsQuery,
  SnapshotPositionsDocument,
  type ISnapshotEditorInputQuery,
} from 'generated/graphql';

const inputWidth = 'normal';

type SubAccount = ISnapshotEditorInputQuery['portfolio']['accounts'][number]['subAccounts'][number];
type SubAccountValue = SubAccountOptionValue<SubAccount>;

const PositionsEditorDashboard: FunctionComponent = () => {
  const inputQueryResult = useDefaultErrorHandling(useSnapshotEditorInputQuery());
  const [selectedSnapshotDate, setSelectedSnapshotDate] = useState<Dayjs | null>(null);
  const [selectedSubAccount, setSelectedSubAccount] = useState<SubAccountValue | null>(null);

  if (!inputQueryResult.loaded) {
    return <inputQueryResult.Fallback />;
  }

  const custodianAccounts = inputQueryResult.data.portfolio.accounts.filter((account) =>
    [AccountType.Custodian, AccountType.Blockchain].includes(calculateAccountType(account))
  );

  const minDate = selectedSubAccount ? dayjs.utc(selectedSubAccount.createdAt) : undefined;

  const isValidDate = isValidDayjsDate(selectedSnapshotDate);
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={defaultRowSpacing} alignItems="flex-end" flexWrap="wrap">
        <StaticSingleAutocomplete
          name="subAccountIds"
          {...createSubAccountAutocompleteOptions(custodianAccounts)}
          label="Sub-account"
          width="normal"
          value={selectedSubAccount}
          onChange={setSelectedSubAccount}
        />
        <DateTimeInput
          label="Date"
          name="date"
          width="normal"
          onChange={setSelectedSnapshotDate}
          value={selectedSnapshotDate}
          showTime={false}
          minDate={minDate}
          error={!isValidDate ? 'Invalid date' : ''}
          maxDate={dayjs.utc().subtract(1, 'day')}
          disabled={!selectedSubAccount}
        />
      </Stack>
      {selectedSnapshotDate && selectedSubAccount && isValidDate ? (
        <AssetPositionEditor date={selectedSnapshotDate} subAccount={selectedSubAccount} />
      ) : undefined}
    </Stack>
  );
};

type FormState = {
  amount: number | null;
};

const formSchema = yup.object().shape({
  amount: yup.number().required(),
});

type AssetPositionEditorProps = {
  date: Dayjs;
  subAccount: SubAccountValue;
};

const AssetPositionEditor: FunctionComponent<AssetPositionEditorProps> = ({ date, subAccount }) => {
  const [onlyExistingAssetPositions, setOnlyExistingAssetPositions] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null);
  const [updateSnapshotPosition, updateSnapshotPositionResult] = useUpdateSnapshotPositionMutation();
  const { showSuccessMessage, showGraphqlError } = useFeedback();

  const methods = useForm<FormState>({
    resolver: gYupResolver(formSchema),
  });

  const positionsQueryResult = useDefaultErrorHandling(
    useSnapshotPositionsQuery({
      variables: {
        date: formatISODate(date),
        subAccountId: subAccount.id,
      },
    })
  );

  const availableAssetsQueryResult = useDefaultErrorHandling(
    usePositionAvailableAssetsQuery({
      variables: { exchange: subAccount.account.venue.label },
    })
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Reset form values and selected asset when date or sub-account changes
  useEffect(() => {
    methods.reset({
      amount: null,
    });
    setSelectedAsset(null);
  }, [date, subAccount, methods]);

  if (!positionsQueryResult.loaded) {
    return <positionsQueryResult.Fallback />;
  }
  if (!availableAssetsQueryResult.loaded) {
    return <availableAssetsQueryResult.Fallback />;
  }

  if (positionsQueryResult.data.portfolio.snapshot.length === 0) {
    return <Message>No snapshot available for selected date.</Message>;
  }

  if (positionsQueryResult.data.portfolio.snapshot.length > 1) {
    return <Message>Error: Multiple snapshots found for selected date. Should be only 1.</Message>;
  }

  const snapshot = positionsQueryResult.data.portfolio.snapshot[0];
  const assetPositionExistsInSnapshot = snapshot.positions.some((x) => x.asset.id === selectedAsset?.id);

  const snapshotSpotAssets = new Set<string>(snapshot.positions.filter((p) => p.spot).map((p) => p.asset.id));

  const availableAssets = availableAssetsQueryResult.data.assets.list.filter((a) => {
    if (onlyExistingAssetPositions && !snapshotSpotAssets.has(a.id)) {
      return false;
    }
    return a.type === IAssetType.Exchange || a.label?.startsWith('spt:');
  });
  const assetAutocompleteProps = createAssetSelectOptions(getAssets(availableAssets));

  const assetIsSelected = !isNil(selectedAsset);

  async function setPosition(formData: FormState): Promise<void> {
    try {
      await updateSnapshotPosition({
        variables: {
          date: convertDateInUtcToUTCISODate(date),
          amount: bignumber(formData.amount).toString(),
          assetId: selectedAsset!.id,
          subAccountId: subAccount.id,
        },
        refetchQueries: [SnapshotPositionsDocument],
      });
      showSuccessMessage('Position successfully updated');
    } catch (e) {
      showGraphqlError(e);
    }
  }

  return (
    <Stack spacing={2}>
      <GCheckbox
        width="minContent"
        shiftByLabelHeight
        checked={onlyExistingAssetPositions}
        label="Show assets for open positions only"
        onChange={(value): void => setOnlyExistingAssetPositions(value)}
      />

      <Stack direction="row" spacing={defaultRowSpacing} alignItems="flex-end" flexWrap="wrap">
        <StaticSingleAutocomplete
          {...assetAutocompleteProps}
          width={inputWidth}
          label="Asset"
          name="asset"
          limitTags={3}
          value={selectedAsset}
          onChange={(newAsset) => {
            setSelectedAsset(newAsset);
            const positionAmount = snapshot.positions.find((x) => x.asset.id === newAsset?.id)?.spot?.amount;
            const amountInitialValue = positionAmount ? bignumber(positionAmount).toNumber() : null;
            methods.setValue('amount', amountInitialValue);
          }}
        />
        <GFormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(setPosition)}>
            <Stack direction="row" spacing={defaultRowSpacing} alignItems="flex-end" flexWrap="wrap">
              <FormInput type="number" name="amount" label="Amount" width={inputWidth} />
              <SubmitButton
                width={inputWidth}
                disabled={!assetIsSelected}
                loading={updateSnapshotPositionResult.loading}
              >
                {assetIsSelected && !assetPositionExistsInSnapshot ? 'Add position' : 'Change position'}
              </SubmitButton>
            </Stack>
          </form>
        </GFormProvider>
      </Stack>
    </Stack>
  );
};

export default PositionsEditorDashboard;
