import type { ReactElement } from 'react';

import InputLabel from 'components/technical/inputs/InputLabel';
import OptionsStressTestShockRow from './OptionsStressTestShockRow';
import { Box } from '@mui/joy';

const OptionsStressTestShockList = (): ReactElement => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'grid',
        gridAutoRows: 'min-content',
        gridTemplateColumns: 'max-content 1fr',
        gap: '0.5rem',
        rowGap: '1rem',
      }}
    >
      <span />
      <InputLabel label="Shock values" />
      <OptionsStressTestShockRow name="shocks.volatilityShock" label="Volatility shock" />
      <OptionsStressTestShockRow name="shocks.priceShock" label="Price shock" />
      <OptionsStressTestShockRow name="shocks.interestFreeRateShock" label="Interest free rate shock" />
    </Box>
  );
};

export default OptionsStressTestShockList;
