import { Stack } from '@mui/joy';
import dayjs from 'dayjs';
import isNil from 'lodash/fp/isNil';
import { type ReactElement, type ReactNode, useEffect, useMemo } from 'react';
import { useForm, type UseFormReturn, useWatch } from 'react-hook-form';

import ErrorMessage from 'components/technical/ErrorMessage';
import { FormDateTimeInput } from 'components/technical/form/FormDateTimeInput';
import FormFreeSoloMultiAutocomplete from 'components/technical/form/FormFreeSoloAutocomplete.tsx';
import FormInput from 'components/technical/form/FormInput';
import FormSelect from 'components/technical/form/FormSelect';
import FormStaticSingleAutocomplete, {
  type FormStaticAutocompleteProps,
} from 'components/technical/form/FormStaticSingleAutocomplete';
import FormTextArea from 'components/technical/form/FormTextArea.tsx';
import GFormProvider from 'components/technical/form/GFormProvider';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import gYupResolver from 'components/technical/form/gYupResolver';
import InputSuggestion from 'components/technical/form/InputSugestion.tsx';
import SubmitButton from 'components/technical/form/SubmitButton.tsx';
import { type GraphQlErrorHandler, useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError.tsx';
import { Header3 } from 'components/technical/Header3';
import {
  type AssetField,
  calculateAssetBuySellVisibility,
  calculateAssetFeeVisibility,
  clearAssetVisibilityErrors,
  formSchema,
  type FormState,
  orderSide,
  orderTypes,
  statusTypes,
  tradeTypes,
  transactionTypes,
} from './TransactionForm.validation';
import { IAssetType, ITransactionType } from '../../../generated/graphql';
import type { Asset } from '../../market/asset/Asset.types';
import { type AssetSelectOptionValue, createAssetSelectOptions } from '../../market/asset/AssetService';
import {
  type CreateSubAccountIdAutocompleteOptionsInputAccount,
  createSubAccountIdSelectOptions,
} from '../../portfolio/account/AccountService.tsx';
import { defaultRowSpacing } from '../../StackSpacing.ts';
import { useMarketValueAutocomplete } from '../UseMarketValueAutocomplete.tsx';
import { isAssetValidForAccount } from '../transactions/create/TransactionForm.validation.ts';

const FORM_SPACING = 1.5;

const AssetValueInputs = (props: {
  prefix: AssetField;
  assetOptions: Pick<FormStaticAutocompleteProps<Asset>, 'options' | 'isValueEqual' | 'optionHeight' | 'limitTags'>;
  error: string | undefined;
  methods: UseFormReturn<FormState, unknown>;
}): ReactElement => {
  const asset = props.methods.getValues(`${props.prefix}.asset`);
  const assetType = asset?.type;

  useEffect(() => {
    if (assetType === IAssetType.Public) {
      props.methods.clearErrors(`${props.prefix}.marketValue`);
    }
  }, [assetType, props.methods, props.prefix]);

  const date = props.methods.getValues('executedAt');
  const autocompleteValue = useMarketValueAutocomplete(date, asset?.id);
  const amount = props.methods.getValues(`${props.prefix}.amount`);
  return (
    <Stack spacing={1}>
      <Stack direction="row" flexWrap="wrap" spacing={FORM_SPACING}>
        <FormStaticSingleAutocomplete<FormState>
          {...props.assetOptions}
          name={`${props.prefix}.asset`}
          label="Asset"
          width="normal"
        />
        <FormInput<FormState> type="number" name={`${props.prefix}.amount` as const} label="Amount" width="normal" />
        <InputSuggestion
          suggestions={
            !autocompleteValue.loading && !autocompleteValue.error && amount
              ? autocompleteValue.prices?.map((suggestion) => `${suggestion.mul(amount)}`)
              : undefined
          }
        >
          <FormInput<FormState>
            type="number"
            name={`${props.prefix}.marketValue` as const}
            label="Total market value"
            startDecorator="$"
            width="normal"
          />
        </InputSuggestion>
      </Stack>
      {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
    </Stack>
  );
};

const filterAssetsBasedOnAccount = (
  assets: AssetSelectOptionValue[],
  account: { venue: { label: string } } | undefined
): AssetSelectOptionValue[] => {
  if (isNil(account)) {
    return assets;
  }

  return assets.filter((asset) => isAssetValidForAccount(asset, account));
};

const TransactionForm = (props: {
  onClose: () => void;
  onSubmit: (data: FormState, onErrorAndThrow: GraphQlErrorHandler) => void;
  onAdded: () => void;
  accounts: CreateSubAccountIdAutocompleteOptionsInputAccount[];
  assets: AssetSelectOptionValue[];
  disableSubAccount?: boolean;
  defaultValues: FormState;
  submitButtonIcon: ReactElement;
  submitButtonText: ReactNode;
  existingTags: string[];
}): ReactElement => {
  const { disableSubAccount } = props;
  const methods = useForm<FormState>({
    resolver: gYupResolver(formSchema),
    defaultValues: props.defaultValues,
  });

  const { getValues, control, resetField, clearErrors, handleSubmit, setValue } = methods;

  const subAccountId = useWatch<FormState, 'subAccount'>({
    name: 'subAccount',
    control: control,
  });

  const subAccountIdToAccount = useMemo(() => {
    return new Map(props.accounts.flatMap((account) => account.subAccounts.map((subAcc) => [subAcc.id, account])));
  }, [props.accounts]);

  const subAccountOptions = createSubAccountIdSelectOptions(props.accounts);
  const assetOptions = createAssetSelectOptions(
    filterAssetsBasedOnAccount(props.assets, subAccountIdToAccount.get(subAccountId ?? ''))
  );

  const transactionType = getValues('type');
  const shouldValidateTradeFields = transactionType === ITransactionType.Trade;

  useEffect(() => {
    if (!shouldValidateTradeFields) {
      resetField('trade.orderType');
      resetField('trade.orderSide');
      resetField('trade.type');
    }
  }, [shouldValidateTradeFields, resetField]);

  useEffect(() => {
    if (disableSubAccount || isNil(subAccountId)) {
      return;
    }

    const account = subAccountIdToAccount.get(subAccountId ?? '');
    if (!account) {
      return;
    }

    // when we change sub-account which doesnt support assets, select box gets blank, but value is still in the model
    const assetFields = ['buy.asset', 'sell.asset', 'fee.asset'] as const;
    for (const field of assetFields) {
      const asset = getValues(field);
      if (!isNil(asset) && !isAssetValidForAccount(asset, account)) {
        setValue(field, undefined);
      }
    }
  }, [subAccountId, disableSubAccount, getValues, setValue, subAccountIdToAccount]);

  const tradeType = getValues('trade.type');
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    clearErrors('trade.orderType');
  }, [tradeType, clearErrors]);

  const visibility = calculateAssetBuySellVisibility(transactionType);
  const { shouldShowBuy, shouldShowSell } = visibility;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const shouldShowFee = calculateAssetFeeVisibility(methods.getValues('fee'));
  clearAssetVisibilityErrors(methods, visibility, shouldShowFee);

  const { onErrorAndThrow } = useGraphQLApiError(methods);

  return (
    <GFormProvider {...methods}>
      <form onSubmit={handleSubmit((data) => props.onSubmit(data, onErrorAndThrow))}>
        <Stack spacing={3}>
          <Stack spacing={1}>
            <div>
              <Header3 title="General information" />
              <Stack spacing={1}>
                <Stack direction="row" flexWrap="wrap" spacing={FORM_SPACING}>
                  <FormSelect<FormState>
                    options={transactionTypes.filter(
                      ({ value }) =>
                        ![
                          ITransactionType.Investment,
                          ITransactionType.Vesting,
                          ITransactionType.Unknown,
                          ITransactionType.TradeDerivative,
                        ].includes(value)
                    )}
                    name="type"
                    label="Type"
                    width="normal"
                  />
                  <FormSelect<FormState>
                    options={subAccountOptions}
                    name="subAccount"
                    label="Sub-account"
                    disabled={props.disableSubAccount}
                    width="normal"
                  />
                  <FormDateTimeInput<FormState>
                    label="Date and time (UTC)"
                    name="executedAt"
                    maxDate={dayjs.utc()}
                    width="normal"
                  />
                </Stack>
                {transactionType === ITransactionType.Trade && (
                  <div>
                    <Header3 title="Extra trade details" />
                    <Stack direction="row" flexWrap="wrap" spacing={FORM_SPACING}>
                      <FormSelect<FormState> options={tradeTypes} name="trade.type" label="Trade type" width="normal" />
                      <FormSelect<FormState>
                        options={orderTypes}
                        name="trade.orderType"
                        label="Order type"
                        width="normal"
                      />
                      <FormSelect<FormState>
                        options={orderSide}
                        name="trade.orderSide"
                        label="Order side"
                        width="normal"
                      />
                    </Stack>
                  </div>
                )}
              </Stack>
            </div>
            <div>
              <Header3 title="Transaction details" />
              <Stack spacing={defaultRowSpacing}>
                {shouldShowBuy && (
                  <div>
                    <Header3 title="Buy" />
                    <AssetValueInputs
                      prefix="buy"
                      assetOptions={assetOptions}
                      error={methods.getFieldState('buy', methods.formState).error?.message}
                      methods={methods}
                    />
                  </div>
                )}
                {shouldShowSell && (
                  <div>
                    <Header3 title="Sell" />
                    <AssetValueInputs
                      prefix="sell"
                      assetOptions={assetOptions}
                      error={methods.getFieldState('sell', methods.formState).error?.message}
                      methods={methods}
                    />
                  </div>
                )}
              </Stack>
            </div>
            <div>
              <Header3 title="Fee" />
              <AssetValueInputs
                prefix="fee"
                assetOptions={assetOptions}
                error={methods.getFieldState('fee', methods.formState).error?.message}
                methods={methods}
              />
            </div>
            <div>
              <Header3 title="Additional" />
              <Stack direction="row" flexWrap="wrap" width="100%" spacing={FORM_SPACING}>
                <FormInput<FormState> name="externalId" label="External id" width="normal" />
                <FormInput<FormState> name="subType" label="SubType" width="normal" />
                <FormSelect<FormState> options={statusTypes} name="status" label="Status" width="normal" />
              </Stack>
            </div>
            <div>
              <FormFreeSoloMultiAutocomplete
                options={props.existingTags}
                name="tags"
                label="Tags"
                width="normal"
                multiple
                newEntryLabel="(New tag)"
              />
            </div>
            <div>
              <FormTextArea name="comment" width="fullWidth" label="Comment" />
            </div>
          </Stack>
          <Stack alignItems="center" spacing={FORM_SPACING}>
            <GraphQLApiFormErrorMessage />
            <SubmitButton width="xl2" startDecorator={props.submitButtonIcon}>
              {props.submitButtonText}
            </SubmitButton>
          </Stack>
        </Stack>
      </form>
    </GFormProvider>
  );
};

export default TransactionForm;
