import { Add } from '@mui/icons-material';
import { Stack, Typography } from '@mui/joy';
import { createColumnHelper } from '@tanstack/react-table';
import { type ReactElement, useEffect } from 'react';
import GTable from 'components/technical/GTable/GTable';
import GButton from 'components/technical/inputs/GButton/GButton';
import {
  ITransactionsUploadStatus,
  ITransactionsUploadType,
  type IUploadTransactionsStatusQuery,
  useUploadTransactionsMutation,
  useUploadTransactionsStatusQuery,
} from 'generated/graphql';
import { useNavigate, useParams } from 'react-router';
import { SubAccountLabel } from 'components/portfolio/account/SubAccountLabel';
import { defaultRowSpacing } from 'components/StackSpacing';
import { getStatusText } from './upload.utils';
import { isNil } from 'lodash/fp';
import { KeyValueItem } from 'components/technical/KeyValueItem';
import Loader from 'components/technical/Loader/Loader';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';

type RowsErrors =
  IUploadTransactionsStatusQuery['bookkeeping']['transactionUpload']['upload']['firstInvalidRows'][number];

function isFinalStatus(status: ITransactionsUploadStatus): boolean {
  return [
    ITransactionsUploadStatus.Succeeded,
    ITransactionsUploadStatus.Failed,
    ITransactionsUploadStatus.ValidationFailed,
  ].includes(status);
}

const UploadStatusAndConfirmation = (): ReactElement => {
  const { uploadId } = useParams<{ uploadId: string }>();
  if (!uploadId) {
    throw new Error('uploadId is required');
  }

  const [uploadTransactions] = useUploadTransactionsMutation();
  const { showGraphqlError } = useFeedback();

  const { data, stopPolling } = useUploadTransactionsStatusQuery({
    variables: { uploadId },
    pollInterval: 2000,
    onError: (e) => {
      showGraphqlError(e);
      stopPolling();
    },
    fetchPolicy: 'no-cache',
  });

  const columnHelper = createColumnHelper<RowsErrors>();

  const navigate = useNavigate();

  useEffect(() => {
    const status = data?.bookkeeping.transactionUpload.upload.status;

    if (status && isFinalStatus(status)) {
      stopPolling();
    }
  }, [data, stopPolling]);

  if (!data) {
    return <Loader />;
  }

  const upload = data.bookkeeping.transactionUpload.upload;

  function uploadValidatedFile(): void {
    uploadTransactions({
      variables: {
        validationUploadId: uploadId!,
      },
      onError: showGraphqlError,
      onCompleted: (data) => navigate(`/app/operations/upload-transactions/${data.uploadTransactions.uploadId}`),
    });
  }

  return (
    <>
      <Stack maxWidth="575px" gap={defaultRowSpacing}>
        <KeyValueItem label="File name" value={upload.fileName} />
        <KeyValueItem
          label={upload.type === ITransactionsUploadType.Validation ? 'Validation status' : 'Upload status'}
          value={getStatusText(upload)}
        />
        <KeyValueItem label="Sub-account" value={<SubAccountLabel subAccount={upload.subAccount} plain />} />
        {!isNil(upload.fileRowsCount) && <KeyValueItem label="File rows" value={upload.fileRowsCount} />}

        {!isNil(upload.createdCount) && upload.type === ITransactionsUploadType.Upload && (
          <KeyValueItem label="Rows created" value={upload.createdCount} />
        )}

        {!isNil(upload.invalidRowsCount) && <KeyValueItem label="Invalid rows" value={upload.invalidRowsCount} />}
        {!isNil(upload.extraTag) && <KeyValueItem label="Additional tag" value={upload.extraTag} />}

        {upload.error && <KeyValueItem label="Error" value={upload.error} />}
      </Stack>

      <Stack gap={defaultRowSpacing} direction="row">
        {upload.possibleToProceedWithUpload && (
          <GButton variant="outlined" onClick={uploadValidatedFile} startDecorator={<Add />} width="xl2">
            {!isNil(upload.invalidRowsCount) && upload.invalidRowsCount > 0
              ? 'Create valid transactions'
              : 'Create transactions'}
          </GButton>
        )}

        <GButton width="xl2" onClick={() => navigate('/app/operations/upload-transactions')}>
          Upload another file
        </GButton>
      </Stack>

      {upload.firstInvalidRows.length > 0 && (
        <>
          <Typography level="body-lg">Review first {upload.firstInvalidRows.length} invalid transactions</Typography>
          <GTable
            columns={[
              columnHelper.accessor('rowNumber', { header: 'Row#' }),
              columnHelper.accessor('row', { header: 'Row' }),
              columnHelper.accessor('message', {
                header: 'Error',
                cell: (info) => (
                  <Typography level="body-md" component="p">
                    {info.getValue()}
                  </Typography>
                ),
              }),
            ]}
            data={upload.firstInvalidRows}
            disablePagination
          />
        </>
      )}
    </>
  );
};

export default UploadStatusAndConfirmation;
