import { Stack } from '@mui/joy';
import dayjs from 'dayjs';
import type Highcharts from 'highcharts';
import sortBy from 'lodash/sortBy';
import { bignumber } from 'mathjs';
import { type FunctionComponent, useMemo, useRef, useState } from 'react';
import { formatPercentage } from 'components/formatter.utils';
import ActionsChartWithTitle from 'components/ActionsChartWithTitle.tsx';
import ActionsContextProvider from 'components/technical/actions/ActionsContextProvider.tsx';
import {
  dateTimeAxisFormat,
  dateTimeExportFormat,
  type HighchartSeries,
  percentageFormatter,
  tooltipFormat,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import HighChartsContainer from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper';
import StaticMultiAutocomplete from 'components/technical/inputs/Autocomplete/StaticMultiAutocomplete';
import { type IAssetClusterGroupPriceQuery, useAssetClusterGroupPriceQuery } from 'generated/graphql';

import { convertDateInUtcToUTCISODate } from '../../../../date.utils';
import type { Asset, DerivativeAsset } from '../../Asset.types';
import { createAssetSelectOptions } from '../../AssetService';

const MAX_ASSETS = 25;
const DATE_RANGE = 30;

type CalculatedAssetToPriceRow = {
  [k: string]: {
    date: number;
    __typename?: 'AssetPriceRow' | undefined;
    price: string | number;
  }[];
};

const getCalculatedReturns = (assetsToPriceRows: CalculatedAssetToPriceRow): CalculatedAssetToPriceRow => {
  const calculatedRows: CalculatedAssetToPriceRow = {};

  for (const [assetId, rows] of Object.entries(assetsToPriceRows)) {
    const initialPrice = rows[0].price;
    calculatedRows[assetId] = rows.map((row) => ({
      ...row,
      price: bignumber(row.price).div(initialPrice).toNumber(),
    }));
  }

  return calculatedRows;
};

const calculateChartData = (
  data: IAssetClusterGroupPriceQuery,
  selectedAssets: { id: string; symbol: string }[]
): HighchartSeries[] => {
  const assetsToPriceRows = Object.fromEntries(
    data.assets.price.map((row) => {
      return [
        row.asset.id,
        sortBy(
          row.rows.map((row) => ({ ...row, date: dayjs.utc(row.date.toString()).valueOf() })),
          (row) => row.date
        ),
      ];
    })
  );

  const calculatedReturns = getCalculatedReturns(assetsToPriceRows);
  return selectedAssets.map((asset) => {
    return {
      name: asset.symbol,
      data: calculatedReturns[asset.id].map((row) => ({
        x: row.date,
        y: bignumber(row.price).toNumber(),
        textValue: formatPercentage(bignumber(row.price).toNumber()),
      })),
      type: 'line',
    };
  });
};

const calculateOptions = (): Highcharts.Options => {
  return {
    ...dateTimeAxisFormat,
    ...dateTimeExportFormat('historical-normalized-price'),
    ...tooltipFormat,
    yAxis: {
      labels: {
        formatter: percentageFormatter,
      },
      title: {
        text: undefined,
      },
    },
    plotOptions: {
      line: {
        marker: {
          symbol: 'circle',
        },
      },
    },
  };
};

const AssetClusterGroupChart: FunctionComponent<{
  groupAssets: Exclude<Asset, DerivativeAsset>[];
}> = ({ groupAssets }) => {
  const [selectedAssets, setSelectedAssets] = useState(groupAssets.slice(0, MAX_ASSETS));
  const assetOptions = useMemo(() => createAssetSelectOptions(groupAssets), [groupAssets]);
  const startDate = useRef(dayjs.utc().subtract(DATE_RANGE, 'days'));

  const queryOutput = useAssetClusterGroupPriceQuery({
    variables: {
      assetIds: selectedAssets.map((asset) => asset.id),
      fromDate: convertDateInUtcToUTCISODate(startDate.current),
    },
  });

  return (
    <ActionsContextProvider>
      <Stack spacing={1}>
        <StaticMultiAutocomplete
          {...assetOptions}
          value={selectedAssets}
          width="xl2"
          onChange={setSelectedAssets}
          label="Assets"
        />
        <ActionsChartWithTitle title="Historical normalized price" paper>
          <Stack spacing={1}>
            {selectedAssets.length > MAX_ASSETS ? (
              <Stack direction="row" justifyContent="center">
                Too many assets selected
              </Stack>
            ) : (
              <HighChartsContainer<IAssetClusterGroupPriceQuery>
                {...queryOutput}
                calculateOptions={calculateOptions}
                calculateChartData={(data): HighchartSeries[] => calculateChartData(data, selectedAssets)}
              />
            )}
          </Stack>
        </ActionsChartWithTitle>
      </Stack>
    </ActionsContextProvider>
  );
};

export default AssetClusterGroupChart;
