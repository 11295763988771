import type { FunctionComponent } from 'react';
import { useParams } from 'react-router';
import { TwoThirdsLayout } from 'components/TwoThirdsLayout.tsx';

import VestingSection from './vesting/VestingSection';
import { useAsset } from '../../UseAsset';
import EventSectionContainer from '../event/EventSectionContainer';

const PrivateAssetVestingTab: FunctionComponent = () => {
  const { assetId } = useParams();

  const { loaded, data: asset, Fallback } = useAsset(assetId!);

  if (!loaded) {
    return <Fallback />;
  }

  return (
    <TwoThirdsLayout left={<VestingSection asset={asset} />} right={<EventSectionContainer assetId={asset.id} />} />
  );
};

export default PrivateAssetVestingTab;
