import { Box, DialogContent, Stack } from '@mui/joy';
import { type FunctionComponent, useState } from 'react';
import { useDrawer } from 'components/technical/Drawer/UseDrawer.tsx';
import GLink from 'components/technical/GLink/GLink';
import AsyncActionButton from 'components/technical/inputs/GButton/AsyncActionButton';
import GButton from 'components/technical/inputs/GButton/GButton';
import GCheckbox from 'components/technical/inputs/GCheckbox/GCheckbox';
import { notificationLink } from 'components/technical/Sidebar/Menu';

import NotificationList from './NotificationList';
import { useMarkAllAsRead } from './useMarkAllAsRead';

const NotificationsDrawer: FunctionComponent = () => {
  const [showUnreadOnly, setShowUnreadOnly] = useState(false);
  const { closeDrawer } = useDrawer();
  const markNotificationsAsRead = useMarkAllAsRead();

  return (
    <DialogContent>
      <Stack spacing={1.5}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1.5}>
          <GCheckbox
            width="normal"
            label="Show unread only"
            onChange={(): void => setShowUnreadOnly(!showUnreadOnly)}
          />
          <GLink to={notificationLink}>
            <GButton variant="plain" onClick={(): void => closeDrawer()} color="primary">
              View all
            </GButton>
          </GLink>
        </Stack>
        <Box
          sx={{
            overflow: 'auto',
          }}
        >
          <NotificationList showUnreadOnly={showUnreadOnly} defaultPageSize={5} hideRowsPerPage />
        </Box>
        <AsyncActionButton onClick={markNotificationsAsRead}>Mark all as read</AsyncActionButton>
      </Stack>
    </DialogContent>
  );
};

export default NotificationsDrawer;
