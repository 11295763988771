import type { PortfolioOptimizerInputFields } from '../portfolio/PortfolioOptimizer.validation.ts';
import type { AssetOptimizerInputFields } from '../asset/AssetOptimizer.validation.ts';
import { FormInput } from '../../../../technical/inputs';
import type { InitialPortfolioStepInput } from './InitialPortfolio.validation.ts';
import { syncOutlookWithGivenUniverseFields } from '../assumptionsAndOutlook/AssumptionsAndOutlook.utils.ts';
import type { ICellRendererParams } from 'ag-grid-enterprise';
import type { ReactElement, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { Stack } from '@mui/joy';

export const InputCellRenderer = (props: ICellRendererParams<{ id: string }, ReactNode>): ReactElement | null => {
  const id = props.data?.id;

  const {
    formState: { isSubmitting },
    getValues,
    setValue,
  } = useFormContext<PortfolioOptimizerInputFields | AssetOptimizerInputFields>();

  if (!id) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <FormInput<InitialPortfolioStepInput>
        type="number"
        name={`givenPortfolio.${id}` as const}
        width="fullWidth"
        disabled={isSubmitting}
        onBlur={() => syncOutlookWithGivenUniverseFields(getValues, setValue)}
        startDecorator="%"
      />
    </Stack>
  );
};
