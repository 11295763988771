import type { ReactElement } from 'react';

import { FactorRegressionCalculator } from './FactorRegressionCalculator';
import { useFactorRegressionInputSuspenseQuery } from '../../../generated/graphql';
import type { BacktestingConfiguration } from '../lab/backtesting/BacktestConfiguration.types.ts';

const FactorDashboard = ({ backtestingConfig }: { backtestingConfig: BacktestingConfiguration }): ReactElement => {
  const { data } = useFactorRegressionInputSuspenseQuery();
  return (
    <FactorRegressionCalculator
      factors={data.factorRegressionV2.supportedFactors}
      presets={data.factorRegressionV2.factorPresets}
      backtestingConfig={backtestingConfig}
    />
  );
};

export default FactorDashboard;
