import type { BigNumber } from 'mathjs';
import type { ReactElement } from 'react';

import DialogButton from 'components/technical/inputs/GButton/DialogButton';
import GButton from 'components/technical/inputs/GButton/GButton';
import type { GButtonProps } from 'components/technical/inputs/GButton/GButton.props';
import { ImportPortfolioDialog } from './ImportPortfolioDialog';
import bigNumMath from '../../bigNumMath';
import { ILeverageType } from '../../generated/graphql';

// the value assumes that percentage is a value from 0 to 1. We want to filter-out assets below 0.01%
export const assetMinPercentageContribution = 0.0001;
export const defaultAvailableCapital = 1000;

const calculateAssetValue = (
  constraintFormulation: 'cash' | 'percentage',
  posValue: BigNumber,
  percentageDenominator?: number | undefined
): BigNumber => (constraintFormulation === 'cash' ? posValue : posValue.mul(100).div(percentageDenominator!));

const addPortfolio = (params: {
  addAssets: (assets: { id: string; value: BigNumber }[]) => void;
  portfolioAssets: { id: string; value: BigNumber }[];
  constraintFormulation: 'cash' | 'percentage';
  percentageDenominator?: number;
}): void => {
  const minCashValue =
    assetMinPercentageContribution *
    (typeof params.percentageDenominator === 'number' ? params.percentageDenominator : defaultAvailableCapital);

  const newAssets: { id: string; value: BigNumber }[] = params.portfolioAssets
    .map((pos): { id: string; value: BigNumber } => ({
      id: pos.id,
      value: calculateAssetValue(params.constraintFormulation, pos.value, params.percentageDenominator),
    }))
    .filter((pos: { id: string; value: BigNumber }) => {
      if (params.constraintFormulation === 'cash') {
        return pos.value.abs().greaterThan(minCashValue);
      }

      return pos.value.abs().div(100).greaterThan(assetMinPercentageContribution);
    });

  params.addAssets(newAssets);
};

export const ImportPortfolioButton = (props: {
  disabled: boolean;
  portfolioAssets: { id: string; value: BigNumber }[];
  replaceAssets: (assets: { id: string; value: BigNumber }[]) => void;
  portfolioAmount: number | undefined;
  constraintFormulation: 'cash' | 'percentage';
  leverageType: ILeverageType;
}): ReactElement => {
  const buttonProps: Partial<GButtonProps> = {
    variant: 'solid',
    width: 'fullWidth',
    disabled: props.disabled,
  };

  const buttonText = 'Import from portfolio';
  const portfolioValue = bigNumMath.sum(props.portfolioAssets.map((asset) => asset.value));
  if (props.constraintFormulation !== 'percentage' || props.leverageType !== ILeverageType.LongShort) {
    return (
      <GButton
        {...buttonProps}
        onClick={(): void => {
          addPortfolio({
            portfolioAssets: props.portfolioAssets,
            addAssets: props.replaceAssets,
            percentageDenominator: portfolioValue.toNumber(),
            constraintFormulation: props.constraintFormulation,
          });
        }}
      >
        {buttonText}
      </GButton>
    );
  }

  return (
    <DialogButton
      {...buttonProps}
      renderDialog={({ onClose }): ReactElement => (
        <ImportPortfolioDialog
          onClose={onClose}
          onPortfolioValueProvided={(value): void => {
            addPortfolio({
              portfolioAssets: props.portfolioAssets,
              addAssets: props.replaceAssets,
              percentageDenominator: value,
              constraintFormulation: props.constraintFormulation,
            });
            onClose();
          }}
          portfolioAmount={props.portfolioAmount}
        />
      )}
    >
      {buttonText}
    </DialogButton>
  );
};
