import isEmpty from 'lodash/fp/isEmpty';
import isNil from 'lodash/fp/isNil';
import type { TestContext, ValidationError } from 'yup';

export const isValidFloatString = (value: unknown, { createError, path }: TestContext): true | ValidationError => {
  // independent validation errors
  if (isNil(value)) {
    return true;
  }

  if (typeof value !== 'string') {
    return true;
  }

  if (value === '') {
    return true;
  }

  const values = value.split(',');
  if (values.length === 0) {
    return createError({
      path,
      message: 'Required',
    });
  }

  if (new Set(values).size !== values.length) {
    return createError({
      path,
      message: 'Has duplicate values',
    });
  }

  for (const val of values) {
    if (isEmpty(val)) {
      return createError({
        path,
        message: 'Has empty value',
      });
    }

    if (Number.isNaN(Number(val))) {
      return createError({
        path,
        message: `${val} is not a number`,
      });
    }
  }

  return true;
};

export const minFloatString = (min: number): ((value: unknown, testContext: TestContext) => true | ValidationError) => {
  return (value: unknown, testContext: TestContext): true | ValidationError => {
    // independent validation errors
    if (isNil(value)) {
      return true;
    }

    if (typeof value !== 'string') {
      return true;
    }

    if (value === '') {
      return true;
    }

    if (!isValidFloatString(value, testContext)) {
      return true;
    }

    const values = getFloatStringValues(value);
    const tooLowValue = values.find((val) => val < min);
    if (!isNil(tooLowValue)) {
      return testContext.createError({
        path: testContext.path,
        message: `Must be greater than or equal to ${min.toFixed(2)}`,
      });
    }

    return true;
  };
};

export const getFloatStringValues = (input: string): number[] => {
  const values = input.split(',');
  return values.map((val) => Number.parseFloat(val));
};
