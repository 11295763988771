import { DarkModeOutlined, LightModeOutlined } from '@mui/icons-material';
import { IconButton, ToggleButtonGroup, useColorScheme } from '@mui/joy';
import type { ReactElement } from 'react';

import type { GButtonProps } from 'components/technical/inputs/GButton/GButton.props';

const ColorSchemeButtonGroup = ({ color }: { color: GButtonProps['color'] }): ReactElement => {
  const { mode, setMode } = useColorScheme();
  return (
    <ToggleButtonGroup
      sx={{
        border: '0 !important', // extra handling for showing toogle button inside a ButtonGroup
      }}
      value={mode}
      onChange={(_e, newValue): void => {
        setMode(newValue);
      }}
      color={color}
    >
      <IconButton value="light">
        <LightModeOutlined />
      </IconButton>
      <IconButton value="dark">
        <DarkModeOutlined />
      </IconButton>
    </ToggleButtonGroup>
  );
};

const ColorSchemeToggler = ({ color }: { color: GButtonProps['color'] }): ReactElement => {
  const { mode, setMode } = useColorScheme();
  const icon = mode === 'light' ? <DarkModeOutlined /> : <LightModeOutlined />;
  return (
    <IconButton
      color={color}
      onClick={(): void => {
        setMode(mode === 'light' ? 'dark' : 'light');
      }}
    >
      {icon}
    </IconButton>
  );
};

const ColorSchemeChanger = ({
  color,
  variant,
}: {
  color: GButtonProps['color'];
  variant: 'button-group' | 'button';
}): ReactElement => {
  if (variant === 'button-group') {
    return <ColorSchemeButtonGroup color={color} />;
  }

  return <ColorSchemeToggler color={color} />;
};

export default ColorSchemeChanger;
