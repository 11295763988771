import { AutocompleteListbox, AutocompleteOption, Box } from '@mui/joy';
import React, { type ReactElement, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { formatNumber } from 'components/formatter.utils';
import { truncateText } from '../../text.styles.ts';

const InputSuggestion = (props: {
  children: React.ReactElement<{ onFocus: () => void; onBlur: () => void; name: string }>;
  suggestions?: string[];
}): ReactElement => {
  const { setValue, clearErrors } = useFormContext();
  const [suggestionVisible, setSuggestionVisible] = useState(false);

  if (!props.suggestions || props.suggestions.length === 0) {
    return props.children;
  }
  const cloned = React.cloneElement(props.children, {
    onFocus: () => {
      props.children.props.onFocus?.();
      setSuggestionVisible(true);
    },
    onBlur: () => {
      props.children.props.onBlur?.();
      setSuggestionVisible(false);
    },
  });

  function selectSuggestion(suggestion: string): void {
    setValue(props.children.props.name, suggestion);
    clearErrors(props.children.props.name);
  }
  const uniqueSuggestions = [...new Set(props.suggestions)];
  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      {cloned}
      {suggestionVisible && (
        <AutocompleteListbox
          sx={{
            position: 'absolute',
            width: '100%',
          }}
        >
          {uniqueSuggestions.map((suggestion) => (
            <AutocompleteOption
              key={suggestion}
              onMouseDown={(): void => selectSuggestion(suggestion)}
              onTouchStart={(): void => selectSuggestion(suggestion)}
            >
              <Box
                sx={{
                  maxWidth: '100%',
                  ...truncateText,
                }}
              >
                {formatNumber(suggestion)}
              </Box>
            </AutocompleteOption>
          ))}
        </AutocompleteListbox>
      )}
    </Box>
  );
};

export default InputSuggestion;
