import { Stack, type StackProps } from '@mui/joy';
import { type ReactElement, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import ErrorMessage from '../ErrorMessage';

const CognitoApiErrorMessage = ({
  justify = 'center',
}: {
  justify?: StackProps['justifyContent'];
}): ReactElement | null => {
  const context = useFormContext();
  const error = context.formState.errors.cognitoApiError;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage: string | undefined = error?.message;

  useEffect(() => {
    const subst = context.watch(() => {
      context.clearErrors('cognitoApiError');
    });

    return (): void => subst.unsubscribe();
  }, [context]);

  if (errorMessage) {
    return (
      <Stack justifyContent={justify} spacing={1.5}>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </Stack>
    );
  }

  return null;
};

export default CognitoApiErrorMessage;
