import { NavigateNext } from '@mui/icons-material';
import { Card, CardContent, CardOverflow, Divider, Link, Stack } from '@mui/joy';
import type { FunctionComponent, PropsWithChildren } from 'react';

import { DASHBOARD_CARD_HEIGHT } from './PortfolioDashboard.types.ts';

type CardWithFooterProps = {
  seeMoreAction: () => void;
};

const CardWithFooter: FunctionComponent<PropsWithChildren<CardWithFooterProps>> = ({ seeMoreAction, children }) => {
  return (
    <Card
      sx={{
        // on desktop cards shown in horizonatl row and should be aligned by height, on mobile they take full width and height can be set by content to save space
        height: { xs: undefined, sm: DASHBOARD_CARD_HEIGHT },
        justifyContent: 'space-between',
      }}
    >
      <Stack justifyContent="space-between" height="100%" overflow={'hidden'}>
        {children}
      </Stack>
      <CardOverflow variant="soft" sx={{ display: { xs: 'none', sm: 'block' } }}>
        <Divider inset="context" />
        <CardContent orientation="horizontal">
          <Link onClick={seeMoreAction} level="body-sm" sx={{ ml: 'auto', alignItems: 'center' }}>
            See more <NavigateNext fontSize="small" />
          </Link>
        </CardContent>
      </CardOverflow>
    </Card>
  );
};

export default CardWithFooter;
