import type { ReactElement, ReactNode } from 'react';
import { type FieldValues, useFormState } from 'react-hook-form';
import ErrorMessage from '../ErrorMessage';

const FormErrorMessage = <T extends FieldValues>({ name }: { name: string }): ReactElement | null => {
  const state = useFormState<T>({
    // @ts-ignore
    name: name,
  });

  const message = state.errors[name]?.message as ReactNode;
  if (!message) {
    return null;
  }

  return <ErrorMessage>{message}</ErrorMessage>;
};

export default FormErrorMessage;
