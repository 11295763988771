import type { ReactElement } from 'react';
import type { DerivativeAsset, PrivateAsset, PublicAsset } from '../../../market/asset/Asset.types';
import AssetSelectOption from '../../../market/asset/AssetSelectOption/AssetSelectOption';
import { createAssetLink } from '../../../market/asset/cryptocurrencies/CryptocurrenciesData';
import GLink from '../../GLink/GLink';

export const SearchResult = (props: {
  asset: PublicAsset | DerivativeAsset | PrivateAsset;
  onClick: () => void;
}): ReactElement => {
  const link = createAssetLink(props.asset);

  return (
    <GLink
      to={link}
      sx={(theme) => ({
        display: 'block',
        padding: '0.25rem 0.75rem',

        '&:hover': {
          background: theme.palette.background.body,
        },

        '&:not(:last-child)': {
          borderBottom: `1px solid ${theme.palette.background.level2}`,
        },
      })}
      onClick={props.onClick}
    >
      <AssetSelectOption asset={props.asset} />
    </GLink>
  );
};
