import type { ReactElement } from 'react';
import { TrianglePointer } from '../icons';

import { Box } from '@mui/joy';

const iconSize = '0.625rem';
const GLinearProgress = ({ value }: { value: number | null }): ReactElement | null => {
  if (!value) {
    return null;
  }

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.background.body,
        borderRadius: 'radius.xs',
        height: '0.5rem',
        display: 'flex',
        lineHeight: '0.5rem',
        width: '100%',
      })}
    >
      <Box
        sx={(theme) => ({
          width: `${value}%`,
          position: 'relative',
          backgroundColor: theme.palette.primary.solidBg,
          borderRadius: theme.radius.xs,
        })}
      >
        <Box sx={{ position: 'absolute', right: '0', transform: 'translate(50%, calc(-100% - 0.1rem))' }}>
          <TrianglePointer
            sx={{
              height: iconSize,
              width: iconSize,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default GLinearProgress;
