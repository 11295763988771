import type { FormInputType } from '../../../../technical/form/Form.types.ts';
import * as yup from 'yup';

export type FormOutputFields = {
  name: string;
  description: string;
};

export const basePortfolioSchema = yup.object({
  name: yup.string().required(),
  description: yup.string(),
});

export type FormInputFields = FormInputType<FormOutputFields>;
