import { TupleKeyMap } from 'components/TupleKeyMap.ts';
import type { IPortfolioReportQuery } from 'generated/graphql';

/**
 * important prop: for one account and one dimension should be ONLY ONE correspondent sub-fund
 * @returns accountAndDimensionToSubFund: TupleKeyMap [account.id, dimension] -> sub-fund
 * @returns subFundDimensions: list of uniq sub-fund dimensions
 */
export function mapAccountAndDimensionToSubFund(subFunds: IPortfolioReportQuery['portfolio']['subFunds']['list']): {
  accountAndDimensionToSubFund: TupleKeyMap<[string, string], string>;
  subFundDimensions: string[];
} {
  const accountAndDimensionToSubFund = new TupleKeyMap<[string, string], string>();
  const subFundDimensions = new Set<string>();
  for (const subFund of subFunds) {
    subFundDimensions.add(subFund.dimension);
    for (const account of subFund.accounts) {
      accountAndDimensionToSubFund.set([account.id, subFund.dimension], subFund.name);
    }
  }
  return {
    accountAndDimensionToSubFund,
    subFundDimensions: Array.from(subFundDimensions).sort(),
  };
}
