import { Autocomplete as JoyAutocomplete, AutocompleteOption, Box, createFilterOptions, FormControl } from '@mui/joy';
import type { FunctionComponent, ReactNode } from 'react';
import { createRenderTagsFunction } from './GAutocomplete.utils';
import type { GInputProps } from '../GInput/GInput.props';
import InputError from '../InputError';
import InputLabel from '../InputLabel';
import { calculatePlaceholder, shouldRenderInputLabel } from '../LabelService.ts';
import type { DefaultColorPalette } from '@mui/joy/styles/types/colorSystem';
import { truncateText } from '../../../text.styles.ts';
import widthSx from '../../../width.styles.ts';

const filter = createFilterOptions<string>();

type FreeSoloAutocompletePropsShared = {
  options: string[];
  onBlur?: () => void;
  disabled?: boolean;
  label: string;
  limitTags?: number;
  error?: string;
  width?: GInputProps['width'];
  color?: DefaultColorPalette;
  newEntryLabel: string;
  fullHeight?: boolean;
};

type FreeSoloSingleAutocompleteProps = FreeSoloAutocompletePropsShared & {
  multiple: false;
  onChange: (option: string | null) => void;
  value: string | null;
};

type FreeSoloMultiAutocompleteProps = FreeSoloAutocompletePropsShared & {
  multiple: true;
  onChange: (option: string[]) => void;
  value: string[] | null;
};

export type FreeSoloAutocompleteProps = FreeSoloSingleAutocompleteProps | FreeSoloMultiAutocompleteProps;

/**
 * Similar to StaticMultiAutocomplete, but it allows adding new/unknown string values, not from options list
 * also for simplicity it's not generic - options are only string values
 */
const FreeSoloAutocomplete: FunctionComponent<FreeSoloAutocompleteProps> = (props) => {
  const {
    options,
    value,
    onBlur,
    onChange,
    fullHeight,
    disabled,
    limitTags = 5,
    error,
    width,
    multiple,
    newEntryLabel,
  } = props;

  const height = fullHeight ? '100%' : 'auto';
  const allWidthSx = {
    fullWidth: {},
    ...widthSx,
  };

  return (
    <Box
      sx={{
        minWidth: 0,
        width: '100%',
        height,
        ...allWidthSx[width ?? 'fullWidth'],
      }}
    >
      <FormControl
        error={Boolean(error)}
        sx={{
          height,
        }}
      >
        {shouldRenderInputLabel(props) ? <InputLabel {...props} /> : <></>}
        <JoyAutocomplete
          placeholder={calculatePlaceholder(props)}
          color={props.color}
          onBlur={onBlur}
          value={value}
          onChange={(_event, value): void => {
            if (multiple && Array.isArray(value)) {
              onChange(value);
              return;
            }
            if (!multiple && (typeof value === 'string' || value === null)) {
              onChange(value);
              return;
            }

            throw new Error(
              `FreeSoloAutocomplete received unexpected value type. Expected ${
                multiple ? 'an array of strings' : 'a string or null'
              } but received type ${typeof value} - value ${value}.`
            );
          }}
          options={options}
          freeSolo
          multiple={multiple}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          disableClearable={fullHeight}
          filterSelectedOptions
          sx={{
            height,
          }}
          slotProps={{
            wrapper: {
              sx: fullHeight
                ? {
                    alignItems: 'flex-start',
                  }
                : {},
            },
          }}
          limitTags={limitTags}
          readOnly={disabled}
          renderOption={(props, option): ReactNode => (
            // @ts-expect-error key is not present in props, but is passed at runtime
            <AutocompleteOption {...props} key={props.key}>
              <Box
                sx={{
                  maxWidth: '100%',
                  ...truncateText,
                }}
              >
                {options.includes(option) ? option : `"${option}" ${newEntryLabel}`}
              </Box>
            </AutocompleteOption>
          )}
          filterOptions={(options, params): string[] => {
            const filteredOptions = filter(options, params);

            const exactOptionMatch = filteredOptions.includes(params.inputValue);
            const alreadyHasThatTag = Array.isArray(value) && value.includes(params.inputValue);

            if (params.inputValue !== '' && !exactOptionMatch && !alreadyHasThatTag) {
              // add option to create new tag in addition to existing tags
              filteredOptions.push(params.inputValue);
            }

            return filteredOptions;
          }}
          renderTags={createRenderTagsFunction({
            showChipTooltips: true,
            getOptionLabel: (item: string): string => item,
          })}
          getLimitTagsText={(more: string | number): ReactNode => `+${more}`}
        />
        <InputError error={error} />
      </FormControl>
    </Box>
  );
};

export default FreeSoloAutocomplete;
