import PageShell from '../components/technical/PageShell/PageShell.tsx';
import OrderList from '../components/bookkeeping/orders/OrderList.tsx';
import TransactionDashboardOld from '../components/bookkeeping/transactionsOld/TransactionDashboard.tsx';
import TransactionDashboard from '../components/bookkeeping/transactions/TransactionDashboard.tsx';
import TradingActivityDashboard from '../components/bookkeeping/tradingActivity/TradingActivityDashboard.tsx';
import UploadTransactions from '../components/bookkeeping/transactionsOld/upload/UploadTransactions.tsx';
import UploadStatusAndConfirmation from '../components/bookkeeping/transactionsOld/upload/UploadStatusAndConfirmation.tsx';
import { createIndexRoute } from './CreateIndexRoute.tsx';
import InvestmentDashboard from '../components/bookkeeping/investments/InvestmentDashboard.tsx';
import PortfolioReport from '../components/bookkeeping/report/PortfolioReport.tsx';
import ReconciliationReportContainer from '../components/bookkeeping/reconciliation/ReconciliationReport.tsx';
import CostBasisReport from '../components/portfolio/journal/costBasisReport/CostBasisReport.tsx';

export const operationRoutes = {
  handle: { breadcrumb: 'Operations' },
  path: 'operations',
  children: [
    {
      handle: { title: 'Orders', breadcrumb: 'Orders' },
      path: 'orders',
      element: (
        <PageShell>
          <OrderList />
        </PageShell>
      ),
    },
    {
      handle: { title: 'Transactions', breadcrumb: 'Transactions' },
      path: 'transactions-old',
      element: (
        <PageShell>
          <TransactionDashboardOld />
        </PageShell>
      ),
    },
    {
      handle: { title: 'Transactions', breadcrumb: 'Transactions' },
      path: 'transactions',
      element: (
        <PageShell>
          <TransactionDashboard />
        </PageShell>
      ),
    },
    {
      handle: { breadcrumb: 'Trading activity' },
      path: 'trading-activity',
      element: (
        <PageShell>
          <TradingActivityDashboard />
        </PageShell>
      ),
    },
    {
      handle: { title: 'Upload transactions', breadcrumb: 'Upload Transactions' },
      path: 'upload-transactions',
      children: [
        {
          path: 'upload',
          element: (
            <PageShell>
              <UploadTransactions />
            </PageShell>
          ),
        },
        {
          handle: { title: 'Upload details', breadcrumb: 'Upload details' },
          path: ':uploadId',
          element: (
            <PageShell>
              <UploadStatusAndConfirmation />
            </PageShell>
          ),
        },
        createIndexRoute('upload'),
      ],
    },
    {
      handle: { title: 'Investments', breadcrumb: 'Investments' },
      path: 'investments',
      element: (
        <PageShell>
          <InvestmentDashboard />
        </PageShell>
      ),
    },
    {
      handle: { breadcrumb: 'Report' },
      path: 'report',
      element: (
        <PageShell>
          <PortfolioReport />
        </PageShell>
      ),
    },
    {
      handle: { title: 'Reconciliation', breadcrumb: 'Reconciliation' },
      path: 'reconciliation',
      element: (
        <PageShell>
          <ReconciliationReportContainer />
        </PageShell>
      ),
    },
    {
      handle: { title: 'Cost basis', breadcrumb: 'Cost basis' },
      path: 'cost-basis',
      element: (
        <PageShell>
          <CostBasisReport />
        </PageShell>
      ),
    },
    createIndexRoute('orders'),
  ],
};
