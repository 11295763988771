import { useContext, useMemo } from 'react';

import { type ImperativeModal, Context } from './ImperativeModalContext.tsx';

export interface UseImperativeModal {
  showModal(modal: ImperativeModal): void;
}

export const useImperativeModal = (): UseImperativeModal => {
  const context = useContext(Context);

  return useMemo(
    () => ({
      showModal: (modal: ImperativeModal): void => {
        context.showModal(modal);
      },
    }),
    [context]
  );
};
