import type { FunctionComponent, ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import FreeSoloMultiAutocomplete, { type FreeSoloAutocompleteProps } from '../inputs/Autocomplete/FreeSoloAutocomplete';

type FormFreeSoloAutocompleteProps = Omit<FreeSoloAutocompleteProps, 'value' | 'onChange' | 'onBlur'> & {
  name: string;
};

const FormFreeSoloAutocomplete: FunctionComponent<FormFreeSoloAutocompleteProps> = (props) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field: { value, onChange, onBlur }, formState, fieldState }): ReactElement => {
        return (
          <FreeSoloMultiAutocomplete
            {...props}
            onBlur={onBlur}
            value={value}
            onChange={onChange}
            disabled={!!props.disabled || formState.isSubmitting || formState.disabled}
            error={props.error ? props.error : fieldState.error?.message}
          />
        );
      }}
    />
  );
};

export default FormFreeSoloAutocomplete;
