/** combine metric label and asset benchmark to get some uniq id */
export const createMetricLabelWithBenchmark = (
  measure: string,
  benchmark: undefined | null | { id: string }
): string => {
  if (!benchmark) {
    return measure;
  }

  return `${measure}_${benchmark.id}`;
};
