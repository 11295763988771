import { Box, Card } from '@mui/joy';
import dayjs, { type Dayjs } from 'dayjs';
import isNil from 'lodash/fp/isNil';
import { Fragment, type ReactElement, useState } from 'react';
import HeaderBar from '../HeaderBar/HeaderBar';
import { Search } from '../icons';
import { Select } from '../inputs';
import StaticAutocomplete from '../inputs/Autocomplete/StaticSingleAutocomplete';
import StaticSingleAutocomplete from '../inputs/Autocomplete/StaticSingleAutocomplete';
import type { StaticAutocompleteOption } from '../inputs/Autocomplete/StaticSingleAutocomplete.props';
import DateRangeInput from '../inputs/date/DateRangeInput';
import DateTimeInput from '../inputs/date/DateTimeInput';
import GInput from '../inputs/GInput/GInput';
import SectionColumn from '../layout/Column/SectionColumn';
import SectionPanel from '../layout/SectionPanel';
import StaticMultiAutocomplete from '../inputs/Autocomplete/StaticMultiAutocomplete.tsx';
import FreeSoloAutocomplete from '../inputs/Autocomplete/FreeSoloAutocomplete.tsx';

// biome-ignore lint/suspicious/noExplicitAny:
const showInputs = (prop: any): ReactElement => {
  const { showValue, ...nonValueProps } = prop;
  const options: StaticAutocompleteOption<string>[] = [
    { value: 'text', searchText: 'text', label: 'val label', key: 'key1' },
    { value: 'text2', searchText: 'text', label: 'val label 2', key: 'key2' },
  ];

  const [textState, setTextState] = useState<string>(showValue ? 'text' : '');
  const [selectState, setSelectState] = useState<string | null>(showValue ? 'text' : '');
  const [singleAutocompleteState, setSingleAutocompleteState] = useState<string | null>(showValue ? 'text' : '');
  const [multiAutocompleteState, setMultiAutocompleteState] = useState<string[]>(showValue ? ['text'] : []);
  const [dateRangeState, setDateRangeState] = useState<[Dayjs, Dayjs] | null>(
    showValue ? [dayjs('2022-01-10'), dayjs('2022-01-15')] : null
  );

  const [dateTimeState, setDateTimeState] = useState<Dayjs | null>(showValue ? dayjs('2022-01-10 12:00') : null);

  return (
    <>
      <GInput label="test" {...nonValueProps} value={textState} onChange={(val): void => setTextState(val ?? '')} />
      <Select<string | null>
        label="test"
        variant="normal"
        options={options}
        {...nonValueProps}
        value={selectState}
        onChange={(value): void => setSelectState(value)}
      />
      <StaticSingleAutocomplete<(typeof options)[number]['value']>
        label="test"
        width={'fullWidth'}
        options={options}
        {...nonValueProps}
        value={singleAutocompleteState}
        onChange={(value): void => setSingleAutocompleteState(value)}
      />
      <StaticMultiAutocomplete<(typeof options)[number]['value']>
        label="test"
        width={'fullWidth'}
        options={options}
        {...nonValueProps}
        value={multiAutocompleteState}
        onChange={(value): void => setMultiAutocompleteState(value)}
      />
      <FreeSoloAutocomplete
        label="test"
        multiple={true}
        width={'fullWidth'}
        options={options}
        {...nonValueProps}
        value={multiAutocompleteState}
        newEntryLabel={'New entry'}
        onChange={(val: string[]): void => setMultiAutocompleteState(val ? val : [])}
      />
      <DateRangeInput
        label="test"
        {...nonValueProps}
        value={dateRangeState}
        onChange={(val): void => setDateRangeState(val)}
      />
      <DateTimeInput
        label="test"
        {...nonValueProps}
        value={dateTimeState}
        onChange={(val): void => setDateTimeState(val)}
      />
    </>
  );
};

const InputSizePlayground = (): ReactElement => {
  const options: StaticAutocompleteOption<string>[] = [
    { value: 'text', searchText: 'label 1', label: 'Label 1', key: 'key1' },
    { value: 'text2', searchText: 'label 2', label: 'Label 2', key: 'key2' },
  ];

  const widths = [undefined, 'normal', 'xl2', 'xl3', 'xl4', 'fullWidth'];
  const components = [
    {
      // biome-ignore lint/suspicious/noExplicitAny:
      factory: (width: any): ReactElement => <GInput label="Label" value="text" width={width} />,
      name: 'input',
    },
    {
      // biome-ignore lint/suspicious/noExplicitAny:
      factory: (width: any): ReactElement => <Select label="Label" options={options} value="text" width={width} />,
      name: 'select',
    },
    {
      // biome-ignore lint/suspicious/noExplicitAny:
      factory: (width: any): ReactElement => (
        <StaticAutocomplete<(typeof options)[number]['value']>
          label="Label"
          options={options}
          value="text"
          width={width}
          limitTags={1}
        />
      ),
      name: 'autocomplete',
    },
    {
      // biome-ignore lint/suspicious/noExplicitAny:
      factory: (width: any): ReactElement => (
        <DateRangeInput label="Label" value={[dayjs('2022-01-10'), dayjs('2022-01-15')]} width={width} />
      ),
      name: 'date range',
    },
    {
      // biome-ignore lint/suspicious/noExplicitAny:
      factory: (width: any): ReactElement => (
        <DateTimeInput label="Label" value={dayjs('2022-01-10 12:00')} width={width} />
      ),
      name: 'datetime',
    },
  ];

  return (
    <SectionPanel title="Sizes">
      <Box
        sx={{
          alignItems: 'center',
          display: 'grid',
          gridTemplateColumns: '200px 1fr',
          gap: '1rem',
        }}
      >
        {widths.flatMap((width, wi) =>
          components.map((comp, i) => (
            <Fragment key={`${wi}-${i}`}>
              <span>
                Width: {isNil(width) ? 'default' : width} {comp.name}
              </span>
              <span>{comp.factory(width)}</span>
            </Fragment>
          ))
        )}
      </Box>
    </SectionPanel>
  );
};

const InputPlayground = (): ReactElement => {
  return (
    <SectionColumn>
      <HeaderBar title="Inputs" />
      <Card
        sx={{
          alignItems: 'center',
          display: 'grid',
          gridTemplateColumns:
            '150px repeat(2, minmax(100px, 1fr)) repeat(3, minmax(125px, 1fr)) minmax(240px, 2fr) minmax(220px,2fr)',
          gap: '1rem',
        }}
      >
        <span>State</span>
        <span>Input</span>
        <span>Select</span>
        <span>Static single autocomplete</span>
        <span>Static multi autocomplete</span>
        <span>Multifree solo autocomplete</span>
        <span>Date range picker</span>
        <span>Date time picker</span>

        <span>Label should show in place of placeholder</span>
        {showInputs({ label: 'label', showValue: true, placeholder: 'placeholder' })}

        <span>Value should show in place of label</span>
        {showInputs({ label: 'label', showValue: true })}

        <span>Value should be colored when displaying error</span>
        {showInputs({ error: 'error', showValue: true })}

        <span>Label should be colored when displaying error</span>
        {showInputs({ label: 'label', error: 'error' })}

        <span>Placeholder should be colored when displaying error</span>
        {showInputs({
          placeholder: 'placeholder',
          error: 'error',
        })}

        <span>Icon shouldnt be colored when showing error</span>
        {showInputs({
          startDecorator: <Search color="inherit" />,
          error: 'error',
        })}

        <span>Label should be disabled</span>
        {showInputs({
          label: 'label',
          disabled: true,
        })}

        <span>Value should be disabled</span>
        {showInputs({
          showValue: true,
          disabled: true,
        })}

        <span>Label should be disabled and have required attr</span>
        {showInputs({
          label: 'label',
          disabled: true,
          required: true,
        })}

        <span>Label should be colored and have required attr</span>
        {showInputs({
          color: 'primary',
          label: 'label',
          required: true,
        })}
        <span>Label should show above input in black</span>
        {showInputs({
          color: 'primary',
          label: 'label',
          value: null,
          showLabelAboveInput: true,
        })}
      </Card>
      <InputSizePlayground />
    </SectionColumn>
  );
};

export default InputPlayground;
