import dayjs, { type Dayjs } from 'dayjs';
import isNil from 'lodash/fp/isNil';

export const getLastUpdatedDate = (
  accounts: {
    snapshotSyncStatus: {
      latestSuccessfulTime?: string | null;
    };
  }[]
): Dayjs | null => {
  return dayjs.max(
    accounts
      .filter(
        (
          acc
        ): acc is {
          snapshotSyncStatus: {
            latestSuccessfulTime: string;
          };
        } => !isNil(acc.snapshotSyncStatus.latestSuccessfulTime)
      )
      .map((wall) => dayjs(wall.snapshotSyncStatus.latestSuccessfulTime))
  );
};
