/* eslint-disable no-console */
import { Card, MenuItem } from '@mui/joy';
import type { ReactElement } from 'react';
import { useNavigate } from 'react-router';

import SeeMoreDropdown from '../SeeMoreDropDown/SeeMoreDropdown.tsx';
import ConfirmationDialog from '../form/dialog/ConfirmationDialog';
import HeaderBar from '../HeaderBar/HeaderBar';
import SectionColumn from '../layout/Column/SectionColumn';
import { DialogMenuItem } from '../DialogDropdown/DialogMenuItem.tsx';

const SeeMoreDropdownPlayground = (): ReactElement => {
  const navigate = useNavigate();

  return (
    <SectionColumn>
      <HeaderBar title="See more dropdown" />
      <Card>
        <SeeMoreDropdown>
          <MenuItem onClick={(): void => console.log('You can log anything!')}>Console log</MenuItem>
          <MenuItem onClick={(): void => navigate('/app/playground')}>Navigate to playground</MenuItem>
          <DialogMenuItem
            renderDialog={({ onClose }): ReactElement => (
              <ConfirmationDialog
                onClose={onClose}
                onApprove={() => {
                  console.log('Item removed successfully');
                  onClose();
                }}
              >
                Are you sure you want to remove an item?
              </ConfirmationDialog>
            )}
          >
            Removal modal
          </DialogMenuItem>
        </SeeMoreDropdown>
      </Card>
    </SectionColumn>
  );
};

export default SeeMoreDropdownPlayground;
