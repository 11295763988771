import { TabPanel } from '@mui/joy';
import GErrorBoundary from '../../../technical/GErrorBoundary.tsx';
import Loader from '../../../technical/Loader/Loader.tsx';
import { type ReactElement, type ReactNode, Suspense } from 'react';

const PortfolioResultTab = ({ value, children }: { value: string; children: ReactNode }): ReactElement => (
  <TabPanel value={value} keepMounted={true}>
    <GErrorBoundary>
      <Suspense fallback={<Loader />}>{children}</Suspense>
    </GErrorBoundary>
  </TabPanel>
);

export default PortfolioResultTab;
