import isNil from 'lodash/fp/isNil';
import sortBy from 'lodash/fp/sortBy';
import type { ReactElement } from 'react';
import { formatterForName } from 'components/formatter.utils';
import { getFormat, getName, getNameWithTooltip } from 'components/metrics/MetricsData';

import GTable from 'components/technical/GTable/GTable.tsx';
import { type CellContext, type ColumnDef, createColumnHelper } from '@tanstack/table-core';
import { createMetricLabelWithBenchmark } from './PortfolioRisk.utils';
import type { Solution } from './Results.types.ts';

type RiskRow = Solution['riskMeasures'][number];

const PortfolioRiskMetricsList = <SOLUTION extends Solution>({
  solution,
  compareToSolution,
  getLabelOfBenchmark,
}: {
  solution: SOLUTION;
  compareToSolution: SOLUTION | null;
  getLabelOfBenchmark: (benchmark: SOLUTION['riskMeasures'][number]['benchmark']) => string | undefined;
}): ReactElement => {
  const riskMeasures = sortBy(
    (riskMetric: SOLUTION['riskMeasures'][number]) =>
      getName(riskMetric.measure, { assetSymbol: getLabelOfBenchmark(riskMetric.benchmark) }),
    solution.riskMeasures
  );
  const compareToSolutionByMetricWithBenchmark = Object.fromEntries(
    (compareToSolution?.riskMeasures ?? []).map((riskMeasure) => [
      createMetricLabelWithBenchmark(riskMeasure.measure, riskMeasure.benchmark),
      riskMeasure,
    ])
  );
  const columnHelper = createColumnHelper<RiskRow>();
  // biome-ignore lint/suspicious/noExplicitAny:
  const columns: ColumnDef<RiskRow, any>[] = [
    columnHelper.accessor((row) => getName(row.measure, { assetSymbol: getLabelOfBenchmark(row.benchmark) }), {
      header: 'Metric',
      cell: (params: CellContext<RiskRow, unknown>) =>
        getNameWithTooltip(params.row.original.measure, {
          assetSymbol: getLabelOfBenchmark(params.row.original.benchmark),
        }),
    }),
    columnHelper.accessor('value', {
      header: solution.name,
      meta: { align: 'right' },
      cell: (params: CellContext<RiskRow, unknown>) =>
        formatterForName(getFormat(params.row.original.measure))(params.row.original.value),
    }),
  ];

  if (compareToSolution) {
    columns.push(
      columnHelper.accessor(
        (row): number => {
          const metricId = createMetricLabelWithBenchmark(row.measure, row.benchmark);
          return compareToSolutionByMetricWithBenchmark[metricId]!.value;
        },
        {
          header: compareToSolution.name,
          meta: { align: 'right' },
          cell: (params: CellContext<RiskRow, unknown>): string => {
            const formatter = formatterForName(getFormat(params.row.original.measure));
            return formatter(params.getValue() as number) as string;
          },
        }
      ),
      columnHelper.accessor(
        (row) => {
          const metricId = createMetricLabelWithBenchmark(row.measure, row.benchmark);
          const compareToValue = compareToSolutionByMetricWithBenchmark[metricId]!.value;
          return row.value - compareToValue;
        },
        {
          header: 'Difference',
          meta: { align: 'right' },
          cell: (params: CellContext<RiskRow, number>) =>
            formatterForName(getFormat(params.row.original.measure))(params.getValue()),
        }
      )
    );
  }

  return <GTable data={riskMeasures} columns={columns} disablePagination hideHeaders={isNil(compareToSolution)} />;
};

export default PortfolioRiskMetricsList;
