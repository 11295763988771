import { Outlet } from 'react-router';
import PrivateRoute from 'routes/PrivateRoute/PrivateRoute';
import { NotFoundRoute } from './NotFoundRoute.tsx';
import PublicRoute from './PublicRoute/PublicRoute.tsx';
import type { RoutePath } from './Route.types';
import ErrorDashboard from '../components/technical/errors/ErrorDashboard';
import ErrorIndexRouter from '../components/technical/errors/ErrorIndexRouter';
import { errorName, type ErrorType } from '../components/technical/errors/ErrorTypes';
import Main from '../components/technical/Main/Main.tsx';
import MainIncognito from '../components/technical/Main/MainIncognito.tsx';
import PageShell from '../components/technical/PageShell/PageShell.tsx';
import TermsAndPolicies from '../components/TermsAndPolicies';
import { appUser } from '../groupGuards';
import Drawer from '../components/technical/Drawer/Drawer.tsx';
import { createIndexRoute } from './CreateIndexRoute.tsx';
import { marketRoutes } from './MarketRoutes.tsx';
import { portfolioRoutes } from './PortfolioRoutes.tsx';
import { copilotRoutes } from './CopilotRoutes.tsx';
import { operationRoutes } from './OperationRoutes.tsx';
import { managementRoutes } from './ManagementRoutes.tsx';
import { playgroundRoutes } from './PlaygroundRoutes.tsx';

export const routes: RoutePath[] = [
  {
    path: '/',
    element: (
      <Drawer>
        <Outlet />
      </Drawer>
    ),
    children: [
      {
        handle: {
          breadcrumb: 'Home',
        },
        index: true,
        element: <PublicRoute redirectOnNavigationTo="/app" element={<MainIncognito />} />,
      },
      {
        path: 'app',
        element: <PrivateRoute to="/" element={<Main />} shouldAllow={appUser} />,
        children: [
          marketRoutes,
          portfolioRoutes,
          copilotRoutes,
          operationRoutes,
          managementRoutes,
          {
            handle: { breadcrumb: 'Terms and policies', title: 'Terms and policies' },
            path: 'terms-and-policies',
            element: (
              <PageShell>
                <TermsAndPolicies />
              </PageShell>
            ),
          },
          {
            handle: { breadcrumb: 'Errors', title: 'Errors' },
            path: 'errors',
            children: [
              {
                handle: { breadcrumb: ({ error }: { error: string }) => errorName[error as ErrorType] ?? '?' },
                path: ':error',
                element: (
                  <PageShell>
                    <ErrorDashboard />
                  </PageShell>
                ),
              },
              {
                index: true,
                element: <ErrorIndexRouter />,
              },
            ],
          },
          playgroundRoutes,
          NotFoundRoute,
          createIndexRoute('/app/portfolio/dashboard'),
        ],
      },
    ],
  },
  NotFoundRoute,
];
