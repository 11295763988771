import type { ReactElement, ReactNode } from 'react';
import { IMessageRoleType } from '../../../../generated/graphql.tsx';
import { Box, Card, Stack, Typography } from '@mui/joy';
import type { Dayjs } from 'dayjs';
import { DateTimeFormat, formatDate } from '../../../formatter.utils.ts';
import { useUserTimezone } from '../../../technical/UseUserTimezone.tsx';

export const MessageCard = ({
  role,
  roleDecorator,
  children,
  createdAt,
  anchored,
}: {
  role: IMessageRoleType;
  children: ReactNode;
  createdAt?: Dayjs;
  anchored?: boolean;
  roleDecorator: string | ReactElement;
}): ReactElement => {
  const timezone = useUserTimezone();
  return (
    <Stack
      rowGap={1}
      sx={{
        [role === IMessageRoleType.User ? 'ml' : 'mr']: 'auto',
        overflowAnchor: anchored ? 'auto' : 'none',
      }}
      maxWidth={'100%'}
    >
      <Stack
        direction={role === IMessageRoleType.User ? 'row' : 'row-reverse'}
        justifyContent={'space-between'}
        columnGap={1}
        alignItems={'center'}
      >
        {createdAt && (
          <Typography level={'body-xs'}>{formatDate(createdAt, DateTimeFormat.DateTime, timezone)}</Typography>
        )}
        {typeof roleDecorator === 'string' ? (
          <Typography level={'title-sm'}>{roleDecorator}</Typography>
        ) : (
          <Box
            sx={{
              mr: !createdAt ? 'auto' : 0,
            }}
          >
            {roleDecorator}
          </Box>
        )}
      </Stack>
      <Card
        variant={role === IMessageRoleType.User ? 'solid' : 'plain'}
        invertedColors={role === IMessageRoleType.User}
        color={role === IMessageRoleType.User ? 'primary' : 'neutral'}
        sx={{
          [role === IMessageRoleType.User ? 'borderTopRightRadius' : 'borderTopLeftRadius']: 0,
        }}
      >
        {children}
      </Card>
    </Stack>
  );
};
