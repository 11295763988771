import dayjs, { type Dayjs } from 'dayjs';
import type { FormInputType } from '../../../technical/form/Form.types.ts';
import * as yup from 'yup';
import { yupWhen } from '../../../../validation.ts';
import { isNil } from 'lodash/fp';
import {
  isDayjsDateNotInTheFuture,
  isValidDayjsDate,
  isValidDayjsDateRange,
  parseUtcDate,
} from '../../../date.utils.ts';
import { DateTimeFormat, formatDate } from '../../../formatter.utils.ts';

type FormOutputPortfolio = {
  id: string;
  name: string;
  extraRebalancingSelection: boolean;
};
type FormOutputPortfolioItem = {
  portfolio: FormOutputPortfolio;
  synthPortfolioId: string | null;
};
export type FormOutputFields = {
  portfolios: FormOutputPortfolioItem[];
  portfolioLength?: unknown;
  range: {
    since: Dayjs;
    to: Dayjs | null;
  };
};
export type FormInputFields = FormInputType<FormOutputFields>;
const getPortfolioId = (def: FormOutputPortfolioItem): string =>
  def.portfolio.extraRebalancingSelection ? def.synthPortfolioId! : def.portfolio.id;
export const calculateSchema = (
  portfolioDates: Map<
    string,
    {
      since: UtcDate | null;
      to: UtcDate | null;
    }
  >
): yup.Schema => {
  return yup.object({
    portfolios: yup
      .array(
        yup
          .object({
            portfolio: yup.object().required(),
            synthPortfolioId: yupWhen(['portfolio'], ([portfolio]): yup.Schema => {
              if (isNil(portfolio) || !portfolio.extraRebalancingSelection) {
                return yup.mixed().nullable();
              }

              return yup.string().required();
            }),
          })
          .required()
      )
      .required(),
    portfolioLength: yup
      .mixed()
      .nullable()
      .optional()
      .when('portfolios', ([field], schema) =>
        schema.test('', 'At least one portfolio is required', () => field.length >= 1)
      ),
    range: yupWhen<[FormInputType<FormOutputPortfolioItem>[]]>(['portfolios'], ([portfolios]) => {
      const selectedDefs = portfolios.filter(
        (val): val is FormOutputPortfolioItem => !isNil(val) && !isNil(val.portfolio)
      );

      const firstSince = dayjs.min(
        selectedDefs
          .map((def) => portfolioDates.get(getPortfolioId(def))?.since)
          .filter((date): date is UtcDate => !isNil(date))
          .map((date): Dayjs => {
            const parsedDate = parseUtcDate(date);
            // backend returns the dates when portfolio snapshots are available,
            // whereas filtering in the ui is based on returns, so we need to add one day to since date
            return parsedDate.add(1, 'day');
          })
      );

      const commonTo = dayjs.min(
        selectedDefs
          .map((def) => portfolioDates.get(getPortfolioId(def))?.to)
          .filter((date): date is UtcDate => !isNil(date))
          .map((date) => parseUtcDate(date))
      );

      return yup.object({
        since: yup
          .mixed()
          .test('validDate', 'Date is invalid', isValidDayjsDate)
          .test('validDateNotFuture', 'Date cannot be in the future', isDayjsDateNotInTheFuture)
          .test(
            'afterCommonStartDate',
            `Must be greater or equal to ${formatDate(firstSince, DateTimeFormat.Date)}`,
            (since: unknown): boolean => {
              if (isNil(since) || isNil(firstSince) || !isValidDayjsDate(since)) {
                return true;
              }

              return !since.isBefore(firstSince);
            }
          )
          .test(
            'beforeCommonEndDateIfSet',
            `Must be lower or equal to ${formatDate(commonTo, DateTimeFormat.Date)}`,
            (since: unknown): boolean => {
              if (isNil(since) || !isValidDayjsDate(since)) {
                return true;
              }

              return !since.isAfter(commonTo);
            }
          )
          .required(),
        to: yup
          .mixed()
          .nullable()
          .test('validDate', 'Date is invalid', isValidDayjsDate)
          .test('validDateNotFuture', 'Date cannot be in the future', isDayjsDateNotInTheFuture)
          .test(
            'beforeCommonEndDateIfSet',
            `Must be lower or equal to ${formatDate(commonTo, DateTimeFormat.Date)}`,
            (to: unknown): boolean => {
              if (isNil(to) || !isValidDayjsDate(to)) {
                return true;
              }

              return !to.isAfter(commonTo);
            }
          )
          .when('since', ([since], schema) => {
            return schema.test('sinceBeforeTo', 'Must be equal or greater than from', (to) => {
              if (isNil(since) || isNil(to)) {
                return true;
              }
              return isValidDayjsDateRange([since, to]);
            });
          }),
      });
    }),
  });
};
