import { Stack, TabList, TabPanel, Tabs } from '@mui/joy';
import type { FunctionComponent, ReactNode } from 'react';
import { Outlet, useLocation } from 'react-router';
import { REPLAY_UNMASK_CLASS_NAME } from 'setup';

type NavigableTabsProps = {
  children: ReactNode;
  panels?: ReactNode;
  fullHeight?: boolean;
};

const NavigableTabs: FunctionComponent<NavigableTabsProps> = ({ children, panels, fullHeight }) => {
  const location = useLocation();
  const height = fullHeight ? '100%' : 'auto';
  return (
    <Tabs
      value={location.pathname}
      className={REPLAY_UNMASK_CLASS_NAME}
      sx={{
        height,
      }}
    >
      <Stack spacing={1.5} sx={{ height }}>
        <TabList>{children}</TabList>
        {panels ? (
          panels
        ) : (
          <TabPanel value={location.pathname} sx={{ padding: 0 }}>
            <Outlet />
          </TabPanel>
        )}
      </Stack>
    </Tabs>
  );
};

export default NavigableTabs;
