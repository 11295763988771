import get from 'lodash/fp/get';
import set from 'lodash/fp/set';
import type { IAsset } from 'generated/graphql';

export type Serie = { name: string; date: UtcDate[]; value: number[] };
export type CalculatePerAssetMetricsInputRow = {
  date: UtcDate;
  value: number;
  primaryAsset: Pick<IAsset, 'id'>;
  secondaryAsset: Pick<IAsset, 'id'>;
};

export const calculatePerAssetMetrics = <TAsset>(
  data: CalculatePerAssetMetricsInputRow[],
  idToAsset: Record<string, TAsset>,
  labelProvider: (firstAsset: TAsset, secondAsset: TAsset) => string
): Serie[] => {
  let series: Record<string, Record<string, { date: UtcDate; value: number }[]>> = {};

  for (const row of data) {
    const serieId = [row.primaryAsset.id, row.secondaryAsset.id].sort();
    const serieRows = get(serieId, series) ?? [];
    serieRows.push({ date: row.date, value: row.value });
    series = set(serieId, serieRows, series);
  }

  return Object.entries(series).flatMap(([assetId, nestedAssetAndRows]) => {
    return Object.entries(nestedAssetAndRows).map(([nestedAssetId, serie]) => {
      return {
        name: labelProvider(idToAsset[assetId], idToAsset[nestedAssetId]),
        date: serie.map((point) => point.date),
        value: serie.map((point) => point.value),
      };
    });
  });
};
