import type { Dayjs } from 'dayjs';
import type { FormInputType } from '../../technical/form/Form.types.ts';
import * as yup from 'yup';
import { isNil } from 'lodash/fp';
import { isDayjsDateNotInTheFuture, isValidDayjsDate, isValidDayjsDateRange } from '../../date.utils.ts';

export type FormOutputFields = {
  since: Dayjs | null;
  to: Dayjs | null;
  asset: {
    id: string;
    symbol: string;
  };
  models: {
    id: string;
    name: string;
  }[];
};

export type FormInputFields = FormInputType<FormOutputFields>;

export const schema = yup.object({
  models: yup.array(yup.mixed().required()).required().min(1),
  asset: yup.mixed().required(),
  since: yup
    .mixed()
    .nullable()
    .test('validDate', 'Date is invalid', isValidDayjsDate)
    .test('validDateNotFuture', 'Date cannot be in the future', isDayjsDateNotInTheFuture),
  to: yup
    .mixed()
    .nullable()
    .test('validDate', 'Date is invalid', isValidDayjsDate)
    .test('validDateNotFuture', 'Date cannot be in the future', isDayjsDateNotInTheFuture)
    .when('since', ([since], schema) => {
      return schema.test('sinceBeforeTo', 'Must be equal or greater than from', (to) => {
        if (isNil(since) || isNil(to)) {
          return true;
        }
        return isValidDayjsDateRange([since, to]);
      });
    }),
});
