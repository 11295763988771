import { useUserTimezone } from '../../../technical/UseUserTimezone.tsx';
import { DateTimeFormat, formatDate } from '../../../formatter.utils.ts';
import { Typography } from '@mui/joy';
import type { ReactElement } from 'react';

const PortfolioCreatedAtText = ({ createdAt }: { createdAt: string }): ReactElement => {
  const timezone = useUserTimezone();
  return (
    <Typography level="body-sm">Created at: {formatDate(createdAt, DateTimeFormat.ShortDateTime, timezone)}</Typography>
  );
};

export default PortfolioCreatedAtText;
