import type { PaginationInstance } from '@tanstack/react-table';
import type { CoreInstance } from '@tanstack/table-core';
import type { ReactElement } from 'react';
import { pageOptions } from './UseTablePaginator.tsx';
import { Select } from '../inputs';
import Pagination from '@mui/material/Pagination';

const calculateMaxPageNumber = (totalResults: number | undefined, pageSize: number): number => {
  return Math.ceil((totalResults ?? 0) / pageSize);
};

export const GTablePagination = <ROW,>(props: {
  table: CoreInstance<ROW> & PaginationInstance<ROW>;
  totalResults: number | undefined;
  hideRowsPerPage?: boolean;
}): ReactElement => {
  const maxPageNumber = calculateMaxPageNumber(props.totalResults, props.table.getState().pagination.pageSize);

  return (
    <>
      {!props.hideRowsPerPage && (
        <Select
          width="normal"
          value={props.table.getState().pagination.pageSize.toString()}
          options={pageOptions}
          disableClearable
          onChange={(value): void => {
            props.table.setPagination({
              pageIndex: Math.floor(
                (props.table.getState().pagination.pageIndex * props.table.getState().pagination.pageSize) /
                  Number.parseInt(value)
              ),
              pageSize: Number.parseInt(value),
            });
          }}
        />
      )}
      <Pagination
        sx={{
          marginLeft: 'auto !important',
        }}
        count={maxPageNumber}
        page={props.table.getState().pagination.pageIndex + 1}
        onChange={(_e, page) => {
          props.table.setPagination({
            pageIndex: page - 1,
            pageSize: props.table.getState().pagination.pageSize,
          });
        }}
      />
    </>
  );
};
