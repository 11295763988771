import type { ReactElement } from 'react';
import { Navigate } from 'react-router';

import { ErrorType } from './ErrorTypes.ts';
import { useHasErrors } from './UseHasErrors.tsx';
import Loader from '../Loader/Loader.tsx';

const ErrorIndexRouter = (): ReactElement => {
  const { loaded, errorTypes } = useHasErrors();
  if (!loaded) {
    return <Loader />;
  }

  const targetError = errorTypes.at(0) ?? ErrorType.JOURNAL;
  return <Navigate to={`/app/errors/${targetError}`} replace />;
};

export default ErrorIndexRouter;
