import { Add } from '@mui/icons-material';
import type { FunctionComponent, ReactNode } from 'react';

import GButton from './inputs/GButton/GButton';
import type { GButtonProps } from './inputs/GButton/GButton.props';

type AddButtonProps = {
  onClick: () => void;
  disabled?: boolean;
  children?: ReactNode;
  width?: GButtonProps['width'];
  variant?: GButtonProps['variant'];
};
const AddButton: FunctionComponent<AddButtonProps> = ({ onClick, disabled = false, children, width, variant }) => {
  return (
    <GButton
      startDecorator={<Add />}
      variant={variant ?? 'outlined'}
      width={width}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </GButton>
  );
};

export default AddButton;
