import { Tab, TabList, Tabs } from '@mui/joy';
import type { ReactElement, ReactNode } from 'react';

const SelectableTabs = <T extends string | number | null>({
  tabs,
  onChange,
  value,
}: {
  tabs: { label: ReactNode; value: Exclude<T, null> }[];
  onChange: (value: T) => void;
  value: T;
}): ReactElement => {
  return (
    <Tabs value={value} onChange={(_e, value): void => onChange(value as T)}>
      <TabList>
        {tabs.map((tab) => {
          return (
            <Tab key={tab.value} value={tab.value}>
              {tab.label}
            </Tab>
          );
        })}
      </TabList>
    </Tabs>
  );
};

export default SelectableTabs;
